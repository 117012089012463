import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert, SelectPicker } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import Helper from '../../utils/Helper';
import Swal from 'sweetalert2';

const { Column, HeaderCell, Cell } = Table;

class CreatePurchaseOrderFromTransfer extends Component {
    componentDidMount(){
        let branch_selected = Helper.getBranchSelected()
        this.props.onEmptyInfo()
        this.props.onGetTransferStoreBillRequest(branch_selected.id)
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onSelectTransferStoreBill = item => {
        if (item !== null){
            this.props.onSelectTransferStoreBill(item).then(result => {
                if(result !== undefined && result.products !== undefined && result.products !== null && result.products.length > 0){
                    this.props.onGetReceiptOfGoodByProducts(result.products).then(list => {
                        for(let i = 0; i < list.length; i++){
                            if(result.receipt_of_good_id === list[i].id){
                                this.props.onFieldChange("receipt_of_good_selected", {
                                    id: list[i].id,
                                    code: list[i].receipt_code,
                                    date: list[i].receipt_date,
                                    products: list[i].products
                                });
                                break
                            }
                        }
                    })
                }
            })
        }
    }
    onSelectReceiptOfGood = item => {   
        if (item !== null){
            this.props.onSelectReceiptOfGood(item).then(result => {
                if(result !== undefined && result.products !== undefined && result.products !== null && result.products.length > 0){
                    let products = this.props.purchase_order.products
                    for(let i = 0; i < result.products.length; i++){
                        for(let j = 0; j < products.length; j++){
                            if(result.products[i].product_id === products[j].id && result.products[i].product_option_id === products[j].product_option_id){
                                products[j].real_quantity = result.products[i].total 
                            }
                        }
                    }
                    this.props.onFieldChange("products",products);
                }
            })
        }
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("real_quantity", key, value);
    }
    onSubmit(){
        let {products, transfer_store_selected, receipt_of_good_selected, receipt_of_good_products } = this.props.purchase_order
        if(!(transfer_store_selected !== null && transfer_store_selected.id > 0)){
            Alert.error('Vui lòng chọn phiếu chuyển kho')
            return;
        }
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        if(receipt_of_good_selected.id !== undefined && receipt_of_good_selected.id > 0 && receipt_of_good_products.length > 0){
            for(let i = 0; i < receipt_of_good_products.length; i++){
                for(let j = 0; j < products.length; j++){
                    if(receipt_of_good_products[i].id === products[j].id && receipt_of_good_products[i].product_option_id === products[j].product_option_id && receipt_of_good_products[i].quantity !== products[j].quantity){
                        Alert.error('Số lượng hàng hóa '+products[j].name+' trong phiếu ghi nhận không đồng nhất với phiếu chuyển kho')
                        return;
                    }
                }
            }
        }
        let confirm_diff = false, confirm_diff_alert = ""
        for(let i = 0; i < products.length; i++){
            if(products[i].quantity !== products[i].real_quantity){
                confirm_diff_alert = 'Hàng hóa ' + products[i].name + ' có số lượng thực tế khác với số lượng hàng chuyển'
                confirm_diff = true
                break
            }
        }
        if (confirm_diff === true){
            Swal.fire({
                title: 'Xác nhận',
                text: confirm_diff_alert,
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                reverseButtons: true,
                confirmButtonText: 'TIẾP TỤC',
                cancelButtonText: 'DỪNG LẠI',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.onSubmitData()
                }
            })
        } else {
            this.onSubmitData()
        }
    }
    onSubmitData(){
        let {products, transfer_store_selected, import_receipt_notes, import_receipt_date, receipt_of_good_selected } = this.props.purchase_order
        let products_array = []
        products.forEach(item => {
            products_array.push({
                "id": item.id,
                "product_option_id": item.product_option_id,
                "product_name": item.name,
                "product_sku": item.sku,
                "product_model": item.model,
                "category_id": parseInt(item.category_id),
                "category_name": item.category_name,
                "brand_id": parseInt(item.brand_id),
                "brand_name": item.brand_name,
                "retail": parseFloat(item.price),
                "quantity": parseInt(item.real_quantity),
                "is_gift": parseInt(item.is_gift),
                "is_transfer_store": 1,
            })
        })
        let data_post = {
            import_receipt_date: import_receipt_date,
            import_receipt_notes: import_receipt_notes,
            transfer_store_id: transfer_store_selected.id,
            transfer_store_code: transfer_store_selected.code,
            transfer_store_date: Helper.replaceMysqlDateTime(transfer_store_selected.date),
            store_id: transfer_store_selected.to_store.store_id,
            store_code: transfer_store_selected.to_store.store_code,
            store_name: transfer_store_selected.to_store.store_name,
            products: products_array,
            receipt_of_good_id: (receipt_of_good_selected.id !== undefined && receipt_of_good_selected.id > 0)?receipt_of_good_selected.id:0,
            is_transfer_store: 1
        }
        //console.log(data_post);
        this.props.onSubmit(data_post).then(result => {
            if(result !== null && result.length > 0){
                let first_id = 0
                result.forEach(item => {
                    if(first_id === 0){
                        first_id = item.id
                    } else{
                        window.open("/purchase-order/" + item.id)
                    }
                })
                if (first_id > 0){
                    this.props.history.push("/purchase-order/" + first_id);
                }
            }
        })
    }
    render(){
        let {transfer_stores, transfer_store_selected, receipt_of_goods, receipt_of_good_selected, products, total_quantity, total_amount, total_product, import_receipt_notes, import_receipt_notes_active, save_button_loading } = this.props.purchase_order;
        
        let quantity_text_display = ""
        if(total_quantity > 0){
            quantity_text_display = (<span>&nbsp;({total_quantity})</span>)
        }
        let notes_field_class = "nk-int-st"
        if (import_receipt_notes !== "") {
            import_receipt_notes_active = true;
        }
        if(import_receipt_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let transfer_store_bills_array = []
        let transfer_store_bill_selected = 0
        if (transfer_stores !== null && transfer_stores.length > 0){
            transfer_stores.forEach(item => {
                let transfer_date = Helper.convertMysqlDateTime(Helper.replaceMysqlDateTime(item.transfer_date))
                transfer_store_bills_array.push({
                    label: item.transfer_store_code + " - " + transfer_date,
                    value: item.id
                })
            })
        }
        if (transfer_store_selected !== null && transfer_store_selected.id !== undefined && transfer_store_selected.id > 0){
            transfer_store_bill_selected = transfer_store_selected.id
        }
        let receipt_of_goods_array = []
        let receipt_of_good_value_selected = 0
        if (receipt_of_goods !== null && receipt_of_goods.length > 0){
            receipt_of_goods.forEach(item => {
                let receipt_date = Helper.convertMysqlDateTime(Helper.replaceMysqlDateTime(item.receipt_date))
                receipt_of_goods_array.push({
                    label: item.receipt_code + " - " + receipt_date,
                    value: item.id
                })
            })
        }
        if (receipt_of_good_selected !== null && receipt_of_good_selected.id !== undefined && receipt_of_good_selected.id > 0){
            receipt_of_good_value_selected = receipt_of_good_selected.id
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }
        return (
            <div className="container">
            <Helmet>
                <title>Nhập hàng chuyển kho</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/purchase-order/new-from-transfer"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <SelectPicker
                            block
                            size="lg"
                            placeholder="Chọn phiếu chuyển kho"
                            cleanable={false}
                            value={transfer_store_bill_selected}
                            data={transfer_store_bills_array}
                            onSelect={this.onSelectTransferStoreBill}
                        />
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={55}
                                    data={products} >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={300}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                return nameText
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Số lượng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.quantity} disabled />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Thực tế</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.real_quantity} min={0} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker format="YYYY-MM-DD HH:mm" appearance="subtle" placeholder={moment().format("YYYY-MM-DD HH:mm")} 
                                    defaultValue={moment().toDate()}
                                    placement="leftStart" ranges={[]} block disabled />
                                </div>
                                <div className="cl"></div>
                            </div>
                            { receipt_of_goods_array.length > 0 ? 
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <SelectPicker
                                    block
                                    size="lg"
                                    placeholder="Chọn phiếu ghi nhận"
                                    cleanable={false}
                                    value={receipt_of_good_value_selected}
                                    data={receipt_of_goods_array}
                                    onSelect={this.onSelectReceiptOfGood}
                                />
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng tiền hàng{quantity_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="import_receipt_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={import_receipt_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;HOÀN TẤT NHẬP HÀNG
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        purchase_order: state.purchase_order
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(PurchaseOrderAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(PurchaseOrderAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PurchaseOrderAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PurchaseOrderAction.fieldChange(field,value))
        },
        onUpdateProducts(products){
            dispatch(PurchaseOrderAction.updateProductsRequest(products))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(PurchaseOrderAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(PurchaseOrderAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(PurchaseOrderAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(PurchaseOrderAction.addRequest(data_post))
        },
        onGetTransferStoreBillRequest: branch_id => {
            return dispatch(PurchaseOrderAction.getTransferStoreBillRequest(branch_id))
        },
        onSelectTransferStoreBill: id => {
            return dispatch(PurchaseOrderAction.selectTransferStoreBillRequest(id))
        },
        onGetReceiptOfGoodByProducts: products => {
            return dispatch(PurchaseOrderAction.getReceiptOfGoodByProductsRequest(products))
        },
        onSelectReceiptOfGood: id => {
            return dispatch(PurchaseOrderAction.selectReceiptOfGoodRequest(id))
        },
    }
}
const CreatePurchaseOrderFromTransferConnect = connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrderFromTransfer);
export default withRouter(CreatePurchaseOrderFromTransferConnect);