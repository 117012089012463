import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as ProductsAction from '../../actions/ProductAction';
import Helper from '../../utils/Helper';

class CategoriesSelectBox extends Component {
    onSelectSearchByCategoryChange(opt, meta){
        if(opt != null){
            this.props.onFieldChange("search_category_selected",opt);
        } else {
            this.props.onFieldChange("search_category_selected",[]);
        }
    }
    componentDidMount(){
        this.props.onListCategories();
    }
    render(){
        let { search_categories, search_category_selected } = this.props.product;
        
        let categories_array = [
            {
                label: "Tất cả",
                value: 0,
                level: 0
            }
        ]
        if (search_categories !== undefined && search_categories.length > 0) {
            search_categories.forEach(function(item){
                var level = 0;
                categories_array.push({
                    label: item.info.name,
                    value: item.info.id,
                    level: level
                }); 
                if (typeof item.child != 'undefined' && item.child.length > 0){
                    item.child.forEach(function(item_2){
                        categories_array.push({
                            label: item_2.info.name,
                            value: item_2.info.id,
                            level: level + 1
                        }); 
                        if (typeof item_2.child != 'undefined' && item_2.child.length > 0){
                            item_2.child.forEach(function(item_3){
                                categories_array.push({
                                    label: item_3.info.name,
                                    value: item_3.info.id,
                                    level: level + 2
                                }); 
                                if (typeof item_3.child != 'undefined' && item_3.child.length > 0){
                                    item_3.child.forEach(function(item_4){
                                        categories_array.push({
                                            label: item_4.info.name,
                                            value: item_4.info.id,
                                            level: level + 3
                                        }); 
                                        if (typeof item_4.child != 'undefined' && item_4.child.length > 0){
                                            item_4.child.forEach(function(item_5){
                                                categories_array.push({
                                                    label: item_5.info.name,
                                                    value: item_5.info.id,
                                                    level: level + 4
                                                }); 
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
        return (
            <Select styles={Helper.setSelectBoxStyles()} options={categories_array}
                                    formatOptionLabel={Helper.formatOptionLabel}
                                    isClearable
                                    className="cl_select_box"
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    value={search_category_selected}
                                    placeholder="Chọn một nhóm hàng"
                                            onChange={(opt, meta) => this.onSelectSearchByCategoryChange(opt, meta)}
                                            ref={(input) => { this.search_by_category_input = input; }} />
        );
    }
}

const mapStateToProps = state => {
    return {
        product: state.product
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(ProductsAction.fieldChange(field,value))
        },
        onListCategories: () => {
            dispatch(ProductsAction.listCategoriesRequest())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSelectBox);