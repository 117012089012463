export const LIST_CATEGORY = 'LIST_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const FOCUS_FIELD_CATEGORY = 'FOCUS_FIELD_CATEGORY';
export const ERROR_FIELD_CATEGORY = 'ERROR_FIELD_CATEGORY';
export const FIELD_CHANGE_CATEGORY = 'FIELD_CHANGE_CATEGORY';
export const RESET_FIELD_CATEGORY = 'RESET_FIELD_CATEGORY';
export const BUTTON_SPINNER_CATEGORY = 'BUTTON_SPINNER_CATEGORY';
export const ITEM_BUTTON_SPINNER_CATEGORY = 'ITEM_BUTTON_SPINNER_CATEGORY';
export const RESET_FIELD_SEARCH_CATEGORY = 'RESET_FIELD_SEARCH_CATEGORY';
export const SEARCH_CATEGORY = 'SEARCH_CATEGORY';
export const GET_FINANCE_SOURCE_CATEGORY = 'GET_FINANCE_SOURCE_CATEGORY';

export const PRODUCT_CATEGORY_TYPE = 1
export const PRODUCT_BRAND_TYPE = 2
export const WAREHOUSE_CATEGORY_TYPE = 3
export const SHELVES_CATEGORY_TYPE = 4
export const CUSTOMER_GROUP_CATEGORY_TYPE = 5
export const ZONE_CATEGORY_TYPE = 6
export const PRODUCT_OPTION_CATEGORY_TYPE = 7
export const ACCOUNTING_TERMS_CATEGORY_TYPE = 8
export const UNIT_CATEGORY_TYPE = 9