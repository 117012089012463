export const GET_RETURN_INVOICE = 'GET_RETURN_INVOICE';
export const LIST_RETURN_INVOICE = 'LIST_RETURN_INVOICE';
export const SEARCH_RETURN_INVOICE = 'SEARCH_RETURN_RETURN_INVOICE';
export const RESET_FIELD_SEARCH_RETURN_INVOICE = 'RESET_FIELD_SEARCH_RETURN_INVOICE';
export const FIELD_CHANGE_RETURN_INVOICE = 'FIELD_CHANGE_RETURN_INVOICE';
export const FIELDS_CHANGE_RETURN_INVOICE = 'FIELDS_CHANGE_RETURN_INVOICE';
export const FOCUS_FIELD_RETURN_INVOICE = 'FOCUS_FIELD_RETURN_INVOICE';
export const ERROR_FIELD_RETURN_INVOICE = 'ERROR_FIELD_RETURN_INVOICE';
export const BUTTON_SPINNER_RETURN_INVOICE = 'BUTTON_SPINNER_RETURN_INVOICE';
export const BUTTON_UPDATE_SPINNER_RETURN_INVOICE = 'BUTTON_UPDATE_SPINNER_RETURN_INVOICE';
export const EMPTY_RETURN_INVOICE = 'EMPTY_RETURN_INVOICE';
export const GET_STAFF_NOTES_RETURN_INVOICE = 'GET_STAFF_NOTES_RETURN_INVOICE';
export const GET_LAST_STAFF_NOTES_RETURN_INVOICE = 'GET_LAST_STAFF_NOTES_RETURN_INVOICE';