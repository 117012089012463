import React from 'react';
import Cookies from 'universal-cookie';
const cookie_time_expiries = (new Date(new Date().getTime() + (7 * 24 * 3600 * 1000)))
const cookie_year_expiries = (new Date(new Date().getTime() + (365 * 24 * 3600 * 1000)))
const cookies = new Cookies();
const Helper = {
    setSelectBoxStyles(){
        return {
            container: (base, state) => ({
                ...base,
                opacity: state.isDisabled ? ".5" : "1",
                backgroundColor: "transparent",
                zIndex: state.isFocused ? "101 !important" : "8",
            }),
            valueContainer: (base) => ({
                ...base,
                overflowY: "hidden"
            })
        }
    },
    formatOptionLabel: ({ label, level }) => {
        if (level > 0) {
            var padding = level * 5;
            return (
                <div style={{ marginLeft: padding + "px" }}>
                    {label}
                </div>
                );
        } else {
            return <div>{label}</div>;
        }
    },
    findIndex(list, id) {
        var result = -1;
        list.forEach((item, index) => {
            if(item.id === id){
                result = index;
            }
        });
        return result;
    },
    findIndexByKey(list, key) {
        var result = -1;
        list.forEach((item, index) => {
            if(item.key === key){
                result = index;
            }
        });
        return result;
    },
    getJwt() {
        return cookies.get("signed");
    },
    setToken(token){
        //console.log("set signed true")
        //localStorage.setItem("token", "Bearer " + token);
        //cookies.set('token', 'MobyKid ' + token, { sameSite: 'strict', path: '/', expires: (new Date(new Date().getTime() + (7 * 24 * 3600 * 1000)))});
        cookies.set('signed', true, { sameSite: 'strict', path: '/', expires: cookie_time_expiries });
    },
    getUser(){
        var user = localStorage.getItem("user");
        if(user !== undefined && user !== null){
            user = JSON.parse(user)
        } else {
            user = cookies.get('user')
        }
        return user; 
    },
    setUser(user){
        localStorage.setItem("user",JSON.stringify(user));
        cookies.set('user', JSON.stringify(user), { sameSite: 'strict', path: '/', expires: cookie_time_expiries });
        /*if (user.branch_id !== undefined && user.branch_id > 0){
            let branch_selected = this.getBranchSelected()
            if(!(branch_selected !== null && branch_selected.id > 0)){
                this.setBranchSelected({
                    id: user.branch_id,
                    name: user.branch_name
                })
            }
        }*/
    },
    getStaffTransporter(){
        /*var transporter_info = localStorage.getItem("transporter_info");
        if(transporter_info !== undefined && transporter_info !== null){
            transporter_info = JSON.parse(transporter_info)
        } else {
            transporter_info = cookies.get('transporter_info')
        }*/
        let  transporter_info = cookies.get('transporter_info')
        return transporter_info;
    },
    setStaffTransporter(transporter_info){
        //localStorage.setItem("transporter_info",JSON.stringify(transporter_info));
        cookies.set('transporter_info', JSON.stringify(transporter_info), { sameSite: 'strict', path: '/', expires: cookie_time_expiries });
    },
    getLoginByWeb(){
        let  login_by_web = cookies.get('login_by_web')
        return login_by_web;
    },
    setLoginByWeb(){
        //localStorage.setItem("transporter_info",JSON.stringify(transporter_info));
        cookies.set('login_by_web', 1, { sameSite: 'strict', path: '/', expires: cookie_year_expiries });
    },
    setUserRole(role){
        localStorage.setItem("role", role);
    },
    checkMyRole(module){
        var allow = false
        var role = localStorage.getItem("role");
        if (role !== null && role !== ""){
            role = JSON.parse(role)
            if (role !== null){
                if (role.role === "admin"){
                    allow = true
                } else{
                    if (role.modules !== undefined && role.modules.length > 0){
                        for (var i = 0; i < role.modules.length; i++){
                            if(role.modules[i] === module){
                                allow = true
                                break
                            }
                        }
                    }
                }
            }
        }
        return allow;
    },
    isViewer(){
        var allow = false
        var role = localStorage.getItem("role");
        if (role !== null && role !== ""){
            role = JSON.parse(role)
            if (role !== null &&  role.role === "viewer"){
                allow = true
            }
        }
        return allow;
    },
    getMyStores(){
        var my_stores = localStorage.getItem("my_stores");
        if (my_stores !== null){
            my_stores = JSON.parse(my_stores)
        }
        return my_stores;
    },
    setMyStores(stores){
        localStorage.setItem("my_stores",JSON.stringify(stores));
    },
    getMyBranchs(){
        var my_branchs = localStorage.getItem("my_branchs");
        if (my_branchs !== null){
            my_branchs = JSON.parse(my_branchs)
        }
        return my_branchs;
    },
    setMyBranchs(branchs){
        localStorage.setItem("my_branchs",JSON.stringify(branchs));
    },
    setBranchSelected(branch_info){
        localStorage.setItem("branch_selected",JSON.stringify({
            id: branch_info.id,
            name: branch_info.name
        }));
    },
    getBranchSelected(){
        var branch_selected = localStorage.getItem("branch_selected");
        if (branch_selected !== null){
            branch_selected = JSON.parse(branch_selected)
        }
        let id = 0
        let name = ""
        if(branch_selected !== null && branch_selected.id > 0){
            id = branch_selected.id 
            name = branch_selected.name
        }
        return {
            id,
            name,
        };
    },
    getLanguage() {
        let language = localStorage.getItem("language")
        if(language === null){
            language = 1
        }
        return language
    },
    setLanguage(language){
        localStorage.setItem("language",language);
    },
    getSettings(){
        var settings = localStorage.getItem("settings");
        if (settings !== null){
            settings = JSON.parse(settings)
        }
        return settings;
    },
    setSettings(settings){
        localStorage.setItem("settings",JSON.stringify(settings));
    },
    removeLocalStore(){
        cookies.remove('signed', { sameSite: 'strict', path: '/', expires: cookie_time_expiries });
        cookies.remove('transporter_info', { sameSite: 'strict', path: '/', expires: cookie_time_expiries});
        cookies.remove('user', { sameSite: 'strict', path: '/', expires: cookie_time_expiries });
        localStorage.removeItem("token")
        localStorage.removeItem("user");
        localStorage.removeItem("transporter_info");
        localStorage.removeItem("role");
        localStorage.removeItem("my_stores");
        localStorage.removeItem("language");
        localStorage.removeItem("settings");
        localStorage.removeItem("my_branchs");
        localStorage.removeItem("branch_selected");
    },
    getQueryString(key){
        let params = new URLSearchParams(window.location.search);
        return params.get(key);
    },
    parseMoney(money){
        if(+process.env.REACT_APP_MONEY_DECIMAL === 0){
            money = Math.trunc(money)
        }
        return money
    },
    parseMoneyText(money){
        return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    removeMoneyText(money){
        return money.toString().replace(/,/g,'')
    },
    replaceMysqlDateTime(date){
        return date.replace("Z","").replace("T"," ")
    },
    convertToMysqlDate(date){
        return date.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, "$3-$2-$1")
    },
    convertMysqlDate(date){
        return date.replace(/(\d{4})-(\d{2})-(\d{2})/g, "$3/$2/$1")
    },
    convertMysqlDateTime(date){
        return date.replace(/(\d{4})-(\d{2})-(\d{2}) (.*)/g, "$3/$2/$1 $4")
    },
    isInt(n){
        return Number(n) === n && n % 1 === 0;
    },
    getMainUrl(){
        //let port = ":3000"
        return "http://" + window.location.hostname
    },
    getMonthName(month){
        const month_array = {
            "January": "T1",
            "February": "T2",
            "March": "T3",
            "April": "T4",
            "May": "T5",
            "June": "T6",
            "July": "T7",
            "August": "T8",
            "September": "T9",
            "October": "T10",
            "November": "T11",
            "December": "T12",
        }
        return month_array[month]
    },
    isSSl(){
        var transporter_info = this.getStaffTransporter();
        let is_ssl = false
        if(transporter_info !== undefined && transporter_info !== null && transporter_info.id !== undefined && transporter_info.id > 0){
            is_ssl = true
        }
        return is_ssl
    }
}
export default Helper;