export const GET_RECEIPT_GOODS = 'GET_RECEIPT_GOODS';
export const LIST_RECEIPT_GOODS = 'LIST_RECEIPT_GOODS';
export const SEARCH_RECEIPT_GOODS = 'SEARCH_RECEIPT_GOODS';
export const RESET_FIELD_SEARCH_RECEIPT_GOODS = 'RESET_FIELD_SEARCH_RECEIPT_GOODS';
export const FIELD_CHANGE_RECEIPT_GOODS = 'FIELD_CHANGE_RECEIPT_GOODS';
export const FIELDS_CHANGE_RECEIPT_GOODS = 'FIELDS_CHANGE_RECEIPT_GOODS';
export const FOCUS_FIELD_RECEIPT_GOODS = 'FOCUS_FIELD_RECEIPT_GOODS';
export const ERROR_FIELD_RECEIPT_GOODS = 'ERROR_FIELD_RECEIPT_GOODS';
export const BUTTON_SPINNER_RECEIPT_GOODS = 'BUTTON_SPINNER_RECEIPT_GOODS';
export const BUTTON_UPDATE_SPINNER_RECEIPT_GOODS = 'BUTTON_UPDATE_SPINNER_RECEIPT_GOODS';
export const EMPTY_RECEIPT_GOODS = 'EMPTY_RECEIPT_GOODS';
export const GET_STAFF_NOTES_RECEIPT_GOODS = 'GET_STAFF_NOTES_RECEIPT_GOODS';
export const GET_LAST_STAFF_NOTES_RECEIPT_GOODS = 'GET_LAST_STAFF_NOTES_RECEIPT_GOODS';
export const SUM_TOTAL_RECEIPT_GOODS = 'SUM_TOTAL_RECEIPT_GOODS';
export const UPDATE_PRODUCT_RECEIPT_GOODS = 'UPDATE_PRODUCT_RECEIPT_GOODS';

export const STATUS_IN_PROGRESS_RECEIPT_GOODS = 1
export const STATUS_FINISH_RECEIPT_GOODS = 2
export const STATUS_CANCEL_RECEIPT_GOODS = 3