export const FIELD_CHANGE_REPORT = 'FIELD_CHANGE_REPORT';
export const RESET_FIELD_REPORT = 'RESET_FIELD_REPORT';
export const RESET_FIELD_SEARCH_REPORT = 'RESET_FIELD_SEARCH_REPORT';
export const FOCUS_FIELD_REPORT = 'FOCUS_FIELD_REPORT';
export const ERROR_FIELD_REPORT = 'ERROR_FIELD_REPORT';
export const SET_LOADING_REPORT = 'SET_LOADING_REPORT';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const REPORT_INFO = 'REPORT_INFO';
export const REVENUE_REPORT = 'REVENUE_REPORT';
export const DAILY_REVENUE = 'DAILY_REVENUE';
export const TOP_PRODUCT_REPORT = 'TOP_PRODUCT_REPORT';
export const FIELDS_CHANGE_REPORT = 'FIELDS_CHANGE_REPORT';

export const REPORT_VIEW_CHART = 1
export const REPORT_VIEW_INFO = 2

export const INTEREST_TYPE_SELL = 1
export const INTEREST_TYPE_PROFIT = 2
export const INTEREST_TYPE_IMPORT_EXPORT = 3
export const INTEREST_TYPE_IMPORT_EXPORT_DETAILS = 4
export const INTEREST_TYPE_TIME = 5
export const INTEREST_TYPE_RECEIPT_DISCOUNT = 6
export const INTEREST_TYPE_RECEIPT_RETURN = 7
export const INTEREST_TYPE_SELLER = 8
export const INTEREST_TYPE_PRODUCT_PROFIT = 9
export const INTEREST_TYPE_OVERVIEW_BUSINESS_CAC = 10
export const INTEREST_TYPE_OVERVIEW_BUSINESS_AOV = 11
export const INTEREST_TYPE_OVERVIEW_BUSINESS_CR = 12
export const INTEREST_TYPE_OVERVIEW_BUSINESS_CRR = 13

