import * as MessageActionType from '../constants/MessageActionType';
var initialState = {
    error_message: ""
};
const message = (state = initialState, action) => {
    switch(action.type){
        case MessageActionType.SET_ERROR_MESSAGE:
            return {
                ...state,
                error_message: action.message
            };
        case MessageActionType.CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                error_message: ""
            };
        default: 
            return state;
    }
}
export default message;