import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { DatePicker } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import Helper from '../../utils/Helper';
import * as ReceiptOfGoodAction from '../../actions/ReceiptOfGoodAction';
import * as ReceiptOfGoodActionType from '../../constants/ReceiptOfGoodActionType';
import Moment from 'react-moment';
class ViewReceiptOfGood extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.props.onGetData(id).then(result => {
                if (!(result !== undefined && result.products !== undefined && result.products !== null)){
                    this.props.history.push("/receipt-of-goods");
                }
            })
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("receipt_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onFinish(){
        Swal.fire({
            title: 'Bạn thật sự muốn hoàn tất phiếu ghi nhận nhập hàng này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onFinish(this.props.receipt_of_good.id).then(result => {
                    if (result !== undefined && result.status !== undefined){
                        if(result.status === true){
                            this.props.onGetData(this.props.receipt_of_good.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    }
                });
            },
        })
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(this.props.receipt_of_good.id, reason_cancel).then(result => {
                        if(result.status === true){
                            this.props.onGetData(this.props.receipt_of_good.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let total_amount = item.retail * item.total
                 item.retail = Math.round(item.retail, -3)
                 total_amount = Math.round(total_amount, -3)
                 return (
                     <tr key={ index }>
                         <td className="text-center"><img src={item.product_url} alt="" /></td>
                         <td>{ item.product_model }</td>
                        <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.total} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.retail} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} />
</td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let { total_quantity, total_product, total_amount, products, receipt_date, save_button_loading} = this.props.receipt_of_good;
        receipt_date = Helper.replaceMysqlDateTime(receipt_date)
        let allow_update_cancel_status = false, allow_update_finish_status = false, allow_update_date = false
        if (Helper.checkMyRole('update_receipt_of_good_date') === true){
            allow_update_date = true
        }
        if (Helper.checkMyRole('update_receipt_of_good_cancel') === true){
            allow_update_cancel_status = true
        }
        if (Helper.checkMyRole('update_receipt_of_good_finish') === true){
            allow_update_finish_status = true
        }
        let html_alert_status = ""
        if(this.props.receipt_of_good.receipt_status === ReceiptOfGoodActionType.STATUS_FINISH_RECEIPT_OF_GOOD){
            allow_update_finish_status = false
            allow_update_date = false
            html_alert_status = <div className="col-xs-12">
                <div className="alert alert-success text-center">
                    <h3>{ this.props.receipt_of_good.staff_finish_name } hoàn thành lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.receipt_of_good.finish_time }</Moment></h3>
                </div>
            </div>  
        } else if(this.props.receipt_of_good.receipt_status === ReceiptOfGoodActionType.STATUS_CANCEL_RECEIPT_OF_GOOD){
            allow_update_cancel_status = false
            allow_update_finish_status = false
            allow_update_date = false
            html_alert_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.receipt_of_good.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.receipt_of_good.cancel_time }</Moment><br />
                        Lý do: { this.props.receipt_of_good.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Phiếu ghi nhận nhập hàng { this.props.receipt_of_good.receipt_code }</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/receipt-of-good/" + this.props.receipt_of_good.id} />
                </Helmet>   
                <div className="row">
                    { html_alert_status }
                    <div className="col-xs-12">
                    <div className="pull-left">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                        </ul>
                    </div>
                    <div className="pull-right">
                        { allow_update_date === true ?
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-info mr-right-5" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton> : "" }
                        { allow_update_finish_status === true ?
                        <button type="button"
                            className="btn btn-success mr-right-5" onClick={ () => { this.onFinish() } } >
                            <i className="fa fa-check"></i>&nbsp;Đã nhập hàng
                        </button> : "" }
                        { allow_update_cancel_status === true ?
                        <button type="button"
                            className="btn btn-danger" onClick={ () => { this.onCancel() } } >
                            <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                        </button> : "" }
                    </div>
                    <div className="cl"></div>
                    <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white">
                                <div className="form-element-list" >
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Mã phiếu:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <strong>{ this.props.receipt_of_good.receipt_code }</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className={allow_update_date === true ? "col-lg-3 col-md-3 col-sm-3 col-xs-12 mr-top-7" : "col-lg-3 col-md-3 col-sm-3 col-xs-12"}>
                                                        <label className="hrzn-fm">Thời gian:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <div className="pull-left">
                                                        { allow_update_date === true ? 
                                                        <div>
                                                            <DatePicker format="YYYY-MM-DD HH:mm" value={new Date(moment(receipt_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())} placement="bottomStart" size="md" ranges={[]} onChange = { (value) => { this.onDatePickerChange(value) } } />
                                                        </div> : <span>{ moment(receipt_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>  }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Người tạo:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        {this.props.receipt_of_good.staff_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Phiếu nhập</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <Link to={"/purchase-order/" + this.props.receipt_of_good.import_receipt_id.toString()} target="_blank">{this.props.receipt_of_good.import_receipt_code}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Phiếu chuyển</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <Link to={"/transfer-store/" + this.props.receipt_of_good.transfer_store_id.toString()} target="_blank">{this.props.receipt_of_good.transfer_store_code}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Trạng thái:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <span style={{ fontSize: 15, color: this.props.receipt_of_good.receipt_status_color }}>{this.props.receipt_of_good.receipt_status_text}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Chi nhánh:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        {this.props.receipt_of_good.branch_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
                                </div>    
                                <div className="normal-table-list mr-top-20">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex table-hover table-condensed">
                                            <colgroup>
                                                <col width="5%"></col>
                                                <col width="15%"></col>
                                                <col></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Mã hàng</th>
                                                    <th>Tên hàng</th>
                                                    <th className="text-right">Số lượng</th>
                                                    <th className="text-right">Giá nhập</th>
                                                    <th className="text-right">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showListProduct(products) }
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <td className="text-right">Tổng số lượng</td>
                                                    <td className="text-right">{ total_quantity }</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <td className="text-right">Tổng số mặt hàng</td>
                                                    <td className="text-right">{ total_product }</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <td className="text-right">Tổng tiền hàng</td>
                                                    <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        receipt_of_good: state.receipt_of_good
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFocusField(field,value){
            dispatch(ReceiptOfGoodAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ReceiptOfGoodAction.fieldChange(field,value))
        },
        onEmptyInfo(){
            dispatch(ReceiptOfGoodAction.emptyInfo())
        },
        onGetData: id => {
            return dispatch(ReceiptOfGoodAction.getRequest(id))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(ReceiptOfGoodAction.updateRequest(data_post, id, button_name))
        },
        onFinish: id => {
            return dispatch(ReceiptOfGoodAction.finishRequest(id))
        },
        onCancel: (id, reason_cancel) => {
            return dispatch(ReceiptOfGoodAction.cancelRequest(id, reason_cancel))
        },
    }
}
const ViewReceiptOfGoodConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewReceiptOfGood));
export default withRouter(ViewReceiptOfGoodConnect);