import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MultiCascader } from 'rsuite';
import * as VendorBillAction from '../../actions/VendorBillAction';
import Helper from '../../utils/Helper';

class VendorBillStatusSelectBox extends Component {
    onSelectSearchByStatusChange(opt, meta){
        if(opt != null){
            this.props.onFieldChange("search_status",opt);
        } else {
            this.props.onFieldChange("search_status",[]);
        }
    }   
    componentDidMount(){
        this.props.onListStatus();
    }
    render(){
        let { vendor_bill_status_select, search_status } = this.props.vendor_bill;
        let status_array = []
        if (vendor_bill_status_select.list !== undefined) {
            Object.keys(vendor_bill_status_select.list).forEach(key => status_array.push({value: parseInt(key), label: vendor_bill_status_select.list[key]}))
        }
        let _component = (
            <Select styles={Helper.setSelectBoxStyles()} options={status_array}
                                    isClearable
                                    className="cl_select_box"
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    value={search_status}
                                    placeholder="Trạng thái" onChange={(opt, meta) => this.onSelectSearchByStatusChange(opt, meta)}  />
        )
        if (this.props.multi === true){
            _component = (
                <MultiCascader
                    style={{width: 207}}
                    placeholder="Trạng thái"
                    data={status_array}
                    value={search_status}
                    searchable={false}
                    onChange={this.onSelectSearchByStatusChange.bind(this)}
                />
            )
        }
        return _component;
    }
}

const mapStateToProps = state => {
    return {
        vendor_bill: state.vendor_bill
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(VendorBillAction.fieldChange(field,value))
        },
        onListStatus: () => {
            dispatch(VendorBillAction.listStatusRequest())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VendorBillStatusSelectBox);