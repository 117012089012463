import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import * as InventoryAction from '../../actions/InventoryAction';

class InventoryForProduct extends Component {
    constructor() {
        super();
        this.state = {
            product_id: 0,
            options: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.product_id !== prevState.product_id || nextProps.options !== prevState.options){
          return { product_id: nextProps.product_id, options: nextProps.options};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.product_id !== this.props.product_id || prevProps.options !== this.props.options){
            this.setState({product_id: this.props.product_id, options: this.props.options});
            this.props.onListProductRemainInventory(this.props.product_id.toString());
        } 
    } 
    componentDidMount(){
        this.props.onListProductRemainInventory(this.props.product_id.toString());
    }
    showList(lists, skip_option = false){
        var result = null;
        if(lists.length > 0){
            if (this.props.options.length > 0 && skip_option === false){
                result = this.props.options.map((option_item, index) => {
                    let list_for_option = []
                    lists.forEach(item => {
                        if (option_item.id === item.product_option_id){
                            list_for_option.push(item)
                        }
                    })
                    if (list_for_option.length > 0){
                        return (
                            <tbody key={ index }>
                                <tr>
                                   <td colSpan="3"><strong>{option_item.name}</strong></td>
                                </tr>
                               { this.showList(list_for_option, true) }
                            </tbody>
                        ); 
                    }  
                    return null
                })
            } else {
                result = lists.map((item, index) => {
                     let store_text = (
                        <span>
                            {item.store_code}<br />
                            {item.store_name}
                        </span>
                    )
                     let parent_store_text = ""
                     if (item.parent_store_id > 0){
                        parent_store_text = (
                            <span>
                                {item.parent_store_code}<br />
                                {item.parent_store_name}
                            </span>
                        )
                     }
                     return (
                        <tr key={ index }>
                            <td>{parent_store_text}</td>
                            <td>{store_text}</td>
                            <td className="text-right"><NumberFormat value={item.remain} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                     );   
                });
            }
        }
        return result;
    }
    render(){
        let { list } = this.props.inventory;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                    { list.length > 0 ? 
                            <div>
                                <div className="normal-table-list">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex">
                                            <colgroup>
                                                <col width="20%"></col>
                                                <col width="20%"></col>
                                                <col width="5%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Kho hàng</th>
                                                    <th>Kệ hàng</th>
                                                    <th className="text-right">Số lượng</th>
                                                </tr>
                                            </thead>
                                            { this.props.options.length > 0 ? this.showList(list) : <tbody>{ this.showList(list) }</tbody> }
                                        </table>
                                    </div>
                                </div>
                            </div> : <div className="alert alert-warning">Không có thông tin nào</div> } 
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        inventory: state.inventory
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onListProductRemainInventory: product_ids => {
            dispatch(InventoryAction.listProductRemainInventoryRequest(product_ids))
        },
    }
}
 export default connect(mapStateToProps, mapDispatchToProps)(InventoryForProduct)