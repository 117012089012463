import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select  from 'react-select';
import { TagPicker, Icon } from 'rsuite';
import * as ProductsAction from '../../actions/ProductAction';
import Helper from '../../utils/Helper';

class BrandsSelectBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            is_bind_data: false
        }
    }
    onSelectSearchByBrandChange(opt, meta){
        if (this.props.is_report === true){
            this.props.onChange(opt)
        } else{
            if(opt != null){
                this.props.onFieldChange("search_brand_selected",opt);
            } else {
                this.props.onFieldChange("search_brand_selected",[]);
            }
        }
    }
    componentDidMount(){
        this.props.onListBrands();
    }
    componentDidUpdate(){
        let _this = this
        if(_this.state.is_bind_data === false){
            if (_this.props.is_multiple === true && _this.props.selected !== null && _this.props.selected.length > 0) {
                let brand_selected = _this.props.selected
                setTimeout(() => {
                    let { search_brands } = _this.props.product;
                    if(search_brands.length > 0){
                        let brand_selected_array = []
                        brand_selected.forEach(value => {
                            for(let i = 0; i < search_brands.length; i++){
                                if(search_brands[i].id === value){
                                    brand_selected_array.push({
                                        label: search_brands[i].name,
                                        value: search_brands[i].id
                                    })
                                    break
                                }
                            }
                        })
                        if(brand_selected_array.length > 0){
                            _this.props.onBindDataSelected(brand_selected_array)
                        }
                    }
                }, 250)
            }
            _this.setState({
                is_bind_data: true
            })
        }
    }
    onHandleMultiChange(value){
        if (this.props.is_report === true){
            this.props.onChange(value)
        }  
    }
    onHandleMultiSelect(value, item){
        this.props.onSelect(value, item)
    }
    onHandleMultiClean(){
        this.props.onClean()
    }
    render(){
        let { search_brands, search_brand_selected } = this.props.product;
        let brands_array = []
        if (search_brands !== undefined && search_brands.length > 0) {
            search_brands.forEach(function(item){
                brands_array.push({
                    label: item.name,
                    value: item.id
                }); 
            });
        }
        if (search_brand_selected.length === 0 && this.props.is_multiple === true && this.props.selected !== null && this.props.selected.length > 0) {
            search_brand_selected = this.props.selected
        }
        else if (search_brand_selected.value === undefined && this.props.selected !== null && this.props.selected.value !== undefined && this.props.selected.value !== null){
            search_brand_selected = this.props.selected
            if(search_brand_selected.label === ""){
                for(let i = 0; i < brands_array.length; i++){
                    if(+brands_array[i].value === search_brand_selected.value){
                        search_brand_selected.label = brands_array[i].label
                        break;
                    }
                }
            }
        }
        let component = <Select styles={Helper.setSelectBoxStyles()} options={brands_array}
                            isClearable
                            className="cl_select_box"
                            menuPlacement="auto"
                            menuPosition="fixed"
                            value={search_brand_selected}
                            placeholder="Chọn một thương hiệu"
                            onChange={(opt, meta) => this.onSelectSearchByBrandChange(opt, meta)}
                            ref={(input) => { this.search_by_brand_input = input; }} />
        if (this.props.is_multiple === true) {
            component = <TagPicker
                            placeholder="Chọn thương hiệu"
                            style={{width: 207}}
                            data={brands_array}
                            value={search_brand_selected}
                            onClean={this.onHandleMultiClean.bind(this)}
                            onChange={this.onHandleMultiChange.bind(this)}
                            onSelect={this.onHandleMultiSelect.bind(this)}
                            renderMenu={menu => {
                                if (brands_array.length === 0) {
                                    return (
                                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                        <Icon icon="spinner" spin /> Loading...
                                    </p>
                                    );
                                }
                                return menu;
                            }}
                        />
        }
        return component;
    }
}

BrandsSelectBox.defaultProps = {
    is_report: false,
    is_multiple: false,
    selected: []
}

const mapStateToProps = state => {
    return {
        product: state.product
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(ProductsAction.fieldChange(field,value))
        },
        onListBrands: () => {
            dispatch(ProductsAction.listBrandsRequest())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandsSelectBox);