import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import * as VendorBillAction from '../../actions/VendorBillAction';
import * as UserAction from '../../actions/UserAction';
import * as ModalAction from '../../actions/ModalAction';
import * as UserActionType from '../../constants/UserActionType';
import * as DiscountModeType from '../../constants/DiscountModeType';
import * as CategoryActionType from '../../constants/CategoryActionType';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Alert, DatePicker, Icon, InputNumber, Popover, SelectPicker, Whisper, } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Helper from '../../utils/Helper';
import CreatePartnerFast from '../partner/CreateFast';
import Modal from '../shared/Modal';
import DiscountInput from '../shared/DiscountInput';
import TagInput from '../shared/TagInput';
let timeoutSearchObject = null
const triggerRefAddProduct = React.createRef();
class AccountingCreateVendorBill extends Component {
    constructor(props){
        super(props)

        this.onCleanObject = this.onCleanObject.bind(this);
        this.onSearchObject = this.onSearchObject.bind(this);
        this.onSelectObject = this.onSelectObject.bind(this);
    }
    componentDidMount(){
        this.props.onEmptyInfo()
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onNumberFieldFocus = input_name => {
        let field_active = input_name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })  
    }
    onNumberFieldBlur = input_name => {
        let field_active = input_name + '_active';
        let value = ""
        if (input_name === "amount"){
            value = this.amount_input.value
        }
        let is_focus = false;
        if(value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })  
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onTaxFieldChange(value){
        var _this = this
        _this.props.onFieldChange("tax",value);
        setTimeout(() => {
            _this.props.onSumTotal()
        }, 250);
    }
    onNumberFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("bill_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onUserFieldChange = item => {
        this.props.onFieldChange("object_selected", item)
    }
    onSearchObject(keyword, event){
        if(timeoutSearchObject !== null){
            clearTimeout(timeoutSearchObject)
        }
        if (!keyword) {
          return;
        }
        let _this = this
        timeoutSearchObject = setTimeout(() => {
            var data_search = {
                "user_types": UserActionType.STAFF_USER_TYPE + "," + UserActionType.CUSTOMER_USER_TYPE + "," + UserActionType.TRANSPORTER_USER_TYPE + "," + UserActionType.PAYMENT_OBJECT_USER_TYPE,
                "keyword": keyword,
            }
            _this.props.onSearchObject(data_search, 1, _this.props.user.limit, false);
        }, 250);
    }
    onSelectObject(_,item){
        let value = {}
        if(item !== null){
            value = item
        }
        this.props.onFieldChange("object_selected", value);
    }
    onCleanObject = () => {
        this.props.onFieldChange("object_selected", {});
    }
    handleModalClose(){
        this.props.onShowModal(false);
    }
    handleModalShowAddObject(){
        this.props.onShowModal(true, "Tạo thông tin đối tượng giao dịch", "modal-lg", "add_user", "submit_add_user");
    }
    handleModalSubmit(submit_type){
        if (submit_type === "submit_add_user"){
            this.handleSubmitFormAddObject()
        }
    }
    handleSubmitFormAddObject(){
        this.createPartnerRef.onSubmit()
    }
    onCreatePartnerReturn(user_info){
        this.props.onFieldChange("object_selected", {
            label: user_info.name,
            value: user_info.id,
            id: user_info.id,
            code: user_info.code,
            name: user_info.name,
            phone: user_info.phone,
            address: user_info.address,
            city: user_info.city,
            district: user_info.district,
            ward: user_info.ward,
        });
        this.handleModalClose();
    }
    onAddProductChange(value) {
        triggerRefAddProduct.current.open()
        let product_selected = this.props.vendor_bill.product_selected
        product_selected["name"] = value
        this.props.onFieldChange("product_selected", product_selected);
    }
    onUnitChange(value) {
        triggerRefAddProduct.current.open()
        let product_selected = this.props.vendor_bill.product_selected
        product_selected["unit"] = value
        this.props.onFieldChange("product_selected", product_selected);
    }
    onProductQuantityFieldChange = (value) => {
        let product_selected = this.props.vendor_bill.product_selected
        product_selected["quantity"] = value
        this.props.onFieldChange("product_selected", product_selected);
    }
    onProductPriceFieldChange = (values) => {
        let product_selected = this.props.vendor_bill.product_selected
        product_selected["price"] = values.value
        this.props.onFieldChange("product_selected", product_selected);
    }
    onAddToList = () => {
        let product_selected = this.props.vendor_bill.product_selected
        let name = "", id = 0, unit = "", unit_id = 0, quantity = 0, price = 0
        if (!(product_selected.name !== undefined && product_selected.name !== null && product_selected.name !== "")){
            Alert.error("Vui lòng chọn hàng hóa, vật tư")
            return 
        }
        if (!(product_selected.unit !== undefined && product_selected.unit !== null && product_selected.unit !== "")){
            Alert.error("Vui lòng chọn đơn vị")
            return 
        }
        if (product_selected.quantity !== undefined){
            quantity = parseFloat(product_selected.quantity)
            if (!(quantity > 0)){
                Alert.error("Vui lòng nhập số lượng")
                return 
            }
        }
        if (product_selected.price !== undefined){
            price = parseFloat(product_selected.price)
            if (!(price > 0)){
                Alert.error("Vui lòng nhập đơn giá")
                return 
            }
        }
        name = product_selected.name.label
        if (name !== product_selected.name.value){
            id = parseInt(product_selected.name.value)
        }
        if (isNaN(id)){
            id = 0
        }
        unit = product_selected.unit.label
        if (unit !== product_selected.unit.value){
            unit_id = parseInt(product_selected.unit.value)
        }
        if (isNaN(unit_id)){
            unit_id = 0
        }
        let products = this.props.vendor_bill.products
        let add = true
        if (products.length > 0){
            products.forEach(item => {
                if (item.id === id && item.name === name){
                    item.quantity = item.quantity + quantity
                    item.price = price
                    item.total = item.quantity * item.price
                    add = false
                }
            })
        }
        if (add === true){
            let total = quantity * price
            products.push({
               name, id, unit, unit_id, quantity, price, total
            })
        }
        this.props.onFieldChange("products", products)
        this.onClearFormAddProduct()
        var _this = this
        setTimeout(() => {
            _this.props.onSumTotal()
        }, 250);
        triggerRefAddProduct.current.close()
    }
    onClearFormAddProduct(){
        this.props.onFieldChange("product_selected", {
            name: "",
            id: 0,
            unit: "",
            unit_id: "",
            quantity: 1,
            price: "",
            tax: "",
            total: 0
        })
        this.props.onFieldChange("clear_tag_input", true)
    }
    onRemoveProduct = id => {
        let products = this.props.vendor_bill.products;
        let index = Helper.findIndex(products, id);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("quantity", key, value);
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price", key, values.value);
    }
    onDiscountItemFieldChange(key, value, discount_mode){
        this.props.onUpdateProductItem("discount_mode", key, discount_mode);
        this.props.onUpdateProductItem("discount", key, value);
    }
    onTaxItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("tax", key, value);
    }
    onSubmit(){
        let { object_selected, products, bill_date, bill_notes, tax } = this.props.vendor_bill
        if (!(object_selected.id !== undefined && object_selected.id > 0)){
            Alert.error("Vui lòng chọn đối tác giao dịch")
            return 
        }
        if (products.length === 0){
            Alert.error("Vui lòng chọn hàng hóa giao dịch")
            return 
        }
        if (bill_date === ""){
            bill_date = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        for (var i = 0; i < products.length; i++){
            products[i].discount_money = products[i].discount
            if (products[i].discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                products[i].discount_money = 0
            } else{
                products[i].discount = 0
            }
            products[i].discount = parseFloat(products[i].discount)
            products[i].discount_money = parseFloat(products[i].discount_money)
        }
        tax = parseFloat(tax)
        if (isNaN(tax)){
            tax = 0
        }
        this.props.onSubmit({
            object_id: object_selected.id,
            object_name: object_selected.name,
            bill_date,
            bill_notes,
            tax,
            products
        }).then(result => {
            if(result.status === true){
               this.props.history.push("/accounting/vendor-bills")
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            } 
        })
    }
    showListProducts(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                return (
                    <tr className="cl_handover" key={ index }>
                        <td className="text-center">{index + 1}</td>
                        <td>{ item.name }</td>
                        <td>{ item.unit }</td>
                        <td>
                            <div className="nk-int-st nk-toggled">
                                <InputNumber className="text-right" value={item.quantity} min={1} onChange={(value) => { this.onQuantityItemFieldChange(item.id,value) }}/>
                            </div>    
                        </td>
                        <td>
                            <div className="nk-int-st nk-toggled">
                                <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={item.price} onValueChange={(values) => this.onPriceItemFieldChange(item.id,values)} />
                            </div>
                        </td>
                        <td>
                            <DiscountInput discount_input="discount_item" discount_value={item.discount_value} fieldChange={(input_name,value,discount_mode) => {this.onDiscountItemFieldChange(item.id,value,discount_mode)}} />
                        </td>
                        <td>
                            <div className="nk-int-st nk-toggled">
                                <InputNumber className="text-right" value={item.tax} max={100} min={0} onChange={(value) => { this.onTaxItemFieldChange(item.id,value) }}/>
                            </div>
                        </td>
                        <td className="text-right">
                            <div className="nk-int-st nk-toggled"><NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={item.total} /></div>
                        </td>
                        <td className="text-center">
                            <button type="button" onClick={() => { this.onRemoveProduct(item.id) }}><i className="fa fa-remove"></i></button>
                        </td>
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { object_selected, products, save_button_loading } = this.props.vendor_bill
        let { list } = this.props.user
        let { show_modal, modal_type, modal_title, modal_submit_type, modal_button_spinner} = this.props.modal;

        let has_in_list = false
        let objects_array = []
        let object_value_selected = 0
        if(object_selected.value !== undefined && object_selected.value !== null){
            object_value_selected = object_selected.value;
        }
        if (list.length > 0) {
            list.forEach(function(item){
                objects_array.push({
                    label: item.code + " - " + item.name + " - " + item.phone,
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    phone: item.phone,
                    address: item.address,
                    city: item.city,
                    district: item.district,
                    ward: item.ward,
                });
                if (object_value_selected > 0 && object_value_selected === item.id){
                    has_in_list = true
                }
            });
        }
        if (has_in_list === false && object_selected.value !== undefined && object_selected.value !== null){
            objects_array.push(object_selected); 
        }
        let price_product_selected = "", quantity_product_selected = ""
        if (this.props.vendor_bill.product_selected.price !== undefined && this.props.vendor_bill.product_selected.price !== null){
            price_product_selected = this.props.vendor_bill.product_selected.price
        }
        if (this.props.vendor_bill.product_selected.quantity !== undefined && this.props.vendor_bill.product_selected.quantity !== null){
            quantity_product_selected = this.props.vendor_bill.product_selected.quantity
        }
        const speaker_products = (
            <Popover title="Thêm hàng hóa" style={{ width: 500 }}>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="form-group">
                            <TagInput api={{
                                url: `product-materials`
                            }} placeholder="Tìm kiếm hàng hóa, vật tư" limit={1} creatable={true} clear={this.props.vendor_bill.clear_tag_input} onChange={(value) => this.onAddProductChange(value)}  />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className="nk-int-mk mr-bot-5">
                            <strong>Đơn vị</strong>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb cl_list_product">
                            <TagInput api={{
                                url: `categories`,
                                param: {
                                    category_type: CategoryActionType.UNIT_CATEGORY_TYPE
                                },
                                method: "POST"
                            }} placeholder="Đơn vị" limit={1} creatable={true} clear={this.props.vendor_bill.clear_tag_input} onChange={(value) => this.onUnitChange(value)} />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className="nk-int-mk mr-bot-5">
                            <strong>Số lượng</strong>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb cl_list_product">
                            <div className="nk-int-st nk-toggled">
                                <InputNumber className="text-right" value={quantity_product_selected} min={1} onChange={(value) => { this.onProductQuantityFieldChange(value) }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className="nk-int-mk mr-bot-5">
                            <strong>Đơn giá</strong>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className="nk-int-st nk-toggled">
                                <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={price_product_selected} onValueChange={(values) => this.onProductPriceFieldChange(values)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 text-center">
                        <button className="btn btn-danger mr-right-5" onClick={ () => { triggerRefAddProduct.current.close() } } >
                            <i className="fa fa-ban"></i>&nbsp;Đóng lại
                        </button>
                        <LaddaButton
                            loading={ this.props.category.save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-info" onClick={ () => { this.onAddToList() } } >
                            <i className="fa fa-save"></i>&nbsp;Thêm vào danh sách
                        </LaddaButton>
                    </div>
                </div>
            </Popover>
          );
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 col-xs-12">
                        <div className="mr-bot-5">
                            <LaddaButton
                                loading={ save_button_loading } 
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-success pull-right notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                <i className="fa fa-plus"></i>&nbsp;TẠO PHIẾU
                            </LaddaButton>
                            <div className="cl"></div>
                        </div>
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <h2>
                                Tạo phiếu mua hàng
                                </h2>
                                <p></p>
                            </div>
                            <div className="bsc-tbl">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="nk-int-mk mr-bot-5">
                                                    <h2>Ngày mua hàng</h2>
                                                </div>
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <DatePicker format="YYYY-MM-DD HH:mm"  placeholder={moment().format("YYYY-MM-DD HH:mm")} placement="bottomStart" oneTap ranges={[]} block onChange = { (value) => { this.onDatePickerChange(value) } } />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                            <div className="nk-int-mk mr-bot-5">
                                                <h2>Đối tác giao dịch</h2>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="pull-left w-85">
                                                    <SelectPicker
                                                        block
                                                        placeholder="Tìm đối tác giao dịch"
                                                        cleanable={true}
                                                        data={objects_array}
                                                        value={object_value_selected}
                                                        onClean={this.onCleanObject}
                                                        onSearch={this.onSearchObject}
                                                        onSelect={this.onSelectObject}
                                                        renderMenu={menu => {
                                                            if (objects_array.length === 0) {
                                                                return (
                                                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                                    <Icon icon="spinner" spin />
                                                                </p>
                                                                );
                                                            }
                                                            return menu;
                                                        }}
                                                    />
                                                </div>
                                                <div className="pull-left mr-left-5">
                                                    <button type="button" className='btn btn-link btn-sm btn-plus' onClick={() => { this.handleModalShowAddObject() }}><Icon icon="plus-circle" size="2x" /></button>
                                                </div>
                                                <div className="cl"></div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="nk-int-mk mr-bot-5 padd-10">
                                                <h2>
                                                    <div className="pull-left mr-top-10">Hàng hóa</div>
                                                    <div className="pull-left mr-left-5">
                                                        <Whisper
                                                            ref={triggerRefAddProduct}
                                                            preventOverflow
                                                            trigger="click"
                                                            speaker={speaker_products}
                                                            placement="auto"
                                                            enterable
                                                        >
                                                            <button type="button" className='btn btn-link btn-sm btn-plus'><Icon icon="plus-circle" size="2x" /></button>
                                                        </Whisper>
                                                    </div>
                                                    <div className="cl"></div>
                                                </h2>
                                            </div>
                                            <div className="normal-table-list cl_list_product">
                                            { products.length > 0 ? 
                                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                                    <colgroup>
                                                        <col width="1%"></col>
                                                        <col></col>
                                                        <col width="5%"></col>
                                                        <col width="10%"></col>
                                                        <col width="15%"></col>
                                                        <col width="15%"></col>
                                                        <col width="10%"></col>
                                                        <col width="15%"></col>
                                                        <col width="1%"></col>
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>STT</th>
                                                            <th>Tên hàng</th>
                                                            <th>ĐVT</th>
                                                            <th>Số lượng</th>
                                                            <th>Đơn giá</th>
                                                            <th>Giảm giá</th>
                                                            <th>Thuế (%)</th>
                                                            <th className="text-right">Thành tiền</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    { this.showListProducts(products) }
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan="7" className="text-right">Tạm tính</th>
                                                            <th className="text-right">
                                                                <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={this.props.vendor_bill.total_amount} />
                                                            </th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="7" className="text-right">Thuế (%)</th>
                                                            <th>
                                                                <div className="nk-int-st nk-toggled">
                                                                        <InputNumber className="text-right hide-number-arrows" value={this.props.vendor_bill.tax} min={0} onChange={(value) => { this.onTaxFieldChange(value) }}/>
                                                                </div>
                                                            </th>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="7" className="text-right">Tổng tiền</th>
                                                            <th className="text-right">
                                                                <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={this.props.vendor_bill.amount} />
                                                            </th>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table> : "" }
                                            </div>       
                                        </div>
                                    </div>                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>           
                <Modal show={show_modal} type={modal_type} spinner={modal_button_spinner} title={modal_title} handleClose={this.handleModalClose.bind(this)} handleSubmit={this.handleModalSubmit.bind(this,modal_submit_type)}  children={<CreatePartnerFast getRef={ref => (this.createPartnerRef = ref)} avatarUploaderWidth={100} avatarUploaderHeight={100} has_save_button={false} return_info={true} return={user_info => { this.onCreatePartnerReturn(user_info)  } } vendor={true} />}></Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vendor_bill: state.vendor_bill,
        user: state.user,
        category: state.category,
        modal: state.modal
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(VendorBillAction.emptyInfo())
            dispatch(UserAction.resetList())
        },
        onErrorField(field,value){
            dispatch(VendorBillAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(VendorBillAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(VendorBillAction.fieldChange(field,value))
        },
        onShowModal: (status, title, type, input, submit_type) => {
            dispatch(ModalAction.showModal(status, title, type, input, submit_type))
        },
        onModalButtonSpinner(status){
            dispatch(ModalAction.buttonSpinner(status))
        },
        onSearchObject: (data_search,page,limit,spinner) => {
            dispatch(UserAction.searchRequest(data_search,page,limit,spinner))
        },
        onUpdateProducts(products){
            dispatch(VendorBillAction.updateProductsRequest(products))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(VendorBillAction.updateProductItemRequest(input_name, key, value))
        },
        onSumTotal(){
            dispatch(VendorBillAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(VendorBillAction.addRequest(data_post))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(AccountingCreateVendorBill)