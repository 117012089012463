import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { Alert } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Helper from '../../utils/Helper';
import * as TransferStoreAction from '../../actions/TransferStoreAction';
import * as TransferStoreActionType from '../../constants/TransferStoreActionType';
import Moment from 'react-moment';
class ViewTransferWarehouse extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: 0
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.id !== prevState.id){
          return { id: nextProps.id};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.id !== this.props.id){
            this.setState({id: this.props.id});
            this.onInitData()
        } 
    } 
    componentDidMount(){
        this.onInitData()
    }
    onInitData(){
        if (this.props.id > 0){
            this.props.onGetStaffNotes(this.props.id)
            this.props.onGetLastStaffNote(this.props.id)
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onShowPrintButton(){
        this.props.onFieldChange("show_print_button", true)
    }
    onPrint() {
        let id = 'transfer_store_bill'
        const iframe = document.frames
        ? document.frames[id]
        : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();
        return false;
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(this.props.transfer_store.id, reason_cancel).then(result => {
                        if(result.status === true){
                            this.props.onEmptyInfo()
                            this.props.onGetData(this.props.transfer_store.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    onSaveData(){
        let transfer_notes = this.props.transfer_store.transfer_notes
        this.props.onUpdateData({
            transfer_notes
        }, this.props.transfer_store.id, "save_button_loading").then(result => {
            if(result.status === true){
                Alert.success("Thông tin phiếu chuyển kho đã lưu thành công")
                this.notes_input.value = ""
                this.props.onGetStaffNotes(this.props.id)
                this.props.onGetLastStaffNote(this.props.id)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price_suggest", key, values.value);
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let has_difference_text = ""
                 if(this.props.transfer_store.transfer_status !== TransferStoreActionType.STATUS_IS_TEMP_TRANSFER_STORE && this.props.transfer_store.import_receipt_id > 0 && item.quantity !== item.real_quantity){
                    has_difference_text = <div key={"has_difference_" + item.product_id}><label className='label label-danger'>Có chênh lệch</label></div>
                 }
                 return (
                     <tr key={ index } >
                         <td className="text-center"><img src={item.url} alt="" /></td>
                         <td>{ item.model }</td>
                        <td>{gift_text}{ item.name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }{has_difference_text}</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.real_quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.transfer_store.id, id)
            },
          })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr key={ index }>
                         <td>{ item.content }</td>
                         <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton>
                         </td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let {products, total_product, total_quantity, total_amount, transfer_date, staff_notes, last_notes, last_notes_staff_name, last_notes_time, save_button_loading, from_store, to_store } = this.props.transfer_store;
        transfer_date = Helper.replaceMysqlDateTime(transfer_date)

        let allow_update_staff_notes = true
        let allow_update_cancel_status = false
        if (Helper.checkMyRole('update_cancel_transfer_store') === true){
            allow_update_cancel_status = true
        }
        let html_alert_bill_status = ""
        if(this.props.transfer_store.transfer_status === TransferStoreActionType.STATUS_CANCEL_TRANSFER_STORE){
            allow_update_cancel_status = false
            allow_update_staff_notes = false
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.transfer_store.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.transfer_store.cancel_time }</Moment><br />
                        Lý do: { this.props.transfer_store.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        let from_store_text = []
        if(from_store !== null && from_store.length > 0){
            from_store.forEach(item => {
                from_store_text.push(
                    <span>{item.store_code + " - " + item.store_name}</span>
                )
            })
        }
        return (
            <div className="container">
            <Helmet>
                <title>Phiếu chuyển kho { this.props.transfer_store.transfer_store_code }</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/transfer-store/" + this.props.transfer_store.id} />
            </Helmet>  
                <div className="row">
                    { html_alert_bill_status }
                    <div className="col-xs-12">
                    <div className="pull-left">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                            </li>
                        </ul>
                        <iframe
                            id="transfer_store_bill"
                            src={"/transfer-store/print/" + this.props.transfer_store.id}
                            style={{ display: 'none' }}
                            title="Phiếu chuyển kho"
                            onLoad={() => { this.onShowPrintButton() }}
                        />
                    </div>
                    <div className="pull-right">
                        { allow_update_staff_notes === true ? 
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-success mr-right-5" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton> : "" }
                        { this.props.transfer_store.show_print_button === true ? 
                        <button type="button"
                            className="btn btn-info mr-right-5" onClick={ () => { this.onPrint() } } >
                                <i className="fa fa-print"></i>&nbsp;In phiếu
                            
                        </button> : "" }
                        { allow_update_cancel_status === true ?
                        <button type="button"
                            className="btn btn-danger" onClick={ () => { this.onCancel() } } >
                            <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                        </button> : "" }
                    </div>
                    <div className="cl"></div>
                    <div className="tab-content tab-custom-st">
                        <div id="info" className="tab-pane fade active in bg-white">
                            <div className="form-element-list" >
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Mã phiếu:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <strong>{ this.props.transfer_store.transfer_store_code }</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Thời gian:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <div className="pull-left">
                                                    { moment(transfer_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Trạng thái:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <span style={{ fontSize: 15, color: this.props.transfer_store.transfer_status_color }}>{this.props.transfer_store.transfer_status_text}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Người tạo:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.transfer_store.staff_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Kho chuyển:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    { from_store_text }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Chi nhánh:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.transfer_store.branch_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Người nhập:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.transfer_store.staff_import_name}
                                                    { this.props.transfer_store.import_receipt_id > 0 ?  <span> - <Link to={"/purchase-order/" + this.props.transfer_store.import_receipt_id}>{this.props.transfer_store.import_receipt_code}</Link></span>: ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Kho nhận:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    { to_store.store_code + " - " + to_store.store_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Chi nhánh:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {to_store.branch_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            { last_notes ? 
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label className="hrzn-fm">Ghi chú mới nhất</label>
                                                </div>
                                                <div className="col-xs-12">
                                                {last_notes_staff_name} ({moment(last_notes_time * 1000).format("YYYY-MM-DD HH:mm").toString()}): {last_notes}
                                                </div>
                                                <div className="col-xs-12">
                                                    <hr />
                                                </div>
                                            </div>  : ""   }
                                            { allow_update_staff_notes === true ? 
                                            <div className="row mr-top-10">    
                                                <TextareaAutoHeight placeholder="Thêm ghi chú" name={"transfer_notes"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                            </div> : null }
                                        </div>
                                    </div>
                                </div>    
                            </div>    
                            <div className="normal-table-list mr-top-20">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-condensed">
                                        <colgroup>
                                            <col width="5%"></col>
                                            <col width="15%"></col>
                                            <col></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Mã hàng</th>
                                                <th>Tên hàng</th>
                                                <th className="text-right">Số lượng</th>
                                                <th className="text-right">Thực tế</th>
                                                <th className="text-right">Giá nhập</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.showListProduct(products) }
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td className="text-right" colSpan="5">Tổng số lượng</td>   
                                                <td className="text-right">{ total_quantity }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan="5">Tổng số mặt hàng</td>
                                                <td className="text-right">{ total_product }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan="5">Tổng tiền</td>
                                                <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>    
                        </div>
                        <div id="note" className="tab-pane fade bg-white padd-10">
                            { staff_notes.length > 0 ?
                            <table className="table table-sc-ex table-hover table-condensed w-auto">
                                <thead>
                                    <tr>
                                        <th>Nội dung</th>
                                        <th width="10%">Thời gian</th>
                                        <th width="10%">Người tạo</th>
                                        <th width="5%">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.showListStaffNotes(staff_notes) }
                                </tbody>
                            </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                        </div>  
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
ViewTransferWarehouse.defaultProps = {
    id: 0
}
const mapStateToProps = state => {
    return {
        transfer_store: state.transfer_store
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(TransferStoreAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(TransferStoreAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(TransferStoreAction.fieldChange(field,value))
        },
        onEmptyInfo(){
            dispatch(TransferStoreAction.emptyInfo())
        },
        onGetData: id => {
            return dispatch(TransferStoreAction.getRequest(id))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(TransferStoreAction.updateRequest(data_post, id, button_name))
        },
        onGetStaffNotes: id => {
            dispatch(TransferStoreAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(TransferStoreAction.getLastStaffNoteRequest(id))
        },
        onRemoveStaffNote: (id, staff_note_id) => {
            dispatch(TransferStoreAction.removeStaffNoteRequest(id, staff_note_id))
        },
        onCancel: (id, reason_cancel) => {
            return dispatch(TransferStoreAction.cancelRequest(id, reason_cancel))
        },
    }
}
const ViewTransferWarehouseConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewTransferWarehouse));
export default withRouter(ViewTransferWarehouseConnect);