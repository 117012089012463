import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import { SelectPicker, Icon } from 'rsuite';
import * as VendorBillAction from '../../actions/VendorBillAction';
import * as UserAction from '../../actions/UserAction';
import * as VendorBillActionType from '../../constants/VendorBillActionType';
import * as UserActionType from '../../constants/UserActionType';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import PaymentFilter from '../shared/PaymentFilter';
import Helper from '../../utils/Helper';
import VendorBillStatusSelectBox from './VendorBillStatusSelectBox';
let timeoutSearchObject = null
class AccountingVendorBills extends Component {
    constructor(props){
        super(props)

        this.onCleanObject = this.onCleanObject.bind(this);
        this.onSearchObject = this.onSearchObject.bind(this);
        this.onSelectObject = this.onSelectObject.bind(this);
    }
    componentDidMount(){
        this.props.onResetListObject()
        var data_search = this.getDataSearch()
        if (data_search.search !== undefined && data_search.search === true){
            this.props.onSearchVendorBills(data_search,this.props.vendor_bill.page,this.props.vendor_bill.limit, true);
        } else {
            let search_status_default = [VendorBillActionType.STATUS_FINISH_VENDOR_BILL, VendorBillActionType.STATUS_NEW_VENDOR_BILL]
            var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
            var to_date = moment().endOf('month').format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
            this.props.onFieldChange("search_status",search_status_default);
            this.props.onSearchVendorBills({
                "from_date": search_date[0],
                "to_date": search_date[1],
                "status": search_status_default.join(",")
            },1,this.props.vendor_bill.limit, false);
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onSearchObject(keyword, event){
        if(timeoutSearchObject !== null){
            clearTimeout(timeoutSearchObject)
        }
        if (!keyword) {
          return;
        }
        let _this = this
        timeoutSearchObject = setTimeout(() => {
            var data_search = {
                "user_types": UserActionType.STAFF_USER_TYPE + "," + UserActionType.CUSTOMER_USER_TYPE + "," + UserActionType.TRANSPORTER_USER_TYPE + "," + UserActionType.PAYMENT_OBJECT_USER_TYPE,
                "keyword": keyword,
            }
            _this.props.onSearchObject(data_search, 1, _this.props.user.limit, false);
        }, 250);
    }
    onSelectObject(_,item){
        let value = {}
        if(item !== null){
            value = item
        }
        this.props.onFieldChange("object_selected", value);
    }
    onCleanObject = () => {
        this.props.onFieldChange("object_selected", {});
    }
    onPaymentFilterChange(value,_){
        this.props.onFieldChange("payment_filter_selected", value)
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _code = this.props.vendor_bill.search_code;
        let _status = this.props.vendor_bill.search_status;
        let _date = this.props.vendor_bill.search_date;
        let _customer = this.props.vendor_bill.object_selected;
        let _payment = this.props.vendor_bill.payment_filter_selected;
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        var data_search = {
            "search": false
        }
        var is_search = false
        if (_code !== ""){
            data_search["code"] = _code;
            is_search = true
    
            this.props.onFieldChange("search_status", []);
            this.props.onFieldChange("payment_filter_selected", []);
            this.props.onFieldChange("object_selected", []);
        } 
        else{
            if (_status.length > 0){
                data_search["status"] = _status.join(",");
                is_search = true
            }
            if (_date[0] !== undefined){
                data_search["from_date"] = _date[0];
                is_search = true
            }
            if (_date[1] !== undefined){
                data_search["to_date"] = _date[1];
                is_search = true
            }
            if(_customer.value !== undefined){
                data_search["partner_id"] = _customer.value;
                is_search = true
            }
            if(_payment.length > 0){
                data_search["payment_method"] = _payment.join(",");
                is_search = true
            }
        }
        data_search["has_finance_source"] = 1;
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search.search !== undefined && data_search.search === true){
            this.props.onSearchVendorBills(data_search,1,this.props.vendor_bill.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        this.props.onFieldChange("page", pageNumber)
        this.props.onSearchVendorBills(data_search,pageNumber,this.props.vendor_bill.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/accounting/vendor-bill/" + id);
    }
    showList(lists){
        /*let has_search_by_payment = false
        if(this.props.vendor_bill.payment_filter_selected !== "" && this.props.vendor_bill.is_search === true){
            has_search_by_payment = true
        }*/
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let status_text = ""
                if (item.bill_status !== VendorBillActionType.STATUS_NEW_VENDOR_BILL){
                    status_text = <label className='label' style={{backgroundColor: item.bill_status_color }}>{ item.bill_status_text }</label>
                }
                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                        <td>{item.bill_code}</td>
                        <td>{ item.object_name }</td>
                        <td>
                        <Moment format="DD/MM/YYYY HH:mm">
                        { Helper.replaceMysqlDateTime(item.bill_date) }
                        </Moment>
                        </td>
                        <td className="text-right"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.paid_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center">{status_text}</td>
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { total_amount, total_paid_amount, total, limit, total_page, page, list, search_code, object_selected, search_button_loading } = this.props.vendor_bill;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let objects_array = []
        let object_value_selected = 0
        if(object_selected.value !== undefined && object_selected.value !== null){
            object_value_selected = object_selected.value;
        }
        if (this.props.user.list.length > 0) {
            this.props.user.list.forEach(function(item){
                objects_array.push({
                    label: item.code + " - " + item.name + " - " + item.phone,
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    phone: item.phone,
                    address: item.address,
                    city: item.city,
                    district: item.district,
                    ward: item.ward,
                });
            });
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter has_default={true} value={this.props.vendor_bill.search_date} placeholder="Chọn ngày thanh toán" onFieldChange = { (value) => { this.onDatePickerChange(value) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu mua hàng" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <VendorBillStatusSelectBox multi={true}  />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <PaymentFilter multi={true} has_cod={false} value={this.props.vendor_bill.payment_filter_selected} placeholder="Phương thức thanh toán" onChange={(value,name) => this.onPaymentFilterChange(value,name) } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <SelectPicker
                                    block
                                    placeholder="Tìm đối tác giao dịch"
                                    cleanable={true}
                                    data={objects_array}
                                    value={object_value_selected}
                                    onClean={this.onCleanObject}
                                    onSearch={this.onSearchObject}
                                    onSelect={this.onSelectObject}
                                    renderMenu={menu => {
                                        if (objects_array.length === 0) {
                                            return (
                                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                <Icon icon="spinner" spin />
                                            </p>
                                            );
                                        }
                                        return menu;
                                    }}
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <h2>
                                    <div className="pull-left">Phiếu mua hàng</div>
                                    <Link to="/accounting/vendor-bill/new" className="btn btn-green pull-right"><i className="fa fa-plus"></i>&nbsp;Tạo phiếu mua hàng</Link>
                                </h2>
                                <p></p>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="7%"></col>
                                        <col></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã phiếu</th>
                                            <th>Đối tác</th>
                                            <th>Thời gian</th>
                                            <th className="text-right">Tổng tiền</th>
                                            <th className="text-right">Đã thanh toán</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                        <tr className="bg-warning">
                                            <th colSpan="3"></th>
                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_paid_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vendor_bill: state.vendor_bill,
        user: state.user,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(VendorBillAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(VendorBillAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(VendorBillAction.fieldChange(field,value))
        },
        onResetFieldSearch(){
            dispatch(VendorBillAction.resetFieldSearch())
        },
        onSearchVendorBills: (data_search,page,limit,spinner) => {
            dispatch(VendorBillAction.searchRequest(data_search,page,limit,spinner))
        },
        onGetInvoice: (id) => {
            dispatch(VendorBillAction.getRequest(id))
        },
        onItemButtonSpinner(id,status){
            dispatch(VendorBillAction.itemButtonSpinner(id,status))
        },
        onSearchObject: (data_search,page,limit,spinner) => {
            dispatch(UserAction.searchRequest(data_search,page,limit,spinner))
        },
        onResetListObject: () => {
            dispatch(UserAction.resetList())
        }
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(AccountingVendorBills)