import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Helper from '../../utils/Helper';
import MyPagination from '../shared/Pagination';
import ReportParam from './ReportParam';
import ReportProductProfitCompare from './ReportProductProfitCompare';
import moment from 'moment';

class ReportProductProfit extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_full: false
        }
    }
    componentDidMount(){
        let show_full_param = Helper.getQueryString('show_full');
        let show_full = false
        if(show_full_param !== undefined){
            if(+show_full_param === 1){
                show_full = true
            }
        }
        this.setState({
            show_full
        })
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            let allow_view_profit = false
            if (Helper.checkMyRole('view_profit') === true){
                allow_view_profit = true
            }
            result = lists.map((item, index) => {
                let size_text = ""
                if (item.product_option_name !== undefined && item.product_option_name !== null && item.product_option_name !== ""){
                size_text = <span><br />{item.product_option_name}</span>
                }
                return (
                    <tr key={ index }>
                        <td><Link to={"/product/" + item.product_id} target="_blank">{item.product_code}</Link></td>
                        <td>{ item.product_name }{size_text}</td>
                        <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.quantity_return} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.amount_return} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.total_retail} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.profit} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {allow_view_profit === true ? <td className="text-right">{item.rate}</td> : null }
                    </tr>
                );   
            });
        }
        return result;
    }
    handlePageChange(pageNumber){
        this.props.onChangePage(pageNumber)
    }
    handleShowFullInfo(){
        let show_full = !this.state.show_full
        this.props.onParseUrl(show_full)
        this.setState({
            show_full
        })
    }
    render(){
        let { list, from_date, to_date, branch_selected, params, compare_from_date, compare_to_date, compare_list, compare_before } = this.props;
        let list_report = []
        if (list !== null){
            if(list.list !== undefined && list.list !== null){
                list_report = list.list
            }
        }
        let { total_amount, total_product, total_quantity, total_quantity_return, total_amount_return, net_revenue, total_retail, total_profit, total_rate, page, total_page, total_record, limit } = list
        let final_total_page = total_page
        let branch_selected_name = ""
        if(branch_selected.name !== undefined && branch_selected.name !== null && branch_selected.name !== ""){
            branch_selected_name = branch_selected.name
        }
        let date_filter_text = (<span>Trong ngày { Helper.convertMysqlDate(from_date) }</span>)
        if (to_date !== ""){
            date_filter_text = (<span>Từ ngày  { Helper.convertMysqlDate(from_date) } đến {Helper.convertMysqlDate(to_date)}</span>)
        }
        let has_compare = false
        if(compare_from_date !== undefined && compare_from_date !== null && compare_from_date !== ""){
            has_compare = true
            var compare_from_date_text = moment(compare_from_date).format('YYYY-MM-DD'); 
            var compare_to_date_text = moment(compare_to_date).format('YYYY-MM-DD');
            date_filter_text = (<span>Trong ngày  { Helper.convertMysqlDate(from_date) }<br />so với<br />từ ngày { Helper.convertMysqlDate(compare_from_date_text) } đến { Helper.convertMysqlDate(compare_to_date_text) }</span>)
            if (to_date !== ""){
                date_filter_text = (<span><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span>&nbsp;Từ ngày  { Helper.convertMysqlDate(from_date) } đến {Helper.convertMysqlDate(to_date)}<br />so với<br /><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span>&nbsp;từ ngày { Helper.convertMysqlDate(compare_from_date_text) } đến { Helper.convertMysqlDate(compare_to_date_text) }</span>)
            }
            if(compare_list.total_page !== undefined && compare_list.total_page > 0 && compare_list.total_page > total_page){
                final_total_page = compare_list.total_page
            }
        }
        let _pagination;
        if(total_record > limit){
            _pagination = <MyPagination page={page} total_page={final_total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let allow_view_profit = false
        if (Helper.checkMyRole('view_profit') === true){
            allow_view_profit = true
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="normal-table-list"> 
                            <div className="basic-tb-hd text-center">
                                <h2>
                                    Báo cáo lợi nhuận theo hàng hóa
                                </h2>
                                <p>
                                    {date_filter_text}<br />
                                    { branch_selected_name !== "" ? <span>Chi nhánh: { branch_selected_name }</span> : "" }
                                </p>
                                {params !== null ?
                                <ReportParam params={this.props.params} onRemoveParamFilter={(param_type, index) => this.props.onRemoveParamFilter(param_type, index)} />
                                : null }
                            </div>
                                { _pagination }
                            <div className="bsc-tbl">
                                {has_compare === true ?
                                <div>
                                    <button className="btn btn-link btn-sm" onClick={() => this.handleShowFullInfo() }><i className={this.state.show_full === true?"fa fa-compress":"fa fa-expand"}></i>&nbsp;{this.state.show_full === true?"Xem rút gọn":"Xem đầy đủ"}</button>
                                    <ReportProductProfitCompare list={list} compare_list={compare_list} compare_before={compare_before} show_full={this.state.show_full} />
                                </div>
                                :
                                <table className="table table-bordered table-hover table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col></col>
                                        <col width="6%"></col>
                                        <col width="8%"></col>
                                        <col width="5%"></col>
                                        <col width="8%"></col>
                                        <col width="12%"></col>
                                        {allow_view_profit === true ? <col width="9%"></col> : null }
                                        {allow_view_profit === true ? <col width="8%"></col> : null } 
                                        {allow_view_profit === true ? <col width="8%"></col> : null } 
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className="text-middle">Mã hàng</th>
                                            <th className="text-middle">Tên hàng</th>
                                            <th className="text-right text-middle">SL bán</th>
                                            <th className="text-right text-middle">Doanh thu</th>
                                            <th className="text-right text-middle">SL trả</th>
                                            <th className="text-right text-middle">Giá trị trả</th>
                                            <th className="text-right text-middle">Doanh thu thuần</th>
                                            {allow_view_profit === true ? <th className="text-right text-middle">Tổng giá vốn</th>: null }
                                            {allow_view_profit === true ? <th className="text-right text-middle">Lợi nhuận</th> : null }
                                            {allow_view_profit === true ? <th className="text-right text-middle">Tỷ suất (%)</th> : null }
                                        </tr>
                                        <tr className="bg-warning">
                                            <th  colSpan="2">SL mặt hàng: {<NumberFormat value={total_product} displayType={'text'} thousandSeparator={true} />}</th>
                                            <th className="text-right"><NumberFormat value={total_quantity} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_quantity_return} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_amount_return} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={net_revenue} displayType={'text'} thousandSeparator={true} /></th>
                                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={total_retail} displayType={'text'} thousandSeparator={true} /></th> : null }
                                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={total_profit} displayType={'text'} thousandSeparator={true} /></th> : null }
                                            {allow_view_profit === true ? <th className="text-right">{total_rate}</th> : null }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list_report) }
                                    </tbody>
                                </table> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReportProductProfit;