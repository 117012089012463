import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as PaymentInvoiceAction from '../../actions/PaymentInvoiceAction';
import * as PaymentInvoiceActionType from '../../constants/PaymentInvoiceActionType';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
class ViewOwnerPayment extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.props.onGetPaymentInvoice(id)
        }
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onSetBillStatus(this.props.payment_invoice.id, {
                        status: PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE,
                        reason_cancel
                    }).then(result => {
                        if(result.status === true){
                            this.props.onGetPaymentInvoice(this.props.payment_invoice.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    showListBills(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let payment_bill_finance_sources = []
                item.finance_sources.forEach(financeItem => {
                    if (financeItem.is_cash === 1){
                        payment_bill_finance_sources.push(<label className="label label-info mr-right-5" key={"cash"}>Tiền mặt:&nbsp;<NumberFormat value={financeItem.amount} displayType={'text'} thousandSeparator={true} /></label>)
                    } else if (financeItem.finance_source_id > 0){
                        payment_bill_finance_sources.push(<label key={"bank_" + financeItem.finance_source_id} className="label label-info mr-right-5">{financeItem.finance_source_name}&nbsp;<NumberFormat value={financeItem.amount} displayType={'text'} thousandSeparator={true} /></label>)
                    } 
                })
                let receipt_link = "/purchase-order/" + item.import_receipt_id
                if(item.vendor_bill_id > 0){
                    receipt_link = "/accounting/vendor-bill/" + item.vendor_bill_id
                }
                return (
                    <tr  key={ index }>
                        <td><Link to={receipt_link} target="_blank">{ item.code }</Link></td>
                        <td className="text-center">{payment_bill_finance_sources}</td>
                        <td className="text-right"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { payment_bill_date, payment_bill_notes, payment_category_name, partner_name, receipts_selected } = this.props.payment_invoice
        let payment_bill_finance_sources = [], receipts = [], total_amount = 0
        if (receipts_selected !== undefined && receipts_selected !== null && receipts_selected.length > 0){
            let skip_duplicate = []
            receipts_selected.forEach(receiptItem => {
                if (receiptItem.payment_bill_finance !== undefined && receiptItem.payment_bill_finance !== null){
                    receiptItem.payment_bill_finance.forEach(financeItem => {
                        if (financeItem.is_cash === 1 && skip_duplicate["cash"] === undefined){
                            payment_bill_finance_sources.push(<label className="label label-info mr-right-5" key={"cash"}>Tiền mặt</label>)
                            skip_duplicate["cash"] = 1
                        } else if (financeItem.finance_source_id > 0 && skip_duplicate["bank_" + financeItem.finance_source_id] === undefined){
                            payment_bill_finance_sources.push(<label key={"bank_" + financeItem.finance_source_id} className="label label-info mr-right-5">{financeItem.finance_source_name}</label>)
                            skip_duplicate["bank_" + financeItem.finance_source_id] = 1
                        } 
                    })
                    receipts.push({
                        import_receipt_id: (receiptItem.import_receipt_id > 0) ? receiptItem.import_receipt_id : 0,
                        vendor_bill_id: (receiptItem.vendor_bill_id > 0) ? receiptItem.vendor_bill_id : 0,
                        code: (receiptItem.import_receipt_code !== "") ? receiptItem.import_receipt_code : receiptItem.vendor_bill_code,
                        amount: receiptItem.amount,
                        finance_sources: receiptItem.payment_bill_finance
                    })
                    total_amount += receiptItem.amount
                }
            })
        }
        let allow_update_cancel_status = false
        if (Helper.checkMyRole('update_owner_payment_cancel') === true){
            allow_update_cancel_status = true
        }
        let html_alert_bill_status = ""
        if(this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE){
            allow_update_cancel_status = false
            html_alert_bill_status = <div className="col-md-8 col-md-offset-2 col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.payment_invoice.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.payment_invoice.cancel_time }</Moment><br />
                        Lý do: { this.props.payment_invoice.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        return (
            <div className="container">
                <div className="row">
                    {html_alert_bill_status}
                    <div className="col-md-8 col-md-offset-2 col-xs-12 mr-bot-5">
                        { allow_update_cancel_status === true ?
                            <button type="button"
                                className="btn btn-danger pull-right" onClick={ () => { this.onCancel() } } >
                                <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                            </button> : "" }
                            <div className="cl"></div>
                    </div>
                    <div className="col-md-8 col-md-offset-2 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <h2>
                                Phiếu chi
                                </h2>
                                <p></p>
                            </div>
                            <div className="bsc-tbl">
                                <div className="row">
                                    <div className="col-md-10 col-md-offset-1 col-xs-12">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <div className="nk-int-mk mr-bot-5">
                                                    <h2>Ngày chi trả</h2>
                                                </div>
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <span>{ moment(payment_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>
                                                </div>
                                                <div className="nk-int-mk mr-bot-5">
                                                    <h2>Danh mục chi trả</h2>
                                                </div>
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    { payment_category_name }
                                                </div>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                <div className="row">
                                                    <div className="col-sm-6 col-xs-12">
                                                        <div className="nk-int-mk mr-bot-5">
                                                            <h2>Đối tượng chi trả</h2>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            { partner_name }   
                                                        </div>
                                                    </div>
                                                     <div className="col-sm-6 col-xs-12">
                                                            <div className="nk-int-mk mr-bot-5">
                                                                <h2>Ghi chú</h2>
                                                            </div>
                                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                                {payment_bill_notes}
                                                            </div>
                                                     </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                            <div className="nk-int-mk mr-bot-5">
                                                                <h2>Nguồn chi trả</h2>
                                                            </div>
                                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                                {payment_bill_finance_sources}
                                                            </div>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { receipts.length > 0 ? 
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="normal-table-list cl_list_product">
                                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                                    <colgroup>
                                                        <col width="10%"></col>
                                                        <col></col>
                                                        <col width="10%"></col>
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>Mã phiếu</th>
                                                            <th></th>
                                                            <th className="text-right">Số tiền</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { this.showListBills(receipts) }
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th className="text-right" colSpan="2">Tổng tiền</th>
                                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                        </tr>
                                                    </tbody>
                                                </table>    
                                            </div>       
                                        </div>
                                    </div>
                                </div> : "" } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        payment_invoice: state.payment_invoice,
        user: state.user,
        category: state.category,
        modal: state.modal
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PaymentInvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PaymentInvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PaymentInvoiceAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(PaymentInvoiceAction.emptyInfo())
        },
        onGetPaymentInvoice: (id) => {
            return dispatch(PaymentInvoiceAction.getRequest(id))
        },
        onSetBillStatus: (id, data) => {
            return dispatch(PaymentInvoiceAction.setBillStatus(id, data))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(ViewOwnerPayment)