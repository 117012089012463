import * as FinanceSourceActionType from '../constants/FinanceSourceActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: FinanceSourceActionType.FOCUS_FIELD_FINANCE_SOURCE,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: FinanceSourceActionType.ERROR_FIELD_FINANCE_SOURCE,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: FinanceSourceActionType.FIELD_CHANGE_FINANCE_SOURCE,
        field,
        value
    }
}

export const resetField = () => {
    return {
        type: FinanceSourceActionType.RESET_FIELD_FINANCE_SOURCE
    }
}

export const buttonSpinner = status => {
    return {
        type: FinanceSourceActionType.BUTTON_SPINNER_FINANCE_SOURCE,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: FinanceSourceActionType.ITEM_BUTTON_SPINNER_FINANCE_SOURCE,
        id,
        status
    }
}

export const updateRequest = (data_post, id) => {
    return (dispatch) => {
        return apiCaller.callApi(`finance-sources/${id}`,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`finance-sources/create`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(resetField())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const deleteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`categories/${id}`, null, 'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`finance-sources`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(list(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const list = (result) => {
    return {
        type: FinanceSourceActionType.LIST_FINANCE_SOURCE,
        ...result
    }
}

export const resetFieldSearch = () => {
    return {
        type: FinanceSourceActionType.RESET_FIELD_SEARCH_FINANCE_SOURCE
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: FinanceSourceActionType.SEARCH_FINANCE_SOURCE,
        search_button_loading
    }
}


export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`finance-sources/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

const get = info => {
    return {
        type: FinanceSourceActionType.GET_FINANCE_SOURCE,
        info
    }
}