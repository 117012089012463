import React, { Component } from 'react';
import { Icon } from 'rsuite';

class ReportParam extends Component {
    constructor(props){
        super(props)
        this.state = {
            params: null
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.params !== prevState.params){
            return { params: nextProps.params};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.params !== this.props.params){
            this.setState({params: this.props.params});
        } 
    } 
    componentDidMount(){
        this.setState({
            params: this.props.params
        })
    }
    handleRemoveParamFilter(param_type, index){
        this.props.onRemoveParamFilter(param_type, index)
    }
    showParam = () => {
        let params = this.state.params
        let param_element = []
        if(params.brand !== undefined && params.brand !== null && params.brand.length > 0){
            params.brand.forEach((item, index) => {
                param_element.push(
                    <div key={"brand_" + index} className="param-filter">
                        <div className="param-filter__label">
                            {item.label}
                        </div>
                        <div className="param-filter__remove">
                            <Icon icon="close" onClick={() => this.handleRemoveParamFilter('brand', index)} />
                        </div>
                    </div>
                )
            })
        }
        if(params.keyword !== undefined && params.keyword !== null && params.keyword !== ""){
            param_element.push(
                <div key={"keyword"} className="param-filter">
                    <div className="param-filter__label param-filter__keyword">
                        {params.keyword}
                    </div>
                    <div className="param-filter__remove">
                        <Icon icon="close" onClick={() => this.handleRemoveParamFilter('keyword')} />
                    </div>
                </div>
            )
        }
        return param_element
    }
    render(){
        
        return (
            <div className="mr-top-10">
                {this.showParam()}
            </div>
        );
    }
}
export default ReportParam