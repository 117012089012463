import * as LoginActionType from './../constants/LoginActionType';
import apiCaller from '../utils/apiCaller';
import Helper from '../utils/Helper';

export const focusField = (field,value) => {
    return {
        type: LoginActionType.FOCUS_FIELD_LOGIN_PAGE,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: LoginActionType.ERROR_FIELD_LOGIN_PAGE,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: LoginActionType.FIELD_CHANGE_LOGIN_PAGE,
        field,
        value
    }
}

export const resetField = () => {
    return {
        type: LoginActionType.RESET_FIELD_LOGIN_PAGE
    }
}

export const buttonSpinner = status => {
    return {
        type: LoginActionType.BUTTON_SPINNER_LOGIN_PAGE,
        status
    }
}

export const loginRequest = (info) => {
    return (dispatch) => {
        return apiCaller.callApi('user/login',info,'POST').then(res => {
            dispatch(buttonSpinner(false));
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    if(res.data.result.user.position_id === 4){
                        dispatch(getStaffTransporterRequest(res.data.result.user.id, res.data.result))
                    } else {
                        dispatch(login(res.data.result.token, res.data.result.user, res.data.result.language));
                        dispatch(setAuthenticate(true))
                    }
                    dispatch(settingsRequest())
                    dispatch(resetField());
                } 
                return {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:"",
                }
            }
        });
    }
}

export const loginByTokenRequest = token => {
    return (dispatch) => {
        return apiCaller.callApi('user/login-by-openid',{
            "sync_token": token
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(login(res.data.result.token, res.data.result.user, res.data.result.language));
                    dispatch(setAuthenticate(true))
                    dispatch(settingsRequest())
                    Helper.setBranchSelected({
                        name: res.data.result.branch_name,
                        id: res.data.result.branch_id,
                    })
                    Helper.setMyStores(res.data.result.stores)
                } 
                return {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:"",
                }
            }
        });
    }
}

export const getStaffTransporterRequest = (staff_id, user_info) => {
    return (dispatch) => {
        return apiCaller.callApi(`staff-transporter/${staff_id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(getStaffTransporter(res.data.result));
                    if(user_info !== undefined && user_info !== null && user_info.user.id > 0){
                        dispatch(login(user_info.token, user_info.user, user_info.language));
                        dispatch(setAuthenticate(true))
                    }
                }
            }
        });
    }
}

const getStaffTransporter = (transporter_info) => {
    return {
        type: LoginActionType.GET_STAFF_TRANSPORTER,
        transporter_info
    }
}

export const logoutRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi('user/logout',null,'POST').then(() => {
            //dispatch(setAuthenticate(false));
            return true
        });
    }
}

export const checkAuthenticateRequest = (id, is_ssl = false) => {
    return (dispatch) => {
        if (is_ssl === true){
            return apiCaller.callApi(`check_user_authentication.php`,{id},'GET',true).then(res => {
                var  authenticated = false;
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        dispatch(setUser(res.data.result))
                        authenticated = true;
                    }
                }
                dispatch(setAuthenticate(authenticated))
            });
        }
        return apiCaller.callApi(`user/${id}`,null,'GET').then(res => {
            var  authenticated = false;
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(setUser(res.data.result))
                    authenticated = true;
                }
            }
            dispatch(setAuthenticate(authenticated))
        });
    }
}

export const login = (token, user, language) => {
    return {
        type: LoginActionType.SET_TOKEN,
        token,
        user,
        language
    }
}

export const setAuthenticate = (authenticated) => {
    return {
        type: LoginActionType.SET_AUTHENTICATE,
        authenticated
    }
}

export const setUser = (user) => {
    return {
        type: LoginActionType.SET_USER_INFO,
        user
    }
}

export const myStoresRequest = (branch_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`my-stores`,{
            branch_id
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    Helper.setMyStores(res.data.result)
                    return res.data.result
                }
            }
        });
    }
}

const settingsRequest = () => {
    return (dispatch) => {
        Helper.setSettings({has_liabilities: false})
    }
}

export const  myBranchsRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`branchs`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                let lists = []
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    lists = res.data.result.list
                }
                return lists
            }
        });
    }
}


export const getBranchsRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`branchs/select`,{
            is_select: 1
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                let lists = []
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    lists = res.data.result.list
                }
                return lists
            }
        });
    }
}