import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import NumberFormat from 'react-number-format';
import * as InvoiceAction from '../../actions/InvoiceAction';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import Barcode from 'react-barcode';
import { DISCOUNT_MODE_BY_MONEY } from '../../constants/DiscountModeType';
import logo from '../../logo.png';
import '../../print_a5.css';

class PrintInvoice extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)
        }
    }
    onInitData(id){
        this.props.onGetInvoice(id)
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><br />(Quà tặng)</span>
                    item.price = 0
                    item.discount  = 0
                    item.discount_money = 0
                 } 
                 
                 let price_after = item.price * item.quantity
                 let discount_amount = item.discount_money
                 let discount_text = <NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} />
                 if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                    discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} /></span>
                 }
                 price_after = price_after - discount_amount
                 let total_amount = price_after
                 return (
                     <tbody key={ index } >
                        <tr>
                            <td className="text-center">{ (index + 1) }</td>
                            <td colSpan="4">{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }{gift_text}</td>
                        </tr>  
                        <tr>
                            <td></td>
                            <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="text-right">{ discount_text }</td>
                            <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>  
                     </tbody>
                 );   
            });
        }
        return result;
    }
    render(){
        let { receipt_bill_code, receipt_bill_date, products, total_amount, discount, discount_mode, other_fee, ship_fee } = this.props.invoice;
        receipt_bill_date = Helper.replaceMysqlDateTime(receipt_bill_date)
        let hide_display_discount = "hide", hide_display_ship_fee = "hide", hide_display_other_fee = "hide", hide_display = "hide"
        let total_amount_after = total_amount
        let discount_text = "0"
        if(discount > 0){
            hide_display_discount = ""
            hide_display = ""
            if(discount_mode === DISCOUNT_MODE_BY_MONEY){
                total_amount_after = total_amount_after - discount
                discount_text = <NumberFormat value={discount} displayType={'text'} thousandSeparator={true} />
            } else {
                let discount_value = total_amount_after * discount / 100
                total_amount_after = total_amount_after - discount_value
                discount_text = <span>({ discount }%) <NumberFormat value={discount_value} displayType={'text'} thousandSeparator={true} /></span>
            }
        }   
        if(other_fee > 0){
            hide_display_other_fee = ""
            hide_display = ""
            total_amount_after = total_amount_after + other_fee
        }
        if(ship_fee > 0){
            hide_display_ship_fee = ""
            hide_display = ""
            total_amount_after = total_amount_after + ship_fee
        }
        let receiver_address = this.props.invoice.receiver_address
        if(this.props.invoice.receiver_ward_selected !== undefined && this.props.invoice.receiver_ward_selected !== null && this.props.invoice.receiver_ward_selected.label !== undefined && this.props.invoice.receiver_ward_selected.label !== ""){
            receiver_address += ", " + this.props.invoice.receiver_ward_selected.label
        }
        if(this.props.invoice.receiver_district_selected !== undefined && this.props.invoice.receiver_district_selected !== null && this.props.invoice.receiver_district_selected.label !== undefined && this.props.invoice.receiver_district_selected.label !== ""){
            receiver_address += ", " + this.props.invoice.receiver_district_selected.label
        }
        if(this.props.invoice.receiver_city_selected !== undefined && this.props.invoice.receiver_city_selected !== null && this.props.invoice.receiver_city_selected.label !== undefined && this.props.invoice.receiver_city_selected.label !== ""){
            receiver_address += ", " + this.props.invoice.receiver_city_selected.label
        }
        let receiver_info = null
        if(this.props.invoice.receiver_phone !== "" && this.props.invoice.receiver_phone !== this.props.invoice.customer_phone){
            receiver_info = <tr>
                <td><strong>Người nhận:&nbsp;</strong>{this.props.invoice.receiver_name}</td>
                <td><strong>Số điện thoại:&nbsp;</strong>{this.props.invoice.receiver_phone}</td>
            </tr>
        }
        return (
                <div className="face">
                    <div className="text-center hide-print">
                        <button className="btn btn-default btn-sm" onClick={() => { window.print() }}><i className="fa fa-print"></i>&nbsp;In</button>
                    </div>
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <div className="pull-left">
                                <img alt="" src={logo} style={ { "height":"80px" } } />
                                </div>
                                <div className="pull-right text-center">
                                    <span>MOBY KID</span><br />
                                    <span>ĐIỀU TỐT NHẤT CHO CON</span><br />
                                    <span>15i Nguyễn Thị Minh Khai, Phường Bến Nghé, Quận 1, HCM</span><br />
                                    <span>344A Lê Hồng Phong, Phường 1, Quận 10, HCM</span><br />
                                    <span>Hotline: 0986.680.500 - Website: moby.com.vn</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-center">
                    <h4>PHIẾU HÓA ĐƠN</h4>
                    <div><Barcode className="text-center" width={3} height={50} font="Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif" value={receipt_bill_code} /></div>
                    Ngày  <Moment format="DD">{ receipt_bill_date }</Moment>  Tháng  <Moment format="MM">{ receipt_bill_date }</Moment>  Năm <Moment format="YYYY">{ receipt_bill_date }</Moment>
                </div>
                <div className="mr-top-10">
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td><strong>Khách hàng:&nbsp;</strong>{this.props.invoice.customer_name}</td>
                                <td><strong>Số điện thoại:&nbsp;</strong>{this.props.invoice.customer_phone}</td>
                            </tr>
                            {receiver_info}
                            <tr>
                                <td colSpan="2">{ receiver_address ? <span><strong>Địa chỉ giao hàng:&nbsp;</strong>{ receiver_address }</span> : "" }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mr-top-10">    
                    <table className="table table-sc-ex table-hover table-condensed">
                        <colgroup>
                            <col width="1%"></col>
                            <col width="15%"></col>
                            <col width="15%"></col>
                            <col width="15%"></col>
                            <col width="15%"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-right">Số lượng</th>
                                <th className="text-right">Đơn giá</th>
                                <th className="text-right">Giảm giá</th>
                                <th className="text-right">Thành tiền</th>
                            </tr>
                        </thead>
                            { this.showListProduct(products) }
                        <tbody>
                            <tr className={hide_display}>
                                <td className="text-right" colSpan="4">Tổng tiền hàng</td>
                                <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                            <tr className={hide_display_discount}>
                                <td className="text-right" colSpan="4">Giảm giá hóa đơn</td>
                                <td className="text-right">{discount_text}</td>
                            </tr>
                            <tr className={hide_display_ship_fee}>
                                <td className="text-right" colSpan="4">Phí vận chuyển</td>
                                <td className="text-right"><NumberFormat value={ship_fee} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                            <tr className={hide_display_other_fee}>
                                <td className="text-right" colSpan="4">Chi phí khác</td>
                                <td className="text-right"><NumberFormat value={other_fee} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                            <tr>
                                <td className="text-right" colSpan="4">Tổng tiền</td>
                                <td className="text-right"><NumberFormat value={total_amount_after} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                        </tbody>    
                    </table>  
                </div>   
                <div className="mr-top-10">
                    <strong>Ghi chú:</strong>
                    <div><strong>- Sản phẩm thời trang:</strong> Khách hàng vui lòng kiểm tra khi nhận hàng. Lưu ý không được mặc thử.</div>
                    <div><strong>- Thiết bị điện tử, Ghế, Địu, Xe đẩy:</strong> Khách hàng vui lòng kiểm tra thông số kĩ thuật, Phiếu Bảo Hành trước khi thanh toán. Hàng đã thanh toán không được đổi trả, chỉ áp dụng theo chính sách bảo hành.</div>
                </div>   
                <div className="mr-top-10">
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td className="text-center"><strong>Người nhận</strong></td>
                                <td className="text-center"><strong>Người giao</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.invoice,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo: () => {
            dispatch(InvoiceAction.emptyInfo())
        },
        onGetInvoice: (id) => {
            dispatch(InvoiceAction.getRequest(id))
        },
    }
}
const PrintInvoiceConnect = (connect(mapStateToProps, mapDispatchToProps)(PrintInvoice));
export default withRouter(PrintInvoiceConnect);