import * as ReportActionType from '../constants/ReportActionType';
var initialState = {
    report_view: ReportActionType.REPORT_VIEW_INFO,
    interest_type: ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS,
    is_combine_product: 0,
    from_date: "",
    to_date: "",
    branch_selected: {},
    list: [],
    revenue: null,
    daily_revenue: null,
    top_product: null,
    total: 0,
    total_page: 0,
    limit: 10,
    page: 1,
    loading: false,
    back: false,
    search_product: "",
    brand_selected: [],
};
const report = (state = initialState, action) => {
    switch(action.type){
        case ReportActionType.FIELD_CHANGE_REPORT:
            return {
                ...state,
                [action.field]: action.value
            };
        case ReportActionType.FIELDS_CHANGE_REPORT:
            return {
                ...state,
                ...action
            };
        case ReportActionType.SET_LOADING_REPORT:
            return {
                ...state,
                loading: action.status
            };
        case ReportActionType.REPORT_INFO:
            return {
                ...state,
                list: action.list
            };      
        case ReportActionType.CLEAR_REPORT:
            return {
                ...state,
                list: [],
                revenue: null,
                daily_revenue: null,
                top_product: null,
                total: 0,
                total_page: 0,
                limit: 10,
                page: 1,
                loading: false,
                back: false,
            };
        case ReportActionType.REVENUE_REPORT:
            return {
                ...state,
                revenue: action.infos
            };     
        case ReportActionType.DAILY_REVENUE:
            return {
                ...state,
                daily_revenue: action.infos
            };  
        case ReportActionType.TOP_PRODUCT_REPORT:
            return {
                ...state,
                top_product: action.infos
            };   
        default: 
            return state;
    }
}
export default report;