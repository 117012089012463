import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import * as ProductAction from '../../actions/ProductAction';
import Helper from '../../utils/Helper';

class HistoriesForProduct extends Component {
    constructor() {
        super();
        this.state = {
            product_id: 0,
            product_option_selected: 0
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.product_id !== prevState.product_id || nextProps.options !== prevState.options){
            return { product_id: nextProps.product_id};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.product_id !== this.props.product_id || prevProps.options !== this.props.options){
            this.setState({product_id: this.props.product_id});
            let my_stores = Helper.getMyStores()
            let branch_selected = Helper.getBranchSelected()
            this.props.onGetHistories(this.props.product_id,{
                "branch_id": branch_selected.id,
                "store_id": my_stores.store_id,
            })
        } 
    } 
    componentDidMount(){
        let my_stores = Helper.getMyStores()
        let branch_selected = Helper.getBranchSelected()
        this.props.onGetHistories(this.props.product_id,{
            "branch_id": branch_selected.id,
            "store_id": my_stores.store_id,
        })
    }
    onProductOptionClick(product_option_id){
        this.setState({
            product_option_selected: product_option_id
        })
    }
    onProductOptionInfoClick(product_option_info){
        let url = ""
        if (product_option_info.receipt_bill_id > 0){
            url = "/invoice/" + product_option_info.receipt_bill_id
        } else if (product_option_info.import_receipt_id > 0){
            url = "/purchase-order/" + product_option_info.import_receipt_id
        }
        if (url !== ""){
            window.open(url, "_blank");
        }
    }
    showList(lists){
        let result = []
        let product_option_selected = this.state.product_option_selected
        if(lists.length > 0){
            let tab_options = []
            lists.forEach((item, index) => {
                if (item.product_option_id !== undefined && item.product_option_id > 0){
                    let tab_active = "tab_product_option_histories btn btn-default btn-sm w-100px mr-right-5 fsize-100 "
                    if ((index === 0 && product_option_selected === 0) || (product_option_selected > 0 && product_option_selected === item.product_option_id)) {
                        tab_active = "tab_product_option_histories btn btn-primary btn-sm w-100px mr-right-5 fsize-100"
                    }
                    if (index === 0 && product_option_selected === 0) {
                        product_option_selected = item.product_option_id
                    }
                    if (item.product_option_name !== ""){
                        tab_options.push(
                            <button key={"product_option_" + item.product_option_id} type="button" className={tab_active} onClick={() => { this.onProductOptionClick(item.product_option_id) }}>{item.product_option_name}</button>
                        )
                    }
                }
            })
            if (tab_options.length > 0){
                result.push(<div key="tab_product_options" className="form-group">{tab_options}</div>)
            }
            let info_html_item = []
            lists.forEach((item, index) => {
                let hide_info = "hide"
                if ((product_option_selected > 0 && product_option_selected === item.product_option_id) || (product_option_selected === 0 && index === 0)){
                    hide_info = "cl_handover"
                }
               if (item.list !== undefined){
                item.list.forEach((info_item, info_index) => {
                    info_html_item.push(
                        <tr className={hide_info} key={"product_options_info_" + info_index} onClick={ () => { this.onProductOptionInfoClick(info_item) }}>
                            <td>{info_item.code}</td>
                            <td>{info_item.history_type_text}</td>
                            <td>
                                <Moment format="DD/MM/YYYY HH:mm">
                                { Helper.replaceMysqlDateTime(info_item.time) }
                                </Moment>    
                            </td>
                            <td>{info_item.partner_name}</td>
                            <td className="text-right"><NumberFormat value={Helper.parseMoney(info_item.retail)} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="text-right"><NumberFormat value={info_item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="text-right"><NumberFormat value={info_item.remain} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                    )
                })
               }
            })
            result.push(
                <table key="table_product_options_info" className="table table-sc-ex table-hover table-striped table-condensed">
                    <thead>
                        <tr>
                            <th>Chứng từ</th>
                            <th>Phương thức</th>
                            <th>Thời gian</th>
                            <th>Đối tác</th>
                            <th className="text-right">Giá vốn</th>
                            <th className="text-right">Số lượng</th>
                            <th className="text-right">Tồn cuối</th>
                        </tr>
                    </thead>
                    <tbody>
                    { info_html_item }
                    </tbody>
                </table>
            )
        }
        return result;
    }
    render(){
        let { histories } = this.props.product;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                    { histories.length > 0 ? 
                            <div>
                                { this.showList(histories) }
                             </div> : <div className="alert alert-warning">Không có thông tin nào</div> }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        product: state.product
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetHistories: (id, data_post) => {
            dispatch(ProductAction.getHistoriesRequest(id, data_post))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(HistoriesForProduct)