import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { RadioGroup, Radio  } from 'rsuite';
import moment from 'moment';
import * as ReportAction from '../../actions/ReportAction';
import * as ReportActionType from '../../constants/ReportActionType';
import DatePickerFilter from '../shared/DatePickerFilter';
import LoadingIndicator from '../shared/Loader';
import Helper from '../../utils/Helper';
import Swal from 'sweetalert2';
import ReportCAC from './ReportCAC';
import ReportAOV from './ReportAOV';
import ReportCRR from './ReportCRR';

class ReportOverviewBusiness extends Component {
    constructor(props){
        super(props)
        this.state = {
            from_date: "",
            to_date: "",
        }
    }
    componentDidMount(){

        let from_date = moment().startOf('month').format('YYYY-MM-DD'); 
        let to_date = moment().endOf('month').format('YYYY-MM-DD');
        
        let from_date_search = Helper.getQueryString('from_date');
        let to_date_search = Helper.getQueryString('to_date');
        let interest_type_search = parseInt(Helper.getQueryString('interest'));
        
        if(from_date_search !== undefined && from_date_search !== null && from_date_search !== ""){
            var from_date_check = moment(from_date_search);
            if (from_date_check.isValid() === true){
                from_date = from_date_search
            }
        }
        if(to_date_search !== undefined && to_date_search !== null && to_date_search !== ""){
            var to_date_check = moment(to_date_search);
            if (to_date_check.isValid() === true){
                to_date = to_date_search
            }
        } else if(from_date_search !== undefined && from_date_search !== null && from_date_search !== "" && from_date !== ""){
            to_date = ""
        }
        if (!(interest_type_search !== undefined && interest_type_search !== null && interest_type_search > 0)){
            interest_type_search = ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_AOV
        }
        this.props.onFieldChange("from_date",from_date);
        this.props.onFieldChange("to_date",to_date);
        this.props.onFieldChange("interest_type",interest_type_search);
        this.setState({
            from_date,
            to_date,
        })
        this.onViewReport(from_date, to_date)
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onReportDatePickerChange(value){
        if (value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            this.props.onFieldChange("from_date",from_date);
            this.props.onFieldChange("to_date",to_date);
            this.setState({
                from_date,
                to_date,
            })
            this.onViewReport(from_date, to_date)
        }
    }
    onInterestChange(value){
        this.props.onFieldChange('interest_type', value)
        let from_date = moment().startOf('month').format('YYYY-MM-DD'); 
        let to_date = moment().endOf('month').format('YYYY-MM-DD');
        this.props.onFieldChange("from_date",from_date);
        this.props.onFieldChange("to_date",to_date);
        this.setState({
            from_date,
            to_date,
        })
        this.onViewReport(this.state.from_date, this.state.to_date)
    }
    onViewInReport = (from_date, to_date) => {
        this.setState({
            from_date,
            to_date,
        })
        this.onViewReport(from_date, to_date)
    }
    onViewReport(from_date, to_date){
        var _this = this
        setTimeout(function(){   
            if(from_date !== ""){
                let param = []
                param.push("interest=" + _this.props.report.interest_type)
                if (from_date !== ""){
                    param.push("from_date=" + from_date)
                }
                if (to_date !== ""){
                    param.push("to_date=" + to_date)
                }
                if(param.length > 0){
                    _this.props.history.push("/report/overview-business?" + param.join("&"))
                }
                _this.props.onSetLoading(true)
                _this.props.onReport(_this.props.report.interest_type, {
                    from_date: from_date,
                    to_date: to_date,
                }).then(result => {
                    if(result.status === false && result.message !== undefined && result.message !== ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Thông báo lỗi',
                            text: result.message
                        })
                    }
                })
            }
        },250)
    }
    render(){
        let { list, report_view, interest_type, loading, from_date, to_date } = this.props.report;
        let date_filter = []
        if (from_date !== ""){
            date_filter.push(from_date)
        }
        if (to_date !== ""){
            date_filter.push(to_date)
        }
        let page_title = "Báo cáo giá trị đơn hàng trung bình (AOV)"
        let report_component = <ReportAOV list={list} report_view={report_view} from_date={this.state.from_date} to_date={this.state.to_date} />
        if(interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CAC){
            page_title = "Báo cáo chi phí chuyển đổi khách hàng (CAC)"
            report_component = <ReportCAC list={list} report_view={report_view} from_date={this.state.from_date} to_date={this.state.to_date} />
        } else if(interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CRR){
            page_title = "Báo cáo tỷ lệ khách hàng quay lại (CRR)"
            report_component = <ReportCRR list={list} report_view={report_view} from_date={this.state.from_date} to_date={this.state.to_date} />
        }
        return (
            <div className="container">
            <Helmet>
                <title>{page_title}</title>
            </Helmet>   
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="row">
                             <div className="col-xs-12">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Kiểu hiển thị</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <RadioGroup
                                                name="report_view"
                                                value={report_view}
                                                onChange={value => { this.props.onFieldChange('report_view', value) } }
                                            >
                                            <Radio checked={report_view === ReportActionType.REPORT_VIEW_CHART ? true : false}  value={ReportActionType.REPORT_VIEW_CHART} disabled> Biểu đồ</Radio>
                                            <Radio checked={report_view === ReportActionType.REPORT_VIEW_INFO ? true : false}  value={ReportActionType.REPORT_VIEW_INFO}> Báo cáo</Radio>
                                        </RadioGroup>    
                                    </div>
                                </div>
                             </div>  
                             <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Mối quan tâm</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <RadioGroup
                                                name="report_view"
                                                value={interest_type}
                                                onChange={value => { this.onInterestChange(value) }}
                                            >
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_AOV ? true : false} value={ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_AOV}> Giá trị đơn hàng trung bình (AOV)</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CAC ? true : false} value={ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CAC}> Chi phí chuyển đổi khách hàng (CAC)</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CRR ? true : false} value={ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CRR}> Tỷ lệ Khách hàng quay lại (CRR)</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CR ? true : false} value={ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CR} disabled> Tỷ lệ chuyển đổi (CR)</Radio>
                                        </RadioGroup>    
                                    </div>
                                </div>
                             </div> 
                             <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Thời gian</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <DatePickerFilter placeholder="Chọn thời gian báo cáo" has_default={true} value={date_filter} onFieldChange = { (value) => { this.onReportDatePickerChange(value) } }
                                            />
                                    </div>
                                </div>
                             </div> 
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        { loading === true ? <LoadingIndicator /> : report_component }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {    
        report: state.report
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(ReportAction.fieldChange(field,value))
        },
        onSetLoading(status){
            dispatch(ReportAction.setLoading(status))
        },
        onReport: (interest_type, data_post) => {
            return dispatch(ReportAction.reportRequest(interest_type, data_post))
        },
        onReportByChart: (data_post) => {
            dispatch(ReportAction.reportByChartRequest(data_post))
        },
    }
}

const ReportOverviewBusinessConnect = (connect(mapStateToProps, mapDispatchToProps)(ReportOverviewBusiness));
export default withRouter(ReportOverviewBusinessConnect);