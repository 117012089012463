import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Helper from '../../utils/Helper';

class ReportProductProfitCompare extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_full: false
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.show_full !== prevState.show_full){
            return { show_full: nextProps.show_full};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.show_full !== this.props.show_full){
            this.setState({show_full: this.props.show_full});
        } 
    } 
    componentDidMount(){
        this.setState({
            show_full: this.props.show_full
        })
    }
    rateValue(value, compare){
        let return_rate = 0
        if(compare > value){
            return_rate = (100 - (value / compare * 100))
            return_rate = Math.round(return_rate)
            if(this.props.compare_before === true){
                return_rate = (-1) * return_rate
            }
        } else {
            return_rate = 100 - (compare / value * 100)
            return_rate = Math.round(return_rate)
            if(this.props.compare_before === false){
                return_rate = (-1) * return_rate
            }
        }
        if(isNaN(return_rate) || return_rate === Infinity){
            return_rate = 0
        }
        return return_rate
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            let allow_view_profit = false
            if (Helper.checkMyRole('view_profit') === true){
                allow_view_profit = true
            }
            result = lists.map((item, index) => {
                let size_text = ""
                if (item.product_option_name !== undefined && item.product_option_name !== null && item.product_option_name !== ""){
                size_text = <span><br />{item.product_option_name}</span>
                }
                let compare_quantity_rate = this.rateValue(item.quantity, item.compare_quantity)
                let compare_amount_rate = this.rateValue(item.amount, item.compare_amount)
                let compare_total_amount_rate = this.rateValue(item.total_amount, item.compare_total_amount)
                let compare_quantity_return_rate = this.rateValue(item.quantity_return, item.compare_quantity_return)
                let compare_amount_return_rate = this.rateValue(item.amount_return, item.compare_amount_return)
                let compare_total_retail_rate = this.rateValue(item.total_retail, item.compare_total_retail)
                let compare_profit_rate = this.rateValue(item.profit, item.compare_profit)
                let compare_rate_value = this.rateValue(item.rate, item.compare_rate)
                return (
                    <tr key={ index }>
                        <td><Link to={"/product/" + item.product_id} target="_blank">{item.product_code}</Link></td>
                        <td>{ item.product_name }{size_text}</td>
                        <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.compare_quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right">{ compare_quantity_rate > 0 ? compare_quantity_rate + "%" : compare_quantity_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_quantity_rate * (-1)}%</span> : 0 }</td>
                        {this.state.show_full === true ? <td className="text-right"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {this.state.show_full === true ? <td className="text-right"><NumberFormat value={item.compare_amount} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {this.state.show_full === true ? <td className="text-right">{compare_amount_rate > 0 ? compare_amount_rate : <span><i className="fa fa-arrow-down text-danger"></i>{compare_amount_rate * (-1)}</span>}%</td> : null }
                        {this.state.show_full === true ? <td className="text-right"><NumberFormat value={item.quantity_return} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {this.state.show_full === true ? <td className="text-right"><NumberFormat value={item.compare_quantity_return} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {this.state.show_full === true ? <td className="text-right">{ compare_quantity_return_rate > 0 ? compare_quantity_return_rate + "%" : compare_quantity_return_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_quantity_return_rate * (-1)}%</span> : 0 }</td> : null }
                        {this.state.show_full === true ? <td className="text-right"><NumberFormat value={item.amount_return} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {this.state.show_full === true ? <td className="text-right"><NumberFormat value={item.compare_amount_return} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {this.state.show_full === true ? <td className="text-right">{ compare_amount_return_rate > 0 ? compare_amount_return_rate + "%" : compare_amount_return_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_amount_return_rate * (-1)}%</span> : 0 }</td> : null }
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.compare_total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right">{ compare_total_amount_rate > 0 ? compare_total_amount_rate + "%" : compare_total_amount_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_total_amount_rate * (-1)}%</span> : 0 }</td>
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.total_retail} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.compare_total_retail} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {allow_view_profit === true ? <td className="text-right">{ compare_total_retail_rate > 0 ? compare_total_retail_rate + "%" : compare_total_retail_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_total_retail_rate * (-1)}%</span> : 0 }</td> : null }
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.profit} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.compare_profit} displayType={'text'} thousandSeparator={true} /></td> : null }
                        {allow_view_profit === true ? <td className="text-right">{ compare_profit_rate > 0 ? compare_profit_rate + "%" : compare_profit_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_profit_rate * (-1)}%</span> : 0 }</td> : null }
                        {allow_view_profit === true && this.state.show_full === true ? <td className="text-right">{item.rate}</td> : null }
                        {allow_view_profit === true && this.state.show_full === true ? <td className="text-right">{item.compare_rate}</td> : null }
                        {allow_view_profit === true && this.state.show_full === true ? <td className="text-right">{ compare_rate_value > 0 ? compare_rate_value + "%" : compare_rate_value !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{compare_rate_value * (-1)}%</span> : 0 }</td> : null }
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { list, compare_list } = this.props;
        let { total_amount, total_quantity, total_quantity_return, total_amount_return, net_revenue, total_retail, total_profit, total_rate, total_record } = list
        let list_report = [], final_list = []
        if (list !== null){
            if(list.list !== undefined && list.list !== null){
                list_report = list.list
            }
        }
        let rate_total_amount = this.rateValue(total_amount, compare_list.total_amount), rate_total_quantity = this.rateValue(total_quantity, compare_list.total_quantity), rate_total_quantity_return = this.rateValue(total_quantity_return, compare_list.total_quantity_return), rate_total_amount_return = this.rateValue(total_amount_return, compare_list.total_amount_return), rate_net_revenue = this.rateValue(net_revenue, compare_list.net_revenue), rate_total_retail = this.rateValue(total_retail, compare_list.total_retail), rate_total_profit = this.rateValue(total_profit, compare_list.total_profit), rate_total_rate = this.rateValue(total_rate, compare_list.total_rate)
        if(list_report.length > 0){
            list_report.forEach(item => {
                if(compare_list.list !== undefined && compare_list.list !== null){
                    for(let i = 0; i < compare_list.list.length; i++){
                        let compare_item = compare_list.list[i]
                        if(compare_item.product_id === item.product_id){
                            final_list.push({
                                ...item,
                                compare_quantity: compare_item.quantity,    
                                compare_amount: compare_item.amount,
                                compare_quantity_return: compare_item.quantity_return,
                                compare_amount_return: compare_item.amount_return,
                                compare_total_amount: compare_item.total_amount,
                                compare_total_retail: compare_item.total_retail,
                                compare_profit: compare_item.profit,
                                compare_rate: compare_item.rate
                            })
                            break
                        }
                    }
                }
            })
            if(total_record < compare_list.total_record){
                compare_list.list.forEach(compare_item => {
                    let allow_add = true
                    for(let i = 0; i < final_list.length; i++){
                        if(final_list[i].product_id === compare_item.product_id){
                            allow_add = false
                            break
                        }
                    }
                    if(allow_add === true){
                        final_list.push({
                            ...compare_item,
                            quantity: 0,
                            compare_quantity: compare_item.quantity,
                            amount: 0,
                            compare_amount: compare_item.amount,
                            quantity_return: 0,
                            compare_quantity_return: compare_item.quantity_return,
                            amount_return: 0,
                            compare_amount_return: compare_item.amount_return,
                            total_amount: 0,
                            compare_total_amount: compare_item.total_amount,
                            total_retail: 0,
                            compare_total_retail: compare_item.total_retail,
                            profit: 0,
                            compare_profit: compare_item.profit,
                            rate: 0,
                            compare_rate: compare_item.rate
                        })
                    }
                })
            }
        }
        let allow_view_profit = false
        if (Helper.checkMyRole('view_profit') === true){
            allow_view_profit = true
        }
        return (
            <div className="compare-container">
                <table className="table table-bordered table-hover table-condensed">
                    <colgroup>
                        <col width="10%"></col>
                        <col></col>
                        <col width="3%"></col>
                        <col width="3%"></col>
                        <col width="6%"></col>
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        {this.state.show_full === true ? <col width="3%"></col> : null }
                        {this.state.show_full === true ? <col width="3%"></col> : null }
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        {this.state.show_full === true ? <col width="6%"></col> : null }
                        <col width="6%"></col>
                        <col width="6%"></col>
                        <col width="6%"></col>
                        {allow_view_profit === true ? <col width="6%"></col> : null }
                        {allow_view_profit === true ? <col width="6%"></col> : null }
                        {allow_view_profit === true ? <col width="6%"></col> : null }
                        {allow_view_profit === true ? <col width="6%"></col> : null } 
                        {allow_view_profit === true ? <col width="6%"></col> : null } 
                        {allow_view_profit === true ? <col width="6%"></col> : null } 
                        {allow_view_profit === true && this.state.show_full === true  ? <col width="6%"></col> : null } 
                        {allow_view_profit === true && this.state.show_full === true  ? <col width="6%"></col> : null } 
                        {allow_view_profit === true && this.state.show_full === true  ? <col width="6%"></col> : null } 
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="text-middle" rowSpan="2">Mã hàng</th>
                            <th className="text-middle" rowSpan="2">Tên hàng</th>
                            <th className="text-center text-middle" colSpan="3">SL bán</th>
                            {this.state.show_full === true ? <th className="text-center text-middle" colSpan="3">Doanh thu</th> : null } 
                            {this.state.show_full === true ? <th className="text-center text-middle" colSpan="3">SL trả</th> : null } 
                            {this.state.show_full === true ? <th className="text-center text-middle" colSpan="3">Giá trị trả</th> : null } 
                            <th className="text-center text-middle" colSpan="3">Doanh thu thuần</th>
                            {allow_view_profit === true ? <th className="text-center text-middle" colSpan="3">Tổng giá vốn</th>: null }
                            {allow_view_profit === true ? <th className="text-center text-middle" colSpan="3">Lợi nhuận</th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th className="text-center text-middle" colSpan="3">Tỷ suất (%)</th> : null }
                        </tr>
                        <tr>
                            <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th>
                            <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th>
                            <th></th>
                            {this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th> : null }
                            {this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th> : null }
                            {this.state.show_full === true ? <th>&nbsp;</th> : null }
                            {this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th> : null }
                            {this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th> : null }
                            {this.state.show_full === true ? <th>&nbsp;</th> : null }
                            {this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th> : null }
                            {this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th> : null }
                            {this.state.show_full === true ? <th>&nbsp;</th> : null }
                            <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th>
                            <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th>
                            <th>&nbsp;</th>
                            {allow_view_profit === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th> : null }
                            {allow_view_profit === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th> : null }
                            {allow_view_profit === true ? <th>&nbsp;</th> : null }
                            {allow_view_profit === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th> : null }
                            {allow_view_profit === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th> : null }
                            {allow_view_profit === true ? <th>&nbsp;</th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#007bff"}}>&nbsp;</span></th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th className="text-center text-middle"><span className="badge" style={{backgroundColor: "#17a2b8"}}>&nbsp;</span></th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th>&nbsp;</th> : null }
                        </tr>
                        <tr className="bg-warning">
                            <th  colSpan="2"></th>
                            <th className="text-right"><NumberFormat value={total_quantity} displayType={'text'} thousandSeparator={true} /></th>
                            <th className="text-right"><NumberFormat value={compare_list.total_quantity} displayType={'text'} thousandSeparator={true} /></th>
                            <th className="text-right">{ rate_total_quantity > 0 ? rate_total_quantity + "%" : rate_total_quantity !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_quantity * (-1)}%</span> : 0 }</th>
                            {this.state.show_full === true ? <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {this.state.show_full === true ? <th className="text-right"><NumberFormat value={compare_list.total_amount} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {this.state.show_full === true ? <th className="text-right">{ rate_total_amount > 0 ? rate_total_amount + "%" : rate_total_amount !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_amount * (-1)}%</span> : 0 }</th> : null }
                            {this.state.show_full === true ? <th className="text-right"><NumberFormat value={total_quantity_return} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {this.state.show_full === true ? <th className="text-right"><NumberFormat value={compare_list.total_quantity_return} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {this.state.show_full === true ? <th className="text-right">{ rate_total_quantity_return > 0 ? rate_total_quantity_return + "%" : rate_total_quantity_return !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_quantity_return * (-1)}%</span> : 0 }</th> : null }
                            {this.state.show_full === true ? <th className="text-right"><NumberFormat value={total_amount_return} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {this.state.show_full === true ? <th className="text-right"><NumberFormat value={compare_list.total_amount_return} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {this.state.show_full === true ? <th className="text-right">{ rate_total_amount_return > 0 ? rate_total_amount_return + "%" : rate_total_amount_return !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_amount_return * (-1)}%</span> : 0 }</th> : null }
                            <th className="text-right"><NumberFormat value={net_revenue} displayType={'text'} thousandSeparator={true} /></th>
                            <th className="text-right"><NumberFormat value={compare_list.net_revenue} displayType={'text'} thousandSeparator={true} /></th>
                            <th className="text-right">{ rate_net_revenue > 0 ? rate_net_revenue + "%" : rate_net_revenue !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_net_revenue * (-1)}%</span> : 0 }</th>
                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={total_retail} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={compare_list.total_retail} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {allow_view_profit === true ? <th className="text-right">{ rate_total_retail > 0 ? rate_total_retail + "%" : rate_total_retail !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_retail * (-1)}%</span> : 0 }</th> : null }
                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={total_profit} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={compare_list.total_profit} displayType={'text'} thousandSeparator={true} /></th> : null }
                            {allow_view_profit === true ? <th className="text-right">{ rate_total_profit > 0 ? rate_total_profit + "%" : rate_total_profit !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_profit * (-1)}%</span> : 0 }</th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th className="text-right">{total_rate}</th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th className="text-right">{compare_list.total_rate}</th> : null }
                            {allow_view_profit === true && this.state.show_full === true ? <th className="text-right">{ rate_total_rate > 0 ? rate_total_rate + "%" : rate_total_rate !== 0 ? <span><i className="fa fa-arrow-down text-danger"></i>{rate_total_quantity * (-1)}%</span> : 0 }</th> : null }
                        </tr>
                    </thead>
                    <tbody>
                    { this.showList(final_list) }
                    </tbody>
                </table>
            </div>
        );
    }
}
export default ReportProductProfitCompare;