import * as CategoryActionType from '../constants/CategoryActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    code: "",
    name: "",
    category_type: 0,
    bank_selected: [],
    banks: [],
    list: [],
    page: 1,
    total: 0,
    total_page: 0,
    limit: 10,
    search_keyword: "",
    search_button_loading: false,
    save_button_loading: false,
    is_edit: false
};
const category = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case CategoryActionType.ITEM_BUTTON_SPINNER_CATEGORY:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case CategoryActionType.BUTTON_SPINNER_CATEGORY:
            return {
                ...state,
                save_button_loading: action.status
            };
        case CategoryActionType.FIELD_CHANGE_CATEGORY:
              return {
                ...state,
                [action.field]: action.value
              };
        case CategoryActionType.RESET_FIELD_CATEGORY:
            return {
                ...state,
                id: 0,
                code: "",
                name: "",
                bank_selected: []
            };
        case CategoryActionType.LIST_CATEGORY:
            return {
                ...state,
                list: action.list,
                page: action.page,
                limit: action.limit,
                total: action.total,
                total_page: action.total_page
            };
        case CategoryActionType.GET_FINANCE_SOURCE_CATEGORY:
            return {
                ...state,
                banks: action.banks
            };
        case CategoryActionType.GET_CATEGORY:
            let bank_selected = []
            if (action.category.finance_sources !== null && action.category.finance_sources.length > 0){
                action.category.finance_sources.forEach(item => {
                    if(item.is_cash === 1){
                        bank_selected.push("cash")
                    } else {
                        bank_selected.push(item.finance_source_id)
                    }
                })
            }
            return {
                ...state,
                id: action.category.id,
                code: action.category.code,
                name: action.category.name,
                bank_selected
            };
        default: 
            return state;
    }
}
export default category;