import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import MyPagination from '../shared/Pagination';
import * as AddressBookAction from '../../actions/AddressBookAction';
import Swal from 'sweetalert2';
class ListAddressBook extends Component {
    componentDidMount(){
        if(this.props.user.is_search === true){
            this.handlePageChange(1)
        } else {
            this.props.onSearch({
                "user_id": this.props.user.id
            },1,this.props.address_book.limit,false);
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    getDataSearch(){
        let _name = this.props.user.search_name;
        let _phone = this.props.user.search_phone;
        let _keyword = this.props.user.search_keyword;
        var data_search = {
            "phone": _phone,
            "name": _name,
            "keyword": _keyword,
            "user_id": this.props.user.id,
            "is_admin": 1,
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search !== null){
            this.props.onSearch(data_search,1,this.props.address_book.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        this.props.onSearchCustomers(data_search,pageNumber,this.props.address_book.limit, false);
    }
    onShowEditItem(id){
        this.props.onResetField()
        this.props.onItemButtonSpinner(id,true)
        this.props.onGetData(id).then(result => {
            this.props.onItemButtonSpinner(id,false)
            this.props.onShowEdit()
        })
    }
    onRemoveItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa địa chỉ này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onDelete(id).then(result => {
                    if (result !== undefined && result.status !== undefined){
                        if (result.status === true){
                            var data_search = this.getDataSearch();
                            this.props.onSearch(data_search, this.props.address_book.page, this.props.address_book.limit, false)
                        } else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    }
                });
            },
        })
    }
    onActiveItem(id,active){
        this.props.onItemButtonSpinner(id,true);
        if(+active === 1){
            active = 0
        } else {
            active = 1
        }
        this.props.onUpdate({
            id,
            active
        }).then(result => {
            if (result !== undefined && result.status !== undefined){
                if (result.status === true){
                    var data_search = this.getDataSearch();
                    this.props.onSearch(data_search, this.props.address_book.page, this.props.address_book.limit, false)
                } else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            }
        });
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let address_full = item.address
                if(item.ward_name !== ""){
                    address_full += ", " + item.ward_name
                }
                if(item.district_name !== ""){
                    address_full += ", " + item.district_name
                }
                if(item.city_name !== ""){
                    address_full += ", " + item.city_name
                }
                let active_class = 'fa fa-eye';
                let active_text = (<span><br /><label className="label label-warning"><i className="fa fa-eye-slash"></i>&nbsp;Disabled</label></span>);
                if(+item.active === 1){
                    active_class = 'fa fa-eye-slash';
                    active_text = '';
                }
                 return (
                     <tr key={ index }>
                         <td>{ item.name }{active_text}</td>
                         <td>{ item.phone }</td>
                         <td>{ address_full }</td>
                         <td className="text-center">
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link" onClick={ () => { this.onShowEditItem(item.id) } } >
                                <i className="fa fa-edit"></i>
                            </LaddaButton>&nbsp;
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link" onClick={ () => { this.onActiveItem(item.id, item.active) } } >
                                <i className={active_class}></i>
                            </LaddaButton>&nbsp;
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link" onClick={ () => { this.onRemoveItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton>
                        </td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list } = this.props.address_book;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="normal-table-list">
                        <div className="bsc-tbl">
                            <table className="table table-sc-ex table-hover table-condensed">
                                <colgroup>
                                    <col width="15%"></col>
                                    <col width="10%"></col>
                                    <col></col>
                                    <col width="15%"></col>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>Họ tên</th>
                                        <th>Điện thoại</th>
                                        <th>Địa chỉ</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { this.showList(list) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                                { _pagination }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        address_book: state.address_book
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(AddressBookAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(AddressBookAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(AddressBookAction.fieldChange(field,value))
        },
        onResetFieldSearch(){
            dispatch(AddressBookAction.resetFieldSearch())
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(AddressBookAction.searchRequest(data_search,page,limit,spinner))
        },
        onDelete(id){
            return dispatch(AddressBookAction.deleteRequest(id))
        },
        onUpdate(data_post){
            return dispatch(AddressBookAction.updateRequest(data_post))
        },
        onItemButtonSpinner(id,status){
            dispatch(AddressBookAction.itemButtonSpinner(id,status))
        },
        onGetData(id){
            return dispatch(AddressBookAction.getRequest(id))
        },
        onResetField(){
            dispatch(AddressBookAction.resetField())
        }
    }
}
const ListAddressBookConnect = (connect(mapStateToProps, mapDispatchToProps)(ListAddressBook));
export default withRouter(ListAddressBookConnect);