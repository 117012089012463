import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import Moment from 'react-moment';
import LaddaButton, { ZOOM_IN, XL, S } from 'react-ladda';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as BillLadingActionType from '../../constants/BillLadingActionType';
import Helper from '../../utils/Helper';
import apiCaller from '../../utils/apiCaller';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import { SelectPicker } from 'rsuite';
import ShipInvoiceHeader from './ShipInvoiceHeader';
import ModalUpdateReasonPending from './ModalUpdateReasonPending';
class ViewShipInvoice extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: 0,
            time: 0,
            invoice_info: null,
            payments: null,

            bill_lading_notes: "",
            bill_lading_notes_input: "",
            change_finance_source_id: 0,
            change_finance_source_id_selected: 0,
            change_finance_source: null,
            list_card_accounts: [],
            list_bank_accounts: [],

            pending_button_loading: false,
            done_button_loading: false,
            save_info_button_loading: false,
            show_call_phone: false,
            has_get_info: false,
            show_modal_update: false,
            show_modal_pending: false
        }
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        var transporter_info = Helper.getStaffTransporter();
        if(transporter_info !== undefined && transporter_info !== null && transporter_info.id !== undefined && transporter_info.id > 0 && id > 0){
            this.props.onGetBillLading(id).then(async (bill_lading) => {
                if(bill_lading !== undefined && bill_lading !== null && bill_lading.transporter_id === transporter_info.id){
                    let invoice = await this.props.onGetInvoice(bill_lading.receipt_bill_id)
                    //console.log("invoice: ", invoice)
                    let payments = await this.props.onGetPaymentBills(invoice.id)
                    //console.log("payments: ", payments)
                    await this.getFinanceSources()
                    this.setState({
                        ...bill_lading,
                        invoice_info: {
                            ...invoice
                        },
                        payments: (payments.list !== undefined  &&  payments.list.length > 0)?payments.list:null
                    })
                }
                this.setState({
                    has_get_info: true
                })
            })
        }
    }
    getFinanceSources(){
        let _url = 'get_all_finance_sources.php'
        let is_ssl = true
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            _url = 'finance-sources'
            is_ssl = false
        }
        apiCaller.callApi(_url,{
            limit: 0,
            page: 1,
        },'GET', is_ssl).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    let change_finance_source = ""
                    if (list.length > 0){
                        let list_bank_accounts = [], list_card_accounts = []
                        list.forEach(item => {
                            let label = item.name + " - " + item.account_name + " - " + item.account_number
                            if (item.is_card === 1) {
                                list_card_accounts.push({
                                    ...item,
                                    label: label,
                                    value: item.id
                                })
                            } else if (item.is_bank === 1) {
                                list_bank_accounts.push({
                                    ...item,
                                    label: label,
                                    value: item.id
                                })
                            }
                            if(this.state.change_finance_source_id === item.id){
                                change_finance_source = {
                                    ...item,
                                    label: label,
                                    value: item.id
                                }
                            }
                        })
                        this.setState({
                            list_card_accounts,
                            list_bank_accounts,
                            change_finance_source,
                        });
                    }
                }
            }
        });
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let gift_text = ""
                if (item.is_gift === 1){
                    gift_text = <span><br />(Quà tặng)</span>
                    item.price = 0
                    item.discount  = 0
                    item.discount_money = 0
                } 
                let price_after = item.price * item.quantity
                let discount_amount = item.discount_money
                if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                }
                price_after = price_after - discount_amount
                let total_amount = price_after
                return (
                <tbody key={ index } >
                    <tr>
                        <td className="text-center">{ (index + 1) }</td>
                        <td colSpan="2">
                            <div className="product-name">
                            { item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }{gift_text}
                            </div>
                        </td>
                    </tr>  
                    <tr>
                        <td></td>
                        <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>  
                </tbody>
                );   
            });
        }
        return result;
    }
    onShowCallPhone(){
        this.setState({
            show_call_phone: !this.state.show_call_phone
        })
    }
    onBeginShip(){
        var transporter_info = Helper.getStaffTransporter();
        this.props.onBeginShip(this.state.id).then(response => {
            if(response.status !== undefined && response.status !== null){
                if(response.status === true){
                    this.props.onGetBillLading(this.state.id).then(async (bill_lading) => {
                        this.setState({
                            ...bill_lading
                        })
                    })
                } else if (response.message  !== undefined && response.message !== null){
                    if(response.message === 'duplicate_in_progress'){
                        this.props.onGetBillInProgress(transporter_info.id).then(info => {
                            //console.log("info: ", info)
                            Swal.fire({
                                title: 'Thông báo',
                                text: "Bạn đang giao hàng cho vận đơn " + info.receipt_bill_code + ", không thể tiếp tục",
                                icon: 'warning',
                                showCancelButton: true,
                                showLoaderOnConfirm: true,
                                reverseButtons: true,
                                confirmButtonText: 'XEM VẬN ĐƠN',
                                cancelButtonText: 'ĐÓNG LẠI',
                                preConfirm: async () => {
                                    this.props.history.push("/ship-invoice/" + info.id)
                                    let invoice = await this.props.onGetInvoice(info.receipt_bill_id)
                                    this.setState({
                                        ...info,
                                        invoice_info: {
                                            ...invoice
                                        }
                                    })
                                },
                            })
                        })
                    } else{
                        Swal.fire("Thông báo", response.message, "error")
                    }
                }
            } else {
                Swal.fire("Thông báo", "Đã phát sinh lỗi không xác định, vui lòng thử lại sau", "error")
            }
        })
    }
    onSavePending(data){
        let reason_pending_selected = data.ship_status_reason_option
        let other_reason_pending = data.reason_pending
        this.props.onPending(this.state.id, {
            reason_pending: other_reason_pending,
            ship_status_reason_option: reason_pending_selected
        }).then(response => {
            this.setState({
                show_modal_pending: false,
            })
            if(response.status !== undefined && response.status !== null){
                if(response.status === true){
                    this.props.onGetBillLading(this.state.id).then((bill_lading) => {
                        this.setState({
                            ...bill_lading
                        })
                    })
                } else if (response.message  !== undefined && response.message !== null){
                    Swal.fire("Thông báo", response.message, "error")
                }
            } else {
                Swal.fire("Thông báo", "Đã phát sinh lỗi không xác định, vui lòng thử lại sau", "error")
            }
        }) 
    }
    onFinish(){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn hoàn tất giao hàng vận đơn này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onFinish(this.state.id).then((result) => {
                    if(result.status !== undefined){
                        if(result.status === true){
                            this.props.onGetBillLading(this.state.id).then((bill_lading) => {
                                this.setState({
                                    ...bill_lading
                                })
                            })
                        } else if(result.message !== undefined && result.message !== null){
                            Swal.fire("Thông báo lỗi", result.message, "error")
                        }
                    }
                })   
            },
        })
    }
    onRemoveShip(){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn bỏ đơn này khỏi danh sách giao hàng?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveShip(this.state.id).then((result) => {
                    if(result.status !== undefined){
                        if(result.status === true){
                            this.props.history.push("/ship-invoices")
                        } else if(result.message !== undefined && result.message !== null){
                            Swal.fire("Thông báo lỗi", result.message, "error")
                        }
                    }
                })   
            },
        })
    }
    onSaveInfo(){
        //console.log("this.state.change_finance_source_id_selected: ", this.state.change_finance_source_id_selected)
        //console.log("this.state.bill_lading_notes_input: ", this.state.bill_lading_notes_input)
        let change_finance_source_id = this.state.change_finance_source_id_selected
        let change_to_cash = 0
        if(change_finance_source_id === -1){
            change_finance_source_id = 0
            change_to_cash = 1
        }
        let bill_lading_notes = this.state.bill_lading_notes_input
        let data_post = null
        if(change_finance_source_id > 0 || bill_lading_notes !== ""){
            data_post = {}
            if(change_finance_source_id > 0){
                data_post.change_finance_source_id = change_finance_source_id
            }
            if(change_to_cash > 0){
                data_post.change_to_cash = change_to_cash
            }
            if(bill_lading_notes !== ""){
                data_post.bill_lading_notes = bill_lading_notes
            }
        }
        if (data_post !== null){
            //console.log("data_post: ", data_post)
            this.setState({
                save_info_button_loading: true
            })
            this.props.onSaveInfo(this.state.id, data_post).then(result => {
                this.setState({
                    save_info_button_loading: false,
                    bill_lading_notes_input: "",
                    change_finance_source_id_selected: 0
                })
                if(result !== undefined && result !== null &&  result.status !== undefined &&  result.status === true){
                    let change_finance_source = null
                    if(change_finance_source_id > 0){
                        this.state.list_bank_accounts.forEach(item => {
                            if(change_finance_source_id === item.value){
                                change_finance_source = item
                            }
                        })
                        if(change_finance_source === null){
                            this.state.list_card_accounts.forEach(item => {
                                if(change_finance_source_id === item.value){
                                    change_finance_source = item
                                }
                            })
                        }
                    }
                    this.setState({
                        change_finance_source_id,
                        change_finance_source,
                        change_to_cash,
                        bill_lading_notes
                    })
                    this.onShowHideModalUpdate()
                }
            })
        }
    }
    onSelectChangeFinanceSource(value){
        this.setState({
            change_finance_source_id_selected: value
        })
    }
    onCleanChangeFinanceSource(){
        this.setState({
            change_finance_source_id_selected: 0
        })
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            [name]: value
        })
    }
    onShowHideModalUpdate(){
        this.setState({
            show_modal_update: !this.state.show_modal_update
        })
    }
    onShowHideModalPending(){
        this.setState({
            show_modal_pending: !this.state.show_modal_pending
        })
    }
    render(){
        let {pending_button_loading, done_button_loading, save_info_button_loading, id, has_get_info, time, payments, list_card_accounts, list_bank_accounts, change_to_cash, change_finance_source, change_finance_source_id, change_finance_source_id_selected, bill_lading_notes} = this.state
        if (has_get_info === true &&  id === 0){
            return <Redirect to="/ship_invoices" />
        }
        let receiver_address = null, receiver_name = "", receiver_phone = "", receipt_bill_code = "", receipt_bill_date = "", customer_name = "", customer_phone = "", ship_status = -1, staff_update_status_name = "", ship_status_color = "", ship_status_text = "", ship_status_time  = 0, ship_status_reason = ""
        let products = []

        let hide_display_discount = "hide", hide_display_ship_fee = "hide", hide_display_other_fee = "hide", hide_display = "hide"
        let total_amount_after = 0, total_amount = 0, discount = 0, discount_money = 0, other_fee, ship_fee = 0
        let discount_text = "0"
        if(this.state.id > 0){
            ship_status = this.state.ship_status
            receiver_name = this.state.receiver_name
            receiver_phone = this.state.receiver_phone
            receiver_address = this.state.receiver_address
            receipt_bill_code = this.state.receipt_bill_code
            receipt_bill_date = this.state.receipt_bill_date
            customer_name = this.state.customer_name
            customer_phone = this.state.customer_phone
            staff_update_status_name = this.state.staff_update_status_name
            ship_status_color = this.state.ship_status_color
            ship_status_text = this.state.ship_status_text
            ship_status_reason = this.state.ship_status_reason
            ship_status_time = this.state.ship_status_time
            products = this.state.invoice_info.products
            total_amount = this.state.invoice_info.total_amount
            discount = this.state.invoice_info.discount
            discount_money = this.state.invoice_info.discount_money
            other_fee = this.state.invoice_info.other_fee
            ship_fee = this.state.invoice_info.ship_fee
            total_amount_after = total_amount
            if(discount > 0 || discount_money > 0){
                hide_display_discount = ""
                hide_display = ""
                if(discount_money > 0){
                    total_amount_after = total_amount_after - discount
                    discount_text = <NumberFormat value={discount_money} displayType={'text'} thousandSeparator={true} />
                } else if (discount > 0){
                    let discount_value = total_amount_after * discount / 100
                    total_amount_after = total_amount_after - discount_value
                    discount_text = <span>({ discount }%) <NumberFormat value={discount_value} displayType={'text'} thousandSeparator={true} /></span>
                }
            }   
            if(other_fee > 0){
                hide_display_other_fee = ""
                hide_display = ""
                total_amount_after = total_amount_after + other_fee
            }
            if(ship_fee > 0){
                hide_display_ship_fee = ""
                hide_display = ""
                total_amount_after = total_amount_after + ship_fee
            }
        }
        let show_action_button = true
        if(ship_status === BillLadingActionType.STATUS_CANCEL_SHIP_BILL_LADINGS || ship_status === BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS){
            show_action_button = false
        }
        let status_text = <div>
            <label className="label ship-status-label" style={{backgroundColor: ship_status_color}}>{ship_status_text}</label>
            {ship_status_reason !== "" ?
            <span className="small"><br/>{ship_status_reason}</span> : "" }
        </div>
        if(ship_status === BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS){
            status_text = <div className="alert alert-success">
                Đã giao hàng lúc <Moment format="DD/MM/YYYY HH:mm" unix>
                { ship_status_time }
                </Moment>
            </div>
        } else if(ship_status === BillLadingActionType.STATUS_CANCEL_SHIP_BILL_LADINGS){
            status_text = <div className="alert alert-danger">
                {staff_update_status_name} đã hủy giao hàng lúc <Moment format="DD/MM/YYYY HH:mm" unix>
                { ship_status_time }
                </Moment>
            </div>
        }
        //console.log("change_to_cash: ",change_to_cash)
        //console.log("change_finance_source_id: ",change_finance_source_id)
        let has_is_cash = false
        let show_text_change_payment = true
        let payment_method_text = null
        if(payments !== null &&  payments.length > 0){
            //console.log(payments)
            payment_method_text = []
            let amount_finance_source_bank = 0, amount_finance_source_card = 0
            payments.forEach(payment_item => {
                if(payment_item.payment_bill_finance !== null && payment_item.payment_bill_finance.length > 0){
                    payment_item.payment_bill_finance.forEach((finance_source_item, finance_source_index) => {
                        if(finance_source_item.is_cod === 1){
                            has_is_cash = true
                            payment_method_text.push(<span key={finance_source_index} className="payment-finance-source">Thu hộ: {Helper.parseMoneyText(finance_source_item.amount)}đ</span>)
                            if(change_to_cash === 1){
                                show_text_change_payment = false
                            }
                        } else if (finance_source_item.is_cash === 1){
                            has_is_cash = true
                            payment_method_text.push(<span key={finance_source_index} className="payment-finance-source">Tiền mặt: {Helper.parseMoneyText(finance_source_item.amount)}đ</span>)
                            if(change_to_cash === 1){
                                show_text_change_payment = false
                            }
                        } else if(finance_source_item.is_bank === 1){
                            amount_finance_source_bank += finance_source_item.amount
                        } else if(finance_source_item.is_card === 1){
                            amount_finance_source_card += finance_source_item.amount
                        }
                        if(change_finance_source_id === finance_source_item.id){
                            show_text_change_payment = false
                        }
                    })
                }
            })
            if(amount_finance_source_bank > 0){
                payment_method_text.push(<span key="by_bank" className="payment-finance-source">Chuyển khoản: {Helper.parseMoneyText(amount_finance_source_bank)}đ</span>)
            }
            if(amount_finance_source_card > 0){
                payment_method_text.push(<span key="by_card" className="payment-finance-source">Quẹt thẻ: {Helper.parseMoneyText(amount_finance_source_bank)}đ</span>)
            }
        }
        let finance_sources_array = []
        if(has_is_cash === false){
            finance_sources_array.push({
                label: "Thu bằng tiền mặt",
                value: -1
            })
        }
        if(list_bank_accounts.length > 0){
            list_bank_accounts.forEach(item => {
                finance_sources_array.push({
                    label: "Chuyển  khoản: " + item.label,
                    value: item.value
                })
            })
        }
        if(list_card_accounts.length > 0){
            list_card_accounts.forEach(item => {
                finance_sources_array.push({
                    label: "Quẹt thẻ: " + item.label,
                    value: item.value
                })
            })
        }
        //console.log("show_text_change_payment: ",show_text_change_payment)
        let change_payment_text = ""
        if(show_text_change_payment === true){
            if(change_to_cash === 1){
                change_payment_text = "Đổi thanh toán bằng tiền mặt"
            } else if (change_finance_source !== undefined && change_finance_source !== null && change_finance_source.value > 0){
                change_payment_text = "Đổi thanh toán bằng "
                if (change_finance_source.is_card === 1) {
                    change_payment_text += " quẹt thẻ "
                } else if (change_finance_source.is_bank === 1) {
                    change_payment_text += " chuyển khoản "
                }
                change_payment_text += change_finance_source.label
            }
        }
        return (
            <div className="bill-ladings">
                <ShipInvoiceHeader back={true} innerRef={e => this.ship_invoice_header_ref = e}/>
                <table className="table">
                    <colgroup>
                        <col width="160"></col>
                        <col></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <td><strong>Hóa đơn bán hàng:</strong></td>
                            <td>{receipt_bill_code}</td>
                        </tr>
                        {receipt_bill_date !== "" ? 
                        <tr>
                            <td><strong>Ngày mua hàng:</strong></td>
                            <td><Moment format="DD/MM/YYYY HH:mm">
                                { Helper.replaceMysqlDateTime(receipt_bill_date) }
                                </Moment></td>
                        </tr> : null }
                        <tr>
                            <td><strong>Ngày giao hàng:</strong></td>
                            <td><Moment format="DD/MM/YYYY HH:mm" unix>
                                { time }
                                </Moment></td>
                        </tr>
                        {customer_name !== receiver_name ?
                        <tr>
                            <td><strong>Khách hàng:</strong></td>
                            <td>{customer_name}</td>
                        </tr> : null }
                        {customer_phone !== receiver_phone ?
                        <tr>
                            <td><strong>Điện thoại:</strong></td>
                            <td>{customer_phone}</td>
                        </tr> : null }
                        <tr>
                            <td><strong>Người nhận:</strong></td>
                            <td>{receiver_name}</td>
                        </tr>
                        <tr>    
                            <td><strong>Điện thoại:</strong></td>
                            <td>{receiver_phone}</td>
                        </tr>
                        <tr>
                            <td colSpan="2"><strong>Địa chỉ giao hàng:</strong></td>
                        </tr>
                        {receiver_address !== "" ? 
                        <tr>
                            <td colSpan="2">{receiver_address}</td>
                        </tr> : null }
                        {payment_method_text !== null && payment_method_text.length > 0 ?
                        <tr>
                            <td colSpan="2">
                                <div style={{marginBottom: 16}}>
                                    <strong>Phương thức thanh toán:</strong>
                                </div>
                                {payment_method_text}
                            </td>
                        </tr> : null }
                        { show_text_change_payment === true ?
                        <tr>
                            <td colSpan="2">
                                <strong>{change_payment_text}</strong>
                            </td>
                        </tr> : null }
                        { bill_lading_notes !== "" ?
                        <tr>
                            <td colSpan="2">
                                <strong>Ghi chú:</strong> {bill_lading_notes}
                            </td>
                        </tr> : null }
                        <tr>
                            <td colSpan="2" className="text-center">
                                { status_text }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="text-center">
                                <h5>HÀNG HÓA</h5>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <table className="table table-sc-ex table-hover table-condensed">
                                    <colgroup>
                                        <col width="1%"></col>
                                        <col width="15%"></col>
                                        <col width="15%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className="text-center">STT</th>
                                            <th className="text-right">Số lượng</th>
                                            <th className="text-right">Thành tiền</th>
                                        </tr>
                                    </thead>
                                        { this.showListProduct(products) }
                                    <tbody>
                                        <tr className={hide_display}>
                                            <td className="text-right" colSpan="2">Tổng tiền hàng</td>
                                            <td className="text-right"><NumberFormat value={Helper.parseMoney(total_amount)} displayType={'text'} thousandSeparator={true} /></td>
                                        </tr>
                                        <tr className={hide_display_discount}>
                                            <td className="text-right" colSpan="2">Giảm giá hóa đơn</td>
                                            <td className="text-right">{discount_text}</td>
                                        </tr>
                                        <tr className={hide_display_ship_fee}>
                                            <td className="text-right" colSpan="2">Phí vận chuyển</td>
                                            <td className="text-right"><NumberFormat value={Helper.parseMoney(ship_fee)} displayType={'text'} thousandSeparator={true} /></td>
                                        </tr>
                                        <tr className={hide_display_other_fee}>
                                            <td className="text-right" colSpan="2">Chi phí khác</td>
                                            <td className="text-right"><NumberFormat value={Helper.parseMoney(other_fee)} displayType={'text'} thousandSeparator={true} /></td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Tổng tiền</td>
                                            <td className="text-right"><NumberFormat value={Helper.parseMoney(total_amount_after)} displayType={'text'} thousandSeparator={true} /></td>
                                        </tr>
                                    </tbody>    
                                </table> 
                            </td>
                        </tr>
                    </tbody>
                </table>
                { show_action_button === true ? <div>
                    {<div className={"call-phone-container update-info"} onClick={() => this.onShowHideModalUpdate()}>
                        <i className="fa fa-pencil fa-2x"></i>
                    </div>}
                {customer_phone !== receiver_phone ?
                <div className={"call-phone-container" + (this.state.show_call_phone === true ? " close-phone" : "")} onClick={() => this.onShowCallPhone()}>
                    <i className="call-phone fa fa-phone fa-2x"></i>
                </div>
                : <a href={"tel: +84" + receiver_phone} className="call-phone-container">
                        <i className="call-phone fa fa-phone fa-2x"></i>
                    </a> }
                {customer_phone !== receiver_phone ?
                <a href={"tel: +84" + receiver_phone} className={"call-phone-container-item" + (this.state.show_call_phone === true ? " first" : "")}>
                    <div className="call-phone-bg pull-right">
                        <i className="call-phone fa fa-mobile fa-2x"></i>
                    </div>
                    <div className={"phone-number pull-right" + (this.state.show_call_phone === true ? " show" : "")}>{receiver_name}</div>
                </a> : null }
                {customer_phone !== receiver_phone ?
                    <a href={"tel: +84" + customer_phone}  className={"call-phone-container-item" + (this.state.show_call_phone === true ? " second" : "")}>
                        <div className="call-phone-bg pull-right">
                        <i className="call-phone fa fa-mobile fa-2x"></i>
                    </div>
                    <div className={"phone-number pull-right" + (this.state.show_call_phone === true ? " show" : "")}>{customer_name}</div>
                    </a>
                : null } </div> : null }
                {show_action_button === true && ship_status > -1 ? 
                <div className="btn-footer">
                    { ship_status === BillLadingActionType.STATUS_SHIP_IN_PROGRESS_BILL_LADINGS ?
                    <div>
                        <div className="pull-left w-50">
                            <LaddaButton
                                loading={ pending_button_loading }
                                data-size={XL}
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-warning btn-block notika-btn-success waves-effect" onClick={ () => { this.onShowHideModalPending() } } >
                                <i className="fa fa-ban"></i>&nbsp;TẠM NGỪNG
                            </LaddaButton>
                        </div>
                        <div className="pull-left w-50">
                            <LaddaButton
                                loading={ done_button_loading }
                                data-size={XL}
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onFinish() } } >
                                <i className="fa fa-check"></i>&nbsp;ĐÃ GIAO
                            </LaddaButton>
                        </div>
                    </div> : 
                        <div>
                            <div className="pull-left w-40">
                                <LaddaButton
                                    loading={ pending_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-danger btn-block notika-btn-success waves-effect" onClick={ () => { this.onRemoveShip() } } >
                                    <i className="fa fa-remove"></i>&nbsp;BỎ ĐƠN
                                </LaddaButton>
                            </div>
                            <div className="pull-left w-60">
                                <LaddaButton
                                    loading={ done_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-info btn-block notika-btn-success waves-effect" onClick={ () => { this.onBeginShip() } } >
                                    <i className="fa fa-truck"></i>&nbsp;BẮT ĐẦU GIAO
                                </LaddaButton>
                            </div>
                        </div> 
                    }
                </div> : null }
                <div className={this.state.show_modal_update === true ? "modal display-block" : "modal display-none"}>
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cập nhật thông tin vận đơn</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.onShowHideModalUpdate()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="control-label">Đổi phương thức thanh toán</label>
                                <SelectPicker
                                    block
                                    placeholder="Chọn một phương thức thanh toán"
                                    searchable={false}
                                    data={finance_sources_array}
                                    value={change_finance_source_id_selected}
                                    onSelect={this.onSelectChangeFinanceSource.bind(this)}
                                    onClean={this.onCleanChangeFinanceSource.bind(this)}
                                    renderMenu={menu => {
                                        return menu;
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Ghi chú giao hàng</label>
                                <textarea name="bill_lading_notes_input" value={this.state.bill_lading_notes_input} className="form-control bill-lading-notes" onChange={ (e) => { this.onFieldChange(e) } }></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <LaddaButton
                                loading={ save_info_button_loading }
                                data-size={S}
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-info btn-save-bill-lading-info" onClick={ () => { this.onSaveInfo() } } >
                                <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                            </LaddaButton>
                            <button type="button" className="btn btn-secondary btn-close-bill-lading-info" data-dismiss="modal" onClick={() => this.onShowHideModalUpdate()}><i className="fa fa-ban"></i>&nbsp;Đóng</button>
                        </div>
                        </div>
                    </div>
                </div>
                { ship_status === BillLadingActionType.STATUS_SHIP_IN_PROGRESS_BILL_LADINGS ?
                    <ModalUpdateReasonPending show={this.state.show_modal_pending} onShowHide={() => this.onShowHideModalPending() } onSave={(data) => this.onSavePending(data)} />
                : null }
            </div>   
        );
    }
}

const mapStateToProps = state => {
    return {
        bill_lading: state.bill_lading,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetBillLading: (id) => {
            return dispatch(BillLadingAction.getRequest(id, true))
        },
        onGetBillInProgress: (user_id) => {
            return dispatch(BillLadingAction.getBillInProgressRequest(user_id, true))
        },
        onGetInvoice: (id) => {
            return dispatch(InvoiceAction.getRequest(id, false, true))
        },
        onGetPaymentBills: id => {
            return dispatch(InvoiceAction.getPaymentBillsRequest(id, 0, true, true))
        },
        onBeginShip: id => {
            return dispatch(BillLadingAction.setBillLadingStatus(id, {status : BillLadingActionType.STATUS_SHIP_IN_PROGRESS_BILL_LADINGS}, true))
        },
        onPending: (id, reason_pending) => {
            return dispatch(BillLadingAction.setBillLadingStatus(id, {
                status: BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS,
                ...reason_pending
            }, true))
        },
        onFinish: (id) => {
            return dispatch(BillLadingAction.setBillLadingStatus(id,{
                status: BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS
            }, true))
        },
        onRemoveShip: (id) => {
            return dispatch(BillLadingAction.updateRequest({
                "deleted": 1
            }, id, "pending_button_loading", true))
        },
        onSaveInfo: (id, data_post) => {
            return dispatch(BillLadingAction.updateRequest(data_post, id, "save_info_button_loading", true))
        }
    }
}
const ViewShipInvoiceConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewShipInvoice));
export default withRouter(ViewShipInvoiceConnect);