import React, { Component } from 'react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';

class Modal extends Component {
    render(){
        let showHideClassName = this.props.show ? "modal display-block" : "modal display-none";
        let modalTypeClassName = this.props.type ? "modal-dialog " + this.props.type : "modal-dialog";
        return(
            <div className={showHideClassName}>
                <div className={modalTypeClassName}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="pull-right">
                            <LaddaButton
                                loading={ this.props.spinner }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-primary notika-btn-success waves-effect" onClick={ this.props.handleSubmit } >
                                <i className="fa fa-save"></i>&nbsp;Lưu
                            </LaddaButton>
                        </div><div className="cl"></div>
                        {this.props.children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.handleClose}><i className="fa fa-ban"></i>&nbsp;Đóng</button>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Modal;