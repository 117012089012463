import * as InvoiceActionType from '../constants/InvoiceActionType';
import * as DiscountModeType from '../constants/DiscountModeType';
import * as PaymentMethodType from '../constants/PaymentMethodType';
import moment from 'moment';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    is_reorder: 0,
    is_pending_import: 0,
    receipt_bill_code: "",
    receipt_bill_date: "",
    receipt_bill_notes: "",
    staff_notes_input: "",
    staff_notes_active: false,
    staff_notes_error: false,
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    receipt_bill_status_select: [],
    receipt_bill_status_color_select: [],
    receipt_bill_status: 0,
    receipt_bill_status_text: "",
    receipt_bill_status_color: "",
    receipt_bill_payment_status: 0,
    receipt_bill_payment_status_text: "",
    receipt_bill_payment_status_color: "",
    receipt_bill_ship_status_select: [],
    receipt_bill_ship_status_color_select: [],
    receipt_bill_ship_status: 0,
    receipt_bill_ship_status_text: "",
    receipt_bill_ship_status_color: "",
    customer_id: 0,
    customer_code: "",
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    customer_city: 0,
    customer_district: 0,
    customer_ward: 0,

    has_shipping: false,
    has_real_ship_info: false,
    has_cod: false,
    transporter_selected: [],
    transporter_id_error: false,
    transporter_name: "",
    transporter_bill_number: "",
    transporter_bill_number_active: false,
    transporter_bill_number_error: false,
    address_book_selected: {},
    receiver_name: "", 
    receiver_name_active: false,
    receiver_name_error: false,
    receiver_phone: "", 
    receiver_phone_active: false,
    receiver_phone_error: false,
    receiver_address: "", 
    receiver_address_active: false,
    receiver_address_error: false,
    receiver_city_selected: [], 
    receiver_city_error: false,
    receiver_district_selected: [], 
    receiver_district_error: false,
    receiver_ward_selected: [], 
    receiver_ward_error: false,
    weight: 0,
    weight_kg: 0,
    weight_active: false,
    weight_unit: {
      label: "gram",
      value: InvoiceActionType.WEIGHT_UNIT_GRAM
    }, // gram
    length: 0,
    length_active: false,
    width: 0,
    width_active: false,
    height: 0,
    height_active: false,
    pick_up_address: [],
    delivery_type: 0,
    delivery_type_text: "",
    delivery_check_type: 0,
    delivery_check_type_text: "",
    receiver_pay_fee: 0,
    money_collect_cod: 0,
    customer_selected: [],
    total_quantity: 0,
    total_amount: 0,
    ship_fee: 0,
    other_fee: 0,
    ship_fee_shop_paid_return: 0,
    total_discount: 0,
    total_after_discount: 0,
    total_payment_amount: 0,
    total_paid_amount_by_method: 0,
    total_amount_return: 0,
    total_quantity_origin: 0,
    total_ship_fee: 0,
    total_ship_fee_shop_paid: 0,
    discount: 0,
    discount_mode: DiscountModeType.DISCOUNT_MODE_BY_MONEY, // giảm theo số tiền
    payment_method: PaymentMethodType.PAYMENT_METHOD_BY_CASH, // 1: tiền mặt, 2: thẻ, 3: chuyển khoản
    payment_method_text: "",
    payment_method_info: [],
    payment_amount: 0,
    liabilities_amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    staff_finish_id: 0,
    staff_finish_name: "",
    finish_time: 0,
    branch_id: 0,
    branch_name: "",
    products: [],
    product_returns: [],
    payment_bills: [],
    ship_logs: [],
    staff_notes: [],
    return_bill_id: 0,
    return_bill_code: "",
    return_receipt_bill_id: 0,
    return_receipt_bill_code: "",
    total: 0,
    limit: 15,
    total_page: 0,
    page: 1,
    list: [],
    customers: [],
    search_code: "",
    search_product: "",
    search_date: [],
    search_status: [],
    search_customer_selected: [],
    sell_channel_selected: {},
    branch_selected: {},
    search_has_return: 0,
    search_has_ship_fee: 0, 
    search_ship_fee_shop_paid: 0, 
    search_ship_fee_customer_paid: 0,
    receipt_return_selected: {},
    payment_filter_selected: "",
    payment_text_filter_selected: "",
    search_button_loading: false,
    save_button_loading: false,
    save_date_button_loading: false,
    save_note_button_loading: false,
    save_seller_button_loading: false,
    save_channel_button_loading: false,
    save_ship_button_loading: false,
    save_button_copy_loading: false,
    is_search: false,
    direct_to_list: false,
    show_print_button: false,
    has_discount_receipt: false,
};
const invoice = (state = initialState, action) => {
    let index = -1;
    let products  
    switch(action.type){
        case InvoiceActionType.ITEM_BUTTON_SPINNER_INVOICE:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case InvoiceActionType.BUTTON_SPINNER_INVOICE:
            return {
                ...state,
                save_button_loading: action.status
            };
        case InvoiceActionType.BUTTON_UPDATE_SPINNER_INVOICE:
          return {
              ...state,
              [action.button_name]: action.status
          };
        case InvoiceActionType.FOCUS_FIELD_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case InvoiceActionType.ERROR_FIELD_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case InvoiceActionType.FIELD_CHANGE_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case InvoiceActionType.FIELDS_CHANGE_INVOICE:
              return {
                ...state,
                ...action.fields
              };
        case InvoiceActionType.EMPTY_INVOICE:      
          return {
            ...state,
            id: 0,
            is_reorder: 0,
            is_pending_import: 0,
            receipt_bill_code: "",
            receipt_bill_date: "",
            receipt_bill_notes: "",
            receipt_bill_notes_active: false,
            receipt_bill_status: 0,
            receipt_bill_status_text: "",
            receipt_bill_status_color: "",
            receipt_bill_payment_status: 0,
            receipt_bill_payment_status_text: "",
            receipt_bill_payment_status_color: "",
            receipt_bill_ship_status: 0,
            receipt_bill_ship_status_text: "",
            receipt_bill_ship_status_color: "",
            customer_id: 0,
            customer_code: "",
            customer_name: "",
            customer_phone: "",
            customer_address: "",
            customer_city: 0,
            customer_district: 0,
            customer_ward: 0,
          
            customer_selected: [],
            has_shipping: false,
            has_real_ship_info: false,
            has_cod: false,
            transporter_selected: [],
            transporter_id_error: false,
            transporter_name: "",
            transporter_bill_number: "",
            transporter_bill_number_active: false,
            transporter_bill_number_error: false,
            address_book_selected: {},
            receiver_name: "", 
            receiver_name_active: false,
            receiver_name_error: false,
            receiver_phone: "", 
            receiver_phone_active: false,
            receiver_phone_error: false,
            receiver_address: "", 
            receiver_address_active: false,
            receiver_address_error: false,
            receiver_city_selected: [], 
            receiver_city_error: false,
            receiver_district_selected: [], 
            receiver_district_error: false,
            receiver_ward_selected: [], 
            receiver_ward_error: false,
            weight: 0,
            weight_kg: 0,
            weight_active: false,
            weight_unit: {
              label: "gram",
              value: InvoiceActionType.WEIGHT_UNIT_GRAM
            }, // gram
            length: 0,
            length_active: false,
            width: 0,
            width_active: false,
            height: 0,
            height_active: false,
            pick_up_address: [],
            delivery_type: 0,
            delivery_check_type: 0,
            receiver_pay_fee: 0,
            money_collect_cod: 0,
            
            total_quantity: 0,
            total_amount: 0,
            ship_fee: 0,
            other_fee: 0,
            ship_fee_shop_paid_return: 0,
            total_discount: 0,
            total_after_discount: 0,
            total_payment_amount: 0,
            total_amount_return: 0,
            total_quantity_origin: 0,
            total_ship_fee: 0,
            total_ship_fee_shop_paid: 0,
            discount: 0,
            discount_mode: DiscountModeType.DISCOUNT_MODE_BY_MONEY, // giảm theo số tiền
            payment_method: PaymentMethodType.PAYMENT_METHOD_BY_CASH, // 1: tiền mặt, 2: thẻ, 3: chuyển khoản
            payment_method_text: "",
            payment_method_info: [],
            payment_amount: 0,
            liabilities_amount: 0,
            return_fee: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            staff_finish_id: 0,
            staff_finish_name: "",
            finish_time: 0,
            branch_id: 0,
            branch_name: "",
            return_bill_id: 0,
            return_bill_code: "",
            return_receipt_bill_id: 0,
            return_receipt_bill_code: "",
            products: [],
            product_returns: [],
            payment_bills: [],
            ship_logs: [],
            total: 0,
            limit: 15,
            total_page: 0,
            list: [],
            customers: [],
            save_button_loading: false,
            save_date_button_loading: false,
            save_note_button_loading: false,
            save_seller_button_loading: false,
            save_channel_button_loading: false,
            save_ship_button_loading: false,
            is_search: false,
            direct_to_list: false,
            show_print_button: false,
            has_discount_receipt: false,
          };
        case InvoiceActionType.RESET_FIELD_SEARCH_INVOICE:     
          var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
          var to_date = moment().endOf('month').format('YYYY-MM-DD');
          var search_date = [];
          if(from_date !== ""){
              search_date.push(from_date)
          }
          if(to_date !== ""){
              search_date.push(to_date)
          } 
            return {
              ...state,
              search_code: "",
              search_product: "",
              search_date,
              customer_selected: [],
              search_status: [],
              payment_filter_selected: "",
              payment_text_filter_selected: "",
              search_has_return: 0,
              search_has_ship_fee: 0, 
              search_ship_fee_shop_paid: 0, 
              search_ship_fee_customer_paid: 0,
              is_search: false,
              total: 0,
              limit: 15,
              total_page: 0,
              page: 1,
              list: [],
            };
        case InvoiceActionType.CLEAR_SHIPPING_INFO:      
            return {
              ...state,
              has_shipping: false,
              has_real_ship_info: false,
              has_cod: false,
              transporter_selected: [],
              transporter_id_error: false,
              transporter_name: "",
              transporter_bill_number: "",
              transporter_bill_number_active: false,
              transporter_bill_number_error: false,
              address_book_selected: {},
              receiver_name: "", 
              receiver_name_active: false,
              receiver_name_error: false,
              receiver_phone: "", 
              receiver_phone_active: false,
              receiver_phone_error: false,
              receiver_address: "", 
              receiver_address_active: false,
              receiver_address_error: false,
              receiver_city_selected: [], 
              receiver_city_error: false,
              receiver_district_selected: [], 
              receiver_district_error: false,
              receiver_ward_selected: [], 
              receiver_ward_error: false,
              weight: 0,
              weight_kg: 0,
              weight_active: false,
              weight_unit: {
                label: "gram",
                value: InvoiceActionType.WEIGHT_UNIT_GRAM
              }, // gram
              length: 0,
              length_active: false,
              width: 0,
              width_active: false,
              height: 0,
              height_active: false,
              pick_up_address: [],
              delivery_type: 0,
              delivery_check_type: 0,
              receiver_pay_fee: 0,
            };
        case InvoiceActionType.GET_INVOICE:
            let new_state = {
              id: action.invoice.id,
              is_reorder: action.invoice.is_reorder,
              is_pending_import: parseInt(action.invoice.is_pending_import),
              receipt_bill_code: action.invoice.receipt_bill_code,
              receipt_bill_date: action.invoice.receipt_bill_date,
              receipt_bill_notes: action.invoice.receipt_bill_notes,
              receipt_bill_notes_active: true,
              receipt_bill_status: action.invoice.receipt_bill_status,
              receipt_bill_status_text: action.invoice.receipt_bill_status_text,
              receipt_bill_status_color: action.invoice.receipt_bill_status_color,
              receipt_bill_payment_status: action.invoice.receipt_bill_payment_status,
              receipt_bill_payment_status_text: action.invoice.receipt_bill_payment_status_text,
              receipt_bill_payment_status_color: action.invoice.receipt_bill_payment_status_color,
              receipt_bill_ship_status: action.invoice.receipt_bill_ship_status,
              receipt_bill_ship_status_text: action.invoice.receipt_bill_ship_status_text,
              receipt_bill_ship_status_color: action.invoice.receipt_bill_ship_status_color,
              customer_selected: {
                id: action.invoice.customer_id,
                code: action.invoice.customer_code,
                name: action.invoice.customer_name,
                phone: action.invoice.customer_phone,
                address: action.invoice.customer_address,
                city: action.invoice.customer_city,
                city_name: action.invoice.customer_city_name,
                district: action.invoice.customer_district,
                district_name: action.invoice.customer_district_name,
                ward: action.invoice.customer_ward,
                ward_name: action.invoice.customer_ward_name,
              },
              customer_id: action.invoice.customer_id,
              customer_code: action.invoice.customer_code,
              customer_name: action.invoice.customer_name,
              customer_phone: action.invoice.customer_phone,
              customer_city: action.invoice.customer_city,
              customer_district: action.invoice.customer_district,
              customer_ward: action.invoice.customer_ward,
            }  
            if (action.invoice.shipping_info !== undefined && action.invoice.shipping_info !== null) {
              let has_shipping = false, has_real_ship_info = false, has_cod = false
              let transporter_selected = {}
              let receiver_pay_fee = 0
              if (action.is_return === false){
                if (action.invoice.shipping_info.transporter_id > 0){
                  has_shipping = true
                  has_real_ship_info = true
                  has_cod = !!(action.invoice.money_collect_cod > 0)
                  transporter_selected = {
                    label: action.invoice.shipping_info.transporter_name,
                    value: action.invoice.shipping_info.transporter_id,
                    is_staff: action.invoice.shipping_info.is_staff_transporter
                  }
                }
                receiver_pay_fee = action.invoice.shipping_info.receiver_pay_fee
              } else{
                new_state.receipt_bill_notes = ""
              }
              new_state = {
                ...new_state,
                has_shipping,
                has_real_ship_info,
                has_cod,
                transporter_selected,
                transporter_name: action.invoice.shipping_info.transporter_name,
                transporter_bill_number: action.invoice.shipping_info.transporter_bill_number,
                transporter_bill_number_active: !!(action.invoice.shipping_info.transporter_bill_number !== ""),
                receiver_name: action.invoice.shipping_info.receiver_name, 
                receiver_name_active: !!(action.invoice.shipping_info.receiver_name !== ""),
                receiver_phone: action.invoice.shipping_info.receiver_phone, 
                receiver_phone_active: !!(action.invoice.shipping_info.receiver_phone !== ""),
                receiver_address: action.invoice.shipping_info.receiver_address, 
                receiver_address_active: !!(action.invoice.shipping_info.receiver_address !== ""),
                receiver_city_selected: (action.invoice.shipping_info.receiver_city > 0) ? {
                  label: action.invoice.shipping_info.receiver_city_name,
                  value: action.invoice.shipping_info.receiver_city
                } : {}, 
                receiver_district_selected: (action.invoice.shipping_info.receiver_district > 0) ? {
                  label: action.invoice.shipping_info.receiver_district_name,
                  value: action.invoice.shipping_info.receiver_district
                } : {}, 
                receiver_ward_selected: (action.invoice.shipping_info.receiver_ward > 0) ? {
                  label: action.invoice.shipping_info.receiver_ward_name,
                  value: action.invoice.shipping_info.receiver_ward
                } : {}, 
                weight: action.invoice.shipping_info.weight,
                weight_kg: action.invoice.shipping_info.weight_kg,
                weight_active: !!(action.invoice.shipping_info.weight > 0),
                weight_unit: {
                  label: "gram",
                  value: InvoiceActionType.WEIGHT_UNIT_GRAM
                }, // gram
                length: action.invoice.shipping_info.length,
                length_active: !!(action.invoice.shipping_info.length > 0),
                width: action.invoice.shipping_info.width,
                width_active: !!(action.invoice.shipping_info.width > 0),
                height: action.invoice.shipping_info.height,
                height_active: !!(action.invoice.shipping_info.height > 0),
                pick_up_address: (action.invoice.shipping_info.pick_up_address_id > 0) ? {
                  label: action.invoice.shipping_info.pick_up_address,
                  value: action.invoice.shipping_info.pick_up_address_id 
                } : {},
                delivery_type: action.invoice.shipping_info.delivery_type,
                delivery_type_text: action.invoice.shipping_info.delivery_type_text,
                delivery_check_type: action.invoice.shipping_info.delivery_check_type,
                delivery_check_type_text: action.invoice.shipping_info.delivery_check_type_text,
                receiver_pay_fee,
                money_collect_cod: action.invoice.money_collect_cod,
              }
            }
            let total_quantity = 0, total_amount = action.invoice.total_amount, ship_fee = action.invoice.ship_fee, other_fee = action.invoice.other_fee,
            total_discount = 0, total_after_discount = 0
            let total_quantity_origin = 0, total_amount_origin = 0, total_quantity_return = 0,  total_amount_return = action.invoice.total_amount_return
            action.invoice.products.forEach(function(item){
              total_quantity += item.quantity
            })

            let payment_method = 0
            if (action.invoice.is_cash === 1){
              payment_method = PaymentMethodType.PAYMENT_METHOD_BY_CASH
            }
            let discount = (action.invoice.discount > 0) ? action.invoice.discount : action.invoice.discount_money
            products = action.invoice.products
            let product_returns = []
            let has_discount_receipt = false
            let discount_receipt = 0

            let discount_amount = action.invoice.discount_money
            if (action.invoice.discount > 0){
                discount_amount = action.invoice.total_amount * action.invoice.discount / 100
            }
            if(discount_amount > 0){
              let total_discount_origin = discount_amount
              action.invoice.products.forEach(itemSelected => {
                let price = itemSelected.price
                let discount_amount_item = itemSelected.discount_money
                if (itemSelected.discount > 0){
                  discount_amount_item = price * itemSelected.discount / 100
                }
                if ((price - discount_amount_item) > 0){
                  total_quantity_origin += itemSelected.quantity
                }
              })
              if(total_quantity_origin > 0 && discount_amount > 0){
                discount_receipt = total_discount_origin / total_quantity_origin
              }
              total_quantity_origin = 0
            }
            if (discount_receipt > 0){
                has_discount_receipt = true
            }
            for (let _product_key = 0; _product_key < products.length; _product_key++){
              products[_product_key].discount_receipt = discount_receipt
            }
            let ship_fee_shop_paid_return = action.invoice.ship_fee_shop_paid_return
            if (action.is_return === true){
                ship_fee_shop_paid_return = action.invoice.ship_fee_shop_paid
                total_quantity = 0
                total_amount = 0
                ship_fee = 0
                other_fee = 0
                total_discount = 0
                total_after_discount = 0
                total_amount_origin = 0
                discount = 0
                products = []
                action.invoice.products.forEach(itemSelected => {
                  let key = itemSelected.id + '_' + itemSelected.product_option_id
                  let price = itemSelected.price
                  discount_amount = itemSelected.discount_money
                  if (itemSelected.discount > 0){
                      discount_amount = price * itemSelected.discount / 100
                  }
                  //price = price - discount_amount - discount_receipt
                  price = price - discount_amount
                  let remainQuantity = itemSelected.quantity - itemSelected.quantity_return
                  let has_return = true
                  if (!(remainQuantity > 0)){
                    has_return = false
                  }
                  price = Math.round(price, -3)
                  total_quantity_origin += remainQuantity
                  total_amount_origin += price * remainQuantity
                  product_returns.unshift({
                      "key": key,
                      "id": itemSelected.id,
                      "product_option_id": itemSelected.product_option_id,
                      "product_option_name": itemSelected.product_option_name,
                      "url": itemSelected.product_url,
                      "name": itemSelected.product_name,
                      "model": itemSelected.product_model,
                      "sku": itemSelected.product_sku,
                      "has_return": has_return,
                      "price": price, 
                      "retail": itemSelected.retail,
                      "quantity": remainQuantity,
                      "quantity_return_selected": 0,
                      "total_amount_return": 0,
                      "categories": itemSelected.categories,
                      "category_id": itemSelected.category_id,
                      "category_name": itemSelected.category_name,
                      "brand_id": itemSelected.brand_id,
                      "brand_name": itemSelected.brand_name
                  })  
                })
            } 
            return {
                ...state,
                ...new_state,
                discount,
                discount_mode: (action.invoice.discount_money > 0) ? DiscountModeType.DISCOUNT_MODE_BY_MONEY : DiscountModeType.DISCOUNT_MODE_BY_PERCENT, // mặc định giảm theo số tiền
                payment_method, // 1: tiền mặt, 2: thẻ, 3: chuyển khoản
                payment_method_text: "",
                payment_method_info: [],
                payment_amount: 0,
                liabilities_amount: 0,
                staff_id: action.invoice.staff_id,
                staff_name: action.invoice.staff_name,
                staff_cancel_id: action.invoice.staff_cancel_id,
                staff_cancel_name: action.invoice.staff_cancel_name,
                reason_cancel: action.invoice.reason_cancel,
                cancel_time: action.invoice.cancel_time,
                staff_finish_id: action.invoice.staff_finish_id,
                staff_finish_name: action.invoice.staff_finish_name,
                finish_time: action.invoice.finish_time,
                branch_id: action.invoice.branch_id,
                branch_name: action.invoice.branch_name,
                return_bill_id: action.invoice.return_bill_id,
                return_bill_code: action.invoice.return_bill_code,
                return_receipt_bill_id: action.invoice.return_receipt_bill_id,
                return_receipt_bill_code: action.invoice.return_receipt_bill_code,
                products: products,
                product_returns: product_returns,
                total_quantity,
                total_amount: total_amount,
                ship_fee: ship_fee,
                other_fee: other_fee,
                ship_fee_shop_paid_return,
                total_discount: total_discount,
                total_after_discount: total_after_discount,
                total_quantity_return,
                total_amount_return,
                total_quantity_origin,
                total_amount_origin,
                has_discount_receipt,

                sell_channel_selected: (action.invoice.sell_channel > 0) ? {
                  label: action.invoice.sell_channel_text,
                  value: action.invoice.sell_channel
                } : {},
            };
        case InvoiceActionType.GET_SHIP_STATUS_INVOICE:
            let ship_status_array = [], ship_status_color_array = []
            Object.keys(action.list).map((k) => {
              ship_status_array.push({
                  label: action.list[k],
                  value: k
              })
              return true
            })
            Object.keys(action.color).map((k) => {
              ship_status_color_array.push({
                  label: action.color[k],
                  value: k
              })
              return true
            })
          return {  
              ...state,
              receipt_bill_ship_status_select: ship_status_array,
              receipt_bill_ship_status_color_select: ship_status_color_array
          }; 
        case InvoiceActionType.GET_BILL_STATUS_INVOICE:
            let bill_status_array = [], bill_status_color_array = []
            Object.keys(action.list).map((k) => {
              bill_status_array.push({
                  label: action.list[k],
                  value: k
              })
              return true
            })
            Object.keys(action.color).map((k) => {
              bill_status_color_array.push({
                  label: action.color[k],
                  value: k
              })
              return true
            })
          return {  
              ...state,
              receipt_bill_status_select: bill_status_array,
              receipt_bill_status_color_select: bill_status_color_array
          };  
        case InvoiceActionType.LIST_INVOICE_STATUS:
          return {
              ...state,
              receipt_bill_status_select: action.status
            };
        case InvoiceActionType.GET_PAYMENT_BILLS_INVOICE:
          return {  
              ...state,
              payment_bills: action.payment_bills,
              total_payment_amount: action.total_payed
          };  
        case InvoiceActionType.GET_STAFF_NOTES_INVOICE:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };  
        case InvoiceActionType.GET_SHIP_LOG_INVOICE:
          return {  
              ...state,
              ship_logs: action.lists
          };       
        case InvoiceActionType.GET_LAST_STAFF_NOTES_INVOICE:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case InvoiceActionType.LIST_INVOICE:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount: action.total_amount,
                total_after_discount: action.total_amount_after_discount,
                total_payment_amount: action.total_paid_amount,
                total_discount: action.total_discount,
                total_paid_amount_by_method: action.total_paid_amount_by_method,
                total_amount_return: action.total_amount_return,
                total_ship_fee: action.total_ship_fee,
                total_ship_fee_shop_paid: action.total_ship_fee_shop_paid,
                list: [...action.list],
                direct_to_list: false
              };
        case InvoiceActionType.SEARCH_INVOICE:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case InvoiceActionType.CLEAR_PRODUCTS_INVOICE:
          return {
            ...state,
            products: []
          };
        case InvoiceActionType.SELECT_PRODUCT_INVOICE:
          products = state.products;
          let itemSelected = action.item
          if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            let key = itemSelected.id + '_' + itemSelected.product_option_id
            let index = Helper.findIndexByKey(products, key);
            if(index > -1){
              products[index].quantity = parseInt(products[index].quantity) + 1
              let amount = products[index].price * products[index].quantity
              if(products[index].discount > 0){
                let discount_value = products[index].discount
                if(products[index].discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                  discount_value = Helper.parseMoney(amount * products[index].discount / 100)
                }
                amount = amount - discount_value
              }
              products[index].total_amount = amount
            } else {
                products.unshift({
                    "key": key,
                    "id": itemSelected.id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_option_name": itemSelected.product_option_name,
                    "url": itemSelected.url,
                    "name": itemSelected.name,
                    "model": itemSelected.model,
                    "sku": itemSelected.sku,
                    "price": itemSelected.price,
                    "price_save": itemSelected.price,
                    "quantity": 1,
                    "discount_value": 0,
                    "discount": 0,
                    "discount_mode": DiscountModeType.DISCOUNT_MODE_BY_MONEY,
                    "total_amount": itemSelected.price,
                    "remain": itemSelected.remain,
                    "unit": itemSelected.unit,
                    "categories": itemSelected.categories,
                    "category_id": itemSelected.category_id,
                    "category_name": itemSelected.category_name,
                    "brand_id": itemSelected.brand_id,
                    "brand_name": itemSelected.brand_name,
                    "is_gift": itemSelected.is_gift,
                })
            }
          }
          return {
            ...state,
            products: [...products]
          }; 
        case InvoiceActionType.UPDATE_PRODUCTS_INVOICE: 
            return {
              ...state,
              products: [...action.products]
            };   
        case InvoiceActionType.UPDATE_PRODUCT_INVOICE: 
          index = Helper.findIndexByKey(state.products, action.key);
          if (index > -1){
            let product = state.products[index]
            if (action.input_name === "quantity"){
              product.quantity = action.value
            } else if (action.input_name === "price"){
              product.price = action.value
            } else if (action.input_name === "discount"){
              product.discount = action.value
            } else if (action.input_name === "discount_mode"){
              product.discount = 0;
              product.discount_value = 0
              product.discount_mode = action.value
            } else if (action.input_name === "is_gift"){
              product.is_gift = action.value
              product.discount = 0;
              product.discount_value = 0
              product.discount_mode = DiscountModeType.DISCOUNT_MODE_BY_MONEY
              if(action.value === 1){
                product.price = 0
              }
              else{
                product.price = product.price_save
              }
            } 
            if(product.is_gift === 1){
              product.price = 0
              product.discount = 0;
              product.discount_value = 0
              product.discount_mode = DiscountModeType.DISCOUNT_MODE_BY_MONEY
            } 
            let amount = product.price * product.quantity
            if(product.discount > 0){
              let discount_value = product.discount
              if(product.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                discount_value = Helper.parseMoney(amount * product.discount / 100)
              }
              product.discount_value = discount_value
              amount = amount - discount_value
            }
            product.total_amount= amount
            state.products[index] = product
          }
          return {
            ...state
          };   
          case InvoiceActionType.SUM_TOTAL_INVOICE:
              state.total_amount = 0
              state.total_quantity = 0
              if(state.products.length > 0){
                let total_amount = 0, total_quantity = 0
                state.products.forEach(function(item){
                  total_amount += item.total_amount
                  total_quantity += parseInt(item.quantity)
                });
                state.total_amount = total_amount
                state.total_quantity = total_quantity
              }
              return {
                ...state
              };  
        case InvoiceActionType.UPDATE_PRODUCT_RETURN_INVOICE: 
          index = Helper.findIndexByKey(state.product_returns, action.key);
          if (index > -1){
            let product = state.product_returns[index]
            if (action.input_name === "quantity_return_selected"){
              product.quantity_return_selected = parseInt(action.value)
            }
            let amount = product.price * product.quantity_return_selected
            if(product.discount > 0){
              let discount_value = product.discount
              if(product.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                discount_value = Helper.parseMoney(amount * product.discount / 100)
              }
              product.discount_value = discount_value
              amount = amount - discount_value
            }
            product.total_amount_return = amount
            state.product_returns[index] = product
          }
          return {
            ...state
          };
          case InvoiceActionType.SUM_TOTAL_RETURN_INVOICE:
              state.total_amount_return = 0
              state.total_quantity_return = 0
              //console.log("state.product_returns: ",state.product_returns)
              if(state.product_returns.length > 0){
                let total_amount_return = 0, total_quantity_return = 0
                state.product_returns.forEach(function(item){
                  total_amount_return += item.total_amount_return
                  total_quantity_return += parseInt(item.quantity_return_selected)
                });
                state.total_amount_return = total_amount_return
                state.total_quantity_return = total_quantity_return
              }
              return {
                ...state
              };  
        case InvoiceActionType.RESET_FLAG_SEARCH_INVOICE:
          return {
            ...state,
            is_search: action.status
          };       
        case InvoiceActionType.DIRECT_TO_LIST_INVOICE:
          return {
            ...state,
            direct_to_list: action.status
          };     
        default: 
            return state;
    }
}
export default invoice;