import React, { Component } from 'react';
import { Whisper, Popover, ButtonToolbar, Button, Icon, Divider } from 'rsuite';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Helper from '../../utils/Helper';
import apiCaller from '../../utils/apiCaller';
const triggerRef = React.createRef();
const PAYMENT_MODE_CASH = 1;
const PAYMENT_MODE_CARD = 2;
const PAYMENT_MODE_BANK = 3;
class PaymentInput extends Component {
    constructor() {
        super();
        this.amounted = true
        this.payAmountInput = [];
        this.state = { 
            mode: PAYMENT_MODE_CASH, 
            cards: [],
            banks: [],
            amount: "",
            cash_source_id: 0,
            payment_text: "",
            list_card_accounts: [],
            list_bank_accounts: []
        };
    }
    componentWillUnmount(){
        this.amounted = false
    }
    componentDidMount(){
        apiCaller.callApi(`finance-sources`,{
            limit: 0,
            page: 1,
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    if (list.length > 0){
                        let cash_source_id = 0
                        let list_bank_accounts = [], list_card_accounts = []
                        list.forEach(item => {
                            let label = item.name + " - " + item.account_name + " - " + item.account_number
                            if (item.is_cash === 1) {
                                cash_source_id = item.id
                            } else if (item.is_card === 1) {
                                list_card_accounts.push({
                                    label: label,
                                    value: item.id
                                })
                            } else if (item.is_bank === 1) {
                                list_bank_accounts.push({
                                    label: label,
                                    value: item.id
                                })
                            }
                        })
                        let card_selected = [], bank_selected = []
                        if (list_card_accounts.length === 1){
                            card_selected = [{
                                amount: 0,
                                account: {
                                    label: list_card_accounts[0].label,
                                    value: list_card_accounts[0].value,
                                }
                            }]
                        }
                        if (list_bank_accounts.length === 1){
                            bank_selected = [{
                                amount: 0,
                                account: {
                                    label: list_bank_accounts[0].label,
                                    value: list_bank_accounts[0].value,
                                }
                            }]
                        }
                        if (this.amounted === true){
                            this.setState({
                                cash_source_id: cash_source_id,
                                list_card_accounts: list_card_accounts,
                                cards: card_selected,
                                list_bank_accounts: list_bank_accounts,
                                banks: bank_selected,
                            });
                        }
                    }
                }
            }
        });
    }
    onSelectPayMode(value){
        this.setState({
            mode: value
        });
    }
    onSelectAccountChange(account_type, opt, index){
        //console.log("account index: ",index);
        //console.log("opt: ",opt);
        if(opt != null){
            let lists = []
            if (account_type === PAYMENT_MODE_CARD){
               lists = this.state.cards
            } else if (account_type === PAYMENT_MODE_BANK){
               lists = this.state.banks
            }
           if(lists.length > 0){ 
                let duplicate = false;
                for (let i = 0; i < lists.length; i++) {
                    if(opt.value === lists[i].account.value && i !== index){
                        duplicate = true;
                        break;
                    }
                }
                //console.log("duplicate: ",duplicate);
                if(duplicate === true){
                    let accountRestore = []
                    if(typeof lists[index].account.value !== "undefined"){
                        accountRestore = lists[index].account
                    }
                    lists[index].account = accountRestore
                } else {
                    for (let i = 0; i < lists.length; i++) {
                        if(index === i){
                            lists[index].account = opt
                            break;
                        }
                    }
                }

                if (account_type === PAYMENT_MODE_CARD){
                    this.setState({
                        cards: lists
                    });
                } else if (account_type === PAYMENT_MODE_BANK){
                    this.setState({
                        banks: lists
                    });
                }
           }
        }
    }
    onRemovePaymentItem(account_type,index){
        //console.log("remove index: ",index);
        let lists = []
        if (account_type === PAYMENT_MODE_CARD){
           lists = [...this.state.cards]
        } else if (account_type === PAYMENT_MODE_BANK){
            lists = [...this.state.banks]
        }
        //console.log("remove lists: ",lists);
        if(lists.length > 0){
            let lists_after = lists.slice(0, index).concat(lists.slice(index + 1, lists.length))
            //console.log("after remove lists: ",lists_after);
           if (account_type === PAYMENT_MODE_CARD){
                this.setState({
                    cards: [...lists_after]
                });
            } else if (account_type === PAYMENT_MODE_BANK){
                this.setState({
                    banks: [...lists_after]
                });
            }
        }
    }
    onPaymentAmountFieldChange(account_type,index,values){
        const {value} = values;
        let lists = []
        if (account_type === PAYMENT_MODE_CARD){
           lists = [...this.state.cards]
        } else if (account_type === PAYMENT_MODE_BANK){
            lists = [...this.state.banks]
        }
        if(lists.length > 0){
            for (let i = 0; i < lists.length; i++) {
                if(index === i){
                    lists[index].amount = value
                    break;
                }
            }
            if (account_type === PAYMENT_MODE_CARD){
                this.setState({
                    cards: [...lists]
                });
            } else if (account_type === PAYMENT_MODE_BANK){
                this.setState({
                    banks: [...lists]
                });
            }
        }
        //console.log("amount index: ",index);
        //console.log("value: ",value);
    }
    onCashAmountFieldChange(values){
        const {value} = values;
        this.setState({
            amount: value
        });
    }
    onAddMorePayment(account_type){
        let lists = []
        if (account_type === PAYMENT_MODE_CARD){
           lists = [...this.state.cards]
        } else if (account_type === PAYMENT_MODE_BANK){
           lists = [...this.state.banks]
        }
        lists.push({
            account: [],
            amount: 0
        })
        if (account_type === PAYMENT_MODE_CARD){
            this.setState({
                cards: [...lists]
            });
        } else if (account_type === PAYMENT_MODE_BANK){
            this.setState({
                banks: [...lists]
            });
        }
    }
    onParsePaymentList(account_type){
        let result = null
        let accounts = [], source_accounts = []
        if (account_type === PAYMENT_MODE_CARD){
            accounts = this.state.cards
            source_accounts = this.state.list_card_accounts
        } else if (account_type === PAYMENT_MODE_BANK){
            accounts = this.state.banks
            source_accounts = this.state.list_bank_accounts
        }
        if (accounts.length > 0){
            result = accounts.map((item, index) => {
                return (
                    <div className="form-group" key={index}>
                        <div className="pull-left" style={{marginTop: 7}}>
                            <Button size="xs"><Icon icon="trash" onClick={() => { this.onRemovePaymentItem(account_type,index) }}  /></Button>
                        </div>
                        <div className="pull-left mr-left-10" style={{width: "53%"}}>
                                <Select styles={Helper.setSelectBoxStyles()} options={source_accounts}
                                        formatOptionLabel={Helper.formatOptionLabel}
                                        isSearchable
                                        className="cl_select_box"
                                        menuPlacement="bottom"
                                        value={item.account}
                                        onChange={(opt, meta) => this.onSelectAccountChange(account_type, opt, index)}
                                        placeholder="Chọn tài khoản" />
                        </div>
                        <div className="pull-left mr-left-10" style={{width: "30%"}}>
                            <div className="nk-int-st nk-toggled">
                                <NumberFormat className="form-control text-right" getInputRef = {(el) => this.payAmountInput[index] = el} thousandSeparator={true} prefix={""} value={item.amount} onValueChange={(values) => this.onPaymentAmountFieldChange(account_type,index,values)} />
                            </div>
                        </div>
                        <div className="cl"></div>
                    </div>
                );   
            });
        }
        return result;
    }
    onSubmitPayment() {
        let payment_text_array = [];
        let cards = this.state.cards;
        let banks = this.state.banks;
        let finance_sources = []
        if(this.state.amount > 0){
            payment_text_array.push("Tiền mặt")
            finance_sources.push({
                "finance_source_id": this.state.cash_source_id,
                "amount": parseFloat(this.state.amount),
                "is_cash": 1,
                "is_cod": 0
            })
        }
        if(cards.length > 0){
            for (let i = 0; i < cards.length; i++) {
                if(cards[i].amount > 0){
                    if (payment_text_array.findIndex(k => k === 'Thẻ') === -1) {
                        payment_text_array.push("Thẻ")
                    }
                    finance_sources.push({
                        "finance_source_id": cards[i].account.value,
                        "amount": parseFloat(cards[i].amount),
                        "is_cash": 0,
                        "is_cod": 0
                    })
                }
            }
        }
        if(banks.length > 0){
            for (let i = 0; i < banks.length; i++) {
                if(banks[i].amount > 0){
                    if (payment_text_array.findIndex(k => k === 'Chuyển khoản') === -1) {
                        payment_text_array.push("Chuyển khoản")
                    }
                    finance_sources.push({
                        "finance_source_id": banks[i].account.value,
                        "amount": parseFloat(banks[i].amount),
                        "is_cash": 0,
                        "is_cod": 0
                    })
                }
            }
        }
        this.props.onChange(finance_sources,payment_text_array.join(", "))
        this.onHidePayment();
    }
    onHidePayment(){
        triggerRef.current.close();
    }
    render(){
        let button_cash = (
            <Button onClick={() => { this.onSelectPayMode(PAYMENT_MODE_CASH) }} >
                <Icon icon="money"  /> Tiền mặt
            </Button>
        );
        if(this.state.mode === PAYMENT_MODE_CASH){
            button_cash = (
                <Button color="green" onClick={() => { this.onSelectPayMode(PAYMENT_MODE_CASH) }} >
                    <Icon icon="money"  /> Tiền mặt
                </Button>
            );
        }
        let button_card = (
            <Button onClick={() => { this.onSelectPayMode(PAYMENT_MODE_CARD) }} className={this.state.list_card_accounts.length > 0 ? "display-inline-block": "display-none"}>
                <Icon icon="credit-card"  /> Thẻ
            </Button>
        );
        if(this.state.mode === PAYMENT_MODE_CARD){
            button_card = (
                <Button color="green" onClick={() => { this.onSelectPayMode(PAYMENT_MODE_CARD) }} >
                    <Icon icon="credit-card"  /> Thẻ
                </Button>
            );
        }
        let button_transfer = (
            <Button onClick={() => { this.onSelectPayMode(PAYMENT_MODE_BANK) }} className={this.state.list_bank_accounts.length > 0 ? "display-inline-block": "display-none"}>
                <Icon icon="bank"  /> Chuyển khoản
            </Button>
        );
        if(this.state.mode === PAYMENT_MODE_BANK){
            button_transfer = (
                <Button color="green" onClick={() => { this.onSelectPayMode(PAYMENT_MODE_BANK) }} >
                    <Icon icon="bank"  /> Chuyển khoản
                </Button>
            );
        }
        const speaker = (
            <Popover title={this.props.title} style={{minWidth: 350}}>
                <div className="form-group">
                    <ButtonToolbar>
                        { button_cash }
                        { button_card }
                        { button_transfer }
                    </ButtonToolbar>
                </div>
                <div className="form-group">
                    <div className="pull-left">
                        <strong className="font-14">Cần phải trả</strong>
                    </div>
                    <div className="pull-right">
                        <strong className="font-14"><NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={this.props.amount} /></strong>
                    </div>
                    <div className="cl"></div>
                </div>  
                <Divider />
                <div className={this.state.mode === PAYMENT_MODE_CASH ? "display-block": "display-none"}>
                    <div className="form-group">
                        <div className="pull-left">
                            <strong className="font-14">Số tiền</strong>
                        </div>
                        <div className="pull-right">
                            <div className="nk-int-st nk-toggled">
                                <NumberFormat className="form-control text-right" getInputRef = {(el) => this.cash_amount = el} thousandSeparator={true} prefix={""} value={this.state.amount} onValueChange={(values) => this.onCashAmountFieldChange(values)} />
                            </div>
                        </div>
                        <div className="cl"></div>
                    </div>
                </div>
                <div className={this.state.mode === PAYMENT_MODE_CARD ? "display-block": "display-none"}>
                    <div className={ this.state.cards.length === this.state.list_card_accounts.length ? "display-none" : "form-group" }><Button size="xs" onClick={() => this.onAddMorePayment(PAYMENT_MODE_CARD) }><Icon icon="plus-circle" /> Thêm nguồn thanh toán</Button></div>
                    { this.onParsePaymentList(PAYMENT_MODE_CARD) }
                </div>
                <div className={this.state.mode === PAYMENT_MODE_BANK ? "display-block": "display-none"}>
                    <div className={ this.state.banks.length === this.state.list_bank_accounts.length ? "display-none" : "form-group" }><Button size="xs" onClick={() => this.onAddMorePayment(PAYMENT_MODE_BANK) }><Icon icon="plus-circle" /> Thêm nguồn thanh toán</Button></div>
                    { this.onParsePaymentList(PAYMENT_MODE_BANK) }
                </div>
                <Divider />
                <div className="form-group text-right">
                    <Button color="cyan" onClick={() => { this.onSubmitPayment() }} >
                        <Icon icon="check-circle"  /> Đồng ý
                    </Button>{" "}
                    <Button onClick={() => { this.onHidePayment() }} >
                        <Icon icon="ban"  /> Bỏ qua
                    </Button>
                </div>
            </Popover>
        );
        return(
            <Whisper
                placement={this.props.placement}
                ref={triggerRef}
                trigger="click"
                onEntered={() => {
                }}
                speaker={speaker} >
                    { this.props.button === true ?
                    <div>
                        <Icon icon="plus-circle" size="lg" className="txt-blue" />
                    </div>
                    : <div className="nk-int-st nk-toggled">
                        <NumberFormat className="form-control text-right cl_handover" readOnly={true} thousandSeparator={true} prefix={""} value={this.props.payment_amount} />
                    </div> }
            </Whisper>
        );
    }
}

PaymentInput.defaultProps = {
    placement: "leftStart",
    button: false
}

export default PaymentInput;