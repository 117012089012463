export const GET_PAYMENT_INVOICE = 'GET_PAYMENT_INVOICE';
export const LIST_PAYMENT_INVOICE = 'LIST_PAYMENT_INVOICE';
export const LIST_PAYMENT_INVOICE_STATUS = 'LIST_PAYMENT_INVOICE_STATUS';
export const SEARCH_PAYMENT_INVOICE = 'SEARCH_PAYMENT_INVOICE';
export const FIELD_CHANGE_PAYMENT_INVOICE = 'FIELD_CHANGE_PAYMENT_INVOICE';
export const FIELDS_CHANGE_PAYMENT_INVOICE = 'FIELDS_CHANGE_PAYMENT_INVOICE';
export const RESET_FIELD_PAYMENT_INVOICE = 'RESET_FIELD_PAYMENT_INVOICE';
export const RESET_FIELD_SEARCH_PAYMENT_INVOICE = 'RESET_FIELD_SEARCH_PAYMENT_INVOICE';
export const FOCUS_FIELD_PAYMENT_INVOICE = 'FOCUS_FIELD_PAYMENT_INVOICE';
export const ERROR_FIELD_PAYMENT_INVOICE = 'ERROR_FIELD_PAYMENT_INVOICE';
export const BUTTON_SPINNER_PAYMENT_INVOICE = 'BUTTON_SPINNER_PAYMENT_INVOICE';
export const BUTTON_UPDATE_SPINNER_PAYMENT_INVOICE= 'BUTTON_UPDATE_SPINNER_PAYMENT_INVOICE';
export const ITEM_BUTTON_SPINNER_PAYMENT_INVOICE = 'ITEM_BUTTON_SPINNER_PAYMENT_INVOICE';
export const DIRECT_TO_LIST_PAYMENT_INVOICE = 'DIRECT_TO_LIST_PAYMENT_INVOICE';
export const EMPTY_PAYMENT_INVOICE = 'EMPTY_PAYMENT_INVOICE';
export const SET_PRODUCTS_PAYMENT_INVOICE = 'SET_PRODUCTS_PAYMENT_INVOICE';
export const SET_RECEIPTS_PAYMENT_INVOICE = 'SET_RECEIPTS_PAYMENT_INVOICE';
export const UPDATE_AMOUNT_PAYMENT_INVOICE = 'UPDATE_AMOUNT_PAYMENT_INVOICE';

export const GET_STAFF_NOTES_PAYMENT_INVOICE = 'GET_STAFF_NOTES_PAYMENT_INVOICE';
export const GET_LAST_STAFF_NOTES_PAYMENT_INVOICE = 'GET_LAST_STAFF_NOTES_PAYMENT_INVOICE';
export const GET_BILL_STATUS_PAYMENT_INVOICE = 'GET_BILL_STATUS_PAYMENT_INVOICE';

export const STATUS_NEW_PAYMENT_INVOICE = 1;
export const STATUS_FINISH_PAYMENT_INVOICE = 2;
export const STATUS_CANCEL_PAYMENT_INVOICE = 3;

export const CUSTOMER_PAYMENT_INVOICE = 1;
export const OWNER_PAYMENT_INVOICE = 2;