import React, { Component } from 'react';
import { Button } from 'rsuite';
import FileSaver from 'file-saver';
class DownloadLink extends Component {
    onDownload = () => {
        if(this.props.file_url !== ""){
            FileSaver.saveAs(this.props.file_url,this.props.file_name);
        }
    }
    render(){
        return(
            <div className="panel-relative">
                <div className="download-link-container">
                    <Button appearance="link" active onClick={() => { this.onDownload() }}>{this.props.button_name}</Button>
                </div>
            </div>
        );
    }
}
export default DownloadLink;