import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as PaymentInvoiceAction from '../../actions/PaymentInvoiceAction';
import * as PaymentInvoiceActionType from '../../constants/PaymentInvoiceActionType';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import UserSelectBox from '../shared/UserSelectBox';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import PaymentFilter from '../shared/PaymentFilter';
import Helper from '../../utils/Helper';
import { Whisper, Tooltip } from 'rsuite';
import PaymentBillStatusSelectBox from './PaymentBillStatusSelectBox';
class PaymentInvoices extends Component {
    constructor() {
        super();
        this.state = {
            type: 0,
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.type !== prevState.type){
          return { type: nextProps.type};
        }
        return null;
    }   
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.type !== this.props.type){
            this.setState({type: this.props.type});
            this.props.onFieldChange("payment_bill_type",this.props.type);
            var _this = this
            setTimeout(() => {
                var data_search = _this.getDataSearch();
                _this.props.onSearchPaymentInvoices(data_search,_this.props.payment_invoice.page,_this.props.payment_invoice.limit, true);
            },250)
        } 
    } 
    componentDidMount(){
        let _this = this
        let page = Helper.getQueryString('page');
        if(page === null){
            page = this.props.payment_invoice.page
        }
        let type =  PaymentInvoiceActionType.CUSTOMER_PAYMENT_INVOICE
        if(_this.props.type !== undefined && _this.props.type > 0){
            type = _this.props.type
        }
        _this.bindDataSearch()
        _this.props.onFieldChange("payment_bill_type",type);
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            if (data_search.search !== undefined && data_search.search === true){
                _this.props.onSearchPaymentInvoices(data_search,page,_this.props.payment_invoice.limit, true);
            } else {
                var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
                var to_date = moment().endOf('month').format('YYYY-MM-DD');
                var search_date = [];
                if(from_date !== ""){
                    search_date.push(from_date)
                }
                if(to_date !== ""){
                    search_date.push(to_date)
                }
                let used_for = ""
                if(type === PaymentInvoiceActionType.CUSTOMER_PAYMENT_INVOICE){
                    used_for = "invoice"
                }
                _this.props.onFieldChange("search_date",search_date);
                _this.props.onSearchPaymentInvoices({
                    "from_date": search_date[0],
                    "to_date": search_date[1],
                    "has_finance_source": 1,
                    "skip_sum_return_bill_payment": 1,
                    "payment_bill_type": type,
                    "used_for": used_for
                },page,_this.props.payment_invoice.limit, false);
            }
        },250)
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let status = Helper.getQueryString('status');
        let from_date = Helper.getQueryString('from_date');
        let to_date = Helper.getQueryString('to_date');
        let partner_id = Helper.getQueryString('partner_id');
        let selected_invoice_code = Helper.getQueryString('receipt_bill_code');
        let is_invoice_cancel = Helper.getQueryString('is_invoice_cancel');
        let payment_method = Helper.getQueryString('payment_method');
        var search_date = [];
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_date.push(from_date)
        }
        if(to_date !== undefined && to_date !== null && to_date !== ""){
            search_date.push(to_date)
        }
        var customer_selected = {}
        if(partner_id !== undefined && partner_id !== null && partner_id > 0){
            customer_selected.value = partner_id
        }
        var search_status = []
        if (status !== undefined && status !== null && status !== ""){
            search_status = status.split(",")
        }
        var payment_filter_selected = []
        if (payment_method !== undefined && payment_method !== null && payment_method !== ""){
            payment_filter_selected = payment_method.split(",")
        }
        if (search_code === null){
            search_code = ""
        }
        if (selected_invoice_code === null){
            selected_invoice_code = ""
        }
        if (!(is_invoice_cancel > 0)){
            is_invoice_cancel = 0   
        }
        this.props.onFieldsChange({
            search_code, search_status, search_date, payment_filter_selected, search_invoice_cancel: parseInt(is_invoice_cancel), selected_invoice_code
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("customer_selected", item);
    }
    onPaymentFilterChange(value,_){
        this.props.onFieldChange("payment_filter_selected", value)
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _code = this.props.payment_invoice.search_code;
        let _invoice_code = this.props.payment_invoice.selected_invoice_code;
        let _import_receipt_code = this.props.payment_invoice.selected_import_receipt_code;
        let _status = this.props.payment_invoice.search_status;
        let _date = this.props.payment_invoice.search_date;
        let _customer = this.props.payment_invoice.customer_selected;
        let _payment = this.props.payment_invoice.payment_filter_selected;
        let _is_invoice_cancel = parseInt(this.props.payment_invoice.search_invoice_cancel);
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        let used_for = ""
        if(this.props.payment_invoice.payment_bill_type === PaymentInvoiceActionType.CUSTOMER_PAYMENT_INVOICE){
            used_for = "invoice"
        }
        var data_search = {
            "search": false,
            "param": [],
            "payment_bill_type": this.props.payment_invoice.payment_bill_type,
        }
        var is_search = false
        if (_code !== "" || (_invoice_code !== null && _invoice_code !== "")){
            if (_code !== ""){
                data_search["code"] = _code;
                data_search["param"].push("code=" + _code)
                this.props.onFieldsChange({
                    payment_filter_selected: {}, search_invoice_cancel:0, selected_invoice_code: ""
                })
            } else if(_invoice_code !== null && _invoice_code !== ""){
                data_search["receipt_bill_code"] = _invoice_code;
                data_search["param"].push("receipt_bill_code=" + data_search["receipt_bill_code"])
                this.props.onFieldsChange({
                    payment_filter_selected: {}, search_invoice_cancel:0, selected_code: ""
                })
            }
            is_search = true
        } else {
            if (_status.length > 0){
                data_search["status"] = _status.join(",");
                data_search["param"].push("status=" + data_search["status"])
                is_search = true
            }
            if (_date[0] !== undefined){
                data_search["from_date"] = _date[0];
                data_search["param"].push("from_date=" + data_search["from_date"])
                is_search = true
            }
            if (_date[1] !== undefined){
                data_search["to_date"] = _date[1];
                data_search["param"].push("to_date=" + data_search["to_date"])
                is_search = true
            }
            if(_customer.value !== undefined){
                data_search["partner_id"] = _customer.value;
                data_search["param"].push("partner_id=" + data_search["partner_id"])
                is_search = true
            }
            if(_import_receipt_code !== null && _import_receipt_code !== ""){
                data_search["import_receipt_code"] = _import_receipt_code;
                data_search["param"].push("import_receipt_code=" + data_search["import_receipt_code"])
                is_search = true
            }
            if(_is_invoice_cancel === 1){
                data_search["is_invoice_cancel"] = 1;
                data_search["param"].push("is_invoice_cancel=" + data_search["is_invoice_cancel"])
                is_search = true
            }
            if(_payment.length > 0){
                data_search["payment_method"] = _payment.join(",");
                data_search["param"].push("payment_method=" + data_search["payment_method"])
                is_search = true
            }
        }
        data_search["has_finance_source"] = 1;
        data_search["skip_sum_return_bill_payment"] = 1;
        data_search["used_for"] = used_for;
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search.search !== undefined && data_search.search === true){
            if(data_search.param.length > 0){
                let param = data_search.param.join("&")
                if(param !== ""){
                    param = "?" + param
                }
                this.props.history.push("/accounting/invoices" + param)
            }
            this.props.onSearchPaymentInvoices(data_search,1,this.props.payment_invoice.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        let param = "?page=" + pageNumber
        if(data_search.param.length > 0){
            let param_search = data_search.param.join("&")
            if(param_search !== ""){
                param += "&" + param_search
            }
        }
        this.props.history.push("/accounting/invoices" + param)
        this.props.onFieldChange("page", pageNumber)
        this.props.onSearchPaymentInvoices(data_search,pageNumber,this.props.payment_invoice.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/accounting/invoice/" + id);
    }
    showList(lists){
        /*let has_search_by_payment = false
        if(this.props.payment_invoice.payment_filter_selected !== "" && this.props.payment_invoice.is_search === true){
            has_search_by_payment = true
        }*/
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let status_text = "", payment_method_text = ""
                if (item.payment_bill_status !== PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE){
                    status_text = <label className='label' style={{backgroundColor: item.payment_bill_status_color }}>{ item.payment_bill_status_text }</label>
                }
                if (item.payment_bill_finance !== undefined && item.payment_bill_finance.length > 0){
                    var financeItem = item.payment_bill_finance[0]
                    let tooltip_finance_name = <Tooltip>
                    {financeItem.finance_source_name}
                    </Tooltip>
                    if (financeItem.is_cash === 1){
                        payment_method_text = "Tiền mặt"
                    } else if (financeItem.is_cod === 1){
                        payment_method_text = "Thu hộ (COD)"
                    } else if (financeItem.is_return_bill === 1){
                        payment_method_text = "Tiền trả hàng"
                    } else if (financeItem.is_bank === 1){
                        payment_method_text = <Whisper placement="top" trigger="hover" speaker={tooltip_finance_name}>
                        <span>Chuyển khoản</span>
                        </Whisper>
                    } else if (financeItem.is_card === 1){
                        payment_method_text = <Whisper placement="top" trigger="hover" speaker={tooltip_finance_name}>
                        <span>Quẹt thẻ</span>
                        </Whisper>
                    }
                }
                let receipt_bill_cancel_text = ""
                if (item.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
                    receipt_bill_cancel_text = <span>&nbsp;<label className="label label-danger">Đã hủy</label></span>
                } else if (item.receipt_bill_status === InvoiceActionType.STATUS_RETURN_INVOICE){
                    receipt_bill_cancel_text = <span>&nbsp;<label className="label label-warning">Đã trả hàng</label></span>
                }
                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                        <td>{ item.partner_name }</td>
                        <td>
                        <Moment format="DD/MM/YYYY HH:mm">
                        { Helper.replaceMysqlDateTime(item.payment_bill_date) }
                        </Moment>
                        </td>
                        <td>{item.staff_seller_name}</td>
                        <td>{item.receipt_bill_code}{receipt_bill_cancel_text}</td>
                        <td>{payment_method_text}</td>
                        <td className="text-right"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center">{status_text}</td>
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { total_amount, total, limit, total_page, page, list, search_code, selected_invoice_code, search_invoice_cancel, search_button_loading } = this.props.payment_invoice;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        /*let has_search_by_payment = false
        if(this.props.payment_invoice.payment_filter_selected !== "" && is_search === true){
            has_search_by_payment = true
        }*/
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter has_default={true} value={this.props.payment_invoice.search_date} placeholder="Chọn ngày thanh toán" onFieldChange = { (value) => { this.onDatePickerChange(value) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã hóa đơn" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="selected_invoice_code" value={ selected_invoice_code } className="form-control" autoComplete="off" placeholder="Nhập mã hóa đơn bán hàng" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <PaymentBillStatusSelectBox multi={true}  />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <PaymentFilter multi={true} value={this.props.payment_invoice.payment_filter_selected} placeholder="Phương thức thanh toán" onChange={(value,name) => this.onPaymentFilterChange(value,name) } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox getRef={rel => {this.userSelectBoxRef = rel} } onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="toggle-select-act fm-cmp-mg">
                                    <div className="nk-toggle-switch">
                                        <label htmlFor="search_invoice_cancel" className="ts-label">Hóa đơn bán hàng hủy</label>
                                        <input id="search_invoice_cancel" name="search_invoice_cancel" type="checkbox" hidden="hidden" value="1" checked={search_invoice_cancel === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                        <label htmlFor="search_invoice_cancel" className="ts-helper"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        Hóa đơn thanh toán
                                    </h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col></col>
                                        <col width="10%"></col>
                                        <col width="15%"></col>
                                        <col width="15%"></col>
                                        <col width="15%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Khách hàng</th>
                                            <th>Thời gian</th>
                                            <th>Nhân viên bán hàng</th>
                                            <th>Hóa đơn bán hàng</th>
                                            <th>Hình thức thanh toán</th>
                                            <th className="text-right">Tổng tiền</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                        <tr className="bg-warning">
                                            <th colSpan="5"></th>
                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        payment_invoice: state.payment_invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PaymentInvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PaymentInvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PaymentInvoiceAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(PaymentInvoiceAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(PaymentInvoiceAction.resetFieldSearch())
        },
        onSearchPaymentInvoices: (data_search,page,limit,spinner) => {
            dispatch(PaymentInvoiceAction.searchRequest(data_search,page,limit,spinner))
        },
        onGetInvoice: (id) => {
            dispatch(PaymentInvoiceAction.getRequest(id))
        },
        onItemButtonSpinner(id,status){
            dispatch(PaymentInvoiceAction.itemButtonSpinner(id,status))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(PaymentInvoices)