import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as PaymentInvoiceAction from '../../actions/PaymentInvoiceAction';
import * as FinanceSourceAction from '../../actions/FinanceSourceAction';
import * as BillLadingActionType from '../../constants/BillLadingActionType';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import moment from 'moment';
import ModalUpdateReasonPending from './ModalUpdateReasonPending';

class ViewBillLading extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: 0,
            receipt_bill_id: 0,
            receipt_bill_code: "",
            ship_status: 0,
            ship_status_reason: "",
            ship_status_time: 0,
            staff_update_status_name: "",
            change_finance_source: null,
            invoice_info: {
                total_quantity: 0,
                total_payment_amount : 0, total_amount: 0, money_collect_cod: 0,
                products: [],
            },
            payment_bills: [],
            show_modal_pending: false
        }
        this.timeout_set_payment_done = null
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        if (id > 0) {
            this.props.onListStatus()
            this.props.onGetBillLading(id).then(async (bill_lading) => {
                if(bill_lading !== undefined && bill_lading !== null && bill_lading.id !== undefined && bill_lading.id > 0){
                    let invoice = await this.props.onGetInvoice(bill_lading.receipt_bill_id)
                    let payment_bills = await this.props.onGetPaymentBills(bill_lading.receipt_bill_id)
                    let change_finance_source = null
                    if (bill_lading.change_finance_source_id > 0){
                        let finance_source = await this.props.onGetFinanceSource(bill_lading.change_finance_source_id)
                        if(finance_source !== undefined && finance_source.id > 0){
                            change_finance_source = finance_source
                        }
                    }
                    this.setState({
                        ...bill_lading,
                        change_finance_source,
                        invoice_info: {
                            ...invoice
                        },
                        payment_bills: payment_bills.list
                    })
                } else {
                    this.props.history.push("/bill-ladings")
                }
            })
        }
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }

    onPaymentDoneFieldChange = (event, id) => {
        let target = event.target;
        let value = 0
        if(target.checked === true){
            value = 1
        }
        let payment_update = {}
        let payment_bills = this.state.payment_bills.filter(item => {
            if(item.id === id){
                item.is_confirmed = value
                payment_update = {
                    id: item.id,
                    is_confirmed: value
                }
            }
            return item
        })
        let _this = this
        _this.timeout_set_payment_done = setTimeout(() => {
            _this.props.onSetPaymentDone({
                is_confirmed: payment_update.is_confirmed
            }, payment_update.id).then(() => {
                _this.setState({
                    payment_bills 
                })
            })
        },250)
    }
    onSelectShipStatus(item){
        let id = this.state.id
        if(item !== null){
            switch(item.value){
                case BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS:
                    this.onFinish(id)
                    break
                case BillLadingActionType.STATUS_CANCEL_SHIP_BILL_LADINGS:
                    this.onCancel(id)
                    break
                case BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS:
                    this.onShowHideModalPending()
                    break
                default:
                    this.props.onSetBillLadingStatus(id, item.value).then((result) => {
                        if(result.status !== undefined){
                            if(result.status === true){
                                this.props.onGetBillLading(id).then((bill_lading) => {
                                    this.setState({
                                        ...bill_lading
                                    })
                                })
                            } else if(result.message !== undefined && result.message !== null){
                                Swal.fire("Thông báo lỗi", result.message, "error")
                            }
                        }
                    })   
                    break
            }
        }
    }
    onFinish(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn hoàn tất giao hàng vận đơn này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onFinish(id).then((result) => {
                    if(result.status !== undefined){
                        if(result.status === true){
                            this.props.onGetBillLading(id).then((bill_lading) => {
                                this.setState({
                                    ...bill_lading
                                })
                            })
                        } else if(result.message !== undefined && result.message !== null){
                            Swal.fire("Thông báo lỗi", result.message, "error")
                        }
                    }
                })   
            },
        })
    }
    onCancel(id){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy giao hàng',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(id, reason_cancel).then((result) => {
                        if(result.status !== undefined){
                            if(result.status === true){
                                this.props.onGetBillLading(id).then((bill_lading) => {
                                    this.setState({
                                        ...bill_lading
                                    })
                                })
                            } else if(result.message !== undefined && result.message !== null){
                                Swal.fire("Thông báo lỗi", result.message, "error")
                            }
                        }
                    })   
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }

    onSavePending(data){
        let reason_pending_selected = data.ship_status_reason_option
        let other_reason_pending = data.reason_pending
        this.props.onPending(this.state.id, {
            reason_pending: other_reason_pending,
            ship_status_reason_option: reason_pending_selected
        }).then(response => {
            this.setState({
                show_modal_pending: false,
            })
            if(response.status !== undefined && response.status !== null){
                if(response.status === true){
                    this.props.onGetBillLading(this.state.id).then((bill_lading) => {
                        this.setState({
                            ...bill_lading
                        })
                    })
                } else if (response.message  !== undefined && response.message !== null){
                    Swal.fire("Thông báo", response.message, "error")
                }
            } else {
                Swal.fire("Thông báo", "Đã phát sinh lỗi không xác định, vui lòng thử lại sau", "error")
            }
        }) 
    }
    onShowHideModalPending(){
        this.setState({
            show_modal_pending: !this.state.show_modal_pending
        })
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let gift_text = ""
                if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                }
                let price_after = item.price * item.quantity
                let discount_amount = item.discount_money
                let discount_text = <NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} />
                if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                    discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} /></span>
                }
                price_after = price_after - discount_amount
                let total_amount = price_after
                return (
                    <tr className="cl_handover" key={ index }>
                        <td className="text-center"><img src={item.product_url} alt="" /></td>
                        <td>{ item.product_model }</td>
                        <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                        <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right">{ discount_text }</td>
                        <td className="text-right"><NumberFormat value={price_after} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    render(){
        let { id, receipt_bill_id, receipt_bill_code, payment_bills , ship_status, ship_status_reason, ship_status_time, staff_update_status_name, change_finance_source, change_to_cash, bill_lading_notes} = this.state
        let { products } = this.state.invoice_info
        let { ship_status_select } = this.props.bill_lading;
        let user = Helper.getUser()
        let status_array = []
        if (ship_status_select.list !== undefined) {
            Object.keys(ship_status_select.list).forEach(key => {
                let allow_add = true
                let value = parseInt(key)
                if(ship_status === BillLadingActionType.STATUS_NEW_SHIP_BILL_LADINGS){
                    if(value === BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS){
                        allow_add = false
                    }
                }
                if(allow_add === true){
                    status_array.push({value, label: ship_status_select.list[key]})
                }
            })
        }
        let change_payment_text = ""
        if(change_to_cash === 1){
            change_payment_text = "Đổi thanh toán bằng tiền mặt"
        } else if(change_finance_source !== null && change_finance_source.id !== undefined && change_finance_source.id > 0){
            change_payment_text = "Đổi thanh toán bằng "
            if (change_finance_source.is_card === 1) {
                change_payment_text += " quẹt thẻ "
            } else if (change_finance_source.is_bank === 1) {
                change_payment_text += " chuyển khoản "
            }
            change_payment_text += change_finance_source.name + " - " + change_finance_source.account_name + " - " + change_finance_source.account_number
        }

        let html_alert_bill_status = ""
        if(ship_status === BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-success text-center">
                    <h3>{ staff_update_status_name } hoàn thành giao hàng lúc <Moment unix format="HH:mm DD/MM/YYYY">{ ship_status_time }</Moment></h3>
                </div>
            </div>  
        } else if(ship_status === BillLadingActionType.STATUS_CANCEL_SHIP_BILL_LADINGS){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                        <h3>{ staff_update_status_name } đã hủy giao hàng lúc <Moment unix format="HH:mm DD/MM/YYYY">{ ship_status_time }</Moment><br />
                        Lý do: { ship_status_reason }</h3>
                </div>
            </div>  
        } else if(ship_status === BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-warning text-center">
                        <h3>{ staff_update_status_name } đã tạm ngừng giao hàng lúc <Moment unix format="HH:mm DD/MM/YYYY">{ ship_status_time }</Moment><br />
                        Lý do: { ship_status_reason }</h3>
                </div>
            </div>  
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Vận đơn hóa đơn bán hàng {receipt_bill_code}</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/bill-lading/" + id} />
                </Helmet>   
                <div className="row">
                    {html_alert_bill_status}
                    <div className="col-xs-12">
                        <div className="form-element-list" >
                            <div className="row">
                                <div className="col-sm-3 col-xs-12">
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label className="hrzn-fm">Mã phiếu:</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                <NavLink to={"/invoice/" + receipt_bill_id} target="_blank">{ receipt_bill_code }</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label className="hrzn-fm">Khách hàng:</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                { this.state.customer_name }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label className="hrzn-fm">Chi nhánh:</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                {this.state.branch_name}    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-xs-12">
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label className="hrzn-fm">Người giao:</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                {this.state.transporter_name}    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label className="hrzn-fm">Người nhận:</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                {this.state.receiver_name}    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <label className="hrzn-fm">Địa chỉ:</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                {this.state.receiver_address}    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12">
                                                <label className="label-control">Trạng thái giao hàng:</label>
                                                <div>
                                                    <span key="bill_ship_status_text" className="mr-right-5" style={{ color: this.state.ship_status_color, fontSize: 15 }}>{ this.state.ship_status_text }</span>
                                                    {/*<div className="btn-group">
                                                        <button type="button" className="btn" style={{ backgroundColor: this.state.ship_status_color, fontSize: 15, color: "#FFFFFF" }}>{ this.state.ship_status_text }</button>
                                                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: this.state.ship_status_color, fontSize: 15, color: "#FFFFFF" }}>
                                                            <span className="caret"></span>
                                                            <span className="sr-only">Toggle Dropdown</span>
                                                        </button>
                                                        <ul className="dropdown-menu">q
                                                            { status_array.map((item, index) => {
                                                                return <li key={index}><a href=" #" onClick={() => { this.onSelectShipStatus(item) }}>{item.label}</a></li>
                                                            }) }
                                                        </ul>
                                                        </div>*/}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                {bill_lading_notes !== undefined && bill_lading_notes !== null && bill_lading_notes !== "" ?
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <div className="row">
                                                        <div className="col-sm-3 col-xs-12">
                                                            <label className="hrzn-fm">Ghi chú:</label>
                                                        </div>
                                                        <div className="col-sm-9 col-xs-12">
                                                            {bill_lading_notes}    
                                                        </div>
                                                    </div>
                                                </div> : null }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <label className="label-control">Phương thức thanh toán:</label>
                                        <div>
                                            {change_payment_text !== "" ?
                                            <div className="form-group">
                                                {change_payment_text}
                                            </div>
                                            : null }
                                            { payment_bills !== undefined && payment_bills.length > 0 ? 
                                                payment_bills.map((item, index) => {
                                                    let payment_method = [] 
                                                    if (item.payment_bill_finance.length > 0){
                                                        item.payment_bill_finance.forEach((methodItem, index) => {
                                                            let source_name = ""
                                                            if (methodItem.is_cash === 1){
                                                                source_name = "Tiền mặt"
                                                            } else if (methodItem.is_cod){
                                                                source_name = "Thu hộ (COD)"
                                                            } else if (methodItem.is_return_bill){
                                                                source_name = "Tiền trả hàng"
                                                            } else if (methodItem.finance_source_name){
                                                                source_name = methodItem.finance_source_name
                                                            }
                                                            payment_method.push(<h5 key={index} ><label className="label label-info mr-right-5">{source_name}: <NumberFormat value={methodItem.amount} displayType={'text'} thousandSeparator={true} /></label></h5>)    
                                                        })
                                                    }
                                                    let confirmed_info = null
                                                    if(item.is_confirmed === 1){
                                                        let  staff_confirm_name = item.staff_confirm_name
                                                        if(staff_confirm_name === ""){
                                                            staff_confirm_name = user.name
                                                        }
                                                        let staff_confirm_time = moment().format('DD/MM/YYYY HH:mm')
                                                        let confirm_time = item.confirm_time
                                                        if(confirm_time > 0){
                                                            staff_confirm_time = <Moment format="DD/MM/YYYY HH:mm" unix={true}>
                                                            { confirm_time }
                                                            </Moment>
                                                        } 
                                                        confirmed_info = <span className="payment_done_staff">({staff_confirm_name} lúc {staff_confirm_time})</span>
                                                    }
                                                    return (
                                                        <div key={index} className="mr-bot-5 payment-set-confirm">
                                                            <div className="pull-left">
                                                                {payment_method}
                                                            </div>
                                                            {change_payment_text === ""?
                                                            <div className="pull-left mr-left-10 mr-top-5">
                                                                <div className="toggle-select-act fm-cmp-mg">
                                                                    <div className="nk-toggle-switch">
                                                                        <input id={"payment_done_" + index} name={"payment_done_" + index} type="checkbox" hidden="hidden" value="1" checked={item.is_confirmed === 1 ? true : false} onChange={ (e) => { this.onPaymentDoneFieldChange(e, item.id) } }></input>
                                                                        <label htmlFor={"payment_done_" + index} className="ts-helper"></label>
                                                                        <label htmlFor={"payment_done_" + index} className="ts-label">Đã nhận</label>
                                                                        {confirmed_info}
                                                                    </div>
                                                                </div>
                                                            </div> : null }
                                                        </div>
                                                    );   
                                                })
                                                : null 
                                            }
                                        </div>            
                                    </div>    
                                </div>
                            </div> 
                        </div>
                        {  products !== undefined && products !== null && products.length > 0 ?
                        <div className="normal-table-list mr-top-20">
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-condensed">
                                    <colgroup>
                                    <col width="5%"></col>
                                    <col width="10%"></col>
                                    <col></col>
                                    <col width="10%"></col>
                                    <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Mã hàng</th>
                                            <th>Tên hàng</th>
                                            <th className="text-right">Số lượng</th>
                                            <th className="text-right">Đơn giá</th>
                                            <th className="text-right">Giảm giá</th>
                                            <th className="text-right">Giá bán</th>
                                            <th className="text-right">Thành tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListProduct(products) }
                                    </tbody>
                                </table>
                            </div>
                        </div> : null }
                    </div>
                </div>
                { ship_status === BillLadingActionType.STATUS_SHIP_IN_PROGRESS_BILL_LADINGS ?
                    <ModalUpdateReasonPending show={this.state.show_modal_pending} onShowHide={() => this.onShowHideModalPending() } onSave={(data) => this.onSavePending(data)} />
                : null }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        bill_lading: state.bill_lading
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetBillLading: (id) => {
            return dispatch(BillLadingAction.getRequest(id))
        },
        onGetInvoice: (id) => {
            return dispatch(InvoiceAction.getRequest(id))
        },
        onGetPaymentBills: id => {
            return dispatch(InvoiceAction.getPaymentBillsRequest(id, 0))
        },
        onGetFinanceSource: id => {
            return dispatch(FinanceSourceAction.getRequest(id))
        },
        onListStatus: () => {
            dispatch(BillLadingAction.listStatusRequest())
        },
        onSetPaymentDone: (payment_update, id) => {
            return dispatch(PaymentInvoiceAction.updatePaymentRequest(id, payment_update))
        },
        onFinish: (id) => {
            return dispatch(BillLadingAction.setBillLadingStatus(id,{
                status: BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS
            }))
        },
        onCancel: (id, reason_cancel) => {
            return dispatch(BillLadingAction.setBillLadingStatus(id, {
                status: BillLadingActionType.STATUS_CANCEL_SHIP_BILL_LADINGS,
                reason_cancel
            }))
        },
        onPending: (id, reason_pending) => {
            return dispatch(BillLadingAction.setBillLadingStatus(id, {
                status: BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS,
                ...reason_pending
            }))
        },
        onSetBillLadingStatus: (id, status) => {
            return dispatch(BillLadingAction.setBillLadingStatus(id, {
                status
            }))
        },
    }
}
const ViewBillLadingConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewBillLading));
export default withRouter(ViewBillLadingConnect);