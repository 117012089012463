import React, { Component } from 'react';
import autosize from 'autosize';
class TextareaAutoHeight extends Component {
    componentDidMount(){
        autosize(this.textarea);
     }
    render(){
        const style = {
            maxHeight: "100px",
            minHeight: "50px",
            resize: "none",
            padding: "9px",
            boxSizing: "border-box",
            fontSize: "15px",
          };
        return (
            <textarea className="form-control" name={this.props.name} style={style} autoComplete="off" onFocus={ (e) => { this.props.onFocus(e) } }  onBlur={ (e) => { this.props.onBlur(e) } } onChange={ (e) => { this.props.onChange(e) } } ref={(input) => { this.textarea = input; this.props.refInput(input) }} placeholder={this.props.placeholder} value={this.props.value} />
        )
    }
}
export default TextareaAutoHeight;