import * as FinanceSourceActionType from '../constants/FinanceSourceActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    account_number: "",
    name: "",
    account_name: "",
    amount: 0,
    is_cash: 0,
    is_card: 0,
    is_bank: 0,
    list: [],
    page: 1,
    total: 0,
    total_page: 0,
    limit: 10,
    search_keyword: "",
    search_button_loading: false,
    save_button_loading: false,
    is_edit: false
};
const finance_sources = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case FinanceSourceActionType.ITEM_BUTTON_SPINNER_FINANCE_SOURCE:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case FinanceSourceActionType.BUTTON_SPINNER_FINANCE_SOURCE:
            return {
                ...state,
                save_button_loading: action.status
            };
        case FinanceSourceActionType.FIELD_CHANGE_FINANCE_SOURCE:
              return {
                ...state,
                [action.field]: action.value
              };
        case FinanceSourceActionType.RESET_FIELD_FINANCE_SOURCE:
            return {
                ...state,
                id: 0,
                account_number: "",
                name: "",
                account_name: "",
                amount: 0,
                is_cash: 0,
                is_card: 0,
                is_bank: 0,
            };
        case FinanceSourceActionType.LIST_FINANCE_SOURCE:
            return {
                ...state,
                list: action.list,
                page: action.page,
                limit: action.limit,
                total: action.total,
                total_page: action.total_page
            };
        case FinanceSourceActionType.GET_FINANCE_SOURCE:
            return {
                ...state,
                ...action.info
            };
        default: 
            return state;
    }
}
export default finance_sources;