import React, { Component } from  'react';
class Footer extends Component{
    render(){
        return (
            <div className="footer-copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="footer-copy-right">
                                <p>Copyright © 2019 . All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;