import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import { DatePicker, Alert  } from 'rsuite';
import LaddaButton, { ZOOM_IN, L } from 'react-ladda';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as PaymentInvoiceActionType from '../../constants/PaymentInvoiceActionType';
import * as PurchaseOrderActionType from '../../constants/PurchaseOrderActionType';
import * as UserActionType from '../../constants/UserActionType';
import * as ModalAction from '../../actions/ModalAction';
import InvoiceShipping from './InvoiceShipping';
import SellChannelSelectBox from '../shared/SellChannelSelectBox';
import UserSelectBox from '../shared/UserSelectBox';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import Modal from "../shared/Modal";
import CreatePartnerFast from '../partner/CreateFast';
import PaymentInput from '../shared/PaymentInput';
import { DISCOUNT_MODE_BY_MONEY } from '../../constants/DiscountModeType';

class ViewInvoice extends Component {
    constructor(props){
        super(props)
        this.state = {
            btn_set_import_transfer_index: -1,
            bill_lading_info: null,
            change_shipping_info: false
        }
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)
        }
    }
    componentDidUpdate(){
        if(this.state.change_shipping_info === true){
            this.setState({
                change_shipping_info: false
            })
        }
    }
    onInitData(id){
        this.props.onGetInvoice(id).then(result => {
            this.props.onGetPaymentBills(id)
            this.props.onGetStaffNotes(id)
            this.props.onGetLastStaffNote(id)
            this.props.onGetShipLog(id)
            if(+result.has_shipping === 1){
                this.props.onGetBillLading(id).then(bill_lading => {
                    if(bill_lading !== undefined){
                        this.setState({
                            bill_lading_info: bill_lading
                        })
                    }
                })
            }
        })
        this.props.onGetShipStatus()
    }
    onRefreshInfo(){
        this.setState({
            change_shipping_info: true
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onReceiptBillDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("receipt_bill_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onUserFieldChange(item){
        let staff_id = 0
        let staff_name = ""
        if(item !== null){
            staff_id = item.value
            staff_name = item.label
        }
        this.props.onFieldChange("staff_id", staff_id);
        this.props.onFieldChange("staff_name", staff_name);
    }
    onPaymentFieldChange = (value,payment_text) => {
        //console.log("value: ",value);
        //console.log("payment_text: ",payment_text);
        if (value !== null && value.length > 0){
            let pay_amount = 0
            value.forEach(function(item){
                if(item.amount > 0){
                    pay_amount += parseFloat(item.amount)
                }
            });
            //console.log("pay_amount: ",pay_amount);
            if (pay_amount > 0){
                // kiểm tra thanh thiếu hay dư
                let total_amount_after = this.props.invoice.total_amount
                let discount = this.props.invoice.discount
                let discount_mode = this.props.invoice.discount_mode
                let other_fee = this.props.invoice.other_fee
                let ship_fee = this.props.invoice.ship_fee
                let money_collect_cod = this.props.invoice.money_collect_cod
                let receiver_pay_fee = parseInt(this.props.invoice.receiver_pay_fee)
                if(discount > 0){
                    if(discount_mode === DISCOUNT_MODE_BY_MONEY){
                        total_amount_after = total_amount_after - discount
                    } else {
                        total_amount_after = total_amount_after - (total_amount_after * discount / 100)
                    }
                }   
                if(other_fee > 0){
                    total_amount_after = total_amount_after + other_fee
                }
                if(ship_fee > 0){
                    if (receiver_pay_fee === InvoiceActionType.SENDER_PAY_SHIP_FEE){
                        total_amount_after = total_amount_after - ship_fee
                    } else if (receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                        total_amount_after = total_amount_after + ship_fee
                    }
                }
                if(money_collect_cod > 0){
                    total_amount_after = total_amount_after - money_collect_cod
                }
                let cod_amount = total_amount_after - pay_amount - this.props.invoice.total_payment_amount
                if (cod_amount < 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        text: "Đã thanh toán vượt tổng tiền trên hóa đơn bán hàng"
                    })
                } 
                else if (cod_amount > 0){
                    if(this.props.invoice.has_cod === false){
                        Swal.fire({
                            title: 'Xác nhận',
                            text: "Đã thanh toán thiếu tổng tiền hóa đơn, số tiền chuyển thành thu hộ (COD)?",
                            icon: 'warning',
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            reverseButtons: true,
                            confirmButtonText: 'ĐỒNG Ý',
                            cancelButtonText: 'KHÔNG',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.invoiceShippingRef.onSetShippingCOD(cod_amount)
                            }
                        })
                    }
                }
                else {
                    let _this = this
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Xác nhận',
                            text: "Bạn thật sự muốn thực hiện thanh toán cho hóa đơn bán hàng?",
                            icon: 'warning',
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            reverseButtons: true,
                            confirmButtonText: 'ĐỒNG Ý',
                            cancelButtonText: 'KHÔNG',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                _this.props.onSavePayment(_this.props.invoice.id,value).then(result => {
                                    if(result.status === true){
                                        _this.props.onGetInvoice(_this.props.invoice.id).then(resultInvoice => {
                                            _this.props.onGetPaymentBills(resultInvoice.id)
                                        })
                                    }
                                    else if(result.status === false && result.message !== undefined && result.message !== ""){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Thông báo lỗi',
                                            text: result.message
                                        })
                                    } 
                                })  
                            }
                        })
                    },250)
                }
            }
        }
    }
    onSaveSeller(){
        if(this.props.invoice.staff_id === 0){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn nhân viên bán hàng'
            })
            return;
        }
        this.props.onUpdateData({
            staff_id: this.props.invoice.staff_id,
            staff_name: this.props.invoice.staff_name
        }, this.props.invoice.id, "save_seller_button_loading").then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    onSaveSellChannel(){
        if(!(this.props.invoice.sell_channel_selected.value !== undefined && this.props.invoice.sell_channel_selected.value > 0)){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn kênh bán hàng'
            })
            return;
        }
        this.props.onUpdateData({
            sell_channel: this.props.invoice.sell_channel_selected.value
        }, this.props.invoice.id, "save_channel_button_loading").then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    onSaveDate(){
        if(this.props.invoice.receipt_bill_date === ""){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn thời gian bán hàng'
            })
            return;
        }
        this.props.onUpdateData({
            receipt_bill_date: this.props.invoice.receipt_bill_date
        }, this.props.invoice.id, "save_date_button_loading").then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    onCopyData(){
        let _this = this
        Swal.fire({
            title: 'Xác nhận',
            text: "Bạn thật sự muốn sao chép hóa đơn bán hàng này?",
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
        }).then((result) => {
            if (result.isConfirmed) {
                _this.props.onCopyInvoice(_this.props.invoice.id).then(result => {
                    if(result.status === true){
                        let id = result.id
                        Swal.fire({
                            title: 'Thông báo',
                            text: "Hóa đơn bán hàng đã sao chép thành công",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'XEM HÓA ĐƠN',
                        }).then(() => {
                            _this.props.history.push("/invoice/" + id)
                            _this.onInitData(id)
                        })
                    }
                    else if(result.status === false && result.message !== undefined && result.message !== ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Thông báo lỗi',
                            text: result.message
                        })
                    } 
                })  
            }
        })
    }
    onSaveData(){
        if(Helper.checkMyRole('update_invoice_date') === true && this.props.invoice.receipt_bill_date === ""){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn thời gian bán hàng'
            })
            return;
        } 
        if(Helper.checkMyRole('update_invoice_seller') === true && this.props.invoice.staff_id === 0){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn nhân viên bán hàng'
            })
            return;
        }
        if(Helper.checkMyRole('update_invoice_channel') === true && !(this.props.invoice.sell_channel_selected.value !== undefined && this.props.invoice.sell_channel_selected.value > 0)){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn kênh bán hàng'
            })
            return;
        }
        let data_post = {}
        let has_update = false
        if (Helper.checkMyRole('update_invoice_seller') === true){
            data_post.staff_id = this.props.invoice.staff_id
            data_post.staff_name = this.props.invoice.staff_name
            has_update = true
        }
        if (Helper.checkMyRole('update_invoice_date') === true){
            data_post.receipt_bill_date = this.props.invoice.receipt_bill_date
            has_update = true
        }
        if (Helper.checkMyRole('update_invoice_channel') === true){
            data_post.sell_channel = this.props.invoice.sell_channel_selected.value
            has_update = true
        }
        if (has_update === true){
            this.props.onUpdateData(data_post, this.props.invoice.id, "save_button_loading").then(result => {
                if(result.status === true){
                    Alert.success(result.message)
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        }
    }
    onAddNewNote(){
        if(this.props.invoice.staff_notes_input === ""){
            this.props.onErrorField("staff_notes_error",true)
            this.notes_input.focus()
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng nhập ghi chú'
            })
            return;
        }
        this.props.onUpdateData({
            staff_notes: this.props.invoice.staff_notes_input
        }, this.props.invoice.id, "save_note_button_loading").then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
        this.notes_input.value = ""
    }
    onSetBillStatus(status){
        if(status === InvoiceActionType.STATUS_FINISH_INVOICE){
            Swal.fire({
                title: 'Xác nhận',
                text: "Bạn thật sự muốn hoàn thành hóa đơn này?",
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                reverseButtons: true,
                confirmButtonText: 'ĐỒNG Ý',
                cancelButtonText: 'KHÔNG',
                preConfirm: () => {
                    this.props.onSetBillStatus(this.props.invoice.id, {
                        status
                    }).then(result => {
                        if(result.status === true){
                            this.props.onGetInvoice(this.props.invoice.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                },
            })
        } else if(status === InvoiceActionType.STATUS_CANCEL_INVOICE){
            Swal.fire({
                title: 'Vui lòng nhập lý do hủy',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Tiếp tục',
                showLoaderOnConfirm: true,
                preConfirm: (reason_cancel) => {
                    if(reason_cancel !== ""){
                        this.props.onSetBillStatus(this.props.invoice.id, {
                            status,
                            reason_cancel
                        }).then(result => {
                            if(result.status === true){
                                this.props.onGetInvoice(this.props.invoice.id)
                            }
                            else if(result.status === false && result.message !== undefined && result.message !== ""){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Thông báo lỗi',
                                    text: result.message
                                })
                            }
                        })
                    } else {
                        Swal.showValidationMessage('Vui lòng nhập lý do')
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                //console.log("result: ",result)
                //console.log("result.isConfirmed: ",result.isConfirmed)
                if (result.isConfirmed) {
                    this.props.onGetInvoice(this.props.invoice.id)
                }
            })
        } else if(status === InvoiceActionType.STATUS_IN_PROGRESS_INVOICE){
            Swal.fire({
                title: 'Xác nhận',
                text: "Bạn thật sự muốn chuyển hóa đơn Re-order?",
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                reverseButtons: true,
                confirmButtonText: 'ĐỒNG Ý',
                cancelButtonText: 'KHÔNG',
                preConfirm: () => {
                    this.props.onSetBillStatus(this.props.invoice.id, {
                        status
                    }).then(result => {
                        if(result.status === true){
                            this.props.onGetInvoice(this.props.invoice.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                },
            })
        }
    }
    onReturnProduct(){
        this.props.history.push("/invoice/return?id=" + this.props.invoice.id);
    }
    onProductItemClick(id){
        this.props.history.push("/product/" + id);
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let gift_text = ""
                if (item.is_gift === 1){
                gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                }
                let price_after = item.price * item.quantity
                let discount_amount = item.discount_money
                let discount_text = <NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} />
                if (item.discount > 0){
                discount_amount = price_after * item.discount / 100
                discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} /></span>
                }
                price_after = price_after - discount_amount
                let total_amount = price_after
                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onProductItemClick(item.id) }}>
                        <td className="text-center"><img src={item.product_url} alt="" /></td>
                        <td>{ item.product_model }</td>
                        <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                        <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        { item.quantity_return > 0 ? <td className="text-right"><NumberFormat value={item.quantity_return} displayType={'text'} thousandSeparator={true} /></td> : <td></td> }
                        { this.props.invoice.is_reorder === 1 ? <td className="text-right"><NumberFormat value={item.quantity_in_stock} displayType={'text'} thousandSeparator={true} /></td> : <td></td> }
                        { this.props.invoice.is_reorder === 1 ? <td className="text-right"><NumberFormat value={item.quantity_other_branch} displayType={'text'} thousandSeparator={true} /></td> : <td></td> }
                        <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right">{ discount_text }</td>
                        <td className="text-right"><NumberFormat value={price_after} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    onSubmitImportReceiptTransfer(index, data_post){
        this.setState({
            btn_set_import_transfer_index: index
        })
        this.props.onSubmitImportReceiptTransfer(this.props.invoice.id, {
            product_id: data_post.product_id,
            product_option_id: data_post.product_option_id,
            import_receipt_id: data_post.import_receipt_id,
        }).then(res => {
            this.setState({
                btn_set_import_transfer_index: -1
            })
            if(res.status !== undefined){
                if(res.message !== undefined && res.message !== null && res.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        html: res.message
                    })
                }
            }
        })
    }
    showListProductWithExport(lists){
        var result = null;
        if(lists.length > 0){
            let block_other_branch = false
            let branch_selected = Helper.getBranchSelected()
            if(branch_selected.id !== this.props.invoice.branch_id) {
                block_other_branch = true
            }
            lists.forEach(item => {
                let price_after = item.price * item.quantity
                let discount_amount = item.discount_money
                if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                }
                price_after = price_after - discount_amount
            })
            result = lists.map((item, index) => {
                let gift_text = ""
                if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                }
                let price_after = item.price * item.quantity
                let discount_amount = item.discount_money
                if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                }
                price_after = price_after - discount_amount
                let discount_receipt = 0
                if (item.discount_receipt > 0){
                    discount_receipt = item.discount_receipt * item.quantity
                    price_after = price_after  - discount_receipt
                }
                let total_amount = price_after
                let total_retail = 0
                let has_expiry = false
                let export_info_text = ""
                if (item.stores.length > 0){
                    export_info_text = item.stores.map((store_item, store_index) => {
                        let real_quantity = store_item.quantity - store_item.quantity_return
                        let retail  = store_item.retail * real_quantity
                        total_retail += retail
                        retail = Helper.parseMoney(retail)
                        let import_receipt_cancel_style = "text-normal"
                        if (store_item.import_receipt_status === PurchaseOrderActionType.STATUS_CANCEL_PURCHASE_ORDER){
                            import_receipt_cancel_style = "text-danger"
                        }
                        let btn_loading = false
                        if(this.state.btn_set_import_transfer_index === store_index){
                            btn_loading = true
                        }
                        let expiry_info = <NumberFormat value={real_quantity} displayType={'text'} thousandSeparator={true} />
                        if(store_item.expiry_info !== undefined && store_item.expiry_info !== null && store_item.expiry_info.length > 0){
                            expiry_info = store_item.expiry_info.map((expiry_info_item, expiry_info_index) => {
                                return <div key={index+'_'+store_index+'_'+expiry_info_index}>
                                        <Moment format="DD/MM/YYYY">{ Helper.replaceMysqlDateTime(expiry_info_item.expiry_date) }</Moment> ({expiry_info_item.quantity})
                                </div>
                            })
                            has_expiry = true
                        }
                        return <tr key={index+'_'+store_index}>
                                {has_expiry === false ? <td><Link className={import_receipt_cancel_style} to={"/purchase-order/" + store_item.import_receipt_id} target="_blank">{store_item.import_receipt_code}</Link></td> : null }
                                {has_expiry === false ? <td className="text-right">{expiry_info}</td> : null }
                                {has_expiry === true ? <td><Link className={import_receipt_cancel_style} to={"/purchase-order/" + store_item.import_receipt_id} target="_blank">{store_item.import_receipt_code}</Link>{expiry_info}</td> : null }
                                <td className="text-right"><NumberFormat value={Helper.parseMoney(store_item.retail)} displayType={'text'} thousandSeparator={true} /></td>
                                <td className="text-right"><NumberFormat value={retail} displayType={'text'} thousandSeparator={true} /></td>
                                {block_other_branch === false ? <td className="text-center">{store_item.import_receipt_status === PurchaseOrderActionType.STATUS_CANCEL_PURCHASE_ORDER ? 
                                    <LaddaButton
                                        loading={ btn_loading }
                                        data-style={ZOOM_IN}
                                        data-spinner-color="#000000"
                                        className="btn btn-link btn-sm" onClick={ () => { this.onSubmitImportReceiptTransfer(store_index, {
                                            product_id: item.id, 
                                            product_option_id: item.product_option_id, 
                                            import_receipt_id: store_item.import_receipt_id, 
                                        }) } } >
                                        <i className="fa fa-external-link"></i>
                                    </LaddaButton>
                                    : null }</td> : null }
                        </tr>
                    })
                }
                total_retail = Helper.parseMoney(total_retail)
                let profit = total_amount - total_retail
                profit = Helper.parseMoney(profit)
                return (
                    <tr key={ index }>
                        <td className="text-center"><img src={item.product_url} alt="" /></td>
                        <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                        <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        { item.quantity_return > 0 ? <td className="text-right"><NumberFormat value={item.quantity_return} displayType={'text'} thousandSeparator={true} /></td> : <td></td> }
                        <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.price  * item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={discount_receipt} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td>
                            { (item.stores.length > 0) ?
                            <table className="table table-bordered">
                                <colgroup>
                                    {has_expiry === false ? <col width="5%"></col> : null }
                                    <col></col>
                                    <col width="7%"></col>
                                    <col width="7%"></col>
                                    <col width="5%"></col>
                                </colgroup>
                                <thead>
                                    <tr>
                                        {has_expiry === false ? <th>Mã phiếu</th> : null }
                                        {has_expiry === false ? <th className="text-right">SL</th> : <th className="text-right">Hạn dùng (SL)</th> }
                                        <th className="text-right">GN</th>
                                        <th className="text-right">Tổng</th>
                                        {block_other_branch === false ? <th></th> : null }
                                    </tr>
                                </thead>
                                <tbody>
                            { export_info_text }
                            </tbody></table>
                            : "" }
                        </td>
                        <td className="text-right"><NumberFormat value={total_retail} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={profit} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    onCancelPaymentInvoiceItem(id){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy thanh toán',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancelPaymentInvoice(reason_cancel, id).then(result => {
                        if(result.status === true){
                            this.props.onGetInvoice(this.props.invoice.id)
                            this.props.onGetPaymentBills(this.props.invoice.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    showListPaymentBills(lists){
        var result = null;
        if(lists.length > 0){
            let accounting_access = false
            if (Helper.checkMyRole('accounting_access') === true){
                accounting_access = true
            }
            let update_payment_cancel = false
            if (Helper.checkMyRole('update_payment_invoice_cancel') === true){
                update_payment_cancel = true
            }
            let update_finish_payment_cancel = false
            if (Helper.checkMyRole('update_payment_invoice_cancel_admin') === true){
                update_finish_payment_cancel = true
            }
            result = lists.map((item, index) => {
                let payment_method = []
                let is_cod = false, is_return_bill = false
                if (item.payment_bill_finance.length > 0){
                    item.payment_bill_finance.forEach((methodItem, index) => {
                        let source_name = ""
                        if (methodItem.is_cash === 1){
                            source_name = "Tiền mặt"
                        } else if (methodItem.is_cod){
                            source_name = "Thu hộ (COD)"
                            is_cod = true
                        } else if (methodItem.is_return_bill){
                            source_name = "Tiền trả hàng"
                            is_return_bill = true
                        } else if (methodItem.finance_source_name){
                            source_name = methodItem.finance_source_name
                        }
                        payment_method.push(<h5 key={index} ><label className="label label-info mr-right-5">{source_name}: <NumberFormat value={methodItem.amount} displayType={'text'} thousandSeparator={true} /></label></h5>)    
                    })
                }
                let status_text = ""
                if (item.payment_bill_status !== PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE){
                    status_text = <label className='label' style={{backgroundColor: item.payment_bill_status_color }}>{ item.payment_bill_status_text }</label>
                }
                return (
                    <tr key={ index }>
                        <td>{ item.payment_bill_code }</td>
                        <td>{ moment(Helper.replaceMysqlDateTime(item.payment_bill_date), moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</td>
                        <td>{payment_method}</td>
                        <td>{ item.staff_name }</td>
                        <td>{ status_text }</td>
                        <td className="text-center">
                            { accounting_access === true ? <Link to={`/accounting/invoice/${item.id}`} target="_blank">Xem hóa đơn</Link> : "" }
                            { (update_payment_cancel === true && is_cod === false && is_return_bill === false && (item.payment_bill_status === PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE || update_finish_payment_cancel === true)) ? 
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onCancelPaymentInvoiceItem(item.id) } } >
                                <i className="fa fa-remove"></i>&nbsp;Hủy thanh toán
                            </LaddaButton> : "" }
                        </td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    showShipLogs(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                return (
                    <tr key={ index }>
                        <td><Moment unix format="HH:mm DD/MM/YYYY">{ item.time }</Moment></td>
                        <td className="text-center"><label className='label' style={{backgroundColor: item.receipt_bill_ship_status_color }}>{ item.receipt_bill_ship_status_text }</label></td>
                        <td>{ item.transporter_name }</td>
                        <td>{ item.receiver_name }<br />{ item.receiver_address }</td>
                        <td>{ item.staff_update_name }</td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.invoice.id, id)
            },
        })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                return (
                    <tr key={ index }>
                        <td>{ item.content }</td>
                        <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                        <td>{ item.staff_name }</td>
                        <td className="text-center">
                        { this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_IN_PROGRESS_INVOICE ? 
                        <LaddaButton
                            loading={ item.loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                            <i className="fa fa-remove"></i>
                        </LaddaButton> : "" }
                        </td>
                    </tr>  
                );   
            });
        }
        return result;
    }

    handleModalClose(){
        this.props.onShowModal(false);
    }
    handleModalSubmit(submit_type){
        if (submit_type === "submit_add_user"){
            this.handleSubmitFormAddCustomer()
        }
    }
    handleSubmitFormAddCustomer(){
        this.createPartnerRef.onSubmit()
    }
    onCreatePartnerReturn(user_info){
        if (user_info.user_type === UserActionType.TRANSPORTER_USER_TYPE){
            this.props.onFieldChange("transporter_selected", {
                label: user_info.name,
                value: user_info.id,
                is_staff: user_info.is_staff_transporter,
            })
        }
        this.handleModalClose();
    }
    onPrint() {
        let id = 'receipt'
        const iframe = document.frames
        ? document.frames[id]
        : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();
        return false;
    }
    showPrintButton(){
        this.props.onFieldChange("show_print_button", true)
    }
    render(){
        let { receipt_bill_code, receipt_bill_date, receipt_bill_notes, is_reorder, is_pending_import, staff_notes, last_notes, last_notes_staff_name, last_notes_time, staff_notes_input, staff_notes_active, staff_notes_error, products, payment_bills, ship_logs, total_quantity, total_amount, ship_fee, other_fee, money_collect_cod, discount, discount_mode, total_payment_amount, save_note_button_loading, has_shipping, has_cod, receiver_pay_fee, save_button_loading, save_button_copy_loading, branch_id, return_bill_id, total_amount_return} = this.props.invoice;
        let block_other_branch = false
        let branch_selected = Helper.getBranchSelected()
        if(branch_selected.id !== branch_id) {
            block_other_branch = true
        }
        receipt_bill_date = Helper.replaceMysqlDateTime(receipt_bill_date)
        receiver_pay_fee = parseInt(receiver_pay_fee)
        let receiver_pay_fee_text = ""
        if (receiver_pay_fee === InvoiceActionType.SENDER_PAY_SHIP_FEE){
            receiver_pay_fee_text = "Moby trả"
        } else if (receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
            receiver_pay_fee_text = "Khách trả"
        }
        let notes_field_class = "nk-int-st"
        if (staff_notes_input !== "") {
            staff_notes_active = true;
        }
        if(staff_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        if(staff_notes_error === true){
            notes_field_class += ' error';
        }
        let hide_display_discount = "hide", hide_display_ship_fee = "hide", hide_display_other_fee = "hide", hide_display_cod = "hide", hide_display_amount_after = "hide"
        let total_amount_after = total_amount
        let discount_text = "0"
        if(discount > 0){
            hide_display_discount = ""
            hide_display_amount_after = ""
            if(discount_mode === DISCOUNT_MODE_BY_MONEY){
                total_amount_after = total_amount_after - discount
                discount_text = <NumberFormat value={discount} displayType={'text'} thousandSeparator={true} />
            } else {
                let discount_value = total_amount_after * discount / 100
                total_amount_after = total_amount_after - discount_value
                discount_text = <span>({ discount }%) <NumberFormat value={discount_value} displayType={'text'} thousandSeparator={true} /></span>
            }
        }   
        if(other_fee > 0){
            hide_display_other_fee = ""
            total_amount_after = total_amount_after + other_fee
        }
        let total_amount_real_pay = total_amount_after
        if(ship_fee > 0){
            hide_display_ship_fee = ""
            if (+receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                total_amount_after = total_amount_after + ship_fee
                total_amount_real_pay = total_amount_real_pay + ship_fee
            } else if (+receiver_pay_fee === InvoiceActionType.SENDER_PAY_SHIP_FEE){
                total_amount_real_pay = total_amount_after - ship_fee
            }
        }
        let money_collect_cod_cal = 0, money_collect_cod_final = 0, cod_amount = 0, remain_amount = total_amount_after
        let col_span = 10       
        let htmlPaymentRemainAmount = [], htmlCustomerPayment = ""
        let add_more_payment = false
        if (total_payment_amount > 0){
            if (payment_bills.length > 0){
                payment_bills.forEach(payment_bill_item => {
                    if (payment_bill_item.is_cod === 1){
                        total_payment_amount = total_payment_amount - payment_bill_item.amount
                    }
                })
            }
            remain_amount = total_amount_after - total_payment_amount
            htmlPaymentRemainAmount.push(<tr key="customer_paid">
                <td className="text-right" colSpan={col_span}>Khách đã trả</td>
                <td className="text-right"><NumberFormat value={total_payment_amount} displayType={'text'} thousandSeparator={true} /></td>
            </tr>)  
        } 
        let hide_display = ""
        if(total_amount_after === 0){
            hide_display = "hide"
        }
        //console.log("remain_amount: ",remain_amount)
        //console.log("has_cod: ",has_cod)
        money_collect_cod_cal = remain_amount
        if (remain_amount > 0){
            if (has_cod === true && money_collect_cod_cal === remain_amount){
                remain_amount = 0
            } else {
                add_more_payment = true
            }
            if (remain_amount > 0 && remain_amount !== total_amount_after) {
                htmlPaymentRemainAmount.push(<tr key="paid_more" className={hide_display}>
                    <td className="text-right" colSpan={col_span}>Cần trả thêm</td>
                    <td className="text-right"><NumberFormat value={remain_amount} displayType={'text'} thousandSeparator={true} /></td>
                </tr>)
            }
        } else {
            cod_amount = money_collect_cod_cal
            if (remain_amount !== 0){
                htmlPaymentRemainAmount.push(<tr key="return_customer" className={hide_display}>
                        <td className="text-right" colSpan={col_span}>Trả lại khách</td>
                        <td className="text-right"><NumberFormat value={remain_amount * (-1)} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>)
            }
        }
        if (return_bill_id > 0 && total_amount_return > 0){         
            htmlPaymentRemainAmount.push(<tr key="amount_return" className={hide_display}>
                <td className="text-right" colSpan={col_span}>Khách trả hàng</td>
                <td className="text-right"><NumberFormat value={total_amount_return} displayType={'text'} thousandSeparator={true} /></td>
            </tr>)  
        }
        cod_amount = money_collect_cod_cal
        if (has_cod === true){
            money_collect_cod_final = money_collect_cod_cal
            hide_display_cod = ""
        }
        //console.log("cod_amount: ",cod_amount)
        if (!(this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_FINISH_INVOICE || this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE)){
            htmlCustomerPayment = <PaymentInput button={true} amount={remain_amount} payment_amount={0} onChange={(value,payment_text) => { this.onPaymentFieldChange(value,payment_text) }} />
        }
        let bill_reorder = false
        let show_tab_staff_note = true, show_tab_ship_logs = true
        let allow_update = false, allow_update_cancel_status = false, allow_update_cod = false, allow_update_ship = false, allow_update_ship_status = false, allow_update_date = false, allow_update_seller = false, allow_update_channel = false, allow_copy = false, allow_return_product = false
        let show_shipping_info = true, allow_add_new_note = true
        if (Helper.checkMyRole('update_invoice_cancel') === true){
            allow_update_cancel_status = true
        }
        if (Helper.checkMyRole('update_invoice_date') === true){
            allow_update_date = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_invoice_seller') === true){
            allow_update_seller = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_invoice_ship') === true){
            allow_update_cod = true
            allow_update_ship = true
            allow_update_ship_status = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_invoice_channel') === true){
            allow_update_channel = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_invoice_copy') === true){
            allow_copy = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_invoice_return') === true){
            allow_return_product = true
            allow_update = true
        }
        let view_branch = false
        if (Helper.checkMyRole('view_invoice_branch') === true){
            view_branch = true
        }
        if (this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_FINISH_INVOICE || this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE || this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_RETURN_INVOICE){
            allow_update = false
            allow_update_cancel_status = false
            allow_update_ship = false
            allow_update_cod = false
            allow_update_ship_status = false
            allow_update_date = false
            allow_update_seller = false
            allow_update_channel = false
            allow_add_new_note = false
            show_shipping_info = has_shipping
            if (staff_notes.length === 0){
                show_tab_staff_note = false
            }
            if (ship_logs.length === 0){
                show_tab_ship_logs = false
            }
        } else if (this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_REORDER_INVOICE) {
            bill_reorder = true
            allow_return_product = false
        }
        if (this.props.invoice.receipt_bill_ship_status === InvoiceActionType.SHIP_STATUS_DONE_INVOICE){
            allow_update_ship = false
            allow_update_ship_status = false
        } else if (this.props.invoice.receipt_bill_ship_status === InvoiceActionType.SHIP_STATUS_RETURNING_INVOICE){
            allow_update_ship = false
            allow_update_cod = false
        }
        let html_alert_bill_status = ""
        if(this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_FINISH_INVOICE && this.props.invoice.staff_finish_id > 0){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-success text-center">
                    <h3>{ this.props.invoice.staff_finish_name } hoàn thành lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.invoice.finish_time }</Moment></h3>
                </div>
            </div>  
        } else if(this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
            allow_return_product = false
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.invoice.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.invoice.cancel_time }</Moment><br />
                        Lý do: { this.props.invoice.reason_cancel }
                    </h3><br />
                    { allow_copy === true ?
                        <LaddaButton
                            loading={ save_button_copy_loading }
                            data-size={L}
                            data-style={ZOOM_IN}
                            className="btn btn-info mr-right-5" onClick={ () => { this.onCopyData() } } >
                            <i className="fa fa-copy"></i>&nbsp;Sao chép hóa đơn
                        </LaddaButton> : "" }
                </div>
            </div>  
        }
        let payment_method_text = [], total_payment_by_cash = 0, total_payment_by_bank = 0, total_payment_by_card = 0, total_payment_by_return_bill = 0
        let paid_by_cod_is_done = false, paid_by_bank_is_done = false, paid_by_card_is_done = false, paid_by_cash_is_done = false, paid_by_return_bill_is_done = false
        if(payment_bills.length > 0){
            payment_bills.forEach(item => {
                if (item.payment_bill_finance.length > 0){
                    item.payment_bill_finance.forEach((methodItem, _) => {
                        if (methodItem.is_cash === 1){
                            total_payment_by_cash += methodItem.amount
                            if (item.is_done === 1){
                                paid_by_cash_is_done = true
                            }
                        } else if (methodItem.is_bank === 1){
                            total_payment_by_bank += methodItem.amount
                            if (item.is_done === 1){
                                paid_by_bank_is_done = true
                            }
                        } else if (methodItem.is_card === 1){
                            total_payment_by_card += methodItem.amount
                            if (item.is_done === 1){
                                paid_by_card_is_done = true
                            }
                        } else if (methodItem.is_return_bill === 1){
                            total_payment_by_return_bill += methodItem.amount
                            if (item.is_done === 1){
                                paid_by_return_bill_is_done = true
                            }
                        }  else if (methodItem.is_cod === 1 && item.is_done === 1){
                            paid_by_cod_is_done = true
                        }
                    })
                }
            })
            let payment_done = "", payment_label_done = "label-info"
            if (total_payment_by_return_bill > 0){
                if (paid_by_return_bill_is_done === true){
                    payment_done = <span><i className="fa fa-check-circle"></i>&nbsp;</span>
                    payment_label_done = "label-success"
                }
                payment_method_text.push(<label key="total_payment_by_return_bill" className={"label "+payment_label_done+" mr-right-5"}>{payment_done}Tiền trả hàng: <NumberFormat value={total_payment_by_return_bill} displayType={'text'} thousandSeparator={true} /></label>)
            }
            if (total_payment_by_cash > 0){
                if (paid_by_cash_is_done === true){
                    payment_done = <span><i className="fa fa-check-circle"></i>&nbsp;</span>
                    payment_label_done = "label-success"
                }
                payment_method_text.push(<label key="total_payment_by_cash" className={"label "+payment_label_done+" mr-right-5"}>{payment_done}Tiền mặt: <NumberFormat value={total_payment_by_cash} displayType={'text'} thousandSeparator={true} /></label>)
            }
            if (total_payment_by_bank > 0){
                if (paid_by_bank_is_done === true){
                    payment_done = <span><i className="fa fa-check-circle"></i>&nbsp;</span>
                    payment_label_done = "label-success"
                }
                payment_method_text.push(<label key="total_payment_by_bank" className={"label "+payment_label_done+" mr-right-5"}>{payment_done}Chuyển khoản: <NumberFormat value={total_payment_by_bank} displayType={'text'} thousandSeparator={true} /></label>)
            }
            if (total_payment_by_card > 0){
                if (paid_by_card_is_done === true){
                    payment_done = <span><i className="fa fa-check-circle"></i>&nbsp;</span>
                    payment_label_done = "label-success"
                }
                payment_method_text.push(<label key="total_payment_by_card" className={"label "+payment_label_done}>{payment_done}Quẹt thẻ: <NumberFormat value={total_payment_by_card} displayType={'text'} thousandSeparator={true} /></label>)
            }
        }
        if(money_collect_cod > 0){
            let payment_done = "", payment_label_done = "label-info"
            if (paid_by_cod_is_done === true){
                payment_done = <span><i className="fa fa-check-circle"></i>&nbsp;</span>
                payment_label_done = "label-success"
            }
            payment_method_text.push(<label key="total_payment_by_cod" className={"label "+payment_label_done+" mr-right-5"}>{payment_done}Thu hộ (COD): <NumberFormat value={money_collect_cod} displayType={'text'} thousandSeparator={true} /></label>)
        }
        let is_reorder_text = ""
        if(this.props.invoice.is_reorder === 1 && this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
            is_reorder_text = <span className="text-danger">Re-order&nbsp;-&nbsp;</span>
        }
        if(this.props.invoice.receipt_bill_status !== InvoiceActionType.STATUS_CANCEL_INVOICE && Helper.checkMyRole('update_invoice_cancel_admin') === true){
            allow_update_cancel_status = true
        }
        if(this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_RETURN_INVOICE){
            allow_update_cancel_status = false
        }
        if (paid_by_cod_is_done === true){
            allow_update_cod = false
        }
        let allow_change_to_main = allow_update
        if(Helper.isViewer() === true){
            allow_change_to_main = false
        }
        if(allow_update_ship_status === true && this.props.invoice.transporter_selected !== undefined && this.props.invoice.transporter_selected !== null && this.props.invoice.transporter_selected.is_staff !== undefined && this.props.invoice.transporter_selected.is_staff === 1){
            allow_update_ship_status = false
        }
        if (block_other_branch === true){
            allow_update = false
            allow_update_cancel_status = false
            allow_update_seller = false
            allow_update_date = false
            allow_update_ship = false
            allow_update_ship_status = false
            add_more_payment = false
            allow_change_to_main = false
            allow_update_channel = false
        }
        if (is_pending_import === 1){
            allow_update = false
            allow_update_cancel_status = false
            allow_update_ship = false
            allow_update_ship_status = false
            allow_update_date = false
            allow_update_seller = false
            allow_update_channel = false
            allow_add_new_note = false
            allow_return_product = false
            allow_update_cancel_status = false
            allow_change_to_main = false
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>Hóa đơn đang bị treo do có phiếu nhập hàng chuyển kho đã bị hủy</h3>
                    Vui lòng xem thông tin xuất hàng
                </div>
            </div> 
        }
        let has_export_info = false
        let total_revenue = total_amount_after, profit = 0, total_discount_receipt = 0
        let total_retail = 0
        
        if(other_fee > 0){
            total_revenue = total_revenue - other_fee
        }
        if(ship_fee > 0 && +receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
            total_revenue = total_revenue - ship_fee
        }
        //allow_return_product = false
        if (products.length > 0){
            if (allow_return_product === true){
                let has_product_return = false
                for(let i = 0; i < products.length; i++){
                    if((products[i].quantity - products[i].quantity_return) > 0){
                        has_product_return = true
                        break
                    }
                }
                if (has_product_return === false){
                    allow_return_product = false
                }
            }
            for(let product_key = 0; product_key < products.length; product_key++){
                let item = products[product_key]
                if(item.stores !== undefined && item.stores !== null && item.stores.length > 0){
                    has_export_info = true
                    for(let store_key = 0; store_key < item.stores.length; store_key++){
                        let store_item = item.stores[store_key]
                        total_retail += (store_item.retail * (store_item.quantity - store_item.quantity_return))
                    }
                }
                total_discount_receipt += item.discount_receipt * item.quantity
            }
            profit = total_revenue - total_retail
            total_retail = Helper.parseMoney(total_retail)
            profit = Helper.parseMoney(profit)
        }
        let link_print_info = "/invoice/print/" + this.props.invoice.id
        if(this.state.change_shipping_info === true){
            link_print_info = "/invoice/print/" + this.props.invoice.id + "?time=" + (new Date().getTime().toString())
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Hóa đơn bán hàng { receipt_bill_code }</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/invoice/" + this.props.invoice.id} />
                </Helmet>   
                <div className="row">
                    { html_alert_bill_status }
                    <div className="col-xs-12">
                        <div className="pull-left">
                            <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                                <li>
                                    <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                                </li>
                                <li className="active">
                                    <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                                </li>
                                { has_export_info === true ?
                                <li>
                                    <a data-toggle="tab" href="#export_info" aria-expanded="false">Thông tin xuất hàng</a>
                                </li> : "" }
                                <li>
                                    <a data-toggle="tab" href="#payment" aria-expanded="false">Lịch sử thanh toán</a>
                                </li>
                                { show_tab_ship_logs === true ? 
                                <li>
                                    <a data-toggle="tab" href="#ship_log" aria-expanded="false">Lịch sử giao hàng</a>
                                </li> : "" } 
                                { show_tab_staff_note === true ? 
                                <li>
                                    <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                                </li> : "" }
                            </ul>
                        </div>
                        <div className="pull-right">
                            { allow_update === true ?
                            <LaddaButton
                                loading={ save_button_loading }
                                data-style={ZOOM_IN}
                                className="btn btn-success" onClick={ () => { this.onSaveData() } } >
                                <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                            </LaddaButton> : "" }
                            { this.props.invoice.show_print_button === true ? 
                            <button type="button"
                                className="btn btn-info mr-left-5" onClick={ () => { this.onPrint() } } >
                                    <i className="fa fa-print"></i>&nbsp;In hóa đơn
                                
                            </button> : "" }
                            { allow_update_cancel_status === true ?
                            <button type="button"
                                className="btn btn-danger mr-left-5" onClick={ () => { this.onSetBillStatus(InvoiceActionType.STATUS_CANCEL_INVOICE) } } >
                                <i className="fa fa-remove"></i>&nbsp;Hủy hóa đơn
                            </button> : "" }
                            { allow_return_product === true ?
                            <button type="button"
                                className="btn btn-default mr-left-5" onClick={ () => { this.onReturnProduct() } } >
                                <i className="fa fa-reply-all"></i>&nbsp;Trả hàng
                            </button> : "" }
                            <iframe
                                id="receipt"
                                src={link_print_info}
                                style={{ display: 'none' }}
                                title="Receipt"
                                onLoad={() => { this.showPrintButton() }}
                            />
                        </div>
                        <div className="cl"></div>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in">
                                <div className="form-element-list" >
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Mã phiếu:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <strong>{ receipt_bill_code }</strong>
                                                        {this.state.bill_lading_info !== null&& this.state.bill_lading_info.id !== undefined && this.state.bill_lading_info.id > 0 ?
                                                        <button type="button" className="btn btn-link btn-sm" onClick={() => window.open("/bill-lading/" + this.state.bill_lading_info.id)}>(Xem vận đơn)</button> : null }
                                                    </div>
                                                </div>
                                            </div>
                                            { this.props.invoice.return_receipt_bill_id > 0 ? 
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Hóa đơn gốc:<br /> (trả hàng)</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <NavLink to={"/invoice/" + this.props.invoice.return_receipt_bill_id} target="_blank">{ this.props.invoice.return_receipt_bill_code }</NavLink>
                                                        { this.props.invoice.ship_fee_shop_paid_return > 0 ?
                                                        <span><br /> PVC (Moby trả): { Helper.parseMoneyText(this.props.invoice.ship_fee_shop_paid_return) }</span> : "" }
                                                    </div>
                                                </div>
                                            </div> : "" }
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className={allow_update_date === true ? "col-lg-3 col-md-3 col-sm-3 col-xs-12 mr-top-7" : "col-lg-3 col-md-3 col-sm-3 col-xs-12"}>
                                                        <label className="hrzn-fm">Thời gian:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        { allow_update_date === true ? 
                                                        <div>
                                                        <DatePicker format="YYYY-MM-DD HH:mm" value={new Date(moment(receipt_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())} placement="bottomStart" size="md" cleanable={false} ranges={[]} onChange = { (value) => { this.onReceiptBillDatePickerChange(value) } } />
                                                        </div> : <span>{ moment(receipt_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>  }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Khách hàng:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <NavLink to={"/customer/" + this.props.invoice.customer_id} target="_blank">{ this.props.invoice.customer_code } - { this.props.invoice.customer_name }</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className={allow_update_seller === true ? "col-lg-3 col-md-3 col-sm-3 col-xs-12 mr-top-7" : "col-lg-3 col-md-3 col-sm-3 col-xs-12"}>
                                                        <label className="hrzn-fm">Người bán:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        { allow_update_seller === true ? 
                                                        <div>
                                                            <UserSelectBox block={false} placeholder="Tìm nhân viên bán hàng" allow_update={allow_update_seller} cleanable={false} user_type={UserActionType.STAFF_USER_TYPE} user_selected={{
                                                                    label: this.props.invoice.staff_name,
                                                                    value: this.props.invoice.staff_id
                                                                }} use_for_create={true} modal_inside={false} onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                                                        </div>
                                                        : <span>{this.props.invoice.staff_name}</span> }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className={this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_REORDER_INVOICE ? "col-lg-4 col-md-4 col-sm-4 col-xs-12 mr-top-5" : "col-lg-4 col-md-4 col-sm-4 col-xs-12"}>
                                                        <label className="hrzn-fm">Trạng thái:</label>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                        {this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_REORDER_INVOICE  ? 
                                                        <div>
                                                            <div className="pull-left mr-top-5">
                                                                <span style={{ fontSize: 15, color: this.props.invoice.receipt_bill_status_color }}>{this.props.invoice.receipt_bill_status_text}</span>
                                                            </div>
                                                            { allow_change_to_main === true ? 
                                                            <div className="pull-left mr-left-10">
                                                                <button type="button"
                                                                    className="btn btn-info" onClick={ () => { this.onSetBillStatus(InvoiceActionType.STATUS_IN_PROGRESS_INVOICE) } } >
                                                                    <i className="fa fa-arrow-right"></i>&nbsp;Khách mua hàng
                                                                </button>
                                                            </div> : "" }
                                                        </div> : <span style={{ fontSize: 15, color: this.props.invoice.receipt_bill_status_color }}>{is_reorder_text}{this.props.invoice.receipt_bill_status_text}</span> }
                                                    </div>
                                                </div>
                                            </div>
                                            { show_shipping_info === true ? 
                                            <div>
                                                { this.props.invoice.transporter_name  !== "" ? 
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label className="hrzn-fm">
                                                                Đối tác giao hàng:
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <span>{ this.props.invoice.transporter_name }</span>
                                                        </div>
                                                    </div>
                                                </div> : "" }
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <div className="row">
                                                        <div className={allow_update_ship === true ? "col-lg-4 col-md-4 col-sm-4 col-xs-12 mr-top-7" : "col-lg-4 col-md-4 col-sm-4 col-xs-12"}>
                                                            <label className="hrzn-fm">
                                                                { (has_shipping === true && (this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_FINISH_INVOICE || this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_IN_PROGRESS_INVOICE) ) ? "Trạng thái giao hàng:" : "Thông tin giao hàng:" }
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <InvoiceShipping allow_update_cod={allow_update_cod} allow_update={allow_update_ship} allow_update_ship_status={allow_update_ship_status} in_view={true} bill_reorder={bill_reorder}  cod_amount={cod_amount} receiver_name_error={this.props.invoice.receiver_name_error} receiver_phone_error={this.props.invoice.receiver_phone_error} receiver_address_error={this.props.invoice.receiver_address_error}   refresh_info={() => this.onRefreshInfo() } getRef={ref => (this.invoiceShippingRef = ref)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : "" }
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className={allow_update_channel === true ? "col-lg-4 col-md-4 col-sm-4 col-xs-12 mr-top-7" : "col-lg-4 col-md-4 col-sm-4 col-xs-12"}>
                                                        <label className="hrzn-fm">Kênh bán:</label>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                        { allow_update_channel === true ? 
                                                        <div>
                                                            <SellChannelSelectBox block={false} selected={this.props.invoice.sell_channel_selected} onFieldChange={(value) => this.props.onFieldChange("sell_channel_selected",value) } /> 
                                                        </div>
                                                        : <span>{ this.props.invoice.sell_channel_selected.label !== undefined ? this.props.invoice.sell_channel_selected.label : "" }</span> }
                                                    </div>
                                                </div>
                                            </div>
                                            { view_branch === true ? 
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                        <label className="hrzn-fm">Chi nhánh:</label>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                        {this.props.invoice.branch_name}    
                                                    </div>
                                                </div>
                                            </div> : "" }
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            { receipt_bill_notes ? 
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <label className="hrzn-fm">Khách ghi chú:</label>
                                                    </div>
                                                    <div className="col-xs-12">
                                                        {receipt_bill_notes}
                                                    </div>
                                                </div>
                                            </div> : "" }
                                            { (add_more_payment === true || payment_method_text.length > 0) ?
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="pull-left">
                                                            <label className="hrzn-fm">Phương thức thanh toán:</label>
                                                        </div>
                                                        { add_more_payment === true ? 
                                                        <div className="pull-left mr-left-5 mr-top-3">
                                                            {htmlCustomerPayment}
                                                        </div> : "" }
                                                    </div>
                                                    <div className="col-xs-12 payment-method-text-container">
                                                        <h5>{payment_method_text}</h5>
                                                    </div>
                                                </div>
                                            </div> : "" }
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                { last_notes ? 
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <label className="hrzn-fm">Nhân viên ghi chú mới nhất:</label>
                                                    </div>
                                                    <div className="col-xs-12">
                                                    {last_notes_staff_name} ({moment(last_notes_time * 1000).format("YYYY-MM-DD HH:mm").toString()}): {last_notes}
                                                    </div>
                                                    <div className="col-xs-12">
                                                        <hr />
                                                    </div>
                                                </div>  : ""   }
                                                { allow_add_new_note === true ?
                                                <div className="row mr-top-10">    
                                                    <div className="col-xs-12">
                                                        <div className="pull-left w-90">
                                                            <div className={notes_field_class}>
                                                                <TextareaAutoHeight placeholder="Thêm ghi chú" name={"staff_notes_input"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                                            </div>
                                                        </div>
                                                        <div className="pull-left mr-left-5">
                                                            <LaddaButton
                                                                loading={ save_note_button_loading }
                                                                data-size={L}
                                                                data-style={ZOOM_IN}
                                                                data-spinner-color="#000000"
                                                                className="btn btn-link" onClick={ () => { this.onAddNewNote() } } >
                                                                <i className="fa fa-save"></i>
                                                            </LaddaButton>
                                                        </div>
                                                        <div className="cl"></div>
                                                    </div>
                                                </div> : "" }
                                            </div>
                                        </div>
                                    </div>    
                                </div>    
                                <div className="normal-table-list mr-top-20">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex table-hover table-condensed">
                                            <colgroup>
                                            <col width="5%"></col>
                                            <col width="10%"></col>
                                            <col></col>
                                            <col width="10%"></col>
                                            {this.props.invoice.return_bill_id > 0 ? <col width="10%"></col> : <col width="0%"></col>}
                                            {is_reorder === 1 ? <col width="10%"></col> : <col width="0%"></col> }
                                            {is_reorder === 1 ? <col width="10%"></col> : <col width="0%"></col> }
                                            <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Mã hàng</th>
                                                    <th>Tên hàng</th>
                                                    <th className="text-right">Số lượng</th>
                                                    {this.props.invoice.return_bill_id > 0 ? <th className="text-right">SL trả</th> : <th></th> }
                                                    {is_reorder === 1 ? <th className="text-right">Tồn kho</th> : <th></th> }
                                                    {is_reorder === 1 ? <th className="text-right">Liên CN</th> : <th></th> }
                                                    <th className="text-right">Đơn giá</th>
                                                    <th className="text-right">Giảm giá</th>
                                                    <th className="text-right">Giá bán</th>
                                                    <th className="text-right">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showListProduct(products) }
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td className="text-right" colSpan={col_span}>Tổng số lượng</td>
                                                    <td className="text-right">{ total_quantity }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-right" colSpan={col_span}>Tổng tiền hàng</td>
                                                    <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                                <tr className={hide_display_discount}>
                                                    <td className="text-right" colSpan={col_span}>Giảm giá hóa đơn</td>
                                                    <td className="text-right">{discount_text}</td>
                                                </tr>
                                                <tr className={hide_display_ship_fee}>
                                                    <td className="text-right" colSpan={col_span}>Phí vận chuyển {"("+receiver_pay_fee_text+")"}</td>
                                                    <td className="text-right"><NumberFormat value={ship_fee} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                                <tr className={hide_display_other_fee}>
                                                    <td className="text-right" colSpan={col_span}>Chi phí khác</td>
                                                    <td className="text-right"><NumberFormat value={other_fee} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                                <tr className={hide_display_amount_after}>
                                                    <td className="text-right" colSpan={col_span}>Tổng sau giảm</td>
                                                    <td className="text-right"><NumberFormat value={total_amount_after} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                                <tr className={hide_display}>
                                                    <td className="text-right" colSpan={col_span}>Khách cần trả</td>
                                                    <td className="text-right"><NumberFormat value={total_amount_real_pay} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                                <tr className={hide_display_cod}>
                                                    <td className="text-right" colSpan={col_span}>Thu hộ (COD)</td>
                                                    <td className="text-right"><NumberFormat value={money_collect_cod_final} displayType={'text'} thousandSeparator={true} /></td>
                                                </tr>
                                                { htmlPaymentRemainAmount }  
                                            </tbody>
                                        </table>
                                    </div>
                                </div>    
                            </div>
                            { has_export_info === true ?
                            <div id="export_info" className="tab-pane fade bg-white padd-10">
                                <div className="normal-table-list mr-top-20">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex table-hover table-condensed">
                                            <colgroup>
                                                <col width="5%"></col>
                                                <col width="15%"></col>
                                                <col width="5%"></col>
                                                {this.props.invoice.return_bill_id > 0 ? <col width="5%"></col> : <col width="0%"></col>}
                                                <col width="8%"></col>
                                                <col width="8%"></col>
                                                <col width="8%"></col>
                                                <col width="8%"></col>
                                                <col ></col>
                                                <col width="8%"></col>
                                                <col width="8%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Hàng hóa</th>
                                                    <th className="text-right">SL</th>
                                                    {this.props.invoice.return_bill_id > 0 ? <th className="text-right">SL trả</th> : <th></th> }
                                                    <th className="text-right">Giá bán</th>
                                                    <th className="text-right">Thành tiền</th>
                                                    <th className="text-right">Giảm HD</th>
                                                    <th className="text-right">Tổng bán</th>
                                                    <th className="text-center">Xuất hàng</th>
                                                    <th className="text-right">Tổng nhập</th>
                                                    <th className="text-right">Lợi nhuận</th>
                                                </tr>
                                                <tr className="bg-warning">
                                                    <th colSpan="5"></th>
                                                    <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                    <th className="text-right"><NumberFormat value={total_discount_receipt} displayType={'text'} thousandSeparator={true} /></th>
                                                    <th className="text-right"><NumberFormat value={total_revenue} displayType={'text'} thousandSeparator={true} /></th>
                                                    <th></th>
                                                    <th className="text-right"><NumberFormat value={total_retail} displayType={'text'} thousandSeparator={true} /></th>
                                                    <th className="text-right"><NumberFormat value={profit} displayType={'text'} thousandSeparator={true} /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showListProductWithExport(products) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>    
                            </div> : "" }
                            <div id="payment" className="tab-pane fade bg-white padd-10">
                                { payment_bills.length > 0 ?
                                <table className="table table-sc-ex table-condensed">
                                    <colgroup>
                                        <col width="5%"></col>
                                        <col width="8%"></col> 
                                        <col width="20%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã phiếu</th>
                                            <th>Thời gian</th>
                                            <th>Phương thức thanh toán</th>
                                            <th>Người xử lý</th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListPaymentBills(payment_bills) }
                                    </tbody>
                                </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                            </div>
                            <div id="ship_log" className="tab-pane fade bg-white padd-10">
                                { ship_logs.length > 0 ?
                                <table className="table table-sc-ex table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="20%"></col> 
                                        <col></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Thời gian</th>
                                            <th>&nbsp;</th>
                                            <th>Đối tác giao hàng</th>
                                            <th>Người nhận</th>
                                            <th>Người cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showShipLogs(ship_logs) }
                                    </tbody>
                                </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                            </div>
                            <div id="note" className="tab-pane fade bg-white padd-10">
                                { staff_notes.length > 0 ?
                                <table className="table table-sc-ex table-condensed w-auto">
                                    <thead>
                                        <tr>
                                            <th>Nội dung</th>
                                            <th width="10%">Thời gian</th>
                                            <th width="10%">Người tạo</th>
                                            { this.props.invoice.receipt_bill_status === InvoiceActionType.STATUS_IN_PROGRESS_INVOICE ? <th width="5%">&nbsp;</th> : <th></th> }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListStaffNotes(staff_notes) }
                                    </tbody>
                                </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.props.modal.show_modal} type={this.props.modal.modal_type} spinner={this.props.modal.modal_button_spinner} title={this.props.modal.modal_title} handleClose={this.handleModalClose.bind(this)} handleSubmit={this.handleModalSubmit.bind(this,this.props.modal.modal_submit_type)}  children={<CreatePartnerFast getRef={ref => (this.createPartnerRef = ref)} avatarUploaderWidth={100} avatarUploaderHeight={100} has_save_button={false} return_info={true} return={user_info => { this.onCreatePartnerReturn(user_info)  } } partner_type={(typeof this.props.user.user_type !== "undefined") ? this.props.user.user_type : UserActionType.CUSTOMER_USER_TYPE} />}></Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.invoice,
        modal: state.modal,
        user: state.user,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowModal: (status, title, type, input, submit_type) => {
            dispatch(ModalAction.showModal(status, title, type, input, submit_type))
        },
        onErrorField(field,value){
            dispatch(InvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(InvoiceAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(InvoiceAction.emptyInfo())
        },
        onGetInvoice: (id) => {
            return dispatch(InvoiceAction.getRequest(id))
        },
        onGetPaymentBills: id => {
            dispatch(InvoiceAction.getPaymentBillsRequest(id))
        },
        onGetShipStatus: () => {
            dispatch(InvoiceAction.getShipStatusRequest())
        },
        onGetBillStatus: () => {
            dispatch(InvoiceAction.getBillStatusRequest())
        },
        onSetBillStatus: (id, data) => {
            return dispatch(InvoiceAction.setBillStatus(id, data))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(InvoiceAction.updateRequest(data_post, id, button_name))
        },
        onSavePayment: (id, payment_info) => {
            return dispatch(InvoiceAction.updatePaymentRequest(id, payment_info))
        },
        onCopyInvoice: id => {
            return dispatch(InvoiceAction.copyInvoiceRequest(id))
        },
        onCancelPaymentInvoice: (reason_cancel, id) => {
            return dispatch(InvoiceAction.cancelPaymentInvoiceRequest(reason_cancel, id))
        },
        onGetStaffNotes: id => {
            dispatch(InvoiceAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(InvoiceAction.getLastStaffNoteRequest(id))
        },
        onRemoveStaffNote: (id,staff_note_id) => {
            dispatch(InvoiceAction.removeStaffNoteRequest(id, staff_note_id))
        },
        onGetShipLog: id => {
            dispatch(InvoiceAction.getShipLogRequest(id))
        },
        onSubmitImportReceiptTransfer: (id, data_post) => {
            return dispatch(InvoiceAction.submitImportReceiptTransferRequest(id, data_post))
        },
        onGetBillLading: (id) => {
            return dispatch(BillLadingAction.getByReceiptBillRequest(id))
        },
    }
}
const ViewInvoiceConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewInvoice));
export default withRouter(ViewInvoiceConnect);