import * as ReceiptGoodActionType from '../constants/ReceiptGoodActionType';
import * as DiscountModeType from '../constants/DiscountModeType';
import moment from 'moment';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    receipt_code: "",
    receipt_date: "",
    receipt_notes: "",
    receipt_status: 0,
    receipt_status_text: "",
    receipt_status_color: "",
    receipt_bill_id: 0,
    receipt_bill_code: "",
    staff_notes_input: "",
    staff_notes_active: false,
    staff_notes_error: false,
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    customer_id: 0,
    customer_code: "",
    customer_name: "",
    customer_phone: "",
    total_quantity: 0,
    total_product: 0,
    total_amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    staff_finish_id: 0,
    staff_finish_name: "",
    finish_time: 0,
    customer_selected: {},
    branch_id: 0,
    branch_name: "",
    products: [],
    staff_notes: [],
    total: 0,
    limit: 15,
    total_page: 0,
    page: 1,
    list: [],
    customers: [],
    search_code: "",
    search_bill_code: "",
    search_product: "",
    search_date: [],
    branch_selected: {},
    search_button_loading: false,
    save_button_loading: false,
    save_note_button_loading: false,
    is_search: false,
    show_modal: false
};
const receipt_good = (state = initialState, action) => {
  let index = -1
    switch(action.type){
        case ReceiptGoodActionType.BUTTON_SPINNER_RECEIPT_GOODS:
            return {
                ...state,
                save_button_loading: action.status
            };
          case ReceiptGoodActionType.BUTTON_UPDATE_SPINNER_RECEIPT_GOODS:
            return {
                ...state,
                [action.button_name]: action.status
            };
        case ReceiptGoodActionType.FOCUS_FIELD_RECEIPT_GOODS:
              return {
                ...state,
                [action.field]: action.value
              };
        case ReceiptGoodActionType.ERROR_FIELD_RECEIPT_GOODS:
              return {
                ...state,
                [action.field]: action.value
              };
        case ReceiptGoodActionType.FIELD_CHANGE_RECEIPT_GOODS:
              return {
                ...state,
                [action.field]: action.value
              };
        case ReceiptGoodActionType.FIELDS_CHANGE_RECEIPT_GOODS:
              return {
                ...state,
                ...action.fields
              };
        case ReceiptGoodActionType.EMPTY_RECEIPT_GOODS:      
          return {
            ...state,
            id: 0,
            receipt_code: "",
            receipt_date: "",
            receipt_notes: "",
            receipt_status: 0,
            receipt_status_text: "",
            receipt_status_color: "",
            receipt_bill_id: 0,
            receipt_bill_code: "",
            staff_notes_input: "",
            staff_notes_active: false,
            staff_notes_error: false,
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            customer_id: 0,
            customer_code: "",
            customer_name: "",
            customer_phone: "",
            total_quantity: 0,
            total_product: 0,
            total_amount: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            staff_finish_id: 0,
            staff_finish_name: "",
            finish_time: 0,
            branch_id: 0,
            branch_name: "",
            products: [],
            staff_notes: [],
            is_cancel: 0,
            search_button_loading: false,
            save_button_loading: false,
            save_note_button_loading: false,
            is_search: false,
            show_modal: false
          };
        case ReceiptGoodActionType.RESET_FIELD_SEARCH_RECEIPT_GOODS:     
          var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
          var to_date = moment().endOf('month').format('YYYY-MM-DD');
          var search_date = [];
          if(from_date !== ""){
              search_date.push(from_date)
          }
          if(to_date !== ""){
              search_date.push(to_date)
          } 
            return {
              ...state,
              search_code: "",
              search_bill_code: "",
              search_product: "",
              customers: [],
              search_date,
              branch_selected: {},
              search_button_loading: false,
              save_button_loading: false,
              save_note_button_loading: false,
              is_search: false,
            };
        case ReceiptGoodActionType.GET_RECEIPT_GOODS:
            return {
              ...state,
              id: action.receipt_good.id,
              receipt_code: action.receipt_good.receipt_code,
              receipt_date: action.receipt_good.receipt_date,
              receipt_notes: action.receipt_good.receipt_notes,
              receipt_status: action.receipt_good.receipt_status,
              receipt_status_text: action.receipt_good.receipt_status_text,
              receipt_status_color: action.receipt_good.receipt_status_color,
              receipt_bill_id: action.receipt_good.receipt_bill_id,
              receipt_bill_code: action.receipt_good.receipt_bill_code,
              customer_id: action.receipt_good.customer_id,
              customer_code: action.receipt_good.customer_code,
              customer_name: action.receipt_good.customer_name,
              customer_phone: action.receipt_good.customer_phone,
              total_quantity: action.receipt_good.total_quantity,
              total_product: action.receipt_good.total_product,
              total_amount: action.receipt_good.total_amount,
              staff_id: action.receipt_good.staff_id,
              staff_name: action.receipt_good.staff_name,
              staff_cancel_id: action.receipt_good.staff_cancel_id,
              staff_cancel_name: action.receipt_good.staff_cancel_name,
              reason_cancel: action.receipt_good.reason_cancel,
              cancel_time: action.receipt_good.cancel_time,
              staff_finish_id: action.receipt_good.staff_finish_id,
              staff_finish_name: action.receipt_good.staff_finish_name,
              finish_time: action.receipt_good.finish_time,
              branch_id: action.receipt_good.branch_id,
              branch_name: action.receipt_good.branch_name,
              products: action.receipt_good.products,
            }
        case ReceiptGoodActionType.GET_STAFF_NOTES_RECEIPT_GOODS:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };      
        case ReceiptGoodActionType.GET_LAST_STAFF_NOTES_RECEIPT_GOODS:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case ReceiptGoodActionType.LIST_RECEIPT_GOODS:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount: action.total_amount,
                list: [...action.list],
                direct_to_list: false
              };
        case ReceiptGoodActionType.SEARCH_RECEIPT_GOODS:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case ReceiptGoodActionType.UPDATE_PRODUCT_RECEIPT_GOODS: 
          index = Helper.findIndexByKey(state.products, action.key);
          if (index > -1){
            let product = state.products[index]
            if (action.input_name === "quantity_return_selected"){
              product.quantity_return_selected = parseInt(action.value)
            }
            let amount = product.price * product.quantity_return_selected
            if(product.discount > 0){
              let discount_value = product.discount
              if(product.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                discount_value = Helper.parseMoney(amount * product.discount / 100)
              }
              product.discount_value = discount_value
              amount = amount - discount_value
            }
            product.total_amount_return = amount
            state.products[index] = product
          }
          return {
            ...state
          };
        case ReceiptGoodActionType.SUM_TOTAL_RECEIPT_GOODS:
            state.total_amount_return = 0
            state.total_quantity_return = 0
            if(state.products.length > 0){
              let total_amount_return = 0, total_quantity_return = 0
              state.products.forEach(function(item){
                total_amount_return += item.total_amount_return
                total_quantity_return += parseInt(item.quantity_return_selected)
              });
              state.total_amount = total_amount_return
              state.total_quantity = total_quantity_return
            }
            return {
              ...state
            };  
        default: 
            return state;
    }
}
export default receipt_good;