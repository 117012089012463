import * as ReceiptOfGoodActionType from '../constants/ReceiptOfGoodActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: ReceiptOfGoodActionType.FOCUS_FIELD_RECEIPT_OF_GOOD,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: ReceiptOfGoodActionType.FIELDS_CHANGE_RECEIPT_OF_GOOD,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: ReceiptOfGoodActionType.FIELD_CHANGE_RECEIPT_OF_GOOD,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: ReceiptOfGoodActionType.EMPTY_RECEIPT_OF_GOOD
    }
}

export const resetFieldSearch = () => {
    return {
        type: ReceiptOfGoodActionType.RESET_FIELD_SEARCH_RECEIPT_OF_GOOD
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-of-goods/${id}`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(get(res.data.result))
                return res.data.result
            }
        });
    }
}

const get = receipt_of_good => {
    return {
        type: ReceiptOfGoodActionType.GET_RECEIPT_OF_GOOD,
        receipt_of_good
    }
}
export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`receipt-of-goods`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit, total_page, page } = res.data.result;
                    dispatch(lists(total,limit,total_page,page,list));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}

const lists = (total,limit,total_page,page,receipt_of_goods) => {
    return {
        type: ReceiptOfGoodActionType.LIST_RECEIPT_OF_GOOD,
        total,
        limit,
        total_page,
        page,
        receipt_of_goods,
    }
}
const searchButtonSpinner = (search_button_loading) => {
    return {
        type: ReceiptOfGoodActionType.SEARCH_RECEIPT_OF_GOOD,
        search_button_loading
    }
}

export const listStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-of-goods/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: ReceiptOfGoodActionType.LIST_RECEIPT_OF_GOOD_STATUS,
        status
    }
}

export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `receipt-of-goods/${id}`;
        let _method = 'POST';
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    return {
                        status: res.data.status,
                        message: (res.data.message)?res.data.message:""
                    }
                }
            }
        });
    }
}
export const cancelRequest = (id, reason_cancel) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-of-goods/cancel/` + id,{reason_cancel},'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}
export const finishRequest = (id, reason_cancel) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-of-goods/finish/` + id,null,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}