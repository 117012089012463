import * as UserActionType from '../constants/UserActionType';
import * as CategoryActionType from '../constants/CategoryActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: UserActionType.FOCUS_FIELD_USER,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: UserActionType.ERROR_FIELD_USER,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: UserActionType.FIELD_CHANGE_USER,
        field,
        value
    }
}

export const resetField = () => {
    return {
        type: UserActionType.RESET_FIELD_USER
    }
}

export const buttonSpinner = status => {
    return {
        type: UserActionType.BUTTON_SPINNER_USER,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: UserActionType.ITEM_BUTTON_SPINNER_USER,
        id,
        status
    }
}

export const updateRequest = (id, data_post) => {
    return (dispatch) => {
        return apiCaller.callApi(`user/${id}`,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                return true
            }
        });
    }
}

export const addRequest = (data_post, return_info) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`users/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    if (return_info === false){
                        dispatch(directToList(true));
                    }
                    dispatch(setUserInfo(res.data.result))
                    dispatch(resetField())
                    return {
                        status: true,
                        user_info: res.data.result
                    }
                }
                return {
                    status: false,
                    message: (res.data.message !== undefined && res.data.message !== null)?res.data.message:""
                }
            }
        });
    }
}

const setUserInfo = user => {
    return {
        type: UserActionType.SET_USER,
        user
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`user/${id}`,null,'GET').then(res => {
            dispatch(get(res.data.result))
            return res.data.result
        });
    }
}

const get = user => {
    return {
        type: UserActionType.GET_USER,
        user
    }
}

export const listRequest = (page,limit,user_type) => {
    return (dispatch) => {
        return apiCaller.callApi(`users`,{
            limit,
            page,
            user_type
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listUser(res.data.result));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`users`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listUser(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}

export const listStaffPositionRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`user/staff-position`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffPosition(res.data.result))
            }
        });
    }
}
const getStaffPosition = (list) => {
    return {
        type: UserActionType.GET_STAFF_POSITION_USER,
        list
    }
}

export const resetFieldSearch = () => {
    return {
        type: UserActionType.RESET_FIELD_SEARCH_USER
    }
}

export const resetList = () => {
    return {
        type: UserActionType.RESET_LIST_USER
    }
}

const listUser = (result) => {
    return {
        type: UserActionType.LIST_USER,
        ...result
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: UserActionType.SEARCH_USER,
        search_button_loading
    }
}

export const listCustomerGroupRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`categories`,{
            category_type: CategoryActionType.CUSTOMER_GROUP_CATEGORY_TYPE,
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listCustomerGroup(res.data.result.list));
                }
            }
        });
    }
}

const listCustomerGroup = list => {
    return {
        type: UserActionType.LIST_CUSTOMER_GROUP,
        list
    }
}

export const resetDirect = () => {
    return {
        type: UserActionType.RESET_DIRECT_USER
    }
}

export const setUserType = user_type => {
    return {
        type: UserActionType.SET_USER_TYPE,
        user_type
    }
}

const directToList = status => {
    return {
        type: UserActionType.DIRECT_TO_LIST_USER,
        status
    }
}