export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const LIST_PURCHASE_ORDER = 'LIST_PURCHASE_ORDER';
export const LIST_PURCHASE_ORDER_STATUS = 'LIST_PURCHASE_ORDER_STATUS';
export const SEARCH_PURCHASE_ORDER = 'SEARCH_PURCHASE_ORDER';
export const SHOW_HIDE_FIELD_ADD_NEW_PURCHASE_ORDER = 'SHOW_HIDE_FIELD_ADD_NEW_PURCHASE_ORDER';
export const FIELD_CHANGE_PURCHASE_ORDER = 'FIELD_CHANGE_PURCHASE_ORDER';
export const FIELDS_CHANGE_PURCHASE_ORDER = 'FIELDS_CHANGE_PURCHASE_ORDER';
export const RESET_FIELD_PURCHASE_ORDER = 'RESET_FIELD_PURCHASE_ORDER';
export const RESET_FIELD_SEARCH_PURCHASE_ORDER = 'RESET_FIELD_SEARCH_PURCHASE_ORDER';
export const FOCUS_FIELD_PURCHASE_ORDER = 'FOCUS_FIELD_PURCHASE_ORDER';
export const ERROR_FIELD_PURCHASE_ORDER = 'ERROR_FIELD_PURCHASE_ORDER';
export const BUTTON_SPINNER_PURCHASE_ORDER = 'BUTTON_SPINNER_PURCHASE_ORDER';
export const BUTTON_UPDATE_SPINNER_PURCHASE_ORDER = 'BUTTON_UPDATE_SPINNER_PURCHASE_ORDER';
export const ITEM_BUTTON_SPINNER_PURCHASE_ORDER = 'ITEM_BUTTON_SPINNER_PURCHASE_ORDER';
export const UPDATE_PRODUCTS_PURCHASE_ORDER = 'UPDATE_PRODUCTS_PURCHASE_ORDER';
export const UPDATE_PRODUCT_PURCHASE_ORDER = 'UPDATE_PRODUCT_PURCHASE_ORDER';
export const SUM_TOTAL_PURCHASE_ORDER = 'SUM_TOTAL_PURCHASE_ORDER';
export const CLEAR_PRODUCTS_PURCHASE_ORDER = 'CLEAR_PRODUCTS_PURCHASE_ORDER';
export const SELECT_PRODUCT_PURCHASE_ORDER = 'SELECT_PRODUCT_PURCHASE_ORDER';
export const DIRECT_TO_LIST_PURCHASE_ORDER = 'DIRECT_TO_LIST_PURCHASE_ORDER';
export const RESET_FLAG_SEARCH_PURCHASE_ORDER = 'RESET_FLAG_SEARCH_PURCHASE_ORDER';
export const EMPTY_PURCHASE_ORDER = 'EMPTY_PURCHASE_ORDER';
export const SUM_TOTAL_RETURN_PURCHASE_ORDER = 'SUM_TOTAL_RETURN_PURCHASE_ORDER';
export const UPDATE_PRODUCT_RETURN_PURCHASE_ORDER = 'UPDATE_PRODUCT_RETURN_PURCHASE_ORDER';

export const GET_PAYMENT_BILLS_PURCHASE_ORDER = 'GET_PAYMENT_BILLS_PURCHASE_ORDER';
export const GET_LIABILITIES_PURCHASE_ORDER = 'GET_LIABILITIES_PURCHASE_ORDER';
export const GET_STAFF_NOTES_PURCHASE_ORDER = 'GET_STAFF_NOTES_PURCHASE_ORDER';
export const GET_LAST_STAFF_NOTES_PURCHASE_ORDER = 'GET_LAST_STAFF_NOTES_PURCHASE_ORDER';
export const GET_BILL_REORDER_PURCHASE_ORDER = 'GET_BILL_REORDER_PURCHASE_ORDER';
export const GET_INVOICES_PURCHASE_ORDER = 'GET_INVOICES_PURCHASE_ORDER';
export const LIST_TRANSFER_STORE_PURCHASE_ORDER = 'LIST_TRANSFER_STORE_PURCHASE_ORDER';
export const GET_TRANSFER_STORE_PURCHASE_ORDER = 'GET_TRANSFER_STORE_PURCHASE_ORDER';
export const LIST_RETURN_BILL_PURCHASE_ORDER = 'LIST_RETURN_BILL_PURCHASE_ORDER';
export const GET_RETURN_BILL_PURCHASE_ORDER = 'GET_RETURN_BILL_PURCHASE_ORDER';
export const GET_TRANSFER_STORE_USE_PURCHASE_ORDER = 'GET_TRANSFER_STORE_USE_PURCHASE_ORDER';
export const LIST_RECEIPT_OF_GOODS_PURCHASE_ORDER = 'LIST_RECEIPT_OF_GOODS_PURCHASE_ORDER';
export const GET_RECEIPT_OF_GOOD_PURCHASE_ORDER = 'GET_RECEIPT_OF_GOOD_PURCHASE_ORDER';
export const LIST_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER = 'LIST_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER';
export const GET_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER = 'GET_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER';


export const STATUS_FINISH_PURCHASE_ORDER = 1;
export const STATUS_CANCEL_PURCHASE_ORDER = 4;

export const LIST_PRODUCT_EXPIRIES = 'LIST_PRODUCT_EXPIRIES';
export const SEARCH_PRODUCT_EXPIRIES = 'SEARCH_PRODUCT_EXPIRIES';
export const FIELD_CHANGE_PRODUCT_EXPIRIES = 'FIELD_CHANGE_PRODUCT_EXPIRIES';
export const FIELDS_CHANGE_PRODUCT_EXPIRIES = 'FIELDS_CHANGE_PRODUCT_EXPIRIES';
export const RESET_FIELD_SEARCH_PRODUCT_EXPIRIES = 'RESET_FIELD_SEARCH_PRODUCT_EXPIRIES';
export const FOCUS_FIELD_PRODUCT_EXPIRIES = 'FOCUS_FIELD_PRODUCT_EXPIRIES';