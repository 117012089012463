import * as VendorBillActionType from '../constants/VendorBillActionType';
import * as PaymentInvoiceActionType from '../constants/PaymentInvoiceActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: VendorBillActionType.FOCUS_FIELD_VENDOR_BILL,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: VendorBillActionType.ERROR_FIELD_VENDOR_BILL,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: VendorBillActionType.FIELDS_CHANGE_VENDOR_BILL,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: VendorBillActionType.FIELD_CHANGE_VENDOR_BILL,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: VendorBillActionType.EMPTY_VENDOR_BILL
    }
}

export const resetField = () => {
    return {
        type: VendorBillActionType.EMPTY_VENDOR_BILL
    }
}

export const resetFieldSearch = () => {
    return {
        type: VendorBillActionType.RESET_FIELD_SEARCH_VENDOR_BILL
    }
}

export const buttonSpinner = status => {
    return {
        type: VendorBillActionType.BUTTON_SPINNER_VENDOR_BILL,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: VendorBillActionType.ITEM_BUTTON_SPINNER_VENDOR_BILL,
        id,
        status
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`vendor-bills/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (res.data.result !== undefined && res.data.result !== null){
                    dispatch(resetField())
                    dispatch(directToList(true));
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

const directToList = (status) => {
    return {
        type: VendorBillActionType.DIRECT_TO_LIST_VENDOR_BILL,
        status
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`vendor-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

export const get = vendor_bill => {
    return {
        type: VendorBillActionType.GET_VENDOR_BILL,
        vendor_bill
    }
}

export const getBillStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/status`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getBillStatus(res.data.result.list, res.data.result.color))
            }
        });
    }
}

const getBillStatus = (list, color) => {
    return {
        type: VendorBillActionType.GET_BILL_STATUS_VENDOR_BILL,
        list,
        color
    }
}
export const addNewNoteRequest = (data_post, id) => {
    return (dispatch) => {
        dispatch(updateButtonSpinner("save_note_button_loading",true))
        return apiCaller.callApi(`notes/new`,{
            "content": data_post.staff_notes,
            "payment_bill_id": id
        },'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
                dispatch(updateButtonSpinner("save_note_button_loading",false))
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "vendor_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: VendorBillActionType.GET_STAFF_NOTES_VENDOR_BILL,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "vendor_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: VendorBillActionType.GET_LAST_STAFF_NOTES_VENDOR_BILL,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}
export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(lists(res.data.result));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`vendor-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(lists(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const lists = (result) => {
    return {
        type: VendorBillActionType.LIST_VENDOR_BILL,
        ...result
    }
}
const searchButtonSpinner = (search_button_loading) => {
    return {
        type: VendorBillActionType.SEARCH_VENDOR_BILL,
        search_button_loading
    }
}

export const listStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`vendor-bills/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: VendorBillActionType.LIST_VENDOR_BILL_STATUS,
        status
    }
}

export const setBillStatus = (id, data) => {
    return (dispatch) => {
        let _url = `vendor-bills/finish/${id}`
        let data_post = null
        if(data.status === VendorBillActionType.STATUS_CANCEL_VENDOR_BILL){
            _url = `vendor-bills/cancel/${id}`
            data_post = {
                reason_cancel: data.reason_cancel
            }
        }
        return apiCaller.callApi(_url,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: VendorBillActionType.BUTTON_UPDATE_SPINNER_VENDOR_BILL,
        button_name,
        status
    }
}

export const setInvoiceProducts = products => {
    return {
        type: VendorBillActionType.SET_PRODUCTS_VENDOR_BILL,
        products
    }
}

export const updateProductItemRequest = (input_name, id, value) => {
    return (dispatch) => {
        dispatch(updateProductItem(input_name, id, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, id, value) => {
    return {
        type: VendorBillActionType.UPDATE_PRODUCT_VENDOR_BILL,
        input_name,
        id,
        value
    }
}

export const updateProductsRequest = (products) => {
    return (dispatch) => {
        dispatch(updateProducts(products));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProducts = (products) => {
    return {
        type: VendorBillActionType.SET_PRODUCTS_VENDOR_BILL,
        products
    }
}

export const sumTotal = () => {
    return {
        type: VendorBillActionType.SUM_TOTAL_VENDOR_BILL
    }
}

export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `vendor-bills/${id}`;
        let _method = 'POST';
        if(button_name !== ""){
            dispatch(updateButtonSpinner(button_name,true))
        }
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getRequest(id))
                if (data_post.staff_notes !== undefined){
                    dispatch(getLastStaffNoteRequest(id))
                    dispatch(getStaffNotesRequest(id))
                }
                if(button_name !== ""){
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const getPaymentBillsRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills`,{
            "vendor_bill_id": id,
            "has_finance_source": 1,
            "skip_cod_payment": 1,
            "status": (PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE).toString().concat(',',(PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE).toString())
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getPaymentBills(res.data.result.list, res.data.result.total_payed))
            }
        });
    }
}

const getPaymentBills = (payment_bills, total_payed) => {
    return {
        type: VendorBillActionType.GET_PAYMENT_BILLS_VENDOR_BILL,
        payment_bills,
        total_payed
    }
}
