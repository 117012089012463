import React, { Component } from 'react';
import { SelectPicker } from 'rsuite';
import moment from 'moment';
class DateFilterSelectBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            value: 1
        }
        this.onSelect = this.onSelect.bind(this);

        this.date_array = [
            {
                label: "Hôm nay",
                value: 1
            },
            {
                label: "Hôm qua",
                value: 2
            },
            {
                label: "7 ngày qua",
                value: 3
            },
            {
                label: "Tháng này",
                value: 4
            },
            {
                label: "Tháng trước",
                value: 5
            }
        ] 
    }
    componentDidMount(){
        if(this.props.value !== undefined && this.props.value !== null && this.props.value > 0){
            this.setState({
                value: this.props.value
            })
        }
    }
    onSelect(value){
        let date = []
        let label = ""
        if (value === 1){
            date = [new Date(), new Date()]
            label = "Hôm nay"
        } else if (value === 2){
            date = [moment().add(-1, 'days').toDate(), moment().add(-1, 'days').toDate()]
            label = "Hôm qua"
        } else if (value === 3){
            date = [moment().add(-7, 'days').toDate(), new Date()]
            label = "7 ngày qua"
        } else if (value === 4){
            date = [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
            label = "Tháng này"
        } else if (value === 5){

            let last_date_in_last_month = moment().endOf('month').add(moment().daysInMonth() * (-1), 'days')
            var number_date_last_month = (moment(last_date_in_last_month.year() + "-"+(last_date_in_last_month.month() + 1), "YYYY-MM").daysInMonth()) * (-1);
            date = [moment().startOf('month').add(number_date_last_month, 'days').toDate(), moment().endOf('month').add(moment().daysInMonth() * (-1), 'days').toDate()]
            label = "Tháng trước"
        }
        this.props.onFieldChange(date, label);
        this.setState({
            value
        })
    }
    render(){
        let date_selected = this.state.value
        return <SelectPicker
                block
                cleanable={false}
                searchable={false}
                appearance="subtle"
                data={this.date_array}
                value={date_selected}
                onSelect={this.onSelect}
                renderMenu={menu => {
                    return menu;
                }}
            />
    }
}
export default DateFilterSelectBox