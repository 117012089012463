import * as SupplierVendorBillActionType from '../constants/SupplierVendorBillActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: SupplierVendorBillActionType.FOCUS_FIELD_SUPPLIER_VENDOR_BILL,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: SupplierVendorBillActionType.ERROR_FIELD_SUPPLIER_VENDOR_BILL,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: SupplierVendorBillActionType.FIELDS_CHANGE_SUPPLIER_VENDOR_BILL,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: SupplierVendorBillActionType.FIELD_CHANGE_SUPPLIER_VENDOR_BILL,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: SupplierVendorBillActionType.EMPTY_SUPPLIER_VENDOR_BILL
    }
}
export const resetFieldSearch = () => {
    return {
        type: SupplierVendorBillActionType.RESET_FIELD_SEARCH_SUPPLIER_VENDOR_BILL
    }
}

export const getRequest = (id) => {
    return (dispatch) => {
        return apiCaller.callApi(`supplier-vendor-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

const get = supplier_vendor_bill => {
    return {
        type: SupplierVendorBillActionType.GET_SUPPLIER_VENDOR_BILL,
        supplier_vendor_bill,
    }
}


export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`supplier-vendor-bills`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(Lists(res.data.result));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`supplier-vendor-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(Lists(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}
const Lists = (result) => {
    return {
        type: SupplierVendorBillActionType.LIST_SUPPLIER_VENDOR_BILL,
        ...result
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: SupplierVendorBillActionType.SEARCH_SUPPLIER_VENDOR_BILL,
        search_button_loading
    }
}
export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `supplier-vendor-bills/${id}`;
        let _method = 'POST';
        if(button_name !== ""){
            dispatch(updateButtonSpinner(button_name,true))
        }
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if(button_name !== ""){
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: SupplierVendorBillActionType.BUTTON_UPDATE_SPINNER_SUPPLIER_VENDOR_BILL,
        button_name,
        status
    }
}


export const cancelRequest = (id, reason_cancel) => {
    return () => {
        return apiCaller.callApi(`supplier-vendor-bills/cancel/${id}`,{reason_cancel},'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}
export const buttonSpinner = status => {
    return {
        type: SupplierVendorBillActionType.BUTTON_SPINNER_SUPPLIER_VENDOR_BILL,
        status
    }
}
export const sumTotal = () => {
    return {
        type: SupplierVendorBillActionType.SUM_TOTAL_SUPPLIER_VENDOR_BILL
    }
}
export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`supplier-vendor-bills/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (res.data.result !== undefined && res.data.result !== null){
                    dispatch(emptyInfo())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            }
        });
    }
}



export const selectProductToListRequest = item => {
    return (dispatch) => {
        dispatch(selectProductToList(item))
         setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const selectProductToList = item => {
    return {
        type: SupplierVendorBillActionType.SELECT_PRODUCT_SUPPLIER_VENDOR_BILL,
        item
    }
}

export const updateProductItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        //console.log("key in action request: ", key)
        dispatch(updateProductItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, key, value) => {
    //console.log("key in action: ", key)
    return {
        type: SupplierVendorBillActionType.UPDATE_PRODUCT_SUPPLIER_VENDOR_BILL,
        input_name,
        key,
        value
    }
}

export const updateProductsRequest = (products) => {
    return (dispatch) => {
        dispatch(updateProducts(products));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProducts = (products) => {
    return {
        type: SupplierVendorBillActionType.UPDATE_PRODUCTS_SUPPLIER_VENDOR_BILL,
        products
    }
}

export const clearProductRequest = () => {
    return {
        type: SupplierVendorBillActionType.CLEAR_PRODUCTS_SUPPLIER_VENDOR_BILL
    }
}