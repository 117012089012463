import * as BankTransactionActionType from '../constants/BankTransactionActionType';
import apiCaller from '../utils/apiCaller';

export const fieldChange = (field,value) => {
    return {
        type: BankTransactionActionType.FIELD_CHANGE_BANK_TRANSACTION,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: BankTransactionActionType.FIELDS_CHANGE_BANK_TRANSACTION,
        fields
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`finance-sources/daily-bank-transaction`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(list(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const list = (result) => {
    return {
        type: BankTransactionActionType.LIST_BANK_TRANSACTION,
        ...result
    }
}

export const resetFieldSearch = () => {
    return {
        type: BankTransactionActionType.RESET_FIELD_SEARCH_BANK_TRANSACTION
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: BankTransactionActionType.SEARCH_BANK_TRANSACTION,
        search_button_loading
    }
}