import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom";
import NumberFormat from 'react-number-format';
import * as UserAction from '../../actions/UserAction';
import Moment from 'react-moment';
import ListForSupplier from '../purchase-order/ListForSupplier';
import LiabilitiesForSupplier from './LiabilitiesForSupplier';

class ViewSupplier extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        if (id > 0) {
            this.props.onGetData(id)
        }
    }
    render(){
        let {id, city_name, district_name, ward_name } = this.props.user
        let zone_text = "", zone_array  = []
        if(ward_name !== ""){
            zone_array.push(ward_name)
        }
        if(district_name !== ""){
            zone_array.push(district_name)
        }
        if(city_name !== ""){
            zone_array.push(city_name)
        }
        if (zone_array.length > 0){
            zone_text = zone_array.join(", ")
        }
        let list_imports = null, list_liabilities = null
        if (id > 0){
            list_imports = <ListForSupplier supplier_id={id} limit={10} />
            list_liabilities = <LiabilitiesForSupplier supplier_id={id} limit={10} />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <NavLink to="/suppliers"><i className="fa fa-arrow-left fa-2x text-black"></i></NavLink>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>   
                            </li>
                            <li>
                                <a data-toggle="tab" href="#imports" aria-expanded="false">Lịch sử nhập hàng</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#liabilities" aria-expanded="false">Nợ cần thu</a>
                            </li>
                        </ul>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white padd-10">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã NCC:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{ this.props.user.code }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Tên NCC:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{ this.props.user.name }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Tổng nợ:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <NumberFormat value={this.props.user.total_liabilities} displayType={'text'} thousandSeparator={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Đã thanh toán:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <NumberFormat value={this.props.user.paid_liabilities} displayType={'text'} thousandSeparator={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Còn lại:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <NumberFormat value={this.props.user.remain_liabilities} displayType={'text'} thousandSeparator={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Điện thoại:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{ this.props.user.phone }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Địa chỉ:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.props.user.address }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Khu vực:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { zone_text }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Người tạo:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.props.user.staff_name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Ngày tạo:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.user.time }</Moment>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="imports" className="tab-pane fade bg-white padd-10">
                               { list_imports }
                            </div>
                            <div id="liabilities" className="tab-pane fade bg-white padd-10">
                                { list_liabilities }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        purchase_order: state.purchase_order
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetData: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
    }
}
const ViewSupplierConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewSupplier));
export default withRouter(ViewSupplierConnect);