import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert, SelectPicker } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import Helper from '../../utils/Helper';

const { Column, HeaderCell, Cell } = Table;

class CreatePurchaseOrderFromReturn extends Component {
    componentDidMount(){
        let branch_selected = Helper.getBranchSelected()
        this.props.onEmptyInfo()
        this.props.onGetReturnBillRequest(branch_selected.id)
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onSelectReturnBill = item => {
        if (item !== null){
            this.props.onSelectReturnBill(item)
        }
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("real_quantity", key, value);
    }
    onSubmit(){
        let {products, return_bill_selected, import_receipt_notes, import_receipt_date } = this.props.purchase_order
        if(!(return_bill_selected !== null && return_bill_selected.id > 0)){
            Alert.error('Vui lòng chọn phiếu trả hàng')
            return;
        }
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        if (import_receipt_date === ""){
            import_receipt_date = moment().format("YYYY-MM-DD HH:mm:ss")
        }

        let products_array = []
        products.forEach(item => {
            item.quantity = parseInt(item.quantity)
            item.real_quantity = parseInt(item.real_quantity)
            if (item.real_quantity > 0 && item.real_quantity === item.quantity){
                products_array.push({
                    "id": item.id,
                    "product_option_id": item.product_option_id,
                    "product_name": item.name,
                    "product_sku": item.sku,
                    "product_model": item.model,
                    "category_id": parseInt(item.category_id),
                    "category_name": item.category_name,
                    "brand_id": parseInt(item.brand_id),
                    "brand_name": item.brand_name,
                    "retail": parseFloat(item.price),
                    "quantity": item.real_quantity,
                    "is_gift": parseInt(item.is_gift),
                    "is_return_bill": 1,
                })
            }
        })
        if (products_array.length === 0){
            Alert.error('Vui lòng nhập chọn số lượng hàng hóa phù hợp')
            return;
        }
        let my_stores = Helper.getMyStores()
        let store_id = 0
        let store_code = "", store_name = ""
        if(my_stores !== null && my_stores.store_id !== undefined && my_stores.store_id !== null && my_stores.store_id > 0){
            store_id = my_stores.store_id
            store_code = my_stores.store_code
            store_name = my_stores.store_name
        }
        let data_post = {
            import_receipt_date: import_receipt_date,
            import_receipt_notes: import_receipt_notes,
            return_bill_id: return_bill_selected.id,
            return_bill_code: return_bill_selected.code,
            return_bill_date: Helper.replaceMysqlDateTime(return_bill_selected.date),
            store_id,
            store_code,
            store_name,
            products: products_array,
            is_return_bill: 1
        }
        //console.log(data_post);
        this.props.onSubmit(data_post).then(result => {
            if(result !== null){
                if(result.id !== undefined && result.id !== null){
                    this.props.history.push("/purchase-order/" + result.id);
                }
                else if(result.length > 0){
                    let first_id = 0
                    result.forEach(item => {
                        if(first_id === 0){
                            first_id = item.id
                        } else{
                            window.open("/purchase-order/" + item.id)
                        }
                    })
                    if (first_id > 0){
                        this.props.history.push("/purchase-order/" + first_id);
                    }
                }
            }
        })
    }
    render(){
        let {return_bills, return_bill_selected, products, total_quantity, total_amount, total_product, import_receipt_notes, import_receipt_notes_active, save_button_loading } = this.props.purchase_order;
        
        let quantity_text_display = ""
        if(total_quantity > 0){
            quantity_text_display = (<span>&nbsp;({total_quantity})</span>)
        }
        let notes_field_class = "nk-int-st"
        if (import_receipt_notes !== "") {
            import_receipt_notes_active = true;
        }
        if(import_receipt_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let return_bills_array = []
        let return_bill_value_selected = 0
        if (return_bills !== null && return_bills.length > 0){
            return_bills.forEach(item => {
                let return_bill_date = Helper.convertMysqlDateTime(Helper.replaceMysqlDateTime(item.return_bill_date))
                return_bills_array.push({
                    label: item.return_bill_code + " - " + return_bill_date + " - " + item.receipt_bill_code  + " - " + item.customer_name,
                    value: item.id
                })
            })
        }
        if (return_bill_selected !== null && return_bill_selected.id !== undefined && return_bill_selected.id > 0){
            return_bill_value_selected = return_bill_selected.id
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }
        return (
            <div className="container">
            <Helmet>
                <title>Nhập hàng khách trả</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/purchase-order/new-from-return"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <SelectPicker
                            block
                            size="lg"
                            placeholder="Chọn phiếu trả hàng"
                            cleanable={false}
                            value={return_bill_value_selected}
                            data={return_bills_array}
                            onSelect={this.onSelectReturnBill}
                        />
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={55}
                                    data={products} >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={300}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                return nameText
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Số lượng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.quantity} disabled />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Thực tế</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.real_quantity} min={0} max={rowData.quantity} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={120} align="right">
                                        <HeaderCell>Đơn giá</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let retail = parseFloat(rowData.price)
                                                if (retail <= 0) {
                                                    retail = ""
                                                }
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={retail} disabled/>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={120} align="right">
                                        <HeaderCell>Thành tiền</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                <div className="nk-int-st nk-toggled">
                                                    <NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={rowData.total_amount} />
                                                </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker format="YYYY-MM-DD HH:mm" appearance="subtle" placeholder={moment().format("YYYY-MM-DD HH:mm")} 
                                    defaultValue={moment().toDate()}
                                    placement="leftStart" ranges={[]} block disabled />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng tiền hàng{quantity_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="import_receipt_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={import_receipt_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;HOÀN TẤT NHẬP HÀNG
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        purchase_order: state.purchase_order
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(PurchaseOrderAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(PurchaseOrderAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PurchaseOrderAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PurchaseOrderAction.fieldChange(field,value))
        },
        onUpdateProducts(products){
            dispatch(PurchaseOrderAction.updateProductsRequest(products))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(PurchaseOrderAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(PurchaseOrderAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(PurchaseOrderAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(PurchaseOrderAction.addRequest(data_post))
        },
        onGetReturnBillRequest: branch_id => {
            return dispatch(PurchaseOrderAction.getReturnBillRequest(branch_id))
        },
        onSelectReturnBill: id => {
            return dispatch(PurchaseOrderAction.selectReturnBillRequest(id))
        },
    }
}
const CreatePurchaseOrderFromReturnConnect = connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrderFromReturn);
export default withRouter(CreatePurchaseOrderFromReturnConnect);