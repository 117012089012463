import * as ModalActionType from '../constants/ModalActionType';
var initialState = {
    show_modal: false,
    modal_type: "",
    modal_title: "",
    modal_input: "",
    modal_submit_type: "",
    modal_button_spinner: false
};
const modal = (state = initialState, action) => {
    switch(action.type){
        case ModalActionType.SHOW_MODAL:
            return {
                ...state,
                show_modal: action.status,
                modal_type: action.modal_type,
                modal_title: action.modal_title,
                modal_input: action.modal_input,
                modal_submit_type: action.modal_submit_type
            };
        case ModalActionType.MODAL_BUTTON_SPINNER:
            return {
                ...state,
                modal_button_spinner: action.status
            };
        default: 
            return state;
    }
}
export default modal;