import * as VendorBillActionType from '../constants/VendorBillActionType';
import * as DiscountModeType from '../constants/DiscountModeType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    object_id: 0,
    object_name: "",
    object_info: {},
    import_receipt_id: 0,
    import_receipt_code: "",
    bill_date: "",
    bill_code: "",
    bill_notes: "", 
    sub_amount: 0,
    amount: 0,
    tax: 0,
    bill_status: 0,
    bill_status_text: "",
    bill_status_color: "",
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    staff_finish_id: 0,
    staff_finish_name: "",
    finish_time: 0,
    branch_id: 0,
    branch_name: "",
    categories: [],
    products: [],
    finance_sources: [],
    payment_bills: [],
    vendor_bill_status_select: [],
    vendor_bill_status_color_select: [],
    staff_notes_input: "",
    staff_notes_active: false,
    staff_notes_error: false,
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    staff_notes: [],

    total: 0,
    limit: 15,
    total_quantity: 0,
    total_amount: 0,
    total_paid_amount: 0,
    total_page: 0,
    page: 1,
    list: [],
    search_code: "",
    search_date: [],
    search_status: [],
    object_selected: {},
    category_selected: {},
    payment_filter_selected: [],
    product_selected: {
      name: "",
      id: 0,
      unit: "",
      unit_id: "",
      quantity: 1,
      price: "",
      total: 0
    },
    clear_tag_input: false,
    search_button_loading: false,
    save_button_loading: false,
    save_note_button_loading: false,
    is_search: false,
    direct_to_list: false,
};
const vendor_bill = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case VendorBillActionType.ITEM_BUTTON_SPINNER_VENDOR_BILL:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case VendorBillActionType.BUTTON_SPINNER_VENDOR_BILL:
            return {
                ...state,
                save_button_loading: action.status
            };
        case VendorBillActionType.BUTTON_UPDATE_SPINNER_VENDOR_BILL:
          return {
              ...state,
              [action.button_name]: action.status
          };
        case VendorBillActionType.FOCUS_FIELD_VENDOR_BILL:
              return {
                ...state,
                [action.field]: action.value
              };
        case VendorBillActionType.ERROR_FIELD_VENDOR_BILL:
              return {
                ...state,
                [action.field]: action.value
              };
        case VendorBillActionType.FIELD_CHANGE_VENDOR_BILL:
              return {
                ...state,
                [action.field]: action.value
              };
        case VendorBillActionType.FIELDS_CHANGE_VENDOR_BILL:
              return {
                ...state,
                ...action.fields
              };
        case VendorBillActionType.EMPTY_VENDOR_BILL:      
          return {
            ...state,
            id: 0,
            object_id: 0,
            object_name: "",
            object_info: {},
            import_receipt_id: 0,
            import_receipt_code: "",
            bill_date: "",
            bill_code: "",
            bill_notes: "", 
            sub_amount: 0,
            amount: 0,
            tax: 0,
            bill_status: 0,
            bill_status_text: "",
            bill_status_color: "",
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            staff_finish_id: 0,
            staff_finish_name: "",
            finish_time: 0,
            branch_id: 0,
            branch_name: "",
            categories: [],
            products: [],
            finance_sources: [],
            payment_bills: [],
            vendor_bill_status_select: [],
            vendor_bill_status_color_select: [],
            staff_notes_input: "",
            staff_notes_active: false,
            staff_notes_error: false,
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            staff_notes: [],

            total: 0,
            limit: 15,
            total_quantity: 0,
            total_amount: 0,
            total_paid_amount: 0,
            total_page: 0,
            page: 1,
            list: [],
            search_code: "",
            search_date: [],
            search_status: [],
            object_selected: {},
            category_selected: {},
            payment_filter_selected: [],
            product_selected: {
              name: "",
              id: 0,
              unit: "",
              unit_id: "",
              quantity: 1,
              price: "",
              total: 0
            },
            clear_tag_input: false,
            search_button_loading: false,
            save_button_loading: false,
            save_note_button_loading: false,
            is_search: false,
            direct_to_list: false,
          };
        case VendorBillActionType.RESET_FIELD_SEARCH_VENDOR_BILL:      
            return {
              ...state,
              search_code: "",
              search_date: [],
              search_status: [],
              object_selected: [],
              payment_filter_selected: [],
              search_button_loading: false,
              is_search: false,
            };
        case VendorBillActionType.GET_VENDOR_BILL:

          let total_quantity = 0
          action.vendor_bill.products.forEach(function(item){
            total_quantity += item.quantity
          })
            return {
                ...state,
                id: action.vendor_bill.id,
                bill_code: action.vendor_bill.bill_code,
                bill_date: action.vendor_bill.bill_date,
                bill_notes: action.vendor_bill.bill_notes, 
                bill_status: action.vendor_bill.bill_status, 
                bill_status_text: action.vendor_bill.bill_status_text, 
                bill_status_color: action.vendor_bill.bill_status_color, 
                object_id: action.vendor_bill.object_id, 
                object_name: action.vendor_bill.object_name, 
                object_info: action.vendor_bill.object_info,
                total_amount: action.vendor_bill.amount, 
                paid_amount: action.vendor_bill.paid_amount,
                staff_id: action.vendor_bill.staff_id, 
                staff_name: action.vendor_bill.staff_name, 
                staff_cancel_id: action.vendor_bill.staff_cancel_id, 
                staff_cancel_name: action.vendor_bill.staff_cancel_name, 
                reason_cancel: action.vendor_bill.reason_cancel, 
                cancel_time: action.vendor_bill.cancel_time, 
                staff_finish_id: action.vendor_bill.staff_finish_id, 
                staff_finish_name: action.vendor_bill.staff_finish_name, 
                finish_time: action.vendor_bill.finish_time, 
                branch_id: action.vendor_bill.branch_id, 
                branch_name: action.vendor_bill.branch_name, 
                products: action.vendor_bill.products,
                total_quantity
            };
        case VendorBillActionType.GET_BILL_STATUS_VENDOR_BILL:
            let bill_status_array = [], bill_status_color_array = []
            Object.keys(action.list).map((k) => {
              bill_status_array.push({
                  label: action.list[k],
                  value: k
              })
              return true
            })
            Object.keys(action.color).map((k) => {
              bill_status_color_array.push({
                  label: action.color[k],
                  value: k
              })
              return true
            })
          return {  
              ...state,
              payment_bill_status_select: bill_status_array,
              payment_bill_status_color_select: bill_status_color_array
          };  
        case VendorBillActionType.LIST_VENDOR_BILL_STATUS:
          return {
              ...state,
              vendor_bill_status_select: action.status
            }; 
        case VendorBillActionType.GET_PAYMENT_BILLS_VENDOR_BILL:
          return {  
              ...state,
              payment_bills: action.payment_bills,
              total_payed_amount: action.total_payed
          }; 
        case VendorBillActionType.GET_STAFF_NOTES_VENDOR_BILL:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };  
        case VendorBillActionType.GET_LAST_STAFF_NOTES_VENDOR_BILL:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case VendorBillActionType.LIST_VENDOR_BILL:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount: action.total_amount,
                total_paid_amount: action.total_paid,
                list: [...action.list],
                direct_to_list: false
              };
        case VendorBillActionType.SET_PRODUCTS_VENDOR_BILL:
          return {  
              ...state,
              products: action.products
          };  
        case VendorBillActionType.UPDATE_PRODUCT_VENDOR_BILL: 
          index = Helper.findIndex(state.products, action.id);
          if (index > -1){
            let product = state.products[index]
            if (action.input_name === "quantity"){
              product.quantity = action.value
            } else if (action.input_name === "price"){
              product.price = action.value
            } else if (action.input_name === "discount"){
              product.discount = action.value
            } else if (action.input_name === "discount_mode"){
              product.discount = 0;
              product.discount_value = 0
              product.discount_mode = action.value
            } else if (action.input_name === "tax"){
              product.tax = parseFloat(action.value)
              if(isNaN(product.tax)){
                product.tax = 0
              }
            }
            let amount = product.price * product.quantity
            if(product.discount !== undefined && product.discount > 0){
              let discount_value = product.discount
              if(product.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                discount_value = Helper.parseMoney(amount * product.discount / 100)
              }
              product.discount_value = discount_value
              amount = amount - discount_value
            }
            if (product.tax !== undefined && product.tax > 0){
              amount = amount + (amount * product.tax / 100)
            }
            product.total = amount
            state.products[index] = product
          }
          return {
            ...state
          };   
        case VendorBillActionType.SUM_TOTAL_VENDOR_BILL:
            state.amount = 0
            state.sub_amount = 0
            if(state.products.length > 0){
              let total_amount = 0
              state.products.forEach(function(item){
                total_amount += item.total
              });
              state.sub_amount = total_amount
              if (state.tax !== undefined && state.tax > 0){
                total_amount = total_amount + (total_amount * state.tax / 100)
              }
              state.amount = total_amount
            }
            return {
              ...state
            };  
        case VendorBillActionType.SEARCH_VENDOR_BILL:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case VendorBillActionType.DIRECT_TO_LIST_VENDOR_BILL:
          return {
            ...state,
            direct_to_list: action.status
          };     
        default: 
            return state;
    }
}
export default vendor_bill;