import React, { Component } from 'react';
import LaddaButton, { ZOOM_IN, S } from 'react-ladda';
import * as BillLadingActionType from '../../constants/BillLadingActionType';
import apiCaller from '../../utils/apiCaller';
import Swal from 'sweetalert2';
import { SelectPicker } from 'rsuite';
class ModalUpdateReasonPending extends Component {
    constructor(props){
        super(props)
        this.state = {
            reason_pending_options: [],
            reason_pending_selected: 0,
            other_reason_pending_input: "",
            save_pending_button_loading: false,
            show_modal_pending: false
        }
    }
    componentDidMount(){
        this.getReasonPending()
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.show!==prevState.show_modal_pending){
            return { show_modal_pending: nextProps.show};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.show!==this.props.show){
            this.setState({show_modal_pending: this.props.show});
        
        }
    }
    getReasonPending(){
        let _url = 'get_reason_pending.php'
        let is_ssl = true
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            _url = 'bill-ladings/reason-pending'
            is_ssl = false
        }
        apiCaller.callApi(_url, null,'GET', is_ssl).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let list = res.data.result;
                    if (list !== undefined && list !== null){
                        let reason_pending_options = []
                        Object.keys(list).map((k) => {
                            reason_pending_options.push({
                                label: list[k],
                                value: parseInt(k)
                            })
                            return true
                        })
                        this.setState({
                            reason_pending_options
                        });
                    }
                }
            }
        });
    }
    onSavePending(){
        let reason_pending_selected = this.state.reason_pending_selected
        let other_reason_pending = this.state.other_reason_pending_input
        if(reason_pending_selected === 0){
            Swal.fire("Thông báo", "Vui lòng chọn lý do tạm ngừng giao hàng", "error")
            return false
        }
        if(reason_pending_selected === BillLadingActionType.REASON_PENDING_OTHER && other_reason_pending === ""){
            this.reason_pending_input.focus()
            Swal.fire("Thông báo", "Vui lòng nhập lý do khác", "error")
            return false
        }
        if(reason_pending_selected !== BillLadingActionType.REASON_PENDING_OTHER){
            let reason_pending_options = this.state.reason_pending_options
            other_reason_pending = ""
            for(let i = 0; i < reason_pending_options.length; i++){
                if(reason_pending_options[i].value === reason_pending_selected){
                    other_reason_pending = reason_pending_options[i].label
                    break
                }
            }
        }
        this.setState({
            save_pending_button_loading: true,
        })
        this.onShowHideModalPending()
        this.props.onSave({
            reason_pending: other_reason_pending,
            ship_status_reason_option: reason_pending_selected
        })
    }
    onSelectChangeReasonPending(value){
        this.setState({
            reason_pending_selected: value
        })
    }
    onCleanChangeReasonPending(){
        this.setState({
            reason_pending_selected: 0
        })
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            [name]: value
        })
    }
    onShowHideModalPending(){
        this.props.onShowHide()
    }
    render(){
        return (
            <div className={this.state.show_modal_pending === true ? "modal display-block" : "modal display-none"}>
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tạm ngừng giao hàng</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.onShowHideModalPending()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="control-label">Lý do tạm ngừng</label>
                            <SelectPicker
                                block
                                placeholder="Chọn một lý do"
                                searchable={false}
                                data={this.state.reason_pending_options}
                                value={this.state.reason_pending_selected}
                                onSelect={this.onSelectChangeReasonPending.bind(this)}
                                onClean={this.onCleanChangeReasonPending.bind(this)}
                                renderMenu={menu => {
                                    return menu;
                                }}
                                />
                            </div>
                            {this.state.reason_pending_selected === BillLadingActionType.REASON_PENDING_OTHER ?
                            <div className="form-group">
                                <label className="control-label">Nhập lý do khác</label>
                                <textarea name="other_reason_pending_input" value={this.state.other_reason_pending_input} className="form-control bill-lading-notes" ref={input => this.reason_pending_input = input} onChange={ (e) => { this.onFieldChange(e) } }></textarea>
                            </div> : null }
                        </div>
                        <div className="modal-footer">
                            <LaddaButton
                                loading={ this.state.save_pending_button_loading }
                                data-size={S}
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-info btn-save-bill-lading-info" onClick={ () => { this.onSavePending() } } >
                                <i className="fa fa-check"></i>&nbsp;Đồng ý
                            </LaddaButton>
                            <button type="button" className="btn btn-secondary btn-close-bill-lading-info" data-dismiss="modal" onClick={() => this.onShowHideModalPending()}><i className="fa fa-ban"></i>&nbsp;Đóng</button>
                        </div>
                        </div>
                    </div>
                </div>
        );
    }
}
export default (ModalUpdateReasonPending)