import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as UserAction from '../../actions/UserAction';
import * as LoginAction from '../../actions/LoginAction';
import * as BillLadingActionType from '../../constants/BillLadingActionType';
import * as UserActionType from '../../constants/UserActionType';
import BillLadingStatusSelectBox from './BillLadingStatusSelectBox';
import UserSelectBox from '../shared/UserSelectBox';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
import { SelectPicker } from 'rsuite';
class BillLadings extends Component {
    constructor(props){
        super(props)
        this.state = {
            branchs: [],
        }
    }
    componentDidMount(){
        let _this = this
        this.props.onResetFieldSearch();
        _this.bindDataSearch();
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            if (data_search.search !== undefined && data_search.search === true){
                _this.props.onSearch(data_search,_this.props.bill_lading.page,_this.props.bill_lading.limit, true);
            } else {
                let search_status_default = [BillLadingActionType.STATUS_NEW_SHIP_BILL_LADINGS, BillLadingActionType.STATUS_SHIP_IN_PROGRESS_BILL_LADINGS, BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS, BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS]
                _this.props.onFieldChange("search_status",search_status_default);
                var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
                var to_date = moment().endOf('month').format('YYYY-MM-DD');
                var search_date = [];
                if(from_date !== ""){
                    search_date.push(from_date)
                }
                if(to_date !== ""){
                    search_date.push(to_date)
                }
                _this.props.onFieldChange("search_date",search_date);
                _this.props.onSearch({
                    "from_date": search_date[0],
                    "to_date": search_date[1],
                    "ship_status": search_status_default.join(",")
                },1,_this.props.bill_lading.limit, false);
                
            }
        })
        _this.props.onGetBranchs().then(branchs => {
            let branchs_state = []
            if (branchs !== null && branchs.length > 0){
                branchs.forEach(item => {
                    let color = "#00b8ce"
                    if (item.id !== 1){
                        color = "#a1eef7"
                    }
                    
                    branchs_state.push({...item, color})
                });
            }
            _this.setState({
                branchs: branchs_state
            })
        })
        _this.props.onFieldChange("scroll_get_more", false)
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onReceiptBillDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("search_customer_selected", item);
    }
    onTransporterFieldChange(item){
        this.props.onFieldChange("search_transporter_selected", item);
    }
    onSelectBranch(value){
        if(value !== null){
            let branch_selected_state = {}
            if (this.state.branchs.length > 0){
                this.state.branchs.forEach(item => {
                    if(item.id === value){
                        branch_selected_state = {
                            name: item.branch_name,
                            id: item.id
                        }
                    }
                });
            }
            this.props.onFieldChange("branch_selected",branch_selected_state)
        }
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let status = Helper.getQueryString('ship_status');
        let from_date = Helper.getQueryString('from_date');
        let to_date = Helper.getQueryString('to_date');
        let customer_id = Helper.getQueryString('customer');
        let transporter_id = Helper.getQueryString('transporter');
        let branch_param = Helper.getQueryString('branch');
        var search_date = [];
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_date.push(from_date)
        }
        if(to_date !== undefined && to_date !== null && to_date !== ""){
            search_date.push(to_date)
        }
        var search_customer_selected = null
        if(customer_id !== undefined && customer_id !== null && customer_id > 0){
            search_customer_selected = {}
            search_customer_selected.value = parseInt(customer_id)
            this.props.onGetCustomer(customer_id).then(result => {
                if (result.id !== undefined && result.id > 0){
                    search_customer_selected.label = result.name + " - " + result.phone
                }
            })
        }
        var search_transporter_selected = null
        if(transporter_id !== undefined && transporter_id !== null && transporter_id > 0){
            search_transporter_selected = {}
            search_transporter_selected.value = parseInt(transporter_id)
            this.props.onGetCustomer(transporter_id).then(result => {
                if (result.id !== undefined && result.id > 0){
                    search_transporter_selected.label = result.name
                }
            })
        }
        var search_status = []
        if (status !== undefined && status !== null && status !== ""){
            let search_status_array = status.split(",")
            search_status_array.forEach(item => {
                search_status.push(parseInt(item))
            })
        }
        if (search_code === null){
            search_code = ""
        }
        let branch_selected = {}
        if (branch_param > 0){
            branch_selected.id = parseInt(branch_param)   
        }
        this.props.onFieldsChange({
            search_code, search_status, search_date, search_customer_selected, search_transporter_selected, branch_selected
        })
    }
    getDataSearch(){
        let _code = this.props.bill_lading.search_code;
        let _status = this.props.bill_lading.search_status;
        let _date = this.props.bill_lading.search_date;
        let _customer = this.props.bill_lading.search_customer_selected;
        let _transporter = this.props.bill_lading.search_transporter_selected;
        let _branch = this.props.bill_lading.branch_selected;
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        var data_search = {
            "search": false,
            "param": []
        }
        let is_search = false
        if (_code !== ""){
            data_search["bill_code"] = _code;
            data_search["param"].push("code=" + _code)
            is_search = true
            this.props.onFieldsChange({
                search_customer_selected: null, search_transporter_selected: null, branch_selected: {}
            })
        } else {
            if (_branch !== null && _branch.id !== undefined && _branch.id !== null && _branch.id > 0){
                data_search["branch_id"] = _branch.id;
                data_search["param"].push("branch=" + _branch.id)
                is_search = true
            }
            if (_status.length > 0){
                data_search["ship_status"] = _status.join(",");
                data_search["param"].push("ship_status=" + _status.join(","))
                is_search = true
            }
            if (_date[0] !== undefined){
                data_search["from_date"] = _date[0];
                data_search["param"].push("from_date=" + _date[0])
                is_search = true
            }
            if (_date[1] !== undefined){
                data_search["to_date"] = _date[1];
                data_search["param"].push("to_date=" + _date[1])
                is_search = true
            }
            if(_customer !== undefined && _customer !== null && _customer.value !== undefined){
                data_search["customer_id"] = _customer.value;
                data_search["param"].push("customer=" + _customer.value)
                is_search = true
            }
            if(_transporter !== undefined && _transporter !== null && _transporter.value !== undefined){
                data_search["transporter_id"] = _transporter.value;
                data_search["param"].push("transporter=" + _transporter.value)
                is_search = true
            }
        }
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search.search !== undefined && data_search.search === true){
            if(data_search.param.length > 0){
                let param = data_search.param.join("&")
                if(param !== ""){
                    param = "?" + param
                }
                this.props.history.push("/bill-ladings" + param)
            }
            this.props.onSearch(data_search,1,this.props.bill_lading.limit, true);
        }
    }
    onViewAll(){
        this.userSelectBoxRef.onClean()
        this.props.onResetFieldSearch();
        this.props.history.push("/bill-ladings")
        this.props.onListInvoices(1,this.props.bill_lading.limit);
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        let param = "?page=" + pageNumber
        if(data_search.param.length > 0){
            let param_search = data_search.param.join("&")
            if(param_search !== ""){
                param += "&" + param_search
            }
        }
        this.props.history.push("/bill-ladings" + param)
        this.props.onFieldChange("page", pageNumber)
        this.props.onSearch(data_search,pageNumber,this.props.bill_lading.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/bill-lading/" + id);
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let ship_status_color = item.ship_status_color
                let ship_status_text = item.ship_status_text
                let branch_color = ""
                if (this.state.branchs.length > 0){
                    this.state.branchs.forEach(branchItem => {
                        if(branchItem.id === item.branch_id){
                            branch_color = <span className="badge" style={{backgroundColor: branchItem.color}}>&nbsp;</span>
                        }
                    });
                }
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td>{item.receipt_bill_code}</td>
                         <td>
                            <Moment format="DD/MM/YYYY HH:mm" unix>
                            { item.time }
                            </Moment>
                         </td>
                         <td>{ item.customer_name }<br />{item.customer_phone}</td>
                         <td>{ item.receiver_name }<br />{item.receiver_address}</td>
                         <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                         <td>{ item.transporter_name }</td>
                         <td className="text-center"><label className='label' style={{backgroundColor: ship_status_color }}>{ ship_status_text }</label></td>
                         <td className="text-center">{branch_color}</td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, search_code, branch_selected, search_button_loading } = this.props.bill_lading;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let branchs_array = [], branchs_label_array = []
        if (this.state.branchs.length > 0){
            branchs_array.push({
                label: "Tất cả chi nhánh",
                value: 0
            })
            this.state.branchs.forEach(item => {
                branchs_array.push({
                    label: item.branch_name,
                    value: item.id
                })
                branchs_label_array.push(<h6 key={"branch_name_text" + item.id} className="pull-left mr-left-5"><span className="badge" style={{backgroundColor: item.color}}>&nbsp;</span>&nbsp;{item.branch_name}</h6>)
            });
        }
        let branch_selected_value = 0
        if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
            branch_selected_value = branch_selected.id
        }
        return (
            <div className="container">
            <Helmet>
                <title>Danh sách hóa đơn bán hàng</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/bill-ladings"} />
            </Helmet>   
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter has_default={true} value={this.props.bill_lading.search_date} placeholder="Chọn ngày bán hàng" onFieldChange = { (value) => { this.onReceiptBillDatePickerChange(value) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã hóa đơn bán hàng" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <BillLadingStatusSelectBox multi={true}  />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox user_selected={this.props.bill_lading.search_customer_selected} getRef={rel => {this.userSelectBoxRef = rel} } onFieldChange={(item) => { this.onUserFieldChange(item) } } select_style={{width: 209}}/>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox user_type={UserActionType.TRANSPORTER_USER_TYPE} user_selected={this.props.bill_lading.search_transporter_selected} placeholder="Tìm người giao hàng" getRef={rel => {this.userTransporterSelectBoxRef = rel} } onFieldChange={(item) => { this.onTransporterFieldChange(item) } }/>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <SelectPicker
                                    style={{color: "#FFFFFF"}}
                                    block
                                    searchable={false}
                                    cleanable={false}
                                    placeholder="Chọn chi nhánh"
                                    data={branchs_array}
                                    value={branch_selected_value}
                                    onSelect={this.onSelectBranch}
                                    renderMenu={menu => {
                                        return menu;
                                    }}
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        <div className="pull-left">Vận đơn ({this.props.bill_lading.total} đơn)</div>
                                    </h2>
                                    <p></p>
                                </div>
                                <div className="pull-right mr-top-20">{branchs_label_array}</div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="15%"></col>
                                        <col></col>
                                        <col width="10%"></col>
                                        <col width="15%"></col>
                                        <col width="8%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã hóa đơn</th>
                                            <th>Thời gian</th>
                                            <th>Khách hàng</th>
                                            <th>Người nhận</th>
                                            <th className="text-right">Tổng tiền</th>
                                            <th>Người giao</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        bill_lading: state.bill_lading
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(BillLadingAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(BillLadingAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(BillLadingAction.resetFieldSearch())
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(BillLadingAction.searchRequest(data_search,page,limit,spinner))
        },
        onGetCustomer: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
        onGetBranchs: () => {
            return dispatch(LoginAction.getBranchsRequest())
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(BillLadings)