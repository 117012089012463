import * as BillLadingActionType from '../constants/BillLadingActionType';
import apiCaller from '../utils/apiCaller';

export const addRequest = (data_post) => {
    return () => {
        return apiCaller.callApi(`bill-ladings`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const fieldsChange = fields => {
    return {
        type: BillLadingActionType.FIELDS_CHANGE_BILL_LADINGS,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: BillLadingActionType.FIELD_CHANGE_BILL_LADINGS,
        field,
        value
    }
}

export const resetFieldSearch = () => {
    return {
        type: BillLadingActionType.RESET_FIELD_SEARCH_BILL_LADINGS
    }
}
export const getRequest = (id, is_ssl = false) => {
    return () => {
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if(is_ssl === true){
            return apiCaller.callApi(`get_billlading.php`,{id},'GET', true).then(res => {
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        return res.data.result
                    }
                }
            }).catch(() => {
                return null
            })
        }
        return apiCaller.callApi(`bill-ladings/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    return res.data.result
                }
            }
        });
    }
}
export const getByReceiptBillRequest = receipt_bill_id => {
    return () => {
        return apiCaller.callApi(`bill-ladings/receipt_bill/${receipt_bill_id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    return res.data.result
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner,is_ssl = false) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if(is_ssl === true){
            return apiCaller.callApi(`get_billladings.php`,{
                limit,
                page,
                ...data_search
            },'GET', true).then(res => {
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        dispatch(lists(res.data.result));
                        if (spinner === true){
                            dispatch(searchButtonSpinner(false));
                        }
                    }
                }
            });
        }
        return apiCaller.callApi(`bill-ladings`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(lists(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const lists = (result) => {
    return {
        type: BillLadingActionType.LIST_BILL_LADINGS,
        ...result
    }
}
const searchButtonSpinner = (search_button_loading) => {
    return {
        type: BillLadingActionType.SEARCH_BILL_LADINGS,
        search_button_loading
    }
}

export const listStatusRequest = (is_ssl = false) => {
    return (dispatch) => {
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if (is_ssl === true){
            return apiCaller.callApi(`get_billlading_status.php`,null,'GET',true).then(res => {
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        dispatch(listStatus(res.data.result));
                    }
                }
            });
        }
        return apiCaller.callApi(`bill-ladings/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: BillLadingActionType.LIST_BILL_LADINGS_STATUS,
        status
    }
}
export const updateRequest = (data_post, id, button_name = "", is_ssl = false)  => {
    return (dispatch) => {
        let _url = `bill-ladings/${id}`;
        let _method = 'PATCH';
        if(button_name !== ""){
            dispatch(updateButtonSpinner(button_name,true))
        }
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if (is_ssl === true){
            return apiCaller.callApi(`update_billlading.php`,{
                id,
                ...data_post
            },'POST', true).then(res => {
                if(res.status === 201 || res.status === 200){
                    if(button_name !== ""){
                        dispatch(updateButtonSpinner(button_name,false))
                    }
                    return {
                        status: res.data.status,
                        message: (res.data.message !== undefined)?res.data.message:""
                    }
                } 
                return {
                    status: false
                }
            });
        }
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getRequest(id))
                if(button_name !== ""){
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: BillLadingActionType.BUTTON_UPDATE_SPINNER_BILL_LADINGS,
        button_name,
        status
    }
}

export const getBillInProgressRequest = (user_id, is_ssl = false) => {
    return () => {
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if(is_ssl === true){
            return apiCaller.callApi(`get_billlading_inprogress.php`, null,'GET', true).then(res => {
                let info = null
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        info = res.data.result
                    }
                }
                return info
            });
        }
        return apiCaller.callApi(`bill-ladings/ship-in-progress/${user_id}`, null,'GET').then(res => {
            let info = null
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    info = res.data.result
                }
            }
            return info
        });
    }
}

export const scanInvoiceRequest = code => {
    return () => {
        return apiCaller.callApi(`assign_invoice.php`, {code},'POST', true).then(res => {
            let info = null
            if(res.status === 201 || res.status === 200){
                info = {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:""
                } 
            }
            return info
        });
    }
}

export const setBillLadingStatus = (id, data, is_ssl = false) => {
    return () => {
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        let _url = `bill-ladings/status/${id}`
        let data_post = {
            ship_status: data.status
        }
        let data_post_ssl = {
            id,
            ship_status: data.status,
        }
        if(data.status === BillLadingActionType.STATUS_CANCEL_SHIP_BILL_LADINGS){
            _url = `bill-ladings/cancel/${id}`
            data_post = {
                ship_status_reason: data.reason_cancel
            }
            data_post_ssl.ship_status_reason = data.reason_cancel
        } else if(data.status === BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS){
            _url = `bill-ladings/pending/${id}`
            data_post = {
                ship_status_reason: data.reason_pending,
                ship_status_reason_option: data.ship_status_reason_option
            }
            data_post_ssl.ship_status_reason = data.reason_pending
            data_post_ssl.ship_status_reason_option = data.ship_status_reason_option
        } else if(data.status === BillLadingActionType.STATUS_FINISH_SHIP_BILL_LADINGS){
            _url = `bill-ladings/finish/${id}`
        }
        if(is_ssl === true){
            return apiCaller.callApi(`update_billlading_status.php`,data_post_ssl,'POST', true).then(res => {
                if(res.status === 201 || res.status === 200){
                    return {
                        status: res.data.status,
                        message: (res.data.message)?res.data.message:""
                    }
                } 
                return {
                    status: false
                }
            });
        }
        return apiCaller.callApi(_url,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}