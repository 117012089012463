export const GET_SUPPLIER_VENDOR_BILL = 'GET_SUPPLIER_VENDOR_BILL';
export const LIST_SUPPLIER_VENDOR_BILL = 'LIST_SUPPLIER_VENDOR_BILL';
export const SEARCH_SUPPLIER_VENDOR_BILL = 'SEARCH_SUPPLIER_VENDOR_BILL';
export const RESET_FIELD_SEARCH_SUPPLIER_VENDOR_BILL = 'RESET_FIELD_SEARCH_SUPPLIER_VENDOR_BILL';
export const FIELD_CHANGE_SUPPLIER_VENDOR_BILL = 'FIELD_CHANGE_SUPPLIER_VENDOR_BILL';
export const FIELDS_CHANGE_SUPPLIER_VENDOR_BILL = 'FIELDS_CHANGE_SUPPLIER_VENDOR_BILL';
export const FOCUS_FIELD_SUPPLIER_VENDOR_BILL = 'FOCUS_FIELD_SUPPLIER_VENDOR_BILL';
export const ERROR_FIELD_SUPPLIER_VENDOR_BILL = 'ERROR_FIELD_SUPPLIER_VENDOR_BILL';
export const BUTTON_SPINNER_SUPPLIER_VENDOR_BILL = 'BUTTON_SPINNER_SUPPLIER_VENDOR_BILL';
export const BUTTON_UPDATE_SPINNER_SUPPLIER_VENDOR_BILL = 'BUTTON_UPDATE_SPINNER_SUPPLIER_VENDOR_BILL';
export const EMPTY_SUPPLIER_VENDOR_BILL = 'EMPTY_SUPPLIER_VENDOR_BILL';
export const SUM_TOTAL_SUPPLIER_VENDOR_BILL = 'SUM_TOTAL_SUPPLIER_VENDOR_BILL';
export const SELECT_PRODUCT_SUPPLIER_VENDOR_BILL = 'SELECT_PRODUCT_SUPPLIER_VENDOR_BILL';
export const UPDATE_PRODUCT_SUPPLIER_VENDOR_BILL = 'UPDATE_PRODUCT_SUPPLIER_VENDOR_BILL';
export const UPDATE_PRODUCTS_SUPPLIER_VENDOR_BILL = 'UPDATE_PRODUCTS_SUPPLIER_VENDOR_BILL';
export const CLEAR_PRODUCTS_SUPPLIER_VENDOR_BILL = 'CLEAR_PRODUCTS_SUPPLIER_VENDOR_BILL';

export const STATUS_NEW_SUPPLIER_VENDOR_BILL = 1
export const STATUS_FINISH_SUPPLIER_VENDOR_BILL = 2
export const STATUS_WARNING_SUPPLIER_VENDOR_BILL = 3
export const STATUS_CANCEL_SUPPLIER_VENDOR_BILL = 4