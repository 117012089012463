import React, { Component } from 'react';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
class DatePickerFilter extends Component {
    constructor(props){
        super(props)
        this.onReceiptBillDatePickerChange = this.onReceiptBillDatePickerChange.bind(this);
        let value = []
        if(this.props.value !== undefined && this.props.value !== null && this.props.value.length > 0){
            value = [moment(this.props.value[0]).toDate(),moment(this.props.value[1]).toDate()]
        }
        else if (this.props.has_default === true){
            value = [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
        }
        this.state = {
            value
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.value !== prevState.value){
            return { value: [moment(nextProps.value[0]).toDate(),moment(nextProps.value[1]).toDate()]};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.value !== this.props.value){
            this.setState({value: [moment(this.props.value[0]).toDate(),moment(this.props.value[1]).toDate()]});
        } 
    } 
    onReceiptBillDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            this.props.onFieldChange(value);
            this.setState({
                value
            })
        }
    }
    render(){
        let last_date_in_last_month = moment().endOf('month').add(moment().daysInMonth() * (-1), 'days')
        var number_date_last_month = (moment(last_date_in_last_month.year() + "-"+(last_date_in_last_month.month() + 1), "YYYY-MM").daysInMonth()) * (-1);
        return <DateRangePicker
                        value={this.state.value}
                        onChange = { (value) => { this.onReceiptBillDatePickerChange(value) } }
                        block
                        placement="auto"
                        placeholder={this.props.placeholder}
                        ranges={[{
                            label: 'Hôm qua',
                            value: [moment().add(-1, 'days').toDate(), moment().add(-1, 'days').toDate()]
                        }, {
                            label: 'Hôm nay',
                            value: [new Date(), new Date()]
                        }, {
                            label: 'Tuần này',
                            value: [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()]
                        }, {
                            label: 'Tuần trước',
                            value: [moment().startOf('isoWeek').add(-7, 'days').toDate(), moment().endOf('isoWeek').add(-7, 'days').toDate()]
                        }, {
                            label: '7 ngày qua',
                            value: [moment().add(-7, 'days').toDate(), new Date()]
                        }, {
                            label: 'Tháng này',
                            value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
                        }, {
                            label: 'Tháng trước',
                            value: [moment().startOf('month').add(number_date_last_month, 'days').toDate(), moment().endOf('month').add(moment().daysInMonth() * (-1), 'days').toDate()]
                        }, {
                            label: '30 ngày qua',
                            value: [moment().add(-30, 'days').toDate(), new Date()]
                        }, {
                            label: 'Quý này',
                            value: [moment().startOf('quarter').toDate(), moment().endOf('quarter').toDate()]
                        }, {
                            label: 'Quý trước',
                            value: [moment().quarter(moment().quarter() - 1).startOf('quarter').toDate(), moment().quarter(moment().quarter() - 1).endOf('quarter').toDate()]
                        }, {
                            label: 'Năm này',
                            value: [moment().startOf('year').toDate(), moment().endOf('year').toDate()]
                        }, {
                            label: 'Năm trước',
                            value: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()]
                        }]}
                    />
    }
}
DatePickerFilter.defaultProps = {
    has_default: false,
    value: null
}
export default DatePickerFilter