export const UPDATE_RECEIPT_OF_GOOD = 'UPDATE_RECEIPT_OF_GOOD';
export const GET_RECEIPT_OF_GOOD = 'GET_RECEIPT_OF_GOOD';
export const LIST_RECEIPT_OF_GOOD = 'LIST_RECEIPT_OF_GOOD';
export const EMPTY_RECEIPT_OF_GOOD = 'EMPTY_RECEIPT_OF_GOOD';
export const LIST_RECEIPT_OF_GOOD_STATUS = 'LIST_RECEIPT_OF_GOOD_STATUS';
export const GET_RECEIPT_OF_GOOD_STATUS = 'GET_RECEIPT_OF_GOOD_STATUS';
export const RESET_FIELD_SEARCH_RECEIPT_OF_GOOD = 'RESET_FIELD_SEARCH_RECEIPT_OF_GOOD';
export const SEARCH_RECEIPT_OF_GOOD = 'SEARCH_RECEIPT_OF_GOOD';
export const FOCUS_FIELD_RECEIPT_OF_GOOD = 'FOCUS_FIELD_RECEIPT_OF_GOOD';
export const FIELDS_CHANGE_RECEIPT_OF_GOOD = 'FIELDS_CHANGE_RECEIPT_OF_GOOD';
export const FIELD_CHANGE_RECEIPT_OF_GOOD = 'FIELD_CHANGE_RECEIPT_OF_GOOD';

export const STATUS_IN_PROGRESS_RECEIPT_OF_GOOD = 1;
export const STATUS_FINISH_RECEIPT_OF_GOOD = 2;
export const STATUS_CANCEL_RECEIPT_OF_GOOD = 3;