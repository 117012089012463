import * as InvoiceActionType from '../constants/InvoiceActionType';
import * as PaymentInvoiceActionType from '../constants/PaymentInvoiceActionType';
import * as AddressBookAction from './AddressBookAction';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: InvoiceActionType.FOCUS_FIELD_INVOICE,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: InvoiceActionType.ERROR_FIELD_INVOICE,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: InvoiceActionType.FIELDS_CHANGE_INVOICE,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: InvoiceActionType.FIELD_CHANGE_INVOICE,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: InvoiceActionType.EMPTY_INVOICE
    }
}

export const resetFieldSearch = () => {
    return {
        type: InvoiceActionType.RESET_FIELD_SEARCH_INVOICE
    }
}

export const buttonSpinner = status => {
    return {
        type: InvoiceActionType.BUTTON_SPINNER_INVOICE,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: InvoiceActionType.ITEM_BUTTON_SPINNER_INVOICE,
        id,
        status
    }
}

export const getRequest = (id, is_return = false, is_ssl = false) => {
    return (dispatch) => {
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if(is_ssl === true){
            return apiCaller.callApi(`get_invoice.php`,{id},'GET', true).then(res => {
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        return res.data.result
                    }
                }
            });
        }
        return apiCaller.callApi(`receipt-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result, is_return))
                    return res.data.result
                }
            }
        });
    }
}

const get = (invoice, is_return) => {
    return {
        type: InvoiceActionType.GET_INVOICE,
        invoice,
        is_return
    }
}

export const getPaymentBillsRequest = (id, skip_cod_payment = 0, is_not_dispatch = false, is_ssl = false) => {
    return (dispatch) => {
        if(process.env.REACT_APP_IS_LOCALHOST === "1"){
            is_ssl = false
        }
        if(is_ssl === true){
            return apiCaller.callApi(`get_payment_bills.php`,{
                "receipt_bill_id": id,
                "has_finance_source": 1,
                "skip_cod_payment": skip_cod_payment,
                "status": (PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE).toString().concat(',',(PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE).toString())
            },'GET', true).then(res => {
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    return res.data.result
                }
            });
        }
        return apiCaller.callApi(`payment-bills`,{
            "receipt_bill_id": id,
            "has_finance_source": 1,
            "skip_cod_payment": skip_cod_payment,
            "status": (PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE).toString().concat(',',(PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE).toString())
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                if (is_not_dispatch === false){
                    dispatch(getPaymentBills(res.data.result.list, res.data.result.total_payed))
                }
                return res.data.result
            }
        });
    }
}

const getPaymentBills = (payment_bills, total_payed) => {
    return {
        type: InvoiceActionType.GET_PAYMENT_BILLS_INVOICE,
        payment_bills,
        total_payed
    }
}

export const cancelPaymentInvoiceRequest = (reason_cancel, id) => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills/cancel/${id}`,{
            reason_cancel
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const getShipStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/ship-status`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getShipStatus(res.data.result.list, res.data.result.color))
            }
        });
    }
}

const getShipStatus = (list, color) => {
    return {
        type: InvoiceActionType.GET_SHIP_STATUS_INVOICE,
        list,
        color
    }
}

export const getBillStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/status`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getBillStatus(res.data.result.list, res.data.result.color))
            }
        });
    }
}

const getBillStatus = (list, color) => {
    return {
        type: InvoiceActionType.GET_BILL_STATUS_INVOICE,
        list,
        color
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "receipt_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: InvoiceActionType.GET_STAFF_NOTES_INVOICE,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "receipt_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: InvoiceActionType.GET_LAST_STAFF_NOTES_INVOICE,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}

export const getShipLogRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/ship-log/${id}`, null, 'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getShipLog(res.data.result))
            }
        });
    }
}

const getShipLog = lists => {
    return {
        type: InvoiceActionType.GET_SHIP_LOG_INVOICE,
        lists
    }
}

export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listInvoice(res.data.result));
                    dispatch(resetFlagViewAll());
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true))
        }
        dispatch(resetFlagViewAll(true))
        return apiCaller.callApi(`receipt-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listInvoice(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false))    
                    }
                    return res.data.result
                }
            }
        });
    }
}

const listInvoice = (result) => {
    return {
        type: InvoiceActionType.LIST_INVOICE,
        ...result
    }
}

export const resetFlagViewAll = (status) => {
    return {
        type: InvoiceActionType.RESET_FLAG_SEARCH_INVOICE,
        status
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: InvoiceActionType.SEARCH_INVOICE,
        search_button_loading
    }
}

export const listStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: InvoiceActionType.LIST_INVOICE_STATUS,
        status
    }
}

export const selectProductToListRequest = item => {
    return (dispatch) => {
        dispatch(selectProductToList(item))
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const selectProductToList = item => {
    return {
        type: InvoiceActionType.SELECT_PRODUCT_INVOICE,
        item
    }
}

export const updateProductItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        dispatch(updateProductItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, key, value) => {
    return {
        type: InvoiceActionType.UPDATE_PRODUCT_INVOICE,
        input_name,
        key,
        value
    }
}

export const updateProductReturnItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        dispatch(updateProductReturnItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotalForReturn());
        },200);
    }
}

export const sumTotalForReturn = () => {
    return {
        type: InvoiceActionType.SUM_TOTAL_RETURN_INVOICE
    }
}

const updateProductReturnItem = (input_name, key, value) => {
    return {
        type: InvoiceActionType.UPDATE_PRODUCT_RETURN_INVOICE,
        input_name,
        key,
        value
    }
}

export const updateProductsRequest = (products) => {
    return (dispatch) => {
        dispatch(updateProducts(products));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProducts = (products) => {
    return {
        type: InvoiceActionType.UPDATE_PRODUCTS_INVOICE,
        products
    }
}

export const clearProductRequest = () => {
    return {
        type: InvoiceActionType.CLEAR_PRODUCTS_INVOICE
    }
}

export const sumTotal = () => {
    return {
        type: InvoiceActionType.SUM_TOTAL_INVOICE
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`receipt-bills/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (res.data.result !== undefined && res.data.result !== null){
                    dispatch(emptyInfo())
                    dispatch(directToList(true));
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            }
        });
    }
}

export const updateShipStatusRequest = (status, id)  => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/ship-status/${id}`,{
            receipt_bill_ship_status: status
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getRequest(id))
            }
        });
    }
}

export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `receipt-bills/${id}`;
        let _method = 'POST';
        if(button_name !== "" && button_name !== "ship_status"){
            dispatch(updateButtonSpinner(button_name,true))
        }
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if(button_name !== "ship_status"){
                    dispatch(getRequest(id))
                }
                if (button_name === "save_note_button_loading"){
                    dispatch(getLastStaffNoteRequest(id))
                    dispatch(getStaffNotesRequest(id))
                }
                if(button_name !== "" && button_name !== "ship_status"){
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const updatePaymentRequest = (id, payment_bills)  => {
    return () => {
        return apiCaller.callApi(`receipt-bills/update-payment/${id}`,{
            "payment_bills": payment_bills
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const copyInvoiceRequest = id => {
    return () => {
        return apiCaller.callApi(`receipt-bills/copy/${id}`, null ,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const setBillStatus = (id, data) => {
    return () => {
        let _url = `receipt-bills/finish/${id}`
        let data_post = null
        if(data.status === InvoiceActionType.STATUS_CANCEL_INVOICE){
            _url = `receipt-bills/cancel/${id}`
            data_post = {
                reason_cancel: data.reason_cancel
            }
        } else if(data.status === InvoiceActionType.STATUS_IN_PROGRESS_INVOICE){
            _url = `receipt-bills/change-reorder/${id}`
            /*data_post = {
                money_collect_cod: data.money_collect_cod,
                products: data.products,
                payment_bills: data.payment_bills,
                shipping_info: data.shipping_info,
            }*/
        }
        return apiCaller.callApi(_url,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: InvoiceActionType.BUTTON_UPDATE_SPINNER_INVOICE,
        button_name,
        status
    }
}

const directToList = (status) => {
    return {
        type: InvoiceActionType.DIRECT_TO_LIST_INVOICE,
        status
    }
}



export const clearShippingInfoRequest = () => {
    return (dispatch) => {
        dispatch(AddressBookAction.resetList())
        dispatch(clearShippingInfo())
    }
}

const clearShippingInfo = () => {
    return {
        type: InvoiceActionType.CLEAR_SHIPPING_INFO
    }
}

export const submitReturn = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`return-bills/new`,{
            ...data_post
        },'PUT').then(res => {
            dispatch(buttonSpinner(false));
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    info: (res.data.result)?res.data.result:""  
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const submitImportReceiptTransferRequest = (id, data_post) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/set-import-transfer/${id}`, data_post,'POST').then(res => {
            if((res.status === 201 || res.status === 200) && res.data.status !== undefined && res.data.status === true){
                dispatch(get(res.data.result))
            } 
            return {
                status: res.data.status,
                message: (res.data.message)?res.data.message:""
            }
        });
    }
}