import * as InventoryActionType from '../constants/InventoryActionType';
import apiCaller from '../utils/apiCaller';


export const listProductRemainInventoryRequest = product_ids => {
    return (dispatch) => {
        return apiCaller.callApi(`inventories/products-remain`,{
            product_ids
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listProductRemainInventory(res.data.result));
                }
            }
        });
    }
}

const listProductRemainInventory = (list) => {
    return {
        type: InventoryActionType.PRODUCT_REMAIN_INVENTORY,
        list
    }
}