import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectPicker, Icon, Whisper, Popover } from 'rsuite';
import Modal from "../shared/Modal";
import CreatePartnerFast from '../partner/CreateFast';
import CreatePartnerMainInfo from '../partner/CreateMainInfo';
import * as UserAction from '../../actions/UserAction';
import * as ModalAction from '../../actions/ModalAction';
import * as UserActionType from '../../constants/UserActionType';
import apiCaller from '../../utils/apiCaller';
import Swal from 'sweetalert2';
import Helper from '../../utils/Helper';
let timeoutSearchUser = null
const triggerRef = React.createRef();
class UserSelectBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            list: [],
            selected: {},
            search_button_loading: false
        }
        this.onClean = this.onClean.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.opOpen = this.onOpen.bind(this);
    }
    onSearch(keyword, event){
        if(timeoutSearchUser !== null){
            clearTimeout(timeoutSearchUser)
        }
        if (!keyword) {
            return;
        }
        this.setState({
            search_button_loading: true
        })
        timeoutSearchUser = setTimeout(() => {
            var data_search = {
                "keyword": keyword,
                "user_type": this.props.user_type,
            }
            this.onSearchUsers(data_search);
        }, 700);
    }
    onSearchUsers(data_search){
        let is_ssl = false, url = `users`
        if(Helper.isSSl()){
            is_ssl = true
            url = "search_users.php"
        }
        apiCaller.callApi(url,{
            limit: 0,
            page: 1,
            ...data_search
        },'GET',is_ssl).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    this.setState({
                        list,
                        search_button_loading: false
                    })
                }
            }
        });
    }
    onSelect(_,item){
        let value = {}
        if(item !== null){
            if(this.props.from_invoice === true && +item.is_stop_serving === 1){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo',
                    html: "Khách hàng này đã ngừng phục vụ<br />Hãy liên hệ với quản lý để biết thông tin"
                })
                this.onClean()
                return
            }
            value = item
        }
        this.props.onFieldChange(value);
        this.setState({
            selected: value
        })
    }
    onClean = () => {
        this.props.onFieldChange({});
        this.setState({
            selected: {},
            list: []
        })
    }
    onOpen = () => {
        let input = document.getElementsByClassName("rs-picker-search-bar-input");
        input[0].focus()
    }
    handleModalClose(){
        this.props.onShowModal(false);
    }
    handleModalShowAddCustomer(){
        let model_title = "Tạo thông tin khách hàng"
        if (this.props.user_type === UserActionType.SUPPLIER_USER_TYPE){
            model_title = "Tạo thông tin nhà cung cấp"
        } else if (this.props.user_type === UserActionType.TRANSPORTER_USER_TYPE){
            model_title = "Tạo thông tin nhà vận chuyển"
        } else if (this.props.user_type === UserActionType.PAYMENT_OBJECT_USER_TYPE){
            model_title = "Tạo thông tin đối tượng chi trả"
        }
        this.props.onSetUserType(this.props.user_type)
        this.props.onShowModal(true, model_title, "modal-lg", "add_user", "submit_add_user");
    }
    handleModalSubmit(submit_type){
        if (submit_type === "submit_add_user"){
            this.handleSubmitFormAddCustomer()
        }
    }
    handleSubmitFormAddCustomer(){
        this.createPartnerRef.onSubmit()
    }
    onShowHideFormCreate(show){
        if (show === true){
            triggerRef.current.open();
        } else{
            triggerRef.current.close();
        }
    }
    onCreatePartnerReturn(user_info){
        if (this.props.use_for_create === true){
            this.props.onFieldChange({
                label: user_info.name,
                value: user_info.id,
                id: user_info.id,
                code: user_info.code,
                name: user_info.name,
                phone: user_info.phone,
                address: user_info.address,
                city: user_info.city,
                district: user_info.district,
                ward: user_info.ward,
                is_staff: user_info.is_staff_transporter,
                is_stop_serving: user_info.is_stop_serving,
                remain_point: user_info.remain_point,
            });
        }
        let _this = this
        if(user_info.user_type === UserActionType.CUSTOMER_USER_TYPE || user_info.user_type === UserActionType.TRANSPORTER_USER_TYPE || this.props.user_type === UserActionType.SUPPLIER_USER_TYPE){
            setTimeout(() => {
                _this.onShowHideFormCreate(false)
            }, 250)
        } else{
            this.handleModalClose();
        }
        this.setState({
            selected: {
                label: user_info.name,
                value: user_info.id
            },
            list: [{
                ...user_info
            }]
        })
    }
    componentWillUnmount() {
        if(typeof this.props.getRef !== "undefined"){
            this.props.getRef(undefined)
        }
    }
    componentDidMount(){
        if(typeof this.props.getRef !== "undefined"){
            this.props.getRef(this)
        }
    }
    render(){
        let { list, selected, search_button_loading } = this.state;
        let { show_modal, modal_type, modal_title, modal_submit_type, modal_button_spinner} = this.props.modal;
        let user_selected = 0
        if(typeof this.props.user_selected !== "undefined" && this.props.user_selected !== null && typeof this.props.user_selected.value !== "undefined"){
            user_selected = this.props.user_selected.value
        }
        let user_type = this.props.user_type
        let users_array = [];
        let has_in_list = false
        if (list.length > 0) {
            list.forEach(function(item){
                let label = item.name + " - " + item.phone
                if (user_type === UserActionType.SUPPLIER_USER_TYPE || user_type === UserActionType.TRANSPORTER_USER_TYPE){
                    label = item.name
                }
                users_array.push({
                    label: label,
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    phone: item.phone,
                    address: item.address,
                    city: item.city,
                    district: item.district,
                    ward: item.ward,
                    is_staff: item.is_staff_transporter,
                    is_stop_serving: item.is_stop_serving,
                    remain_point: item.remain_point,
                }); 
                if (user_selected > 0 && user_selected === item.id){
                    has_in_list = true
                }
            });
        }
        let user_value_selected = "";
        if(typeof selected !== "undefined" && selected !== null){
            user_value_selected = selected.value;
        }
        if(users_array.length === 0 && this.props.user_selected !== undefined && this.props.user_selected !== null && this.props.user_selected.value !== undefined && this.props.user_selected.value !== null){
            if (has_in_list === false) {
                users_array.push(this.props.user_selected); 
            }
            user_value_selected = this.props.user_selected.value;
        }
        let appearance = (typeof this.props.appearance !== "undefined" && this.props.appearance !== "")? this.props.appearance : "default"
        let selectBoxComponent = <SelectPicker
                    block={this.props.block}
                    disabled={!this.props.allow_update}
                    placeholder={this.props.placeholder}
                    cleanable={this.props.cleanable}
                    style={this.props.select_style}
                    appearance={appearance}
                    data={users_array}
                    value={user_value_selected}
                    onClean={this.onClean}
                    onSearch={this.onSearch}
                    onSelect={this.onSelect}
                    onOpen={this.onOpen}
                    renderMenu={menu => {
                        if (search_button_loading === true) {
                            return (
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                <Icon icon="spinner" spin />
                            </p>
                            );
                        }
                        return menu;
                    }}
                />
                let popover_title = "Thêm khách hàng"
                if(this.props.user_type === UserActionType.TRANSPORTER_USER_TYPE){
                    popover_title = "Thêm nhà vận chuyển"
                } else if(this.props.user_type === UserActionType.SUPPLIER_USER_TYPE){
                    popover_title = "Thêm nhà cung cấp"
                } else if(this.props.user_type === UserActionType.STAFF_USER_TYPE){
                    popover_title = "Thêm nhân viên"
                }
                
        return <div>{ this.props.has_add_new === true ? <div>
                    <div className="pull-left w-85">
                        { selectBoxComponent }
                    </div>
                    { this.props.allow_update === true ?
                    <div className="pull-left mr-left-5">
                        { this.props.user_type === UserActionType.CUSTOMER_USER_TYPE || this.props.user_type === UserActionType.TRANSPORTER_USER_TYPE || this.props.user_type === UserActionType.SUPPLIER_USER_TYPE ?
                        <Whisper
                            ref={triggerRef}
                            placement="leftStart"
                            preventOverflow
                            trigger='none'
                            speaker={<Popover className="form-create-partner" title={popover_title} style={{ width: 500 }}><div className="form-create-partner__close"><Icon className="cl_handover" icon='close' size="lg" onClick={ (e) => { this.onShowHideFormCreate(false) }} /></div><CreatePartnerMainInfo getRef={ref => (this.createPartnerRef = ref)} avatarUploaderWidth={100} avatarUploaderHeight={100} has_save_button={false} return_info={true} return={user_info => { this.onCreatePartnerReturn(user_info)  } } partner_type={this.props.user_type} /></Popover>}
                            enterable
                        >
                            <button type="button" className='btn btn-link btn-sm btn-plus' onClick={() => this.onShowHideFormCreate(true)}><Icon icon="plus-circle" size="2x" /></button>
                        </Whisper>
                        : <button type="button" className='btn btn-link btn-sm btn-plus' onClick={() => { this.handleModalShowAddCustomer() }}><Icon icon="plus-circle" size="2x" /></button> }
                    </div> : "" }
                    <div className="cl"></div>
                    { this.props.modal_inside === true ? <Modal show={show_modal} type={modal_type} spinner={modal_button_spinner} title={modal_title} handleClose={this.handleModalClose.bind(this)} handleSubmit={this.handleModalSubmit.bind(this,modal_submit_type)}  children={<CreatePartnerFast getRef={ref => (this.createPartnerRef = ref)} avatarUploaderWidth={100} avatarUploaderHeight={100} has_save_button={false} return_info={true} return={user_info => { this.onCreatePartnerReturn(user_info)  } } partner_type={this.props.user_type} />}></Modal> : "" }
                    </div> : selectBoxComponent }
                </div>
    }
}
UserSelectBox.defaultProps = {
    use_for_create: false,
    user_type: UserActionType.CUSTOMER_USER_TYPE,
    placeholder: "Tìm khách hàng",
    has_add_new: false,
    from_invoice: false,
    modal_inside: true,
    allow_update: true,
    cleanable: true,
    block: true,
    select_style: {}
}
const mapStateToProps = state => {
    return {
        modal: state.modal
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowModal: (status, title, type, input, submit_type) => {
            dispatch(ModalAction.showModal(status, title, type, input, submit_type))
        },
        onModalButtonSpinner(status){
            dispatch(ModalAction.buttonSpinner(status))
        },
        onSetUserType(type){
            dispatch(UserAction.setUserType(type))
        }
    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(UserSelectBox))