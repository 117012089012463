import React, { Component } from 'react';
import { Pagination } from 'rsuite';

class MyPagination extends Component {
    render(){
        return(
            <div className="mr-top-10"><div className="pull-right"><Pagination
                prev={true}
                next={true}
                first={true}
                last={true}
                ellipsis={true}
                boundaryLinks={true}
                activePage={this.props.page}
                pages={this.props.total_page}
                maxButtons={5}
                onSelect={this.props.pageChange}
            /></div><div className="cl"></div></div>
        );
    }
}
export default MyPagination;