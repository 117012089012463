import axios from 'axios';
//import qs from 'qs';
import Helper from '../utils/Helper';
const apiCaller = {
    callApi(endpoint, body = '', method = 'GET', is_ssl = false){
        //console.log(qs.stringify(body));
        let token = Helper.getJwt();
        let language_id = Helper.getLanguage();
        if(token === null){
            token = "";
        }
        if(body !== "" && body !== null){
            let skip_branch_param = false
            if(body.branch_id !== undefined && body.branch_id !== null){
                skip_branch_param = true
            }
            if (skip_branch_param === false){
                let branch_selected = Helper.getBranchSelected()
                if (branch_selected !== undefined && branch_selected !== null && branch_selected.id > 0){
                    body.branch_id = branch_selected.id 
                    body.branch_name = branch_selected.name
                }
            }
        }
        let url = `${process.env.REACT_APP_API_URL}/${endpoint}`
        if(is_ssl === true){
            url = `${process.env.REACT_APP_BILL_LADING_API_URL}/${endpoint}`
        }
        //console.log("url: ", url)
        return axios({
            method: method,
            url,
            headers: {
                //'Authorization': token,
                'language_id': language_id,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            params: (method === 'GET') ? body : null,
            data: body,
            withCredentials: true
        }).catch(err => {
            console.log(err);
        });
    },
    uploadFile(endpoint, files, uploadProgress){
        //console.log(qs.stringify(body));
        let token = Helper.getJwt();
        let language_id = Helper.getLanguage();
        if(token === null){
            token = "";
        }
        let data = new FormData()
        data.append('files_upload', files[0])
    
        return axios.put(`${process.env.REACT_APP_API_URL}/${endpoint}`, data, {
            headers: {
                'Authorization': token,
                'language_id': language_id,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            onDownloadProgress: uploadProgress
        }).catch(err => console.log(err))
    }
}
export default apiCaller;