import * as AddressBookActionType from '../constants/AddressBookActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: AddressBookActionType.FOCUS_FIELD_ADDRESS_BOOK,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: AddressBookActionType.ERROR_FIELD_ADDRESS_BOOK,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: AddressBookActionType.FIELD_CHANGE_ADDRESS_BOOK,
        field,
        value
    }
}

export const resetField = () => {
    return {
        type: AddressBookActionType.RESET_FIELD_ADDRESS_BOOK
    }
}

export const buttonSpinner = status => {
    return {
        type: AddressBookActionType.BUTTON_SPINNER_ADDRESS_BOOK,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: AddressBookActionType.ITEM_BUTTON_SPINNER_ADDRESS_BOOK,
        id,
        status
    }
}

export const updateRequest = (data_post) => {
    return (dispatch) => {
        let _url = `address-books/new`
        let method = 'PUT'
        let id = (data_post.id !== undefined && data_post.id !== null) ? data_post.id : 0
        if(id > 0){
            _url = `address-books/${id}`
            method = 'POST'
        }
        return apiCaller.callApi(_url,data_post,method).then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(resetField())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            }
        });
    }
}
export const deleteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`address-books/${id}`, null, 'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`address-books/${id}`,null,'GET').then(res => {
            dispatch(get(res.data.result))
            return res.data.result
        });
    }
}

const get = address_book => {
    return {
        type: AddressBookActionType.GET_ADDRESS_BOOK,
        address_book
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`address-books`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(lists(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

export const resetFieldSearch = () => {
    return {
        type: AddressBookActionType.RESET_FIELD_SEARCH_ADDRESS_BOOK
    }
}

export const resetList = () => {
    return {
        type: AddressBookActionType.RESET_LIST_ADDRESS_BOOK
    }
}

const lists = (result) => {
    return {
        type: AddressBookActionType.LIST_ADDRESS_BOOK,
        ...result
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: AddressBookActionType.SEARCH_ADDRESS_BOOK,
        search_button_loading
    }
}
export const setProps = (field,value) => {
    return (dispatch) => {
        let status = false
        if(value === "error"){
            status = true

            dispatch(fieldChange([field + "_active"],status))
            dispatch(fieldChange([field + "_error"],status))
            dispatch(fieldChange("field_focus", field))
        } else if(field === "clear_zone"){
            dispatch(fieldChange("clear_zone", value))
        }
    }
}