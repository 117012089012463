export const GET_VENDOR_BILL = 'GET_VENDOR_BILL';
export const LIST_VENDOR_BILL = 'LIST_VENDOR_BILL';
export const LIST_VENDOR_BILL_STATUS = 'LIST_VENDOR_BILL_STATUS';
export const SEARCH_VENDOR_BILL = 'SEARCH_VENDOR_BILL';
export const FIELD_CHANGE_VENDOR_BILL = 'FIELD_CHANGE_VENDOR_BILL';
export const FIELDS_CHANGE_VENDOR_BILL = 'FIELDS_CHANGE_VENDOR_BILL';
export const RESET_FIELD_VENDOR_BILL = 'RESET_FIELD_VENDOR_BILL';
export const RESET_FIELD_SEARCH_VENDOR_BILL = 'RESET_FIELD_SEARCH_VENDOR_BILL';
export const FOCUS_FIELD_VENDOR_BILL = 'FOCUS_FIELD_VENDOR_BILL';
export const ERROR_FIELD_VENDOR_BILL = 'ERROR_FIELD_VENDOR_BILL';
export const BUTTON_SPINNER_VENDOR_BILL = 'BUTTON_SPINNER_VENDOR_BILL';
export const BUTTON_UPDATE_SPINNER_VENDOR_BILL= 'BUTTON_UPDATE_SPINNER_VENDOR_BILL';
export const ITEM_BUTTON_SPINNER_VENDOR_BILL = 'ITEM_BUTTON_SPINNER_VENDOR_BILL';
export const DIRECT_TO_LIST_VENDOR_BILL = 'DIRECT_TO_LIST_VENDOR_BILL';
export const EMPTY_VENDOR_BILL = 'EMPTY_VENDOR_BILL';
export const SET_PRODUCTS_VENDOR_BILL = 'SET_PRODUCTS_VENDOR_BILL';
export const UPDATE_PRODUCT_VENDOR_BILL = 'UPDATE_PRODUCT_VENDOR_BILL';
export const SUM_TOTAL_VENDOR_BILL = 'SUM_TOTAL_VENDOR_BILL';

export const GET_STAFF_NOTES_VENDOR_BILL = 'GET_STAFF_NOTES_VENDOR_BILL';
export const GET_LAST_STAFF_NOTES_VENDOR_BILL = 'GET_LAST_STAFF_NOTES_VENDOR_BILL';
export const GET_BILL_STATUS_VENDOR_BILL = 'GET_BILL_STATUS_VENDOR_BILL';
export const GET_PAYMENT_BILLS_VENDOR_BILL = 'GET_PAYMENT_BILLS_VENDOR_BILL';

export const STATUS_NEW_VENDOR_BILL = 1;
export const STATUS_FINISH_VENDOR_BILL = 2;
export const STATUS_CANCEL_VENDOR_BILL = 3;