import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { Checkbox, RadioGroup, Radio, SelectPicker, Icon, Popover, Whisper, DateRangePicker } from 'rsuite';
import {FiRepeat} from 'react-icons/fi';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as ReportAction from '../../actions/ReportAction';
import * as ReportActionType from '../../constants/ReportActionType';
import DatePickerFilter from '../shared/DatePickerFilter';
import LoadingIndicator from '../shared/Loader';
import Helper from '../../utils/Helper';
import ReportImportExportDetails from './ReportImportExportDetails';
import ReportProductProfit from './ReportProductProfit';
import ReportImportExport from './ReportImportExport';
import BrandsSelectBox from '../product/BrandsSelectBox';
import Swal from 'sweetalert2/dist/sweetalert2';
const compareDateTriggerRef = React.createRef();
const compareDateRef = React.createRef();
let timeoutSearch = null
class ReportProducts extends Component {
    constructor(props){
        super(props)
        this.state = {
            branchs: [],
            name_template: "",
            save_report_template_loading: false,
            has_report_param: false,
            report_templates: [],
            report_template_selected: null,
            param_selected: {},
            compare_date_picker_open: false,
            compare_date: [],
            compare_from_date: "",
            compare_to_date: "",
            compare_list: []
        }
        this.onSelectBranch = this.onSelectBranch.bind(this);
        this.onSelectReportTemplate = this.onSelectReportTemplate.bind(this);    
        this.onCleanReportTemplate = this.onCleanReportTemplate.bind(this);
    }
    componentDidMount(){
        let branchs_state = []
        let branchs = Helper.getMyBranchs()
        if (branchs !== null && branchs.length > 0){
            branchs.forEach(item => {
                branchs_state.push(item)
            });
        }
        this.setState({
            branchs: branchs_state
        })
        let from_date = moment().startOf('month').format('YYYY-MM-DD'); 
        let to_date = moment().endOf('month').format('YYYY-MM-DD');

        let branch_search = Helper.getQueryString('branch');
        let from_date_search = Helper.getQueryString('from_date');
        let to_date_search = Helper.getQueryString('to_date');
        let compare_from_date_search = Helper.getQueryString('compare_from_date');
        let compare_to_date_search = Helper.getQueryString('compare_to_date');
        let brand_search = Helper.getQueryString('brand');
        let search_product = Helper.getQueryString('product');
        let interest = parseInt(Helper.getQueryString('interest'));
        let page = parseInt(Helper.getQueryString('page'));
        if(branch_search !== undefined && branch_search !== null && branch_search !== ""){
            branch_search = parseInt(branch_search)
            if(branch_search > 0){
                let branch_selected = {}
                branchs_state.forEach(item => {
                    if (branch_search === item.id){
                        branch_selected = {
                            name: item.branch_name,
                            id: item.id,
                        }
                    }   
                });
                this.props.onFieldChange("branch_selected",branch_selected);
            }
        }
        if(from_date_search !== undefined && from_date_search !== null && from_date_search !== ""){
            var from_date_check = moment(from_date_search);
            if (from_date_check.isValid() === true){
                from_date = from_date_search
            }
        }
        if(to_date_search !== undefined && to_date_search !== null && to_date_search !== ""){
            var to_date_check = moment(to_date_search);
            if (to_date_check.isValid() === true){
                to_date = to_date_search
            }
        }
        if(brand_search !== undefined && brand_search !== null && brand_search !== ""){
            let brand_search_array = brand_search.split(",")
            if(brand_search_array.length > 0){
                this.props.onFieldChange("brand_selected", brand_search_array);
            }
        }
        if(search_product !== undefined && search_product !== null && search_product !== ""){
            this.props.onFieldChange("search_product",search_product);
        }
        if(!(page > 0)){
            page = 1
        }
        if (!(interest > 0)){
            interest = ReportActionType.INTEREST_TYPE_PRODUCT_PROFIT
        }
        if(compare_from_date_search !== undefined && compare_from_date_search !== null && compare_from_date_search !== ""){
            this.setState({
                compare_from_date: compare_from_date_search,
                compare_to_date: compare_to_date_search
            })
        }
        this.props.onFieldChange("from_date",from_date);
        this.props.onFieldChange("to_date",to_date);
        this.props.onFieldChange("interest_type",interest);
        this.onViewReport(page)
        this.onListReportTemplates(interest)
    }
    componentDidUpdate(){
        if(this.state.compare_date_picker_open === true){
            compareDateTriggerRef.current.open()
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onCombineProductHandleCheck(value, checked){
        let is_combine_product = 0
        if (checked === true){
            is_combine_product = 1
        }
        this.props.onFieldChange("is_combine_product", is_combine_product);
        this.onViewReport()
    }
    handleClearFilter(){
        this.props.onFieldChange("brand_selected", []);
        this.props.onFieldChange("search_product", "");
        this.props.onFieldChange("branch_selected", {});
        this.setState({
            param_selected: {},
            report_template_selected: null,
            compare_date: [],
            compare_from_date: "",
            compare_to_date: "",
            compare_list: []
        })
    }
    onReportDatePickerChange(value){
        if (value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            this.props.onFieldChange("from_date",from_date);
            this.props.onFieldChange("to_date",to_date);
            this.handleClearFilter()
            this.onViewReport()
        }
    }
    onChangeInterest(value){
        this.props.onFieldChange("interest_type", value);
        this.props.onClearReport()
        this.handleClearFilter()
        this.onViewReport()
    }
    onViewReport(page){
        if(timeoutSearch !== null){
            clearTimeout(timeoutSearch)
        }
        if (!(page > 0)){
            page = 1
        }
        var _this = this
        let show_full_param = Helper.getQueryString('show_full');
        let show_full = 0
        if(show_full_param !== undefined){
            if(+show_full_param === 1){
                show_full = 1
            }
        }
        timeoutSearch = setTimeout(function(){   
            if(_this.props.report.from_date !== "" && _this.props.report.to_date !== ""){
                let param_selected = _this.state.param_selected
                let my_stores = Helper.getMyStores()
                let branch_id = 0, branch_name = ""
                let brand_ids = ""
                let branch_selected = _this.props.report.branch_selected
                if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
                    branch_id = branch_selected.id
                    branch_name = branch_selected.name
                }
                if (_this.props.report.interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS){
                    branch_selected = Helper.getBranchSelected()
                    branch_id = branch_selected.id
                    branch_name = branch_selected.name
                }
                if (_this.props.report.brand_selected.length > 0){
                    brand_ids = _this.props.report.brand_selected.join(",")
                }
                _this.props.onSetLoading(true)
                _this.props.onReport(_this.props.report.interest_type, {
                    branch_name,
                    branch_id,
                    store_id: my_stores.store_id,
                    from_date: _this.props.report.from_date,
                    to_date: _this.props.report.to_date,
                    is_combine: _this.props.report.is_combine_product,
                    product_keyword: _this.props.report.search_product,
                    brand_ids,
                    page
                })
                let has_report_param = false
                let param = []
                param.push("interest=" + _this.props.report.interest_type)
                param.push("from_date=" + _this.props.report.from_date)
                param.push("to_date=" + _this.props.report.to_date)
                let compare_from_date = "", compare_to_date = ""
                if(_this.state.compare_from_date !== null && _this.state.compare_from_date !== ""){
                    compare_from_date = _this.state.compare_from_date
                    compare_to_date = _this.state.compare_to_date
                    param.push("compare_from_date=" + compare_from_date)
                    param.push("compare_to_date=" + compare_to_date)
                    param.push("show_full=" + show_full)
                    _this.runCompare(compare_from_date, compare_to_date, page, false)
                }
                if (branch_id > 0){
                    param.push("branch=" + branch_id)
                    has_report_param = true
                }
                if (brand_ids !== ""){
                    param.push("brand=" + brand_ids)
                    has_report_param = true
                }
                if (_this.props.report.search_product !== null && _this.props.report.search_product !== ""){
                    param.push("product=" + _this.props.report.search_product)
                    has_report_param = true
                    param_selected.keyword = _this.props.report.search_product
                }
                if (page > 0){
                    param.push("page=" + page)
                }
                let param_text = param.join("&")
                _this.props.history.push("/report/products?" + param_text)
                _this.setState({
                    has_report_param,
                    param_selected
                })
            }
        },500)
    }
    onChangePage(page){
        this.onViewReport(page)
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null) {
            this.onViewReport()
        }
    }
    onSelectBranch(value){
        if(value !== null){
            let branch_selected_state = {}
            let param_selected = this.state.param_selected
            if (this.state.branchs.length > 0){
                this.state.branchs.forEach(item => {
                    if(item.id === value){
                        branch_selected_state = {
                            name: item.branch_name,
                            id: item.id,
                        }
                        
                    }
                });
            }
            param_selected.branch = branch_selected_state
            this.setState({
                param_selected
            })
            this.props.onFieldChange("branch_selected",branch_selected_state)
            this.onViewReport()
        }
    }
    onSelectBrand(opt){
        if(opt === null){
            opt = []
        }
        let param_selected = this.state.param_selected
        if(!(param_selected.brand !== undefined && param_selected.brand !== null && param_selected.brand.length > 0)){
            param_selected.brand = []
        }
        let final_brand_selected = []
        if(opt.length > 0){
            if(opt.length > 0 && param_selected.brand.length > 0){
                for(let i = 0; i < opt.length; i++){
                    for(let j = 0; j < param_selected.brand.length; j++){
                        if(param_selected.brand[j].value === opt[i]){
                            final_brand_selected.push({
                                ...param_selected.brand[j]
                            })
                            break
                        }
                    }
                }
            }
        }
        param_selected.brand = final_brand_selected
        this.setState({
            param_selected
        })
        this.props.onFieldChange("brand_selected",opt)
        this.onViewReport()
    }
    onBindBrandDataSelected(items){
        let param_selected = this.state.param_selected
        if(!(param_selected.brand !== undefined && param_selected.brand !== null && param_selected.brand.length > 0)){
            param_selected.brand = []
        }
        if(items.length > 0){
            items.forEach(item => {
                let allow_add = true
                if(param_selected.brand.length > 0){
                    for(let i = 0; i < param_selected.brand.length; i++){
                        if(param_selected.brand[i].value === item.value){
                            allow_add = false
                            break
                        }
                    }
                }
                if(allow_add === true){
                    param_selected.brand.push({
                        ...item
                    })
                }
            })
            this.setState({
                param_selected
            })
        }
    }
    onSelectBrandInfo(value, item){
        if(item !== null){
            let param_selected = this.state.param_selected
            if(!(param_selected.brand !== undefined && param_selected.brand !== null && param_selected.brand.length > 0)){
                param_selected.brand = []
            }
            let allow_add = true
            if(param_selected.brand.length > 0){
                for(let i = 0; i < param_selected.brand.length; i++){
                    if(param_selected.brand[i].value === item.value){
                        allow_add = false
                        break
                    }
                }
            }
            if(allow_add === true){
                param_selected.brand.push({
                    ...item
                })
            }
            let final_brand_selected = []
            if(value.length > 0 && param_selected.brand.length > 0){
                for(let i = 0; i < value.length; i++){
                    for(let j = 0; j < param_selected.brand.length; j++){
                        if(param_selected.brand[j].value === value[i]){
                            final_brand_selected.push({
                                ...param_selected.brand[j]
                            })
                            break
                        }
                    }
                }
            }
            param_selected.brand = final_brand_selected
            this.setState({
                param_selected
            })
        }
    }
    onCleanBrand(){
        this.props.onFieldChange("brand_selected", [])
        this.onViewReport()
    }
    showList(list_products){
        var result = null;
        if(list_products.length > 0){
            result = list_products.map((item, index) => {
                let gift_text = ""
                if (item.is_gift === 1){
                gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                }
                //console.log('id: ',item.id, item.retail_according);
                let retail_according = ""
                if (item.retail_according === 1){
                    retail_according = <span><br /><label className="label label-info">Giá nhập theo đợt</label></span>
                }
                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id,item.parse_name) }}>
                        <td className="text-center"><img src={item.url} alt="" /></td>
                        <td>{ item.model }</td>
                        <td>{gift_text}{ item.name }{retail_according}</td>
                        <td className="text-right"><NumberFormat value={item.remain} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.retail} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} />
</td>
                    </tr>
                );   
            });
        }
        return result;
    }
    onSelectReportTemplate(opt){
        this.setState({
            report_template_selected: opt
        })
        if (opt !== null){
            let report_template_selected = null
            for(let i = 0; i < this.state.report_templates.length; i++){
                if(this.state.report_templates[i].id === opt){
                    report_template_selected = this.state.report_templates[i]
                    break
                }
            }
            if(report_template_selected !== null){
                let param = JSON.parse(report_template_selected.params)
                let brand_ids = []
                if(param.brand_ids !== ""){
                    brand_ids = param.brand_ids.split(",")
                }
                this.props.onFieldChanges({
                    from_date: param.from_date,
                    to_date: param.to_date,
                    store_id: param.store_id,
                    branch_id: {
                        id: param.branch_id,
                        name: ""
                    },
                    brand_selected: brand_ids,
                    is_combine_product: param.is_combine,
                    search_product: param.product,
                });
                let _this = this
                setTimeout(() => {
                    _this.onViewReport()
                }, 250)
            }
        }
    }
    onCleanReportTemplate = () => {
        let _this = this
        _this.setState({
            report_template_selected: 0
        })
        let from_date = moment().startOf('month').format('YYYY-MM-DD'); 
        let to_date = moment().endOf('month').format('YYYY-MM-DD');
        _this.props.onFieldChanges({
            from_date,
            to_date,
            branch_id: null,
            brand_selected: "",
            is_combine_product: 0,
            search_product: "",
        });
        setTimeout(() => {
            _this.onViewReport()
        }, 250)
    }
    handleSaveReportTemplate = () => {
        if(this.state.name_template === ""){
            this.name_template_input.focus()
            return false
        }
        let my_stores = Helper.getMyStores()
        let branch_id = 0
        let brand_ids = ""
        let branch_selected = this.props.report.branch_selected
        if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
            branch_id = branch_selected.id
        }
        if (this.props.report.brand_selected.length > 0){
            brand_ids = this.props.report.brand_selected.join(",")
        }
        let params = {
            from_date: this.props.report.from_date,
            to_date: this.props.report.to_date,
            store_id: my_stores.store_id,
            branch_id,
            brand_ids,
            is_combine: this.props.report.is_combine_product,
            product: this.props.report.search_product,
        }
        this.props.onSaveReportTemplate({
            report_type: "product",
            interest: this.props.report.interest_type,
            params: JSON.stringify(params),
            name: this.state.name_template
        }).then(res => {
            if(res.status !== undefined){
                if(res.status === true){
                    let report_templates = this.state.report_templates
                    report_templates.push(res.result)
                    this.setState({
                        report_templates
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Thông báo',
                        text: "Mẫu báo cáo đã lưu thành công"
                    })   
                } else if(res.message !== undefined){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: res.message
                    })
                }
            }
        })
    }
    onListReportTemplates = (interest_type) => {
        this.props.onListReportTemplates("product", interest_type).then(res => {
            if(res.status !== undefined && res.status === true && res.result !== undefined && res.result !== null && res.result.length > 0){
                let report_templates = []
                res.result.forEach(item => {
                    report_templates.push(item)
                })
                this.setState({
                    report_templates
                })
            }
        })
    }
    handleRemoveParamFilter(param_type, index){
        let _this = this
        let has_change = false
        let param_selected = this.state.param_selected
        if(param_type === 'brand' && param_selected.brand !== undefined && param_selected.brand !== null && param_selected.brand.length > 0){
            param_selected.brand = param_selected.brand.filter((item, brand_index) => brand_index !== index)
            has_change = true
        } else if(param_type === 'keyword' && param_selected.keyword !== undefined && param_selected.keyword !== null){
            param_selected.keyword = ""
            has_change = true
        }
        if(has_change === true){
            let brand_selected = [], branch_id = null, is_combine_product = 0, search_product = ""
            if(param_selected.brand !== undefined && param_selected.brand !== null && param_selected.brand.length > 0){
                param_selected.brand.forEach(item => {
                    brand_selected.push(item.value)
                })
            }
            if(param_selected.keyword !== undefined && param_selected.keyword !== null && param_selected.keyword !== ""){
                search_product = param_selected.keyword
            }
            _this.props.onFieldChanges({
                branch_id,
                brand_selected,
                is_combine_product,
                search_product
            });
            setTimeout(() => {
                _this.onViewReport()
            }, 250)
    
            _this.setState({
                param_selected
            })
        }
    }
    onCompareDatePickerChange = (value) => {
        if (typeof value !== undefined && value !== null && value.length > 0){
            this.setState({
                compare_date: value
            })
        }
    }
    handleCompare = () => {
        let _this = this
        if(_this.state.compare_date.length === 0){
            compareDateRef.current.open()
            return false
        }
        compareDateTriggerRef.current.close()
        var from_date = moment(_this.state.compare_date[0]).format('YYYY-MM-DD'); 
        var to_date = moment(_this.state.compare_date[1]).format('YYYY-MM-DD');
        this.runCompare(from_date, to_date)
    }
    runCompare(from_date, to_date, page = 1, parse_url = true){
        let _this = this
        if(from_date !== "" && to_date){
            let my_stores = Helper.getMyStores()
            let branch_id = 0, branch_name = ""
            let brand_ids = ""
            let branch_selected = _this.props.report.branch_selected
            if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
                branch_id = branch_selected.id
                branch_name = branch_selected.name
            }
            if (_this.props.report.interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS){
                branch_selected = Helper.getBranchSelected()
                branch_id = branch_selected.id
                branch_name = branch_selected.name
            }
            if (_this.props.report.brand_selected.length > 0){
                brand_ids = _this.props.report.brand_selected.join(",")
            }
            _this.props.onSetLoading(true)
            _this.props.onReport(_this.props.report.interest_type, {
                branch_name,
                branch_id,
                store_id: my_stores.store_id,
                from_date,
                to_date,
                is_combine: _this.props.report.is_combine_product,
                product_keyword: _this.props.report.search_product,
                brand_ids,
                page
            }, false).then(response => {
                if(response.status === true){
                    if(parse_url === true){
                        let param = []
                        param.push("interest=" + _this.props.report.interest_type)
                        param.push("from_date=" + _this.props.report.from_date)
                        param.push("to_date=" + _this.props.report.to_date)
                        param.push("compare_from_date=" + from_date)
                        param.push("compare_to_date=" + to_date)
                        if (branch_id > 0){
                            param.push("branch=" + branch_id)
                        }
                        if (brand_ids !== ""){
                            param.push("brand=" + brand_ids)
                        }
                        if (_this.props.report.search_product !== null && _this.props.report.search_product !== ""){
                            param.push("product=" + _this.props.report.search_product)
                        }
                        if (page > 0){
                            param.push("page=" + page)
                        }
                        let param_text = param.join("&")
                        _this.props.history.push("/report/products?" + param_text)
                    }
                    _this.setState({
                        compare_list: response.list,
                        compare_from_date: from_date,
                        compare_to_date: to_date
                    })
                }
            })
        }
    }
    handleParseUrl = (show_full) => {
        let address_url = window.location.href
        address_url = address_url.replace(Helper.getMainUrl(),  "")
        address_url = address_url.replace(/&show_full=[0-9]/g, "")
        this.props.history.push(address_url + "&show_full=" + (show_full === true ?1:0))
    }
    render(){
        let { report_view, interest_type, list, loading, from_date, to_date, search_product, branch_selected, brand_selected  } = this.props.report;
        let date_filter = []
        if (from_date !== ""){
            date_filter.push(from_date)
        }
        if (to_date !== ""){
            date_filter.push(to_date)
        }
        let page_title = ""
        let branchs_array = []
        if (this.state.branchs.length > 0){
            branchs_array.push({
                label: "Tất cả",
                value: 0
            })
            this.state.branchs.forEach(item => {
                branchs_array.push({
                    label: item.branch_name,
                    value: item.id
                })
            });
        }
        let branch_selected_value = 0
        if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
            branch_selected_value = branch_selected.id
        }
        let report_templates_array = [], report_template_selected = 0
        if(this.state.report_templates.length > 0){
            this.state.report_templates.forEach(item => {
                report_templates_array.push({
                    ...item,
                    label: item.name,
                    value: item.id
                })
            })
            if(this.state.report_template_selected > 0){
                report_template_selected = this.state.report_template_selected
            }
        }
        let compare_before = true
        if(this.state.compare_to_date !== "" && to_date !== "" && moment(to_date).isBefore(this.state.compare_to_date)){
            compare_before = false
        }
        let reportComponent = <ReportProductProfit params={this.state.param_selected} onRemoveParamFilter={(param_type, index) => this.handleRemoveParamFilter(param_type, index)} compare_from_date={this.state.compare_from_date} compare_to_date={this.state.compare_to_date} compare_list={this.state.compare_list} compare_before={compare_before} list={list} branch_selected={branch_selected} from_date={from_date} to_date={to_date} onChangePage={page => { this.onChangePage(page) }} onParseUrl={(show_full) => this.handleParseUrl(show_full)} />
        if (interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS){
            branch_selected = Helper.getBranchSelected()
            reportComponent = <ReportImportExportDetails list={list} branch_selected={branch_selected} from_date={from_date} to_date={to_date} onChangePage={page => { this.onChangePage(page) }} />
            page_title = "Báo cáo hàng hóa xuất nhập tồn chi tiết " + branch_selected.name
        }
        else if (interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT){
            reportComponent = <ReportImportExport list={list} branch_selected={branch_selected} from_date={from_date} to_date={to_date} onChangePage={page => { this.onChangePage(page) }} />
            page_title = "Báo cáo hàng hóa xuất nhập tồn " + branch_selected.name
        } 
        else if (interest_type === ReportActionType.INTEREST_TYPE_PRODUCT_PROFIT){
            page_title = "Báo cáo lợi nhuận theo hàng hóa"
            if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
                page_title += " tại " + branch_selected.name
            }
        }
        return (
            <div className="container">
            <Helmet>
                <title>{ page_title }</title>
            </Helmet>   
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Kiểu hiển thị</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <RadioGroup
                                                name="report_view"
                                                value={report_view}
                                                onChange={value => { this.props.onFieldChange('report_view', value) } }
                                            >
                                            <Radio checked={report_view === ReportActionType.REPORT_VIEW_CHART ? true : false} value={ReportActionType.REPORT_VIEW_CHART}> Biểu đồ</Radio>
                                            <Radio checked={report_view === ReportActionType.REPORT_VIEW_INFO ? true : false} value={ReportActionType.REPORT_VIEW_INFO}> Báo cáo</Radio>
                                        </RadioGroup>    
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <Checkbox onChange={this.onCombineProductHandleCheck.bind(this) }> Gộp thuộc tính</Checkbox>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Mối quan tâm</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <RadioGroup
                                                name="report_view"
                                                value={interest_type}
                                                onChange={value => { this.onChangeInterest(value) }}
                                            >
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_SELL ? true : false} value={ReportActionType.INTEREST_TYPE_SELL}> Bán hàng</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_PRODUCT_PROFIT ? true : false} value={ReportActionType.INTEREST_TYPE_PRODUCT_PROFIT}> Lợi nhuận</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT ? true : false} value={ReportActionType.INTEREST_TYPE_IMPORT_EXPORT}> Xuất nhập tồn</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS ? true : false} value={ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS}> Xuất nhập tồn chi tiết</Radio>
                                        </RadioGroup>    
                                    </div>
                                </div>
                            </div>    
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Mẫu báo cáo</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <SelectPicker
                                            style={{color: "#FFFFFF"}}
                                            block
                                            searchable={false}
                                            cleanable={true}
                                            placeholder="Chọn mẫu báo cáo"
                                            data={report_templates_array}
                                            value={report_template_selected}
                                            onSelect={this.onSelectReportTemplate}
                                            onClean={this.onCleanReportTemplate}
                                            renderMenu={menu => {
                                                return menu;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Thời gian</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <DatePickerFilter placeholder="Chọn thời gian báo cáo" has_default={true} value={date_filter} onFieldChange = { (value) => { this.onReportDatePickerChange(value) } }
                                            />
                                    </div>
                                </div>
                            </div> 
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Hàng hóa</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <input name="search_product" value={ search_product } className="form-control" autoComplete="off" placeholder="Nhập mã hoặc tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                                    </div>
                                </div>
                            </div>   
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Thương hiệu</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <BrandsSelectBox is_multiple={true} is_report={true} selected={brand_selected} onChange={(opt) => { this.onSelectBrand(opt) }} onSelect={(value, item) => { this.onSelectBrandInfo(value, item) }} onBindDataSelected={(items) => this.onBindBrandDataSelected(items) } onClean={() => { this.onCleanBrand() }} />
                                    </div>
                                </div>
                            </div> 
                            { (interest_type !== ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS) ? 
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Chi nhánh</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <SelectPicker
                                            style={{color: "#FFFFFF"}}
                                            block
                                            searchable={false}
                                            cleanable={false}
                                            placeholder="Chọn chi nhánh"
                                            data={branchs_array}
                                            value={branch_selected_value}
                                            onSelect={this.onSelectBranch}
                                            renderMenu={menu => {
                                                return menu;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> : "" }
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        { loading === true ? <LoadingIndicator /> : <div>
                                {this.state.has_report_param ===true ?
                                <div className="report-button-tool">
                                    <div className="pull-left">
                                        <Whisper
                                            preventOverflow
                                            trigger="click"
                                            speaker={
                                                <Popover title="Nhập tên mẫu báo cáo" style={{ width: 250 }}>
                                                    <div className="pull-left w-80">
                                                        <div className="float-lb floating-lb">
                                                            <div className="nk-int-st nk-toggled">
                                                                <input type="text" className="form-control" autoComplete="off" name="name_template" onChange={ (e) => { this.setState({
                                                                    name_template: e.target.value
                                                                }) } } ref={(input) => { this.name_template_input = input; }} value={this.state.name_template}></input> 
                                                            </div>
                                                        </div>    
                                                    </div>
                                                    <LaddaButton
                                                        loading={ this.state.save_report_template_loading }
                                                        data-style={ZOOM_IN}
                                                        data-spinner-color="#000000"
                                                        className="btn btn-info pull-left mr-left-5" onClick={ () => { this.handleSaveReportTemplate() } } >
                                                        <i className="fa fa-save"></i>
                                                    </LaddaButton>
                                                </Popover>
                                            }
                                            placement={this.props.placement}
                                            enterable
                                        >
                                            <button type="button" className='btn btn-link btn-sm btn-plus'><Icon icon="save" />&nbsp;Lưu mẫu báo cáo</button>
                                        </Whisper>
                                    </div>
                                    <div className="pull-left mr-left-10">
                                        <Whisper
                                            ref={compareDateTriggerRef}
                                            preventOverflow
                                            trigger="none"
                                            speaker={
                                                <Popover title="Chọn khoảng ngày để so sánh" style={{ width: 375 }}>
                                                    <div className="pull-left w-70">
                                                        <DateRangePicker
                                                            ref={compareDateRef}
                                                            value={this.state.compare_date}
                                                            onChange = { (value) => { this.onCompareDatePickerChange(value) } }
                                                            block
                                                            ranges={[]}
                                                            cleanable={false}
                                                            placement="auto" />
                                                    </div>
                                                    <LaddaButton
                                                        loading={ this.state.save_report_template_loading }
                                                        data-style={ZOOM_IN}
                                                        data-spinner-color="#000000"
                                                        className="btn btn-info pull-left mr-left-5" onClick={ () => { this.handleCompare() } } >
                                                        <i className="fa fa-chevron-right"></i>
                                                    </LaddaButton>
                                                    <button className="btn btn-default pull-left mr-left-5" onClick={ () => { compareDateTriggerRef.current.close() } } >
                                                        <i className="fa fa-close"></i>
                                                    </button>
                                                </Popover>
                                            }
                                            placement={this.props.placement}
                                            enterable
                                        >
                                            <button type="button" className='btn btn-link btn-sm btn-compare' onClick={() => compareDateTriggerRef.current.open()}><FiRepeat />&nbsp;So sánh</button>
                                        </Whisper>
                                    </div>
                                </div> : null }    
                                <div>

                                </div>
                            {reportComponent}
                        </div> }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        report: state.report
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(ReportAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(ReportAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ReportAction.fieldChange(field,value))
        },
        onFieldChanges(fields){
            dispatch(ReportAction.fieldChanges(fields))
        },
        onSetLoading(status){
            dispatch(ReportAction.setLoading(status))
        },
        onClearReport: () => {
            dispatch(ReportAction.clearReportRequest())
        },
        onReport: (interest_type, data_post, has_dispatch) => {
            return dispatch(ReportAction.reportRequest(interest_type, data_post, has_dispatch))
        },
        onReportByChart: (data_post) => {
            dispatch(ReportAction.reportByChartRequest(data_post))
        },
        onSaveReportTemplate: (data_post) => {
            return dispatch(ReportAction.saveReportTemplateRequest(data_post))
        },
        onListReportTemplates: (report_type, interest_type) => {
            return dispatch(ReportAction.listReportTemplatesRequest(report_type, interest_type))
        },
    }
}
const ReportProductsConnect = (connect(mapStateToProps, mapDispatchToProps)(ReportProducts));
export default withRouter(ReportProductsConnect);