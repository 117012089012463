import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import LaddaButton, { ZOOM_IN, S } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import UserSelectBox from '../shared/UserSelectBox';
import MyPagination from '../shared/Pagination';
import * as ProductExpiriesAction from '../../actions/ProductExpiriesAction';
import * as UserActionType from '../../constants/UserActionType';
import Helper from '../../utils/Helper';
import Swal from 'sweetalert2';

class ProductExpiries extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_modal_set_list: false
        }
    }
    componentDidMount(){
        this.props.onSearchProductExpiries({},1,this.props.product_expiries.limit, true);
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value)
    }
    onSearchProductEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _model = this.props.product_expiries.search_model;
        let _name = this.props.product_expiries.search_name;
        let _supplier_selected = this.props.product_expiries.search_supplier_selected;
        var data_search = {
            "model": _model,
            "name": _name,
            "search_supplier_selected": _supplier_selected.value,
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search !== null){
            this.props.onSearchProductExpiries(data_search,1,this.props.product_expiries.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        this.props.onSearchProductExpiries(data_search,pageNumber,this.props.product_expiries.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/product/" + id);
    }
    onSelectItemFieldChange = (event, index, expiry_info_index) => {
        let target = event.target;
        let value = 0
        if(target.checked === true){
            value = 1
        }
        let list_product_expiries = this.props.product_expiries.list
        let item_selected = this.props.product_expiries.item_selected
        if(item_selected === null){
            item_selected = []
        }
        if(list_product_expiries.length > 0){
            let product_expiry = list_product_expiries[index]
            if (product_expiry !== undefined && product_expiry.product_id !== undefined && product_expiry.product_id > 0 && product_expiry.expiry_info !== undefined && product_expiry.expiry_info !== null && product_expiry.expiry_info.length > 0){
                let expiry_info = product_expiry.expiry_info[expiry_info_index]
                if (expiry_info !== undefined && expiry_info.id !== undefined && expiry_info.id > 0){
                    product_expiry.expiry_info[expiry_info_index].selected = value
                    let allow_add = true
                    if (item_selected.length > 0){
                        for(let i = 0; i < item_selected.length; i++){
                            if(item_selected[i].id === expiry_info.id){
                                allow_add = false
                                break
                            }
                        }
                    }
                    if (allow_add === true && value === 1){
                        item_selected.push({
                            "product_id": product_expiry.product_id,
                            "product_option_id": product_expiry.product_option_id,
                            "product_option_name": product_expiry.product_option_name,
                            "product_name": product_expiry.product_name,
                            "product_model": product_expiry.product_model,
                            "product_url": product_expiry.product_url,
                            "category_id": product_expiry.category_id,
                            "category_name": product_expiry.category_name,
                            "brand_id": product_expiry.brand_id,
                            "brand_name": product_expiry.brand_name,
                            ...expiry_info
                        })
                    } else if (allow_add === false && value === 0){
                        item_selected = item_selected.filter(item => item.id !== expiry_info.id)
                    }
                }
                list_product_expiries[index] = product_expiry
            }
        }
        this.props.onFieldsChange({
            "list": list_product_expiries,
            "item_selected": item_selected,
            "list_selected": [],
            "supplier_selected": {}
        })
    }
    onSetListProduct = () => {
        let item_selected = this.props.product_expiries.item_selected
        if(!(item_selected.length > 0)){
            Swal.fire("Thông báo", "Vui lòng chọn hàng hóa muốn đổi", "error")
            return false
        }
        let supplier_id = 0, supplier_name = ""
        let products = []
        for(let i = 0; i < item_selected.length; i++){
            if(supplier_id === 0){
                supplier_id = item_selected[i].supplier_id
                supplier_name = item_selected[i].supplier_name
            } else if (supplier_id > 0 && supplier_id !== item_selected[i].supplier_id){
                Swal.fire("Thông báo", "Vui lòng chọn hàng hóa muốn đổi từ nhà cung cấp " + item_selected[i].supplier_name + " đã được chọn đầu tiên", "error")
                return false
            }
            let allow_add = true
            if(products.length > 0){
                for(let j = 0; j < products.length; j++){
                    if(products[j].product_id === item_selected[i].product_id){
                        products[j].quantity += item_selected[i].quantity
                        let allow_add_detail = true
                        for(let k = 0; k < products[j].details.length; k++){
                            if(products[j].details[k].import_receipt_id === item_selected[i].import_receipt_id){
                                products[j].details[k].quantity += item_selected[i].quantity
                                allow_add_detail = false
                                break
                            }
                        }
                        if(allow_add_detail === true){
                            products[j].details.push({
                                "import_receipt_id": item_selected[i].import_receipt_id,
                                "import_receipt_code": item_selected[i].import_receipt_code,
                                "store_id": item_selected[i].store_id,
                                "store_name": item_selected[i].store_name,
                                "store_code": item_selected[i].store_code,
                                "retail": item_selected[i].product_retail,
                                "quantity": item_selected[i].quantity,
                                "product_gift": item_selected[i].product_gift,
                            })
                        }
                        allow_add = false
                        break
                    }
                }
            }
            if(allow_add === true){
                products.push({
                    "product_id": item_selected[i].product_id,
                    "product_option_id": item_selected[i].product_option_id,
                    "product_option_name": item_selected[i].product_option_name,
                    "product_name": item_selected[i].product_name,
                    "product_model": item_selected[i].product_model,
                    "product_url": item_selected[i].product_url,
                    "category_id": item_selected[i].category_id,
                    "category_name": item_selected[i].category_name,
                    "brand_id": item_selected[i].brand_id,
                    "brand_name": item_selected[i].brand_name,
                    "quantity": item_selected[i].quantity,
                    "details": [
                        {
                            "import_receipt_id": item_selected[i].import_receipt_id,
                            "import_receipt_code": item_selected[i].import_receipt_code,
                            "store_id": item_selected[i].store_id,
                            "store_name": item_selected[i].store_name,
                            "store_code": item_selected[i].store_code,
                            "retail": item_selected[i].product_retail,
                            "quantity": item_selected[i].quantity,
                            "product_gift": item_selected[i].product_gift,
                        }
                    ]
                })
            }
        }
        this.props.onFieldsChange({
            "list_selected": products,
            "supplier_selected": {
                id: supplier_id,
                name: supplier_name
            }
        })
        this.onShowHideModalSetList()
    }
    onSaveList(){

    }
    onShowHideModalSetList(){
        this.setState({
            show_modal_set_list: !this.state.show_modal_set_list
        })
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let row_span = item.expiry_info.length + 1
                return (
                    <Fragment key={index}>
                        <tr className="cl_handover" onClick={ () => { this.onItemClick(item.id) }}>
                            <td rowSpan={row_span} className="text-center text-middle"><img src={item.product_url} alt="" /></td>
                            <td rowSpan={row_span} className="text-middle">{ item.product_model }</td>
                            <td rowSpan={row_span} className="text-middle">
                                { item.product_name }
                            </td>
                        </tr>
                        {item.expiry_info.map((expiry_info_item, expiry_info_index) => {
                            let gift_text = <NumberFormat value={expiry_info_item.product_retail} displayType={'text'} thousandSeparator={true} />
                            if (expiry_info_item.product_gift === 1){
                                gift_text = <i className="fa fa-gift"></i>
                            }
                            return <tr key={expiry_info_index}>
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                        { Helper.replaceMysqlDateTime(expiry_info_item.expiry_date) }
                                        </Moment>
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat value={expiry_info_item.quantity} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td className={expiry_info_item.product_gift === 1 ? "text-center" : "text-right"}>{gift_text}</td>
                                    <td><Link to={"/purchase-order/" + expiry_info_item.import_receipt_id} target="_blank">{expiry_info_item.import_receipt_code}</Link></td>
                                    <td>{ expiry_info_item.supplier_name }</td>
                                    <td className="text-center">
                                        <div className="toggle-select-act fm-cmp-mg">
                                            <div className="nk-toggle-switch">
                                                <input id={"payment_done_" + index + '_' + expiry_info_index} name={"payment_done_" + index + '_' + expiry_info_index} type="checkbox" hidden="hidden" value="1" onChange={ (e) => { this.onSelectItemFieldChange(e, index, expiry_info_index) } } checked={expiry_info_item.selected === 1?true:false}></input>
                                                <label htmlFor={"payment_done_" + index + '_' + expiry_info_index} className="ts-helper"></label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                        })}
                    </Fragment>
                );   
            });
        }
        return result;
    }

    showListSelected(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let row_span = item.details.length + 1
                return (
                    <Fragment key={index}>
                        <tr className="cl_handover" onClick={ () => { this.onItemClick(item.id) }}>
                            <td rowSpan={row_span} className="text-center text-middle"><img src={item.product_url} alt="" /></td>
                            <td rowSpan={row_span} className="text-middle">{ item.product_model }</td>
                            <td rowSpan={row_span} className="text-middle">
                                { item.product_name }
                            </td>
                        </tr>
                        {item.details.map((expiry_info_item, expiry_info_index) => {
                            let gift_text = <NumberFormat value={expiry_info_item.retail} displayType={'text'} thousandSeparator={true} />
                            if (expiry_info_item.product_gift === 1){
                                gift_text = <i className="fa fa-gift"></i>
                            }
                            return <tr key={expiry_info_index}>
                                    <td className="text-right">
                                        <NumberFormat value={expiry_info_item.quantity} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td className={expiry_info_item.product_gift === 1 ? "text-center" : "text-right"}>{gift_text}</td>
                                    <td><Link to={"/purchase-order/" + expiry_info_item.import_receipt_id} target="_blank">{expiry_info_item.import_receipt_code}</Link></td>
                                </tr>
                        })}
                    </Fragment>
                );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, search_model, search_name, search_gift, search_button_loading, list_selected, save_list_button_loading } = this.props.product_expiries;
        
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_model" value={ search_model } className="form-control" autoComplete="off" placeholder="Nhập mã hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchProductEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_name" value={ search_name } className="form-control" autoComplete="off" placeholder="Nhập tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchProductEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox getRef={ref => { this.supplierSelectBoxRef = ref } } placeholder="Tìm nhà cung cấp" user_type={UserActionType.SUPPLIER_USER_TYPE} onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="toggle-select-act fm-cmp-mg">
                                    <div className="nk-toggle-switch">
                                        <label htmlFor="search_gift" className="ts-label">Là quà tặng</label>
                                        <input id="search_gift" name="search_gift" type="checkbox" hidden="hidden" value="1" checked={search_gift === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                        <label htmlFor="search_gift" className="ts-helper"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        { _pagination }
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        Hàng hóa đến hạn sử dụng
                                    </h2>
                                    <p></p>
                                </div>
                                <button type="button" className="btn btn-info btn-set-list-product-expired" onClick={() => this.onSetListProduct()}><i className="fa fa-check"></i>&nbsp;Lập danh sách</button>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-condensed">
                                    <colgroup>
                                        <col width="8%"></col>
                                        <col width="10%"></col>
                                        <col width="20%"></col>
                                        <col width="8%"></col>
                                        <col width="8%"></col>
                                        <col width="8%"></col>
                                        <col width="12%"></col>
                                        <col width="12%"></col>
                                        <col width="1%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Mã hàng</th>
                                            <th>Tên hàng</th>
                                            <th>Hạn sử dụng</th>
                                            <th className="text-right">Số lượng</th>
                                            <th className="text-right">Giá nhập</th>
                                            <th>Phiếu nhập</th>
                                            <th>Nhà cung cấp</th>
                                            <th className="text-center">Chọn</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
                <div className={this.state.show_modal_set_list === true ? "modal display-block" : "modal display-none"}>
                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Danh sách đã lập</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.onShowHideModalSetList()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            { list_selected.length > 0 ?
                            <table className="table table-sc-ex table-hover table-condensed">
                                <colgroup>
                                    <col width="8%"></col>
                                    <col width="12%"></col>
                                    <col></col>
                                    <col width="12%"></col>
                                    <col width="12%"></col>
                                    <col width="12%"></col>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Mã hàng</th>
                                        <th>Tên hàng</th>
                                        <th className="text-right">Số lượng</th>
                                        <th className="text-right">Giá nhập</th>
                                        <th>Phiếu nhập</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { this.showListSelected(list_selected) }
                                </tbody>
                            </table> : null }
                        </div>
                        <div className="modal-footer">
                            <LaddaButton
                                loading={ save_list_button_loading }
                                data-size={S}
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-info btn-save-bill-lading-info" onClick={ () => { this.onSaveList() } } >
                                <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                            </LaddaButton>
                            <button type="button" className="btn btn-secondary btn-close-bill-lading-info" data-dismiss="modal" onClick={() => this.onShowHideModalSetList()}><i className="fa fa-ban"></i>&nbsp;Đóng lại</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        product_expiries: state.product_expiries
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFocusField(field,value){
            dispatch(ProductExpiriesAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ProductExpiriesAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(ProductExpiriesAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(ProductExpiriesAction.resetFieldSearch())
        },
        onSearchProductExpiries: (data_search,page,limit,spinner) => {
            dispatch(ProductExpiriesAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const ProductExpiriesConnect = (connect(mapStateToProps, mapDispatchToProps)(ProductExpiries));
export default withRouter(ProductExpiriesConnect);