import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import { Alert, Table, InputNumber, SelectPicker } from 'rsuite';
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import * as ReceiptGoodAction from '../../actions/ReceiptGoodAction';
import * as ReceiptGoodActionType from '../../constants/ReceiptGoodActionType';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as DiscountModeType from '../../constants/DiscountModeType';
import InvoiceShipping from './InvoiceShipping';
import SellChannelSelectBox from '../shared/SellChannelSelectBox';
import Helper from '../../utils/Helper';
import PaymentInput from '../shared/PaymentInput';
import SearchProduct from '../shared/SearchProduct';
import DiscountInput from '../shared/DiscountInput';
const { Column, HeaderCell, Cell } = Table;
let timeoutSearchReceiptReturn = null
class ReturnInvoice extends Component {
    componentDidMount(){
        let { match } = this.props;
        let receipt_good_id = parseInt(Helper.getQueryString('receipt_id'));
        let id = parseInt(Helper.getQueryString('id'));
        if(match !== undefined && match.params !== undefined && match.params.id !== undefined && match.params.id > 0){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)         
        } else if (receipt_good_id > 0){
            this.props.onGetReceiptGood(receipt_good_id, true).then(item => {
                if (item.receipt_status === ReceiptGoodActionType.STATUS_CANCEL_RECEIPT_GOODS){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        text: "Phiếu nhận hàng đã hủy, không thể tiến hành trả hàng"
                    }).then((_) => {
                        this.props.history.push("/receipt-good/" + receipt_good_id)
                    })
                } else if (item.receipt_status === ReceiptGoodActionType.STATUS_FINISH_RECEIPT_GOODS){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        text: "Phiếu nhận hàng đã hoàn tất, không thể tiến hành trả hàng"
                    }).then((_) => {
                        this.props.history.push("/receipt-good/" + receipt_good_id)
                    })
                } else {
                    this.props.onFieldChange("receipt_return_selected",item)
                    if(item.receipt_bill_id !== undefined && item.receipt_bill_id > 0){
                        this.props.onEmptyInfo()
                        this.onInitData(item.receipt_bill_id, item.products)
                        //this.props.history.push("/invoice/return?id=" + item.receipt_bill_id)
                    }
                }
            })
        }
    }
    onInitData(id, product_returns){
        this.props.onGetInvoice(id, true).then(result => {
            //console.log("result: ",result)
            if (result.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo',
                    text: "Hóa đơn bán hàng đã hủy, không thể trả hàng"
                }).then(() => {
                        this.props.history.push("/invoice/" + id)
                })
            } else if (result.receipt_bill_status === InvoiceActionType.STATUS_REORDER_INVOICE){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo',
                    text: "Hóa đơn bán hàng đang trạng thái Re-Order, không thể trả hàng"
                }).then(() => {
                    this.props.history.push("/invoice/" + id)
                })
            }
            let products = result.products
            if(products.length > 0){
                let has_product_return = false
                for(let i = 0; i < products.length; i++){
                    if((products[i].quantity - products[i].quantity_return) > 0){
                        has_product_return = true
                        break
                    }
                }
                if (has_product_return === false){  
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        text: "Hàng hóa trong hóa đơn bán hàng đã được trả hết"
                    }).then(() => {
                        this.props.history.push("/invoice/" + id)
                    })
                    return false
                }
                let has_discount_receipt = false
                if (product_returns !== undefined && product_returns !== null && product_returns.length > 0){
                    for(let i = 0; i < products.length; i++){
                        //let found = false
                        product_returns.forEach(item => {
                            if(products[i].id === item.product_id && products[i].product_option_id === item.product_option_id){
                                products[i].quantity_return_selected = item.quantity
                               // found = true
                            }
                        })
                        /*if (found === false){
                            products.splice(i, 1);
                        }*/
                    }
                    product_returns = []
                    let total_amount = result.total_amount
                    let total_quantity_origin = 0
                    //console.log("result.discount_money: ",result.discount_money)
                    //console.log("result.discount: ",result.discount)
                    
                    let discount_amount = result.discount_money
                    if (result.discount > 0){
                        discount_amount = total_amount * result.discount / 100
                    }
                    //console.log("total_amount: ",total_amount)
                    //console.log("discount_amount: ",discount_amount)
                    let discount_receipt = 0
                    if(discount_amount > 0){
                        let total_discount_origin = discount_amount
                        result.products.forEach(itemSelected => {
                            total_quantity_origin += itemSelected.quantity
                        })
                        if(total_quantity_origin > 0 && discount_amount > 0){
                            discount_receipt = total_discount_origin / total_quantity_origin
                        }
                        total_quantity_origin = 0
                    }
                    if (discount_receipt > 0){
                        has_discount_receipt = true
                    }
                    //console.log("discount_receipt: ",discount_receipt)
                    products.forEach(itemSelected => {
                        let key = itemSelected.id + '_' + itemSelected.product_option_id
                        let price = itemSelected.price
                        //console.log("price: ",price)
                        let discount_amount = itemSelected.discount_money
                        if (itemSelected.discount > 0){
                            discount_amount = price * itemSelected.discount / 100   
                        }
                        //console.log("discount_amount in item: ",discount_amount)
                        //price = price - discount_amount - discount_receipt
                        price = price - discount_amount
                        //console.log("price after: ",price)
                        let remainQuantity = itemSelected.quantity - itemSelected.quantity_return
                        let has_return = true
                        if (!(remainQuantity > 0)){
                            has_return = false
                        }
                        price = Math.round(price, -3)
                        //total_quantity_origin += remainQuantity
                        product_returns.unshift({
                            "key": key,
                            "id": itemSelected.id,
                            "product_option_id": itemSelected.product_option_id,
                            "product_option_name": itemSelected.product_option_name,
                            "url": itemSelected.product_url,
                            "name": itemSelected.product_name,
                            "model": itemSelected.product_model,
                            "sku": itemSelected.product_sku,
                            "has_return": has_return,
                            "price": price, 
                            "retail": itemSelected.retail,
                            "quantity": remainQuantity,
                            "quantity_return_selected": itemSelected.quantity_return_selected,
                            "total_amount_return": itemSelected.quantity_return_selected * price,
                            "categories": itemSelected.categories,
                            "category_id": itemSelected.category_id,
                            "category_name": itemSelected.category_name,
                            "brand_id": itemSelected.brand_id,
                            "brand_name": itemSelected.brand_name
                        })  
                    })
                    //console.log("product_returns: ",product_returns)
                    this.props.onFieldChange("product_returns", product_returns)
                    this.props.onFieldChange("has_discount_receipt", has_discount_receipt)
                    this.props.onSumTotalReturn();  
                }
            } 
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onNumberFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
        this.props.onSumTotal();  
    }
    onNumberReturnFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
        this.props.onSumTotalReturn();  
    }
    onQuantityReturnItemFieldChange = (key,value) => {
        this.props.onUpdateProductReturnItem("quantity_return_selected", key, value);
    }
    onDiscountFieldChange = (input_name, value, discount_mode) => {
        this.props.onFieldChange(input_name,value);
        this.props.onFieldChange("discount_mode",discount_mode);
        this.props.onSumTotal();  
    }
    onPaymentFieldChange = (value,payment_text) => {
        //console.log("value: ",value);
        //console.log("payment_text: ",payment_text);
        if (value !== null){
            let pay_amount = 0
            if (value.length > 0){
                value.forEach(function(item){
                    if(item.amount > 0){
                        pay_amount += parseFloat(item.amount)
                    }
                });
            }
            //console.log("pay_amount: ",pay_amount);
            this.props.onFieldChange("payment_method_info",value);
            this.props.onFieldChange("payment_method_text",payment_text);
            this.props.onFieldChange("payment_amount",pay_amount);
            this.props.onSumTotal();  
        }
    }
    onRemovePayment = () => {
        this.props.onFieldChange("payment_method_info",[]);
        this.props.onFieldChange("payment_method_text","");
        this.props.onFieldChange("payment_amount",0);
        this.props.onSumTotal();  
    }
    onSelectReceiptReturn = (value, item) => {
        if (item !== null){     
            this.props.onFieldChange("receipt_return_selected",item)
            if(item.receipt_bill_id !== undefined && item.receipt_bill_id > 0){
                this.props.onEmptyInfo()
                this.onInitData(item.receipt_bill_id, item.products)
                this.props.history.push("/invoice/return?id=" + item.receipt_bill_id)
            }
        }
    }
    onSearchReceiptReturn(keyword, _){
        if(timeoutSearchReceiptReturn !== null){
            clearTimeout(timeoutSearchReceiptReturn)
        }
        if (!keyword) {
          return;
        }
        let _this = this
        timeoutSearchReceiptReturn = setTimeout(() => {
            var data_search = {
                "keyword": keyword,
                "has_product": 1
            }
            _this.props.onSearchReceiptReturns(data_search);
        }, 700);
    }
    onSelectProduct = itemSelected => {
        //console.log(itemSelected)
        if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            this.props.onSelectProductToList(itemSelected)
        }
    }
    onRemoveProduct = key => {
        let products = this.props.invoice.products;
        let index = Helper.findIndexByKey(products, key);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onClearProduct = () => {
        this.props.onClearProduct();
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("quantity", key, value);
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price", key, values.value);
    }
    onDiscountItemFieldChange(key, value, discount_mode){
        this.props.onUpdateProductItem("discount_mode", key, discount_mode);
        this.props.onUpdateProductItem("discount", key, value);
    }
    onProductItemClick(id){
        this.props.history.push("/product/" + id);
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let price_after = item.price * item.quantity
                 let discount_amount = item.discount_money
                 let discount_text = <NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} />
                 if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                    discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} /></span>
                 }
                 price_after = price_after - discount_amount
                 let total_amount = price_after
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onProductItemClick(item.id) }}>
                         <td className="text-center"><img src={item.product_url} alt="" /></td>
                         <td>{ item.product_model }</td>
                         <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         { this.props.invoice.is_reorder === 1 ? <td className="text-right"><NumberFormat value={item.quantity_in_stock} displayType={'text'} thousandSeparator={true} /></td> : <td></td> }
                         { this.props.invoice.is_reorder === 1 ? <td className="text-right"><NumberFormat value={item.quantity_other_branch} displayType={'text'} thousandSeparator={true} /></td> : <td></td> }
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">{ discount_text }</td>
                         <td className="text-right"><NumberFormat value={price_after} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    showListProductReturn(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                //console.log("item: ",item)
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let total_amount = item.price * item.quantity_return_selected
                 let key = item.id + '_' + item.product_option_id
                 return (
                     <tr key={ index }>
                         <td className="text-center"><img src={item.url} alt="" /></td>
                         <td>{ item.model }</td>
                         <td>{gift_text}{ item.name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td>
                             { item.quantity > 0 ?
                            <div className="nk-int-st nk-toggled">
                                <InputNumber className="text-right" value={item.quantity_return_selected} max={item.quantity} min={0} onChange={(value) => { this.onQuantityReturnItemFieldChange(key,value) }}/>
                            </div> : "" }
                         </td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onSubmit(){
        let {id, product_returns, products, customer_selected, receipt_bill_code, receipt_bill_notes, total_amount, discount, discount_mode, payment_amount, payment_method_info, has_shipping, has_cod, transporter_selected, transporter_bill_number, receiver_name, receiver_phone, receiver_address, receiver_city_selected, receiver_district_selected, receiver_ward_selected, receiver_pay_fee, return_fee, total_amount_return, branch_id} = this.props.invoice
        receiver_pay_fee = parseInt(receiver_pay_fee)
        if(product_returns.length === 0){
            Alert.error('Không có thông tin hàng hóa cần trả hàng')
            return;
        }
        let valid = false
        for(let i = 0; i < product_returns.length; i++){
            let product_item = product_returns[i]
            if(product_item.quantity_return_selected > 0){
                valid = true
                break
            }
        }
        if(valid === false){
            Alert.error('Vui lòng nhập số lượng hàng hóa muốn trả')
            return;
        }
        let products_return = []
        product_returns.forEach(itemSelected => {
            if (itemSelected.quantity_return_selected > 0){
                products_return.push({
                    "product_id": itemSelected.id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_name": itemSelected.name,
                    "product_model": itemSelected.model,
                    "product_sku": itemSelected.sku,
                    "retail": itemSelected.retail,
                    "price": itemSelected.price,
                    "quantity": itemSelected.quantity_return_selected,
                    "branch_id": branch_id,
                })      
            }   
        })
        if(products_return.length === 0){
            Alert.error('Không có thông tin hàng hóa cần trả hàng')
            return;
        }
        if (this.props.invoice.has_discount_receipt === true && product_returns.length !== products_return.length){
            Swal.fire({
                title: 'Xác nhận',
                text: 'Hóa đơn bán hàng có giảm trên hóa đơn, cần phải nhập trả toàn bộ hàng hóa',
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,  
                reverseButtons: true,
                confirmButtonText: 'ĐỒNG Ý',
                cancelButtonText: 'KHÔNG',
                preConfirm: () => {
                    for(let i = 0; i < product_returns.length; i++){
                        product_returns[i].quantity_return_selected = product_returns[i].quantity
                        product_returns[i].total_amount_return = product_returns[i].quantity * product_returns[i].price 
                    }
                    this.props.onFieldChange("product_returns", product_returns)
                    this.props.onSumTotalReturn();  
                },
            })
            return
        }
        let branch_selected = Helper.getBranchSelected()       
        let ship_fee = parseFloat(this.props.invoice.ship_fee)
        let other_fee = parseFloat(this.props.invoice.other_fee)
        let ship_fee_shop_paid_return = parseFloat(this.props.invoice.ship_fee_shop_paid_return)
        let receiver_city = 0, receiver_district = 0, receiver_ward = 0, transporter_id = 0
        let transporter_name = ""
        if (products.length > 0){
            let valid_new_buy = true
            let product_invalid = ""
            for(let j = 0; j < products.length; j++){
                let product_item = products[j]
                if(product_item.remain === 0){
                    valid_new_buy = false
                    product_invalid = product_item.name
                    break
                }
            }
            if (valid_new_buy === false){
                Alert.error('Hàng hóa '+product_invalid+' không đủ tồn để thực hiện đổi trả')
                return;
            }
            if (has_shipping === true) {
                let has_shipping_error = false
                let error_msg = "", field_error = ""
                if (receiver_name === ""){
                    error_msg = 'Vui lòng nhập tên người nhận'
                    field_error = "receiver_name_error"
                    has_shipping_error = true
                } else if (receiver_phone === ""){
                    error_msg = 'Vui lòng nhập số điện thoại người nhận'
                    field_error = "receiver_phone_error"
                    has_shipping_error = true
                } else if (receiver_address === ""){
                    error_msg = 'Vui lòng nhập địa chỉ người nhận'
                    field_error = "receiver_address_error"
                    has_shipping_error = true
                } else if (receiver_city_selected.value === undefined){
                    error_msg = 'Vui lòng chọn tỉnh/thành phố'
                    has_shipping_error = true
                } else if (receiver_district_selected.value === undefined){
                    error_msg = 'Vui lòng chọn quận/huyện'
                    has_shipping_error = true
                } else if (receiver_ward_selected.value === undefined){
                    error_msg = 'Vui lòng chọn phường/xã'
                    has_shipping_error = true
                } else if (transporter_selected.value === undefined){
                    error_msg = 'Vui lòng chọn đối tác giao hàng'
                    has_shipping_error = true
                }
                if(has_shipping_error === true){
                    this.invoiceShippingRef.onShippingModeClick(true)
                    Alert.error(error_msg)
                    let _this = this
                    setTimeout(function(){
                        if(field_error !== ""){
                            _this.props.onErrorField(field_error,true)
                        }
                    },250)
                    return;
                }
                receiver_city = parseInt(receiver_city_selected.value)
                receiver_district = parseInt(receiver_district_selected.value)
                receiver_ward = parseInt(receiver_ward_selected.value)
                transporter_id = parseInt(transporter_selected.value)
                transporter_name = transporter_selected.label
            } else if (ship_fee > 0){
                this.invoiceShippingRef.onShippingModeChange(true)
                Alert.error('Vui lòng nhập thông tin giao hàng')
                return;
            }
            if (ship_fee > 0 && receiver_pay_fee === 0){
                Alert.error('Vui lòng chọn người trả phí vận chuyển')
                return;
            }
        }
        return_fee = parseFloat(return_fee)
        let data_post = {
           return_bill_notes: receipt_bill_notes,
           receipt_bill_id: id,
           receipt_bill_code,
           customer_id: customer_selected.id,
           customer_code: customer_selected.code,
           customer_name: customer_selected.name,
           customer_phone: customer_selected.phone,
           branch_id: branch_selected.id,
           branch_name: branch_selected.name,
           return_fee,
           products: products_return,    
        }
        let final_total_amount_return = total_amount_return
        if(return_fee > 0){
            final_total_amount_return = final_total_amount_return - return_fee
        }
        let data_post_create_receipt_bill = {}
        if(products.length > 0){
            let products_array = []
            products.forEach(item => {
                let discount_percent = 0, discount_money = 0
                if(item.discount > 0){
                    if(item.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                        discount_percent = item.discount
                    } else {
                        discount_money = item.discount
                    }
                }
                products_array.push({
                    "id": item.id,
                    "product_option_id": item.product_option_id,
                    "product_name": item.name,
                    "product_sku": item.sku,
                    "product_model": item.model,
                    "categories": item.categories,
                    "category_id": parseInt(item.category_id),
                    "category_name": item.category_name,
                    "brand_id": parseInt(item.brand_id),
                    "brand_name": item.brand_name,
                    "price": parseFloat(item.price),
                    "quantity": parseInt(item.quantity),
                    "discount": parseFloat(discount_percent),
                    "discount_money": parseFloat(discount_money),
                    "is_gift": parseInt(item.is_gift)
                })
            })
            let discount_money = discount
            if (discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                discount_money = 0
            } else{
                discount = 0
            }
            let payment_bills = []
            if (payment_method_info.length > 0){
                payment_method_info.forEach(paymentItem => {
                    payment_bills.push(paymentItem)
                })
            }
            let money_collect_cod = 0, money_collect_cod_accounting = 0
            let liabilities_amount = 0, final_total_amount = 0, return_other_fee = 0
            let discount_value = discount_money
            if (total_amount > 0){
                if(discount > 0){
                    discount_value = total_amount * discount / 100
                }
                final_total_amount = total_amount - discount_value
                if(ship_fee > 0){
                    if(+receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                        final_total_amount += parseFloat(ship_fee)
                    }
                }
                if(other_fee > 0){
                    final_total_amount += other_fee
                }
                let final_total_amount_save = final_total_amount
                if(final_total_amount_return > 0){
                    final_total_amount = final_total_amount - final_total_amount_return
                }
                if(final_total_amount > 0){
                    payment_bills.push({
                        "finance_source_id": 0,
                        "amount": final_total_amount_return,
                        "is_cash": 0,
                        "is_cod": 0,
                        "is_return_bill": 1
                    })
                } else {
                    return_other_fee = final_total_amount * (-1)
                    payment_bills.push({
                        "finance_source_id": 0,
                        "amount": final_total_amount_save,
                        "is_cash": 0,
                        "is_cod": 0,
                        "is_return_bill": 1
                    })
                    final_total_amount = 0
                }
                liabilities_amount = final_total_amount - payment_amount
                if (liabilities_amount < 0){
                    liabilities_amount = 0
                    has_cod = false
                }
                if (has_cod === true){
                    money_collect_cod = liabilities_amount
                    money_collect_cod_accounting = liabilities_amount
                    if (money_collect_cod_accounting > 0){
                        payment_bills.push({
                            "finance_source_id": 0,
                            "amount": money_collect_cod_accounting,
                            "is_cash": 0,
                            "is_cod": 1
                        })
                    }
                } else if (liabilities_amount > 0){
                    Alert.error('Vui lòng thanh toán đầy đủ tổng tiền trên hóa đơn')
                    return;
                }
            }
            //console.log("has_cod: ",has_cod)
            //console.log("money_collect_cod: ",money_collect_cod)
            let pick_up_address_id = 0
            let pick_up_address = ""
            if (this.props.invoice.pick_up_address_id !== undefined && this.props.invoice.pick_up_address_id.value !== undefined){
                pick_up_address_id = this.props.invoice.pick_up_address_id.value
                pick_up_address = this.props.invoice.pick_up_address_id.label
            }
            let sell_channel = 0
            if (this.props.invoice.sell_channel_selected !== undefined && this.props.invoice.sell_channel_selected.value !== undefined){
                sell_channel = this.props.invoice.sell_channel_selected.value
            }
            console.log("ship_fee_shop_paid_return: ", ship_fee_shop_paid_return)
            data_post_create_receipt_bill = {
                return_bill_id: 0,
                return_bill_code: "",
                return_receipt_bill_id: id,
                return_receipt_bill_code: receipt_bill_code,
                branch_id: branch_selected.id,
                branch_name: branch_selected.name,
                receipt_bill_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                customer_id: customer_selected.id,
                customer_code: customer_selected.code,
                customer_name: customer_selected.name,
                customer_phone: customer_selected.phone,
                customer_address: customer_selected.address,
                customer_city: parseInt(customer_selected.city),
                customer_district: parseInt(customer_selected.district),
                customer_ward: parseInt(customer_selected.ward),
                discount: parseFloat(discount),
                discount_money: parseFloat(discount_money),
                money_collect_cod,
                ship_fee,
                other_fee,
                return_other_fee,
                ship_fee_shop_paid_return,
                sell_channel,
                products: products_array,
                payment_bills: payment_bills,
                shipping_info: {
                    transporter_id,
                    transporter_name,
                    transporter_bill_number: transporter_bill_number,
                    receiver_name,
                    receiver_phone,
                    receiver_address,
                    receiver_city,
                    receiver_district,
                    receiver_ward,
                    weight: parseFloat(this.props.invoice.weight),
                    weight_kg: parseFloat(this.props.invoice.weight_kg),
                    length: parseFloat(this.props.invoice.length),
                    width: parseFloat(this.props.invoice.width),
                    height: parseFloat(this.props.invoice.height),
                    pick_up_address_id,
                    pick_up_address,
                    delivery_type: 0,
                    delivery_check_type: 0,
                    receiver_pay_fee
                }
            }
        }
        //console.log("data_post: ",data_post)
        //console.log("data_post_create_receipt_bill: ",data_post_create_receipt_bill)
        this.props.onSubmitReturn(data_post).then(result => {
            if(result.status === true){ 
                if(products.length > 0 && result.info !== undefined){
                    this.props.onSubmit(data_post_create_receipt_bill).then(result_create_invoice => {
                        if(result_create_invoice.id > 0){
                            this.props.history.push("/invoice/" + id)
                            window.open("/invoice/" + result_create_invoice.id, "_blank")
                        } else if(result_create_invoice.status === false && result_create_invoice.message !== undefined && result_create_invoice.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else{
                    this.props.history.push("/invoice/" + id)
                }
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    render(){
        let { receipt_bill_code, products, discount, discount_mode, total_quantity, receiver_pay_fee, ship_fee, other_fee, total_amount, payment_amount, payment_method_text, receipt_bill_notes, receipt_bill_notes_active, product_returns, total_quantity_origin, total_amount_origin, total_quantity_return, total_amount_return, return_fee, save_button_loading, receiver_name_error, receiver_phone_error, receiver_address_error, has_cod, receipt_return_selected  } = this.props.invoice;
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }  
        let settings = Helper.getSettings()
        let classHasLiabilities = "form-group ic-cmp-int float-lb floating-lb display-none"
        if(settings !== null && settings.has_liabilities === true) {
            classHasLiabilities = "form-group ic-cmp-int float-lb floating-lb"
        }
        let final_total_amount_return = total_amount_return
        let quantity_origin_text_display = "", quantity_return_text_display = ""
        if(total_quantity_origin > 0){
            quantity_origin_text_display = (<span>&nbsp;({total_quantity_origin})</span>)
        }
        if(total_quantity_return > 0){
            quantity_return_text_display = (<span>&nbsp;({total_quantity_return})</span>)
        }
        let return_fee_text = ""
        if(return_fee > 0){
            return_fee_text = Helper.parseMoneyText(return_fee)
            final_total_amount_return = final_total_amount_return - return_fee
        }

        receiver_pay_fee = parseInt(receiver_pay_fee)
        let quantity_text_display = ""
        if(total_quantity > 0){
            quantity_text_display = (<span>&nbsp;({total_quantity})</span>)
        }
        let liabilities_amount = 0, final_total_amount = 0, return_amount = 0, final_total_amount_real_pay = 0
        let discount_text_display = ""
        let discount_value = discount
        if (total_amount > 0){
            if(discount_mode === 2 && discount > 0){
                discount_text_display = (<span>&nbsp;({discount}%)</span>)
                discount_value = total_amount * discount / 100
            }
            final_total_amount = total_amount - discount_value
        }
        let ship_fee_text = "", other_fee_text = "", ship_fee_text_display = ""
        if(other_fee > 0){
            other_fee_text = Helper.parseMoneyText(other_fee)
            final_total_amount += parseFloat(other_fee)
        }

        if(ship_fee > 0){
            ship_fee_text = Helper.parseMoneyText(ship_fee)
            if(+receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                final_total_amount += parseFloat(ship_fee)
                final_total_amount_real_pay += parseFloat(ship_fee)
                ship_fee_text_display = <small><br />Khách trả</small>
            } else if(+receiver_pay_fee === InvoiceActionType.SENDER_PAY_SHIP_FEE){
                final_total_amount_real_pay -= parseFloat(ship_fee)
                ship_fee_text_display = <small><br />Moby trả</small>
            }
        }
        
        if(final_total_amount_return > 0){
            final_total_amount = final_total_amount - final_total_amount_return
        }
        let cod_amount = 0, cod_amount_real = 0
        if (final_total_amount > 0){
            final_total_amount_real_pay = final_total_amount
            liabilities_amount = final_total_amount - payment_amount
            cod_amount_real = liabilities_amount
            if (has_cod === true){
                cod_amount = liabilities_amount
            }
            if (liabilities_amount < 0){
                return_amount = liabilities_amount * (-1)
                liabilities_amount = 0
            }
        } else {
            liabilities_amount = 0
            final_total_amount_real_pay = 0
            final_total_amount = 0
        }
        let payment_text_display = "", payment_remove = ""
        if(payment_method_text !== ""){
            payment_remove = <span>&nbsp;<button type="button" onClick={() => { this.onRemovePayment() }}><i className="fa fa-remove"></i></button></span>
            payment_text_display = (<small><br />({payment_method_text})</small>)
        }
        let notes_field_class = "nk-int-st"
        if (receipt_bill_notes !== "") {
            receipt_bill_notes_active = true;
        }
        if(receipt_bill_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let receipt_returns = this.props.receipt_good.list
        let receipt_return_array = [], receipt_return_value_selected = 0
        if(receipt_returns.length > 0){
            receipt_returns.forEach(receiptReturnItem => {
                receipt_return_array.push({
                    label: receiptReturnItem.receipt_code + " - " + receiptReturnItem.receipt_bill_code + " - " + receiptReturnItem.customer_name + " - " + Helper.convertMysqlDateTime(Helper.replaceMysqlDateTime(receiptReturnItem.receipt_date)),
                    value: receiptReturnItem.id,
                    ...receiptReturnItem            
                })
            })
            if(receipt_return_selected !== null && receipt_return_selected.id !== undefined && receipt_return_selected.id > 0){
                receipt_return_value_selected = receipt_return_selected.id
            }
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Trả hàng cho hóa đơn bán hàng { receipt_bill_code }</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/invoice/return/" + this.props.invoice.id} />
                </Helmet>   
                <div className="row"> 
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="normal-table-list">
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-condensed cl_list_product">
                                    <colgroup>
                                        <col width="5%"></col>
                                        <col width="15%"></col>
                                        <col></col>
                                        <col width="7%"></col>
                                        <col width="7%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Mã hàng</th>
                                            <th>Tên hàng</th>
                                            <th className="text-right">SL</th>
                                            <th className="text-right">SL trả</th>
                                            <th className="text-right">Giá bán</th>
                                            <th className="text-right">Thành tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListProductReturn(product_returns) }
                                    </tbody>
                                </table>
                            </div>
                        </div>                             
                        <div className="mr-top-20">
                            <SearchProduct onSelect={ this.onSelectProduct.bind(this) } onClearProduct={ this.onClearProduct.bind(this) } import_file={false} get_size={true} placeholder="Tìm hàng đổi"/>
                            <div className="normal-table-list cl_list_product">
                                <div>
                                    <Table
                                        virtualized
                                        height={tableHeight}
                                        rowHeight={55}
                                        data={products} >
                                        <Column width={50} align="center" fixed>
                                            <HeaderCell>STT</HeaderCell>
                                            <Cell>
                                                {(rowData,rowIndex) => {
                                                    return (
                                                    <span>{rowIndex + 1}</span>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={50}>
                                            <HeaderCell>&nbsp;</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    let imgTag = ""
                                                    if(rowData.url !== ""){
                                                        imgTag = <img src={rowData.url} alt="" />
                                                    }
                                                    return imgTag;
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={150}>
                                            <HeaderCell>Mã hàng</HeaderCell>
                                            <Cell dataKey="model" />
                                        </Column>
                                        <Column width={300}>
                                            <HeaderCell>Tên hàng</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    let nameText = rowData.name 
                                                    if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                        nameText = (
                                                            <span>
                                                            { rowData.name  }<br />
                                                            { rowData.product_option_name }
                                                            </span>
                                                        )
                                                    }
                                                    return nameText
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={100}>
                                            <HeaderCell>ĐVT</HeaderCell>
                                            <Cell dataKey="unit" />
                                        </Column>
                                        <Column width={100} align="right">
                                            <HeaderCell>Số lượng</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    return (
                                                        <div className="nk-int-st nk-toggled">
                                                            <InputNumber className="text-right" value={rowData.quantity} max={100} min={1} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                        </div>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={120} align="right">
                                            <HeaderCell>Đơn giá</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    return (
                                                        <div className="nk-int-st nk-toggled">
                                                            <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={rowData.price} onValueChange={(values) => this.onPriceItemFieldChange(rowData.key,values)} />
                                                        </div>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={120} align="right">
                                            <HeaderCell>Giảm giá</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    return (
                                                        <DiscountInput discount_input="discount_item" discount_value={rowData.discount_value} fieldChange={(input_name,value,discount_mode) => {this.onDiscountItemFieldChange(rowData.key,value,discount_mode)}} />
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={120} align="right">
                                            <HeaderCell>Thành tiền</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={rowData.total_amount} />
                                                    </div>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={50} fixed="right" align="center">
                                            <HeaderCell>&nbsp;</HeaderCell>
                                            <Cell>
                                                {rowData => {
                                                    return (
                                                        <button type="button" onClick={() => { this.onRemoveProduct(rowData.key) }}><i className="fa fa-remove"></i></button>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list">
                            <div className="form-group">
                                <SelectPicker
                                    block
                                    placeholder="Chọn phiếu nhận hàng khách trả"
                                    cleanable={false}
                                    value={receipt_return_value_selected}
                                    data={receipt_return_array}
                                    onSearch={this.onSearchReceiptReturn.bind(this)}
                                    onSelect={this.onSelectReceiptReturn.bind(this)}
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <h5>Trả hàng <Link to={"/invoice/" + this.props.invoice.id}>{ receipt_bill_code }</Link> </h5>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng hàng mua{quantity_origin_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount_origin} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                Tổng hàng trả{quantity_return_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount_return} />
                                </div>
                                <div className="cl"></div>
                            </div>  
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Phí trả hàng
                                </div>
                                <div className="pull-right">
                                    <div className="nk-int-st nk-toggled">
                                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.return_fee_input = el} thousandSeparator={true} prefix={""} value={return_fee_text} onValueChange={(values) => this.onNumberReturnFieldChange("return_fee",values)} />
                                    </div>
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng tiền trả
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={final_total_amount_return} />
                                </div>
                                <div className="cl"></div>
                            </div>
                        </div>
                        { products.length > 0  ? 
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <h5>Mua hàng</h5>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <InvoiceShipping cod_amount={cod_amount_real} receiver_name_error={receiver_name_error} receiver_phone_error={receiver_phone_error} receiver_address_error={receiver_address_error} getRef={ref => (this.invoiceShippingRef = ref)} />
                                    </div>
                                    <div className="col-xs-6">
                                        <SellChannelSelectBox appearance="subtle" onFieldChange={(value) => this.props.onFieldChange("sell_channel_selected",value) } />
                                    </div>  
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tạm tính{quantity_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Giảm giá{discount_text_display}
                                </div>
                                <div className="pull-right">
                                    <DiscountInput discount_input="discount" discount_value={discount_value} fieldChange={(input_name,value,discount_mode) => {this.onDiscountFieldChange(input_name,value,discount_mode)}} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Phí vận chuyển{ship_fee_text_display}
                                </div>
                                <div className="pull-right">
                                    <div className="nk-int-st nk-toggled">
                                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.ship_fee_input = el} thousandSeparator={true} prefix={""} value={ship_fee_text} onValueChange={(values) => this.onNumberFieldChange("ship_fee",values)} />
                                    </div>
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Thu khác
                                </div>
                                <div className="pull-right">
                                    <div className="nk-int-st nk-toggled">
                                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.other_fee_input = el} thousandSeparator={true} prefix={""} value={other_fee_text} onValueChange={(values) => this.onNumberFieldChange("other_fee",values)} />
                                    </div>
                                </div>
                                <div className="cl"></div>
                            </div>
                            {final_total_amount  > 0 ?
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng tiền{quantity_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={final_total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div> : "" }
                            { final_total_amount > 0 && final_total_amount_real_pay !== final_total_amount ? <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Khách cần trả
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={final_total_amount_real_pay} />
                                </div>
                                <div className="cl"></div>
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Khách thanh toán{payment_remove}{payment_text_display}
                                </div>
                                <div className="pull-right">
                                    <PaymentInput amount={final_total_amount_real_pay} payment_amount={payment_amount} onChange={(value,payment_text) => { this.onPaymentFieldChange(value,payment_text) }} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            { cod_amount > 0 ? 
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Thu hộ (COD)
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={cod_amount} />
                                </div>
                                <div className="cl"></div>
                            </div> : "" }
                            <div className={classHasLiabilities}>
                                <div className="pull-left">
                                    Tính vào công nợ
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={liabilities_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tiền trả hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={return_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                        </div> : "" }
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="receipt_bill_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={receipt_bill_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;TRẢ HÀNG
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.invoice,
        receipt_good: state.receipt_good
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(InvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(InvoiceAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(InvoiceAction.emptyInfo())
        },
        onGetInvoice: (id, is_return) => {
            return dispatch(InvoiceAction.getRequest(id, is_return))
        },
        onUpdateProducts(products){
            dispatch(InvoiceAction.updateProductsRequest(products))
        },
        onSelectProductToList(item){
            dispatch(InvoiceAction.selectProductToListRequest(item))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(InvoiceAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(InvoiceAction.clearProductRequest())
        },
        onUpdateProductReturnItem(input_name, key, value){
            dispatch(InvoiceAction.updateProductReturnItemRequest(input_name, key, value))
        },
        onSumTotal(){
            dispatch(InvoiceAction.sumTotal())
        },
        onSumTotalReturn(){
            dispatch(InvoiceAction.sumTotalForReturn())
        },
        onSubmitReturn(data_post){
            return dispatch(InvoiceAction.submitReturn(data_post))
        },
        onSubmit(data_post){
            return dispatch(InvoiceAction.addRequest(data_post))
        },
        onSearchReceiptReturns: data_search => {
            return dispatch(ReceiptGoodAction.searchRequest(data_search, 1, 10, false))
        },
        onGetReceiptGood: id => {
            return dispatch(ReceiptGoodAction.getRequest(id))
        }
    }
}
const ReturnInvoiceConnect = (connect(mapStateToProps, mapDispatchToProps)(ReturnInvoice));
export default withRouter(ReturnInvoiceConnect);