import { combineReducers } from 'redux';
import uploader from './UploaderReducer';
import login from './LoginReducer';
import store from './StoreReducer';
import purchase_order from './PurchaseOrderReducer';
import invoice from './InvoiceReducer';
import modal from './ModalReducer';
import product from './ProductReducer';
import product_expiries from './ProductExpiriesReducer';
import user from './UserReducer';
import inventory from './InventoryReducer';
import zone from './ZoneReducer';
import message from './MessageReducer';
import report from './ReportReducer';
import payment_invoice from './PaymentInvoiceReducer';
import category from './CategoryReducer';
import finance_source from './FinanceSourceReducer';
import vendor_bill from './VendorBillReducer';
import inventory_check from './InventoryCheckReducer';
import transfer_store from './TransferStoreReducer';
import return_invoice from './ReturnInvoiceReducer';
import receipt_good from './ReceiptGoodReducer';
import receipt_of_good from './ReceiptOfGoodReducer';
import supplier_vendor_bill from './SupplierVendorBillReducer';
import address_book from './AddressBookReducer';
import bank_transactions from './BankTransactionReducer';
import bill_lading from './BillLadingReducer';
import activities from './ActivitiesReducer';

const app_reducers = combineReducers({
    uploader,
    login,
    store,
    purchase_order,
    modal,
    product,
    user,
    address_book,
    inventory,
    invoice,
    zone,
    message,
    report,
    payment_invoice,
    category,
    finance_source,
    vendor_bill,
    inventory_check,
    transfer_store,
    return_invoice,
    receipt_good,
    receipt_of_good,
    supplier_vendor_bill,
    bank_transactions,
    bill_lading,
    activities,
    product_expiries
});

export default app_reducers;