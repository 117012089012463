import * as BankTransactionActionType from '../constants/BankTransactionActionType';
var initialState = {
    id: 0,
    debit_account: "",
    credit_account: "",
    search_date: [],
    transaction_type: 0,
    list: [],
    page: 1,
    total_amount: 0,
    total: 0,
    total_page: 0,
    limit: 10,
    search_code: "",
    search_button_loading: false
};
const bank_transactions = (state = initialState, action) => {
    switch(action.type){
        case BankTransactionActionType.BUTTON_SPINNER_BANK_TRANSACTION:
            return {
                ...state,
                save_button_loading: action.status
            };
        case BankTransactionActionType.FIELD_CHANGE_BANK_TRANSACTION:
              return {
                ...state,
                [action.field]: action.value
              };
        case BankTransactionActionType.FIELDS_CHANGE_BANK_TRANSACTION:
            return {
                ...state,
                ...action.fields
            };
        case BankTransactionActionType.RESET_FIELD_SEARCH_BANK_TRANSACTION:
            return {
                ...state,
                debit_account: "",
                credit_account: "",
                search_date: [],
                transaction_type: 0,
                search_code: "",
            };
        case BankTransactionActionType.LIST_BANK_TRANSACTION:
            return {
                ...state,
                list: action.list,
                total_amount: action.total_amount,
                page: action.page,
                limit: action.limit,
                total: action.total,
                total_page: action.total_page
            };
        default: 
            return state;
    }
}
export default bank_transactions;