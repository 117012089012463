import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom";
import Moment from 'react-moment';
import { Alert } from 'rsuite';
import ZoneSelectBox from '../shared/ZoneSelectBox';
import Helper from '../../utils/Helper';
import * as UserAction from '../../actions/UserAction';
import * as UserActionType from '../../constants/UserActionType';
import LaddaButton, {ZOOM_IN} from 'react-ladda';

class ViewTransporter extends Component {
    constructor() {
        super();
        this.state = { pictures: [], tab: "info", edit: false, edit_success: false};
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            //console.log("match.params.id: ", match.params.id)
            id = match.params.id;
        }
        if (id > 0) {
            //console.log("componentDidMount id: ", id)
            this.props.onGetCustomer(id)
        }
    }

    componentDidUpdate(){
        if (this.birthdayInput !== undefined && this.birthdayInput !== null){
            this.birthdayInput.addEventListener('focus', this.onFieldFocus)
            this.birthdayInput.addEventListener('blur', this.onFieldBlur)
        }
    }

    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error= target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
            this.props.onFocusField(field_error,false); 
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDateFieldChange = (values) => {
        //console.log(values)
        if(values !== null){
            this.props.onFieldChange("birthday",values.formattedValue);
        }
    }
    onCityChange(city){
        this.props.onFieldChange("city_selected",city);
    }
    onDistrictChange(district){
        this.props.onFieldChange("district_selected",district);
    }
    onWardChange(ward){
        this.props.onFieldChange("ward_selected",ward);
    }   
    onChangeTab = (tab_selected) => {
        this.setState({
            tab: tab_selected
        })
    }
    onSetEditCustomerInfo = (action) => {
        let _this = this
        _this.setState({
            edit_customer: action
        })
    }
    onSaveCustomerInfo(){
        let {id, name, phone, birthday, address, city_selected, district_selected, ward_selected} = this.props.user;
        if (birthday !== ""){
            birthday = Helper.convertToMysqlDate(birthday)
        }
        if (name === ""){
            this.props.onErrorField("name_error", true)
            Alert.error("Vui lòng nhập họ tên")
            this.name_input.focus()
            return
        }
        if (phone === ""){
            this.props.onErrorField("phone_error", true)
            Alert.error("Vui lòng nhập điện thoại")
            this.phone_input.focus()
            return
        }
        if (address === ""){
            this.props.onErrorField("address_error", true)
            Alert.error("Vui lòng nhập địa chỉ")
            this.address_input.focus()
            return
        }
        if (city_selected.value  === undefined){
            Alert.error("Vui lòng chọn tỉnh/thành phố")
            return
        }
        if (district_selected.value === undefined){
            Alert.error("Vui lòng chọn quận/huyện")
            return
        }
        let data_post = {
            name: name,
            phone: phone,
            address: address,
            city: parseInt(city_selected.value),
            district: parseInt(district_selected.value),
            ward: parseInt(ward_selected.value),
            user_type: UserActionType.TRANSPORTER_USER_TYPE
        }
        //console.log(data_post);
        this.props.onSubmit(id, data_post).then(result => {
            if (result === true){
                let _this = this
                _this.props.onGetCustomer(id)
                _this.onSetEditCustomerInfo(false)
                _this.setState({
                    edit_success: true
                })
                setTimeout(() => {
                    _this.setState({
                        edit_success: false
                    })
                },4000)
            }
        })
    }
    onPrint() {
        let id = 'receipt'
        const iframe = document.frames
        ? document.frames[id]
        : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();
        return false;
    }
    showPrintButton(){
        this.setState({
            show_print_button: true
        })
    }
    render(){
        let {city_name, district_name, ward_name, name, name_active, name_error, phone_active, phone_error, address_active, address_error, save_button_loading } = this.props.user
        let zone_text = "", zone_array  = []
        if(ward_name !== ""){
            zone_array.push(ward_name)
        }
        if(district_name !== ""){
            zone_array.push(district_name)
        }
        if(city_name !== ""){
            zone_array.push(city_name)
        }
        if (zone_array.length > 0){
            zone_text = zone_array.join(", ")
        }
        let tab_action_button = ""
        if(this.state.tab === "info"){
            if(this.state.edit_customer === true){
                tab_action_button = (
                    <div>
                        <button type="button" className="btn btn-default" onClick={() => this.onSetEditCustomerInfo(false)}><i className="fa fa-ban"></i>&nbsp;Bỏ qua</button>&nbsp;
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-success pull-right notika-btn-success waves-effect" onClick={ () => { this.onSaveCustomerInfo() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div>
                )
            } else {
                tab_action_button = (
                    <div>
                        { this.state.show_print_button === true ?
                        <button type="button" className="btn btn-info mr-right-10" onClick={() => this.onPrint()}><i className="fa fa-print"></i>&nbsp;In thông tin</button>: null }
                        <button type="button" className="btn btn-default" onClick={() => this.onSetEditCustomerInfo(true)}><i className="fa fa-edit"></i>&nbsp;Sửa thông tin</button>
                    </div>
                )
            }
        }

        let name_field_class = "nk-int-st", phone_field_class = "nk-int-st",  address_field_class = "nk-int-st"
        if (this.props.user.name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }
        if (this.props.user.phone !== "") {
            phone_active = true;
        }
        if(phone_active === true){
            phone_field_class += ' nk-toggled';
        }
        if(phone_error === true){
            phone_field_class += ' error';
        }
        if (this.props.user.address !== "") {
            address_active = true;
        }
        if(address_active === true){
            address_field_class += ' nk-toggled';
        }
        if(address_error === true){
            address_field_class += ' error';
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <NavLink to="/customers"><i className="fa fa-arrow-left fa-2x text-black"></i></NavLink>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false" onClick={() => this.onChangeTab('info')}>Thông tin</a>   
                            </li>
                            <li className="pull-right">
                                { tab_action_button }
                                <iframe
                                    id="receipt"
                                    src={"/transporter/print/" + this.props.user.id}
                                    style={{ display: 'none' }}
                                    title="Receipt"
                                    onLoad={() => { this.showPrintButton() }}
                                />
                            </li>
                        </ul>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white padd-10">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="photo">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="row">
                                                { this.state.edit_customer_success === true ?
                                                <div className="col-xs-12">
                                                    <div className="alert alert-success">
                                                        Thông tin nhà vận chuyển đã lưu thành công
                                                    </div>
                                                </div>
                                                : "" }
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã nhà vận chuyển:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{ this.props.user.code }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Tên:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ name_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.name }</strong> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Điện thoại:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            { this.state.edit_customer === true ? 
                                                                <div className={ phone_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="phone" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.phone_input = input; }} value={this.props.user.phone}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.phone }</strong> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Địa chỉ:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ address_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="address" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.address_input = input; }} value={this.props.user.address}></input>
                                                                </div>
                                                                : this.props.user.address }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Khu vực:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                    <ZoneSelectBox appearance="subtle" city={this.props.user.city_selected} district={this.props.user.district_selected} ward={this.props.user.ward_selected} onCityChange={(city) => { this.onCityChange(city) }} onDistrictChange={(district) => { this.onDistrictChange(district) }} onWardChange={(ward) => { this.onWardChange(ward) }} getRef={ref => (this.zoneSelectBoxRef = ref)} />
                                                                : zone_text }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { this.state.edit_customer === false ? 
                                                    <div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">Người tạo:</label>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                    { this.props.user.staff_name }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">Ngày tạo:</label>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                    <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.user.time }</Moment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : "" }
                                                </div>                                        
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        address_book: state.address_book,
        invoice: state.invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetCustomer: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
        onErrorField(field,value){
            dispatch(UserAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(UserAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(UserAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(UserAction.resetField())
        },
        onSubmit(id, data_post){
            return dispatch(UserAction.updateRequest(id, data_post))
        },
    }
}
const ViewTransporterConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewTransporter));
export default withRouter(ViewTransporterConnect);