import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import moment from 'moment';
import * as BankTransactionAction from '../../actions/BankTransactionAction';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
import { SelectPicker } from 'rsuite';
class ListBankTransactions extends Component {
    constructor() {
        super();
        this.state = {
            type: 0,
        }
    }
    componentDidMount(){
        let _this = this
        let page = Helper.getQueryString('page');
        if(page === null){
            page = this.props.bank_transactions.page
        }
        _this.bindDataSearch()
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            if (data_search.search !== undefined && data_search.search === true){
                _this.props.onSearch(data_search,page,_this.props.bank_transactions.limit, true);
            } else {
                var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
                var to_date = moment().endOf('month').format('YYYY-MM-DD');
                var search_date = [];
                if(from_date !== ""){
                    search_date.push(from_date)
                }
                if(to_date !== ""){
                    search_date.push(to_date)
                }  
                _this.props.onFieldChange("search_date",search_date);
                _this.props.onSearch({
                    "from_date": search_date[0],
                    "to_date": search_date[1],
                },page,_this.props.bank_transactions.limit, false);
            }
        },250)
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let from_date = Helper.getQueryString('from_date');
        let to_date = Helper.getQueryString('to_date');
        let debit_account = Helper.getQueryString('debit_account');
        let credit_account = Helper.getQueryString('credit_account');
        let transaction_type = parseInt(Helper.getQueryString('transaction_type'));
        var search_date = [];
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_date.push(from_date)
        }
        if(to_date !== undefined && to_date !== null && to_date !== ""){
            search_date.push(to_date)
        }
        if (search_code === null){
            search_code = ""
        }
        if (debit_account === null){
            debit_account = ""
        }
        if (credit_account === null){
            credit_account = ""
        }
        if (!(transaction_type > 0)){
            transaction_type = 0   
        }
        this.props.onFieldsChange({
            search_code, debit_account, search_date, credit_account, transaction_type
        })
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onSelectTransactionType(value){
        this.props.onFieldChange("transaction_type",value);
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _code = this.props.bank_transactions.search_code;
        let _debit_account = this.props.bank_transactions.debit_account;
        let _credit_account = this.props.bank_transactions.credit_account;
        let _date = this.props.bank_transactions.search_date;
        let _transaction_type = parseInt(this.props.bank_transactions.transaction_type);
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        var data_search = {
            "search": false,
            "param": [],
        }
        var is_search = false
        if (_code !== ""){
            data_search["code"] = _code;
            data_search["param"].push("code=" + _code)
            this.props.onFieldsChange({
                debit_account: "", credit_account: "", transaction_type: 0
            })
            is_search = true
        } else {
            if (_date[0] !== undefined){
                data_search["from_date"] = _date[0];
                data_search["param"].push("from_date=" + data_search["from_date"])
                is_search = true
            }
            if (_date[1] !== undefined){
                data_search["to_date"] = _date[1];
                data_search["param"].push("to_date=" + data_search["to_date"])
                is_search = true
            }
            if(_debit_account !== null && _debit_account !== ""){
                data_search["debit_account"] = _debit_account;
                data_search["param"].push("debit_account=" + data_search["debit_account"])
                is_search = true
            }
            if(_credit_account !== null && _credit_account !== ""){
                data_search["credit_account"] = _credit_account;
                data_search["param"].push("credit_account=" + data_search["credit_account"])
                is_search = true
            }
            if(_transaction_type > 0){
                data_search["transaction_type"] = _transaction_type;
                data_search["param"].push("transaction_type=" + data_search["transaction_type"])
                is_search = true
            }
        }
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search.search !== undefined && data_search.search === true){
            if(data_search.param.length > 0){
                let param = data_search.param.join("&")
                if(param !== ""){
                    param = "?" + param
                }
                this.props.history.push("/accounting/bank-transactions" + param)
            }
            this.props.onSearch(data_search,1,this.props.bank_transactions.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        let param = "?page=" + pageNumber
        if(data_search.param.length > 0){
            let param_search = data_search.param.join("&")
            if(param_search !== ""){
                param += "&" + param_search
            }
        }
        this.props.history.push("/accounting/bank-transactions" + param)
        this.props.onFieldChange("page", pageNumber)
        this.props.onSearch(data_search,pageNumber,this.props.bank_transactions.limit, false);
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let debit_account_name_text = "", debit_bank_name_text = ""
                if(item.debit_account_name !== ""){
                    debit_account_name_text = <span><br />{item.debit_account_name}</span>
                }
                if(item.debit_bank_name !== ""){
                    debit_bank_name_text = <span><br />{item.debit_bank_name}</span>
                }
                let beneficiary_name_text = "", beneficiary_bank_name_text = ""
                if(item.beneficiary_name !== ""){
                    beneficiary_name_text = <span><br />{item.beneficiary_name}</span>
                }
                if(item.beneficiary_bank_name !== ""){
                    beneficiary_bank_name_text = <span><br />{item.beneficiary_bank_name}</span>
                }
                return (
                    <tr key={ index }>
                        <td>{ item.transaction_code }</td>
                        <td>{ item.credit_account }{beneficiary_name_text}{beneficiary_bank_name_text}</td>
                        <td>{ item.debit_account }{debit_account_name_text}{debit_bank_name_text}</td>
                        <td className="text-right">{item.amount}</td>
                        <td>{item.transaction_time}</td>
                        <td>{item.details}</td>
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { total_amount, total, limit, total_page, page, list, search_code, debit_account, credit_account, transaction_type, search_button_loading } = this.props.bank_transactions;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter has_default={true} value={this.props.bank_transactions.search_date} placeholder="Chọn ngày giao dịch" onFieldChange = { (value) => { this.onDatePickerChange(value) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã giao dịch" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="debit_account" value={ debit_account } className="form-control" autoComplete="off" placeholder="Nhập tài khoản ghi nợ" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="credit_account" value={ credit_account } className="form-control" autoComplete="off" placeholder="Nhập tài khoản ghi có" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <SelectPicker
                                    style={{color: "#FFFFFF"}}
                                    block
                                    searchable={false}
                                    cleanable={false}
                                    placeholder="Chọn loại giao dịch"
                                    data={[
                                        {
                                            label: "",
                                            value: 0
                                        },
                                        {
                                            label: "Thu",
                                            value: 1
                                        },
                                        {
                                            label: "Chi",
                                            value: 2
                                        }
                                    ]}
                                    value={transaction_type}
                                    onSelect={(value) => { this.onSelectTransactionType(value) }}
                                    renderMenu={menu => {
                                        return menu;
                                    }}
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        Các giao dịch
                                    </h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="15%"></col>
                                        <col width="15%"></col>
                                        <col width="10%"></col>
                                        <col width="15%"></col>
                                        <col></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã giao dịch</th>
                                            <th>TK ghi có</th>
                                            <th>TK ghi nợ</th>
                                            <th className="text-right">Số tiền</th>
                                            <th>TG giao dịch</th>
                                            <th>Nội dung</th>
                                        </tr>
                                        <tr className="bg-warning">
                                            <th colSpan="3"></th>
                                            <th className="text-right">{total_amount}</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        bank_transactions: state.bank_transactions
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(BankTransactionAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(BankTransactionAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(BankTransactionAction.resetFieldSearch())
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(BankTransactionAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(ListBankTransactions)