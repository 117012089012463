import * as ProductActionType from '../constants/ProductActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    product_option_id: 0,
    product_option_name: "",
    model: "",
    sku: "",
    price: 0,
    retail_average: 0,
    retail: 0,
    brand_id: 0,
    brand_name: "",
    category_id: 0,
    category_name: "",
    categories: [],
    photos: [],
    current_photo: [],
    options: [],
    histories: [],
    name: "",
    description: "",
    content: "",
    h1_seo: "",
    title_seo: "",
    description_seo: "",
    keyword_seo: "",
    slug: "",
    quantity: "",
    store_id: 0,
    store_name: "",
    store_code: "",
    retail_according: 0,
    is_gift: 0,
    total: 0,
    total_page: 0,
    total_remain: 0,
    total_retail: 0,
    limit: 10,
    page: 1,
    list: [],
    search_categories: [],
    search_category_selected: [],
    search_brands: [],
    search_brand_selected: [],
    search_model: "",
    search_name: "",
    search_gift: 0,
    search_button_loading: false,
    is_search: false,
    direct_to_list: false,
    model_active: false,
    model_error: false,
    name_active: false,
    name_error: false,
    sku_active: false,
    quantity_active: false,
    quantity_error: false
};
const product = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case ProductActionType.ITEM_BUTTON_SPINNER_PRODUCTS:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case ProductActionType.BUTTON_SPINNER_PRODUCTS:
            return {
                ...state,
                save_button_loading: action.status
            };
        case ProductActionType.FOCUS_FIELD_PRODUCTS:
              return {
                ...state,
                [action.field]: action.value
              };
        case ProductActionType.ERROR_FIELD_PRODUCTS:
              return {
                ...state,
                [action.field]: action.value
              };
        case ProductActionType.FIELD_CHANGE_PRODUCTS:
              return {
                ...state,
                [action.field]: action.value
              };
        case ProductActionType.RESET_FIELD_SEARCH_PRODUCTS:      
            return {
              ...state,
              search_category_selected: [],
              search_brand_selected: [],
              search_model: "",
              search_name: "",
              search_gift: 0,
              is_search: false,
            };
        case ProductActionType.RESET_FIELD_PRODUCTS:
            return {
              ...state,
                id: 0,
                product_option_id: 0,
                product_option_name: "",
                model: "",
                sku: "",
                price: 0,
                retail_average: 0,
                retail: 0,
                brand_id: 0,
                brand_name: "",
                category_id: 0,
                category_name: "",
                categories: [],
                name: "",
                description: "",
                content: "",
                h1_seo: "",
                title_seo: "",
                description_Seo: "",
                keyword_seo: "",
                slug: "",
                retail_according: 0,
                is_gift: 0,
                quantity: "",
                model_active: false,
                model_error: false,
                name_active: false,
                name_error: false,
                sku_active: false,
                quantity_active: false,
                quantity_error: false
            };
        case ProductActionType.GET_PRODUCTS:
            let categories = []
            action.products.categories.forEach(function(category_item){
              categories.push({
                label: category_item.name,
                value: parseInt(category_item.id)
              });
            });
            let options = []
            action.products.options.forEach(function(option_item){
              options.push({
                label: option_item.name,
                value: parseInt(option_item.id)
              });
            });
            let current_photo = []
            if (action.products.photos.length > 0){
              current_photo = action.products.photos[0]
            }
            return {
                ...state,
                id: action.products.id,
                product_option_id: action.products.product_option_id,
                product_option_name: action.products.product_option_name,
                model: action.products.model,
                sku: action.products.sku,
                price: action.products.price,
                retail: action.products.retail,
                brand_id: action.products.brand_id,
                brand_name: action.products.brand_name,
                category_id: action.products.category_id,
                category_name: action.products.category_name,
                categories: categories,
                photos: action.products.photos,
                options: options,
                current_photo: current_photo,
                name: action.products.name,
                description: action.products.description,
                description_seo: action.products.description_seo,
                content: action.products.content,
                h1_seo: action.products.h1_seo,
                title_seo: action.products.title_seo,
                keyword_seo: action.products.keyword_seo,
                slug: action.products.slug,
                retail_according: parseInt(action.products.retail_according),
                is_gift: parseInt(action.products.is_gift),
            };
        case ProductActionType.REMOVE_PHOTO_PRODUCTS:
          if (state.photos.length > 0 && state.id === action.id){
            state.photos = state.photos.filter((el) => action.photo_id !== el.photo_id)
          }
          return {
            ...state,
          };
        case ProductActionType.VIEW_PHOTO_THUMBNAIL_PRODUCTS:
          return {
            ...state,
            current_photo: action.photo
          };
        case ProductActionType.LIST_PRODUCTS:
            return {
                ...state,
                total: action.total,
                total_remain: action.total_remain,
                total_retail: Helper.parseMoney(action.total_retail),
                limit: action.limit,
                total_page: action.total_page,
                page: action.page,
                list: [...action.list]
              };
        case ProductActionType.SEARCH_PRODUCTS:
            return {
                ...state,
                search_button_loading: action.search_button_loading,
                is_search: true
            };
        case ProductActionType.CLEAR_SEARCH_PRODUCTS:
          return {
            ...state,
            list: []
          };
        case ProductActionType.LIST_CATEGORIES_PRODUCTS:
            return {
                ...state,
                search_categories: action.categories
              };
        case ProductActionType.LIST_BRANDS_PRODUCTS:
          return {
              ...state,
              search_brands: action.brands
            };
        case ProductActionType.GET_HISTORIES_PRODUCTS:
          return {
              ...state,
              histories: action.histories
            };
        case ProductActionType.DIRECT_TO_LIST_PRODUCTS:
          return {
            ...state,
            direct_to_list: action.status
          };       
        default: 
            return state;
    }
}
export default product;