import * as ReportActionType from '../constants/ReportActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: ReportActionType.FOCUS_FIELD_REPORT,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: ReportActionType.ERROR_FIELD_REPORT,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: ReportActionType.FIELD_CHANGE_REPORT,
        field,
        value
    }
}

export const fieldChanges = (fields) => {
    return {
        type: ReportActionType.FIELDS_CHANGE_REPORT,
        ...fields,
    }
}

export const resetField = () => {
    return {
        type: ReportActionType.RESET_FIELD_REPORT
    }
}

export const resetFieldSearch = () => {
    return {
        type: ReportActionType.RESET_FIELD_SEARCH_REPORT
    }
}

export const setLoading = status => {
    return {
        type: ReportActionType.SET_LOADING_REPORT,
        status
    }
}

export const reportRequest = (interest_type, data_post, has_dispatch = true) => {
    return (dispatch) => {
        let url = ""
        if(interest_type === ReportActionType.INTEREST_TYPE_PROFIT){
            url = "reports/profit"
        } else if(interest_type === ReportActionType.INTEREST_TYPE_PRODUCT_PROFIT){
            url = "reports/product-profit"
        } else if(interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT_DETAILS){
            url = "reports/import-export-details"
        } else if(interest_type === ReportActionType.INTEREST_TYPE_IMPORT_EXPORT){
            url = "reports/import-export"
        } else if(interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CAC){
            url = "reports/customer-acquisition-cost"
        } else if(interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_AOV){
            url = "reports/average-oder-value"
        } else if(interest_type === ReportActionType.INTEREST_TYPE_OVERVIEW_BUSINESS_CRR){
            url = "reports/customer-retention-rate"
        }
        return apiCaller.callApi(url,{
            ...data_post
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                var result = null
                if (res.data.result !== undefined && res.data.result !== null){
                    result = res.data.result
                }
                dispatch(setLoading(false));
                if(has_dispatch === true){
                    dispatch(report(result));
                } else if(result !== null){
                    return {
                        status: true,
                        list: result
                    }
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}
const report = list => {
    return {
        type: ReportActionType.REPORT_INFO,
        list
    }
}


export const clearReportRequest = () => {
    return {
        type: ReportActionType.CLEAR_REPORT
    }
}

export const reportByChartRequest = () => {

}

export const revenueReportRequest = (data_post) => {
    return (dispatch) => {
        return apiCaller.callApi(`reports/revenue`,{
            ...data_post
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                var list = []
                if (res.data.result !== undefined && res.data.result !== null){
                    list = res.data.result
                }
                dispatch(revenueReport(list));
            }
        });
    }
}
const revenueReport = infos => {
    return {
        type: ReportActionType.REVENUE_REPORT,
        infos
    }
}

export const getDailyRevenueRequest = (data_post) => {
    return (dispatch) => {
        return apiCaller.callApi(`reports/daily-revenue`,{
            ...data_post
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                var list = []
                if (res.data.result !== undefined && res.data.result !== null){
                    list = res.data.result
                }
                dispatch(getDailyRevenue(list));
            }
        });
    }
}
const getDailyRevenue = infos => {
    return {
        type: ReportActionType.DAILY_REVENUE,
        infos
    }
}

export const topProductReportRequest = (data_post) => {
    return (dispatch) => {
        let _url = `reports/top-product-sellest`
        if (data_post.mode_view === 'quantity'){
            _url = `reports/top-product-quantity-sellest`
        }
        return apiCaller.callApi(_url,{
            from_date: data_post.from_date,
            to_date: data_post.to_date,
            branch_id: data_post.branch_id,
            branch_name: data_post.branch_name,
            limit: data_post.limit
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                var list = []
                if (res.data.result !== undefined && res.data.result !== null){
                    list = res.data.result
                }
                dispatch(topProductReport(list));
            }
        });
    }
}
const topProductReport = infos => {
    return {
        type: ReportActionType.TOP_PRODUCT_REPORT,
        infos
    }
}

export const saveReportTemplateRequest = (data_post) => {
    return () => {
        return apiCaller.callApi("reports/save-template",{
            ...data_post
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                if (res.data.result !== undefined && res.data.result !== null){
                    return {
                        status: true,
                        result: res.data.result
                    }
                }
                return {
                    status: false,
                    message: (res.data.message !== undefined && res.data.message !== null)?res.data.message:""
                }
            }
        });
    }
}

export const listReportTemplatesRequest = (report_type, interest_type) => {
    return () => {
        return apiCaller.callApi("reports/templates",{
            report_type,
            interest_type
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (res.data.result !== undefined && res.data.result !== null){
                    return {
                        status: true,
                        result: res.data.result
                    }
                }
                return {
                    status: false,
                    message: (res.data.message !== undefined && res.data.message !== null)?res.data.message:""
                }
            }
        });
    }
}