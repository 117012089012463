import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import * as PaymentInvoiceAction from '../../actions/PaymentInvoiceAction';
import * as PaymentInvoiceActionType from '../../constants/PaymentInvoiceActionType';
import * as UserAction from '../../actions/UserAction';
import * as UserActionType from '../../constants/UserActionType';
import * as CategoryAction from '../../actions/CategoryAction';
import * as CategoryActionType from '../../constants/CategoryActionType';
import { Alert, CheckTreePicker, DatePicker, Icon, Popover, SelectPicker, Whisper } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Helper from '../../utils/Helper';
import CreateAccountingTerm from './CreateTerm';
import Moment from 'react-moment';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Swal from 'sweetalert2';
let timeoutSearchObject = null
let timeoutSearchCategories = null
class PaymentAmount extends Component {
    constructor() {
        super();
        this.state = {
            finance_sources: []
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.finance_sources !== prevState.finance_sources){
          return { finance_sources: nextProps.finance_sources};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.finance_sources !== this.props.finance_sources){
            this.setState({finance_sources: this.props.finance_sources});
        } 
    } 
    componentDidMount(){
        this.setState({finance_sources: this.props.finance_sources});
    }
    onAmountFieldChange(finance_source_id,values){
        let finance_sources = []
        if(this.state.finance_sources.length > 0){
            for(let i = 0; i < this.state.finance_sources.length; i++){
                let amount = this.state.finance_sources[i].amount
                if(this.state.finance_sources[i].value === finance_source_id){
                    amount = values.value
                }
                finance_sources.push({
                    ...this.state.finance_sources[i],
                    amount
                })
            }
        }
        //this.setState({finance_sources})
        this.props.onChange(finance_sources);
    }
    render(){
        let finance_sources = this.state.finance_sources
        let total_amount = 0
        let paymentAmountInput = null
        if(finance_sources.length === 1){
            let finance_source_item = finance_sources[0]
            paymentAmountInput = <div className="nk-int-st nk-toggled">
                <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={finance_source_item.amount} onValueChange={(values) => this.onAmountFieldChange(finance_source_item.value,values)} />
            </div> 
        }
        const speaker = (
            <Popover title="Nhập số tiền thanh toán">
                { 
                finance_sources.length ? 
                    finance_sources.map((item, index) => {
                        if(item.amount !== undefined && item.amount !== ""){
                            total_amount += parseFloat(item.amount)
                        }
                        return (
                            <div key={index} className="form-group">
                                <div className="nk-int-mk mr-bot-5">
                                    <h2>{item.label}</h2>
                                </div>
                                <div className="nk-int-st nk-toggled">
                                    <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={item.amount} onValueChange={(values) => this.onAmountFieldChange(item.value,values)} />
                                </div>
                            </div>
                        )
                    }) : null 
                }
            </Popover>
        );
        return(
            <div>
            { finance_sources.length > 1 ? 
            <Whisper
                placement="leftStart"
                trigger="click"
                speaker={speaker}
                >
                    <div className="nk-int-st nk-toggled">
                        <NumberFormat className="form-control text-right cl_handover" readOnly={true} thousandSeparator={true} prefix={""} value={total_amount} />
                    </div>
            </Whisper> : 
                    paymentAmountInput
            } </div>
        );
    }
}
class AccountingCreatePayment extends Component {
    constructor(props){
        super(props)
        this.onCleanObject = this.onCleanObject.bind(this);
        this.onSearchObject = this.onSearchObject.bind(this);
        this.onSelectObject = this.onSelectObject.bind(this);
        this.onCleanCategories = this.onCleanCategories.bind(this);
        this.onSearchCategories = this.onSearchCategories.bind(this);
        this.onSelectCategories = this.onSelectCategories.bind(this);
        this.onFinanceSourceChange = this.onFinanceSourceChange.bind(this);
        this.receipt_id = 0
    }
    componentDidMount(){
        this.props.onEmptyInfo()
        let params = new URLSearchParams(this.props.location.search);
        let object_id = parseInt(params.get('object_id'));
        if(isNaN(object_id)){
            object_id = 0
        }
        let receipt_id = parseInt(params.get('receipt_id'));
        if(isNaN(receipt_id)){
            receipt_id = 0
        }
        this.receipt_id = receipt_id
        if (object_id > 0) {
            this.onGetObject(object_id)
        }
    }
    onGetObject(object_id){
        if (object_id > 0){
            this.props.onGetObject(object_id).then(result => {
                if (result.id !== undefined && result.id > 0){
                    let is_supplier = (result.user_type === UserActionType.SUPPLIER_USER_TYPE) ? true: false
                    this.onSelectObject(null, {
                        label: result.name + " - " + result.phone,
                        value: result.id,
                        is_supplier,
                        ...result
                    });
                    if (this.receipt_id > 0){
                        if (is_supplier === true){
                            this.props.onGetImportReceipt(this.receipt_id).then(item => {
                                if (item.id !== undefined && item.id > 0){
                                    let amount = item.total_liabilities
                                    item.import_receipt_date = Helper.replaceMysqlDateTime(item.import_receipt_date)
                                    this.onSelectReceipt(null, {
                                        label: item.import_receipt_code + " - " + Helper.convertMysqlDateTime(item.import_receipt_date) + " - " + Helper.parseMoneyText(amount.toString()),
                                        value: item.id,
                                        id: item.id,
                                        date: item.import_receipt_date,
                                        amount,
                                        payed_amount: item.paid_liabilities,
                                        code: item.import_receipt_code,
                                    });
                                }
                            })
                        } else {
                            this.props.onGetVendorBill(this.receipt_id)
                        }
                    }
                }
            })
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })  
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("payment_bill_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onSearchObject(keyword, event){
        if(timeoutSearchObject !== null){
            clearTimeout(timeoutSearchObject)
        }
        if (!keyword) {
            return;
        }
        timeoutSearchObject = setTimeout(() => {
            var data_search = {
                "user_types": UserActionType.STAFF_USER_TYPE + "," + UserActionType.CUSTOMER_USER_TYPE  + "," + UserActionType.SUPPLIER_USER_TYPE + "," + UserActionType.TRANSPORTER_USER_TYPE + "," + UserActionType.PAYMENT_OBJECT_USER_TYPE,
                "keyword": keyword,
            }
            this.props.onSearchObject(data_search, 1, this.props.user.limit, false);
        }, 250);
    }
    onSelectObject(_,item){
        let value = {}
        if(item !== null){
            value = item
        }
        this.props.onFieldChange("object_selected", value);
        this.props.onFieldChange("total_liabilities_amount", 0)
        this.props.onFieldChange("total_paid_amount", 0)
        this.props.onFieldChange("receipts_selected", [])
        if (value.id > 0){
            this.props.onGetReceiptForPayment(value.id, value.is_supplier)
        }
    }
    onCleanObject = () => {
        this.props.onFieldChange("object_selected", {});
    }
    onSelectReceipt = (_,item) => {
        let receipts_selected = this.props.payment_invoice.receipts_selected
        if (item !== null){
            let add_to_list = true
            if (receipts_selected.length > 0){
                for(let i = 0; i < receipts_selected.length; i++){
                    if (receipts_selected[i].id === item.id){
                        add_to_list = false
                        break
                    }
                }
            }
            if (add_to_list === true){
                let finance_sources = []
                if(this.props.payment_invoice.finance_sources_selected.length > 0){
                    this.props.payment_invoice.finance_sources_selected.forEach(financeSourceItem => {
                        finance_sources.push({
                            label: financeSourceItem.label,
                            value: financeSourceItem.value,
                            amount: ""
                        })
                    })
                }
                receipts_selected.push({
                    label: item.code + " - " + Helper.convertMysqlDateTime(item.date) + " - " + Helper.parseMoneyText(item.amount.toString()),
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    amount: item.amount,
                    payed_amount: item.payed_amount,
                    date: item.date,
                    finance_sources
                })
            }
            let total_liabilities_amount = 0, total_paid_amount = 0
            if(receipts_selected.length > 0){
                receipts_selected.forEach(item => {
                    total_liabilities_amount += item.amount
                    total_paid_amount += item.payed_amount
                })
            }
            this.props.onFieldChange("total_liabilities_amount", total_liabilities_amount)
            this.props.onFieldChange("total_paid_amount", total_paid_amount)
            this.props.onFieldChange("receipts_selected", receipts_selected)
        }
    }
    onSearchCategories(keyword, event){
        if(timeoutSearchCategories !== null){
            clearTimeout(timeoutSearchCategories)
        }
        if (!keyword) {
            return;
        }
        timeoutSearchCategories = setTimeout(() => {
            this.props.onSearchCategories({
                "category_type": CategoryActionType.ACCOUNTING_TERMS_CATEGORY_TYPE,
                "keyword": keyword,
            }, 1, 20, false)
        }, 250);
    }
    bindFinanceSourceToListReceipt(finance_sources){
        if (finance_sources !== undefined && finance_sources !== null && finance_sources.length === 1){
            let receipts_selected = [], final_values = []
            if(this.props.payment_invoice.receipts_selected.length > 0){
                for(let i = 0; i < this.props.payment_invoice.receipts_selected.length; i++){
                    let _item = this.props.payment_invoice.receipts_selected[i]
                    receipts_selected.push({
                        ..._item,
                        finance_sources: []
                    })
                }
                let finance_source_item = finance_sources[0]
                let finance_source_name = "", finance_source_key = ""
                if (finance_source_item.is_cash === 1){
                    finance_source_name = "Tiền mặt"
                    finance_source_key = "cash"
                } else if (finance_source_item.finance_source_id > 0){
                    finance_source_name = finance_source_item.finance_source_name
                    finance_source_key = finance_source_item.finance_source_id
                }
                final_values.push({
                    label: finance_source_name,
                    value: finance_source_key,
                })
                for(let j = 0; j < receipts_selected.length; j++){
                    receipts_selected[j].finance_sources.push({
                        label: finance_source_name,
                        value: finance_source_key,
                        amount: ""
                    })
                } 
                this.props.onFieldChange("receipts_selected", receipts_selected)
                this.props.onFieldChange("finance_sources_selected", final_values)
            }
        } else{
            let receipts_selected = []
            if(this.props.payment_invoice.receipts_selected.length > 0){
                for(let k = 0; k < this.props.payment_invoice.receipts_selected.length; k++){
                    let _item = this.props.payment_invoice.receipts_selected[k]
                    receipts_selected.push({
                        ..._item,
                        finance_sources: []
                    })
                }
            }
            this.props.onFieldChange("receipts_selected", receipts_selected)
            this.props.onFieldChange("finance_sources_selected", [])
        }
    }
    onSelectCategories(_,item){
        let value = {}
        if(item !== null){
            value = item
        }
        this.bindFinanceSourceToListReceipt(value.finance_sources)
        this.props.onFieldChange("category_selected", value);   
    }
    onCleanCategories = () => {
        this.props.onFieldChange("category_selected", {});
    }
    onCategoryChange(result) {
        if (result !== null){
            this.bindFinanceSourceToListReceipt(result.finance_sources)
            this.props.onFieldChange("category_selected", {
                label: result.name,
                value: result.id,
                finance_sources: result.finance_sources
            });
        }
    }
    onFinanceSourceChange(values){
        let final_values = [], finance_sources_selected = []
        values.forEach(value => {
            if(value === "cash"){
                final_values.push(value)
            }
            else if (value === "bank" || value === "card"){
                this.state.lists.forEach(item => {
                    if(item.value === value && item.children.length > 0){
                        item.children.forEach(childItem => {
                            final_values.push(childItem.value)
                        })
                    }
                })
            } else{
                final_values.push(value)
            }
        })
        let receipts_selected = []
        if(this.props.payment_invoice.receipts_selected.length > 0){
            for(var i = 0; i < this.props.payment_invoice.receipts_selected.length; i++){
                var _item = this.props.payment_invoice.receipts_selected[i]
                receipts_selected.push({
                    ..._item,
                    finance_sources: []
                })
            }
        }
        if(final_values.length > 0){
            final_values.forEach(itemSelected => {
                if (this.props.payment_invoice.category_selected.finance_sources !== undefined && this.props.payment_invoice.category_selected.finance_sources !== null && this.props.payment_invoice.category_selected.finance_sources.length > 0){
                    this.props.payment_invoice.category_selected.finance_sources.forEach(finance_source_item => {
                        let finance_source_name = "", finance_source_key = ""
                        if (finance_source_item.is_cash === 1 && itemSelected === 'cash'){
                            finance_source_name = "Tiền mặt"
                            finance_source_key = "cash"
                        } else if (finance_source_item.finance_source_id === itemSelected){
                            finance_source_name = finance_source_item.finance_source_name
                            finance_source_key = finance_source_item.finance_source_id
                        }
                        if(finance_source_key !== ""){
                            finance_sources_selected.push({
                                label: finance_source_name,
                                value: finance_source_key,
                            })
                            for(var i = 0; i < receipts_selected.length; i++){
                                receipts_selected[i].finance_sources.push({
                                    label: finance_source_name,
                                    value: finance_source_key,
                                    amount: ""
                                })
                            }
                        }   
                    })   
                }
            })
        }
        this.props.onFieldChange("receipts_selected", receipts_selected)
        this.props.onFieldChange("finance_sources_selected", finance_sources_selected)
    }
    onAmountItemFieldChange(id, finance_sources){
        this.props.onUpdateAmountItem(id, finance_sources);
    }
    onRemoveReceipt = id => {
        let receipts_selected = this.props.payment_invoice.receipts_selected.filter(item => item.id !== id);
        this.props.onFieldChange("receipts_selected", receipts_selected)
    }
    showListBills(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                console.log(item.amount, item.payed_amount)
                let remain_amount = item.amount - item.payed_amount
                console.log(remain_amount)
                return (
                    <tr  key={ index }>
                        <td>{ item.code }</td>
                        <td>
                        <Moment format="DD/MM/YYYY HH:mm">
                        { Helper.replaceMysqlDateTime(item.date) }
                        </Moment>
                        </td>
                        <td className="text-right"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.payed_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={remain_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td>
                            { remain_amount > 0 ?
                            <PaymentAmount finance_sources={item.finance_sources} onChange={(finance_source_value) => { this.onAmountItemFieldChange(item.id, finance_source_value) }} />: null }
                        </td>
                        <td className="text-center">
                            <button type="button" onClick={() => { this.onRemoveReceipt(item.id) }}><i className="fa fa-remove"></i></button>
                        </td>
                    </tr>
                );   
            });
        }
        return result;
    }
    onSubmit = () => {
        let { payment_bill_date, payment_bill_notes, object_selected, category_selected, finance_sources_selected, receipts_selected } = this.props.payment_invoice
        if (!(object_selected.value !== undefined && object_selected.value > 0)){
            Alert.error('Vui lòng chọn đối tượng chi trả')
            return;
        }
        if (!(category_selected.value !== undefined && category_selected.value > 0)){
            Alert.error('Vui lòng chọn danh mục chi')
            return;
        }
        if (finance_sources_selected.length === 0){
            Alert.error('Vui lòng chọn nguồn chi trả')
            return;
        }
        let payment_bill_receipts = []
        let amount_valid = true, amount_valid_msg = ""
        for(var i = 0; i < receipts_selected.length; i++){
            let item = receipts_selected[i]
            let payment_bill_finance = []
            let amount_check = 0
            item.finance_sources.forEach(finance_source_item => {
                let is_cash = 0, finance_source_id = finance_source_item.value 
                let amount = parseFloat(finance_source_item.amount)
                if (finance_source_item.value === "cash"){
                    is_cash  = 1
                    finance_source_id = 0
                }
                payment_bill_finance.push({
                    "finance_source_id": finance_source_id,
                    "is_cash": is_cash,
                    "amount": amount
                })
                amount_check += amount
            })
            if(!(item.pay_amount > 0)){
                amount_valid_msg = 'Vui lòng nhập số tiền cần thanh toán cho ' + item.code
                amount_valid = false
                break
            }
            if (amount_check > (item.amount - item.payed_amount)){
                amount_valid_msg = item.code + ' đã nhập quá số tiền cần thanh toán: ' + Helper.parseMoneyText(amount_check)
                amount_valid = false
                break
            }
            if (object_selected.is_supplier === true){
                payment_bill_receipts.push({
                    "import_receipt_id": item.id,
                    "import_receipt_code": item.code,
                    "payment_bill_finance": payment_bill_finance
                })
            } else {
                payment_bill_receipts.push({
                    "vendor_bill_id": item.id,
                    "vendor_bill_code": item.code,
                    "vendor_bill_date": item.date,
                    "payment_bill_finance": payment_bill_finance
                })
            }
        }
        if (amount_valid === false){
            Alert.error(amount_valid_msg)
            return;
        }
        let payment_bill_type = PaymentInvoiceActionType.OWNER_PAYMENT_INVOICE
        let data_post = {
            payment_category_id: category_selected.value,
            payment_category_name: category_selected.label,
            partner_id: object_selected.id,
            partner_name: object_selected.name,
            partner_code: object_selected.code,
            payment_bill_type,
            payment_bill_date,
            payment_bill_notes,
            payment_bill_receipts
        }
        //console.log("data_post: ",data_post)
        this.props.onSubmit(data_post).then(result => {
            if (result.status !== undefined){
                if (result.status === true){
                    this.props.history.push("/accounting/payment-bills")
                } else if (result.message !== undefined){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            }
        })
    }
    render(){
        let { payment_bill_notes, payment_bill_notes_active, object_selected, category_selected, finance_sources_selected, receipts, receipts_selected, save_button_loading, total_amount, total_paid_amount, total_liabilities_amount } = this.props.payment_invoice
        let { list } = this.props.user
        let notes_field_class = "nk-int-st"
        if (payment_bill_notes !== "") {
            payment_bill_notes_active = true;
        }
        if(payment_bill_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let has_in_list = false
        let users_array = [], receipts_array = []
        let user_value_selected = 0
        let is_supplier = false
        if(object_selected.value !== undefined && object_selected.value !== null){
            user_value_selected = object_selected.value;
            if (object_selected.is_supplier !== undefined){
                is_supplier = object_selected.is_supplier
            }
        }
        if (list.length > 0) {
            list.forEach(function(item){
                users_array.push({
                    label: item.name + " - " + item.phone,
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    phone: item.phone,
                    address: item.address,
                    city: item.city,
                    district: item.district,
                    ward: item.ward,
                    is_supplier: (item.user_type === UserActionType.SUPPLIER_USER_TYPE) ? true: false
                });
                if (user_value_selected > 0 && user_value_selected === item.id){
                    has_in_list = true
                }
            });
        } else {
            let item = this.props.user
            if (item.id > 0){
                users_array.push({
                    label: item.name + " - " + item.phone,
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    phone: item.phone,
                    address: item.address,
                    city: item.city,
                    district: item.district,
                    ward: item.ward,
                    is_supplier: (item.user_type === UserActionType.SUPPLIER_USER_TYPE) ? true: false
                });
            }
        }
        if (has_in_list === false && object_selected.value !== undefined && object_selected.value !== null){
            users_array.push(object_selected); 
        }
        if (receipts.length > 0){
            receipts.forEach(function(item){
                receipts_array.push({
                    label: item.code + " - " + Helper.convertMysqlDateTime(item.date) + " - " + Helper.parseMoneyText(item.amount.toString()),
                    value: item.id,
                    id: item.id,
                    code: item.code,
                    amount: item.amount,
                    payed_amount: item.payed_amount,
                    date: item.date,
                });
            });
        }
        
        let payment_categories_array = [], finance_sources_array = []
        let category_value_selected = 0
        if(category_selected.value !== undefined && category_selected.value !== null){
            category_value_selected = category_selected.value;
        }
        let has_in_list_category = false
        if (this.props.category.list.length > 0){
            this.props.category.list.forEach(function(item){
                payment_categories_array.push({
                    label: item.name,
                    value: item.id,
                    finance_sources: item.finance_sources
                });
                if (category_value_selected > 0 && category_value_selected === item.id){
                    has_in_list_category = true
                    if (item.finance_sources !== null && item.finance_sources.length > 0){
                        item.finance_sources.forEach(finance_source_item => {
                            let finance_source_name = "", finance_source_key = ""
                            if (finance_source_item.is_cash === 1){
                                finance_source_name = "Tiền mặt"
                                finance_source_key = "cash"
                            } else if (finance_source_item.finance_source_id > 0){
                                finance_source_name = finance_source_item.finance_source_name
                                finance_source_key = finance_source_item.finance_source_id
                            }
                            finance_sources_array.push({
                                label: finance_source_name,
                                value: finance_source_key
                            })
                        })   
                    }
                }
            });
        }
        if (has_in_list_category === false && category_selected.value !== undefined && category_selected.value !== null){
            payment_categories_array.push(category_selected); 
            if (category_selected.finance_sources !== undefined && category_selected.finance_sources !== null && category_selected.finance_sources.length > 0){
                category_selected.finance_sources.forEach(finance_source_item => {
                    let finance_source_name = "", finance_source_key = ""
                    if (finance_source_item.is_cash === 1){
                        finance_source_name = "Tiền mặt"
                        finance_source_key = "cash"
                    } else if (finance_source_item.finance_source_id > 0){
                        finance_source_name = finance_source_item.finance_source_name
                        finance_source_key = finance_source_item.finance_source_id
                    }
                    finance_sources_array.push({
                        label: finance_source_name,
                        value: finance_source_key
                    })
                })   
            }
        }
        let finance_source_name_selected = ""
        if(finance_sources_array.length === 1) {
            finance_source_name_selected = finance_sources_array[0].label   
        }
        let finance_sources_value_selected = []
        if(finance_sources_selected.length > 0){
            finance_sources_selected.forEach(item => {
                finance_sources_value_selected.push(item.value)
            })
        }
       
        let object_payment_title = "Phiếu mua hàng"
        let date_title_text = "Ngày mua"
        if(is_supplier === true){
            object_payment_title = "Phiếu nhập hàng"
            date_title_text = "Ngày nhập"
        }
        let remain_liabilities_amount = total_liabilities_amount - total_paid_amount
        if(remain_liabilities_amount < 0){
            remain_liabilities_amount = 0
        }
        let total_remain_amount = 0
        if (total_amount > 0){
            total_remain_amount = remain_liabilities_amount - total_amount
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <h2>
                                Tạo phiếu chi
                                </h2>
                                <p></p>
                            </div>
                            <div className="bsc-tbl">
                                <div className="row">
                                    <div className="col-md-10 col-md-offset-1 col-xs-12">
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12">
                                                <div className="nk-int-mk mr-bot-5">
                                                    <h2>Ngày chi trả</h2>
                                                </div>
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <DatePicker format="YYYY-MM-DD HH:mm"  placeholder={moment().format("YYYY-MM-DD HH:mm")} placement="bottomStart" oneTap ranges={[]} block onChange = { (value) => { this.onDatePickerChange(value) } } />
                                                </div>
                                                <div className="nk-int-mk mr-bot-5">
                                                    <h2>Danh mục chi trả</h2>
                                                </div>
                                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    <div className="pull-left w-80">
                                                        <SelectPicker
                                                            block
                                                            placeholder="Tìm danh mục chi phí"
                                                            cleanable={true}
                                                            data={payment_categories_array}
                                                            value={category_value_selected}
                                                            onClean={this.onCleanCategories}
                                                            onSearch={this.onSearchCategories}
                                                            onSelect={this.onSelectCategories}
                                                            renderMenu={menu => {
                                                                if (payment_categories_array.length === 0) {
                                                                    return (
                                                                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                                        <Icon icon="spinner" spin />
                                                                    </p>
                                                                    );
                                                                }
                                                                return menu;
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="pull-left mr-left-5">
                                                        <CreateAccountingTerm onDone={(result) => { this.onCategoryChange(result) }} placement="auto" />
                                                    </div>
                                                    <div className="cl"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8 col-xs-12">
                                                <div className="row">
                                                    <div className="col-sm-6 col-xs-12">
                                                        <div className="nk-int-mk mr-bot-5">
                                                            <h2>Đối tượng chi trả</h2>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <SelectPicker
                                                                    block
                                                                    placeholder="Tìm đối tượng, nhà cung cấp"
                                                                    cleanable={true}
                                                                    data={users_array}
                                                                    value={user_value_selected}
                                                                    onClean={this.onCleanObject}
                                                                    onSearch={this.onSearchObject}
                                                                    onSelect={this.onSelectObject}
                                                                    renderMenu={menu => {
                                                                        if (users_array.length === 0) {
                                                                            return (
                                                                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                                                <Icon icon="spinner" spin />
                                                                            </p>
                                                                            );
                                                                        }
                                                                        return menu;
                                                                    }}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12">
                                                        <div className="nk-int-mk mr-bot-5">
                                                            <h2>Phiếu mua / nhập hàng</h2>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <SelectPicker
                                                                    block
                                                                    placeholder="Chọn phiếu"
                                                                    cleanable={false}
                                                                    value={0}
                                                                    data={receipts_array}
                                                                    onSelect={this.onSelectReceipt}
                                                                    renderMenu={menu => {
                                                                        if (receipts_array.length === 0) {
                                                                            return (
                                                                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                                                <Icon icon="spinner" spin />
                                                                            </p>
                                                                            );
                                                                        }
                                                                        return menu;
                                                                    }}
                                                                    renderMenuItem={(label, item) => {
                                                                        let label_text = label
                                                                        if(receipts_selected.length > 0){
                                                                            for(let i = 0; i < receipts_selected.length; i++){
                                                                                if (receipts_selected[i].id === item.id){
                                                                                    label_text = <strong>{label}</strong>
                                                                                    break
                                                                                }
                                                                            }
                                                                        }
                                                                        return label_text;
                                                                    }}
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-xs-12">
                                                            <div className="nk-int-mk mr-bot-5">
                                                                <h2>Nguồn chi trả</h2>
                                                            </div>
                                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                                { finance_sources_array.length > 1 ?
                                                                    <CheckTreePicker
                                                                            block
                                                                            placeholder="Chọn nguồn tài chính"
                                                                            cleanable={true}
                                                                            searchable={false}
                                                                            data={finance_sources_array}
                                                                            value={finance_sources_value_selected}
                                                                            onChange={this.onFinanceSourceChange}
                                                                            style={{ width: 267 }}
                                                                        />
                                                                    : <div style={{paddingTop: 4}}>{finance_source_name_selected}</div>}
                                                            </div>
                                                     </div>
                                                     <div className="col-sm-6 col-xs-12">
                                                        <div className="nk-int-mk mr-bot-5">
                                                            <h2>Ghi chú</h2>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className={notes_field_class}>
                                                                <TextareaAutoHeight placeholder="" name={"payment_bill_notes"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.payment_bill_notes_input = input; }}/>
                                                            </div>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { receipts_selected.length > 0 ? 
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="nk-int-mk mr-bot-5">
                                            <h2>
                                                <div className="pull-left mr-top-10">{ object_payment_title }</div>
                                            </h2>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="normal-table-list cl_list_product">
                                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                                    <colgroup>
                                                        <col width="10%"></col>
                                                        <col width="15%"></col>
                                                        <col width="10%"></col>
                                                        <col width="10%"></col>
                                                        <col width="10%"></col>
                                                        <col width="10%"></col>
                                                        <col width="1%"></col>
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>Mã phiếu</th>
                                                            <th>{ date_title_text }</th>
                                                            <th className="text-right">Tổng tiền</th>
                                                            <th className="text-right">Đã thanh toán</th>
                                                            <th className="text-right">Còn nợ</th>
                                                            <th className="text-right">Số tiền</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { this.showListBills(receipts_selected) }
                                                    </tbody>
                                                    <tbody>
                                                        <tr>
                                                            <th className="text-right" colSpan="5">Tổng tiền</th>
                                                            <th className="text-right"><NumberFormat value={total_liabilities_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-right" colSpan="5">Đã thanh toán</th>
                                                            <th className="text-right"><NumberFormat value={total_paid_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-right" colSpan="5">Còn nợ</th>
                                                            <th className="text-right"><NumberFormat value={remain_liabilities_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-right" colSpan="5">Tiền thanh toán</th>
                                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                            <th></th>
                                                        </tr>
                                                        {total_remain_amount ? 
                                                        <tr>
                                                            <th className="text-right" colSpan="5">Số tiền còn lại</th>
                                                            <th className="text-right"><NumberFormat value={total_remain_amount} displayType={'text'} thousandSeparator={true} /></th>
                                                            <th></th>
                                                        </tr> : null }
                                                    </tbody>
                                                </table>    
                                            </div>       
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-4 col-md-offset-4">
                                        <LaddaButton
                                            loading={ save_button_loading }
                                            data-size={XL}
                                            data-style={ZOOM_IN}
                                            data-spinner-color="#000000"
                                            className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                            <i className="fa fa-plus"></i>&nbsp;TẠO PHIẾU
                                        </LaddaButton>
                                    </div>
                                </div> : "" } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        payment_invoice: state.payment_invoice,
        user: state.user,
        category: state.category,
        modal: state.modal
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PaymentInvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PaymentInvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PaymentInvoiceAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(PaymentInvoiceAction.emptyInfo())
        },
        onGetObject: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
        onSearchObject: (data_search,page,limit,spinner) => {
            dispatch(UserAction.searchRequest(data_search,page,limit,spinner))
        },
        onSearchCategories: (data_search,page,limit,spinner) => {
            return dispatch(CategoryAction.searchRequest(data_search,page,limit,spinner))
        },
        onGetImportReceipt: (id) => {
            return dispatch(PaymentInvoiceAction.getImportReceiptRequest(id))
        },
        onGetVendorBill: (id) => {
            return dispatch(PaymentInvoiceAction.getVendorBillRequest(id))
        },
        onGetReceiptForPayment: (id, is_supplier) => {
            dispatch(PaymentInvoiceAction.getReceiptForPaymentRequest(id, is_supplier))
        },
        onUpdateAmountItem: (id, finance_sources) => {
            dispatch(PaymentInvoiceAction.updateAmountItem(id, finance_sources))
        },
        onSubmit: data_post => {
            return dispatch(PaymentInvoiceAction.addRequest(data_post))
        }
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)
export default enhance(AccountingCreatePayment)