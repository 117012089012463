import * as SupplierVendorBillActionType from '../constants/SupplierVendorBillActionType';
import moment from 'moment';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    vendor_bill_code: "",
    vendor_bill_date: "",
    vendor_bill_notes: "",
    vendor_bill_status: 0,
    vendor_bill_status_text: "",
    vendor_bill_status_color: "",
    supplier_selected: {},
    supplier_id: 0,
    supplier_name: "",
    total_quantity: 0,
    total_product: 0,
    total_quantity_real: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    staff_finish_id: 0,
    staff_finish_name: "",
    finish_time: 0,
    branch_id: 0,
    branch_name: "",
    products: [],
    total: 0,
    limit: 15,
    total_page: 0,
    page: 1,
    list: [],
    suppliers: [],
    search_supplier_selected: {},
    search_code: "",
    search_product: "",
    search_date: [],
    branch_selected: {},
    search_button_loading: false,
    save_button_loading: false,
    is_search: false,
};
const supplier_vendor_bill = (state = initialState, action) => {
  let index = -1, products = []
    switch(action.type){
        case SupplierVendorBillActionType.BUTTON_SPINNER_SUPPLIER_VENDOR_BILL:
            return {
                ...state,
                save_button_loading: action.status
            };
          case SupplierVendorBillActionType.BUTTON_UPDATE_SPINNER_SUPPLIER_VENDOR_BILL:
            return {
                ...state,
                [action.button_name]: action.status
            };
        case SupplierVendorBillActionType.FOCUS_FIELD_SUPPLIER_VENDOR_BILL:
              return {
                ...state,
                [action.field]: action.value
              };
        case SupplierVendorBillActionType.ERROR_FIELD_SUPPLIER_VENDOR_BILL:
              return {
                ...state,
                [action.field]: action.value
              };
        case SupplierVendorBillActionType.FIELD_CHANGE_SUPPLIER_VENDOR_BILL:
              return {
                ...state,
                [action.field]: action.value
              };
        case SupplierVendorBillActionType.FIELDS_CHANGE_SUPPLIER_VENDOR_BILL:
              return {
                ...state,
                ...action.fields
              };
        case SupplierVendorBillActionType.EMPTY_SUPPLIER_VENDOR_BILL:      
          return {
            ...state,
            id: 0,
            vendor_bill_code: "",
            vendor_bill_date: "",
            vendor_bill_notes: "",
            vendor_bill_status: 0,
            vendor_bill_status_text: "",
            vendor_bill_status_color: "",
            supplier_id: 0,
            supplier_name: "",
            total_quantity: 0,
            total_product: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            staff_finish_id: 0,
            staff_finish_name: "",
            finish_time: 0,
            branch_id: 0,
            branch_name: "",
            products: [],
            total: 0,
            limit: 15,
            total_page: 0,
            page: 1,
            list: [],
          };
        case SupplierVendorBillActionType.RESET_FIELD_SEARCH_SUPPLIER_VENDOR_BILL:     
          var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
          var to_date = moment().endOf('month').format('YYYY-MM-DD');
          var search_date = [];
          if(from_date !== ""){
              search_date.push(from_date)
          }
          if(to_date !== ""){
              search_date.push(to_date)
          } 
            return {
              ...state,
              search_supplier_selected: {},
              suppliers: [],
              search_code: "",
              search_product: "",
              search_date,
              branch_selected: {},
              search_button_loading: false,
              is_search: false,
            };
        case SupplierVendorBillActionType.GET_SUPPLIER_VENDOR_BILL:
            return {
              ...state,
              id: action.supplier_vendor_bill.id,
              vendor_bill_code: action.supplier_vendor_bill.vendor_bill_code,
              vendor_bill_date: action.supplier_vendor_bill.vendor_bill_date,
              vendor_bill_notes: action.supplier_vendor_bill.vendor_bill_notes,
              vendor_bill_status: action.supplier_vendor_bill.vendor_bill_status,
              vendor_bill_status_text: action.supplier_vendor_bill.vendor_bill_status_text,
              vendor_bill_status_color: action.supplier_vendor_bill.vendor_bill_status_color,
              supplier_id: action.supplier_vendor_bill.supplier_id,
              supplier_name: action.supplier_vendor_bill.supplier_name,
              total_quantity: action.supplier_vendor_bill.total_quantity,
              total_product: action.supplier_vendor_bill.total_product,
              total_quantity_real: action.supplier_vendor_bill.total_quantity_real,
              staff_id: action.supplier_vendor_bill.staff_id,
              staff_name: action.supplier_vendor_bill.staff_name,
              staff_cancel_id: action.supplier_vendor_bill.staff_cancel_id,
              staff_cancel_name: action.supplier_vendor_bill.staff_cancel_name,
              reason_cancel: action.supplier_vendor_bill.reason_cancel,
              cancel_time: action.supplier_vendor_bill.cancel_time,
              staff_finish_id: action.supplier_vendor_bill.staff_finish_id,
              staff_finish_name: action.supplier_vendor_bill.staff_finish_name,
              finish_time: action.supplier_vendor_bill.finish_time,
              branch_id: action.supplier_vendor_bill.branch_id,
              branch_name: action.supplier_vendor_bill.branch_name,
              products: action.supplier_vendor_bill.products,
            }
        case SupplierVendorBillActionType.LIST_SUPPLIER_VENDOR_BILL:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                list: [...action.list],
              };
        case SupplierVendorBillActionType.SEARCH_SUPPLIER_VENDOR_BILL:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case SupplierVendorBillActionType.CLEAR_PRODUCTS_SUPPLIER_VENDOR_BILL:
          return {
            ...state,
            products: []
          };
        case SupplierVendorBillActionType.SELECT_PRODUCT_SUPPLIER_VENDOR_BILL:
            products = state.products;
            let itemSelected = action.item
            if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
              let key = itemSelected.id + '_' + itemSelected.product_option_id
              let index = Helper.findIndexByKey(products, key);
              if(index > -1){
                products[index].quantity = parseInt(products[index].quantity) + 1
              } else {
                  products.unshift({
                      "key": key,
                      "id": itemSelected.id,
                      "product_option_id": itemSelected.product_option_id,
                      "product_option_name": itemSelected.product_option_name,
                      "url": itemSelected.url,
                      "name": itemSelected.name,
                      "model": itemSelected.model,
                      "sku": itemSelected.sku,
                      "quantity": 0,
                      "remain": itemSelected.remain,
                      "unit": itemSelected.unit,
                      "categories": itemSelected.categories,
                      "category_id": itemSelected.category_id,
                      "category_name": itemSelected.category_name,
                      "brand_id": itemSelected.brand_id,
                      "brand_name": itemSelected.brand_name,
                      "is_gift": itemSelected.is_gift,
                  })
              }
          }
          return {
            ...state,
            products: [...products]
          }; 
        case SupplierVendorBillActionType.UPDATE_PRODUCTS_SUPPLIER_VENDOR_BILL: 
            return {
              ...state,
              products: [...action.products]
            }; 
        case SupplierVendorBillActionType.UPDATE_PRODUCT_SUPPLIER_VENDOR_BILL: 
          index = Helper.findIndexByKey(state.products, action.key);
          if (index > -1){
            let product = state.products[index]
            if (action.input_name === "quantity"){
              product.quantity = parseInt(action.value)
            }
            state.products[index] = product
          }
          return {
            ...state
          };
        case SupplierVendorBillActionType.SUM_TOTAL_SUPPLIER_VENDOR_BILL:
            state.total_product = 0
            state.total_quantity = 0
            if(state.products.length > 0){
              let total_product = 0, total_quantity = 0
              state.products.forEach(function(item){
                total_product++
                total_quantity += parseInt(item.quantity)
              });
              state.total_product = total_product
              state.total_quantity = total_quantity
            }
            return {
              ...state
            };  
        default: 
            return state;
    }
}
export default supplier_vendor_bill;