import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as ReturnInvoiceAction from '../../actions/ReturnInvoiceAction';
import * as LoginAction from '../../actions/LoginAction';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
import { SelectPicker } from 'rsuite';
class ReturnInvoices extends Component {
    constructor(props){
        super(props)
        this.state = {
            branchs: [],
        }
        this.onSelectBranch = this.onSelectBranch.bind(this);
    }
    componentDidMount(){
        let _this = this
        _this.bindDataSearch();
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            if (data_search.search !== undefined && data_search.search === true){
                _this.props.onSearch(data_search,_this.props.return_invoice.page,_this.props.return_invoice.limit, true);
            } else {
                var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
                var to_date = moment().endOf('month').format('YYYY-MM-DD');
                var search_date = [];
                if(from_date !== ""){
                    search_date.push(from_date)
                }
                if(to_date !== ""){
                    search_date.push(to_date)
                }
                _this.props.onFieldChange("search_date",search_date);
                _this.props.onSearch({
                    "from_date": search_date[0],
                    "to_date": search_date[1]
                },1,_this.props.return_invoice.limit, false);
            }
        })
        _this.props.onGetBranchs().then(branchs => {
            let branchs_state = []
            if (branchs !== null && branchs.length > 0){
                branchs.forEach(item => {
                    let color = "#00b8ce"
                    if (item.id !== 1){
                        color = "#a1eef7"
                    }
                    
                    branchs_state.push({...item, color})
                });
            }
            _this.setState({
                branchs: branchs_state
            })
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onReceiptBillDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("search_customer_selected", item);
    }
    onSelectBranch(value){
        if(value !== null){
            let branch_selected_state = {}
            if (this.state.branchs.length > 0){
                this.state.branchs.forEach(item => {
                    if(item.id === value){
                        branch_selected_state = {
                            name: item.branch_name,
                            id: item.id
                        }
                    }
                });
            }
            this.props.onFieldChange("branch_selected",branch_selected_state)
        }
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let search_product = Helper.getQueryString('keyword');
        let status = Helper.getQueryString('status');
        let from_date = Helper.getQueryString('from_date');
        let to_date = Helper.getQueryString('to_date');
        let customer_id = Helper.getQueryString('customer');
        let branch_param = Helper.getQueryString('branch');
        var search_date = [];
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_date.push(from_date)
        }
        if(to_date !== undefined && to_date !== null && to_date !== ""){
            search_date.push(to_date)
        }
        var search_customer_selected = {}
        if(customer_id !== undefined && customer_id !== null && customer_id > 0){
            search_customer_selected.value = parseInt(customer_id)
        }
        var search_status = []
        if (status !== undefined && status !== null && status !== ""){
            let search_status_array = status.split(",")
            search_status_array.forEach(item => {
                search_status.push(parseInt(item))
            })
        }
        if (search_product === null){
            search_product = ""
        }
        if (search_code === null){
            search_code = ""
        }
        let branch_selected = {}
        if (branch_param > 0){
            branch_selected.id = parseInt(branch_param)   
        }
        this.props.onFieldsChange({
            search_code, search_status, search_date, search_customer_selected, search_product, branch_selected
        })
    }
    getDataSearch(){
        let _code = this.props.return_invoice.search_code;
        let _bill_code = this.props.return_invoice.search_bill_code;
        let _product = this.props.return_invoice.search_product;
        let _date = this.props.return_invoice.search_date;
        let _branch = this.props.return_invoice.branch_selected;
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;a
        }*/
        var data_search = {
            "search": false,
            "param": []
        }
        let is_search = false
        if (_code !== ""){
            data_search["code"] = _code;
            data_search["param"].push("code=" + _code)
            is_search = true
            this.props.onFieldsChange({
                search_customer_selected: 0, search_product: "", branch_selected: {}
            })
        } else {
            if (_branch !== null && _branch.id !== undefined && _branch.id !== null && _branch.id > 0){
                data_search["branch_id"] = _branch.id;
                data_search["param"].push("branch=" + _branch.id)
                is_search = true
            }
            if (_bill_code !== ""){
                data_search["bill_code"] = _bill_code;
                data_search["param"].push("bill_code=" + _bill_code)
                is_search = true
            }
            if (_product !== ""){
                data_search["product_keyword"] = _product;
                data_search["param"].push("keyword=" + _product)
                is_search = true
            }
            if (_date[0] !== undefined){
                data_search["from_date"] = _date[0];
                data_search["param"].push("from_date=" + _date[0])
                is_search = true
            }
            if (_date[1] !== undefined){
                data_search["to_date"] = _date[1];
                data_search["param"].push("to_date=" + _date[1])
                is_search = true
            }
        }
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search.search !== undefined && data_search.search === true){
            if(data_search.param.length > 0){
                let param = data_search.param.join("&")
                if(param !== ""){
                    param = "?" + param
                }
                this.props.history.push("/return-invoices" + param)
            }
            this.props.onSearch(data_search,1,this.props.return_invoice.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        let param = "?page=" + pageNumber
        if(data_search.param.length > 0){
            let param_search = data_search.param.join("&")
            if(param_search !== ""){
                param += "&" + param_search
            }
        }
        this.props.history.push("/return-invoices" + param)
        this.props.onFieldChange("page", pageNumber)
        this.props.onSearch(data_search,pageNumber,this.props.return_invoice.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/return-invoice/" + id);
    }
    showList(lists){    
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let branch_color = ""
                if (this.state.branchs.length > 0){
                    this.state.branchs.forEach(branchItem => {
                        if(branchItem.id === item.branch_id){
                            branch_color = <span className="badge" style={{backgroundColor: branchItem.color}}>&nbsp;</span>
                        }
                    });
                }
                let status_text = ""
                if(item.is_cancel === 1){
                    status_text = <label className='label label-danger'>Đã hủy</label>
                }
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td>{item.return_bill_code}</td>   
                         <td>{item.receipt_bill_code}</td>
                         <td>
                            <Moment format="DD/MM/YYYY HH:mm">
                            { Helper.replaceMysqlDateTime(item.return_bill_date) }
                            </Moment>
                         </td>
                         <td>{ item.customer_name }</td>
                         <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-center">{branch_color}</td>
                         <td className="text-center">{status_text}</td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, total_amount, search_code, search_bill_code, search_product, branch_selected, search_button_loading } = this.props.return_invoice;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let branchs_array = [], branchs_label_array = []
        if (this.state.branchs.length > 0){
            branchs_array.push({
                label: "Tất cả chi nhánh",
                value: 0
            })
            this.state.branchs.forEach(item => {
                branchs_array.push({
                    label: item.branch_name,
                    value: item.id
                })
                branchs_label_array.push(<h6 key={"branch_name_text" + item.id} className="pull-left mr-left-5"><span className="badge" style={{backgroundColor: item.color}}>&nbsp;</span>&nbsp;{item.branch_name}</h6>)
            });
        }
        let branch_selected_value = 0
        if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
            branch_selected_value = branch_selected.id
        }
        return (
            <div className="container">
            <Helmet>
                <title>Danh sách phiếu trả hàng</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/invoices"} />
            </Helmet>   
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter has_default={true} value={this.props.return_invoice.search_date} placeholder="Chọn ngày trả hàng" onFieldChange = { (value) => { this.onReceiptBillDatePickerChange(value) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu trả hàng" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_bill_code" value={ search_bill_code } className="form-control" autoComplete="off" placeholder="Nhập mã hóa đơn" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_product" value={ search_product } className="form-control" autoComplete="off" placeholder="Nhập mã hoặc tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <SelectPicker
                                    style={{color: "#FFFFFF"}}
                                    block
                                    searchable={false}
                                    cleanable={false}
                                    placeholder="Chọn chi nhánh"
                                    data={branchs_array}
                                    value={branch_selected_value}
                                    onSelect={this.onSelectBranch}
                                    renderMenu={menu => {
                                        return menu;
                                    }}
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        <div className="pull-left">Phiếu trả hàng ({this.props.return_invoice.total} đơn)</div>
                                    </h2>
                                    <p></p>
                                </div>
                                <div className="pull-right mr-top-20">{branchs_label_array}</div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col></col>
                                        <col width="10%"></col>
                                        <col width="5%"></col>
                                        <col width="5%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã Phiếu</th>
                                            <th>Mã hóa đơn</th>
                                            <th>Thời gian</th>
                                            <th>Khách hàng</th>
                                            <th className="text-right">Tổng tiền hàng</th>
                                            <th>CN</th>
                                            <th></th>
                                        </tr>
                                        <tr className="bg-warning">
                                            <th colSpan="4"></th>
                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        return_invoice: state.return_invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(ReturnInvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(ReturnInvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ReturnInvoiceAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(ReturnInvoiceAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(ReturnInvoiceAction.resetFieldSearch())
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(ReturnInvoiceAction.searchRequest(data_search,page,limit,spinner))
        },
        onGetInvoice: (id) => {
            dispatch(ReturnInvoiceAction.getRequest(id))
        },
        onGetBranchs: () => {
            return dispatch(LoginAction.getBranchsRequest())
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(ReturnInvoices)