import * as CategoryActionType from '../constants/CategoryActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: CategoryActionType.FOCUS_FIELD_CATEGORY,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: CategoryActionType.ERROR_FIELD_CATEGORY,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: CategoryActionType.FIELD_CHANGE_CATEGORY,
        field,
        value
    }
}

export const resetField = () => {
    return {
        type: CategoryActionType.RESET_FIELD_CATEGORY
    }
}

export const buttonSpinner = status => {
    return {
        type: CategoryActionType.BUTTON_SPINNER_CATEGORY,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: CategoryActionType.ITEM_BUTTON_SPINNER_CATEGORY,
        id,
        status
    }
}

export const updateRequest = (data_post, id) => {
    return (dispatch) => {
        return apiCaller.callApi(`categories/${id}`,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`categories/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(resetField())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    result: (res.data.result !== undefined) ? res.data.result : null,
                }
            }
        });
    }
}

export const deleteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`categories/${id}`, null, 'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`categories`,{
            limit,
            page,
            ...data_search
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listCategory(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const listCategory = (result) => {
    return {
        type: CategoryActionType.LIST_CATEGORY,
        ...result
    }
}

export const resetFieldSearch = () => {
    return {
        type: CategoryActionType.RESET_FIELD_SEARCH_CATEGORY
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: CategoryActionType.SEARCH_CATEGORY,
        search_button_loading
    }
}

export const getFinanceSourceRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`finance-sources`,{
            limit: 0,
            page: 1,
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    if (list.length > 0){
                        let banks = []
                        list.forEach(item => {
                            let label = item.name + " - " + item.account_number
                            if (item.is_card === 1) {
                                banks.push({
                                    label: label,
                                    value: item.id,
                                    role: "Quẹt thẻ"
                                })
                            } else if (item.is_bank === 1) {
                                banks.push({
                                    label: label,
                                    value: item.id,
                                    role: "Chuyển khoản"
                                })
                            }
                        })

                        dispatch(getFinanceSource(banks))
                    }
                }
            }
        });
    }
}
const getFinanceSource = banks => {
    return {
        type: CategoryActionType.GET_FINANCE_SOURCE_CATEGORY,
        banks
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`categories/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

const get = category => {
    return {
        type: CategoryActionType.GET_CATEGORY,
        category
    }
}