import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as SupplierVendorBillAction from '../../actions/SupplierVendorBillAction';
import * as UserActionType from '../../constants/UserActionType';
import Helper from '../../utils/Helper';
import SearchProduct from '../shared/SearchProduct';
import UserSelectBox from '../shared/UserSelectBox';

const { Column, HeaderCell, Cell } = Table;

class CreateSupplierVendorBill extends Component {
    componentDidMount(){
        this.props.onEmptyInfo()
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onSelectProduct = itemSelected => {
        if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            this.props.onSelectProductToList(itemSelected)
        }
    }
    onRemoveProduct = key => {
        let products = this.props.supplier_vendor_bill.products;
        let index = Helper.findIndexByKey(products, key);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onClearProduct = () => {
        this.props.onClearProduct();
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("quantity", key, value);
    }
    onUserFieldChange(item){
        this.props.onFieldChange("supplier_selected", item);
    }
    onSubmit(){
        let {products, vendor_bill_notes, vendor_bill_date, supplier_selected } = this.props.supplier_vendor_bill
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        let confirm_diff_alert = ""
        for(let i = 0; i < products.length; i++){
            if(products[i].quantity === 0){
                confirm_diff_alert = 'Vui lòng nhập số lượng cho hàng hóa ' + products[i].name
                break
            }
        }
        if (confirm_diff_alert !== ""){
            Alert.error(confirm_diff_alert)
            return;
        }
        if (!(supplier_selected.id !== undefined && supplier_selected.id > 0)){
            Alert.error('Vui lòng chọn nhà cung cấp')
            return;
        }
        let products_array = []
        products.forEach(item => {
            products_array.push({
                "product_id": item.id,
                "product_option_id": item.product_option_id,
                "product_name": item.name,
                "product_sku": item.sku,
                "product_model": item.model,
                "category_id": parseInt(item.category_id),
                "category_name": item.category_name,
                "brand_id": parseInt(item.brand_id),
                "brand_name": item.brand_name,
                "retail": parseFloat(item.price),
                "quantity": parseInt(item.quantity),
                "is_gift": parseInt(item.is_gift),
            })
        })
        let data_post = {
            vendor_bill_date: vendor_bill_date,
            vendor_bill_notes: vendor_bill_notes,
            supplier_id: supplier_selected.id,
            supplier_name: supplier_selected.name,
            products: products_array,
        }
        //console.log(data_post);
        this.props.onSubmit(data_post).then(result => {
            if(result.id !== undefined && result.id > 0){
                this.props.history.push("/supplier-vendor-bill/" + result.id);
            }
        })
    }
    render(){
        let {products, total_quantity, total_product, vendor_bill_notes, vendor_bill_notes_active, save_button_loading } = this.props.supplier_vendor_bill;
        
        let notes_field_class = "nk-int-st"
        if (vendor_bill_notes !== "") {
            vendor_bill_notes_active = true;
        }
        if(vendor_bill_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }
        return (
            <div className="container">
            <Helmet>
                <title>Tạo phiếu mua hàng nhà cung cấp</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/supplier-vendor-bill/new"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <SearchProduct onSelect={ this.onSelectProduct.bind(this) } onClearProduct={ this.onClearProduct.bind(this) } get_size={true} />
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={55}
                                    data={products} >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={450}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                return nameText
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Tồn kho</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.remain} disabled />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Số lượng mua</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.quantity} min={0} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50} fixed="right" align="center">
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <button type="button" onClick={() => { this.onRemoveProduct(rowData.key) }}><i className="fa fa-remove"></i></button>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker format="YYYY-MM-DD HH:mm" appearance="subtle" placeholder={moment().format("YYYY-MM-DD HH:mm")} 
                                    defaultValue={moment().toDate()}
                                    placement="leftStart" ranges={[]} block disabled />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng số lượng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_quantity} />
                                </div>  
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox getRef={ref => { this.supplierSelectBoxRef = ref } } placeholder="Tìm nhà cung cấp" user_type={UserActionType.SUPPLIER_USER_TYPE} onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="vendor_bill_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={vendor_bill_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;TẠO PHIẾU MUA HÀNG
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        supplier_vendor_bill: state.supplier_vendor_bill
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(SupplierVendorBillAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(SupplierVendorBillAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(SupplierVendorBillAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(SupplierVendorBillAction.fieldChange(field,value))
        },
        onSubmit(data_post){
            return dispatch(SupplierVendorBillAction.addRequest(data_post))
        },
        onSelectProductToList(item){
            dispatch(SupplierVendorBillAction.selectProductToListRequest(item))
        },
        onUpdateProducts(products){
            dispatch(SupplierVendorBillAction.updateProductsRequest(products))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(SupplierVendorBillAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(SupplierVendorBillAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(SupplierVendorBillAction.sumTotal())
        },
    }
}
const CreateSupplierVendorBillConnect = connect(mapStateToProps, mapDispatchToProps)(CreateSupplierVendorBill);
export default withRouter(CreateSupplierVendorBillConnect);