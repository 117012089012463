import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import NumberFormat from 'react-number-format';
import * as TransferStoreAction from '../../actions/TransferStoreAction';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import logo from '../../logo.png';

class PrintTransferWarehouse extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)
        }
    }
    onInitData(id){
        this.props.onGetData(id)
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 return (
                     <tbody key={ index } >
                        <tr>
                            <td className="text-center">{ (index + 1) }</td>
                            <td>{item.model}</td>
                            <td>{gift_text}{ item.name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                            <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>  
                     </tbody>
                 );   
            });
        }
        return result;
    }
    render(){
        let { transfer_store_code, transfer_date, products, total_quantity, total_product, from_store } = this.props.transfer_store;
        transfer_date = Helper.replaceMysqlDateTime(transfer_date)
        let to_store_text = this.props.transfer_store.to_store_code + " - " + this.props.transfer_store.to_store_name
        let from_store_text = []
        if(from_store !== null && from_store.length > 0){
            from_store.forEach(item => {
                from_store_text.push(
                    <span>{item.store_code + " - " + item.store_name}</span>
                )
            })
        }
        return (
            <div className="face">
                <div className="text-center hide-print">
                        <button className="btn btn-default btn-sm" onClick={() => { window.print() }}><i className="fa fa-print"></i>&nbsp;In</button>
                </div>
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <div className="pull-left">
                                    <img alt="" src={logo} style={ { "height":"80px" } } />
                                </div>
                                <div className="pull-right text-center">
                                    <span>MOBY KID</span><br />
                                    <span>ĐIỀU TỐT NHẤT CHO CON</span><br />
                                    <span>15i Nguyễn Thị Minh Khai, Phường Bến Nghé, Quận 1, HCM</span><br />
                                    <span>344A Lê Hồng Phong, Phường 1, Quận 10, HCM</span><br />
                                    <span>Hotline: 0986.680.500 - Website: moby.com.vn</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-center">
                    <h4>PHIẾU CHUYỂN KHO</h4>
                    Mã phiếu: {transfer_store_code}<br />
                    Ngày  <Moment format="DD">{ transfer_date }</Moment>  Tháng  <Moment format="MM">{ transfer_date }</Moment>  Năm <Moment format="YYYY">{ transfer_date }</Moment>
                </div>
                <div className="mr-top-10">
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td><strong>Kho chuyển:&nbsp;</strong>{from_store_text}</td>
                            </tr>
                            <tr>
                                <td><strong>Kho nhận:&nbsp;</strong>{to_store_text}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mr-top-10">     
                    <table className="table table-sc-ex table-hover table-condensed">
                        <colgroup>
                            <col width="1%"></col>
                            <col width="15%"></col>
                            <col width="30%"></col>
                            <col width="15%"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th>Mã hàng</th>
                                <th>Tên hàng</th>
                                <th className="text-right">Số lượng</th>
                            </tr>
                        </thead>
                            { this.showListProduct(products) }
                        <tbody>
                            <tr>
                                <td className="text-right" colSpan="3">Tổng số lượng</td>
                                <td className="text-right"><NumberFormat value={total_quantity} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                            <tr>
                                <td className="text-right" colSpan="3">Tổng mặt hàng</td>
                                <td className="text-right"><NumberFormat value={total_product} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                        </tbody>    
                    </table>  
                </div>    
                <div className="mr-top-10">
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td className="text-center" width="50%"><strong>Người giao</strong></td>
                            <td className="text-center" width="50%"><strong>Người nhận</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transfer_store: state.transfer_store,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo: () => {
            dispatch(TransferStoreAction.emptyInfo())
        },
        onGetData: (id) => {
            dispatch(TransferStoreAction.getRequest(id))
        },
    }
}
const PrintTransferWarehouseConnect = (connect(mapStateToProps, mapDispatchToProps)(PrintTransferWarehouse));
export default withRouter(PrintTransferWarehouseConnect);