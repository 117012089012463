import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as DiscountModeType from '../../constants/DiscountModeType';
import * as UserActionType from '../../constants/UserActionType';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as ModalAction from '../../actions/ModalAction';
import SearchProduct from '../shared/SearchProduct';
import DiscountInput from '../shared/DiscountInput';
import PaymentInput from '../shared/PaymentInput';
import Helper from '../../utils/Helper';
import UserSelectBox from '../shared/UserSelectBox';
import InvoiceShipping from './InvoiceShipping';
import SellChannelSelectBox from '../shared/SellChannelSelectBox';
import Modal from "../shared/Modal";
import CreatePartnerFast from '../partner/CreateFast';
import Swal from 'sweetalert2';

const { Column, HeaderCell, Cell } = Table;

class CreateInvoice extends Component {
    componentDidMount(){
        this.props.onEmptyInfo();  
    }
    componentDidUpdate(){
        if(this.props.message.error_message !== ""){
            Alert.error(this.props.message.error_message)
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onNumberFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
        this.props.onSumTotal();  
    }
    onDiscountFieldChange = (input_name, value, discount_mode) => {
        this.props.onFieldChange(input_name,value);
        this.props.onFieldChange("discount_mode",discount_mode);
        this.props.onSumTotal();  
    }
    onPaymentFieldChange = (value,payment_text) => {
        //console.log("value: ",value);
        //console.log("payment_text: ",payment_text);
        if (value !== null){
            let pay_amount = 0
            if (value.length > 0){
                value.forEach(function(item){
                    if(item.amount > 0){
                        pay_amount += parseFloat(item.amount)
                    }
                });
            }
            //console.log("pay_amount: ",pay_amount);
            this.props.onFieldChange("payment_method_info",value);
            this.props.onFieldChange("payment_method_text",payment_text);
            this.props.onFieldChange("payment_amount",pay_amount);
            this.props.onSumTotal();  
        }
    }
    onRemovePayment = () => {
        this.props.onFieldChange("payment_method_info",[]);
        this.props.onFieldChange("payment_method_text","");
        this.props.onFieldChange("payment_amount",0);
        this.props.onSumTotal();  
    }
    onSelectProduct = itemSelected => {
        //console.log(itemSelected)
        if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            this.props.onSelectProductToList(itemSelected)
        }
    }
    onRemoveProduct = key => {
        let products = this.props.invoice.products;
        let index = Helper.findIndexByKey(products, key);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onClearProduct = () => {
        this.props.onClearProduct();
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("quantity", key, value);
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price", key, values.value);
    }
    onDiscountItemFieldChange(key, value, discount_mode){
        this.props.onUpdateProductItem("discount_mode", key, discount_mode);
        this.props.onUpdateProductItem("discount", key, value);
    }
    onIsGiftItemFieldChange(key, e){
        let target = e.target;
        let value = 0;
        if(target.type === "checkbox" && target.checked === true){
            value = 1;
        }
        this.props.onUpdateProductItem("is_gift", key, value);
    }
    onInvoiceDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("receipt_bill_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("customer_selected", item);
        this.props.onClearShippingInfo();
    }
    handleModalClose(){
        this.props.onShowModal(false);
    }
    handleModalSubmit(submit_type){
        if (submit_type === "submit_add_user"){
            this.handleSubmitFormAddCustomer()
        }
    }
    handleSubmitFormAddCustomer(){
        this.createPartnerRef.onSubmit()
    }
    onCreatePartnerReturn(user_info){
        if (user_info.user_type === UserActionType.CUSTOMER_USER_TYPE){
            this.props.onFieldChange("customer_selected", {
                label: user_info.name,
                value: user_info.id,
                id: user_info.id,
                code: user_info.code,
                name: user_info.name,
                phone: user_info.phone,
                address: user_info.address,
                city: user_info.city,
                district: user_info.district,
                ward: user_info.ward,
                remain_point: user_info.remain_point,
            })
        } else if (user_info.user_type === UserActionType.TRANSPORTER_USER_TYPE){
            this.props.onFieldChange("transporter_selected", {
                label: user_info.name,
                value: user_info.id,
                is_staff: user_info.is_staff_transporter,
            })
        }
        this.handleModalClose();
    }
    onSubmit(){
        let {products, customer_selected, receipt_bill_date, receipt_bill_notes, total_amount, discount, discount_mode, payment_amount, payment_method_info, has_shipping, has_cod, transporter_selected, transporter_bill_number, receiver_name, receiver_phone, receiver_address, receiver_city_selected, receiver_district_selected, receiver_ward_selected, receiver_pay_fee} = this.props.invoice
        receiver_pay_fee = parseInt(receiver_pay_fee)
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        if (customer_selected.length === 0){
            Alert.error('Vui lòng chọn khách hàng')
            return;
        }
        if (receipt_bill_date === ""){
            receipt_bill_date = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        let ship_fee = parseFloat(this.props.invoice.ship_fee)
        let other_fee = parseFloat(this.props.invoice.other_fee)
        let receiver_city = 0, receiver_district = 0, receiver_ward = 0, transporter_id = 0, is_staff_transporter = 0
        let transporter_name = ""
        if (has_shipping === true) {
            let has_shipping_error = false
            let error_msg = "", field_error = ""
            if (receiver_name === ""){
                error_msg = 'Vui lòng nhập tên người nhận'
                field_error = "receiver_name_error"
                has_shipping_error = true
            } else if (receiver_phone === ""){
                error_msg = 'Vui lòng nhập số điện thoại người nhận'
                field_error = "receiver_phone_error"
                has_shipping_error = true
            } else if (receiver_address === ""){
                error_msg = 'Vui lòng nhập địa chỉ người nhận'
                field_error = "receiver_address_error"
                has_shipping_error = true
            } else if (receiver_city_selected.value === undefined){
                error_msg = 'Vui lòng chọn tỉnh/thành phố'
                has_shipping_error = true
            } else if (receiver_district_selected.value === undefined){
                error_msg = 'Vui lòng chọn quận/huyện'
                has_shipping_error = true
            } else if (receiver_ward_selected.value === undefined){
                error_msg = 'Vui lòng chọn phường/xã'
                has_shipping_error = true
            } else if (transporter_selected.value === undefined){
                error_msg = 'Vui lòng chọn đối tác giao hàng'
                has_shipping_error = true
            }
            if(has_shipping_error === true){
                this.invoiceShippingRef.onShippingModeClick(true)
                Alert.error(error_msg)
                let _this = this
                setTimeout(function(){
                    if(field_error !== ""){
                        _this.props.onErrorField(field_error,true)
                    }
                },250)
                return;
            }
            receiver_city = parseInt(receiver_city_selected.value)
            receiver_district = parseInt(receiver_district_selected.value)
            receiver_ward = parseInt(receiver_ward_selected.value)
            transporter_id = parseInt(transporter_selected.value)
            transporter_name = transporter_selected.label
            is_staff_transporter = parseInt(transporter_selected.is_staff)
        } else if (ship_fee > 0){
            this.invoiceShippingRef.onShippingModeChange(true)
            Alert.error('Vui lòng nhập thông tin giao hàng')
            return;
        }
        if (ship_fee > 0 && receiver_pay_fee === 0){
            Alert.error('Vui lòng chọn người trả phí vận chuyển')
            return;
        }
        let products_array = []
        for(let i = 0; i < products.length; i++){
            let item = products[i]
            let allow_add = true
            if (products_array.length > 0){
                for(let j = 0; j < products_array.length; j++){
                    if(item.id === products_array[j].id && item.product_option_id === products_array[j].product_option_id){
                        allow_add = false
                        break
                    }
                }
            }
            if (allow_add === true){
                let discount_percent = 0, discount_money = 0
                if(item.discount > 0){
                    if(item.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                        discount_percent = item.discount
                    } else {
                        discount_money = item.discount
                    }
                }
                products_array.push({
                    "id": item.id,
                    "product_option_id": item.product_option_id,
                    "product_name": item.name,
                    "product_sku": item.sku,
                    "product_model": item.model,
                    "categories": item.categories,
                    "category_id": parseInt(item.category_id),
                    "category_name": item.category_name,
                    "brand_id": parseInt(item.brand_id),
                    "brand_name": item.brand_name,
                    "price": parseFloat(item.price),
                    "quantity": parseInt(item.quantity),
                    "discount": parseFloat(discount_percent),
                    "discount_money": parseFloat(discount_money),
                    "is_gift": parseInt(item.is_gift)
                })
            }
        }
        let discount_money = discount
        if (discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
            discount_money = 0
        } else{
            discount = 0
        }
        let payment_bills = []
        if (payment_method_info.length > 0){
            payment_method_info.forEach(paymentItem => {
                payment_bills.push(paymentItem)
            })
        }
        let money_collect_cod = 0, money_collect_cod_accounting = 0
        let liabilities_amount = 0, final_total_amount = 0
        let discount_value = discount_money
        if (total_amount > 0){
            if(discount > 0){
                discount_value = total_amount * discount / 100
            }
            final_total_amount = total_amount - discount_value
            if(other_fee > 0){
                final_total_amount += other_fee
            }
            if(ship_fee > 0){
                if(+receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                    final_total_amount += parseFloat(ship_fee)
                }
            }

            liabilities_amount = final_total_amount - payment_amount
            if (liabilities_amount < 0){
                liabilities_amount = 0
                has_cod = false
            }
            if (has_cod === true){
                money_collect_cod = liabilities_amount
                money_collect_cod_accounting = liabilities_amount
                
                if (money_collect_cod_accounting > 0){
                    payment_bills.push({
                        "finance_source_id": 0,
                        "amount": money_collect_cod_accounting,
                        "is_cash": 0,
                        "is_cod": 1
                    })
                }
            }
        }
        //console.log("has_cod: ",has_cod)
        //console.log("money_collect_cod: ",money_collect_cod)
        let pick_up_address_id = 0
        let pick_up_address = ""
        if (this.props.invoice.pick_up_address_id !== undefined && this.props.invoice.pick_up_address_id.value !== undefined){
            pick_up_address_id = this.props.invoice.pick_up_address_id.value
            pick_up_address = this.props.invoice.pick_up_address_id.label
        }
        let sell_channel = 0
        if (this.props.invoice.sell_channel_selected !== undefined && this.props.invoice.sell_channel_selected.value !== undefined){
            sell_channel = this.props.invoice.sell_channel_selected.value
        }
        let branch_selected = Helper.getBranchSelected()
        let data_post = {
            branch_id: branch_selected.id,
            branch_name: branch_selected.name,
            receipt_bill_date,
            customer_id: customer_selected.id,
            customer_code: customer_selected.code,
            customer_name: customer_selected.name,
            customer_phone: customer_selected.phone,
            customer_address: customer_selected.address,
            customer_city: parseInt(customer_selected.city),
            customer_district: parseInt(customer_selected.district),
            customer_ward: parseInt(customer_selected.ward),
            receipt_bill_notes,
            discount: parseFloat(discount),
            discount_money: parseFloat(discount_money),
            money_collect_cod,
            ship_fee,
            other_fee,
            sell_channel,
            products: products_array,
            payment_bills: payment_bills,
            shipping_info: {
                transporter_id,
                transporter_name,
                is_staff_transporter,
                transporter_bill_number: transporter_bill_number,
                receiver_name,
                receiver_phone,
                receiver_address,
                receiver_city,
                receiver_district,
                receiver_ward,
                weight: parseFloat(this.props.invoice.weight),
                weight_kg: parseFloat(this.props.invoice.weight_kg),
                length: parseFloat(this.props.invoice.length),
                width: parseFloat(this.props.invoice.width),
                height: parseFloat(this.props.invoice.height),
                pick_up_address_id,
                pick_up_address,
                delivery_type: 0,
                delivery_check_type: 0,
                receiver_pay_fee
            }
        }
        //console.log(data_post);
        this.props.onSubmit(data_post).then(result => {
            if(result.status !== undefined){
                if(result.status === false && result.message !== undefined){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        text: result.message
                    })
                }
            }
        })
    }
    render(){
        if (this.props.invoice.direct_to_list === true) {
            return <Redirect to={{ pathname: "/invoices" }} />
        }
        let {products, discount, discount_mode, total_quantity, receiver_pay_fee, ship_fee, other_fee, total_amount, payment_amount, payment_method_text, receipt_bill_notes, receipt_bill_notes_active, save_button_loading, receiver_name_error, receiver_phone_error, receiver_address_error, has_cod } = this.props.invoice;
        let { show_modal, modal_type, modal_title, modal_submit_type, modal_button_spinner} = this.props.modal;
        receiver_pay_fee = parseInt(receiver_pay_fee)
        let quantity_text_display = ""
        if(total_quantity > 0){
            quantity_text_display = (<span>&nbsp;({total_quantity})</span>)
        }
        let liabilities_amount = 0, final_total_amount = 0, return_amount = 0, final_total_amount_real_pay = 0
        let discount_text_display = ""
        let discount_value = discount
        if (total_amount > 0){
            if(discount_mode === 2 && discount > 0){
                discount_text_display = (<span>&nbsp;({discount}%)</span>)
                discount_value = total_amount * discount / 100
            }
            final_total_amount = total_amount - discount_value
        }
        let ship_fee_text = "", other_fee_text = "", ship_fee_text_display = ""
        if(other_fee > 0){
            other_fee_text = Helper.parseMoneyText(other_fee)
            final_total_amount += parseFloat(other_fee)
        }
        final_total_amount_real_pay = final_total_amount
        if(ship_fee > 0){
            ship_fee_text = Helper.parseMoneyText(ship_fee)
            if(+receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                final_total_amount += parseFloat(ship_fee)
                final_total_amount_real_pay += parseFloat(ship_fee)
                ship_fee_text_display = <small><br />Khách trả</small>
            } else if(+receiver_pay_fee === InvoiceActionType.SENDER_PAY_SHIP_FEE){
                final_total_amount_real_pay -= parseFloat(ship_fee)
                ship_fee_text_display = <small><br />Moby trả</small>
            }
        }
        liabilities_amount = final_total_amount - payment_amount
        let cod_amount = 0, cod_amount_real = liabilities_amount
        if (has_cod === true){
            cod_amount = liabilities_amount
        }
        if (liabilities_amount < 0){
            return_amount = liabilities_amount * (-1)
            liabilities_amount = 0
        }
        let payment_text_display = "", payment_remove = ""
        if(payment_method_text !== ""){
            payment_remove = <span>&nbsp;<button type="button" onClick={() => { this.onRemovePayment() }}><i className="fa fa-remove"></i></button></span>
            payment_text_display = (<small><br />({payment_method_text})</small>)
        }
        let notes_field_class = "nk-int-st"
        if (receipt_bill_notes !== "") {
            receipt_bill_notes_active = true;
        }
        if(receipt_bill_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }   
        let settings = Helper.getSettings()
        let classHasLiabilities = "form-group ic-cmp-int float-lb floating-lb display-none"
        if(settings !== null && settings.has_liabilities === true) {
            classHasLiabilities = "form-group ic-cmp-int float-lb floating-lb"
        }
        //console.log("products: ", products)
        return (
            <div className="container">
            <Helmet>
                <title>Bán hàng</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/invoice/new"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <SearchProduct onSelect={ this.onSelectProduct.bind(this) } onClearProduct={ this.onClearProduct.bind(this) } import_file={false} get_size={true}/>
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={100}
                                    data={products}
                                    wordWrap={true}  >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={280}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                return nameText
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={70}>
                                        <HeaderCell>Quà tặng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="toggle-select-act fm-cmp-mg">
                                                        <div className="nk-toggle-switch">
                                                            <input id={"is_gift_" + rowData.key} type="checkbox" hidden="hidden" value="1" checked={rowData.is_gift === 1?true:false} onChange={ (e) => { this.onIsGiftItemFieldChange(rowData.key, e) } }></input>
                                                            <label htmlFor={"is_gift_" + rowData.key} className="ts-helper"></label>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Số lượng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.quantity} max={100} min={1} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Đơn giá</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={rowData.price} onValueChange={(values) => this.onPriceItemFieldChange(rowData.key,values)} />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Giảm giá</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <DiscountInput discount_input="discount_item" discount_value={rowData.discount_value} fieldChange={(input_name,value,discount_mode) => {this.onDiscountItemFieldChange(rowData.key,value,discount_mode)}} />
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Thành tiền</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                <div className="nk-int-st nk-toggled">
                                                    <NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={rowData.total_amount} />
                                                </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50} fixed="right" align="center">
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <button type="button" onClick={() => { this.onRemoveProduct(rowData.key) }}><i className="fa fa-remove"></i></button>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker appearance="subtle" format="YYYY-MM-DD HH:mm"  placeholder={moment().format("YYYY-MM-DD HH:mm")} placement="leftStart" oneTap ranges={[]} block 
                                    onChange = { (value) => { this.onInvoiceDatePickerChange(value) } } />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox from_invoice={true} use_for_create={true} has_add_new={true} modal_inside={false} user_selected={ this.props.invoice.customer_selected } onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                            </div>
                            { this.props.invoice.customer_selected.id !== undefined && this.props.invoice.customer_selected.id > 0 ?
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="customer-point">
                                    Số điểm tích lũy:&nbsp;<NumberFormat className="point-number" thousandSeparator={true} displayType={"text"} value={this.props.invoice.customer_selected.remain_point} />
                                </div>
                            </div> : null }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <InvoiceShipping cod_amount={cod_amount_real} receiver_name_error={receiver_name_error} receiver_phone_error={receiver_phone_error} receiver_address_error={receiver_address_error} getRef={ref => (this.invoiceShippingRef = ref)} />
                                    </div>
                                    <div className="col-xs-6">
                                        <SellChannelSelectBox appearance="subtle" onFieldChange={(value) => this.props.onFieldChange("sell_channel_selected",value) } />
                                    </div>  
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tạm tính{quantity_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Giảm giá{discount_text_display}
                                </div>
                                <div className="pull-right">
                                    <DiscountInput discount_input="discount" discount_value={discount_value} fieldChange={(input_name,value,discount_mode) => {this.onDiscountFieldChange(input_name,value,discount_mode)}} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Phí vận chuyển{ship_fee_text_display}
                                </div>
                                <div className="pull-right">
                                    <div className="nk-int-st nk-toggled">
                                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.ship_fee_input = el} thousandSeparator={true} prefix={""} value={ship_fee_text} onValueChange={(values) => this.onNumberFieldChange("ship_fee",values)} />
                                    </div>
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Thu khác
                                </div>
                                <div className="pull-right">
                                    <div className="nk-int-st nk-toggled">
                                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.other_fee_input = el} thousandSeparator={true} prefix={""} value={other_fee_text} onValueChange={(values) => this.onNumberFieldChange("other_fee",values)} />
                                    </div>
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng tiền{quantity_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={final_total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            { final_total_amount_real_pay !== final_total_amount ? <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Khách cần trả
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={final_total_amount_real_pay} />
                                </div>
                                <div className="cl"></div>
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Khách thanh toán{payment_remove}{payment_text_display}
                                </div>
                                <div className="pull-right">
                                    <PaymentInput amount={final_total_amount_real_pay} payment_amount={payment_amount} onChange={(value,payment_text) => { this.onPaymentFieldChange(value,payment_text) }} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            { cod_amount > 0 ? 
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Thu hộ (COD)
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={cod_amount} />
                                </div>
                                <div className="cl"></div>
                            </div> : "" }
                            <div className={classHasLiabilities}>
                                <div className="pull-left">
                                    Tính vào công nợ
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={liabilities_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tiền trả hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={return_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="receipt_bill_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={receipt_bill_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;THANH TOÁN
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
                <Modal show={show_modal} type={modal_type} spinner={modal_button_spinner} title={modal_title} handleClose={this.handleModalClose.bind(this)} handleSubmit={this.handleModalSubmit.bind(this,modal_submit_type)}  children={<CreatePartnerFast getRef={ref => (this.createPartnerRef = ref)} avatarUploaderWidth={100} avatarUploaderHeight={100} has_save_button={false} return_info={true} return={user_info => { this.onCreatePartnerReturn(user_info)  } } partner_type={(typeof this.props.user.user_type !== "undefined") ? this.props.user.user_type : UserActionType.CUSTOMER_USER_TYPE} />}></Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.invoice,
        user: state.user,
        modal: state.modal,
        message: state.message
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowModal: (status, title, type, input, submit_type) => {
            dispatch(ModalAction.showModal(status, title, type, input, submit_type))
        },
        onEmptyInfo() {
            dispatch(InvoiceAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(InvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(InvoiceAction.fieldChange(field,value))
        },
        onUpdateProducts(products){
            dispatch(InvoiceAction.updateProductsRequest(products))
        },
        onSelectProductToList(item){
            dispatch(InvoiceAction.selectProductToListRequest(item))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(InvoiceAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(InvoiceAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(InvoiceAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(InvoiceAction.addRequest(data_post))
        },
        onClearShippingInfo(){
            dispatch(InvoiceAction.clearShippingInfoRequest())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);