export const UPDATE_INVENTORY_CHECK = 'UPDATE_INVENTORY_CHECK';
export const GET_INVENTORY_CHECK = 'GET_INVENTORY_CHECK';
export const LIST_INVENTORY_CHECK = 'LIST_INVENTORY_CHECK';
export const LIST_INVENTORY_CHECK_STATUS = 'LIST_INVENTORY_CHECK_STATUS';
export const SEARCH_INVENTORY_CHECK = 'SEARCH_INVENTORY_CHECK';
export const SHOW_HIDE_FIELD_ADD_NEW_INVENTORY_CHECK = 'SHOW_HIDE_FIELD_ADD_NEW_INVENTORY_CHECK';
export const FIELD_CHANGE_INVENTORY_CHECK = 'FIELD_CHANGE_INVENTORY_CHECK';
export const RESET_FIELD_INVENTORY_CHECK = 'RESET_FIELD_INVENTORY_CHECK';
export const RESET_FIELD_SEARCH_INVENTORY_CHECK = 'RESET_FIELD_SEARCH_INVENTORY_CHECK';
export const FOCUS_FIELD_INVENTORY_CHECK = 'FOCUS_FIELD_INVENTORY_CHECK';
export const ERROR_FIELD_INVENTORY_CHECK = 'ERROR_FIELD_INVENTORY_CHECK';
export const BUTTON_SPINNER_INVENTORY_CHECK = 'BUTTON_SPINNER_INVENTORY_CHECK';
export const BUTTON_UPDATE_SPINNER_INVENTORY_CHECK = 'BUTTON_UPDATE_SPINNER_INVENTORY_CHECK';
export const ITEM_BUTTON_SPINNER_INVENTORY_CHECK = 'ITEM_BUTTON_SPINNER_INVENTORY_CHECK';
export const UPDATE_PRODUCTS_INVENTORY_CHECK = 'UPDATE_PRODUCTS_INVENTORY_CHECK';
export const UPDATE_PRODUCT_INVENTORY_CHECK = 'UPDATE_PRODUCT_INVENTORY_CHECK';
export const SUM_TOTAL_INVENTORY_CHECK = 'SUM_TOTAL_INVENTORY_CHECK';
export const CLEAR_PRODUCTS_INVENTORY_CHECK = 'CLEAR_PRODUCTS_INVENTORY_CHECK';
export const SELECT_PRODUCT_INVENTORY_CHECK = 'SELECT_PRODUCT_INVENTORY_CHECK';
export const DIRECT_TO_LIST_INVENTORY_CHECK = 'DIRECT_TO_LIST_INVENTORY_CHECK';
export const RESET_FLAG_SEARCH_INVENTORY_CHECK = 'RESET_FLAG_SEARCH_INVENTORY_CHECK';
export const EMPTY_INVENTORY_CHECK = 'EMPTY_INVENTORY_CHECK';

export const GET_STAFF_NOTES_INVENTORY_CHECK = 'GET_STAFF_NOTES_INVENTORY_CHECK';
export const GET_LAST_STAFF_NOTES_INVENTORY_CHECK = 'GET_LAST_STAFF_NOTES_INVENTORY_CHECK';

export const STATUS_NEW_INVENTORY_CHECK = 1;
export const STATUS_FINISH_INVENTORY_CHECK = 2;
export const STATUS_CANCEL_INVENTORY_CHECK = 3;