import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Helper from '../../utils/Helper';
import MyPagination from '../shared/Pagination';

class ReportImportExport extends Component {
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                    let product_option_name = ""
                    if (item.product_option_name !== undefined && item.product_option_name !== null && item.product_option_name !== ""){
                        product_option_name = <span><br />({ item.product_option_name })</span>
                    }
                 return (
                     <tr key={ index }>
                        <td><Link to={"/product/" + item.product_id} target="_blank">{item.product_code}</Link></td>
                         <td>{ item.product_name } { product_option_name }</td>
                         <td className="text-right">{ item.begining_quantity }</td>
                         <td className="text-right"><NumberFormat value={Helper.parseMoney(item.begining_amount)} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">{ item.import_quantity }</td>
                         <td className="text-right"><NumberFormat value={Helper.parseMoney(item.import_amount)} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">{ item.export_quantity }</td>
                         <td className="text-right"><NumberFormat value={Helper.parseMoney(item.export_amount)} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">{ item.ending_quantity }</td>
                         <td className="text-right"><NumberFormat value={Helper.parseMoney(item.ending_amount)} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    handlePageChange(pageNumber){
        this.props.onChangePage(pageNumber)
    }
    render(){
        let { list, from_date, to_date, branch_selected } = this.props;
        let list_report = []
        if (list !== null){
            if(list.list !== undefined && list.list !== null){
                list_report = list.list
            }
        }
        let { page, total_page, total_record, limit } = list
        let branch_selected_name = ""
        if(branch_selected.name !== undefined && branch_selected.name !== null && branch_selected.name !== ""){
            branch_selected_name = branch_selected.name
        }
        let date_filter_text = (<span>Trong ngày  { Helper.convertMysqlDate(from_date) }</span>)
        if (to_date !== ""){
            date_filter_text = (<span>Từ ngày  { Helper.convertMysqlDate(from_date) }  đến ngày {Helper.convertMysqlDate(to_date)}</span>)
        }
        let _pagination;
        if(total_record > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="normal-table-list"> 
                            <div className="basic-tb-hd text-center">
                                <h2>
                                    Báo cáo hàng hóa xuất nhập tồn
                                </h2>
                                <p>
                                    {date_filter_text}<br />
                                    { branch_selected_name !== "" ? <span>Chi nhánh: { branch_selected_name }</span> : "" }
                                </p>
                            </div>
                                { _pagination }
                            <div className="bsc-tbl">
                                <table className="table table-bordered table-hover table-condensed">
                                    <colgroup>
                                        <col width="8%"></col>
                                        <col></col>
                                        <col width="7%"></col>
                                        <col width="8%"></col>
                                        <col width="7%"></col>
                                        <col width="8%"></col>
                                        <col width="7%"></col>
                                        <col width="8%"></col>
                                        <col width="7%"></col>
                                        <col width="8%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className="text-middle">Mã hàng</th>
                                            <th className="text-middle">Tên hàng</th>
                                            <th className="text-right text-middle">Tồn đầu</th>
                                            <th className="text-right text-middle">Giá trị đầu</th>
                                            <th className="text-right text-middle">SL nhập</th>
                                            <th className="text-right text-middle">Giá trị nhập</th>
                                            <th className="text-right text-middle">SL xuất</th>
                                            <th className="text-right text-middle">Giá trị xuất</th>
                                            <th className="text-right text-middle">Tồn cuối</th>
                                            <th className="text-right text-middle">Giá trị cuối</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list_report) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReportImportExport;