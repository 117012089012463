import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import Helper from '../../utils/Helper';

class ReportProfitByChart extends Component {
    onItemClick = (from_date,to_date) => {
        this.props.onView(from_date, to_date, true)
    }
    getElementAtEvent = element => {
        if (!element.length) return
        const { _index: index } = element[0]
        this.props.list.forEach((item, itemIndex) => {
            if (itemIndex === index && item.has_details !== undefined && +item.has_details === 1){
                this.props.onView(item.from_date, item.to_date, true)
            }
        })
    }
    render(){
        let { list } = this.props;
        var labels = [], amount = [], retail = [], profit = []
        if(list.length > 0){
            list.forEach(item => {
                labels.push(item.name)
                amount.push(item.total_amount_after)
                retail.push(item.total_retail)
                profit.push(item.total_profit)
            })
        }
        const data = {
            labels: labels,
            datasets: [
              {
                label: 'Lợi nhuận',
                data: profit,
                backgroundColor: 'rgb(255, 99, 132)',
              },
              {
                label: 'Doanh thu',
                data: amount,
                backgroundColor: 'rgb(54, 162, 235)',
              },
              {
                label: 'Giá vốn',
                data: retail,
                backgroundColor: 'rgb(75, 192, 192)',
              },
            ],
          }
          
          const options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += Helper.parseMoneyText(tooltipItem.yLabel);
                        return label;
                    }
                }
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                        let value_return = 0
                        if(value > 0){
                            if (value >= 1000000000) {
                                value_return = (value / 1000000000) + "tỷ"
                            } else if (value >= 1000000) {
                                value_return = (value / 1000000) + "tr"
                            } else{
                                value_return = (value / 1000) + "K"
                            }
                        }
                        return value_return
                    }
                  },
                },
              ],
            },
          }

        return (
            <Bar data={data} options={options} getElementAtEvent={(element) => { this.getElementAtEvent(element) }} />
        );
    }
}
export default ReportProfitByChart;