import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import { DatePicker, InputNumber, Alert, Input, Icon } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as ReceiptGoodAction from '../../actions/ReceiptGoodAction';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import Helper from '../../utils/Helper';
import UserSelectBox from '../shared/UserSelectBox';
import MyPagination from '../shared/Pagination';
import Moment from 'react-moment';
import Swal from 'sweetalert2';

class CreateReceiptGood extends Component {
    componentDidMount(){
        this.props.onEmptyInfo();  
        this.props.onEmptyInvoiceInfo();
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("receipt_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onNumberFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
        this.props.onSumTotal();  
    }
    onQuantityReturnItemFieldChange = (key,value) => {
        console.log("key: ",key)
        console.log("value: ",value)
        this.props.onUpdateProductItem("quantity_return_selected", key, value);
    }
    onUserFieldChange(item){
        let _this = this
        _this.props.onInvoiceFieldChange("search_customer_selected", item);
    }
    onSearchInvoice(){
        let data_search = this.getDataSearchInvoice()
        if((data_search.customer_id !== undefined && data_search.customer_id > 0) || (data_search.invoice_code && data_search.invoice_code !== "") || (data_search.product_keyword && data_search.product_keyword !== "")){
            let search_status_default = [InvoiceActionType.STATUS_IN_PROGRESS_INVOICE, InvoiceActionType.STATUS_FINISH_INVOICE]
            let _this = this;
            setTimeout(function(){
                _this.props.onSearchInvoices({
                    "code": data_search.invoice_code,
                    "customer_id": data_search.customer_id,
                    "product_keyword": data_search.product_keyword,
                    "receipt_status": search_status_default.join(",")
                },_this.props.invoice.page,5, false);
            },250);
        }
    }
    getDataSearchInvoice(){
        let customer_id = 0
        let invoice_code = ""
        let product_keyword = ""
        if(this.props.invoice.search_customer_selected !== undefined && this.props.invoice.search_customer_selected !== null && this.props.invoice.search_customer_selected.value > 0){
            customer_id = this.props.invoice.search_customer_selected.value
        }
        if(this.props.invoice.search_code !== undefined && this.props.invoice.search_code !== null && this.props.invoice.search_code !== ""){
            invoice_code = this.props.invoice.search_code
        }
        if(this.props.invoice.search_product !== undefined && this.props.invoice.search_product !== null && this.props.invoice.search_product !== ""){
            product_keyword = this.props.invoice.search_product
        }
        return {
            customer_id,
            invoice_code,
            product_keyword
        }
    }
    onSubmit(){
        let {products, receipt_bill_id, receipt_date, receipt_notes } = this.props.receipt_good
        if (receipt_bill_id === 0){
            Alert.error('Vui lòng chọn hóa đơn bán hàng')
            return;
        }
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        let valid = false
        for(let i = 0; i < products.length; i++){
            let product_item = products[i]
            if(product_item.quantity_return_selected > 0){
                valid = true
                break
            }
        }
        if(valid === false){
            Alert.error('Vui lòng nhập số lượng hàng hóa muốn trả')
            return;
        }
        if (receipt_date === ""){
            receipt_date = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        let products_return = []
        products.forEach(itemSelected => {
            if (itemSelected.quantity_return_selected > 0){
                products_return.push({
                    "product_id": itemSelected.id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_name": itemSelected.name,
                    "product_model": itemSelected.model,
                    "product_sku": itemSelected.sku,
                    "retail": itemSelected.retail,
                    "price": itemSelected.price,
                    "quantity": itemSelected.quantity_return_selected,
                })
            }
        })
        let data_post = {
            receipt_bill_id,
            receipt_date,
            receipt_notes,
            products: products_return
        }
        //console.log(data_post);
        this.props.onSubmit(data_post).then(result => {
            if(result.status === true){
                this.props.history.push("/receipt-good/" + result.id)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    showListProductReturn(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let total_amount = item.price * item.quantity_return_selected
                 let key = item.id + '_' + item.product_option_id
                 return (
                     <tr key={ index }>
                         <td className="text-center"><img src={item.url} alt="" /></td>
                         <td>{ item.model }</td>
                         <td>{gift_text}{ item.name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td>
                             { item.quantity > 0 ?
                            <div className="nk-int-st nk-toggled">
                                <InputNumber className="text-right" value={item.quantity_return_selected} max={item.quantity} min={0} onChange={(value) => { this.onQuantityReturnItemFieldChange(key,value) }} />
                            </div> : "" }
                         </td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onInvoiceItemClick(id){
        this.props.onGetInvoice(id).then(result => {
            let product_returns = []
            let total_product_origin = result.products.length, total_quantity_origin = 0

            let discount_amount = result.discount_money
            if (result.discount > 0){
                discount_amount = result.total_amount * result.discount / 100
            }
            let discount_receipt = 0
            if(discount_amount > 0){
              let total_discount_origin = discount_amount
              result.products.forEach(itemSelected => {
                total_quantity_origin += itemSelected.quantity
              })
              if(total_quantity_origin > 0 && discount_amount > 0){
                discount_receipt = total_discount_origin / total_quantity_origin
              } 
            }
            result.products.forEach(itemSelected => {
                let key = itemSelected.id + '_' + itemSelected.product_option_id
                let price = itemSelected.price
                discount_amount = itemSelected.discount_money
                if (itemSelected.discount > 0){
                    discount_amount = price * itemSelected.discount / 100
                }
                price = price - discount_amount - discount_receipt
                let remainQuantity = itemSelected.quantity - itemSelected.quantity_return
                let has_return = true
                if (!(remainQuantity > 0)){
                  has_return = false
                }
                product_returns.unshift({
                    "key": key,
                    "id": itemSelected.id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_option_name": itemSelected.product_option_name,
                    "url": itemSelected.product_url,
                    "name": itemSelected.product_name,
                    "model": itemSelected.product_model,
                    "sku": itemSelected.product_sku,
                    "has_return": has_return,
                    "price": price, 
                    "retail": itemSelected.retail,
                    "quantity": remainQuantity,
                    "quantity_return_selected": 0,
                    "total_amount_return": 0,
                    "categories": itemSelected.categories,
                    "category_id": itemSelected.category_id,
                    "category_name": itemSelected.category_name,
                    "brand_id": itemSelected.brand_id,
                    "brand_name": itemSelected.brand_name
                })  
              })
              let _this = this
              _this.props.onFieldsChange({
                products: [],
                show_modal: false
              })
            setTimeout(() => {
                _this.props.onFieldsChange({
                    receipt_bill_id: result.id,
                    receipt_bill_code: result.receipt_bill_code,
                    customer_selected: {
                        id: result.customer_id,
                        code: result.customer_code,
                        name: result.customer_name,
                        phone: result.customer_phone,
                        value: result.customer_id,
                        label: result.customer_code + " - " + result.customer_name,
                    },
                    total_quantity: 0,
                    total_product: total_product_origin,
                    total_amount: 0,
                    products: product_returns
                })
            }, 250);
        })
    }
    showListInvoices(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr key={index} className="cl_handover" onClick={ () => { this.onInvoiceItemClick(item.id) }}>
                         <td>{item.receipt_bill_code}</td>
                         <td>
                            <Moment format="DD/MM/YYYY HH:mm">
                            { Helper.replaceMysqlDateTime(item.receipt_bill_date) }
                            </Moment>
                         </td>
                         <td>{ item.customer_name }</td>
                         <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-center"><label className='label' style={{backgroundColor: item.receipt_bill_status_color }}>{ item.receipt_bill_status_text }</label></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearchInvoice();
        this.props.onInvoiceFieldChange("page", pageNumber)
        this.props.onSearchInvoice(data_search);
    }
    handleCloseModal = () => {
        this.props.onFieldChange("show_modal", false)
        if (this.props.receipt_good.products.length === 0){
            this.props.history.push("/receipt-goods")
        }
    }
    handleOpenModal = () => {
        this.props.onFieldChange("show_modal", true)
    }
    render(){
        let {products, total_quantity, total_product, total_amount, receipt_notes, receipt_notes_active, save_button_loading, customer_selected, show_modal } = this.props.receipt_good;
        
        let notes_field_class = "nk-int-st"
        if (receipt_notes !== "") {
            receipt_notes_active = true;
        }
        if(receipt_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let showButtonChange = false
        let showHideClassName = products.length === 0 ? "modal display-block" : "modal display-none";
        if (products.length > 0){
            if (show_modal === true){
                showHideClassName = "modal display-block"
            } else{
                showHideClassName = "modal display-none"
            }
            showButtonChange = true
        }
        let customer_text = ""
        if(customer_selected !== undefined && customer_selected.code !== undefined){
            customer_text = customer_selected.code + " - " + customer_selected.name
        }
        let _pagination;
        if(this.props.invoice.total > this.props.invoice.limit){
            _pagination = <MyPagination page={this.props.invoice.page} total_page={this.props.invoice.total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Nhận hàng khách trả</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/receipt-good/new"} />
                </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="normal-table-list">
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-condensed cl_list_product">
                                    <colgroup>
                                        <col width="5%"></col>
                                        <col width="15%"></col>
                                        <col></col>
                                        <col width="7%"></col>
                                        <col width="7%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Mã hàng</th>
                                            <th>Tên hàng</th>
                                            <th className="text-right">SL</th>
                                            <th className="text-right">SL trả</th>
                                            <th className="text-right">Giá bán</th>
                                            <th className="text-right">Thành tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListProductReturn(products) }
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <h5>Phiếu nhận hàng khách trả</h5>
                            </div>    
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker appearance="subtle" format="YYYY-MM-DD HH:mm" placeholder={moment().format("YYYY-MM-DD HH:mm")} placement="leftStart" oneTap ranges={[]} block 
                                    onChange = { (value) => { this.onDatePickerChange(value) } } />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="nk-int-mk mr-bot-5">
                                <label className="pull-left">Khách hàng</label> {showButtonChange === true ? <Icon className="display-block pull-left cl_handover mr-left-5 " icon={"edit"} size="lg" onClick={ (e) => { this.handleOpenModal() } } /> : "" }
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                { customer_text }
                            </div>
                            <div className="nk-int-mk mr-bot-5">
                                <label>Hóa đơn bán hàng</label>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <Link to={"/invoice/" + this.props.receipt_good.receipt_bill_id}>{ this.props.receipt_good.receipt_bill_code }</Link>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="receipt_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={receipt_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Tổng mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng tiền (<NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_quantity} />)
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_amount} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;LƯU THÔNG TIN PHIẾU
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className={showHideClassName}>
                    <div className="modal-dialog modal-percent">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Tìm hóa đơn bán hàng</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.handleCloseModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-xs-3 col-xs-offset-1">
                                            <UserSelectBox user_selected={ this.props.invoice.search_customer_selected } onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                                        </div>
                                        <div className="col-xs-3">
                                            <Input placeholder="Nhập mã hóa đơn bán hàng" onChange={(value) => { this.props.onInvoiceFieldChange("search_code",value) }} value={this.props.invoice.search_code} />
                                        </div>
                                        <div className="col-xs-3">
                                            <Input placeholder="Nhập mã hoặc tên hàng hóa" onChange={(value) => { this.props.onInvoiceFieldChange("search_product",value) }} value={this.props.invoice.search_product} />
                                        </div>
                                        <div className="col-xs-1">
                                            <LaddaButton
                                                loading={ this.props.invoice.search_button_loading }
                                                data-style={ZOOM_IN}
                                                data-spinner-color="#000000"
                                                className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearchInvoice() } } >
                                                <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                            </LaddaButton>
                                        </div>    
                                    </div>
                                </div>
                                <div className="form-group">
                                    <hr />
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex table-hover table-striped table-condensed">
                                            <colgroup>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Mã hóa đơn</th>
                                                    <th>Thời gian</th>
                                                    <th>Khách hàng</th>
                                                    <th className="text-right">Tổng tiền hàng</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            { this.showListInvoices(this.props.invoice.list) }
                                            </tbody>
                                        </table>
                                        { _pagination }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.handleCloseModal()}><i className="fa fa-ban"></i>&nbsp;Đóng</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        receipt_good: state.receipt_good,
        invoice: state.invoice,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(ReceiptGoodAction.emptyInfo())
        },
        onEmptyInvoiceInfo() {
            dispatch(InvoiceAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(ReceiptGoodAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(ReceiptGoodAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ReceiptGoodAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(ReceiptGoodAction.fieldsChange(fields))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(ReceiptGoodAction.updateProductItemRequest(input_name, key, value))
        },
        onSumTotal(){
            dispatch(ReceiptGoodAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(ReceiptGoodAction.addRequest(data_post))
        },
        onSearchInvoices: (data_search,page,limit) => {
            dispatch(InvoiceAction.searchRequest(data_search,page,limit,false))
        },
        onInvoiceFieldChange(field,value){
            dispatch(InvoiceAction.fieldChange(field,value))
        },
        onGetInvoice: (id) => {
            return dispatch(InvoiceAction.getRequest(id))
        },
    }
}
const CreateReceiptGoodConnect = (connect(mapStateToProps, mapDispatchToProps)(CreateReceiptGood));
export default withRouter(CreateReceiptGoodConnect);