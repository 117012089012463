import * as ReceiptGoodActionType from '../constants/ReceiptGoodActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: ReceiptGoodActionType.FOCUS_FIELD_RECEIPT_GOODS,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: ReceiptGoodActionType.ERROR_FIELD_RECEIPT_GOODS,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: ReceiptGoodActionType.FIELDS_CHANGE_RECEIPT_GOODS,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: ReceiptGoodActionType.FIELD_CHANGE_RECEIPT_GOODS,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: ReceiptGoodActionType.EMPTY_RECEIPT_GOODS
    }
}
export const resetFieldSearch = () => {
    return {
        type: ReceiptGoodActionType.RESET_FIELD_SEARCH_RECEIPT_GOODS
    }
}

export const getRequest = (id) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-return/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

const get = receipt_good => {
    return {
        type: ReceiptGoodActionType.GET_RECEIPT_GOODS,
        receipt_good,
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "receipt_return_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: ReceiptGoodActionType.GET_STAFF_NOTES_RECEIPT_GOODS,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "receipt_return_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: ReceiptGoodActionType.GET_LAST_STAFF_NOTES_RECEIPT_GOODS,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}


export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-return`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(ListReceiptReturn(res.data.result));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`receipt-return`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(ListReceiptReturn(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}
const ListReceiptReturn = (result) => {
    return {
        type: ReceiptGoodActionType.LIST_RECEIPT_GOODS,
        ...result
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: ReceiptGoodActionType.SEARCH_RECEIPT_GOODS,
        search_button_loading
    }
}
export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `receipt-return/${id}`;
        let _method = 'POST';
        if(button_name !== ""){
            dispatch(updateButtonSpinner(button_name,true))
        }
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                    dispatch(getStaffNotesRequest(id))
                if(button_name !== ""){
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: ReceiptGoodActionType.BUTTON_UPDATE_SPINNER_RECEIPT_GOODS,
        button_name,
        status
    }
}


export const cancelRequest = (id, reason_cancel) => {
    return () => {
        return apiCaller.callApi(`receipt-return/cancel/${id}`,{reason_cancel},'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}
export const buttonSpinner = status => {
    return {
        type: ReceiptGoodActionType.BUTTON_SPINNER_RECEIPT_GOODS,
        status
    }
}
export const sumTotal = () => {
    return {
        type: ReceiptGoodActionType.SUM_TOTAL_RECEIPT_GOODS
    }
}
export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`receipt-return/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (res.data.result !== undefined && res.data.result !== null){
                    dispatch(emptyInfo())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            }
        });
    }
}

export const updateProductItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        dispatch(updateProductItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, key, value) => {
    return {
        type: ReceiptGoodActionType.UPDATE_PRODUCT_RECEIPT_GOODS,
        input_name,
        key,
        value
    }
}