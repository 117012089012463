import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, TagPicker } from 'rsuite';
import * as PaymentBillAction from '../../actions/VendorBillAction';
import apiCaller from '../../utils/apiCaller';

class TagInput extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        clear: false,
        close: false,
        list: [],
        value: [],
        cacheData: [],
      };
      this.handleSearch = this.handleSearch.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.item_selected = {}
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.clear !== prevState.clear){
          return { clear: nextProps.clear};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.clear !== this.props.clear){
            this.setState({clear: this.props.clear});
            if (this.props.clear === true){
              this.setState({value: []});
              this.props.onFieldChange("clear_tag_input", false)
            }
        } 
    } 
    handleOpen(){
      this.setState({
        close: false
      });
    }
    handleSelect(value, item, event) {
      const { cacheData } = this.state;
      let cacheDataRemove = cacheData.filter(v => v.value !== item.value);
      cacheDataRemove.push(item);
      this.item_selected = item
      this.setState({
        cacheData: cacheDataRemove,
        close: true
      });
    }
    handleChange(value) {
      let final_value = [], final_item = {}
      if (this.props.limit > 0 && value !== null){
        final_value = []
        for(var index = 0; index < value.length; index++){
          final_value.push(value[index])
          if ((index + 1) >= this.props.limit){
            final_item = this.item_selected
            break
          }
        }
      } 
      this.setState({ value: final_value });
      if(final_item.value === undefined){
        this.setState({ close: false });
      }
      this.props.onChange(final_item)
    }
    handleSearch(word) {
      if (!word) {
        return;
      }
      this.setState({
        loading: true
      });
      this.getData(word)
    }
    getData = word => {
      var api = this.props.api
      var param = {}
      var method = "GET"
      if (api.param !== undefined && api.param !== null){
        param = api.param
      }
      param["keyword"] = word
      if (api.method !== undefined && api.method !== null && api.method !== ""){
        method = api.method
      }
      apiCaller.callApi(api.url, param, method).then(res => {
          if(res.status === 201 || res.status === 200){
              if (typeof res.data.result != 'undefined' && res.data.result !== null){
                  let { list } = res.data.result;
                  this.setState({
                    loading: false,
                    list
                  });
              }
          }
      });
    }
    render(){
        let data = []
        if (this.state.list.length > 0){
          this.state.list.forEach(item => {
            data.push({
              label: item.name,
              value: item.id
            })
          })
        }
        let hide_tag_input = ""
        if (this.state.close === true){
          hide_tag_input = "hide-tag-input"
        }
        return(
            <TagPicker
            menuClassName={hide_tag_input}
            style={{height: 36}}
                block
                data={data}
                cacheData={this.state.cacheData}
                value={this.state.value}
                creatable={this.props.creatable}
                placeholder={this.props.placeholder}
                cleanable={this.props.cleanable}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                onSelect={this.handleSelect}
                onOpen={this.handleOpen}
                renderMenu={menu => {
                    if (this.state.loading) {
                      return (
                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                          <Icon icon="spinner" spin /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
            />
        );
    }
}
TagInput.defaultProps = {
  limit: 0,
  creatable: false,
  cleanable: false,
  placeholder: "",
  clear: false
}
const mapStateToProps = state => {
    return {
        payment_bill: state.payment_bill,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(PaymentBillAction.fieldChange(field,value))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TagInput);       