import * as UploaderActionType from '../constants/UploaderActionType';
var initialState = {
  show_uploader: true
};
const uploader = (state = initialState, action) => {
    switch(action.type){
        case UploaderActionType.SHOW_UPLOADER:
          return {
            ...state,
            show_uploader: action.show
          };
        default: 
            return state;
    }
}
export default uploader;