import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as ReceiptOfGoodAction from '../../actions/ReceiptOfGoodAction';
import * as ReceiptOfGoodActionType from '../../constants/ReceiptOfGoodActionType';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
class ReceiptOfGoods extends Component {
    componentDidMount(){
        let _this = this
        let page = Helper.getQueryString('page');
        if(page === null){
            page = 1
        }
        _this.bindDataSearch()
        _this.props.onListStatus();
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            _this.props.onSearch(data_search,page,_this.props.receipt_of_good.limit, true);
        },250)
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let search_product = Helper.getQueryString('keyword');
        let status = Helper.getQueryString('status');
        let from_date = Helper.getQueryString('from_date');
        let to_date = Helper.getQueryString('to_date');
        let import_receipt_code = Helper.getQueryString('import_receipt');
        let transfer_store_code = Helper.getQueryString('transfer_store');
        var search_date = [];
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_date.push(from_date)
        }
        if(to_date !== undefined && to_date !== null && to_date !== ""){
            search_date.push(to_date)
        }
        var search_status = []
        if (status !== undefined && status !== null && status !== ""){
            let search_status_array = status.split(",")
            search_status_array.forEach(item => {
                search_status.push(parseInt(item))
            })
        }
        if (search_product === null){
            search_product = ""
        }
        if (search_code === null){
            search_code = ""
        }
        if (import_receipt_code === null){
            import_receipt_code = ""
        }
        if (transfer_store_code === null){
            transfer_store_code = ""
        }
        this.props.onFieldsChange({
            search_code, search_status, search_date, search_product, search_import_receipt_code: import_receipt_code, search_transfer_store_code: transfer_store_code
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _code = this.props.receipt_of_good.search_code;
        let _product = this.props.receipt_of_good.search_product;
        let _status = this.props.receipt_of_good.search_status;
        let _date = this.props.receipt_of_good.search_date;
        let _import_receipt_code = this.props.receipt_of_good.search_import_receipt_code;
        let _transfer_store_code = this.props.receipt_of_good.search_transfer_store_code;
        let product_id = Helper.getQueryString('product_id');
        let product_option_id_param = Helper.getQueryString('product_option_id');
        let product_option_id = 0
        if (product_option_id_param !== null){
            product_option_id = product_option_id_param
        }
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        var is_search = false
        var data_search = {
            "search": false,
            "code": _code,
            "product_keyword": _product,
            "param": []
        }
        if(product_id > 0){
            data_search["param"].push("product_id=" + product_id)
            is_search = true
        }
        if(product_option_id > 0){
            data_search["param"].push("product_option_id=" + product_option_id)
            is_search = true
        }
        if(_code !== ""){
            data_search["param"].push("code=" + _code)
            is_search = true
        }
        if(_import_receipt_code !== ""){
            data_search["import_receipt_code"] = _import_receipt_code
            data_search["param"].push("import_receipt=" + _import_receipt_code)
            is_search = true
        }
        if(_transfer_store_code !== ""){
            data_search["transfer_store_code"] = _transfer_store_code
            data_search["param"].push("transfer_store=" + _transfer_store_code)
            is_search = true
        }
        if(_product !== ""){
            data_search["param"].push("keyword=" + _product)
            is_search = true
        }
        if (_status.length > 0){
            data_search["receipt_status"] = _status.join(",")
            data_search["param"].push("status=" + _status.join(","))
            is_search = true
        }
        if (typeof _date[0] !== 'undefined'){
            data_search["from_date"] = _date[0];
            data_search["param"].push("from_date=" + _date[0])
            is_search = true
        }
        if (typeof _date[1] !== 'undefined'){
            data_search["to_date"] = _date[1];
            data_search["param"].push("to_date=" + _date[1])
            is_search = true
        }
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if(data_search.param.length > 0){
            let param = data_search.param.join("&")
            if(param !== ""){
                param = "?" + param
            }
            this.props.history.push("/receipt-of-goods" + param)
        }
        this.props.onSearch(data_search,1,this.props.receipt_of_good.limit, true);
    }
    onViewAll(){
        this.supplierSelectBoxRef.onClean()
        this.props.onResetFieldSearch();
        this.props.onListPurchaseOrders(1,this.props.receipt_of_good.limit);
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        let param = "?page=" + pageNumber
        if(data_search.param.length > 0){
            let param_search = data_search.param.join("&")
            if(param_search !== ""){
                param += "&" + param_search
            }
        }
        this.props.history.push("/receipt-of-goods" + param)
        this.props.onSearch(data_search,pageNumber,this.props.receipt_of_good.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/receipt-of-good/" + id);
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let status_text = ""
                if (item.receipt_status !== ReceiptOfGoodActionType.STATUS_IN_PROGRESS_RECEIPT_OF_GOOD){
                    status_text = <label className='label' style={{backgroundColor: item.receipt_status_color }}>{ item.receipt_status_text }</label>
                }
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td className="text-center">{item.receipt_code}</td>
                         <td>
                            <Moment format="DD/MM/YYYY HH:mm">
                            { Helper.replaceMysqlDateTime(item.receipt_date) }
                            </Moment>
                         </td>
                         <td>{ item.import_receipt_code } - { item.transfer_store_code }</td>
                         <td className="text-right"><NumberFormat value={item.total_product} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.total_quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">{ status_text }</td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, search_code, search_product, search_import_receipt_code, search_transfer_store_code, search_button_loading } = this.props.receipt_of_good;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="container">
            <Helmet>
                <title>Danh sách phiếu ghi nhận nhập hàng</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/receipt-of-goods"} />
            </Helmet>  
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter placeholder="Chọn ngày ghi nhận"  value={this.props.receipt_of_good.search_date}
                                    onFieldChange = { (value) => { this.onDatePickerChange(value) } }
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_import_receipt_code" value={ search_import_receipt_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu nhập hàng" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_transfer_store_code" value={ search_transfer_store_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu chuyển kho" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_product" value={ search_product } className="form-control" autoComplete="off" placeholder="Nhập mã hoặc tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        <div className="pull-left">Phiếu ghi nhận nhập hàng</div>
                                    </h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã phiếu</th>
                                            <th>Thời gian</th>
                                            <th>Phiếu nhập - chuyển hàng</th>
                                            <th className="text-right">Tổng mặt hàng</th>
                                            <th className="text-right">Tổng số lượng</th>
                                            <th className="text-right">Tổng tiền hàng</th>
                                            <th>Nhân viên</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        receipt_of_good: state.receipt_of_good
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFocusField(field,value){
            dispatch(ReceiptOfGoodAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ReceiptOfGoodAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(ReceiptOfGoodAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(ReceiptOfGoodAction.resetFieldSearch())
        },
        onListStatus: () => {
            dispatch(ReceiptOfGoodAction.listStatusRequest())
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(ReceiptOfGoodAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(ReceiptOfGoods)