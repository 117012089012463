import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import * as ReportActionType from '../../constants/ReportActionType';
import Helper from '../../utils/Helper';
import ReportProfitByChart from './ReportProfitByChart';
class ReportProfit extends Component {
    onBack = () => {
        this.props.onView(this.props.from_date, this.props.to_date, false)
    }
    onItemClick = (from_date,to_date) => {
        this.props.onView(from_date, to_date, true)
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            let allow_view_profit = false
            if (Helper.checkMyRole('view_profit') === true){
                allow_view_profit = true
            }
            result = lists.map((item, index) => {
                let name_text = item.name
                if (+item.has_details === 1){
                    name_text = <a href=" #" onClick={ () => { this.onItemClick(item.from_date, item.to_date) }}>{item.name}</a>
                } else if (item.receipt_bill_id !== undefined && item.receipt_bill_id > 0){
                    name_text = <Link to={"/invoice/" + item.receipt_bill_id} target="_blank">{item.name}</Link>
                }
                return (
                    <tr key={ index }>
                        <td>{ name_text }</td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right">{ item.total_discount > 0 ? <span>-<NumberFormat value={item.total_discount} displayType={'text'} thousandSeparator={true} /></span> : 0 }</td>
                        <td className="text-right">{ item.total_ship_fee_shop_paid > 0 ? <span>-<NumberFormat value={item.total_ship_fee_shop_paid} displayType={'text'} thousandSeparator={true} /></span> : 0 }</td>
                        <td className="text-right"><NumberFormat value={item.total_amount_after} displayType={'text'} thousandSeparator={true} /></td>
                        {allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.total_retail} displayType={'text'} thousandSeparator={true} /></td>: null }
                        { allow_view_profit === true ? <td className="text-right"><NumberFormat value={item.total_profit} displayType={'text'} thousandSeparator={true} /></td> : null }
                    </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { list, back, from_date, to_date, report_view, branch_selected } = this.props;
        let list_report = []
        if (list !== null){
            if(list.list !== undefined && list.list !== null){
                list_report = list.list
            }
        }
        let { total_amount, total_discount, total_ship_fee_shop_paid, total_amount_after, total_retail, total_profit } = list
        let branch_selected_name = ""
        if(branch_selected.name !== undefined && branch_selected.name !== null && branch_selected.name !== ""){
            branch_selected_name = branch_selected.name
        }
        let date_filter_text = (<span>Trong ngày  { Helper.convertMysqlDate(from_date) }</span>)
        if (to_date !== ""){
            date_filter_text = (<span>Từ ngày  { Helper.convertMysqlDate(from_date) } đến ngày {Helper.convertMysqlDate(to_date)}</span>)
        }
        let allow_view_profit = false
        if (Helper.checkMyRole('view_profit') === true){
            allow_view_profit = true
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="normal-table-list"> 
                            <div className="basic-tb-hd text-center">
                                <h2>
                                    Báo cáo lợi nhuận theo hóa đơn
                                </h2>
                                <p>
                                    {date_filter_text}<br />
                                    { branch_selected_name !== "" ? <span>Chi nhánh: { branch_selected_name }</span> : "" }
                                </p>
                                { back === true ? <p><a href=" #" onClick={ () => { this.onBack() }}>[Quay lại]</a></p> : ""}
                            </div>
                            <div className="bsc-tbl">
                                { report_view === ReportActionType.REPORT_VIEW_CHART ? <ReportProfitByChart list={list_report} onView={(from_date, to_date, back) => {  this.props.onView(from_date, to_date, back) } } />
                                : <table className="table table-bordered table-hover table-condensed">
                                    <colgroup>
                                        <col width="5%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        {allow_view_profit === true ? <col width="10%"></col> : null }
                                        { allow_view_profit === true ? <col width="10%"></col> : null }
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className="text-middle">Thời gian</th>
                                            <th className="text-right text-middle">Tổng tiền hàng</th>
                                            <th className="text-right text-middle">Giảm giá</th>
                                            <th className="text-right text-middle">PVC Moby trả</th>
                                            <th className="text-right text-middle">Doanh thu</th>
                                            {allow_view_profit === true ? <th className="text-right text-middle">Tổng giá vốn</th> : null } 
                                            { allow_view_profit === true ? <th className="text-right text-middle">Lợi nhuận</th> : null }
                                        </tr>
                                        <tr className="bg-warning">
                                            <th></th>
                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_discount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_ship_fee_shop_paid} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_amount_after} displayType={'text'} thousandSeparator={true} /></th>
                                            {allow_view_profit === true ? <th className="text-right"><NumberFormat value={total_retail} displayType={'text'} thousandSeparator={true} /></th> : null }
                                            { allow_view_profit === true ? <th className="text-right"><NumberFormat value={total_profit} displayType={'text'} thousandSeparator={true} /></th> : null }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list_report) }
                                    </tbody>
                                </table> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReportProfit;