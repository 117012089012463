import * as UserActionType from '../constants/UserActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    code: "",
    name: "",
    name_active: false,
    name_error: false,
    phone: "",
    phone_active: false,
    phone_error: false,
    email: "",
    email_active: false,
    password: "",
    password_active: false,
    password_error: false,
    confirm_password: "",
    confirm_password_active: false,
    confirm_password_error: false,
    address: "",
    address_active: false,
    address_error: false,
    city_selected: [],
    city_error: false,
    district_selected: [],
    district_error: false,
    ward_selected: [],
    birthday: "",
    birthday_active: false,
    tax_code: "",
    tax_code_active: false,
    gender: 0,
    facebook_url: "",
    facebook_url_active: false,
    note: "",
    note_active: false,
    is_company: 0,
    company: "",
    company_active: false,
    customer_group_selected: [],
    customer_groups: [],
    customer_group_id: 0,
    customer_group_name: "",
    position_id: 0,
    position_name: "",
    avatar: "",
    avatar_url: "",
    total_liabilities: 0,
    paid_liabilities: 0,
    remain_liabilities: 0,
    total_point: 0,
    remain_point: 0,
    used_point: 0,
    total_amount: 0,
    total_amount_return: 0,
    user_type: 0,
    branch_id: 0,
    is_stop_serving: 0,
    staff_position: [],
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    search_keyword: "",
    search_name: "",
    search_phone: "",
    search_email: "",
    selected: [],
    is_search: false,
    search_button_loading: false,
    save_button_loading: false,
    direct_to_list: false,
    return_info: null
};
const user = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case UserActionType.ITEM_BUTTON_SPINNER_USER:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case UserActionType.BUTTON_SPINNER_USER:
            return {
                ...state,
                save_button_loading: action.status
            };
        case UserActionType.FOCUS_FIELD_USER:
              return {
                ...state,
                [action.field]: action.value
              };
        case UserActionType.ERROR_FIELD_USER:
              return {
                ...state,
                [action.field]: action.value
              };
        case UserActionType.FIELD_CHANGE_USER:
              return {
                ...state,
                [action.field]: action.value
              };
        case UserActionType.RESET_FIELD_USER:
            return {
              ...state,
              id: 0,
              code: "",
              name: "",
              name_active: false,
              name_error: false,
              phone: "",
              phone_active: false,
              phone_error: false,
              email: "",
              email_active: false,
              password: "",
              password_active: false,
              address: "",
              address_active: false,
              address_error: false,
              city_selected: [],
              city_error: false,
              district_selected: [],
              district_error: false,
              ward_selected: [],
              birthday: "",
              birthday_active: false,
              tax_code: "",
              tax_code_active: false,
              gender: 0,
              facebook_url: "",
              facebook_url_active: false,
              note: "",
              note_active: false,
              is_company: 0,
              company: "",
              company_active: false,
              customer_group_selected: [],
              position_id: 0,
              position_name: "",
              customer_group_id: 0,
              customer_group_name: "",
              avatar: "",
              avatar_url: "",
              user_type: 0,
              is_stop_serving: 0
            };
        case UserActionType.RESET_DIRECT_USER:
            return {
              ...state,
              direct_to_list: false,
              return_info: null
            };
        case UserActionType.RESET_FIELD_SEARCH_USER:      
            return {
              ...state,
              search_keyword: "",
              search_name: "",
              search_phone: "",
              search_email: "",
              selected: [],
              search_button_loading: false,
              is_search: false,
            };
        case UserActionType.SET_USER:
            return {
              ...state,
              return_info: {
                id: action.user.id,
                code: action.user.code,
                name: action.user.name,
                phone: action.user.phone,
                email: action.user.email,
                address: action.user.address,
                city: action.user.city,
                district: action.user.district,
                ward: action.user.ward,
                birthday: action.user.birthday,
                tax_code: action.user.tax_code,
                gender: action.user.gender,
                is_company: action.user.is_company,
                company: action.user.company,
                note: action.user.note,
                is_stop_serving: action.user.is_stop_serving,
                user_type: action.user.user_type
              },
            };
        case UserActionType.SET_USER_TYPE:
            return {
              ...state,
              user_type: action.user_type
            };
        case UserActionType.GET_USER:
            return {
                ...state,
                id: action.user.id,
                code: action.user.code,
                name: action.user.name,
                phone: action.user.phone,
                email: action.user.email,
                address: action.user.address,
                city: action.user.city,
                city_name: action.user.city_name,
                city_selected: {
                  label: action.user.city_name,
                  value: action.user.city
                },
                district: action.user.district,
                district_name: action.user.district_name,
                district_selected: {
                  label: action.user.district_name,
                  value: action.user.district
                },
                ward: action.user.ward,
                ward_name: action.user.ward_name,
                ward_selected: {
                  label: action.user.ward_name,
                  value: action.user.ward
                },
                birthday: action.user.birthday,
                facebook_url: action.user.facebook_url,
                tax_code: action.user.tax_code,
                gender: action.user.gender,
                is_company: action.user.is_company,
                company: action.user.company,
                note: action.user.note,
                user_type: action.user.user_type,
                time: action.user.time,
                staff_id: action.user.staff_id,
                staff_name: action.user.staff_name,
                position_id: action.user.position_id,
                position_name: action.user.position_name,
                customer_group_id: action.user.customer_group_id,
                customer_group_name: action.user.customer_group_name,
                customer_group_selected: action.user.customer_group_id,
                total_liabilities: action.user.total_liabilities,
                paid_liabilities: action.user.paid_liabilities,
                remain_liabilities: action.user.remain_liabilities,
                total_point: action.user.total_point,
                remain_point: action.user.remain_point,
                used_point: action.user.used_point,
                is_stop_serving: action.user.is_stop_serving,
            };
        case UserActionType.LIST_USER:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                remain_liabilities: action.remain_liabilities,
                total_liabilities: action.total_liabilities,
                total_amount: action.total_amount,
                total_amount_return: action.total_amount_return,
                list: [...action.list]
              };
        case UserActionType.GET_STAFF_POSITION_USER:
            let staff_position_array = []
            Object.keys(action.list).map((k) => {
              staff_position_array.push({
                  label: action.list[k],
                  value: parseInt(k)
              })
              return true
            })
          return {  
              ...state,
              staff_position: staff_position_array
          }; 
        case UserActionType.RESET_LIST_USER:
          return {
            ...state,
              total: 0,
              limit: 10,
              total_page: 0,
              page: 1,
              list: [],
              selected: []
            };   
        case UserActionType.SEARCH_USER:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case UserActionType.LIST_USER_STATUS:
            return {
                ...state,
                import_receipt_status_select: action.status
              };   
        case UserActionType.LIST_CUSTOMER_GROUP:
            let customer_groups = []
            if (action.list.length > 0){
              action.list.forEach(item => {
                customer_groups.push({
                  label: item.name,
                  value: item.id
                })
              })
            }
            return {
                ...state,
                customer_groups,
              };   
        case UserActionType.DIRECT_TO_LIST_USER:
          return {
            ...state,
            direct_to_list: action.status
          };  
        default: 
            return state;
    }
}
export default user;