import React, { Component } from 'react';
import parser from 'html-react-parser';
import Helper from '../../utils/Helper';
import NumberFormat from 'react-number-format';

class ReportCAC extends Component {
    onShowHeader(dates){
        if(dates.length > 0){
            return (
                <tr>
                    <th></th>
                    {
                       dates.map((value,index) => {
                           return <th key={index} className="text-center text-middle">{parser(value.title)}</th>
                       })
                    }
                </tr>
            );   
        }
        return null;
    }
    onShowList(values){
        var result = null;
        if(values.length > 0){
            result = values.map((item, index) => {
                 let row_title = ""
                 switch(index){
                    case 0:
                        row_title = "Chi phí Marketing"
                        break
                    case 1:
                        row_title = "Chi phí bán hàng"
                        break
                    case 2:
                        row_title = "Tổng chi phí"
                        break
                    case 3:
                        row_title = "Số lượng khách"
                        break
                    default:
                        row_title = "CAC"
                        break
                 }
                 return (
                     <tr key={ index }>
                         <td className="text-bolded">{row_title}</td>
                         {
                            item.map((value,value_index) => {
                                value = Helper.parseMoney(value)
                                return <td key={value_index} className="text-right"><NumberFormat value={value} displayType={'text'} thousandSeparator={true} /></td>
                            })
                         }
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { list, from_date, to_date } = this.props;
        let date_filter = []
        if (from_date !== ""){
            date_filter.push(from_date)
        }
        if (to_date !== ""){
            date_filter.push(to_date)
        }
        let date_filter_text = (<span>Trong ngày  { Helper.convertMysqlDate(from_date) }</span>)
        if (to_date !== ""){
            date_filter_text = (<span>Từ ngày  { Helper.convertMysqlDate(from_date) }  đến ngày {Helper.convertMysqlDate(to_date)}</span>)
        }
        let date_header = [], date_header_text = [], data_expenses = [], marketing_expenses = [], sales_expenses = [], total_expenses = [], new_customer = [], cac = []
        if(list.length > 0){
            let is_in_year = true
            let year_save = ""
            list.forEach((item, index) => {
                let header_title = ""
                if(item.month_title !== ""){
                    header_title = Helper.getMonthName(item.month_title)
                    if(item.year_title !== ""){
                        header_title += "[YEAR]"
                        if(year_save === ""){
                            year_save = item.year_title
                        } else if (year_save !== "" && year_save !== item.year_title){
                            is_in_year = false
                        }
                        if (item.title !== ""){
                            if(index === 0){
                                header_title += "<br /> từ " + item.title
                            } else {
                                header_title += "<br /> đến " + item.title
                            }
                        }
                    }
                } else if (item.title !== ""){
                    header_title = item.title
                }
                date_header.push({
                    "title": header_title,
                    "year": item.year_title
                })
                marketing_expenses.push(item.marketing_expenses)
                sales_expenses.push(item.sales_expenses)
                total_expenses.push(item.total_expenses)
                new_customer.push(item.new_customer)
                cac.push(item.cac)
            })

            data_expenses = [marketing_expenses, sales_expenses, total_expenses, new_customer, cac]
            if(date_header.length > 0){
                for(let i = 0; i < date_header.length; i++){
                    if(date_header[i].year !== ""){
                        if(is_in_year === true){
                            if(i === 0 || i === date_header.length - 1){
                                date_header[i].title = date_header[i].title.replace("[YEAR]", "<br />" + date_header[i].year)
                            } else{
                                date_header[i].title = date_header[i].title.replace("[YEAR]", "")
                            }
                        } else {
                            date_header[i].title = date_header[i].title.replace("[YEAR]", "<br />" + date_header[i].year)
                        }
                        date_header_text.push(<col key={i} width="5%"></col>)
                    }
                }
            }
        }
        return (
            <div className="normal-table-list"> 
                <div className="basic-tb-hd text-center">
                    <h2>
                        Báo cáo chi phí chuyển đổi khách hàng (CAC)
                    </h2>
                    <p>
                        {date_filter_text}
                    </p>
                </div>
                <div className="bsc-tbl">
                    <table className="table table-bordered table-hover table-condensed">
                        <colgroup>
                            <col width="10%"></col>
                            {date_header_text}
                        </colgroup>
                        <thead>
                            { this.onShowHeader(date_header)}
                        </thead>
                        <tbody>
                            { this.onShowList(data_expenses)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
export default ReportCAC;