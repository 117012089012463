import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import { Alert } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import * as ReturnInvoiceAction from '../../actions/ReturnInvoiceAction';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';

class ViewReturnPurchaseOrder extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)
        }
    }
    onInitData(id){
        this.props.onGetReturnInvoice(id).then(result => {
            this.props.onGetStaffNotes(id)
            this.props.onGetLastStaffNote(id)
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onSaveData(){
        this.onAddNewNote()
    }
    onAddNewNote(){
        if(this.props.invoice.staff_notes_input === ""){
            this.props.onErrorField("staff_notes_error",true)
            this.notes_input.focus()
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng nhập ghi chú'
            })
            return;
        }
        this.props.onUpdateData({
            staff_notes: this.props.invoice.staff_notes_input
        }, this.props.invoice.id, "save_note_button_loading").then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
        this.notes_input.value = ""
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(this.props.return_invoice.id, {
                        reason_cancel
                    }).then(result => {
                        if(result.status === true){
                            this.props.onGetReturnInvoice(this.props.return_invoice.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            //console.log("result: ",result)
            //console.log("result.isConfirmed: ",result.isConfirmed)
            if (result.isConfirmed) {
                this.props.onGetReturnInvoice(this.props.return_invoice.id)
            }
        })
    }
    onProductItemClick(id){
        this.props.history.push("/product/" + id);
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let price_after = item.retail * item.quantity
                 let total_amount = price_after
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onProductItemClick(item.id) }}>
                         <td>{ item.product_model }</td>
                         <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.retail} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.invoice.id, id)
            },
        })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr key={ index }>
                         <td>{ item.content }</td>
                         <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton> 
                         </td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let { return_bill_code, return_bill_date, import_receipt_id, import_receipt_code, staff_notes, last_notes, last_notes_staff_name, last_notes_time, staff_notes_input, staff_notes_active, staff_notes_error, products, total_quantity, total_amount, is_cancel  , save_button_loading} = this.props.return_invoice;
        return_bill_date = Helper.replaceMysqlDateTime(return_bill_date)
        let notes_field_class = "nk-int-st"
        if (staff_notes_input !== "") {
            staff_notes_active = true;
        }
        if(staff_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        if(staff_notes_error === true){
            notes_field_class += ' error';
        }
        let show_tab_staff_note = true
        if (staff_notes !== null && staff_notes.length === 0){
            show_tab_staff_note = false
        }
        let allow_update = true, allow_update_cancel_status = false
        if (Helper.checkMyRole('update_return_invoice_cancel') === true){
            allow_update_cancel_status = true
        }
        let html_alert_bill_status = ""
        if(is_cancel === 1){
            allow_update_cancel_status = false
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.return_invoice.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.return_invoice.cancel_time }</Moment><br />
                        Lý do: { this.props.return_invoice.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Phiếu trả hàng { return_bill_code }</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/return-invoice/" + this.props.return_invoice.id} />
                </Helmet>   
                <div className="row">
                    {html_alert_bill_status}
                    <div className="col-xs-12">
                    <div className="pull-left">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                            { show_tab_staff_note === true ? 
                            <li>
                                <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                            </li> : "" }
                        </ul>
                    </div>
                    <div className="pull-right">
                        { allow_update === true ?
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-success" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton> : "" }
                        { allow_update_cancel_status === true ?
                        <button type="button"
                            className="btn btn-danger mr-left-5" onClick={ () => { this.onCancel() } } >
                            <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                        </button> : "" }
                    </div>
                    <div className="cl"></div>
                    <div className="tab-content tab-custom-st">
                        <div id="info" className="tab-pane fade active in">
                            <div className="form-element-list" >
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Mã phiếu:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <strong>{ return_bill_code }</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Mã phiếu nhập:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                <NavLink to={"/purchase-order/" + import_receipt_id} target="_blank">{ import_receipt_code }</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Thời gian:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    { moment(return_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Nhà cung cấp:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <NavLink to={"/supplier/" + this.props.return_invoice.customer_id} target="_blank">{ this.props.return_invoice.customer_name }</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Người trả hàng:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    {this.props.return_invoice.staff_name}    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Chi nhánh:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    {this.props.return_invoice.branch_name}    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            { last_notes ? 
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label className="hrzn-fm">Nhân viên ghi chú mới nhất:</label>
                                                </div>
                                                <div className="col-xs-12">
                                                {last_notes_staff_name} ({moment(last_notes_time * 1000).format("YYYY-MM-DD HH:mm").toString()}): {last_notes}
                                                </div>
                                                <div className="col-xs-12">
                                                    <hr />
                                                </div>
                                            </div>  : ""   }
                                            <div className="row mr-top-10">    
                                                <div className={notes_field_class}>
                                                    <TextareaAutoHeight placeholder="Thêm ghi chú" name={"staff_notes_input"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>    
                            <div className="normal-table-list mr-top-20">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-condensed">
                                        <colgroup>
                                            <col width="10%"></col>
                                            <col></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Mã hàng</th>
                                                <th>Tên hàng</th>
                                                <th className="text-right">Số lượng</th>
                                                <th className="text-right">Giá nhập</th>
                                                <th className="text-right">Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.showListProduct(products) }
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td className="text-right" colSpan="4">Tổng số lượng</td>
                                                <td className="text-right">{ total_quantity }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan="4">Tổng tiền hàng</td>
                                                <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>    
                        </div>
                        <div id="note" className="tab-pane fade bg-white padd-10">
                            { staff_notes.length > 0 ?
                            <table className="table table-sc-ex table-condensed w-auto">
                                <thead>
                                    <tr>
                                        <th>Nội dung</th>
                                        <th width="10%">Thời gian</th>
                                        <th width="10%">Người tạo</th>
                                        <th width="5%">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.showListStaffNotes(staff_notes) }
                                </tbody>
                            </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        return_invoice: state.return_invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo: () => {
            dispatch(ReturnInvoiceAction.emptyInfo())
        },
        onGetReturnInvoice: (id) => {
            return dispatch(ReturnInvoiceAction.getRequest(id))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(ReturnInvoiceAction.updateRequest(data_post, id, button_name))
        },
        onGetStaffNotes: id => {
            dispatch(ReturnInvoiceAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(ReturnInvoiceAction.getLastStaffNoteRequest(id))
        },
        onRemoveStaffNote: (id,staff_note_id) => {
            dispatch(ReturnInvoiceAction.removeStaffNoteRequest(id, staff_note_id))
        },
        onCancel: (id, data) => {
            return dispatch(ReturnInvoiceAction.cancelRequest(id, data))
        },
    }
}
const ViewReturnPurchaseOrderConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewReturnPurchaseOrder));
export default withRouter(ViewReturnPurchaseOrderConnect);