import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import { Alert } from 'rsuite';
import LaddaButton, { ZOOM_IN, L } from 'react-ladda';
import moment from 'moment';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Swal from 'sweetalert2/dist/sweetalert2';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as PaymentInvoiceAction from '../../actions/PaymentInvoiceAction';
import * as PaymentInvoiceActionType from '../../constants/PaymentInvoiceActionType';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';

class ViewPaymentInvoice extends Component {
    constructor(props){
        super(props)
        this.state = {
            receipt_bill_status: 0,
            return_receipt_bill_id: 0,
            return_receipt_bill_code: "",
            transporter_name: ""
        }
    }
    goBack = () => {
        let _this = this
        setTimeout(() => {
            _this.props.history.goBack()
        },250)
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.props.onGetPaymentInvoice(id).then(result => {
                if (result !== undefined && result.receipt_bill_id !== undefined){
                    this.props.onGetInvoice(result.receipt_bill_id).then(invoice_result => {
                        if (invoice_result !== undefined && invoice_result.products !== undefined){
                            this.props.onSetInvoiceProducts(invoice_result.products)
                            let transporter_name = ""
                            if(invoice_result.shipping_info !== undefined && invoice_result.shipping_info !== null && invoice_result.shipping_info.transporter_name !== ""){
                                transporter_name = invoice_result.shipping_info.transporter_name
                            }
                            this.setState({
                                receipt_bill_status: invoice_result.receipt_bill_status,
                                return_receipt_bill_id: invoice_result.return_receipt_bill_id,
                                return_receipt_bill_code: invoice_result.return_receipt_bill_code,
                                transporter_name
                            })
                        }
                    })
                }
            })
            this.props.onGetStaffNotes(id)
            this.props.onGetLastStaffNote(id)
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onAddNewNote(){
        if(this.props.payment_invoice.staff_notes_input === ""){
            this.props.onErrorField("staff_notes_error",true)
            this.notes_input.focus()
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng nhập ghi chú'
            })
            return;
        }
        this.props.onAddNewNote({
            staff_notes: this.props.payment_invoice.staff_notes_input
        }, this.props.payment_invoice.id).then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
        this.notes_input.value = ""
    }
    onSetBillStatus(status){
        if(status === PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE){
            Swal.fire({
                title: 'Xác nhận',
                text: "Bạn thật sự muốn hoàn thành hóa đơn thanh toán này?",
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                reverseButtons: true,
                confirmButtonText: 'ĐỒNG Ý',
                cancelButtonText: 'KHÔNG',
                preConfirm: () => {
                    this.props.onSetBillStatus(this.props.payment_invoice.id, {
                        status
                    }).then(result => {
                        if(result.status === true){
                            this.props.onGetPaymentInvoice(this.props.payment_invoice.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                },
            })
        } else if(status === PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE){
            Swal.fire({
                title: 'Vui lòng nhập lý do hủy',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Tiếp tục',
                showLoaderOnConfirm: true,
                preConfirm: (reason_cancel) => {
                    if(reason_cancel !== ""){
                        this.props.onSetBillStatus(this.props.payment_invoice.id, {
                            status,
                            reason_cancel
                        }).then(result => {
                            if(result.status === true){
                                this.props.onGetPaymentInvoice(this.props.payment_invoice.id)
                            }
                            else if(result.status === false && result.message !== undefined && result.message !== ""){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Thông báo lỗi',
                                    text: result.message
                                })
                            }
                        })
                    } else {
                        Swal.showValidationMessage('Vui lòng nhập lý do')
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
        }
    }
    onProductItemClick(id){
        this.props.history.push("/product/" + id);
    }
    showListProduct(lists){
        var result = null;
        if(lists !== undefined && lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let price_after = item.price * item.quantity
                 let discount_amount = item.discount_money
                 let discount_text = <NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} />
                 if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                    discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} /></span>
                 }
                 price_after = price_after - discount_amount
                 let total_amount = price_after
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onProductItemClick(item.id) }}>
                         <td className="text-center"><img src={item.product_url} alt="" /></td>
                         <td>{ item.product_model }</td>
                         <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">{ discount_text }</td>
                         <td className="text-right"><NumberFormat value={price_after} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.payment_invoice.id, id)
            },
        })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr key={ index }>
                         <td>{ item.content }</td>
                         <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">
                            { this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE ? 
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton> : "" }
                         </td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let {products, receipt_bill_code, payment_bill_date, payment_bill_code, payment_bill_notes, staff_notes, last_notes, last_notes_staff_name, last_notes_time, staff_notes_input, staff_notes_active, staff_notes_error, save_note_button_loading, amount, payment_bill_finance } = this.props.payment_invoice;
        payment_bill_date = Helper.replaceMysqlDateTime(payment_bill_date)
        
        let notes_field_class = "nk-int-st"
        if (staff_notes_input !== "") {
            staff_notes_active = true;
        }
        if(staff_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        if(staff_notes_error === true){
            notes_field_class += ' error';
        }
        let show_tab_staff_note = true
        let allow_update_finish_status = false, allow_add_new_note = true, allow_update_cancel_status = false
        if (Helper.checkMyRole('update_payment_invoice_finish') === true){
            allow_update_finish_status = true
        }
        if (Helper.checkMyRole('update_payment_invoice_cancel') === true){
            allow_update_cancel_status = true
        }
        if (this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE || this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE){
            allow_update_finish_status = false
            allow_update_cancel_status = false
            allow_add_new_note = false
            if (staff_notes.length === 0){
                show_tab_staff_note = false
            }
        }
        if (Helper.checkMyRole('update_payment_invoice_cancel_admin') === true && this.props.payment_invoice.payment_bill_status !== PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE){
            allow_update_cancel_status = true
        }
        /*if(this.props.payment_invoice.is_cod === true){
            allow_update_cancel_status = false
        }*/
        let html_alert_bill_status = ""
        if(this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE && this.props.payment_invoice.staff_finish_id > 0){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-success text-center">
                    <h3>{ this.props.payment_invoice.staff_finish_name } hoàn thành lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.payment_invoice.finish_time }</Moment></h3>
                </div>
            </div>  
        } else if(this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.payment_invoice.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.payment_invoice.cancel_time }</Moment><br />
                        Lý do: { this.props.payment_invoice.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        let payment_method_text = ""
        if (payment_bill_finance !== null && payment_bill_finance !== undefined && payment_bill_finance.length > 0){
            var financeItem = payment_bill_finance[0]
            if (financeItem.is_cash === 1){
                payment_method_text = "Tiền mặt"
            } else if (financeItem.is_cod === 1){
                payment_method_text = "Thu hộ (COD) - " + this.state.transporter_name
            } else if (financeItem.is_return_bill === 1){
                payment_method_text = "Tiền trả hàng"
            } else if (financeItem.is_bank === 1){
                payment_method_text = "Chuyển khoản - " + financeItem.finance_source_name
            } else if (financeItem.is_card === 1){
                payment_method_text = "Quẹt thẻ - " + financeItem.finance_source_name
            }
        }
        let receipt_bill_cancel_text = ""
        if (this.state.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
            receipt_bill_cancel_text = <span>&nbsp;<label className="label label-danger">Đã hủy</label></span>
        } else if (this.state.receipt_bill_status === InvoiceActionType.STATUS_RETURN_INVOICE){
            receipt_bill_cancel_text = <span>&nbsp;<label className="label label-warning">Đã trả hàng</label></span>
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Hóa đơn thanh toán { payment_bill_code } - {this.props.payment_invoice.partner_name} ({Helper.parseMoneyText(amount)})</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/accounting/invoice/" + this.props.payment_invoice.id} />
                </Helmet>   
                <div className="row">
                    { html_alert_bill_status }
                    <div className="col-xs-12">
                        <div className="pull-left">
                            <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                                <li>
                                    <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                                </li>
                                <li className="active">
                                    <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                                </li>
                                {show_tab_staff_note === true ? 
                                <li>
                                    <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                                </li> : "" } 
                            </ul>
                        </div>                   
                        <div className="pull-right">
                            { allow_update_finish_status === true ?
                            <button type="button"
                                className="btn btn-success mr-right-5" onClick={ () => { this.onSetBillStatus(PaymentInvoiceActionType.STATUS_FINISH_PAYMENT_INVOICE) } } >
                                <i className="fa fa-check"></i>&nbsp;Hoàn thành
                            </button> : "" }
                            { allow_update_cancel_status === true ?
                            <button type="button"
                                className="btn btn-danger" onClick={ () => { this.onSetBillStatus(PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE) } } >
                                <i className="fa fa-remove"></i>&nbsp;Hủy thanh toán
                            </button> : "" }
                        </div>
                        <div className="cl"></div>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in">
                            <div className="form-element-list" >
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Hóa đơn thanh toán:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <strong>{ payment_bill_code }</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Thời gian:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <span>{ moment(payment_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Hình thức thanh toán:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    {payment_method_text}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Số tiền:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} />
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Hóa đơn bán hàng:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <NavLink to={"/invoice/" + this.props.payment_invoice.receipt_bill_id} target="_blank">{ receipt_bill_code }</NavLink> { receipt_bill_cancel_text }
                                                </div>
                                            </div>
                                        </div>
                                        { this.state.return_receipt_bill_id > 0 ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Hóa đơn gốc:<br /> (trả hàng)</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <NavLink to={"/invoice/" + this.state.return_receipt_bill_id} target="_blank">{ this.state.return_receipt_bill_code }</NavLink>
                                                </div>
                                            </div>
                                        </div> : "" }
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Khách hàng:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <NavLink to={"/customer/" + this.props.payment_invoice.partner_id} target="_blank">{ this.props.payment_invoice.partner_code } - { this.props.payment_invoice.partner_name }</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Người bán:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <span>{this.props.payment_invoice.staff_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <label className="hrzn-fm">Chi nhánh:</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    {this.props.payment_invoice.branch_name}    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        { payment_bill_notes ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label className="hrzn-fm">Khách ghi chú:</label>
                                                </div>
                                                <div className="col-xs-12">
                                                    {payment_bill_notes}
                                                </div>
                                            </div>
                                        </div> : "" }
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            { last_notes ? 
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label className="hrzn-fm">Nhân viên ghi chú mới nhất:</label>
                                                </div>
                                                <div className="col-xs-12">
                                                {last_notes_staff_name} ({moment(last_notes_time * 1000).format("YYYY-MM-DD HH:mm").toString()}): {last_notes}
                                                </div>
                                                <div className="col-xs-12">
                                                    <hr />
                                                </div>
                                            </div>  : "" }
                                            { allow_add_new_note === true ?
                                            <div className="row mr-top-10">    
                                                <div className="col-xs-12">
                                                    <div className="pull-left w-90">
                                                        <div className={notes_field_class}>
                                                            <TextareaAutoHeight placeholder="Thêm ghi chú" name="staff_notes_input" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                                        </div>
                                                    </div>
                                                    <div className="pull-left mr-left-5">
                                                        <LaddaButton
                                                            loading={ save_note_button_loading }
                                                            data-size={L}
                                                            data-style={ZOOM_IN}
                                                            data-spinner-color="#000000"
                                                            className="btn btn-link" onClick={ () => { this.onAddNewNote() } } >
                                                            <i className="fa fa-save"></i>
                                                        </LaddaButton>
                                                    </div>
                                                    <div className="cl"></div>
                                                </div>
                                            </div> : "" }
                                        </div>
                                    </div>
                                </div>    
                            </div>    
                            <div className="normal-table-list mr-top-20">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-condensed">
                                        <colgroup>
                                            <col width="5%"></col>
                                            <col width="15%"></col>
                                            <col></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Mã hàng</th>
                                                <th>Tên hàng</th>
                                                <th className="text-right">Số lượng</th>
                                                <th className="text-right">Đơn giá</th>
                                                <th className="text-right">Giảm giá</th>
                                                <th className="text-right">Giá bán</th>
                                                <th className="text-right">Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.showListProduct(products) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>    
                        </div>
                            <div id="note" className="tab-pane fade bg-white padd-10">
                                { staff_notes.length > 0 ?
                                <table className="table table-sc-ex table-condensed w-auto">
                                    <thead>
                                        <tr>
                                            <th>Nội dung</th>
                                            <th width="10%">Thời gian</th>
                                            <th width="10%">Người tạo</th>
                                            { this.props.payment_invoice.payment_bill_status === PaymentInvoiceActionType.STATUS_NEW_PAYMENT_INVOICE ? <th width="5%">&nbsp;</th> : <th></th> }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListStaffNotes(staff_notes) }
                                    </tbody>
                                </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        payment_invoice: state.payment_invoice,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PaymentInvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PaymentInvoiceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PaymentInvoiceAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(PaymentInvoiceAction.emptyInfo())
        },
        onGetInvoice: (id) => {
            return dispatch(InvoiceAction.getRequest(id))
        },
        onSetInvoiceProducts: products => {
            dispatch(PaymentInvoiceAction.setInvoiceProducts(products))
        },
        onGetPaymentInvoice: (id) => {
            return dispatch(PaymentInvoiceAction.getRequest(id))
        },
        onGetBillStatus: () => {
            dispatch(PaymentInvoiceAction.getBillStatusRequest())
        },
        onSetBillStatus: (id, data) => {
            return dispatch(PaymentInvoiceAction.setBillStatus(id, data))
        },
        onAddNewNote: (data_post, id) => {
            return dispatch(PaymentInvoiceAction.addNewNoteRequest(data_post, id))
        },
        onGetStaffNotes: id => {
            dispatch(PaymentInvoiceAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(PaymentInvoiceAction.getLastStaffNoteRequest(id))
        },
        onRemoveStaffNote: (id,staff_note_id) => {
            dispatch(PaymentInvoiceAction.removeStaffNoteRequest(id, staff_note_id))
        },
    }
}
const ViewPaymentInvoiceConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewPaymentInvoice));
export default withRouter(ViewPaymentInvoiceConnect);