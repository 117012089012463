import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import apiCaller from '../../utils/apiCaller';
import * as InvoiceActionType from '../../constants/InvoiceActionType';

class InvoiceForCustomer extends Component {
    constructor() {
        super();
        this.state = {
            customer_id: 0,
            liabilities: false,
            list_invoice: [],
            total_invoice: 0,
            limit_invoice: 0,
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.customer_id!==prevState.customer_id){
          return { customer_id: nextProps.customer_id, liabilities: nextProps.liabilities};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.customer_id!==this.props.customer_id){
            this.setState({customer_id: this.props.customer_id, liabilities: this.props.liabilities});
            let receipt_status = InvoiceActionType.STATUS_IN_PROGRESS_INVOICE + "," + InvoiceActionType.STATUS_FINISH_INVOICE + "," + InvoiceActionType.STATUS_REORDER_INVOICE
            if (this.props.liabilities === true){
                receipt_status = InvoiceActionType.STATUS_IN_PROGRESS_INVOICE
            }
            //console.log("this.props.customer_id componentDidUpdate: ", this.props.customer_id)
            this.onSearchInvoices({
                customer_id: this.props.customer_id,
                receipt_status
            },1,this.props.limit);
        } 
    }
    componentDidMount(){
        //console.log("this.props.customer_id componentDidMount: ", this.props.customer_id)
        let receipt_status = InvoiceActionType.STATUS_IN_PROGRESS_INVOICE + "," + InvoiceActionType.STATUS_FINISH_INVOICE + "," + InvoiceActionType.STATUS_REORDER_INVOICE
        this.onSearchInvoices({
            customer_id: this.props.customer_id,
            receipt_status
        },1,this.props.limit);
    }
    onSearchInvoices(data_search, page, limit){
        apiCaller.callApi(`receipt-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit } = res.data.result;
                    this.setState({
                        list_invoice: list,
                        total_invoice: total,
                        limit_invoice: limit,
                    })
                }
            }
        });
    }
    onItemClick(id){
        window.open("/invoice/" + id, "_blank");
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let is_reorder_text = ""
                let receipt_bill_status_color = item.receipt_bill_status_color
                if (item.is_reorder === 1){
                    if(item.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
                        is_reorder_text = <span className="text-danger">Re-order<br /></span>
                    } else if (item.is_in_stock){
                        receipt_bill_status_color = "#3598fe"
                    }
                }
                 return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                        <td>{item.receipt_bill_code}</td>
                        <td>
                           <Moment format="DD/MM/YYYY">
                           { item.receipt_bill_date }
                           </Moment>
                        </td>
                        <td></td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_after_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_payed_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center">{is_reorder_text}<label className='label' style={{backgroundColor: receipt_bill_status_color }}>{ item.receipt_bill_status_text }</label></td>
                    </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total_invoice, limit_invoice, list_invoice } = this.state;
        //console.log("list render: ", list_invoice)
        let btn_view_more = ""
        if(total_invoice > limit_invoice){
            btn_view_more = (
                <div className="mr-top-10 text-center">
                    <Link to={"/invoices?customer_id=" + this.props.customer_id} className="btn btn-default btn-sm">Xem thêm</Link>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        { list_invoice.length > 0 ? 
                            <div>
                                <div className="normal-table-list">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex">
                                        <colgroup>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Mã hóa đơn</th>
                                                    <th>Thời gian</th>
                                                    <th>Mã trả hàng</th>
                                                    <th className="text-right">Tổng tiền hàng</th>
                                                    <th className="text-right">Giảm giá</th>
                                                    <th className="text-right">Tổng sau giảm</th>
                                                    <th className="text-right">Khách đã trả</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showList(list_invoice) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                { btn_view_more }
                            </div>
                         :  <div className="alert alert-warning">Không có thông tin nào</div>
                         }
                    </div>
                </div>
            </div>
        );
    }
}
 export default withRouter(InvoiceForCustomer)