import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink, Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { DatePicker, Alert, Icon, Whisper, Popover } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Helper from '../../utils/Helper';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import * as PurchaseOrderActionType from '../../constants/PurchaseOrderActionType';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as DiscountModeType from '../../constants/DiscountModeType';
import * as UserActionType from '../../constants/UserActionType';
import Moment from 'react-moment';
import DiscountInput from '../shared/DiscountInput';
import UserSelectBox from '../shared/UserSelectBox';
class ViewPurchaseOrder extends Component {
    constructor(props){
        super(props)

        this.expiry_info_ref = []
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.props.onGetData(id).then(result => {
                if (result !== undefined && result.products !== undefined && result.products !== null){
                    if(this.props.accounting === false && result.import_receipt_status !== PurchaseOrderActionType.STATUS_CANCEL_PURCHASE_ORDER){
                        let product_ids_array = []
                        result.products.forEach(function(item){
                            if (item.product_id > 0 && item.remain > 0){
                                product_ids_array.push({
                                    product_id: item.product_id,
                                    product_option_id: item.product_option_id
                                })
                            }
                        })
                        if (product_ids_array.length > 0){
                            var my_stores = Helper.getMyStores();
                            var store_id = parseInt(my_stores.store_id);
                            if(store_id > 0){
                                this.props.onGetBillReorder(store_id, product_ids_array)
                            }
                        }
                    }
                    this.props.onGetPaymentBills(id)
                    this.props.onGetStaffNotes(id)
                    this.props.onGetLastStaffNote(id)
                    this.props.onGetInvoices(id)
                    this.props.onGetTransferStores(id)
                } else {
                    this.props.history.push("/purchase-orders");
                }
            })
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onImportReceiptDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("import_receipt_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("supplier_selected", item);
    }
    onCreatePayment(){
        this.props.history.push("/accounting/payment-bill/new?object_id=" + this.props.purchase_order.supplier_id + '&receipt_id=' +this.props.purchase_order.id)
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(this.props.purchase_order.id, reason_cancel).then(result => {
                        if(result.status === true){
                            this.props.onGetData(this.props.purchase_order.id)
                            this.props.onGetInvoices(this.props.purchase_order.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    onSaveData(){
        let {discount, discount_mode} = this.props.purchase_order
        let data_post = {}
        let has_update = false
        if(this.props.purchase_order.import_receipt_notes !== ""){
            data_post.import_receipt_notes = this.props.purchase_order.import_receipt_notes
            has_update = true
        }
        if(this.props.accounting === true){
            if (this.props.purchase_order.total_payed_amount === 0){
                if(this.props.purchase_order.products.length === 0){
                    Alert.error('Không có hàng hóa trong phiếu nhập hàng')
                    return;
                }
                /*let valid = false
                for(let i = 0; i < this.props.purchase_order.products.length; i++){
                    let item = this.props.purchase_order.products[i]
                    if(item.price > 0){
                        valid = true
                        break
                    }  
                }
                if(valid === false){
                    Alert.error('Vui lòng nhập đơn giá cho hàng hóa')
                    return;
                }*/
                let product_retails = []
                this.props.purchase_order.products.forEach(item => {
                    let price_save = parseFloat(item.price_save)
                    let price = parseFloat(item.price)
                    let final_price = price
                    let discount_percent = 0, discount_money = 0
                    if(item.discount > 0){
                        if(item.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                            discount_percent = item.discount
                            final_price = final_price - (final_price * discount_percent / 100)
                        } else {
                            discount_money = item.discount
                            final_price = final_price - discount_money
                        }
                    }
                    if (!(price > 0)){
                        price = 0
                    }
                    if(price_save !== final_price){
                        product_retails.push({
                            product_id: item.product_id,
                            product_option_id: item.product_option_id,
                            quantity: item.total,
                            retail: price,
                            discount: parseFloat(discount_percent),
                            discount_money: parseFloat(discount_money),
                        })
                    }
                })
                if(product_retails.length > 0){
                    data_post.product_retails = product_retails
                }
                let discount_money = discount
                if (discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                    discount_money = 0
                } else{
                    discount = 0
                }
                if(discount_money === null){
                    discount_money = 0
                }
                data_post.discount = parseFloat(discount)
                data_post.discount_money = parseFloat(discount_money)
                has_update = true
            }
        } else {
            if(this.props.purchase_order.import_receipt_date !== ""){
                data_post.import_receipt_date = this.props.purchase_order.import_receipt_date
                has_update = true
            }
            if(this.props.purchase_order.supplier_selected.value !== undefined && this.props.purchase_order.supplier_selected.value > 0){
                data_post.supplier_id = this.props.purchase_order.supplier_selected.value
                data_post.supplier_code = this.props.purchase_order.supplier_selected.code  
                data_post.supplier_name = this.props.purchase_order.supplier_selected.label
                has_update = true
            }
        }
        if (has_update === true){
            this.props.onUpdateData(data_post, this.props.purchase_order.id, "save_date_button_loading").then(result => {
                if(result.status === true){
                    Alert.success("Thông tin phiếu nhập hàng đã lưu thành công")
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        } else{
            Swal.fire({
                icon: 'warning',
                title: 'Thông báo',
                text: "Không có thông tin nào cần cập nhật"
            })
        }
    }
    onDiscountFieldChange = (input_name, value, discount_mode) => {
        this.props.onFieldChange(input_name,value);
        this.props.onFieldChange("discount_mode",discount_mode);
        this.props.onSumTotal();  
    }
    onProductItemClick(id, parse_name){
        //this.props.history.push("/product/" + parse_name + "_" + id);
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price", key, values.value);
    }
    onDiscountItemFieldChange(key, value, discount_mode){
        this.props.onUpdateProductItem("discount_mode", key, discount_mode);
        this.props.onUpdateProductItem("discount", key, value);
    }
    onShowHideExpiryInfo(index, status){
        if(this.expiry_info_ref[index] !== undefined && this.expiry_info_ref[index] !== null){
            if(status === true){
                this.expiry_info_ref[index].current.open()
            } else {
                this.expiry_info_ref[index].current.close()
            }
        }
    }
    onShowHideEditExpiryInfo(key, value){
        this.props.onUpdateProductItem("edit_expiry_info", key, value);
    }
    onExpiryInfoDateChange(key, expiry_info_index, value){
        let expiry_date = null
        if (value !== undefined && value !== null){
            expiry_date = moment(value).format('YYYY-MM-DD')
        }
        this.props.onUpdateProductExpiryInfoItem("expiry_info_date", key, expiry_info_index, expiry_date)
    }
    onExpiryInfoQuantityChange(key, expiry_info_index, event){
        let target = event.target
        this.props.onUpdateProductExpiryInfoItem("expiry_info_quantity", key, expiry_info_index, target.value)
    }
    oSaveExpiryInfo(key){
        let index = Helper.findIndexByKey(this.props.purchase_order.products, key);
        if (index > -1){
            let product = this.props.purchase_order.products[index]
            if(product.expiry_info !== undefined && product.expiry_info !== null && product.expiry_info.length > 0){
                let total_remain = 0, expiry_info_data = []
                product.expiry_info.forEach(item => {
                    total_remain += item.remain_update
                    let expiry_date_update_array = Helper.replaceMysqlDateTime(item.expiry_date_update).split(" ")
                    let expiry_date_update = Helper.convertToMysqlDate(expiry_date_update_array[0])
                    expiry_info_data.push({
                        "id": item.id,
                        "expiry_date": expiry_date_update,
                        "expiry_quantity": item.remain_update,
                    })
                })
                if (total_remain !== product.remain){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Thông báo',
                        text: "Tổng số lượng bạn nhập không được khác với số lượng còn lại của hàng hóa"
                    })
                    return false
                }
                this.props.onUpdateProductItem("save_expiry_info", key, 1);
                this.props.onUpdateExpiryInfo({
                    "import_receipt_product_id": product.id,
                    "expiry_info": expiry_info_data
                }, this.props.purchase_order.id).then(result => {
                    if (result.status !== undefined && result.status === true){
                        this.props.onUpdateProductItem("expiry_info", key, expiry_info_data);
                    } else if (result.message !== undefined && result.message !== null && result.message !== ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Thông báo',
                            text: result.message
                        })
                    }
                })
            }
        } else{
            Swal.fire({
                icon: 'warning',
                title: 'Thông báo',
                text: "Không có thông tin hạn sử dụng nào cần cập nhật"
            })
        }
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            let allow_update_retail = false
            if (Helper.checkMyRole('update_import_retail') === true){
                allow_update_retail = true
            }
            if(this.props.purchase_order.total_payed_amount > 0){
                allow_update_retail = false
            }
            result = lists.map((item, index) => {
                //console.log("item: ",item)  
                this.expiry_info_ref[index] = React.createRef()
                let last_retail = item.retail
                let gift_text = ""
                if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                }
                let cancel_from_check_text = ""
                if(item.cancel_inventory_check_id > 0){
                    cancel_from_check_text = <Link to={"/inventory-check/" + item.cancel_inventory_check_id} className="label label-danger" target="_blank">Đã hủy - Xem kiểm kho</Link>
                }
                let retail_text = <NumberFormat value={Helper.parseMoney(item.retail)} displayType={'text'} thousandSeparator={true} />
                let total_amount = item.retail * item.total
                if (this.props.accounting === true  && allow_update_retail === true){
                    var retail_input = item.retail
                    if (!isNaN(item.price)){
                        retail_input = item.price
                    }
                    if (retail_input > 0){
                        last_retail = retail_input
                        total_amount = retail_input * item.total
                    } else {
                        retail_input = ""
                    }
                    retail_text = <div className="nk-int-st nk-toggled">
                                <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={retail_input} onValueChange={(values) => this.onPriceItemFieldChange(item.key,values)} />
                            </div>
                }
                let discount_text = "", discount_amount = 0
                if (this.props.accounting === true && allow_update_retail === true){
                    if (item.discount_value > 0){
                        discount_amount = item.discount_value
                    }
                    discount_text = <DiscountInput discount_input="discount_item" discount_value={discount_amount} main_discount={item.discount} discount_mode={item.discount_mode} fieldChange={(input_name,value,discount_mode) => {this.onDiscountItemFieldChange(item.key,value,discount_mode)}} />
                } else {
                    discount_amount = item.discount_money
                    if (item.discount > 0 && item.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                        discount_amount = last_retail * item.discount / 100
                        discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={Helper.parseMoney(discount_amount)} displayType={'text'} thousandSeparator={true} /></span>
                    }
                    discount_text = <NumberFormat value={Helper.parseMoney(discount_amount)} displayType={'text'} thousandSeparator={true} />
                }
                last_retail = last_retail - discount_amount
                let retail_final_text = <NumberFormat value={Helper.parseMoney(last_retail)} displayType={'text'} thousandSeparator={true} />
                total_amount = last_retail * item.total
                total_amount = Math.round(total_amount, -3)

                let has_expiry = false, expiry_info = [], edit_expiry_info = false, wide_expiry_info = { width: 200 }, save_expiry_info_button_loading = false
                if(item.expiry_info !== undefined && item.expiry_info !== null && item.expiry_info.length > 0){
                    has_expiry = true
                    item.expiry_info.forEach(expiry_info_item => {
                        if(expiry_info_item.expiry_date_update === undefined || (expiry_info_item.expiry_date_update !== undefined && expiry_info_item.expiry_date_update === null)){
                            expiry_info_item.expiry_date_update = expiry_info_item.expiry_date
                        }
                        if(expiry_info_item.remain_update === undefined || (expiry_info_item.remain_update !== undefined && expiry_info_item.remain_update === 0)){
                            expiry_info_item.remain_update = expiry_info_item.remain
                        }
                        expiry_info.push(expiry_info_item)
                    })
                    if(item.edit_expiry_info !== undefined && item.edit_expiry_info === 1){
                        edit_expiry_info = true
                        wide_expiry_info = { width: 400 }
                    }
                    if(item.save_expiry_info !== undefined && item.save_expiry_info === 1){
                        save_expiry_info_button_loading = true
                    }
                }

                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onProductItemClick(item.product_id,item.product_parse_name) }}>
                        <td className="text-center"><img src={item.product_url} alt="" /></td>
                        <td>{ item.product_model }</td>
                        <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' } { cancel_from_check_text !== "" ? <span><br />{cancel_from_check_text}</span> : "" }
                            {has_expiry === true ? 
                                <Whisper
                                    ref={this.expiry_info_ref[index]}
                                    placement="topStart"
                                    trigger="none"
                                    speaker={
                                        <Popover title="Hạn sử dụng" style={wide_expiry_info}>
                                            <div className="expiry_info__tool">
                                                { edit_expiry_info === false ?<Icon className="cl_handover expiry_info__tool__edit" icon='edit' size="lg" onClick={ () => { this.onShowHideEditExpiryInfo(item.key, 1) }} /> : null }
                                                <Icon className="cl_handover expiry_info__tool__close" icon='close' size="lg" onClick={ () => { this.onShowHideExpiryInfo(index, false) }} />
                                            </div>
                                            { expiry_info.map((expiry_info_item, expiry_info_index) => {
                                                return <div key={"expiry_info_"+index+"_"+expiry_info_index} className="form-group ic-cmp-int float-lb floating-lb">
                                                            { edit_expiry_info === true ?
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xs-12 mr-top-10">
                                                                    <DatePicker size="xs" placeholder="Chọn hạn sử dụng"  format="YYYY-MM-DD" appearance="subtle"  ranges={[]} cleanable={false} value={new Date(expiry_info_item.expiry_date_update)} onChange = { (value) => { this.onExpiryInfoDateChange(item.key, expiry_info_index, value) } } />
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12">
                                                                    <div className="nk-int-st nk-toggled">
                                                                        <input type="number" min="0" className="form-control text-right" autoComplete="off" onChange={ (e) => { this.onExpiryInfoQuantityChange(item.key, expiry_info_index, e) } } value={expiry_info_item.remain_update}></input>
                                                                    </div>    
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">
                                                                    <Moment format="DD/MM/YYYY">
                                                                        { Helper.replaceMysqlDateTime(expiry_info_item.expiry_date) }
                                                                    </Moment>
                                                                    </label>
                                                                </div>
                                                                <div className="text-right col-sm-8 col-xs-12">
                                                                    {expiry_info_item.remain}
                                                                </div>
                                                            </div> }
                                                        </div>
                                            }) }
                                            { edit_expiry_info === true ?
                                            <div className="form-group text-center">
                                                <button className="btn btn-danger mr-right-5" onClick={ () => this.onShowHideEditExpiryInfo(item.key, false) } >
                                                    <i className="fa fa-ban"></i>&nbsp;Bỏ qua
                                                </button>
                                                <LaddaButton
                                                    loading={ save_expiry_info_button_loading }
                                                    data-style={ZOOM_IN}
                                                    data-spinner-color="#000000"
                                                    className="btn btn-info" onClick={ () => this.oSaveExpiryInfo(item.key) } >
                                                    <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                                                </LaddaButton>
                                            </div> : null }
                                        </Popover>
                                    }
                                    >
                                        <button type="button" className="btn-link btn-sm" onClick={ () => { this.onShowHideExpiryInfo(index, true) }} >Xem hạn sử dụng</button>
                                </Whisper>
                            : null }
                        </td>
                        <td className="text-right"><NumberFormat value={item.total} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.return_quantity} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.used} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.remain} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right">{ retail_text }</td>
                        <td className="text-right">{ discount_text }</td>
                        <td className="text-right">{ retail_final_text }</td>
                        <td className="text-right"><NumberFormat value={Helper.parseMoney(total_amount)} displayType={'text'} thousandSeparator={true} />
</td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    showListPaymentBills(lists){
        var result = null;
        if(lists.length > 0){
            let accounting_access = false
            if (Helper.checkMyRole('accounting_access') === true){
                accounting_access = true
            }
            result = lists.map((item, index) => {
                return (
                <tr key={ index }>
                    <td>{ item.payment_bill_code }</td>
                    <td>{ moment(Helper.replaceMysqlDateTime(item.payment_bill_date), moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</td>
                    <td className="text-right"><NumberFormat value={item.amount - item.amount_return} displayType={'text'} thousandSeparator={true} /></td>
                    <td>{ item.staff_name }</td>
                    <td className="text-center">
                    { accounting_access === true ? <Link to={`/accounting/payment-bill/${item.id}`} target="_blank">Xem hóa đơn</Link> : "" }
                    </td>
                </tr>  
                );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.purchase_order.id, id)
            },
        })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                return (
                    <tr key={ index }>
                        <td>{ item.content }</td>
                        <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                        <td>{ item.staff_name }</td>
                        <td className="text-center">
                        <LaddaButton
                            loading={ item.loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                            <i className="fa fa-remove"></i>
                        </LaddaButton>
                        </td>
                    </tr>  
                );   
            });
        }
        return result;
    }
    onInvoiceItemClick(id){
        window.open("/invoice/" + id, "_blank")
    }
    showListInvoice(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let is_reorder_text = ""
                if (item.is_reorder === 1 && item.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
                    is_reorder_text = <span className="text-danger">Re-order<br /></span>
                }
                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onInvoiceItemClick(item.id) }}>
                        <td>{item.receipt_bill_code}</td>
                        <td>
                        <Moment format="DD/MM/YYYY HH:mm">
                        { Helper.replaceMysqlDateTime(item.receipt_bill_date) }
                        </Moment>
                        </td>
                        <td>{ item.customer_name }</td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_after_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_payed_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center">{is_reorder_text}<label className='label' style={{backgroundColor: item.receipt_bill_status_color }}>{ item.receipt_bill_status_text }</label></td>
                    </tr>
                );   
            });
        }
        return result;
    }
    onTransferStoreItemClick(id){
        window.open("/transfer-store/" + id, "_blank")
    }
    showListTransferStoreBills(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onTransferStoreItemClick(item.id) }}>
                        <td>{item.transfer_store_code}</td>
                        <td>
                        <Moment format="DD/MM/YYYY HH:mm">
                        { Helper.replaceMysqlDateTime(item.transfer_date) }
                        </Moment>
                        </td>
                        <td>{ item.to_store_name } { item.to_branch_name }</td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center"><label className='label' style={{backgroundColor: item.transfer_status_color }}>{ item.transfer_status_text }</label></td>
                    </tr>
                );   
            });
        }
        return result;
    }
    onReturnProduct(){
        this.props.history.push("/purchase-order/return?id=" + this.props.purchase_order.id);
    }
    render(){
        let { total_quantity, total_product, total_amount, total_amount_return_supplier, discount, total_payed_amount, products, payment_bills, bills_reorder, invoices, transfer_store_bills, staff_notes, last_notes, last_notes_staff_name, last_notes_time, import_receipt_notes, import_receipt_notes_active, import_receipt_notes_error, import_receipt_date, is_inventory_check, is_transfer_store, is_return_bill, save_button_loading} = this.props.purchase_order;
        import_receipt_date = Helper.replaceMysqlDateTime(import_receipt_date)
        
        total_amount = Helper.parseMoney(total_amount)
        let sub_total_amount = total_amount
        if(total_amount_return_supplier > 0){
            total_amount = total_amount - total_amount_return_supplier
        }
        let notes_field_class = "nk-int-st"
        if (import_receipt_notes !== "") {
            import_receipt_notes_active = true;
        }
        if(import_receipt_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        if(import_receipt_notes_error === true){
            notes_field_class += ' error';
        }   
        let hide_display_discount = "hide"
        let total_amount_after = total_amount, discount_value = 0
        let discount_text = "0"
        discount_value = discount
        if (this.props.purchase_order.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
            discount_value = total_amount_after * discount / 100
            discount_value = Helper.parseMoney(discount_value)
            total_amount_after = total_amount_after - discount_value
            discount_text = <span>({ discount }%) <NumberFormat value={discount_value} displayType={'text'} thousandSeparator={true} /></span>
        } else {
            total_amount_after = total_amount_after - discount_value
            discount_text = <NumberFormat value={discount_value} displayType={'text'} thousandSeparator={true} />
        }
        if (discount_value > 0) {
            hide_display_discount = ""
        }
        
        let remain_amount = total_amount_after - total_payed_amount
        if(isNaN(remain_amount)){
            remain_amount = 0
        }
        /*if (remain_amount < 0){
            remain_amount = 0
        }*/
        let allow_update = false, allow_update_date = false, allow_update_supplier = false, allow_return_supplier = false
        let allow_update_cancel_status = false
        let allow_update_staff_note = true
        let allow_create_payment = false, allow_update_retail = false
        if(Helper.checkMyRole('update_import_retail') === true ){
            allow_update_retail = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_import_cancel_status') === true){
            allow_update_cancel_status = true
        }
        if (Helper.checkMyRole('update_import_date') === true && this.props.accounting === false){
            allow_update_date = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_import_create_payment') === true){
            allow_create_payment = true
        }   
        if (Helper.checkMyRole('update_import_supplier') === true){
            allow_update_supplier = true
            allow_update = true
        } 
        if (Helper.checkMyRole('update_import_return_supplier') === true){
            allow_return_supplier = true
        } 
        if (is_inventory_check === 1 || is_transfer_store === 1 || is_return_bill === 1){
            remain_amount = 0
            total_amount_after = 0
            total_payed_amount = 0
            allow_update_cancel_status = false
            allow_update_date = false
            allow_update_staff_note = false
            allow_return_supplier = false
            allow_update_supplier = false
            allow_update_retail = false
        }
        if (allow_return_supplier === true){
            let has_product_return = false
            for(let i = 0; i < products.length; i++){
                if(products[i].remain > 0){
                    has_product_return = true
                    break
                }
            }
            allow_return_supplier = has_product_return
        }
        allow_return_supplier = false
        let html_alert_bill_status = ""
        if(this.props.purchase_order.import_receipt_status === PurchaseOrderActionType.STATUS_CANCEL_PURCHASE_ORDER){
            allow_update_cancel_status = false
            allow_update_date = false
            allow_create_payment = false
            allow_update_date = false
            allow_update_supplier = false
            allow_update_retail = false
            allow_update = false
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.purchase_order.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.purchase_order.cancel_time }</Moment><br />
                        Lý do: { this.props.purchase_order.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        if(!(this.props.purchase_order.liabilities_remain > 0)){
            allow_create_payment = false
        }
        let status_text = <span style={{ fontSize: 15, color: this.props.purchase_order.import_receipt_status_color }}>{this.props.purchase_order.import_receipt_status_text}</span>
        if(is_inventory_check === 1){
            allow_update_cancel_status = false
            allow_create_payment = false
            status_text = <span style={{ fontSize: 15 }} className="text-warning">Kiểm kho</span>
        } else if(is_transfer_store === 1){
            allow_create_payment = false
            status_text = <span style={{ fontSize: 15 }} className="text-warning">Chuyển kho</span>
        } else if(is_return_bill === 1){
            allow_update_cancel_status = false
            allow_create_payment = false
            status_text = <span style={{ fontSize: 15 }} className="text-warning">Nhập trả hàng</span>
        }
        if (allow_update === true && !(allow_update_retail === true || allow_update_date === true || allow_update_supplier === true)){
            allow_update = false
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Phiếu nhập hàng { this.props.purchase_order.import_receipt_code } - {this.props.purchase_order.supplier_name}</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/purchase-order/" + this.props.purchase_order.id} />
                </Helmet>   
                <div className="row">
                    { html_alert_bill_status }
                    <div className="col-xs-12">
                    <div className="pull-left">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                            </li>
                            <li className={ bills_reorder.length === 0 ? "active" : "" }>
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                            { payment_bills.length > 0 ?
                            <li>
                                <a data-toggle="tab" href="#payment" aria-expanded="false">Lịch sử thanh toán</a>
                            </li> : null }
                            <li>
                                <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                            </li>
                            { invoices.length > 0 ? 
                            <li>
                                <a data-toggle="tab" href="#invoice" aria-expanded="false">Hóa đơn bán hàng</a>
                            </li> : null }
                            { bills_reorder.length > 0 ?
                            <li className="active">
                                <a data-toggle="tab" href="#reorder" aria-expanded="false">Re-order</a>
                            </li> : null }
                            { transfer_store_bills.length > 0 ?
                            <li>
                                <a data-toggle="tab" href="#transfer_stores" aria-expanded="false">Chuyển kho</a>
                            </li> : null }
                        </ul>
                    </div>
                    <div className="pull-right">
                        { allow_create_payment === true ?
                        <button type="button"
                            className="btn btn-info mr-right-5" onClick={ () => { this.onCreatePayment() } } >
                            <i className="fa fa-dollar"></i>&nbsp;Thanh toán
                        </button> : "" }
                        { (allow_update_retail === true && this.props.accounting === false) ?
                            <a href={"/accounting/purchase-order/" + this.props.purchase_order.id} className="btn btn-default mr-right-5">
                                <i className="fa fa-edit"></i>&nbsp;Cập nhật giá
                            </a>
                        : "" }
                        { allow_return_supplier === true ?
                        <button type="button"
                            className="btn btn-default mr-right-5" onClick={ () => { this.onReturnProduct() } } >
                            <i className="fa fa-reply-all"></i>&nbsp;Trả hàng
                        </button> : "" }
                        { allow_update === true ?
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-success mr-right-5" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton> : "" }
                        { allow_update_cancel_status === true ?
                        <button type="button"
                            className="btn btn-danger" onClick={ () => { this.onCancel() } } >
                            <i className="fa fa-remove"></i>&nbsp;Hủy phiếu nhập
                        </button> : "" }
                    </div>
                    <div className="cl"></div>
                    <div className="tab-content tab-custom-st">
                        <div id="info" className={ bills_reorder.length === 0 ? "tab-pane fade active in bg-white" : "tab-pane fade in bg-white" }>
                            <div className="form-element-list" >
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Mã phiếu:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <strong>{ this.props.purchase_order.import_receipt_code }</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className={allow_update_date === true ? "col-lg-3 col-md-3 col-sm-3 col-xs-12 mr-top-7" : "col-lg-3 col-md-3 col-sm-3 col-xs-12"}>
                                                    <label className="hrzn-fm">Thời gian:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <div className="pull-left">
                                                    { allow_update_date === true ? 
                                                    <DatePicker format="YYYY-MM-DD HH:mm" value={new Date(moment(import_receipt_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())} placement="bottomStart" size="md" ranges={[]} onChange = { (value) => { this.onImportReceiptDatePickerChange(value) } } /> : <span>{ moment(import_receipt_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>  }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { this.props.purchase_order.supplier_id > 0 ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Nhà cung cấp:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <div style={{width: 207}}>
                                                        { allow_update_supplier === true ?
                                                        <UserSelectBox getRef={ref => { this.supplierSelectBoxRef = ref } } placeholder="Chọn nhà cung cấp" user_selected={this.props.purchase_order.supplier_selected} user_type={UserActionType.SUPPLIER_USER_TYPE} onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                                                        : 
                                                        <NavLink to={"/supplier/" + this.props.purchase_order.supplier_id} target="_blank">{ this.props.purchase_order.supplier_name }</NavLink>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null }
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Người tạo:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.purchase_order.staff_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        { is_inventory_check === 1 ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Phiếu kiểm kho</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <Link to={"/inventory-check/" + this.props.purchase_order.inventory_check_id.toString()} target="_blank">{this.props.purchase_order.inventory_check_code}</Link>
                                                </div>
                                            </div>
                                        </div> : null }
                                        { is_transfer_store === 1 ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Phiếu chuyển</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <Link to={"/transfer-store/" + this.props.purchase_order.transfer_store_id.toString()} target="_blank">{this.props.purchase_order.transfer_store_code}</Link>
                                                </div>
                                            </div>
                                        </div> : null }
                                        { is_inventory_check === 0 && is_transfer_store === 0 && is_return_bill === 0 ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Mã đặt hàng</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    { this.props.purchase_order.supplier_vendor_bill_code !== "" ?
                                                    <Link to={"/supplier-vendor-bill/" + this.props.purchase_order.supplier_vendor_bill_id.toString()} target="_blank">{this.props.purchase_order.supplier_vendor_bill_code}</Link>
                                                    : "" }
                                                </div>
                                            </div>
                                        </div> : null }
                                        { is_inventory_check === 0 ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Trạng thái:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    { status_text }
                                                </div>
                                            </div>
                                        </div> : null }
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Chi nhánh:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.purchase_order.branch_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        { allow_update_staff_note === true ? 
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            { last_notes ? 
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label className="hrzn-fm">Ghi chú mới nhất</label>
                                                </div>
                                                <div className="col-xs-12">
                                                {last_notes_staff_name} ({moment(last_notes_time * 1000).format("YYYY-MM-DD HH:mm").toString()}): {last_notes}
                                                </div>
                                                <div className="col-xs-12">
                                                    <hr />
                                                </div>
                                            </div>  : ""   }
                                            <div className="row mr-top-10">    
                                                <div className="col-xs-12">
                                                    <div className={notes_field_class}>
                                                        <TextareaAutoHeight placeholder="Thêm ghi chú" name={"import_receipt_notes"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null }
                                    </div>
                                </div>    
                            </div>    
                            <div className="normal-table-list mr-top-20">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-condensed">
                                        <colgroup>
                                            <col width="5%"></col>
                                            <col width="7%"></col>
                                            <col></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Mã hàng</th>
                                                <th>Tên hàng</th>
                                                <th className="text-right">Tổng nhập</th>
                                                <th className="text-right">Đã trả</th>
                                                <th className="text-right">Đã bán</th>
                                                <th className="text-right">Còn lại</th>
                                                <th className="text-right">Đơn giá</th>
                                                <th className="text-right">Giảm</th>
                                                <th className="text-right">Giá nhập</th>
                                                <th className="text-right">Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.showListProduct(products) }
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td className="text-right" colSpan="10">Tổng số lượng</td>
                                                <td className="text-right">{ total_quantity }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan="10">Tổng số mặt hàng</td>
                                                <td className="text-right">{ total_product }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan="10">Tổng tiền hàng</td>
                                                <td className="text-right"><NumberFormat value={sub_total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr>
                                            { total_amount_return_supplier > 0 ? 
                                            <tr>
                                                <td className="text-right" colSpan="10">Trả hàng</td>
                                                <td className="text-right"><NumberFormat value={total_amount_return_supplier} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr> : null }
                                            { total_amount_return_supplier > 0 ?
                                            <tr>
                                                <td className="text-right" colSpan="10">Tổng tiền hàng sau trả</td>
                                                <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr> : null }
                                            { this.props.accounting === true && total_payed_amount === 0 ?
                                            <tr>
                                                <td className="text-right" colSpan="10">Giảm giá</td>
                                                <td className="text-right">
                                                    <DiscountInput discount_input="discount" discount_value={discount_value} main_discount={discount} discount_mode={this.props.purchase_order.discount_mode} fieldChange={(input_name,value,discount_mode) => {this.onDiscountFieldChange(input_name,value,discount_mode)}} />    
                                                </td>
                                            </tr> : 
                                            <tr className={hide_display_discount}>
                                                <td className="text-right" colSpan="10">Giảm giá</td>
                                                <td className="text-right">{discount_text}</td>
                                            </tr> }
                                            { total_amount_after > 0 ? <tr>
                                                <td className="text-right" colSpan="10">Cần trả NCC</td>
                                                <td className="text-right"><NumberFormat value={total_amount_after} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr> : null }
                                            { total_payed_amount > 0 ? <tr>
                                                <td className="text-right" colSpan="10">Đã thanh toán</td>
                                                <td className="text-right"><NumberFormat value={total_payed_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr> : null }
                                            { remain_amount !== 0 ? <tr>
                                                <td className="text-right" colSpan="10">Công nợ</td>
                                                <td className="text-right"><NumberFormat value={remain_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr> : null }
                                        </tbody>
                                    </table>
                                </div>
                            </div>    
                        </div>
                        { payment_bills.length > 0 ?
                        <div id="payment" className="tab-pane fade bg-white padd-10">
                            <table className="table table-sc-ex table-hover table-condensed">
                                <colgroup>
                                    <col width="10%"></col>
                                    <col width="10%"></col>
                                    <col width="10%"></col>
                                    <col width="10%"></col>
                                    <col width="10%"></col>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>Mã phiếu</th>
                                        <th>Thời gian</th>
                                        <th className="text-right">Số tiền</th>
                                        <th>Người xử lý</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.showListPaymentBills(payment_bills) }
                                </tbody>
                            </table>
                        </div> : null }
                        <div id="note" className="tab-pane fade bg-white padd-10">
                            { staff_notes.length > 0 ?
                            <table className="table table-sc-ex table-hover table-condensed w-auto">
                                <thead>
                                    <tr>
                                        <th>Nội dung</th>
                                        <th width="10%">Thời gian</th>
                                        <th width="10%">Người tạo</th>
                                        <th width="5%">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.showListStaffNotes(staff_notes) }
                                </tbody>
                            </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                        </div>
                        { invoices.length > 0 ? 
                        <div id="invoice" className="tab-pane bg-white padd-10">
                            <div className="normal-table-list">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-striped table-condensed">
                                        <colgroup>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Mã hóa đơn</th>
                                                <th>Thời gian</th>
                                                <th>Khách hàng</th>
                                                <th className="text-right">Tổng tiền hàng</th>
                                                <th className="text-right">Giảm giá</th>
                                                <th className="text-right">Tổng sau giảm</th>
                                                <th className="text-right">Khách đã trả</th>
                                                <th className="text-center">Trạng thái</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { this.showListInvoice(invoices) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : "" } 
                        { bills_reorder.length > 0 ?
                        <div id="reorder" className="tab-pane active bg-white padd-10">
                            <div className="normal-table-list">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-condensed w-auto">
                                        <tbody>
                                            { bills_reorder.map(item => {
                                                let result = []
                                                let all_valid = false
                                                let total_product = item.products.length
                                                let total_valid = 0
                                                item.products.forEach(product_item => {
                                                    if (product_item.valid === true){
                                                        total_valid++
                                                    }
                                                })
                                                if (total_product === total_valid){
                                                    all_valid = true
                                                }
                                                result.push(
                                                    <tr key={item.receipt_bill_id}>
                                                        <th colSpan="4">
                                                            <span className={all_valid === true ? "reorder-all-valid text-success" : ""}>{item.receipt_bill_code} - <NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} />đ - {item.customer_name} - {item.customer_phone}</span>
                                                            <button className="btn btn-link btn-sm pull-right" onClick={() => this.onInvoiceItemClick(item.receipt_bill_id)}>Xem phiếu</button>
                                                        </th>
                                                    </tr>
                                                )
                                                item.products.forEach(product_item => {
                                                    result.push(
                                                        <tr key={item.receipt_bill_id + '_' + product_item.product_id}>
                                                            <td>{product_item.product_name}</td>
                                                            <td className="text-right">{product_item.quantity}/{product_item.remain}</td>
                                                            <td className="text-right"><NumberFormat value={product_item.price} displayType={'text'} thousandSeparator={true} />đ</td>
                                                            <td className="text-center">
                                                                { product_item.valid === true ? <Icon icon="ok-circle" size="2x" style={{ color: "#00c292" }}/> : "" }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                return result
                                            }) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : "" }   
                        { transfer_store_bills.length > 0 ? 
                        <div id="transfer_stores" className="tab-pane bg-white padd-10">
                            <div className="normal-table-list">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-striped table-condensed">
                                        <colgroup>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                            <col></col>
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Mã phiếu</th>
                                                <th>Thời gian</th>
                                                <th>Đến kho</th>
                                                <th className="text-right">Tổng tiền hàng</th>
                                                <th className="text-center">Trạng thái</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { this.showListTransferStoreBills(transfer_store_bills) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : "" }  
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
ViewPurchaseOrder.defaultProps = {
    accounting: false
}
const mapStateToProps = state => {
    return {
        purchase_order: state.purchase_order
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PurchaseOrderAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PurchaseOrderAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PurchaseOrderAction.fieldChange(field,value))
        },
        onEmptyInfo(){
            dispatch(PurchaseOrderAction.emptyInfo())
        },
        onGetData: id => {
            return dispatch(PurchaseOrderAction.getRequest(id))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(PurchaseOrderAction.updateRequest(data_post, id, button_name))
        },
        onGetPaymentBills: id => {
            dispatch(PurchaseOrderAction.getPaymentBillsRequest(id))
        },
        onGetStaffNotes: id => {
            dispatch(PurchaseOrderAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(PurchaseOrderAction.getLastStaffNoteRequest(id))
        },
        onRemoveStaffNote: (id, staff_note_id) => {
            dispatch(PurchaseOrderAction.removeStaffNoteRequest(id, staff_note_id))
        },
        onGetInvoices: id => {
            dispatch(PurchaseOrderAction.getInvoicesRequest(id))
        },
        onGetBillReorder: (store_id, product_ids) => {
            dispatch(PurchaseOrderAction.getBillReorderRequest(store_id, product_ids))
        },
        onGetTransferStores: id => {
            dispatch(PurchaseOrderAction.getTransferStoresRequest(id))
        },
        onCancel: (id, reason_cancel) => {
            return dispatch(PurchaseOrderAction.cancelRequest(id, reason_cancel))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(PurchaseOrderAction.updateProductItemRequest(input_name, key, value))
        },
        onUpdateProductExpiryInfoItem(input_name, key, expiry_info_index, value){
            dispatch(PurchaseOrderAction.updateProductExpiryInfoItemRequest(input_name, key, expiry_info_index, value))
        },
        onSumTotal(){
            dispatch(PurchaseOrderAction.sumTotal())
        },
        onUpdateExpiryInfo: (data_post, id) => {
            return dispatch(PurchaseOrderAction.updateRequest(data_post, id, ""))
        },
    }
}
const ViewPurchaseOrderConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewPurchaseOrder));
export default withRouter(ViewPurchaseOrderConnect);