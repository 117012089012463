import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Moment from 'react-moment';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as BillLadingActionType from '../../constants/BillLadingActionType';
import Helper from '../../utils/Helper';
import ShipInvoiceHeader from './ShipInvoiceHeader';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import Swal from 'sweetalert2';
const search_status_default = [BillLadingActionType.STATUS_NEW_SHIP_BILL_LADINGS, BillLadingActionType.STATUS_SHIP_PENDING_BILL_LADINGS]

class ShipInvoices extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: 0,
            show_camera: false,
            stop_stream_camera: false,
            receipt_bill_code: ""
        }
        this.timeout_get_more = null
    }
    componentDidMount(){
        /*if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
            });
        } else {
            console.log("Not Available");
        }*/
        this.onGetDefault()
        const _this = this
        window.onscroll = function(ev) {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                _this.onListScroll()
            }
        };
        _this.props.onFieldChange("scroll_get_more", true)
        let test_scan_barcode = Helper.getQueryString('page');
        if(test_scan_barcode !== undefined && test_scan_barcode !== null && test_scan_barcode === "1"){
            _this.setState({
                test_scan_barcode: true
            })
        }
    }
    componentDidUpdate(){
        let _this = this
        if(_this.state.receipt_bill_code !== ""){
            setTimeout(() => {
                _this.setState({
                    receipt_bill_code: ""
                })
            }, 250)
        }
    }
    onGetDefault(is_get_inprogress = true){
        var transporter_info = Helper.getStaffTransporter();
        if(transporter_info !== undefined && transporter_info !== null && transporter_info.id !== undefined && transporter_info.id > 0){
            this.props.onSearch({
                "ship_status": search_status_default.join(","),
                "transporter_id": transporter_info.id
            },1,this.props.bill_lading.limit, false);
            if(is_get_inprogress === true){
                this.props.onGetBillInProgress(transporter_info.id).then(info => {
                    this.setState({
                        ...info
                    })
                })
            }
        }
    }
    onView(id){
        this.props.history.push('/ship-invoice/' + id)
    }
    onListScroll(){
        let _this = this
        var transporter_info = Helper.getStaffTransporter();
        if(_this.ship_invoice_header_ref !== undefined && _this.ship_invoice_header_ref !== null && transporter_info !== undefined && transporter_info !== null && transporter_info.id !== undefined && transporter_info.id > 0){
            if(_this.timeout_get_more !== null){
                clearTimeout(_this.timeout_get_more)
            }
            _this.timeout_get_more = setTimeout(() => {
                var data_search = _this.ship_invoice_header_ref.getDataSearch();
                if (!(data_search !== null && data_search.search !== undefined && data_search.search === true)){
                    data_search = {
                        "ship_status": search_status_default.join(","),
                        "transporter_id": transporter_info.id
                    }
                }
                let page = parseInt(_this.props.bill_lading.page) + 1
                _this.props.onSearch(data_search,page,this.props.bill_lading.limit, false);
            }, 250);
        }
    }
    onShowCamera(){
        this.setState({
            show_camera: !this.state.show_camera
        })
    }
    onCloseCamera(){
        this.setState({
            show_camera: false,
            stop_stream_camera: true
        })
    }
    onScan(text){
        //console.log("onScan: ", text)
        this.onShowCamera()
        if(text !== ""){
            this.props.onScanInvoice(text).then(result => {
                if(result !== undefined && result !== null){
                    if(result.status === true){
                        this.setState({
                            receipt_bill_code: text
                        })
                        this.onGetDefault(false)
                    } else if (result.message !== undefined &&  result.message !== null){
                        Swal.fire("Thông báo", result.message, "error")
                    }
                }
            })
        }
    }
    render(){
        let {list} = this.props.bill_lading
        let bill_in_progress = null, cod_amount_text = ""
        if(this.state.id > 0){
            bill_in_progress = {
                id: this.state.id,
                receipt_bill_code: this.state.receipt_bill_code,
                receipt_bill_date: this.state.receipt_bill_date,
                receiver_name: this.state.receiver_name,
                receiver_phone: this.state.receiver_phone,
                receiver_address: this.state.receiver_address,
                total_amount: this.state.total_amount,
                cod_amount: this.state.cod_amount,
                ship_status_color: this.state.ship_status_color,
                ship_status_text: this.state.ship_status_text
            }
            if (this.state.cod_amount > 0){
                cod_amount_text = " - " + Helper.parseMoneyText(this.state.cod_amount)
            }
        }
        return (
            <div>
                { this.state.show_camera === true ?
                    <div className="camera-container">
                        <div className="camera-scan-barcode">
                            <BarcodeScannerComponent
                                width={"100%"}
                                height={200}
                                torch={true}
                                stopStream={this.state.stop_stream_camera}
                                onUpdate={(err, result) => {
                                    if (result) this.onScan(result.text)
                                }}
                                onError = {(error) => {
                                    if (error.name === "NotAllowedError") {
                                        Swal.fire("Thông báo", "Trình duyệt không cho phép truy xuất Camera", "error")
                                    }
                                }}
                            />
                            <div className="camera-scan-barcode__pointer"></div>
                        </div>
                        <div className="camera__close"><i className="fa fa-remove fa-2x" onClick={() => this.onCloseCamera()}></i></div>
                    </div> : 
                    <div className="bill-ladings">
                        <ShipInvoiceHeader innerRef={e => this.ship_invoice_header_ref = e} search={true} onGetDefault={() => this.onGetDefault() }/>
                        <table className="table table-hover table-striped">
                            <tbody>
                                {bill_in_progress !== null ?
                                <tr className="bill-lading-item" onClick={() => this.onView(bill_in_progress.id)}>
                                    <td>
                                        <strong>{bill_in_progress.receiver_name}</strong> {bill_in_progress.receiver_phone !== "" ? <span>&nbsp;({bill_in_progress.receiver_phone})</span> : "" }<br />
                                        {bill_in_progress.receiver_address}<br />
                                        {bill_in_progress.receipt_bill_code} - <Moment format="DD/MM/YYYY HH:mm" unix>
                                        { bill_in_progress.time }
                                        </Moment> {cod_amount_text}<br />
                                        <label className="label" style={{backgroundColor: bill_in_progress.ship_status_color}}>{bill_in_progress.ship_status_text}</label>
                                        <i className="fa fa-chevron-right"></i>
                                    </td>
                                </tr> : null }
                                { list.length > 0 ?
                                list.filter(item => item.id !== this.state.id).map((item, index) => {
                                    let cod_amount_text = ""
                                    if (item.cod_amount > 0){
                                        cod_amount_text = " - " + Helper.parseMoneyText(item.cod_amount)
                                    }
                                    let class_item = "bill-lading-item"
                                    if(this.state.receipt_bill_code === item.receipt_bill_code){
                                        class_item += " zoom-out"
                                    }
                                    return <tr key={index} className={class_item} onClick={() => this.onView(item.id)}>
                                        <td>
                                            <strong>{item.receiver_name}</strong> {item.receiver_phone !== "" ? <span>&nbsp;({item.receiver_phone})</span> : "" }<br />
                                            {item.receiver_address}<br />
                                            {item.receipt_bill_code} - <Moment format="DD/MM/YYYY HH:mm" unix>
                                            { item.time }
                                            </Moment> {cod_amount_text}<br />
                                            <label className="label" style={{backgroundColor: item.ship_status_color}}>{item.ship_status_text}</label>
                                            {item.ship_status_reason !== "" ?
                                            <span className="small">&nbsp;{item.ship_status_reason}</span> : "" }
                                            <i className="fa fa-chevron-right"></i>
                                        </td>
                                    </tr>
                                })
                                : null }
                            </tbody>
                        </table>
                        <div className={"call-phone-container camera-scan"} onClick={() => this.onShowCamera()}>
                            <i className="fa fa-camera fa-2x"></i>
                        </div>
                    </div>  }
            </div> 
        );
    }
}

const mapStateToProps = state => {
    return {
        bill_lading: state.bill_lading,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(BillLadingAction.fieldChange(field,value))
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(BillLadingAction.searchRequest(data_search,page,limit,spinner, true))
        },
        onGetBillInProgress: (user_id) => {
            return dispatch(BillLadingAction.getBillInProgressRequest(user_id, true))
        },
        onScanInvoice: receipt_bill_code => {
            return dispatch(BillLadingAction.scanInvoiceRequest(receipt_bill_code))
        }
    }
}
const ShipInvoicesConnect = (connect(mapStateToProps, mapDispatchToProps)(ShipInvoices));
export default withRouter(ShipInvoicesConnect);