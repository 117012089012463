import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert, SelectPicker } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as TransferStoreAction from '../../actions/TransferStoreAction';
import * as TransferStoreActionType from '../../constants/TransferStoreActionType';
import * as ReceiptOfGoodAction from '../../actions/ReceiptOfGoodAction';
import SearchProduct from '../shared/SearchProduct';
import Helper from '../../utils/Helper';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Swal from 'sweetalert2';
import ViewTransfer from './ViewTransfer';

const { Column, HeaderCell, Cell } = Table;

class TransferWarehouse extends Component {
    constructor(props){
        super(props)
        this._mounted = true
        this.state = {
            to_stores: [],
            invoices: []
        }
    }
    componentDidUpdate(){
        if (Helper.checkMyRole('transfer_store') === false){
            this._mounted = false
            this.props.history.push("/access-denied")
        }
    }
    componentDidMount(){
        this.props.onEmptyInfo()
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        if (id > 0) {
            this.onInitData(id)
        }

        let my_branchs = Helper.getMyBranchs()
        let branch_ids = []
        if(my_branchs !== null && my_branchs.length > 0){
            my_branchs.forEach(item => {
                branch_ids.push(item.id)
            })
        }
        let my_stores = Helper.getMyStores()
        this.props.onGetStoreDestination(branch_ids.join(",")).then(res => {
            if (res.result !== null && res.result.list !== undefined && res.result.list !== null && res.result.list.length > 0){
                let to_stores = []
                res.result.list.forEach(item => {
                    to_stores.push({
                        id: item.id,
                        name: item.name,
                        code: item.code,
                        branch_id: item.branch_id,
                        branch_name: item.branch_name
                    })
                })
                this.setState({
                    to_stores
                })
                let branch_selected = Helper.getBranchSelected()
                if(my_stores !== null && my_stores.list !== undefined && my_stores.list !== null && my_stores.list.length === 1){
                    let item = my_stores.list[0]
                    this.props.onFieldChange("from_store",{
                        label: item.name + " - " + branch_selected.name,
                        value: item.id,
                        store_id: item.id,
                        store_name: item.name,
                        store_code: item.code,
                        branch_id: branch_selected.id,
                        branch_name: branch_selected.name,
                    })
                }
                this.props.onGetInvoiceTransferStoreRequest(branch_selected.id).then(result => {
                    if (result !== null && result.list !== undefined && result.list !== null && result.list.length > 0){
                        let invoices = []
                        result.list.forEach(item => {
                            invoices.push({
                                id: item.id,
                                code: item.receipt_bill_code,
                                total_amount: item.total_amount,
                                date: item.receipt_bill_date,
                                customer_name: item.customer_name,
                                customer_id: item.customer_id
                            })
                        })
                        this.setState({
                            invoices
                        })
                    }
                })
            }
        })
    }
    onInitData(id) {
        this.props.onGetData(id).then(result => {
            if(result.status === false && result.message !== undefined && result.message !== ""){
                this.props.history.push("/transfer-stores");
            }
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onSelectToStore = (_,item) => {
        if (item !== null){
            this.props.onFieldChange("to_store",item);
            if(item.branch_id !== undefined && item.branch_id !== null){
                this.props.onGetReceiptOfGoodFromBranch(item.branch_id)
            }
        }
    }
    onSelectReceiptOfGood = item => {
        if (item !== null){
            let branch_selected = Helper.getBranchSelected()
            this.props.onSelectReceiptOfGood(item, branch_selected.id)
        }
    }
    onCleanReceiptOfGood = () => {
        this.props.onFieldChange("receipt_of_good_selected",{});
        this.props.onFieldChange("products",[]);
    }
    onSelectProduct = itemSelected => {
        if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            if (this.props.transfer_store.receipt_of_good_selected.id !== undefined && this.props.transfer_store.receipt_of_good_selected.id > 0){
                Swal.fire({
                    icon: 'warning',
                    title: 'Thông báo',
                    text: "Đang chọn chuyển kho cho phiếu ghi nhận nhập hàng, không được chọn thêm hàng hóa"
                })
                return
            }
            this.props.onSelectProductToList(itemSelected)
        }
    }
    onRemoveProduct = key => {
        let products = this.props.transfer_store.products;
        let index = Helper.findIndexByKey(products, key);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onClearProduct = () => {
        this.props.onClearProduct();
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("quantity", key, value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("transfer_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onPrint() {
        let id = 'transfer_store_bill'
        const iframe = document.frames
        ? document.frames[id]
        : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();
        return false;
    }
    onSaveTemp(){
        this.onSaveData(false)
    }
    onSubmit(){
        this.onSaveData(true)
    }
    onSaveData(is_finish){
        let {products, transfer_date, transfer_notes, to_store, receipt_of_good_selected } = this.props.transfer_store
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        if (!(to_store !== null && to_store.store_id !== undefined && to_store.store_id > 0)){
            Alert.error('Vui lòng chọn kho nhận')
            return;
        }
        if (transfer_date === ""){
            transfer_date = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        let products_array = []
        products.forEach(item => {
            products_array.push({
                "product_id": item.id,
                "product_option_id": item.product_option_id,
                "product_name": item.name,
                "product_sku": item.sku,
                "product_model": item.model,
                "category_id": parseInt(item.category_id),
                "category_name": item.category_name,
                "brand_id": parseInt(item.brand_id),
                "brand_name": item.brand_name,
                "quantity": parseInt(item.quantity),
                "is_gift": parseInt(item.is_gift),
            })
        })
        
        let branch_selected = Helper.getBranchSelected()
        let data_post = {
            transfer_date: transfer_date,
            transfer_notes: transfer_notes,
            transfer_status: (is_finish === true) ? TransferStoreActionType.STATUS_EXPORT_TRANSFER_STORE : TransferStoreActionType.STATUS_IS_TEMP_TRANSFER_STORE,
            branch_id: branch_selected.id,
            branch_name: branch_selected.name,
            to_branch_id: to_store.branch_id,
            to_branch_name: to_store.branch_name,
            to_store_id: to_store.store_id,
            to_store_code: to_store.store_code,
            to_store_name: to_store.store_name,
            receipt_of_good_id: (receipt_of_good_selected.id !== undefined && receipt_of_good_selected.id > 0)?receipt_of_good_selected.id:0,
            receipt_of_good_code: (receipt_of_good_selected.receipt_code !== undefined && receipt_of_good_selected.receipt_code !== "")?receipt_of_good_selected.receipt_code:"",
            products: products_array,
        }
        //console.log(data_post);
        let button_name = "save_temp_button_loading"
        if (is_finish === true){
            button_name = "save_button_loading"
        }
        if (this.props.transfer_store.id !== null && this.props.transfer_store.id > 0){
            this.props.onUpdate(data_post, this.props.transfer_store.id, button_name).then(result => {
                if(result.status === true){
                    this.props.history.push("/transfer-store/" + result.id)
                    if (is_finish === true){
                        this.onPrint()
                    }
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        } else {
            this.props.onSubmit(data_post).then(result => {
                if(result.status === true){
                    this.props.history.push("/transfer-stores");
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        }
    }
    render(){
        if(this.props.transfer_store.id > 0 && this.props.transfer_store.transfer_status !== TransferStoreActionType.STATUS_IS_TEMP_TRANSFER_STORE){
            return <ViewTransfer id={this.props.transfer_store.id} />
        }
        let {products, transfer_notes, transfer_notes_active, transfer_date, from_store, to_store, save_button_loading, save_temp_button_loading, total_quantity, total_product, receipt_of_good_selected } = this.props.transfer_store;  
        transfer_date = Helper.replaceMysqlDateTime(transfer_date)
        let transfer_date_text = null
        if (transfer_date !== ""){
            transfer_date_text = new Date(moment(transfer_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())
        }
        let notes_field_class = "nk-int-st"
        if (transfer_notes !== "") {
            transfer_notes_active = true;
        }
        if(transfer_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }   
        /*let receipt_bills_array = []
        let receipt_bill_selected = 0
        if (this.state.invoices.length > 0){
            this.state.invoices.forEach(item => {
                receipt_bills_array.push({
                    label: item.code + " / " + Helper.parseMoneyText(item.total_amount) + " / " + item.customer_name,
                    value: item.id
                })
            })
        }*/
        let to_stores_array = [], from_stores_array = []
        let branch_selected = Helper.getBranchSelected()
        let my_stores = Helper.getMyStores()

        let total_my_stores = 0, to_store_skip = 0, to_store_selected = 0, from_store_selected = 0, from_store_default_text = null
        if(my_stores !== null && my_stores.list !== undefined && my_stores.list !== null && my_stores.list.length > 0){
            total_my_stores = my_stores.list.length
            my_stores.list.forEach(item => {
                if (total_my_stores > 1){
                    from_stores_array.push({
                        label: item.name + " - " + branch_selected.name,
                        value: item.id,
                        store_id: item.id,
                        store_name: item.name,
                        store_code: item.code,
                        branch_id: branch_selected.id,
                        branch_name: branch_selected.name,
                    })
                    to_stores_array.push({
                        label: item.name + " - " + branch_selected.name,
                        value: item.id,
                        store_id: item.id,
                        store_name: item.name,
                        store_code: item.code,
                        branch_id: branch_selected.id,
                        branch_name: branch_selected.name,
                    })
                } else {
                    from_store_default_text = <span> { item.name + " - " + branch_selected.name } </span>
                    to_store_skip = item.id
                }
            })
        }
        if(this.state.to_stores !== null && this.state.to_stores.length > 0){
            this.state.to_stores.forEach(item => {
                let allow_add = true
                if (to_store_skip > 0 && to_store_skip === item.id) {
                    allow_add = false
                }
                if (allow_add === true){
                    to_stores_array.push({
                        label: item.name + " - " + item.branch_name,
                        value: item.id,
                        store_id: item.id,
                        store_name: item.name,
                        store_code: item.code,
                        branch_id: item.branch_id,
                        branch_name: item.branch_name,
                    })
                }
            })
        }
        if (from_store !== null && from_store.value !== undefined && from_store.value > 0){
            from_store_selected = from_store.value
        }
        if (to_store !== null && to_store.value !== undefined && to_store.value > 0){
            to_store_selected = to_store.value
        }
        let receipt_of_goods_array = []
        let receipt_of_good_value_selected = 0
        if (this.props.receipt_of_good.list !== null && this.props.receipt_of_good.list.length > 0){
            this.props.receipt_of_good.list.forEach(item => {
                let receipt_date = Helper.convertMysqlDateTime(Helper.replaceMysqlDateTime(item.receipt_date))
                receipt_of_goods_array.push({
                    label: item.receipt_code + " - " + receipt_date,
                    value: item.id
                })
            })
        }
        if (receipt_of_good_selected !== null && receipt_of_good_selected.id !== undefined && receipt_of_good_selected.id > 0){
            receipt_of_good_value_selected = receipt_of_good_selected.id
        }
        return (
            <div className="container">
            <Helmet>
                <title>Chuyển kho</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/transfer-store/new"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <SearchProduct onSelect={ this.onSelectProduct.bind(this) } onClearProduct={ this.onClearProduct.bind(this) } import_file={false} get_size={true}/>
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={55}
                                    wordWrap={true}
                                    data={products} >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={80}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={350}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                return nameText
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Tồn kho</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={rowData.remain} />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={120} align="right">
                                        <HeaderCell>Số lượng chuyển</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let input = null
                                                if (rowData.remain > 0){
                                                    input = <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.quantity}  min={1} max={rowData.remain} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                    </div>
                                                } 
                                                return input
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50} fixed="right" align="center">
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <button type="button" onClick={() => { this.onRemoveProduct(rowData.key) }}><i className="fa fa-remove"></i></button>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker appearance="subtle" format="YYYY-MM-DD HH:mm" placeholder={moment().format("YYYY-MM-DD HH:mm")} 
                                    value={transfer_date_text}
                                    placement="leftStart" oneTap ranges={[]} block 
                                    onChange = { (value) => { this.onDatePickerChange(value) } } />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="nk-int-mk mr-bot-5">
                                <strong>Kho chuyển</strong>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                { from_stores_array.length > 0 ? 
                                <SelectPicker
                                    appearance="subtle"
                                    block
                                    style={{width: 346}}
                                    placeholder="Chọn kho chuyển"
                                    cleanable={false}
                                    value={from_store_selected}
                                    data={from_stores_array}
                                    onSelect={this.onSelectFromStore}
                                /> : from_store_default_text }
                            </div>
                            <div className="nk-int-mk mr-bot-5">
                                <strong>Kho nhận</strong>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <SelectPicker
                                    appearance="subtle"
                                    block
                                    style={{width: 346}}
                                    placeholder="Chọn kho nhận"
                                    cleanable={false}
                                    value={to_store_selected}
                                    data={to_stores_array}
                                    onSelect={this.onSelectToStore}
                                />
                            </div>
                            { receipt_of_goods_array.length > 0 ?
                            <div>
                                <div className="nk-int-mk mr-bot-5">
                                    <strong>Phiếu ghi nhận nhập hàng</strong>
                                </div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <SelectPicker
                                            appearance="subtle"
                                            style={{width: 346}}
                                            placeholder="Chọn từ phiếu ghi nhận nhập hàng"
                                            cleanable={true}
                                            value={receipt_of_good_value_selected}
                                            data={receipt_of_goods_array}
                                            onSelect={this.onSelectReceiptOfGood}
                                            onClean={this.onCleanReceiptOfGood}
                                        />
                                </div>
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <TextareaAutoHeight placeholder="Ghi chú chuyển kho" value={transfer_notes} name={"transfer_notes"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.transfer_notes_input = input; }}/>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng số lượng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_quantity} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_temp_button_loading }
                                    disabled={save_button_loading}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-info pull-left mr-right-5" onClick={ () => { this.onSaveTemp() } } >
                                    <i className="fa fa-save"></i>&nbsp;LƯU TẠM
                                </LaddaButton>
                                <LaddaButton
                                    loading={ save_button_loading }
                                    disabled={save_temp_button_loading}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success pull-left" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;BẮT ĐẦU CHUYỂN KHO
                                </LaddaButton>
                                <iframe
                                    id="transfer_store_bill"
                                    src={"/transfer-store/print/" + this.props.transfer_store.id + "?rand=" + Math.round(Math.random() * 10000000)}
                                    style={{ display: 'none' }}
                                    title="Phiếu chuyển kho"
                                />
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transfer_store: state.transfer_store,
        receipt_of_good: state.receipt_of_good
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(TransferStoreAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(TransferStoreAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(TransferStoreAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(TransferStoreAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(TransferStoreAction.resetField())
        },
        onUpdateProducts(products){
            dispatch(TransferStoreAction.updateProductsRequest(products))
        },
        onSelectProductToList(item){
            dispatch(TransferStoreAction.selectProductToListRequest(item))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(TransferStoreAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(TransferStoreAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(TransferStoreAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(TransferStoreAction.addRequest(data_post))
        },
        onUpdate(data_post, id, button_name){
            return dispatch(TransferStoreAction.updateRequest(data_post, id, button_name))
        },
        onGetData: id => {
            return dispatch(TransferStoreAction.getRequest(id))
        },
        onGetStaffNotes: id => {
            dispatch(TransferStoreAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(TransferStoreAction.getLastStaffNoteRequest(id))
        },
        onGetStoreDestination: branch_ids => {
            return dispatch(TransferStoreAction.getStoreDestinationRequest(branch_ids))
        },
        onGetInvoiceTransferStoreRequest: branch_transfer_id => {
            return dispatch(TransferStoreAction.getInvoiceTransferStoreRequest(branch_transfer_id))
        },
        onGetReceiptOfGoodFromBranch: branch_id => {
            return dispatch(ReceiptOfGoodAction.searchRequest({
                branch_id
            },1,20, false))
        },
        onSelectReceiptOfGood: (id, branch_id) => {
            return dispatch(TransferStoreAction.selectReceiptOfGoodRequest(id, branch_id))
        },
    }
}
const TransferWarehouseConnect = connect(mapStateToProps, mapDispatchToProps) (TransferWarehouse)
export default withRouter(TransferWarehouseConnect)