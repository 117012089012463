import * as PurchaseOrderActionType from '../constants/PurchaseOrderActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: PurchaseOrderActionType.FOCUS_FIELD_PRODUCT_EXPIRIES,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: PurchaseOrderActionType.FIELDS_CHANGE_PRODUCT_EXPIRIES,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: PurchaseOrderActionType.FIELD_CHANGE_PRODUCT_EXPIRIES,
        field,
        value
    }
}

export const resetFieldSearch = () => {
    return {
        type: PurchaseOrderActionType.RESET_FIELD_SEARCH_PRODUCT_EXPIRIES
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`import-receipts/expiries`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit, total_page, page } = res.data.result;
                    dispatch(listProductExpiries(total,limit,total_page,page,list));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}

const listProductExpiries = (total,limit,total_page,page,list) => {
    return {
        type: PurchaseOrderActionType.LIST_PRODUCT_EXPIRIES,
        total,
        limit,
        total_page,
        page,
        list,
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: PurchaseOrderActionType.SEARCH_PRODUCT_EXPIRIES,
        search_button_loading
    }
}