import * as TransferStoreActionType from '../constants/TransferStoreActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    transfer_store_code: "",
    transfer_date: "",
    transfer_notes: "",
    transfer_notes_active: false,
    from_store: [],
    to_store: {},
    import_receipt_id: 0,
    import_receipt_code: "",
    staff_import_id: 0,
    staff_import_name: "",
    receipt_bill_id: 0,
    receipt_bill_code: "",
    receipt_of_good_id: 0,
    receipt_of_good_code: "",
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    transfer_status_select: [],
    transfer_status: 0,
    transfer_status_text: "",
    total_quantity: 0,
    total_product: 0,
    total_amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    branch_id: 0,
    branch_name: "",
    has_difference: 0,
    products: [],
    staff_notes: [],
    receipt_of_good_selected: {},
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    search_code: "",
    search_product: "",
    search_date: [],
    search_status: [],
    search_button_loading: false,
    save_button_loading: false,
    save_temp_button_loading: false,
    show_print_button: false,
    is_search: false,
    direct_to_list: false,
};
const transfer_store = (state = initialState, action) => {
    let index = -1
    let products = []
    switch(action.type){
        case TransferStoreActionType.ITEM_BUTTON_SPINNER_TRANSFER_STORE:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case TransferStoreActionType.BUTTON_SPINNER_TRANSFER_STORE:
            return {
                ...state,
                save_button_loading: action.status
            };
        case TransferStoreActionType.BUTTON_UPDATE_SPINNER_TRANSFER_STORE:
          return {
              ...state,
              [action.button_name]: action.status
          };
        case TransferStoreActionType.FOCUS_FIELD_TRANSFER_STORE:
              return {
                ...state,
                [action.field]: action.value
              };
        case TransferStoreActionType.ERROR_FIELD_TRANSFER_STORE:
              return {
                ...state,
                [action.field]: action.value
              };
        case TransferStoreActionType.FIELD_CHANGE_TRANSFER_STORE:
              return {
                ...state,
                [action.field]: action.value
              };
        case TransferStoreActionType.EMPTY_TRANSFER_STORE:
          return {
            ...state,
            id: 0,
            transfer_store_code: "",
            transfer_date: "",
            transfer_notes: "",
            transfer_notes_active: false,
            from_store: [],
            to_store: {},
            import_receipt_id: 0,
            import_receipt_code: "",
            staff_import_id: 0,
            staff_import_name: "",
            receipt_bill_id: 0,
            receipt_bill_code: "",
            receipt_of_good_id: 0,
            receipt_of_good_code: "",
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            transfer_status_select: [],
            transfer_status: 0,
            transfer_status_text: "",
            total_quantity: 0,
            total_product: 0,
            total_amount: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            branch_id: 0,
            branch_name: "",
            has_difference: 0,
            products: [],
            staff_notes: [],
            total: 0,
            limit: 10,
            total_page: 0,
            page: 1,
            list: [],
            search_code: "",
            search_product: "",
            search_date: [],
            search_status: [],
            search_button_loading: false,
            save_button_loading: false,
            save_temp_button_loading: false,
            show_print_button: false,
            is_search: false,
            direct_to_list: false,
          };
        case TransferStoreActionType.RESET_FIELD_SEARCH_TRANSFER_STORE:      
            return {
              ...state,
              search_code: "",
              search_product: "",
              search_date: [],
              search_status: [],
              is_search: false,
            };
        case TransferStoreActionType.GET_TRANSFER_STORE:
            products = []
            let total_quantity = 0, total_product = 0, total_amount = 0
            action.transfer_store.products.forEach(function(item){
              total_quantity += item.quantity
              total_product++
              total_amount += item.retail * item.real_quantity
              products.push({
                  "id": item.product_id,
                  "product_option_id": item.product_option_id,
                  "product_option_name": item.product_option_name,
                  "url": item.product_url,
                  "name": item.product_name,
                  "model": item.product_model,
                  "sku": item.product_sku,
                  "price": item.retail,
                  "remain": item.remain,
                  "quantity": item.quantity,
                  "real_quantity": item.real_quantity,
                  "unit": item.unit,
                  "categories": item.categories,
                  "category_id": item.category_id,
                  "category_name": item.category_name,
                  "brand_id": item.brand_id,
                  "brand_name": item.brand_name,
                  "is_gift": item.is_gift,
              })
            })
            let to_store = {
              label: action.transfer_store.to_store_name + " - " + action.transfer_store.to_branch_name,
              value: action.transfer_store.to_store_id,
              store_id: action.transfer_store.to_store_id,
              store_name: action.transfer_store.to_store_name,
              store_code: action.transfer_store.to_store_code,
              branch_id: action.transfer_store.to_branch_id,
              branch_name: action.transfer_store.to_branch_name,
            }
            return {  
                ...state,
                ...action.transfer_store,
                products,
                from_store: action.transfer_store.from_store,
                to_store,
                has_difference: action.transfer_store.has_difference,
                receipt_of_good_id: action.transfer_store.receipt_of_good_id,
                receipt_of_good_code: action.transfer_store.receipt_of_good_code,
                total_quantity,
                total_product,
                total_amount
            };
        case TransferStoreActionType.GET_STAFF_NOTES_TRANSFER_STORE:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };       
        case TransferStoreActionType.GET_LAST_STAFF_NOTES_TRANSFER_STORE:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case TransferStoreActionType.LIST_TRANSFER_STORE:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                list: [...action.inventory_checks],
                direct_to_list: false
              };
        case TransferStoreActionType.SEARCH_TRANSFER_STORE:
            return {
                ...state,
                is_search: true,
                search_button_loading: action.search_button_loading
            };
        case TransferStoreActionType.LIST_TRANSFER_STORE_STATUS:
            return {
                ...state,
                import_receipt_status_select: action.status
              };
        case TransferStoreActionType.CLEAR_PRODUCTS_TRANSFER_STORE:
          return {
            ...state,
            products: []
          };
        case TransferStoreActionType.SELECT_PRODUCT_TRANSFER_STORE:
            products = state.products;
            let itemSelected = action.item
            if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
              let key = itemSelected.id + '_' + itemSelected.product_option_id
              let index = Helper.findIndexByKey(products, key);
              if(index > -1){
                products[index].quantity = parseInt(products[index].quantity) + 1
                products[index].quantity_diff = products[index].remain - products[index].quantity
                let amount = products[index].price * products[index].quantity
                products[index].total_amount = amount
              } else {
                  let quantity = 1
                  let quantity_diff = itemSelected.remain - quantity
                  products.unshift({
                      "key": key,
                      "id": itemSelected.id,
                      "product_option_id": itemSelected.product_option_id,
                      "product_option_name": itemSelected.product_option_name,
                      "url": itemSelected.url,
                      "name": itemSelected.name,
                      "model": itemSelected.model,
                      "sku": itemSelected.sku,
                      "price": itemSelected.retail,
                      "quantity": quantity,
                      "discount_value": 0,
                      "discount": 0,
                      "discount_mode": 1,
                      "total_amount": itemSelected.retail,
                      "remain": itemSelected.remain,
                      "quantity_diff": quantity_diff,
                      "unit": itemSelected.unit,
                      "categories": itemSelected.categories,
                      "category_id": itemSelected.category_id,
                      "category_name": itemSelected.category_name,
                      "brand_id": itemSelected.brand_id,
                      "brand_name": itemSelected.brand_name,
                      "is_gift": itemSelected.is_gift,
                  })
              }
          }
          return {
            ...state,
            products: [...products]
          }; 
        case TransferStoreActionType.UPDATE_PRODUCTS_TRANSFER_STORE: 
            return {
              ...state,
              products: [...action.products]
            };   
        case TransferStoreActionType.UPDATE_PRODUCT_TRANSFER_STORE: 
        //console.log("action.key: ",action.key)
          //  console.log("state.products: ",state.products)
            index = Helper.findIndexByKey(state.products, action.key);
            //console.log("index: ",index)
            if (index > -1){
              let product = state.products[index]
              if (action.input_name === "quantity"){
                product.quantity = action.value
              } else if (action.input_name === "price_suggest"){
                product.price_suggest = action.value
              }
              let amount = product.price_suggest * product.quantity
              product.quantity_diff = product.remain - product.quantity
              product.total_amount = amount
              state.products[index] = product
            }
            return {
              ...state
            };   
        case TransferStoreActionType.SUM_TOTAL_TRANSFER_STORE:
            state.total_quantity = 0
            state.total_product = 0
            if(state.products.length > 0){
              let total_quantity = 0, total_product = 0
              state.products.forEach(function(item){
                if (item.remain > 0){
                  total_quantity += parseInt(item.quantity)
                }
                total_product++
              });
              state.total_quantity = total_quantity
              state.total_product = total_product
            }
            return {
              ...state
            };  
        case TransferStoreActionType.RESET_FLAG_SEARCH_TRANSFER_STORE:
          return {
            ...state,
            is_search: action.status
          };       
        case TransferStoreActionType.DIRECT_TO_LIST_TRANSFER_STORE:
          return {
            ...state,
            direct_to_list: action.status
          };     
        case TransferStoreActionType.GET_RECEIPT_OF_GOOD_TRANSFER_STORE:
        let receipt_of_good_selected = {
          id: action.info.id,
          code: action.info.receipt_code,
          date: action.info.receipt_date,
          products: action.info.products
        }
        products = []
        if(action.info.products.length > 0){
          action.info.products.forEach(itemSelected => {
              let key = itemSelected.product_id + '_' + itemSelected.product_option_id
              let quantity = itemSelected.total
              let quantity_diff = itemSelected.remain - quantity
              products.unshift({
                  "key": key,
                  "id": itemSelected.product_id,
                  "product_option_id": itemSelected.product_option_id,
                  "product_option_name": itemSelected.product_option_name,
                  "url": itemSelected.product_url,
                  "name": itemSelected.product_name,
                  "model": itemSelected.product_model,
                  "sku": itemSelected.sku,
                  "price": itemSelected.retail,
                  "quantity": quantity,
                  "discount_value": 0,
                  "discount": 0,
                  "discount_mode": 1,
                  "total_amount": itemSelected.retail,
                  "remain": itemSelected.remain,
                  "quantity_diff": quantity_diff,
                  "unit": itemSelected.unit,
                  "categories": itemSelected.categories,
                  "category_id": itemSelected.category_id,
                  "category_name": itemSelected.category_name,
                  "brand_id": itemSelected.brand_id,
                  "brand_name": itemSelected.brand_name,
                  "is_gift": itemSelected.is_gift,
              })
          })
        }

        return {  
            ...state,
            receipt_of_good_selected,
            products,
        };  
        default: 
            return state;
    }
}
export default transfer_store;