import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Bar, HorizontalBar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { SelectPicker } from 'rsuite';
import moment from 'moment';
import parser from 'html-react-parser';
import * as ReportAction from '../actions/ReportAction';
import * as ActivitiesAction from '../actions/ActivitiesAction';
import Helper from '../utils/Helper';
import DateFilterSelectBox from './shared/DateFilterSelect';
import LoadingIndicator from './shared/Loader';
import Moment from 'react-moment';

class Dashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            revenue_mode_view: 'date',
            revenue_title: 'tháng này',
            top_product_mode_view: 'revenue',
            top_product_title: 'hôm qua',
        }
        this.revenue_from_date = ''
        this.revenue_to_date = ''
        this.top_product_from_date = ''
        this.top_product_to_date = ''
    }
    componentDidMount(){
        this.onGetDailyRevenue()
        if(this.revenue_from_date === "" || this.revenue_to_date === ""){
            this.revenue_from_date = moment().startOf('month').format('YYYY-MM-DD')
            this.revenue_to_date = moment().endOf('month').format('YYYY-MM-DD')
            this.onViewRevenueReport(this.revenue_from_date,this.revenue_to_date)
        }
        if(this.top_product_from_date === "" || this.top_product_to_date === ""){
            this.top_product_from_date = moment().add(-1, 'days').format('YYYY-MM-DD')
            this.top_product_to_date = moment().add(-1, 'days').format('YYYY-MM-DD')
            this.onViewTopProductReport({
                from_date: this.top_product_from_date,
                to_date: this.top_product_to_date,
                mode_view: this.state.top_product_mode_view
            })
        }
        if(Helper.checkMyRole('user_activities') === true){
            this.props.onListActivities(this.props.activities.page, 25)
        }
    }

    onGetDailyRevenue(){
        var _this = this
        setTimeout(function(){   
            let branch_selected = Helper.getBranchSelected()
            _this.props.onGetDailyRevenue({
                branch_id: branch_selected.id,
                branch_name: branch_selected.name
            })
        },250)
    }
    onRevenueDateChange(value, label){
        if(value !== null && value.length > 0 && value[0] !== undefined && value[0] !== null && value[1] !== undefined && value[1] !== null){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            this.onViewRevenueReport(from_date,to_date)
            this.setState({
                revenue_title: label.toLowerCase()
            })
        }
    }
    onRevenueModeViewChange(mode){
        this.setState({
            revenue_mode_view: mode,
        })
    }
    onViewRevenueReport(from_date,to_date){
        var _this = this
        setTimeout(function(){   
            if(from_date !== "" && to_date !== ""){
                _this.revenue_from_date = from_date
                _this.revenue_to_date = to_date
                let branch_selected = Helper.getBranchSelected()
                _this.props.onRevenueReport({
                    from_date: from_date,
                    to_date: to_date,
                    mode_view: _this.state.revenue_mode_view,
                    branch_id: branch_selected.id,
                    branch_name: branch_selected.name
                })
            }
        },250)
    }
    showRevenueReport(info){
        if (info !== null && info.date !== null && info.date.length > 0){
            var labels = [], amounts = [], backgroundColors = [], borderColors = []
            var week_text_array = ['CN','T2','T3','T4','T5','T6','T7']
            let info_array = info.date
            if (this.state.revenue_mode_view === 'day_week'){
                info_array = info.day_of_week
            } else if (this.state.revenue_mode_view === 'hour'){
                info_array = info.hour
            }
            info_array.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;
                return 0;
            })
            info_array.forEach(item => {
                let name_text = ""
                let name_key = parseInt(item.name)
                if (this.state.revenue_mode_view === 'day_week'){
                    name_text = week_text_array[name_key]
                } else {
                    name_text = ((name_key < 10) ? "0" : "") + item.name
                }
                labels.push(name_text)
                amounts.push(item.amount)
                backgroundColors.push('rgba(54, 162, 235, 0.2)')
                borderColors.push('rgba(54, 162, 235, 1)')
            })
            let label = info.branch_name
            const data = {
                labels: labels,
                datasets: [
                  {
                    label: label,
                    data: amounts,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                    borderWidth: 1,
                  },
                ],
              }
              
              const options = {
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            label += Helper.parseMoneyText(tooltipItem.yLabel);
                            return Helper.parseMoneyText(tooltipItem.yLabel);
                        }
                    }
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: function(value, index, values) {
                            let value_return = 0
                            if(value > 0){
                                if (value >= 1000000000) {
                                    value_return = (value / 1000000000) + "tỷ"
                                } else if (value >= 1000000) {
                                    value_return = (value / 1000000) + "tr"
                                } else{
                                    value_return = (value / 1000) + "K"
                                }
                            }
                            return value_return
                        }
                      },
                    },
                  ],
                },
              }
              return <Bar data={data} options={options} />
        }
        return <div className="alert alert-warning">Không có thông tin báo cáo nào</div>
    }
    onSelectTopProductModeView(value){
        this.onViewTopProductReport({
            from_date: this.top_product_from_date,
            to_date: this.top_product_to_date,
            mode_view: value
        })
        this.setState({
            top_product_mode_view: value
        })
    }
    onTopProductDateChange(value, label){
        if(value !== null && value.length > 0 && value[0] !== undefined && value[0] !== null && value[1] !== undefined && value[1] !== null){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            this.onViewTopProductReport({
                from_date,
                to_date,
                mode_view: this.state.top_product_mode_view
            })
            this.setState({
                top_product_title: label.toLowerCase()
            })
        }
    }
    onViewTopProductReport(data_post){
        var _this = this
        setTimeout(function(){   
            if(data_post.from_date !== "" && data_post.to_date !== ""){
                _this.top_product_from_date = data_post.from_date
                _this.top_product_to_date = data_post.to_date 
                let branch_selected = Helper.getBranchSelected()
                _this.props.onViewTopProductReport({
                    from_date: data_post.from_date,
                    to_date: data_post.to_date,
                    mode_view: _this.state.top_product_mode_view,
                    branch_id: branch_selected.id,
                    branch_name: branch_selected.name,
                    limit: 10
                })
            }
        },250)
    }
    showTopProductReport(info){
        if (info !== null && info.length > 0){
            var labels = [], amounts = [], backgroundColors = [], borderColors = []
            let label = ""
            info.forEach(item => {
                labels.push(item.product_name)
                if (this.state.top_product_mode_view === 'quantity'){
                    amounts.push(item.quantity)
                } else{
                    amounts.push(item.amount)
                }
                backgroundColors.push('rgba(54, 162, 235, 0.2)')
                borderColors.push('rgba(54, 162, 235, 1)')
                /*if (label === ""){
                    label = item.branch_name
                }*/
            })
            let _this = this
            const data_product = {
                labels: labels,
                datasets: [
                  {
                    label: label,
                    data: amounts,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                    borderWidth: 1,
                  },
                ],
              }
              
              const options_product = {
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            label += Helper.parseMoneyText(tooltipItem.xLabel);
                            return Helper.parseMoneyText(tooltipItem.xLabel);
                        }
                    }
                },
                scales: {
                    xAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        callback: function(value, index, values) {
                            let value_return = ""
                            if(value > 0 && Helper.isInt(value)){
                                value_return = value
                                if (_this.state.top_product_mode_view === 'revenue'){
                                    if (value >= 1000000000) {
                                        value_return = (value / 1000000000) + "tỷ"
                                    } else if (value >= 1000000) {
                                        value_return = (value / 1000000) + "tr"
                                    } else{
                                        value_return = (value / 1000) + "K"
                                    }
                                }
                            }
                            return value_return
                        }
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        callback: function(value, index, values) {
                            let value_return = value.slice(0, 50);
                            return value_return + "..."
                        }
                      },
                    },
                  ],
                },
              }
              return <HorizontalBar data={data_product} options={options_product} />
        }
        return <div className="alert alert-warning">Không có thông tin báo cáo nào</div>
    }
    render(){
        let revenue_total_amount_in_date = 0, revenue_total_bill_in_date = 0, yesterday_percent = 0, prev_month_percent = 0
        let yesterday_percent_color = "counter", yesterday_percent_icon = "fa fa fa-minus fa-5x", prev_month_percent_color = "counter", prev_month_percent_icon = "fa fa fa-minus fa-5x"
        if(this.props.report.daily_revenue !== null){
            let daily_revenue = this.props.report.daily_revenue
            revenue_total_amount_in_date = daily_revenue.total_amount
            revenue_total_bill_in_date = daily_revenue.total_bill
            yesterday_percent = daily_revenue.yesterday
            prev_month_percent = daily_revenue.prev_month
            if (yesterday_percent < 0){
                yesterday_percent_color = "counter text-danger"
                yesterday_percent_icon = "fa fa-arrow-down fa-5x text-danger"
            } else if (yesterday_percent > 0) {
                yesterday_percent_color = "counter text-success"
                yesterday_percent_icon = "fa fa-arrow-up fa-5x text-success"
            }
            if (prev_month_percent < 0){
                prev_month_percent_color = "counter text-danger"
                prev_month_percent_icon = "fa fa-arrow-down fa-5x text-danger"
            } else if (prev_month_percent > 0){
                prev_month_percent_color = "counter text-success"
                prev_month_percent_icon = "fa fa-arrow-up fa-5x text-success"
            }
        }
        let revenue_loading = true
        let revenue_infos = null 
        let revenue_total_amount = 0
        if (this.props.report.revenue !== null){
            revenue_infos = this.props.report.revenue
            revenue_loading = false
            revenue_total_amount = parseFloat(revenue_infos.total_amount)
        }


        let top_product_loading = true
        let top_product_infos = null 
        if (this.props.report.top_product !== null){
            top_product_infos = this.props.report.top_product
            top_product_loading = false
        }
        revenue_total_amount_in_date = Helper.parseMoney(revenue_total_amount_in_date)
        let show_activities = false
        if(this.props.activities.list.length > 0){
            show_activities = true
        }
        return (
            <div className="container">
                <div className="row">
                    <div className={show_activities === true ? "col-sm-9 col-xs-12" : "col-xs-12" }>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-3 col-xs-12">
                                    <div className="wb-traffic-inner notika-shadow sm-res-mg-t-30 tb-res-mg-t-30">
                                        <div className="website-traffic-ctn">
                                        <h2><span className="counter"><NumberFormat value={revenue_total_amount_in_date} displayType={'text'} thousandSeparator={true} /></span></h2>
                                            { revenue_total_bill_in_date > 0 ? <p><NumberFormat value={revenue_total_bill_in_date} displayType={'text'} thousandSeparator={true} />&nbsp;hóa đơn</p> : "" }
                                            <p>Doanh thu thuần</p>
                                        </div>
                                        <div className="sparkline-bar-stats1"><i className="fa fa-dollar fa-5x text-info"></i></div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-xs-12">
                                    <div className="wb-traffic-inner notika-shadow sm-res-mg-t-30 tb-res-mg-t-30">
                                        <div className="website-traffic-ctn">
                                        <h2><span className="counter">0</span></h2>
                                        <p>Trả hàng</p>
                                        </div>
                                        <div className="sparkline-bar-stats1"><i className="fa fa-reply-all fa-5x text-warning"></i></div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-xs-12">
                                    <div className="wb-traffic-inner notika-shadow sm-res-mg-t-30 tb-res-mg-t-30">
                                        <div className="website-traffic-ctn">
                                        <h2><span className={yesterday_percent_color}>{yesterday_percent + "%"}</span></h2>
                                        <p>So với hôm qua</p>
                                        </div>
                                        <div className="sparkline-bar-stats1"><i className={yesterday_percent_icon}></i></div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-xs-12">
                                    <div className="wb-traffic-inner notika-shadow sm-res-mg-t-30 tb-res-mg-t-30">
                                        <div className="website-traffic-ctn">
                                        <h2><span className={prev_month_percent_color}>{prev_month_percent + "%"}</span></h2>
                                        <p>So với cùng kỳ tháng trước</p>
                                        </div>
                                        <div className="sparkline-bar-stats1"><i className={prev_month_percent_icon}></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="normal-table-list">
                                <div className="basic-tb-hd">
                                    <h2>
                                        <div className="pull-left">
                                            Doanh thu thuần { this.state.revenue_title }&nbsp;<span className="text-info"><i className="fa fa-arrow-circle-right"></i>&nbsp;<NumberFormat value={revenue_total_amount} displayType={'text'} thousandSeparator={true} /></span>
                                        </div>
                                        <div className="pull-right">
                                            <DateFilterSelectBox value={4} onFieldChange={(value, label) => { this.onRevenueDateChange(value, label) }} />
                                        </div>
                                        <div className="cl"></div>
                                    </h2>
                                </div>
                                <div className="bsc-tbl">
                                    <div className="form-group">
                                        <button onClick={() => { this.onRevenueModeViewChange('date') }} className={(this.state.revenue_mode_view === 'date' ? "btn btn-link btn-sm btn-filter-date-chart selected" : "btn btn-link btn-sm btn-filter-date-chart")} type="button">Theo ngày</button>
                                        <button onClick={() => { this.onRevenueModeViewChange('hour') }} className={(this.state.revenue_mode_view === 'hour' ? "btn btn-link btn-sm btn-filter-date-chart selected" : "btn btn-link btn-sm btn-filter-date-chart")} type="button">Theo giờ</button>
                                        <button onClick={() => { this.onRevenueModeViewChange('day_week') }} className={(this.state.revenue_mode_view === 'day_week' ? "btn btn-link btn-sm btn-filter-date-chart selected" : "btn btn-link btn-sm btn-filter-date-chart")} type="button">Theo thứ</button>
                                    </div>
                                    <div>
                                        { revenue_loading === true ? <LoadingIndicator /> : this.showRevenueReport(revenue_infos) }
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div className="form-group">
                            <div className="normal-table-list">
                                <div className="basic-tb-hd">
                                    <h2>
                                        <div className="pull-left">
                                            Top 10 hàng hóa bán chạy { this.state.top_product_title }
                                        </div>
                                        <div className="pull-left">
                                            <SelectPicker
                                                block
                                                cleanable={false}
                                                searchable={false}
                                                appearance="subtle"
                                                data={[
                                                    {
                                                        label: "Theo doanh thu",
                                                        value: "revenue"
                                                    },
                                                    {
                                                        label: "Theo số lượng",
                                                        value: "quantity"
                                                    }
                                                ]}
                                                value={this.state.top_product_mode_view}
                                                onSelect={this.onSelectTopProductModeView.bind(this)}
                                                renderMenu={menu => {
                                                    return menu;
                                                }}
                                            />
                                        </div>
                                        <div className="pull-right">
                                            <DateFilterSelectBox value={2} onFieldChange={(value, label) => { this.onTopProductDateChange(value, label) }} />
                                        </div>
                                        <div className="cl"></div>
                                    </h2>
                                </div>
                                <div className="bsc-tbl">
                                    { top_product_loading === true ? <LoadingIndicator /> : this.showTopProductReport(top_product_infos) }
                                </div>
                            </div>   
                        </div>
                    </div> 
                    {show_activities === true ?
                    <div className="col-sm-3 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <h2>
                                    Các hoạt động gần đây
                                </h2>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table">
                                    <tbody>
                                        { this.props.activities.list.map((item, index) => {
                                            return <tr key={index}>
                                                <td>[{<Moment format="DD/MM/YYYY HH:mm:ss" unix>
                                        { item.time }
                                        </Moment>}] {parser(item.text)} </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>    
                        </div>                 
                    </div> : null }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        report: state.report,
        activities: state.activities,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onSetLoading(status){
            dispatch(ReportAction.setLoading(status))
        },
        onGetDailyRevenue: () => {
            dispatch(ReportAction.getDailyRevenueRequest())
        },
        onRevenueReport: (data_post) => {
            dispatch(ReportAction.revenueReportRequest(data_post))
        },
        onViewTopProductReport: (data_post) => {
            dispatch(ReportAction.topProductReportRequest(data_post))
        },
        onListActivities: (page, limit) => {
            dispatch(ActivitiesAction.searchRequest({}, page, limit))
        },
    }
}
const DashboardConnect = (connect(mapStateToProps, mapDispatchToProps)(Dashboard));
export default withRouter(DashboardConnect);