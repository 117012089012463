import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LaddaButton, { ZOOM_IN, S } from 'react-ladda';
import { Alert, SelectPicker, RadioGroup, Radio, Toggle } from 'rsuite';
import * as UserAction from '../../actions/UserAction';
import * as UserActionType from '../../constants/UserActionType';
import ZoneSelectBox from '../shared/ZoneSelectBox';

class CreatePartnerMainInfo extends Component {
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error= target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
            this.props.onFocusField(field_error,false); 
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDateFieldChange = (values) => {
        //console.log(values)
        if(values !== null){
            this.props.onFieldChange("birthday",values.formattedValue);
        }
    }
    onCityChange(city){
        this.props.onFieldChange("city_selected",city);
    }
    onDistrictChange(district){
        this.props.onFieldChange("district_selected",district);
    }
    onWardChange(ward){
        this.props.onFieldChange("ward_selected",ward);
    }
    onSelectCustomerGroup(item){
        this.props.onFieldChange("customer_group_selected",item);
    }
    onSelectAvatar(file){
        this.props.onFieldChange("avatar", file)
    }
    onCustomerIsCompanyModeChange(checked){
        this.props.onFieldChange("is_company", (checked === true) ? 1 : 0)
    }
    onSelectPartnerType(item){
        this.props.onFieldChange("user_type",item);
    }
    onSubmit(){
        let { is_company, name, company, phone, address, note, city_selected, district_selected, ward_selected, tax_code, gender, email, user_type } = this.props.user;
        let partner_type = this.props.partner_type
        if (this.props.vendor === true){
            if (user_type === 0){
                Alert.error("Vui lòng chọn loại đối tác")
                return
            }
            partner_type = user_type
        }
        if (name === ""){
            this.props.onErrorField("name_error", true)
            let msg_error = "Vui lòng nhập họ tên"
            if(partner_type === UserActionType.SUPPLIER_USER_TYPE){
                msg_error = "Vui lòng nhập tên nhà cung cấp"
            }
            Alert.error(msg_error)
            this.name_input.focus()
            return
        }
        if(partner_type !== UserActionType.PAYMENT_OBJECT_USER_TYPE && phone === ""){
            this.props.onErrorField("phone_error", true)
            Alert.error("Vui lòng nhập điện thoại")
            this.phone_input.focus()
            return
        }
        if(partner_type === UserActionType.CUSTOMER_USER_TYPE){
            if (address === ""){
                this.props.onErrorField("address_error", true)
                Alert.error("Vui lòng nhập địa chỉ")
                this.address_input.focus()
                return
            }
            if (typeof city_selected.value === "undefined"){
                Alert.error("Vui lòng chọn tỉnh/thành phố")
                return
            }
            if (typeof district_selected.value === "undefined"){
                Alert.error("Vui lòng chọn quận/huyện")
                return
            }
        }
        let data_post = {
            name: name,
            phone: phone,
            address: address,
            city: parseInt(city_selected.value),
            district: parseInt(district_selected.value),
            ward: parseInt(ward_selected.value),
            tax_code,
            gender: parseInt(gender),
            company,
            email,
            note,
            is_company: parseInt(is_company),
            user_type: parseInt(partner_type)
        }
        //console.log(data_post);
        this.props.onSubmit(data_post, this.props.return_info)
    }
    componentWillUnmount() {
        this.props.getRef(undefined)
    }
    componentDidMount(){
        this.props.getRef(this)
    }
    componentDidUpdate(){
        if(this.props.user.return_info !== null && this.props.return_info === true){
            /*if (this.props.user.return_info.user_type === this.props.partner_type){
                this.props.return(this.props.user.return_info)
            }*/
            this.props.return(this.props.user.return_info)
            this.props.onResetDirect()
            this.zoneSelectBoxRef.onResetData()
        }
    }   
    render(){
        if (this.props.user.return_info === null && this.props.user.direct_to_list === true) {
            return <Redirect to={{ pathname: "/customers" }} />
        }
        let { name, name_active, name_error, is_company, company, company_active, phone, phone_active, phone_error, address, address_active, address_error, city_selected, district_selected, ward_selected, gender, save_button_loading, user_type } = this.props.user;
        
        let  name_field_class = "nk-int-st", company_field_class = "nk-int-st", phone_field_class = "nk-int-st", address_field_class = "nk-int-st"
        if (name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }
        if (phone !== "") {
            phone_active = true;
        }
        if(phone_active === true){
            phone_field_class += ' nk-toggled';
        }
        if(phone_error === true){
            phone_field_class += ' error';
        }
        if (address !== "") {
            address_active = true;
        }
        if(address_active === true){
            address_field_class += ' nk-toggled';
        }
        if(address_error === true){
            address_field_class += ' error';
        }
        if (company !== "") {
            company_active = true;
        }
        if(company_active === true){
            company_field_class += ' nk-toggled';
        }
        let partner_type_array = [
            {
                label: "Quản trị viên",
                value: UserActionType.STAFF_USER_TYPE
            },
            {
                label: "Khách hàng",
                value: UserActionType.CUSTOMER_USER_TYPE
            },
            {
                label: "Nhà vận chuyển",
                value: UserActionType.TRANSPORTER_USER_TYPE
            },
            {
                label: "Đối tượng chi trả",
                value: UserActionType.PAYMENT_OBJECT_USER_TYPE
            }
        ]
        let partner_type = this.props.partner_type
        if (this.props.vendor === true && user_type > 0){
            partner_type = user_type
        }
        let nameFieldText = "Họ tên"
        if(partner_type === UserActionType.SUPPLIER_USER_TYPE){
            nameFieldText = "Tên nhà cung cấp"
        }
        return (
            <div className="container">
                <div className="row">   
                    <div className="col-xs-12">
                        <div className="form-element-list form-add-customer">
                            { (partner_type === UserActionType.CUSTOMER_USER_TYPE || partner_type === UserActionType.TRANSPORTER_USER_TYPE || this.props.vendor === true) ?
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    <Toggle name="shipping_mode" size="lg" checkedChildren={"Công ty"} unCheckedChildren={"Cá nhân"} onChange={ this.onCustomerIsCompanyModeChange.bind(this) } checked={ is_company === 1 ? true : false} />
                                </div>
                                { this.props.vendor === true ? 
                                <div className="pull-left mr-left-5">
                                    <SelectPicker
                                            block
                                            searchable={false}
                                            cleanable={false}
                                            placeholder="Chọn loại đối tác"
                                            data={partner_type_array}
                                            value={this.props.user.user_type}
                                            onSelect={this.onSelectPartnerType.bind(this)}
                                        />
                                </div> : "" }
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <label>{nameFieldText}</label>
                                <div className={ name_field_class }>
                                    <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name}></input>
                                </div>
                            </div>
                            { is_company === 1 ?  
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={ company_field_class }>
                                    <label>Công ty</label>      
                                    <input type="text" className="form-control" autoComplete="off" name="company" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.company_input = input; }} value={company}></input>
                                </div>
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <label>Điện thoại</label>
                                <div className={ phone_field_class }>
                                    <input type="text" className="form-control" autoComplete="off" name="phone" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.phone_input = input; }} value={phone}></input>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <label>Địa chỉ</label>
                                <div className={ address_field_class }>
                                    <input type="text" className="form-control" autoComplete="off" name="address" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.address_input = input; }} value={address}></input>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <label>Khu vực</label>
                                <ZoneSelectBox appearance="subtle" city={city_selected} district={district_selected} ward={ward_selected} onCityChange={(city) => { this.onCityChange(city) }} onDistrictChange={(district) => { this.onDistrictChange(district) }} onWardChange={(ward) => { this.onWardChange(ward) }} getRef={ref => (this.zoneSelectBoxRef = ref)} />
                            </div>
                            { this.props.partner_type === UserActionType.CUSTOMER_USER_TYPE ?
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <label>Giới tính</label>
                                <div>
                                    <RadioGroup name="gender" inline value={gender} onChange={value => { this.props.onFieldChange('gender', value) }}>
                                        <Radio value="1">Nam</Radio>
                                        <Radio value="2">Nữ</Radio>
                                    </RadioGroup>
                                </div>
                            </div> : "" } 
                            <div className="form-group text-center">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={S}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-save"></i>&nbsp;LƯU THÔNG TIN
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CreatePartnerMainInfo.defaultProps = {
    return_info: false,
    vendor: false,
}
const mapStateToProps = state => {
    return {
        user: state.user,
        message: state.message
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(UserAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(UserAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(UserAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(UserAction.resetField())
        },
        onSubmit(data_post, return_info){
            dispatch(UserAction.addRequest(data_post, return_info))
        },
        onResetDirect(){
            dispatch(UserAction.resetDirect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (CreatePartnerMainInfo);
