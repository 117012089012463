import * as MessageActionType from '../constants/MessageActionType';

export const setErrorMessage = message => {
    return {
        type: MessageActionType.SET_ERROR_MESSAGE,
        message
    }
}
export const clearErrorMessage = () => {
    return {
        type: MessageActionType.CLEAR_ERROR_MESSAGE,
    }
}