import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import NumberFormat from 'react-number-format';
import * as ReceiptGoodAction from '../../actions/ReceiptGoodAction';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import logo from '../../logo.png';
import '../../print_a5.css';

class PrintReceiptGood extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)
        }
    }
    onInitData(id){
        this.props.onGetReceiptGood(id)
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let price_after = item.price * item.quantity
                 let total_amount = price_after
                 return (
                     <tr className="cl_handover" key={ index }>
                         <td>{ item.product_model }</td>
                         <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let { receipt_code, receipt_date, products, total_quantity, total_amount} = this.props.receipt_good;
        receipt_date = Helper.replaceMysqlDateTime(receipt_date)
        let receiver_address = this.props.receipt_good.receiver_address
        if(this.props.receipt_good.receiver_ward_selected !== undefined && this.props.receipt_good.receiver_ward_selected !== null && this.props.receipt_good.receiver_ward_selected.label !== undefined && this.props.receipt_good.receiver_ward_selected.label !== ""){
            receiver_address += ", " + this.props.receipt_good.receiver_ward_selected.label
        }
        if(this.props.receipt_good.receiver_district_selected !== undefined && this.props.receipt_good.receiver_district_selected !== null && this.props.receipt_good.receiver_district_selected.label !== undefined && this.props.receipt_good.receiver_district_selected.label !== ""){
            receiver_address += ", " + this.props.receipt_good.receiver_district_selected.label
        }
        if(this.props.receipt_good.receiver_city_selected !== undefined && this.props.receipt_good.receiver_city_selected !== null && this.props.receipt_good.receiver_city_selected.label !== undefined && this.props.receipt_good.receiver_city_selected.label !== ""){
            receiver_address += ", " + this.props.receipt_good.receiver_city_selected.label
        }
        return (
                <div className="face">
                    <div className="text-center hide-print">
                         <button className="btn btn-default btn-sm" onClick={() => { window.print() }}><i className="fa fa-print"></i>&nbsp;In</button>
                    </div>
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <div className="pull-left">
                                <img alt="" src={logo} style={ { "height":"80px" } } />
                                </div>
                                <div className="pull-right text-center">
                                    <span>MOBY KID</span><br />
                                    <span>ĐIỀU TỐT NHẤT CHO CON</span><br />
                                    <span>15i Nguyễn Thị Minh Khai, Phường Bến Nghé, Quận 1, HCM</span><br />
                                    <span>344A Lê Hồng Phong, Phường 1, Quận 10, HCM</span><br />
                                    <span>Hotline: 0986.680.500 - Website: moby.com.vn</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-center">
                    <h4>PHIẾU NHẬN HÀNG</h4>
                    Mã phiếu: {receipt_code}<br />
                    Ngày  <Moment format="DD">{ receipt_date }</Moment>  Tháng  <Moment format="MM">{ receipt_date }</Moment>  Năm <Moment format="YYYY">{ receipt_date }</Moment>
                </div>
                <div className="mr-top-10">
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td><strong>Khách hàng:&nbsp;</strong>{this.props.receipt_good.customer_name}</td>
                                <td><strong>Số điện thoại:&nbsp;</strong>{this.props.receipt_good.customer_phone}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">{ receiver_address ? <span><strong>Địa chỉ:&nbsp;</strong>{ receiver_address }</span> : "" }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mr-top-10">    
                    <table className="table table-sc-ex table-hover table-condensed">
                        <colgroup>
                            <col width="10%"></col>
                            <col></col>
                            <col width="10%"></col>
                            <col width="10%"></col>
                            <col width="10%"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Mã hàng</th>
                                <th>Tên hàng</th>
                                <th className="text-right">Số lượng</th>
                                <th className="text-right">Giá bán</th>
                                <th className="text-right">Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.showListProduct(products) }
                        </tbody>
                        <tbody>
                            <tr>
                                <td className="text-right" colSpan="4">Tổng số lượng</td>
                                <td className="text-right">{ total_quantity }</td>
                            </tr>
                            <tr>
                                <td className="text-right" colSpan="4">Tổng tiền hàng</td>
                                <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                            </tr>
                        </tbody>   
                    </table>  
                </div>   
                <div className="mr-top-10">
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td className="text-center"><strong>Khách hàng</strong></td>
                                <td className="text-center"><strong>Người nhận</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        receipt_good: state.receipt_good
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo: () => {
            dispatch(ReceiptGoodAction.emptyInfo())
        },
        onGetReceiptGood: (id) => {
            return dispatch(ReceiptGoodAction.getRequest(id))
        },
    }
}
const PrintReceiptGoodConnect = (connect(mapStateToProps, mapDispatchToProps)(PrintReceiptGood));
export default withRouter(PrintReceiptGoodConnect);