import * as AddressBookActionType from '../constants/AddressBookActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    user_id: 0,
    name: "",
    name_active: false,
    name_error: false,
    phone: "",
    phone_active: false,
    phone_error: false,
    address: "",
    address_active: false,
    address_error: false,
    city_selected: [],
    city_error: false,
    district_selected: [],
    district_error: false,
    ward_selected: [],
    field_focus: "",
    clear_zone: false,
    branch_id: 0,
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    search_keyword: "",
    search_name: "",
    search_phone: "",
    is_search: false,
    search_button_loading: false,
    save_button_loading: false,
};
const address_book = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case AddressBookActionType.ITEM_BUTTON_SPINNER_ADDRESS_BOOK:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case AddressBookActionType.BUTTON_SPINNER_ADDRESS_BOOK:
            return {
                ...state,
                save_button_loading: action.status
            };
        case AddressBookActionType.FOCUS_FIELD_ADDRESS_BOOK:
              return {
                ...state,
                [action.field]: action.value
              };
        case AddressBookActionType.ERROR_FIELD_ADDRESS_BOOK:
              return {
                ...state,
                [action.field]: action.value
              };
        case AddressBookActionType.FIELD_CHANGE_ADDRESS_BOOK:
              return {
                ...state,
                [action.field]: action.value
              };
        case AddressBookActionType.RESET_FIELD_ADDRESS_BOOK:
            return {
              ...state,
              id: 0,
              name: "",
              name_active: false,
              name_error: false,
              phone: "",
              phone_active: false,
              phone_error: false,
              address: "",
              address_active: false,
              address_error: false,
              city_selected: [],
              city_error: false,
              district_selected: [],
              district_error: false,
              ward_selected: [],
              field_focus: "",
              clear_zone: false,
              branch_id: 0,
            };
        case AddressBookActionType.RESET_FIELD_SEARCH_ADDRESS_BOOK:      
            return {
              ...state,
              search_keyword: "",
              search_name: "",
              search_phone: "",
              is_search: false,
              search_button_loading: false,
            };
        case AddressBookActionType.RESET_LIST_ADDRESS_BOOK:
          return {
            ...state,
            total: 0,
            limit: 10,
            total_page: 0,
            page: 1,
            list: [],
          }    
        case AddressBookActionType.GET_ADDRESS_BOOK:
            return {
                ...state,
                id: action.address_book.id,
                user_id: action.address_book.user_id,
                name: action.address_book.name,
                phone: action.address_book.phone,
                address: action.address_book.address,
                city: action.address_book.city,
                city_name: action.address_book.city_name,
                city_selected: {
                  label: action.address_book.city_name,
                  value: action.address_book.city
                },
                district: action.address_book.district,
                district_name: action.address_book.district_name,
                district_selected: {
                  label: action.address_book.district_name,
                  value: action.address_book.district
                },
                ward: action.address_book.ward,
                ward_name: action.address_book.ward_name,
                ward_selected: {
                  label: action.address_book.ward_name,
                  value: action.address_book.ward
                },
            };
        case AddressBookActionType.LIST_ADDRESS_BOOK:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                list: [...action.list]
              };
        case AddressBookActionType.SEARCH_ADDRESS_BOOK:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        default: 
            return state;
    }
}
export default address_book;