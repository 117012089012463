import * as ModalActionType from '../constants/ModalActionType';

export const showModal = (status, title, type, input, submit_type) => {
    return {
        type: ModalActionType.SHOW_MODAL,
        status,
        modal_title: title,
        modal_type: type,
        modal_input: input,
        modal_submit_type: submit_type
    }
}

export const buttonSpinner = status => {
    return {
        type: ModalActionType.MODAL_BUTTON_SPINNER,
        status
    }
}