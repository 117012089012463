import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AddressBookAction from '../../actions/AddressBookAction';
import ZoneSelectBox from '../shared/ZoneSelectBox';

class CreateAddressBook extends Component {
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error= target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
            this.props.onFocusField(field_error,false); 
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onCityChange(city){
        this.props.onFieldChange("city_selected",city);
    }
    onDistrictChange(district){
        this.props.onFieldChange("district_selected",district);
    }
    onWardChange(ward){
        this.props.onFieldChange("ward_selected",ward);
    }
    componentDidMount(){
        this.zoneSelectBoxRef.onResetData()
    }
    componentDidUpdate(){
        let field_focus = this.props.address_book.field_focus
        if(field_focus !== ""){
            if(field_focus === "name"){
                this.name_input.focus()
            } else if(field_focus === "phone"){
                this.phone_input.focus()
            } else if(field_focus === "address"){
                this.address_input.focus()
            }
            this.props.onFieldChange("field_focus","");
        }
        if (this.props.address_book.clear_zone === true){
            this.zoneSelectBoxRef.onResetData()
            this.props.onFieldChange("clear_zone",false);
        }
    }
    render(){
        let { name, name_active, name_error, phone, phone_active, phone_error, address, address_active, address_error, city_selected, district_selected, ward_selected } = this.props.address_book;
        let name_field_class = "nk-int-st", phone_field_class = "nk-int-st", address_field_class = "nk-int-st"
        if (name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }
        if (phone !== "") {
            phone_active = true;
        }
        if(phone_active === true){
            phone_field_class += ' nk-toggled';
        }
        if(phone_error === true){
            phone_field_class += ' error';
        }
        if (address !== "") {
            address_active = true;
        }
        if(address_active === true){
            address_field_class += ' nk-toggled';
        }
        if(address_error === true){
            address_field_class += ' error';
        }
        return (
            <div className="container">
                <div className="row">   
                    <div className="col-sm-8 col-xs-12 col-sm-offset-2">
                        <div className="form-element-list padd-top-20">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="nk-int-mk">
                                        <h2>Họ tên</h2>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className={ name_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } } onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name}></input>
                                        </div>
                                    </div>
                                    <div className="nk-int-mk">
                                        <h2>Điện thoại</h2>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className={ phone_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="phone" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.phone_input = input; }} value={phone}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="nk-int-mk">
                                        <h2>Địa chỉ</h2>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <div className={ address_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="address" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.address_input = input; }} value={address}></input>
                                        </div>
                                    </div>
                                    <div className="nk-int-mk">
                                        <h2>Khu vực</h2>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <ZoneSelectBox appearance="subtle" direction="horizontal" city={city_selected} district={district_selected} ward={ward_selected} onCityChange={(city) => { this.onCityChange(city) }} onDistrictChange={(district) => { this.onDistrictChange(district) }} onWardChange={(ward) => { this.onWardChange(ward) }} getRef={ref => (this.zoneSelectBoxRef = ref)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.user,
        address_book: state.address_book,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(AddressBookAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(AddressBookAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(AddressBookAction.fieldChange(field,value))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (CreateAddressBook);
