import * as BillLadingActionType from '../constants/BillLadingActionType';
import moment from 'moment';
var initialState = {
    id: 0,
    back_list: "",
    customer_selected: {},
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    scroll_get_more: false,
    customers: [],
    search_code: "",
    search_on_date: null,
    search_date: [],
    branch_selected: {},
    ship_status_select: [],
    search_status: {},
    search_customer_selected: null,
    search_transporter_selected: null,
    search_button_loading: false,
    save_button_loading: false,
};
const bill_lading = (state = initialState, action) => {
    switch(action.type){
        case BillLadingActionType.FIELD_CHANGE_BILL_LADINGS:
              return {
                ...state,
                [action.field]: action.value
              };
        case BillLadingActionType.FIELDS_CHANGE_BILL_LADINGS:
              return {
                ...state,
                ...action.fields
              };
        case BillLadingActionType.RESET_FIELD_SEARCH_BILL_LADINGS:     
          var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
          var to_date = moment().endOf('month').format('YYYY-MM-DD');
          var search_date = [];
          if(from_date !== ""){
              search_date.push(from_date)
          }
          if(to_date !== ""){
              search_date.push(to_date)
          } 
            return {
              ...state,
              search_code: "",
              search_date,
              branch_selected: {},
              ship_status_select: [],
              search_status: {},
              search_customer_selected: null,
              search_transporter_selected: null,
              search_button_loading: false,
            };
        case BillLadingActionType.LIST_BILL_LADINGS:
            let list = state.list
            if(state.scroll_get_more === true){
              if(action.page === 1){
                list = []
              }
              if(action.list.length > 0){
                action.list.forEach(item => {
                  list.push(item)
                })
              }
            } else{
              list = action.list
            }
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount: action.total_amount,
                list
              };
        case BillLadingActionType.SEARCH_BILL_LADINGS:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case BillLadingActionType.LIST_BILL_LADINGS_STATUS:
          return {
              ...state,
              ship_status_select: action.status
            }; 
        case BillLadingActionType.BUTTON_UPDATE_SPINNER_BILL_LADINGS:
          return {
              ...state,
              [action.button_name]: action.status
          };
        default: 
            return state;
    }
}
export default bill_lading;