import * as LoginActionType from './../constants/LoginActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    branch_id: 0,
    name: "",
    phone: "",
    email: "",
    password: "",
    transporter_info: {},
    email_active: false,
    password_active: false,
    email_error: false,
    password_error: false,
    loading: false,
    logout_from_web: false
};
const login = (state = initialState, action) => {
    switch(action.type){
        case LoginActionType.BUTTON_SPINNER_LOGIN_PAGE:
            return {
                ...state,
                loading: action.status
            };
        case LoginActionType.FOCUS_FIELD_LOGIN_PAGE:
              return {
                ...state,
                [action.field]: action.value
              };
        case LoginActionType.ERROR_FIELD_LOGIN_PAGE:
              return {
                ...state,
                [action.field]: action.value
              };
        case LoginActionType.FIELD_CHANGE_LOGIN_PAGE:
              return {
                ...state,
                [action.field]: action.value
              };
        case LoginActionType.RESET_FIELD_LOGIN_PAGE:
            return {
              ...state,
              email: "",
              password: "",
              email_active: false,
              password_active: false,
              email_error: false,
              password_error: false,
            };
        case LoginActionType.SET_TOKEN:
            //console.log("token user: ",action.user)
            Helper.setToken(action.token);
            Helper.setUser(action.user);
            Helper.setUserRole(action.user.role);
            Helper.setLanguage(action.language);
            return {
                ...state,
                id: action.user.id,
                name: action.user.name,
                phone: action.user.phone,
                email: action.user.email,
                branch_id: action.user.branch_id
            };
        case LoginActionType.SET_AUTHENTICATE:
            var user = {
              id: state.id,
              name: state.name,
              phone: state.phone,
              email: state.email,
              branch_id: state.branch_id
            } 
            if (action.authenticated === false) {
              //console.log("action.authenticated: ",action.authenticated)
              Helper.removeLocalStore()
              user = {
                id: 0,
                name: "",
                phone: "",
                email: "",
                branch_id: 0
              } 
            }
            return {
              ...state,
              id: user.id,
              name: user.name,
              phone: user.phone,
              email: user.email,
              branch_id: user.branch_id
            };
        case LoginActionType.SET_USER_INFO:
          //console.log("set user store: ",action.user)
            Helper.setUser(action.user);
            return {
              ...state,
              id: action.user.id,
              name: action.user.name,
              phone: action.user.phone,
              email: action.user.email,
              branch_id: action.user.branch_id
            };
        case LoginActionType.GET_STAFF_TRANSPORTER:
          let transporter_info = {
            id: action.transporter_info.id,
            name: action.transporter_info.name,
            phone: action.transporter_info.phone,
            address: action.transporter_info.address
          }
          Helper.setStaffTransporter(transporter_info)
          return {
            ...state
          };
        default: 
            return state;
    }
}
export default login;