import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { DatePicker, Alert } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import * as VendorBillAction from '../../actions/VendorBillAction';
import * as VendorBillActionType from '../../constants/VendorBillActionType';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';

class ViewVendorBill extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("bill_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onInitData(id){
        this.props.onGetVendorBill(id)
        this.props.onGetPaymentBills(id)
        this.props.onGetStaffNotes(id)
        this.props.onGetLastStaffNote(id)
    }
    onSaveData(){
        if(Helper.checkMyRole('update_vendor_bill_date') === true && this.props.vendor_bill.bill_date === ""){
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng chọn thời gian mua hàng'
            })
            return;
        } 
        let data_post = {}
        let has_update = false
        if (Helper.checkMyRole('update_vendor_bill_date') === true){
            data_post.bill_date = this.props.vendor_bill.bill_date
            has_update = true
        }
        if(this.props.vendor_bill.staff_notes_input !== ""){
            data_post.staff_notes = this.props.vendor_bill.staff_notes_input
            has_update = true
        }
        if (has_update === true){
            this.props.onUpdateData(data_post, this.props.vendor_bill.id, "save_button_loading").then(result => {
                if(result.status === true){
                    Alert.success(result.message)
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        }
    }
    onAddNewNote(){
        if(this.props.vendor_bill.staff_notes_input === ""){
            this.props.onErrorField("staff_notes_error",true)
            this.notes_input.focus()
            Swal.fire({
                icon: 'error',
                title: 'Thông báo lỗi',
                text: 'Vui lòng nhập ghi chú'
            })
            return;
        }
        this.props.onUpdateData({
            staff_notes: this.props.vendor_bill.staff_notes_input
        }, this.props.vendor_bill.id, "save_note_button_loading").then(result => {
            if(result.status === true){
                Alert.success(result.message)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
        this.notes_input.value = ""
    }
    onSetBillStatus(status){
        if(status === VendorBillActionType.STATUS_FINISH_VENDOR_BILL){
            Swal.fire({
                title: 'Xác nhận',
                text: "Bạn thật sự muốn hoàn thành hóa đơn này?",
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                reverseButtons: true,
                confirmButtonText: 'ĐỒNG Ý',
                cancelButtonText: 'KHÔNG',
                preConfirm: () => {
                    this.props.onSetBillStatus(this.props.vendor_bill.id, {
                        status
                    }).then(result => {
                        if(result.status === true){
                            this.props.onGetVendorBill(this.props.vendor_bill.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                },
            })
        } else if(status === VendorBillActionType.STATUS_CANCEL_VENDOR_BILL){
            Swal.fire({
                title: 'Vui lòng nhập lý do hủy',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Tiếp tục',
                showLoaderOnConfirm: true,
                preConfirm: (reason_cancel) => {
                    if(reason_cancel !== ""){
                        this.props.onSetBillStatus(this.props.vendor_bill.id, {
                            status,
                            reason_cancel
                        }).then(result => {
                            if(result.status === true){
                                this.props.onGetVendorBill(this.props.vendor_bill.id)
                            }
                            else if(result.status === false && result.message !== undefined && result.message !== ""){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Thông báo lỗi',
                                    text: result.message
                                })
                            }
                        })
                    } else {
                        Swal.showValidationMessage('Vui lòng nhập lý do')
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                //console.log("result: ",result)
                //console.log("result.isConfirmed: ",result.isConfirmed)
                if (result.isConfirmed) {
                    this.props.onGetVendorBill(this.props.vendor_bill.id)
                }
            })
        }
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let price_after = item.price * item.quantity
                 let discount_amount = item.discount_money
                 let discount_text = <NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} />
                 if (item.discount > 0){
                    discount_amount = price_after * item.discount / 100
                    discount_text = <span>({ item.discount }%)&nbsp;<NumberFormat value={discount_amount} displayType={'text'} thousandSeparator={true} /></span>
                 }
                 price_after = price_after - discount_amount
                 if (item.tax > 0){
                    price_after = price_after + (price_after * item.tax / 100)
                 }
                 let total_amount = price_after
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onProductItemClick(item.id,item.product_parse_name) }}>
                         <td className="text-center">{ index + 1 }</td>
                         <td>{ item.name } { item.description !== "" ? <small><br />{item.description}</small> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">{ discount_text }</td>
                         <td className="text-right">{item.tax}</td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }

    showListPaymentBills(lists){
        var result = null;
        if(lists.length > 0){
            let accounting_access = false
            if (Helper.checkMyRole('accounting_access') === true){
                accounting_access = true
            }
            result = lists.map((item, index) => {
                 return (
                     <tr key={ index }>
                         <td>{ item.payment_bill_code }</td>
                         <td>{ moment(Helper.replaceMysqlDateTime(item.payment_bill_date), moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</td>
                         <td className="text-right"><NumberFormat value={item.amount - item.amount_return} displayType={'text'} thousandSeparator={true} /></td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">
                            { accounting_access === true ? <Link to={`/accounting/payment-bill/${item.id}`} target="_blank">Xem hóa đơn</Link> : "" }
                         </td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.vendor_bill.id, id)
            },
        })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr key={ index }>
                         <td>{ item.content }</td>
                         <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">
                            { this.props.vendor_bill.bill_status === VendorBillActionType.STATUS_NEW_VENDOR_BILL ? 
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton> : "" }
                         </td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let { bill_code, bill_date, bill_status, products, total_quantity, total_amount, paid_amount, object_name, object_info, staff_notes, staff_notes_input, staff_notes_active, staff_notes_error, save_button_loading, tax, payment_bills } = this.props.vendor_bill;
        bill_date = Helper.replaceMysqlDateTime(bill_date)
        let object_address = object_info.address
        if (object_info.ward_name){
            object_address += ", " + object_info.ward_name
        }
        if (object_info.district_name){
            object_address += ", " + object_info.district_name
        }
        if (object_info.city_name){
            object_address += ", " + object_info.city_name
        }
        let object_info_text = []
        object_info_text.push(<span key="object_name">{object_name}</span>)
        if (object_info.phone !== ""){
            object_info_text.push(<span key="object_phone"> - {object_info.phone}</span>)
        }
        if (object_info.company !== ""){
            object_info_text.push(<span key="object_company"><br />{object_info.company}</span>)
        }
        if (object_address !== ""){
            object_info_text.push(<span key="object_address"><br />{object_address}</span>)
        }

        let notes_field_class = "nk-int-st"
        if (staff_notes_input !== "") {
            staff_notes_active = true;
        }
        if(staff_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        if(staff_notes_error === true){
            notes_field_class += ' error';
        }
        let allow_update = false, allow_update_date = false, allow_add_new_note = true, allow_update_finish_status = false, allow_update_cancel_status = false
        if(Helper.checkMyRole('update_vendor_bill_date') === true){
            allow_update_date = true
            allow_update = true
        }
        if (Helper.checkMyRole('update_vendor_bill_cancel') === true){
            allow_update_cancel_status = true
        }
        if (Helper.checkMyRole('update_vendor_bill_finish') === true){
            allow_update_finish_status = true
        }
        if (bill_status === VendorBillActionType.STATUS_FINISH_VENDOR_BILL || bill_status === VendorBillActionType.STATUS_CANCEL_VENDOR_BILL){
            allow_add_new_note = false
            allow_update_cancel_status = false
            allow_update_finish_status = false
            allow_update = false
            allow_update_date = false
        }
        let html_alert_bill_status = ""
        if(bill_status === VendorBillActionType.STATUS_FINISH_VENDOR_BILL && this.props.vendor_bill.staff_finish_id > 0){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-success text-center">
                    <h3>{ this.props.vendor_bill.staff_finish_name } hoàn thành lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.vendor_bill.finish_time }</Moment></h3>
                </div>
            </div>  
        } else if(bill_status === VendorBillActionType.STATUS_CANCEL_VENDOR_BILL){
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.vendor_bill.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.vendor_bill.cancel_time }</Moment><br />
                        Lý do: { this.props.vendor_bill.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        let total_final_amount = total_amount
        if (tax > 0){
            total_final_amount = total_amount + (total_amount * tax / 100)
        }
        let remain_amount = total_final_amount - paid_amount
        return (
            <div className="container">
                { html_alert_bill_status }
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 col-xs-12">
                        <div className="pull-left">
                            <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                                <li>
                                    <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                                </li>
                                <li className="active">
                                    <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                                </li>
                                { payment_bills.length > 0 ?
                                <li>
                                    <a data-toggle="tab" href="#payment" aria-expanded="false">Lịch sử thanh toán</a>
                                </li> : "" }
                                <li>
                                    <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                                </li>
                            </ul>
                        </div>         
                        <div className="pull-right">
                            { allow_update === true ? 
                            <LaddaButton
                                loading={ save_button_loading } 
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-primary mr-right-5 notika-btn-success waves-effect" onClick={ () => { this.onSaveData() } } >
                                <i className="fa fa-save"></i>&nbsp;Lưu thông tin phiếu
                            </LaddaButton> : "" }
                            { allow_update_finish_status === true ?
                            <button type="button"
                                className="btn btn-success mr-right-5" onClick={ () => { this.onSetBillStatus(VendorBillActionType.STATUS_FINISH_VENDOR_BILL) } } >
                                <i className="fa fa-check"></i>&nbsp;Hoàn thành
                            </button> : "" }
                            { allow_update_cancel_status === true ?
                            <button type="button"
                                className="btn btn-danger" onClick={ () => { this.onSetBillStatus(VendorBillActionType.STATUS_CANCEL_VENDOR_BILL) } } >
                                <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                            </button> : "" }
                        </div>
                        <div className="cl"></div>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in">
                                <div className="normal-table-list">
                                    <div className="basic-tb-hd">
                                        <h2>
                                        Phiếu mua hàng { bill_code }
                                        </h2>
                                        <p></p>
                                    </div>
                                    <div className="bsc-tbl">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1 col-xs-12">
                                            <div className="row">
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className="nk-int-mk mr-bot-5">
                                                        <h2>Ngày mua hàng</h2>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                    { allow_update_date === true ? 
                                                    <div>
                                                       <DatePicker format="YYYY-MM-DD HH:mm" value={new Date(moment(bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())} placeholder={moment().format("YYYY-MM-DD HH:mm")} placement="bottomStart" oneTap cleanable={false} ranges={[]} block onChange = { (value) => { this.onDatePickerChange(value) } } />
                                                    </div> : <span>{ moment(bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>  }
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className="nk-int-mk mr-bot-5">
                                                        <h2>Đối tác giao dịch</h2>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        { object_info_text }    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="normal-table-list mr-top-20">
                                                    <div className="bsc-tbl">
                                                        <table className="table table-sc-ex table-hover table-condensed">
                                                            <colgroup>
                                                                <col width="5%"></col>
                                                                <col></col>
                                                                <col width="10%"></col>
                                                                <col width="10%"></col>
                                                                <col width="10%"></col>
                                                                <col width="10%"></col>
                                                                <col width="10%"></col>
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th>Tên hàng</th>
                                                                    <th className="text-right">Số lượng</th>
                                                                    <th className="text-right">Đơn giá</th>
                                                                    <th className="text-right">Giảm giá</th>
                                                                    <th className="text-right">Thuế (%)</th>
                                                                    <th className="text-right">Thành tiền</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { this.showListProduct(products) }
                                                            </tbody>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="6" className="text-right">Tổng số lượng</td>
                                                                    <td className="text-right">{ total_quantity }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="6" className="text-right">Tạm tính</td>
                                                                    <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="6" className="text-right">Thuế (%)</td>
                                                                    <td className="text-right">{ tax }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="6" className="text-right">Tổng tiền</td>
                                                                    <td className="text-right"><NumberFormat value={total_final_amount} displayType={'text'} thousandSeparator={true} /></td>
                                                                </tr>
                                                                { paid_amount > 0 ? 
                                                                <tr>
                                                                    <td colSpan="6" className="text-right">Đã thanh toán</td>
                                                                    <td className="text-right"><NumberFormat value={paid_amount} displayType={'text'} thousandSeparator={true} /></td>
                                                                </tr> : null }
                                                                { paid_amount > 0 ? 
                                                                <tr>
                                                                    <td colSpan="6" className="text-right">Còn lại</td>
                                                                    <td className="text-right"><NumberFormat value={remain_amount} displayType={'text'} thousandSeparator={true} /></td>
                                                                </tr> : null }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            { payment_bills.length > 0 ?
                            <div id="payment" className="tab-pane fade bg-white padd-10">
                                <table className="table table-sc-ex table-hover table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã phiếu</th>
                                            <th>Thời gian</th>
                                            <th className="text-right">Số tiền</th>
                                            <th>Người xử lý</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListPaymentBills(payment_bills) }
                                    </tbody>
                                </table>
                            </div> : null }
                            <div id="note" className="tab-pane fade bg-white padd-10">
                                <div className="row">
                                    <div className="col-md-8 col-md-offset-2 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            { allow_add_new_note === true ?
                                            <div className="row mr-top-10">    
                                                <div className="col-xs-12">
                                                    <div className={notes_field_class}>
                                                        <TextareaAutoHeight placeholder="Thêm ghi chú" name="staff_notes_input" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                                    </div>
                                                </div>
                                            </div> : "" }
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        { staff_notes.length > 0 ?
                                        <table className="table table-sc-ex table-condensed w-auto">
                                            <thead>
                                                <tr>
                                                    <th>Nội dung</th>
                                                    <th width="20%">Thời gian</th>
                                                    <th width="20%">Người tạo</th>
                                                    { this.props.vendor_bill.bill_status === VendorBillActionType.STATUS_NEW_VENDOR_BILL ? <th width="5%">&nbsp;</th> : <th></th> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showListStaffNotes(staff_notes) }
                                            </tbody>
                                        </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vendor_bill: state.vendor_bill,
        modal: state.modal,
        user: state.user,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(VendorBillAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(VendorBillAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(VendorBillAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(VendorBillAction.emptyInfo())
        },
        onGetVendorBill: (id) => {
            dispatch(VendorBillAction.getRequest(id))
        },
        onGetPaymentBills: id => {
            dispatch(VendorBillAction.getPaymentBillsRequest(id))
        },
        onSetBillStatus: (id, data) => {
            return dispatch(VendorBillAction.setBillStatus(id, data))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(VendorBillAction.updateRequest(data_post, id, button_name))
        },
        onGetStaffNotes: id => {
            dispatch(VendorBillAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(VendorBillAction.getLastStaffNoteRequest(id))
        },
        onRemoveStaffNote: (id,staff_note_id) => {
            dispatch(VendorBillAction.removeStaffNoteRequest(id, staff_note_id))
        },
    }
}
const ViewVendorBillConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewVendorBill));
export default withRouter(ViewVendorBillConnect);