import * as ReturnInvoiceActionType from '../constants/ReturnInvoiceActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: ReturnInvoiceActionType.FOCUS_FIELD_RETURN_INVOICE,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: ReturnInvoiceActionType.ERROR_FIELD_RETURN_INVOICE,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: ReturnInvoiceActionType.FIELDS_CHANGE_RETURN_INVOICE,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: ReturnInvoiceActionType.FIELD_CHANGE_RETURN_INVOICE,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: ReturnInvoiceActionType.EMPTY_RETURN_INVOICE
    }
}
export const resetFieldSearch = () => {
    return {
        type: ReturnInvoiceActionType.RESET_FIELD_SEARCH_RETURN_INVOICE
    }
}

export const getRequest = (id) => {
    return (dispatch) => {
        return apiCaller.callApi(`return-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

const get = return_invoice => {
    return {
        type: ReturnInvoiceActionType.GET_RETURN_INVOICE,
        return_invoice,
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "return_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: ReturnInvoiceActionType.GET_STAFF_NOTES_RETURN_INVOICE,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "return_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: ReturnInvoiceActionType.GET_LAST_STAFF_NOTES_RETURN_INVOICE,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}


export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`return-bills`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(ListReturnInvoice(res.data.result));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`return-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(ListReturnInvoice(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}
const ListReturnInvoice = (result) => {
    return {
        type: ReturnInvoiceActionType.LIST_RETURN_INVOICE,
        ...result
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: ReturnInvoiceActionType.SEARCH_RETURN_INVOICE,
        search_button_loading
    }
}
export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `return-bills/${id}`;
        let _method = 'POST';
        if(button_name !== ""){
            dispatch(updateButtonSpinner(button_name,true))
        }
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if(button_name !== "ship_status"){
                    dispatch(getRequest(id))
                }
                if (button_name === "save_note_button_loading"){
                    dispatch(getLastStaffNoteRequest(id))
                    dispatch(getStaffNotesRequest(id))
                }
                if(button_name !== "" && button_name !== "ship_status"){
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: ReturnInvoiceActionType.BUTTON_UPDATE_SPINNER_RETURN_INVOICE,
        button_name,
        status
    }
}


export const cancelRequest = (id, data) => {
    return () => {
        let data_post = {
            reason_cancel: data.reason_cancel
        }
        return apiCaller.callApi(`return-bills/cancel/${id}`,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}