import * as PaymentInvoiceActionType from '../constants/PaymentInvoiceActionType';
import * as PurchaseOrderActionType from '../constants/PurchaseOrderActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: PaymentInvoiceActionType.FOCUS_FIELD_PAYMENT_INVOICE,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: PaymentInvoiceActionType.ERROR_FIELD_PAYMENT_INVOICE,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: PaymentInvoiceActionType.FIELDS_CHANGE_PAYMENT_INVOICE,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: PaymentInvoiceActionType.FIELD_CHANGE_PAYMENT_INVOICE,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: PaymentInvoiceActionType.EMPTY_PAYMENT_INVOICE
    }
}

export const resetField = () => {
    return {
        type: PaymentInvoiceActionType.RESET_FIELD_PAYMENT_INVOICE
    }
}

export const resetFieldSearch = () => {
    return {
        type: PaymentInvoiceActionType.RESET_FIELD_SEARCH_PAYMENT_INVOICE
    }
}

export const buttonSpinner = status => {
    return {
        type: PaymentInvoiceActionType.BUTTON_SPINNER_PAYMENT_INVOICE,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: PaymentInvoiceActionType.ITEM_BUTTON_SPINNER_PAYMENT_INVOICE,
        id,
        status
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result))
                    return res.data.result
                }
            }
        });
    }
}

export const get = invoice => {
    return {
        type: PaymentInvoiceActionType.GET_PAYMENT_INVOICE,         
        invoice
    }
}

export const getBillStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/status`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getBillStatus(res.data.result.list, res.data.result.color))
            }
        });
    }
}

const getBillStatus = (list, color) => {
    return {
        type: PaymentInvoiceActionType.GET_BILL_STATUS_PAYMENT_INVOICE,
        list,
        color
    }
}
export const addNewNoteRequest = (data_post, id) => {
    return (dispatch) => {
        dispatch(updateButtonSpinner("save_note_button_loading",true))
        return apiCaller.callApi(`notes/new`,{
            "content": data_post.staff_notes,
            "payment_bill_id": id
        },'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
                dispatch(updateButtonSpinner("save_note_button_loading",false))
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "payment_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: PaymentInvoiceActionType.GET_STAFF_NOTES_PAYMENT_INVOICE,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "payment_bill_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: PaymentInvoiceActionType.GET_LAST_STAFF_NOTES_PAYMENT_INVOICE,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}
export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listPaymentInvoice(res.data.result));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`payment-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listPaymentInvoice(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const listPaymentInvoice = (result) => {
    return {
        type: PaymentInvoiceActionType.LIST_PAYMENT_INVOICE,
        ...result
    }
}
const searchButtonSpinner = (search_button_loading) => {
    return {
        type: PaymentInvoiceActionType.SEARCH_PAYMENT_INVOICE,
        search_button_loading
    }
}

export const listStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: PaymentInvoiceActionType.LIST_PAYMENT_INVOICE_STATUS,
        status
    }
}

export const setBillStatus = (id, data) => {
    return (dispatch) => {
        let _url = `payment-bills/finish/${id}`
        let data_post = null
        if(data.status === PaymentInvoiceActionType.STATUS_CANCEL_PAYMENT_INVOICE){
            _url = `payment-bills/cancel/${id}`
            data_post = {
                reason_cancel: data.reason_cancel
            }
        }
        return apiCaller.callApi(_url,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: PaymentInvoiceActionType.BUTTON_UPDATE_SPINNER_PAYMENT_INVOICE,
        button_name,
        status
    }
}

export const setInvoiceProducts = products => {
    return {
        type: PaymentInvoiceActionType.SET_PRODUCTS_PAYMENT_INVOICE,
        products
    }
}

export const getReceiptForPaymentRequest = (id, is_supplier) => {
    return (dispatch) => {
        let _url = `vendor-bills`
        let data_post = {
            from_payment: 1,
            object_id: id
        }
        if(is_supplier === true){
            _url = `import-receipts`
            data_post = {
                is_liabilities: 1,
                supplier_id: id,
                receipt_status: PurchaseOrderActionType.STATUS_FINISH_PURCHASE_ORDER
            }
        }
        return apiCaller.callApi(_url,data_post,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(getReceiptForPayment(res.data.result.list))
                }
            } 
        });
    }
}
const getReceiptForPayment = receipts => {
    return {
        type: PaymentInvoiceActionType.SET_RECEIPTS_PAYMENT_INVOICE,
        receipts
    }
}

export const getImportReceiptRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`import-receipts/${id}`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                return res.data.result
            }
        });
    }
}

export const getVendorBillRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`vendor-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    return res.data.result
                }
            }
        });
    }
}

export const updateAmountItem = (id, finance_sources) => {
    return {
        type: PaymentInvoiceActionType.UPDATE_AMOUNT_PAYMENT_INVOICE,
        id,
        finance_sources
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`payment-bills/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (res.data.result !== undefined && res.data.result !== null){
                    dispatch(resetField())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const updatePaymentRequest = (id, data_post)  => {
    return () => {
        return apiCaller.callApi(`payment-bills/${id}`,data_post,'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}