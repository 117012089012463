import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';

class InvoiceForProduct extends Component {
    constructor() {
        super();
        this.state = {
            product_id: 0
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.product_id!==prevState.product_id){
          return { product_id: nextProps.product_id};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.product_id!==this.props.product_id){
            this.setState({product_id: this.props.product_id});
            this.props.onSearchInvoices({
                product_id: this.props.product_id
            },1,this.props.limit, true);
        } 
    }
    componentDidMount(){
        this.props.onSearchInvoices({
            product_id: this.props.product_id
        },1,this.props.limit, true);
    }
    onItemClick(id){
        window.open("/invoice/" + id, "_blank");
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let is_reorder_text = ""
                let receipt_bill_status_color = item.receipt_bill_status_color
                if (item.is_reorder === 1){
                    if(item.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
                        is_reorder_text = <span className="text-danger">Re-order<br /></span>
                    } else if (item.is_in_stock){
                        receipt_bill_status_color = "#3598fe"
                    }
                }
                 return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                        <td>{item.receipt_bill_code}</td>
                        <td>
                           <Moment format="DD/MM/YYYY">
                           { item.receipt_bill_date }
                           </Moment>
                        </td>
                        <td></td>
                        <td>{ item.customer_name }</td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_after_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_payed_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center">{is_reorder_text}<label className='label' style={{backgroundColor: receipt_bill_status_color }}>{ item.receipt_bill_status_text }</label></td>
                    </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, list } = this.props.invoice;
        let btn_view_more = ""
        if(total > limit){
            btn_view_more = (
                <div className="mr-top-10 text-center">
                    <Link to={"/invoices?product_id=" + this.props.product_id} className="btn btn-default btn-sm">Xem thêm</Link>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        { list.length > 0 ? 
                            <div>
                                <div className="normal-table-list">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex">
                                        <colgroup>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Mã hóa đơn</th>
                                                    <th>Thời gian</th>
                                                    <th>Mã trả hàng</th>
                                                    <th>Khách hàng</th>
                                                    <th className="text-right">Tổng tiền hàng</th>
                                                    <th className="text-right">Giảm giá</th>
                                                    <th className="text-right">Tổng sau giảm</th>
                                                    <th className="text-right">Khách đã trả</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showList(list) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                { btn_view_more }
                            </div>
                         :  <div className="alert alert-warning">Không có thông tin nào</div>
                         }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onSearchInvoices: (data_search,page,limit,spinner) => {
            dispatch(InvoiceAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(InvoiceForProduct)