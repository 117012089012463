import React, { Component } from 'react';
import { TreePicker, CheckTreePicker } from 'rsuite';
import apiCaller from '../../utils/apiCaller';
class PaymentFilter extends Component {
    constructor() {
        super();
        this.amounted = true
        this.state = {
            lists: [],
            selected: ""
        }
        this.onChange = this.onChange.bind(this);
        this.onChangeMulti = this.onChangeMulti.bind(this);
    }
    componentWillUnmount(){
        this.amounted = false
    }
    componentDidMount(){
        apiCaller.callApi(`finance-sources`,{
            limit: 0,
            page: 1,
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    let lists = [
                        {
                            label: "Tiền mặt",
                            value: "cash"
                        }
                    ]
                    if (this.props.has_cod === true){
                        lists.push({
                            label: "Thu hộ (COD)",
                            value: "cod"
                        })
                    }
                    if (list.length > 0){
                        let list_cards = [], list_banks = []
                        list.forEach(item => {
                            let label = item.name + " " + item.account_number
                            if (item.is_card === 1) {
                                list_cards.push({
                                    label: label,
                                    value: item.id
                                })
                            } else if (item.is_bank === 1) {
                                list_banks.push({
                                    label: label,
                                    value: item.id
                                })
                            }
                        })
                        if (list_cards.length > 0){
                            lists.push({
                                label: "Quẹt thẻ",
                                value: "card",
                                children: list_cards
                            })
                        }
                        if (list_banks.length > 0){
                            lists.push({
                                label: "Chuyển khoản",
                                value: "bank",
                                children: list_banks
                            })
                        }
                    }
                    this.setState({
                        lists
                    })
                }
            }
        });
    }
    onChange(value) {
        if(value !== "card" && value !== "bank"){
            this.setState({
                selected: value
            });
            let name = ""
            if(value !== null){
                name = "Tiền mặt"
                if(value === 'cod'){
                    name = "Thu hộ (COD)"
                }
                if(value !== 'cash' && value !== 'cod'){
                    this.state.lists.forEach(item => {
                        if((item.value === "bank" || item.value === "card") && item.children.length > 0){
                            item.children.forEach(childItem => {
                                if (parseInt(childItem.value) === parseInt(value)) {
                                    if(item.value === "bank"){
                                        name = 'Chuyển khoản'
                                    } else if(item.value === "card"){
                                        name = 'Quẹt thẻ'
                                    }
                                } 
                            })
                        }
                    })
                }
            } else {
                value = ""
            }
            this.props.onChange(value,name)
        }
      }
      onChangeMulti(values) {
        this.setState({
            selected: values
        });
        let final_values = []
        values.forEach(value => {
            if(value === "cash" || value === "cod"){
                final_values.push(value)
            }
            else if (value === "bank" || value === "card"){
                this.state.lists.forEach(item => {
                    if(item.value === value && item.children.length > 0){
                        item.children.forEach(childItem => {
                            final_values.push(childItem.value)
                        })
                    }
                })
            } else{
                final_values.push(value)
            }
        })
        this.props.onChange(final_values,"")
      }
    render(){
        let appearance = (this.props.appearance !== "")? this.props.appearance : "default"
        if (this.props.multi === true){
            let value_selected = []
            if(this.state.selected !== undefined && this.state.selected !== null && this.state.selected !== ""){
                value_selected = this.state.selected
            } else if (this.props.value !== undefined && this.props.value !== null && this.props.value !== ""){
                value_selected = this.props.value
            }
            return (
                <CheckTreePicker
                    placeholder={this.props.placeholder}
                    placement={this.props.placement}
                    cleanable={true}
                    searchable={false}
                    defaultExpandAll={this.props.defaultExpandAll}
                    appearance={appearance}
                    data={this.state.lists}
                    value={value_selected}
                    onChange={this.onChangeMulti}
                    style={{ width: 207 }}
                />
            )
        } else {
            let value_selected = 0
            if(this.state.selected !== undefined && this.state.selected !== null && this.state.selected !== ""){
                value_selected = this.state.selected
            } else if (this.props.value !== undefined && this.props.value !== null && this.props.value !== ""){
                value_selected = this.props.value
            }
            return <TreePicker
                placeholder={this.props.placeholder}
                placement={this.props.placement}
                cleanable={true}
                searchable={false}
                defaultExpandAll={this.props.defaultExpandAll}
                appearance={appearance}
                data={this.state.lists}
                value={value_selected}
                onChange={this.onChange}
                style={{width: 207}}
                />
        }
    }
}

PaymentFilter.defaultProps = {
    appearance: "",
    placement: "bottomStart",
    defaultExpandAll: true,
    has_cod: true,
}

export default PaymentFilter;