import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import apiCaller from '../../utils/apiCaller';
import * as InvoiceActionType from '../../constants/InvoiceActionType';

class LiabilitiesForCustomer extends Component {
    constructor() {
        super();
        this.state = {
            list_invoice: [],
            total_invoice: 0,
            limit_invoice: 0,
            customer_id: 0
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.customer_id!==prevState.customer_id){
          return { customer_id: nextProps.customer_id};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.customer_id!==this.props.customer_id){
            this.setState({customer_id: this.props.customer_id});
            this.onSearchInvoices({
                customer_id: this.props.customer_id,
                receipt_status: InvoiceActionType.STATUS_IN_PROGRESS_INVOICE,
                is_liabilities: 1
            },1,this.props.limit)
        } 
    }
    componentDidMount(){
        this.onSearchInvoices({
            customer_id: this.props.customer_id,
            receipt_status: InvoiceActionType.STATUS_IN_PROGRESS_INVOICE,
            is_liabilities: 1
        },1,this.props.limit)
    }
    onSearchInvoices(data_search, page, limit){
        apiCaller.callApi(`receipt-bills`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit } = res.data.result;
                    this.setState({
                        list_invoice: list,
                        total_invoice: total,
                        limit_invoice: limit,
                    })
                }
            }
        });
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let remain_amount = item.total_after_discount - item.total_payed_amount
                 return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                        <td>{item.receipt_bill_code}</td>
                        <td>
                           <Moment format="DD/MM/YYYY">
                           { item.receipt_bill_date }
                           </Moment>
                        </td>
                        <td>Bán hàng</td>
                        <td className="text-right"><NumberFormat value={item.total_after_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_payed_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={remain_amount} displayType={'text'} thousandSeparator={true} /></td>
                    </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total_invoice, limit_invoice, list_invoice } = this.state;
        let btn_view_more = ""
        if(total_invoice > limit_invoice){
            btn_view_more = (
                <div className="mr-top-10 text-center">
                    <Link to={"/invoices?customer_id=" + this.props.customer_id} className="btn btn-default btn-sm">Xem thêm</Link>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        { list_invoice.length > 0 ? 
                            <div>
                                <div className="normal-table-list">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex">
                                        <colgroup>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Mã phiếu</th>
                                                    <th>Thời gian</th>
                                                    <th>Loại</th>
                                                    <th className="text-right">Tổng tiền</th>
                                                    <th className="text-right">Đã thanh toán</th>
                                                    <th className="text-right">Còn nợ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showList(list_invoice) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                { btn_view_more }
                            </div>
                         :  <div className="alert alert-warning">Không có thông tin nào</div>
                         }
                    </div>
                </div>
            </div>
        );
    }
}
 export default withRouter(LiabilitiesForCustomer)