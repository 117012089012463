import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from "react-router-dom";
import { SelectPicker, Icon } from 'rsuite';
import Helper from '../utils/Helper';
import * as LoginAction from '../actions/LoginAction';
import logo from '../logo.png';
class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            branchs: [],
            branch_selected: {}
        }
        this.onSelect = this.onSelect.bind(this);
    }
    componentDidMount(){
        let logout_from = Helper.getQueryString('logout_from');
        if(logout_from === "web"){
            if(process.env.REACT_APP_SYNC_ACCOUNT === "1"){
                this.props.onLogout().then(() => {
                    this.props.onSetAuthenticate(false)
                    this.props.onFieldChange("logout_from_web", true)
                    //window.location = process.env.REACT_APP_WEB_URL + "/admincp/login.php"
                }) 
            } else{
                window.location.href = process.env.REACT_APP_WEB_URL + "/admincp/login.php"
            }
        } else {
            let branchs = Helper.getMyBranchs()
            if(branchs !== undefined && branchs !== null){
                this.onSetBranchs(branchs)
            } else{
                this.props.onGetMyBranchs().then(list => {
                    Helper.setMyBranchs(list)
                    this.onSetBranchs(list)
                })
            }
        }
    }
    onSetBranchs(branchs){
        let branch_selected = Helper.getBranchSelected()
        let branchs_state = []
        let branch_selected_state = {}
        if (branchs !== null && branchs.length > 0){
            branchs.forEach(item => {
                branchs_state.push(item)
            });
        }
        if (branch_selected !== null && branch_selected.id !== undefined && branch_selected.id > 0){
            branch_selected_state = {
                label: branch_selected.name,
                value: branch_selected.id,
            }
        }
        this.setState({
            branchs: branchs_state,
            branch_selected: branch_selected_state,
        })
    }
    onSelect(value){
        if(value !== null){
            let branch_selected_state = {}
            if (this.state.branchs.length > 0){
                this.state.branchs.forEach(item => {
                    if(item.id === value){
                        branch_selected_state = {
                            label: item.branch_name,
                            value: item.id,
                        }
                    }
                });
            }
            Helper.setBranchSelected({
                name: branch_selected_state.label,
                id: branch_selected_state.value,
            })
            this.props.onGetMyStores(branch_selected_state.value).then(result => {
                window.location.reload()
            })
        }
    }
    onLogout(){
        this.props.onLogout().then(() => {
            this.props.onSetAuthenticate(false)
            if(process.env.REACT_APP_SYNC_ACCOUNT === "1"){
                window.location.href = process.env.REACT_APP_WEB_URL + "/admincp/logout.php?from=erp"
            }
        })   
    }
    render(){
        let current_location = this.props.location.pathname;
        var staff_active = "", home_active = "", products_active = "", exchange_active = "", users_active = "", report_active = "", accounting_active = ""
        var products_tab_active = "tab-pane notika-tab-menu-bg animated flipInX", exchange_tab_active = "tab-pane notika-tab-menu-bg animated flipInX", users_tab_active = "tab-pane notika-tab-menu-bg animated flipInX", report_tab_active = "tab-pane notika-tab-menu-bg animated flipInX", accounting_tab_active = "tab-pane notika-tab-menu-bg animated flipInX"
        if (current_location === "/staff") {
            staff_active = "active";
        }
        else if (current_location.includes("/report")) {
            report_active = "active";
            report_tab_active += " active";
        }
        else if (current_location.includes("/accounting")) {
            accounting_active = "active";
            accounting_tab_active += " active";
        }
        else if (current_location.includes("/product") || current_location.includes("/inventories-check") || current_location.includes("/inventory-check")  || current_location.includes("/transfer-store")) {
            products_active = "active";
            products_tab_active += " active";
        }
        else if (current_location.includes("/invoice") || current_location.includes("/purchase-order") || current_location.includes("/return-purchase-orders")  || current_location.includes("/return-invoice") || current_location.includes("/receipt-good") || current_location.includes("/receipt-of-good") || current_location.includes("/supplier-vendor-bill") || current_location.includes("/bill-lading") || current_location.includes("/assign-transporter")) {
            exchange_active = "active";
            exchange_tab_active += " active";
        }
        else if (current_location.includes("/customer") || current_location.includes("/transporter") || current_location.includes("/supplier")) {
            users_active = "active";
            users_tab_active += " active";
        }
        var user = Helper.getUser();
        var user_name = "", position_name = "";
        if (user !== null){ 
            user_name = user.name;
            position_name = user.position_name;
        }
        let branchs_array = []
        if (this.state.branchs.length > 0){
            this.state.branchs.forEach(item => {
                branchs_array.push({
                    label: item.branch_name,
                    value: item.id
                })
            });
        } else if (this.state.branch_selected.value !== undefined && this.state.branch_selected.value !== null){
            branchs_array.push({
                label: this.state.branch_selected.label,
                value: this.state.branch_selected.value,
            })
        }
        let accounting_access = false
        if (Helper.checkMyRole('accounting_access') === true){
            accounting_access = true
        }
        let change_branch = false
        if (Helper.checkMyRole('change_branch') === true){
            change_branch = true
        }
        let view_bank_transactions = false
        if (Helper.checkMyRole('view_bank_transactions') === true){
            view_bank_transactions = true
        }
        let allow_create_from_supplier = false 
        let branch_selected = Helper.getBranchSelected()
        if(branch_selected !== undefined && branch_selected !== null && branch_selected.id > 0 && branch_selected.id === 1){
            allow_create_from_supplier = true
        }
        return(
            <div>
                <div className="header-top-area">
                    <div className="container">
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <div className="logo-area"><img src={logo} alt="" /></div>
                                </div>
                                <div className="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                                    <div className="header-top-menu">
                                        <ul className="nav navbar-nav notika-top-nav">
                                            <li className="nav-item dropdown">
                                                <a href="# " data-toggle="dropdown" role="button" aria-expanded="false" className="nav-link dropdown-toggle"><span><i className="notika-icon notika-search"></i></span></a>
                                                <div role="menu" className="dropdown-menu search-dd animated flipInX">
                                                    <div className="search-input">
                                                        <i className="notika-icon notika-left-arrow"></i>
                                                        <input type="text" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                    <div className="branch-container">
                                                    { change_branch === true ?<SelectPicker
                                                            style={{color: "#FFFFFF"}}
                                                            block
                                                            searchable={false}
                                                            cleanable={false}
                                                            placeholder="Chọn chi nhánh"
                                                            appearance="subtle"
                                                            data={branchs_array}
                                                            value={this.state.branch_selected.value}
                                                            onSelect={this.onSelect}
                                                            renderMenu={menu => {
                                                                if (branchs_array.length === 0) {
                                                                    return (
                                                                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                                        <Icon icon="spinner" spin />
                                                                    </p>
                                                                    );
                                                                }
                                                                return menu;
                                                            }}
                                                        />: <span className="branch-container__name">{this.state.branch_selected.label}</span> }
                                                    </div> 
                                            </li>
                                            <li className="nav-item"><a href="# " data-toggle="dropdown" role="button" aria-expanded="false" className="nav-link dropdown-toggle"><span><i className="notika-icon notika-settings"></i></span></a>
                                                <div role="menu" className="dropdown-menu message-dd chat-dd animated zoomIn">
                                                    <div className="hd-message-info">
                                                        <a href="# ">
                                                            <div className="hd-message-sn">
                                                                <div className="hd-message-img chat-img">
                                                                    <img src="img/post/1.jpg" alt="" ></img>
                                                                </div>
                                                                <div className="hd-mg-ctn">
                                                                    <h3>{user_name}</h3>
                                                                    <p>{position_name}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="hd-mg-va">
                                                        <a href="# " onClick={() => { this.onLogout() }} >Đăng xuất</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                <div className="main-menu-area mg-tb-40">
                    <div className="container">
                        <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="pull-left">
                            <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                                <li className={home_active}><NavLink to="/"><i className="fa fa-dashboard" />&nbsp;Tổng quan</NavLink></li>
                                <li className={products_active}><NavLink to="/products"><i className="fa fa-cube" /> Hàng hóa</NavLink></li>
                                <li className={exchange_active}><NavLink to="/invoices"><i className="fa fa-exchange" /> Giao dịch</NavLink></li>
                                <li className={users_active}><NavLink to="/customers"><i className="fa fa-user" /> Đối tác</NavLink></li>
                                <li className={staff_active}><NavLink to="/staffs"><i className="fa fa-users" /> Nhân viên</NavLink></li>
                                <li className={report_active}><NavLink to="/report/sale"><i className="fa fa-pie-chart" /> Báo cáo</NavLink></li>
                                { accounting_access === true ? <li className={accounting_active}><NavLink to="/accounting/invoices"><i className="fa fa-money" /> Kế toán</NavLink></li> : <li></li> }
                            </ul>
                        </div>              
                        { Helper.isViewer() === false ?
                        <div>
                            <Link to="/invoice/new" className="btn pull-right btn-green"><i className="fa fa-shopping-basket"></i>&nbsp;BÁN HÀNG</Link>
                            <div className="btn-group pull-right mr-right-5">
                                <button type="button" className="btn btn-green">
                                    <i className="fa fa-download"></i>&nbsp;NHẬP HÀNG
                                </button>
                                <button type="button" className="btn btn-green dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="caret"></span>
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                <ul className="dropdown-menu">
                                    { allow_create_from_supplier === true ?
                                    <li>
                                        <Link to="/purchase-order/new" className="dropdown-item">TỪ NHÀ CUNG CẤP</Link>  
                                    </li> : null } 
                                    <li>    
                                        <Link to="/purchase-order/new-from-transfer" className="dropdown-item">CHUYỂN KHO</Link>  
                                    </li>
                                    <li>    
                                        <Link to="/purchase-order/new-from-return" className="dropdown-item">KHÁCH TRẢ</Link>  
                                    </li>
                                </ul>
                            </div>  
                        <Link to="/receipt-goods/new" className="btn pull-right btn-green mr-right-5"><i className="fa fa-file-text-o"></i>&nbsp;NHẬN HÀNG KHÁCH TRẢ</Link>
                        <Link to="/invoice/return" className="btn pull-right btn-green mr-right-5"><i className="fa fa-reply-all"></i>&nbsp;TRẢ HÀNG</Link>
                        </div> : null }
                        <div className="cl"></div>
                        <div className="tab-content custom-menu-content">
                            <div id="products" className={products_tab_active}>
                                <ul className="notika-main-menu-dropdown">
                                    <li><NavLink to="/inventories-check"><i className="fa fa-file-text-o" />&nbsp;Kiểm kho</NavLink></li>
                                    <li><NavLink to="/products-in-stock"><i className="fa fa-file-text-o" />&nbsp;Tồn kho</NavLink></li>
                                    <li><NavLink to="/transfer-stores"><i className="fa fa-file-text-o" />&nbsp;Chuyển kho</NavLink></li>
                                    <li><NavLink to="/expiries"><i className="fa fa-exclamation-triangle" />&nbsp;Đến hạn sử dụng</NavLink></li>
                                </ul>
                            </div>
                            <div id="exchanges" className={exchange_tab_active}>    
                                <ul className="notika-main-menu-dropdown">
                                    <li><NavLink to="/invoices"><i className="fa fa-file-text"></i>&nbsp;Hóa đơn bán hàng</NavLink></li>
                                    <li><NavLink to="/return-invoices"><i className="fa fa-file-text-o"></i>&nbsp;Khách trả hàng</NavLink></li>
                                    <li><NavLink to="/supplier-vendor-bills"><i className="fa fa-file-text-o"></i>&nbsp;Mua hàng NCC</NavLink></li>
                                    <li><NavLink to="/purchase-orders"><i className="fa fa-file-text-o"></i>&nbsp;Nhập hàng</NavLink></li>
                                    <li><NavLink to="/return-purchase-orders"><i className="fa fa-file-text-o"></i>&nbsp;Trả hàng NCC</NavLink></li>
                                    <li><NavLink to="/receipt-goods"><i className="fa fa-file-text-o"></i>&nbsp;Phiếu nhận hàng trả</NavLink></li>
                                    <li><NavLink to="/receipt-of-goods"><i className="fa fa-file-text-o"></i>&nbsp;Ghi nhận nhập hàng</NavLink></li>
                                    <li><NavLink to="/bill-ladings"><i className="fa fa-file-text-o"></i>&nbsp;Vận đơn giao hàng</NavLink></li>
                                    <li><NavLink to="/assign-transporter"><i className="fa fa-truck"></i>&nbsp;Thiết lập giao hàng</NavLink></li>
                                </ul>
                            </div>
                            <div id="users" className={users_tab_active}>    
                                <ul className="notika-main-menu-dropdown">
                                    <li><NavLink to="/customers"><i className="fa fa-user"></i>&nbsp;Khách hàng</NavLink></li>
                                    <li><NavLink to="/suppliers"><i className="fa fa-user"></i>&nbsp;Nhà cung cấp</NavLink></li>
                                    <li><NavLink to="/transporters"><i className="fa fa-user"></i>&nbsp;Nhà vận chuyển</NavLink></li>
                                </ul>
                            </div>
                            <div id="settings" className={report_tab_active}>
                                <ul className="notika-main-menu-dropdown">
                                    <li><NavLink to="/report/products">Hàng hóa</NavLink></li>
                                    <li><NavLink to="/report/sale">Bán hàng</NavLink></li>
                                    <li><NavLink to="/report/overview-business">Tổng quan kinh doanh</NavLink></li>
                                </ul>
                            </div>
                            { accounting_access === true ?                           
                            <div id="accounting" className={accounting_tab_active}>
                                <ul className="notika-main-menu-dropdown">
                                    <li><NavLink to="/accounting/invoices">Hóa đơn thanh toán</NavLink></li>
                                    { Helper.checkMyRole('update_inventory_check_retail') === true ?
                                    <li><NavLink to="/accounting/inventories-check">Giá nhập kiểm kho</NavLink></li> 
                                    : "" }
                                    <li><NavLink to="/accounting/purchase-orders">Giá nhập từ NCC</NavLink></li>
                                    <li><NavLink to="/accounting/vendor-bills">Phiếu mua hàng</NavLink></li>
                                    <li><NavLink to="/accounting/payment-bills">Phiếu chi</NavLink></li>
                                    <li><NavLink to="/accounting/terms">Danh mục chi phí</NavLink></li>
                                    <li><NavLink to="/accounting/banks">Tài khoản ngân hàng</NavLink></li>
                                    <li><NavLink to="/accounting/products">Hàng hóa & Vật tư</NavLink></li>
                                    { view_bank_transactions === true ? <li><NavLink to="/accounting/bank-transactions">Giao dịch ngân hàng</NavLink></li> : null }
                                </ul>
                            </div> : "" }
                        </div>
                    </div>
                </div>
                    </div>
                </div>   
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(LoginAction.fieldChange(field,value))
        },
        onLogout: () => {
            return dispatch(LoginAction.logoutRequest())
        },
        onSetAuthenticate: status => {
            dispatch(LoginAction.setAuthenticate(status))
        },
        onGetMyBranchs: () => {
            return dispatch(LoginAction.myBranchsRequest())
        },
        onGetMyStores: (branch_id) => {
            return dispatch(LoginAction.myStoresRequest(branch_id))
        }
    }
}
const HeaderConnect = (connect(mapStateToProps, mapDispatchToProps)(Header));
export default withRouter(HeaderConnect);