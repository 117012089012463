import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { Alert, DatePicker } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import Helper from '../../utils/Helper';
import * as SupplierVendorBillAction from '../../actions/SupplierVendorBillAction';
import * as SupplierVendorBillActionType from '../../constants/SupplierVendorBillActionType';
import Moment from 'react-moment';
class ViewSupplierVendorBill extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.props.onGetData(id).then(result => {
                if (!(result !== undefined && result.products !== undefined && result.products !== null)){
                    this.props.history.push("/supplier-vendor-bills");
                }
            })
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("vendor_bill_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onSaveData(){
        if(this.props.supplier_vendor_bill.vendor_bill_date === ""){
            Alert.error('Vui lòng chọn thời gian tạo phiếu mua hàng')
            return;
        }
        this.props.onUpdateData({
            vendor_bill_date: this.props.supplier_vendor_bill.vendor_bill_date
        }, this.props.supplier_vendor_bill.id,"save_button_loading").then(result => {
            if(result.status === true){
                Alert.success("Thông tin phiếu mua hàng nhà cung cấp đã lưu thành công")
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(this.props.supplier_vendor_bill.id, reason_cancel).then(result => {
                        if(result.status === true){
                            this.props.onGetData(this.props.supplier_vendor_bill.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 return (
                     <tr key={ index }>
                         <td className="text-center"><img src={item.product_url} alt="" /></td>
                         <td>{ item.product_model }</td>
                        <td>{gift_text}{ item.product_name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.quantity_real} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let { total_quantity, total_product, total_quantity_real, products, vendor_bill_date, save_button_loading} = this.props.supplier_vendor_bill;
        vendor_bill_date = Helper.replaceMysqlDateTime(vendor_bill_date)
        let allow_update_cancel_status = false, allow_update_date = false
        if (Helper.checkMyRole('update_vendor_bill_date') === true){
            allow_update_date = true
        }
        if (Helper.checkMyRole('update_vendor_bill_cancel') === true){
            allow_update_cancel_status = true
        }
        let html_alert_status = ""
        if(this.props.supplier_vendor_bill.vendor_bill_status === SupplierVendorBillActionType.STATUS_FINISH_SUPPLIER_VENDOR_BILL){
            allow_update_date = false
            html_alert_status = <div className="col-xs-12">
                <div className="alert alert-success text-center">
                    <h3>{ this.props.supplier_vendor_bill.staff_finish_name } hoàn thành lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.supplier_vendor_bill.finish_time }</Moment></h3>
                </div>
            </div>  
        } else if(this.props.supplier_vendor_bill.vendor_bill_status === SupplierVendorBillActionType.STATUS_CANCEL_SUPPLIER_VENDOR_BILL){
            allow_update_cancel_status = false
            allow_update_date = false
            html_alert_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.supplier_vendor_bill.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.supplier_vendor_bill.cancel_time }</Moment><br />
                        Lý do: { this.props.supplier_vendor_bill.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Phiếu mua hàng nhà cung cấp { this.props.supplier_vendor_bill.vendor_bill_code }</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/receipt-of-good/" + this.props.supplier_vendor_bill.id} />
                </Helmet>   
                <div className="row">
                    { html_alert_status }
                    <div className="col-xs-12">
                    <div className="pull-left">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                        </ul>
                    </div>
                    <div className="pull-right">
                        { allow_update_date === true ?
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-info mr-right-5" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton> : "" }
                        { allow_update_cancel_status === true ?
                        <button type="button"
                            className="btn btn-danger" onClick={ () => { this.onCancel() } } >
                            <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                        </button> : "" }
                    </div>
                    <div className="cl"></div>
                    <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white">
                                <div className="form-element-list" >
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Mã phiếu:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <strong>{ this.props.supplier_vendor_bill.vendor_bill_code }</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className={allow_update_date === true ? "col-lg-3 col-md-3 col-sm-3 col-xs-12 mr-top-7" : "col-lg-3 col-md-3 col-sm-3 col-xs-12"}>
                                                        <label className="hrzn-fm">Thời gian:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <div className="pull-left">
                                                        { allow_update_date === true ? 
                                                        <div>
                                                            <DatePicker format="YYYY-MM-DD HH:mm" value={new Date(moment(vendor_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())} placement="bottomStart" size="md" ranges={[]} onChange = { (value) => { this.onDatePickerChange(value) } } />
                                                        </div> : <span>{ moment(vendor_bill_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>  }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Trạng thái:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        <span style={{ fontSize: 15, color: this.props.supplier_vendor_bill.vendor_bill_status_color }}>{this.props.supplier_vendor_bill.vendor_bill_status_text}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label className="hrzn-fm">Người tạo:</label>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                        {this.props.supplier_vendor_bill.staff_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
                                </div>    
                                <div className="normal-table-list mr-top-20">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex table-hover table-condensed">
                                            <colgroup>
                                                <col width="5%"></col>
                                                <col width="15%"></col>
                                                <col></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Mã hàng</th>
                                                    <th>Tên hàng</th>
                                                    <th className="text-right">Số lượng đặt</th>
                                                    <th className="text-right">Thực tế nhập</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showListProduct(products) }
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="4" className="text-right">Tổng số mặt hàng</td>
                                                    <td className="text-right">{ total_product }</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className="text-right">Tổng số lượng đặt</td>
                                                    <td className="text-right">{ total_quantity }</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className="text-right">Tổng số lượng nhập</td>
                                                    <td className="text-right">{ total_quantity_real }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        supplier_vendor_bill: state.supplier_vendor_bill
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFocusField(field,value){
            dispatch(SupplierVendorBillAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(SupplierVendorBillAction.fieldChange(field,value))
        },
        onEmptyInfo(){
            dispatch(SupplierVendorBillAction.emptyInfo())
        },
        onGetData: id => {
            return dispatch(SupplierVendorBillAction.getRequest(id))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(SupplierVendorBillAction.updateRequest(data_post, id, button_name))
        },
        onCancel: (id, reason_cancel) => {
            return dispatch(SupplierVendorBillAction.cancelRequest(id, reason_cancel))
        },
    }
}
const ViewSupplierVendorBillConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewSupplierVendorBill));
export default withRouter(ViewSupplierVendorBillConnect);