import React, { Component } from 'react';
import { SelectPicker } from 'rsuite';
import apiCaller from '../../utils/apiCaller';
class SellChannelSelectBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            list: [],
            selected: []
        }
        this._amounted = true
        this.onSelect = this.onSelect.bind(this);
    }
    componentWillUnmount(){
        this._amounted = false
    }
    componentDidMount(){
        apiCaller.callApi(`settings`,{
            setting_type: 2
        },'GET').then(res => {
            if (this._amounted === true){ 
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        let { list } = res.data.result;
                        let selected = []
                        list.forEach(function(item){
                            if (item.is_default === 1){
                                selected = {
                                    label: item.name,
                                    value: item.id
                                }
                            }
                        });
                        this.setState({
                            list,
                            selected
                        })
                        if(typeof selected.value !== "undefined"){
                            this.props.onFieldChange(selected);
                        }
                    }
                }
            }
        });
    }
    onSelect(value,item){
        if(item !== null){
            this.props.onFieldChange(item);
            this.setState({
                selected: item
            })
        } else{
            this.props.onFieldChange([]);
            this.setState({
                selected: []
            })
        }
    }
    render(){
        let { list, selected } = this.state;
        let lists_array = [];
        if (list.length > 0) {
            list.forEach(function(item){
                lists_array.push({
                    label: item.name,
                    value: item.id,
                }); 
            });
        }
        let value_selected = 0
        if(typeof selected.value !== "undefined"){
            value_selected = selected.value;
        }
        if(this.props.selected !== undefined && this.props.selected !== null && this.props.selected.value !== undefined && this.props.selected.value !== null){
            value_selected = this.props.selected.value;
        }
        let appearance = (typeof this.props.appearance !== "undefined" && this.props.appearance !== "")? this.props.appearance : "default"
        return <SelectPicker
                block={this.props.block}
                placeholder={this.props.placeholder}
                placement="leftStart"
                cleanable={false}
                searchable={false}
                appearance={appearance}
                data={lists_array}
                value={value_selected}
                onSelect={this.onSelect}
            />
    }
}
SellChannelSelectBox.defaultProps = {
    block: true
}
export default SellChannelSelectBox