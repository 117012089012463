import React, { Component } from 'react';
import { Whisper, Popover, Toggle } from 'rsuite';
import NumberFormat from 'react-number-format';
import * as DiscountModeType from '../../constants/DiscountModeType';

class DiscountInput extends Component {
    constructor() {
        super();
        this.state = { discount_mode: DiscountModeType.DISCOUNT_MODE_BY_MONEY, discount_value: 0, discount_text: "" };
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.main_discount !== prevState.discount_text || nextProps.discount_mode !== prevState.discount_mode){
            return { product_id: nextProps.product_id};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.main_discount !== this.props.main_discount || prevProps.discount_mode !== this.props.discount_mode){
            this.setState({
                discount_text: this.props.main_discount,
                discount_mode: this.props.discount_mode
            });
        } 
    } 
    componentDidMount(){
        this.setState({
            discount_text: this.props.main_discount,
            discount_mode: this.props.discount_mode
        });
    }
    onDiscountModeChange(checked){
        let value = DiscountModeType.DISCOUNT_MODE_BY_MONEY;
        if (checked === true){
            value = DiscountModeType.DISCOUNT_MODE_BY_PERCENT
        }
        if(value !== this.state.discount_mode){
            this.setState({
                discount_text: ""
            });
            this.discount_input.focus();
        }
        this.setState({
            discount_mode: value
        });
    }
    onNumberFieldChange(input_name,values){
        const {formattedValue, value} = values;
        this.props.fieldChange(input_name, value, this.state.discount_mode);
        this.setState({
            discount_text: formattedValue
        });
    }
    render(){
        const speaker = (
            <Popover title="Nhập giá trị giảm">
                <div className="pull-left">
                    <div className="nk-int-st nk-toggled">
                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.discount_input = el} thousandSeparator={true} prefix={""} value={this.state.discount_text} onValueChange={(values) => this.onNumberFieldChange(this.props.discount_input,values)} />
                    </div>
                </div>
                <div className="pull-right mr-left-10">
                    <Toggle name="discount_mode" checkedChildren="%" unCheckedChildren="VND" checked={ this.state.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT ? true : false } onChange={ this.onDiscountModeChange.bind(this) } />
                </div>
                <div className="cl"></div>
            </Popover>
        );
        return( 
            <Whisper
                placement="leftStart"
                trigger="click"
                onEntered={() => {
                    this.discount_input.focus();
                }}
                speaker={speaker}
                >
                    <div className="nk-int-st nk-toggled">
                        <NumberFormat className="form-control text-right cl_handover" readOnly={true} thousandSeparator={true} prefix={""} value={this.props.discount_value} />
                    </div>
            </Whisper>
        );
    }
}
DiscountInput.defaultProps = {
    disabled: false
}
export default DiscountInput;