import React, { Component } from 'react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";
import { Alert, SelectPicker } from 'rsuite';
import LoadingIndicator from './shared/Loader';
import * as LoginAction from '../actions/LoginAction';
import Helper from '../utils/Helper';

class LoginPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            branchs: [],
            branch_selected: {},
            sync_token: "",
            sync_loading: false,
            is_need_sync: false
        }
        this.onSelect = this.onSelect.bind(this);
    }
    componentDidMount(){
        const jwt = Helper.getJwt();
        if (!(jwt === "true")) {
            if (process.env.REACT_APP_SYNC_ACCOUNT === "1"){
                let login_by_web = Helper.getLoginByWeb()
                let sync_token = Helper.getQueryString('sync');
                if (sync_token !== undefined && sync_token !== null && sync_token !== ""){
                    this.setState({
                        sync_token,
                        sync_loading: true
                    })
                    this.props.onLoginByToken(sync_token).then(response => {
                        if(response.status !== undefined){
                            if(response.status === true){
                                Helper.setLoginByWeb()
                            }
                            else if(response.status === false){
                                let is_login_form = false, is_need_sync = false
                                if(response.message !== undefined){
                                    if (response.message === "ErpStaffID_NOT_FOUND"){
                                        is_login_form = true
                                        is_need_sync = true
                                    } else{
                                        Alert.error(response.message)
                                    }
                                }
                                this.setState({
                                    sync_loading: false,
                                    is_need_sync
                                })
                                if (is_login_form === true){
                                    this.props.onGetBranchs().then(result => {
                                        if(result !== null && result.length > 0){
                                            let branchs = []
                                            result.forEach(item => {
                                                branchs.push({
                                                    label: item.branch_name,
                                                    value: item.id
                                                })
                                            })
                                            this.setState({
                                                branchs
                                            })
                                        }
                                    })
                                }
                            }
                        }
                    })
                } 
                else if(this.props.login.logout_from_web === true){
                    window.location.href = process.env.REACT_APP_WEB_URL + "/admincp/login.php"
                }
                else if(login_by_web === "1"){
                    window.location.href = process.env.REACT_APP_WEB_URL + "/admincp/login.php?login_from=erp"
                } 
                else{
                    this.getBranchs()
                }
            } else{
                this.getBranchs()
            }
        }
    }
    getBranchs(){
        this.props.onGetBranchs().then(result => {
            if(result !== null && result.length > 0){
                let branchs = []
                result.forEach(item => {
                    branchs.push({
                        label: item.branch_name,
                        value: item.id
                    })
                })
                this.setState({
                    branchs
                })
            }
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onEnter(e) {
        if (e.key === 'Enter') {
            this.onLogin()
        }
    }
    onSubmit = (event) => {
        event.preventDefault()
        this.onLogin()
    }
    onLogin(){
        let email = this.props.login.email
        let password = this.props.login.password
        let valid = true;
        if(email === ""){
            this.email_input.focus();
            this.props.onErrorField("email_error",true); 
            valid = false;
        }
        else if (password === ""){
            this.password_input.focus();
            this.props.onErrorField("password_error",true); 
            valid = false;
        } else if (!(this.state.branch_selected !== undefined && this.state.branch_selected !== null && this.state.branch_selected.value > 0)){
            Alert.error("Vui lòng chọn chi nhánh làm việc")
            valid = false;
        }
        if(valid === true){  
            let _data_post = {
                email: this.props.login.email,
                password: this.props.login.password,
                sync_token: this.state.sync_token
            }
            this.props.onButtonSpinner(true);
            this.props.onLogin(_data_post).then(result => {
                if(result !== undefined && result !== null &&  result.status !== undefined){
                    if(result.status === true){
                        if(this.state.sync_token !== ""){
                            Helper.setLoginByWeb()
                        }
                    } else if(result.message !== undefined){
                        Alert.error(result.message)
                    }
                }
            })
        }
    }
    onSelect(value){
        if(value !== null){
            let _this = this
            let branch_selected_state = {}
            if (_this.state.branchs.length > 0){
                _this.state.branchs.forEach(item => {
                    if(item.value === value){
                        branch_selected_state = item
                    }
                });
            }
            Helper.setBranchSelected({
                name: branch_selected_state.label,
                id: branch_selected_state.value,
            })
            _this.props.onGetMyStores(branch_selected_state.value).then(result => {
                _this.setState({
                    branch_selected: branch_selected_state
                })
            })
        }
    }
    render(){
        const { referrer } = this.props.location;
        /*if(referrer !== undefined){
            console.log("referrer: ",referrer.location.pathname);
        }*/
        const jwt = Helper.getJwt();
        if (jwt === "true") {
            let from = referrer !== undefined ? { pathname: referrer.location.pathname } : { pathname: "/" };
            if(Helper.isSSl()){
                window.location.href = process.env.REACT_APP_SSL + '/ship-invoices'
                return null
            }
            return <Redirect to={from} />
        }
        let { email, password, email_active, password_active, email_error, password_error, loading } = this.props.login;
        let email_field_class = 'nk-int-st';
        let password_field_class = 'nk-int-st'; 
        if(email !== ''){
            email_active = true;
        }
        if(password !== ''){
            password_active = true;
        }
        if(email_active === true){
            email_field_class += ' nk-toggled';
        }
        if(email_error === true){
            email_field_class += ' nk-toggled error';
        }
        if(password_active === true){
            password_field_class += ' nk-toggled';
        }
        if(password_error === true){
            password_field_class += ' nk-toggled error';
        }
        return(
            <React.Fragment>
            { this.state.sync_loading === true ?
            <LoadingIndicator />
            : <div className="panel-center">
                <div className="form-element-list" >
                    <div className="cmp-tb-hd mr-bot-50">
                        <h2>Đăng nhập</h2>
                        <p></p>
                    </div>
                    { this.state.is_need_sync === true ?
                    <div className="form-group ic-cmp-int float-lb floating-lb mr-bot-20">
                        <div className="alert alert-warning">
                            Chưa có liên kết tài khoản, vui lòng đăng nhập để liên kết
                        </div>
                    </div> : null }
                    <div className="form-group ic-cmp-int float-lb floating-lb mr-bot-20">
                        <div className={ email_field_class }>
                            <input type="text" className="form-control" autoComplete="off" name="email" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onEnter(e) } } value={ email }  ref={(input) => { this.email_input = input; }}></input>
                            <label className="nk-label">E-mail</label>
                        </div>
                    </div>
                    <div className="form-group ic-cmp-int float-lb floating-lb mr-bot-20">
                        <div className={ password_field_class }>
                            <input type="password" className="form-control" autoComplete="off" name="password"  onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onEnter(e) } } value={password}  ref={(input) => { this.password_input = input; }}></input>
                            <label className="nk-label">Mật khẩu</label>
                        </div>
                    </div>
                    <div className="form-group ic-cmp-int float-lb floating-lb mr-bot-20">
                        <div>
                            <SelectPicker
                                style={{color: "#FFFFFF"}}
                                block
                                searchable={false}
                                cleanable={false}
                                placeholder="Chọn chi nhánh làm việc"
                                data={this.state.branchs}
                                value={this.state.branch_selected.value}
                                onSelect={this.onSelect}
                                renderMenu={menu => {
                                    return menu;
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <LaddaButton
                            loading={ loading }
                            data-style={ZOOM_IN}
                            className="btn btn-success notika-btn-success waves-effect" onClick={ (e) => { this.onSubmit(e) } } >Đăng nhập</LaddaButton>
                    </div>
                    { process.env.REACT_APP_SYNC_ACCOUNT === "1" ? 
                    <div className="form-group text-center">
                        <a href={process.env.REACT_APP_WEB_URL + "/admincp/login.php?login_from=erp"}>Đăng nhập bằng Web MobyKid</a>
                    </div> : null }
                </div>
            </div> } </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(LoginAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(LoginAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(LoginAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(LoginAction.resetField())
        },
        onButtonSpinner(status){
            dispatch(LoginAction.buttonSpinner(status))
        },
        onLoginByToken: (token) => {
            return dispatch(LoginAction.loginByTokenRequest(token))
        },
        onLogin: (info) => {
            return dispatch(LoginAction.loginRequest(info))
        },
        onGetBranchs: () => {
            return dispatch(LoginAction.getBranchsRequest())
        },
        onGetMyStores: (branch_id) => {
            return dispatch(LoginAction.myStoresRequest(branch_id))
        },
    }
}
const LoginPageConnect = (connect(mapStateToProps, mapDispatchToProps)(LoginPage));
export default withRouter(LoginPageConnect);