import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import { Alert, InputNumber } from 'rsuite';
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import Swal from 'sweetalert2/dist/sweetalert2';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import * as PurchaseOrderActionType from '../../constants/PurchaseOrderActionType';
import Helper from '../../utils/Helper';
class ReturnPurchaseOrder extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = parseInt(Helper.getQueryString('id'));
        if(match !== undefined && match.params !== undefined && match.params.id !== undefined && match.params.id > 0){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.onInitData(id)         
        }
    }
    onInitData(id){
        this.props.onGetPurchaseOrder(id, true).then(result => {
            //console.log("result: ",result)
            if (result.import_receipt_status === PurchaseOrderActionType.STATUS_CANCEL_PURCHASE_ORDER){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo',
                    text: "Phiếu nhập hàng đã hủy, không thể trả hàng"
                }).then(() => {
                        this.props.history.push("/purchase_order/" + id)
                })
            }
            let total_amount_return = 0, total_quantity_origin = 0, total_amount_origin = 0
            let products = result.products
            //console.log("products: ", products)
            if(products.length > 0){
                let has_product_return = false
                for(let i = 0; i < products.length; i++){
                    if(products[i].remain > 0){
                        has_product_return = true
                        break
                    }
                }
                if (has_product_return === false){  
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo',
                        text: "Hàng hóa trong phiếu nhập hàng đã được trả hết"
                    }).then(() => {
                        this.props.history.push("/purchase_order/" + id)
                    })
                    return false
                }
                
                let product_returns = []
                //console.log("discount_receipt: ",discount_receipt)
                products.forEach(itemSelected => {
                    let key = itemSelected.product_id + '_' + itemSelected.product_option_id
                    let retail = itemSelected.retail
                    //console.log("price: ",price)
                    let discount_amount = itemSelected.discount_money
                    if (itemSelected.discount > 0){
                        discount_amount = retail * itemSelected.discount / 100   
                    }
                    //console.log("discount_amount in item: ",discount_amount)
                    //price = price - discount_amount - discount_receipt
                    retail = retail - discount_amount - itemSelected.discount_receipt
                    //console.log("price after: ",price)
                    total_quantity_origin += itemSelected.remain 
                    total_amount_origin += (retail * itemSelected.remain)
                    let has_return = true
                    if (!(itemSelected.remain > 0)){
                        has_return = false
                    }
                    let quantity_return_selected = 0
                    if(itemSelected.quantity_return_selected > 0){
                        quantity_return_selected = itemSelected.quantity_return_selected
                    }
                    product_returns.unshift({
                        "key": key,
                        "id": itemSelected.product_id,
                        "product_option_id": itemSelected.product_option_id,
                        "product_option_name": itemSelected.product_option_name,
                        "url": itemSelected.product_url,
                        "name": itemSelected.product_name,
                        "model": itemSelected.product_model,
                        "sku": itemSelected.product_sku,
                        "has_return": has_return,
                        "retail": retail, 
                        "quantity": itemSelected.remain,
                        "quantity_return": itemSelected.return_quantity,
                        "quantity_return_selected": quantity_return_selected,
                        "total_amount_return": quantity_return_selected * retail,
                        "category_id": itemSelected.category_id,
                        "category_name": itemSelected.category_name,
                        "brand_id": itemSelected.brand_id,
                        "brand_name": itemSelected.brand_name
                    })  
                })
                //console.log("product_returns: ",product_returns)
                this.props.onFieldChange("product_returns", product_returns)
                this.props.onFieldChange("total_amount_return", total_amount_return)
                this.props.onFieldChange("total_quantity_origin", total_quantity_origin)
                this.props.onFieldChange("total_amount_origin", total_amount_origin)
                
                this.props.onSumTotalReturn();  
            } 
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onNumberFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
        this.props.onSumTotal();  
    }
    onNumberReturnFieldChange(input_name,values){
        const { value } = values;
        this.props.onFieldChange(input_name, value);
        this.props.onSumTotalReturn();  
    }
    onQuantityReturnItemFieldChange = (key,value) => {
        this.props.onUpdateProductReturnItem("quantity_return_selected", key, value);
    }
    showListProductReturn(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                //console.log("item: ",item)
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 let total_amount = item.retail * item.quantity_return_selected
                 let key = item.id + '_' + item.product_option_id
                 return (
                     <tr key={ index }>
                         <td className="text-center"><img src={item.url} alt="" /></td>
                         <td>{ item.model }</td>
                         <td>{gift_text}{ item.name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td>
                             { item.quantity > 0 ?
                            <div className="nk-int-st nk-toggled">
                                <InputNumber className="text-right" value={item.quantity_return_selected} max={item.quantity} min={0} onChange={(value) => { this.onQuantityReturnItemFieldChange(key,value) }}/>
                            </div> : "" }
                         </td>
                         <td className="text-right"><NumberFormat value={Helper.parseMoney(item.retail)} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={Helper.parseMoney(total_amount)} displayType={'text'} thousandSeparator={true} /></td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onSubmit(){
        let {id, product_returns, products, return_bill_notes, return_fee, branch_id} = this.props.purchase_order
        if(product_returns.length === 0){
            Alert.error('Không có thông tin hàng hóa cần trả nhà cung cấp')
            return;
        }
        let valid = false
        for(let i = 0; i < product_returns.length; i++){
            let product_item = product_returns[i]
            if(product_item.quantity_return_selected > 0){
                valid = true
                break
            }
        }
        if(valid === false){
            Alert.error('Vui lòng nhập số lượng hàng hóa muốn trả')
            return;
        }
        let products_return = []
        product_returns.forEach(itemSelected => {
            if (itemSelected.quantity_return_selected > 0){
                products_return.push({
                    "product_id": itemSelected.id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_name": itemSelected.name,
                    "product_model": itemSelected.model,
                    "product_sku": itemSelected.sku,
                    "retail": itemSelected.retail,
                    "quantity": itemSelected.quantity_return_selected,
                    "branch_id": branch_id,
                })      
            }   
        })
        if(products_return.length === 0){
            Alert.error('Không có thông tin hàng hóa cần trả hàng')
            return;
        }
        let branch_selected = Helper.getBranchSelected()  
        if (products.length > 0){
            let valid_new_buy = true
            let product_invalid = ""
            for(let j = 0; j < products.length; j++){
                let product_item = products[j]
                if(product_item.remain === 0){
                    valid_new_buy = false
                    product_invalid = product_item.name
                    break
                }
            }
            if (valid_new_buy === false){
                Alert.error('Hàng hóa '+product_invalid+' không đủ tồn để thực hiện đổi trả')
                return;
            }
        }
        return_fee = parseFloat(return_fee)
        let data_post = {
           return_bill_notes: return_bill_notes,
           import_receipt_id: id,
           branch_id: branch_selected.id,
           branch_name: branch_selected.name,
           return_fee,
           products: products_return,    
        }
        //console.log("data_post: ",data_post)
        this.props.onSubmitReturn(data_post).then(result => {
            if(result.status === true){ 
                this.props.history.push("/purchase-order/" + id)
            }
            else if(result.status === false && result.message !== undefined && result.message !== ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Thông báo lỗi',
                    text: result.message
                })
            }
        })
    }
    render(){
        let { import_receipt_code, supplier_name, return_bill_notes, return_bill_notes_active, product_returns, total_quantity_origin, total_amount_origin, total_quantity_return, total_amount_return, return_fee, save_button_loading } = this.props.purchase_order;
        let final_total_amount_return = total_amount_return
        let quantity_origin_text_display = "", quantity_return_text_display = ""
        if(total_quantity_origin > 0){
            quantity_origin_text_display = (<span>&nbsp;({total_quantity_origin})</span>)
        }
        if(total_quantity_return > 0){
            quantity_return_text_display = (<span>&nbsp;({total_quantity_return})</span>)
        }
        let return_fee_text = ""
        if(return_fee > 0){
            return_fee_text = Helper.parseMoneyText(return_fee)
            final_total_amount_return = final_total_amount_return - return_fee
        }
        let notes_field_class = "nk-int-st"
        if (return_bill_notes !== "") {
            return_bill_notes_active = true;
        }
        if(return_bill_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        return (
            <div className="container">
                <Helmet>
                    <title>Trả hàng cho phiếu nhập { import_receipt_code } - { supplier_name }</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/purchase-order/return/" + this.props.purchase_order.id} />
                </Helmet>   
                <div className="row"> 
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="normal-table-list">
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-condensed cl_list_product">
                                    <colgroup>
                                        <col width="5%"></col>
                                        <col width="10%"></col>
                                        <col width="25%"></col>
                                        <col width="7%"></col>
                                        <col width="7%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Mã hàng</th>
                                            <th>Tên hàng</th>
                                            <th className="text-right">SL tồn</th>
                                            <th className="text-right">SL trả</th>
                                            <th className="text-right">Giá nhập</th>
                                            <th className="text-right">Thành tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.showListProductReturn(product_returns) }
                                    </tbody>
                                </table>
                            </div>
                        </div>   
                    </div>  
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list">
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <h5>Trả hàng <Link to={"/purchase-order/" + this.props.purchase_order.id}>{ import_receipt_code } - { supplier_name }</Link> </h5>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    Tổng hàng nhập{quantity_origin_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={Helper.parseMoney(total_amount_origin)} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Tổng hàng trả{quantity_return_text_display}
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={Helper.parseMoney(total_amount_return)} />
                                </div>
                                <div className="cl"></div>
                            </div>  
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Phí trả hàng
                                </div>
                                <div className="pull-right">
                                    <div className="nk-int-st nk-toggled">
                                        <NumberFormat className="form-control text-right" getInputRef = {(el) => this.return_fee_input = el} thousandSeparator={true} prefix={""} value={return_fee_text} onValueChange={(values) => this.onNumberReturnFieldChange("return_fee",values)} />
                                    </div>
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left">
                                    NCC hoàn lại
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={Helper.parseMoney(final_total_amount_return)} />
                                </div>
                                <div className="cl"></div>
                            </div>
                        </div>
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="return_bill_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={return_bill_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;TRẢ HÀNG
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        purchase_order: state.purchase_order,
        user: state.user,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PurchaseOrderAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PurchaseOrderAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PurchaseOrderAction.fieldChange(field,value))
        },
        onEmptyInfo: () => {
            dispatch(PurchaseOrderAction.emptyInfo())
        },
        onGetPurchaseOrder: (id, is_return) => {
            return dispatch(PurchaseOrderAction.getRequest(id, is_return))
        },
        onSubmitReturn(data_post){
            return dispatch(PurchaseOrderAction.submitReturn(data_post))
        },
        onUpdateProductReturnItem(input_name, key, value){
            dispatch(PurchaseOrderAction.updateProductReturnItemRequest(input_name, key, value))
        },
        onSumTotalReturn(){
            dispatch(PurchaseOrderAction.sumTotalForReturn())
        },
    }
}
const ReturnPurchaseOrderConnect = (connect(mapStateToProps, mapDispatchToProps)(ReturnPurchaseOrder));
export default withRouter(ReturnPurchaseOrderConnect);