import React, { Component } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import LoginPage from './components/Login';
import Home from './components/Home';
import AuthComponent from './components/Auth';
import PrintInvoice from './components/invoice/PrintInvoice';
import PrintTransfer from './components/inventory/PrintTransfer';
import PrintReceiptGood from './components/receipt-good/PrintReceiptGood';
import PrintTransporter from './components/partner/PrintTransporter';
import ShipInvoices from './components/invoice/ShipInvoices';
import ViewShipInvoice from './components/invoice/ViewShipInvoice';
import 'rsuite/dist/styles/rsuite-default.css';
import 'sweetalert2/src/sweetalert2.scss';

const customHistory = createBrowserHistory();

class App extends Component {
    render(){
        return (
            <Router history={customHistory}>
                <div>
                    <Switch>
                        <Route path="/login" component={LoginPage}  />
                            <Route path="/ship-invoices" exact component={ShipInvoices} />
                            <Route path="/ship-invoice/:id" exact render={({ match }) => <ViewShipInvoice match={match} />} ></Route>
                            <Route path="/invoice/print/:id" exact render={({ match }) => <PrintInvoice match={match} />} ></Route>
                            <Route path="/transporter/print/:id" exact render={({ match }) => <PrintTransporter match={match} />} ></Route>
                            <Route path="/receipt-good/print/:id" exact render={({ match }) => <PrintReceiptGood match={match} />} ></Route>
                            <Route path="/transfer-store/print/:id" exact render={({ match }) => <PrintTransfer match={match} />} ></Route>
                        <AuthComponent>
                            <Route path='/' component={Home} />
                        </AuthComponent>
                    </Switch>
                </div>
            </Router>
        );
    }
}
export default App;