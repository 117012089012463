import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {Helmet} from "react-helmet";
import { withRouter } from 'react-router-dom';
import { InputGroup, Icon, Input } from 'rsuite';
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as UserAction from '../../actions/UserAction';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as UserActionType from '../../constants/UserActionType';
import Helper from '../../utils/Helper';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';

class AssignTransporter extends Component {
    constructor(props){
        super(props)
        this.state = {
            transporter_info: {},
            receipt_bills: [],
            save_button_loading: false,
            submit_success: false
        }
        this.timer_search_transporter = null
        this.timer_search_receipt_bills = null
    }
    componentDidMount(){
    }
    componentDidUpdate(){
    }
    onSearchTransporterByBarcode(e){
        let _this = this
        if(_this.timer_search_transporter !== null){
            clearTimeout(_this.timer_search_transporter);
        }
        let value = this.refSearchTransporterBarcode.value
        if (e.key === 'Enter' && value !== null && value !== "") {
            _this.timer_search_transporter = setTimeout(() => {
                _this.props.onSearchTransporter(value).then(result => {
                    if(result !== undefined && result !== null && result.list !== undefined && result.list !== null && result.list.length > 0){
                        let item = result.list[0]
                        let transporter_info = {
                            ...item
                        }   
                        this.setState({
                            transporter_info
                        }) 
                    } else {
                        Swal.fire("Thông báo", "Không tìm thấy thông tin nhà vận chuyển", "warning")
                    }
                    _this.refSearchTransporterBarcode.value = ""
                })
            })
        }
    }
    onSearchReceiptBillByBarcode(e){
        let _this = this
        if(_this.timer_search_receipt_bills !== null){
            clearTimeout(_this.timer_search_receipt_bills);
        }
        let value = this.refSearchReceiptBillBarcode.value
        if (e.key === 'Enter' && value !== null && value !== "") {
            _this.timer_search_receipt_bills = setTimeout(() => {
                _this.props.onSearchReceiptBill(value).then(result => {
                    if(result !== undefined && result !== null && result.list !== undefined && result.list !== null && result.list.length > 0){
                        let receipt_bills = this.state.receipt_bills
                        result.list.forEach(item => {
                            let allow = true
                            for(let i = 0; i < receipt_bills.length; i++){
                                if(receipt_bills[i].id === item.id){
                                    allow = false
                                    break
                                }
                            }
                            if (item.has_shipping === 0){
                                Swal.fire('Thông báo', 'Hóa đơn ' + item.receipt_bill_code + ' không có giao hàng')
                                allow = false
                            } else if (item.receipt_bill_status === InvoiceActionType.STATUS_CANCEL_INVOICE){
                                Swal.fire('Thông báo', 'Hóa đơn ' + item.receipt_bill_code + ' đã hủy, không thực hiện giao hàng')
                                allow = false
                            } else if (item.receipt_bill_status === InvoiceActionType.STATUS_RETURN_INVOICE){
                                Swal.fire('Thông báo', 'Hóa đơn ' + item.receipt_bill_code + ' đã hoàn trả, không thực hiện giao hàng')
                                allow = false
                            }
                            /*else if (item.receipt_bill_status === InvoiceActionType.STATUS_REORDER_INVOICE){
                                console.log('re order')
                                Swal.fire('Thông báo', 'Hóa đơn ' + item.receipt_bill_code + ' đang trạng thái Re-Order, không thực hiện giao hàng')
                                allow = false
                            }*/
                            if (allow === true && item.receipt_bill_ship_status !== InvoiceActionType.SHIP_STATUS_NEW_INVOICE){
                                let ship_status_text = ""
                                switch(item.receipt_bill_ship_status){    
                                    case InvoiceActionType.SHIP_STATUS_DONE_INVOICE:
                                        ship_status_text = ' đã giao hàng thành công'
                                        break
                                    case InvoiceActionType.SHIP_STATUS_IN_PROGRESS_INVOICE:
                                        ship_status_text = ' đang được giao'
                                        break
                                    case InvoiceActionType.SHIP_STATUS_RETURNING_INVOICE:
                                        ship_status_text = ' đang chờ chuyển hoàn'
                                        break
                                    case InvoiceActionType.SHIP_STATUS_RETURN_FINISH_INVOICE:
                                        ship_status_text = ' đã chuyển hoàn'    
                                        break
                                    default:
                                        ship_status_text = ' đã hủy giao hàng'     
                                        break
                                }
                                allow = false
                                Swal.fire('Thông báo', 'Hóa đơn ' + item.receipt_bill_code + ship_status_text +', không thực hiện giao hàng nữa')
                            }
                            if (allow === true){
                                receipt_bills.push(item)
                            }
                        });
                        this.setState({
                            receipt_bills
                        }) 
                    } else {
                        Swal.fire("Thông báo", "Không tìm thấy thông tin hóa đơn bán hàng", "warning")
                    }
                    _this.refSearchReceiptBillBarcode.value = ""
                })
            })
        }
    }
    onRemoveReceiptBill(id){
        let receipt_bills = this.state.receipt_bills.filter(item => item.id !== id)
        this.setState({
            receipt_bills
        })
    }
    onSubmit(){
        let { transporter_info, receipt_bills } = this.state
        if(!(transporter_info !== null && transporter_info.id !== undefined && transporter_info.id > 0)){
            Swal.fire("Thông báo", "Vui lòng chọn nhà vận chuyển", "warning")
            return false
        }
        if(receipt_bills.length === 0){
            Swal.fire("Thông báo", "Vui lòng chọn hóa đơn bán hàng", "warning")
            return false
        }
        let receipt_bills_post = []
        receipt_bills.forEach(item => {
            let receiver_address = item.receiver_address
            if(item.receiver_ward_name !== undefined && item.receiver_ward_name !== null && item.receiver_ward_name !== ""){
                receiver_address += ", " + item.receiver_ward_name
            }
            if(item.receiver_district_name !== undefined && item.receiver_district_name !== null && item.receiver_district_name !== ""){
                receiver_address += ", " + item.receiver_district_name
            }
            if(item.receiver_city_name !== undefined && item.receiver_city_name !== null && item.receiver_city_name !== ""){
                receiver_address += ", " + item.receiver_city_name
            }
            //console.log("item: ", item)
            receipt_bills_post.push({
                "receipt_bill_id": item.id,
                "receipt_bill_code": item.receipt_bill_code,
                "receipt_bill_date": item.receipt_bill_date,
                "customer_id": item.customer_id,
                "customer_name": item.customer_name,
                "customer_phone": item.customer_phone,
                "receiver_name": item.receiver_name,
                "receiver_phone": item.receiver_phone,
                "receiver_address": receiver_address,
                "receiver_city": item.receiver_city,
                "receiver_district": item.receiver_district,
                "receiver_ward": item.receiver_ward,
                "total_amount": item.total_after_discount,
                "cod_amount": item.money_collect_cod,
                "branch_id": item.branch_id,
                "branch_name": item.branch_name
            })
        })
        this.setState({
            save_button_loading: true
        })
        this.props.onSubmit({
            "transporter_id": transporter_info.id,
            "transporter_name": transporter_info.name,
            "receipt_bills": receipt_bills_post
        }).then(result => {
            if(result.status !== undefined){
                this.setState({
                    save_button_loading: false
                })
                if (result.status === true){
                    this.setState({
                        transporter_info: {},
                        receipt_bills: [],
                        save_button_loading: false,
                    })
                    this.props.history.push('/bill-ladings')
                } else if (result.message !== undefined && result.message !== null && result.message !== ""){
                    Swal.fire("Thông báo", result.message, "error")
                }
            }
        })
    }
    render(){
        let { transporter_info, receipt_bills, save_button_loading } = this.state
        let transporter_name = "", transporter_phone = "", transporter_address = "", has_transporter = false
        if(transporter_info !== null && transporter_info.id !== undefined && transporter_info.id !== null && transporter_info.id > 0){
            if(transporter_info.name !== undefined && transporter_info.name !== null){
                transporter_name = transporter_info.name
            }
            if(transporter_info.phone !== undefined && transporter_info.phone !== null){
                transporter_phone = transporter_info.phone
            }
            if(transporter_info.address !== undefined && transporter_info.address !== null){
                transporter_address = transporter_info.address
                if(transporter_info.ward_name !== undefined && transporter_info.ward_name !== null && transporter_info.ward_name !== ""){
                    transporter_address += ", " + transporter_info.ward_name
                }
                if(transporter_info.district_name !== undefined && transporter_info.district_name !== null && transporter_info.district_name !== ""){
                    transporter_address += ", " + transporter_info.district_name
                }
                if(transporter_info.city_name !== undefined && transporter_info.city_name !== null && transporter_info.city_name !== ""){
                    transporter_address += ", " + transporter_info.city_name
                }
            }
            has_transporter = true
        }
        return <div className="container">
                <Helmet>
                    <title>Sắp đơn giao hàng</title>
                    <link rel="canonical" href={Helper.getMainUrl() + "/assign-transporter"} />
                </Helmet>  
                <div className="row"> 
                    <div className="col-xs-12 col-md-4">
                        <div className="form-element-list" >
                            <div className="form-group">
                                <InputGroup style={{height: 50}}>
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                    <Input placeholder="Nhập mã vạch nhà vận chuyển" inputRef={(el) => this.refSearchTransporterBarcode = el} onKeyDown={this.onSearchTransporterByBarcode.bind(this)} />
                                </InputGroup>
                            </div>
                            { has_transporter === true ?
                            <div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12">
                                            <label className="hrzn-fm">Tên nhà vận chuyển:</label>
                                        </div>
                                        <div className="col-sm-8 col-xs-12">{transporter_name}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12">
                                            <label className="hrzn-fm">Số điện thoại:</label>
                                        </div>
                                        <div className="col-sm-8 col-xs-12">{transporter_phone}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12">
                                            <label className="hrzn-fm">Địa chỉ:</label>
                                        </div>
                                        <div className="col-sm-8 col-xs-12">{transporter_address}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;THIẾT LẬP GIAO HÀNG
                                </LaddaButton>
                                </div>
                            </div> : null }
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-8">
                        <div className="form-element-list" >
                            <div className="form-group">
                                <InputGroup style={{height: 50}}>
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                    <Input placeholder="Nhập mã vạch hóa đơn bán hàng" inputRef={(el) => this.refSearchReceiptBillBarcode = el} onKeyDown={this.onSearchReceiptBillByBarcode.bind(this)} />
                                </InputGroup>
                            </div>
                            <div className="form-group">
                                {receipt_bills.length > 0 ?
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <thead>
                                        <tr>
                                            <th>Mã hóa đơn</th>
                                            <th>Thời gian</th>
                                            <th>Khách hàng</th>
                                            <th className="text-right">Tổng tiền</th>
                                            <th className="text-center">Trạng thái giao hàng</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {receipt_bills.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.receipt_bill_code}</td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY HH:mm">
                                                    { Helper.replaceMysqlDateTime(item.receipt_bill_date) }
                                                    </Moment>
                                                </td>
                                                <td>{ item.customer_name }</td>
                                                <td className="text-right"><NumberFormat value={item.total_after_discount} displayType={'text'} thousandSeparator={true} /></td>
                                                <td className="text-center"><label className='label' style={{backgroundColor: item.receipt_bill_ship_status_color }}>{ item.receipt_bill_ship_status_text }</label></td>
                                                <td className="text-center"><button type="button" className="btn btn-link btn-sm" onClick={() => { this.onRemoveReceiptBill(item.id) }}><i className="fa fa-remove"></i></button></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table> : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
    }
}

const mapStateToProps = state => {
    return {
        invoice: state.invoice,
        user: state.user,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onSubmit(data_post){
            return dispatch(BillLadingAction.addRequest(data_post))
        },
        onSearchTransporter(keyword){
            return dispatch(UserAction.searchRequest({
                "code": keyword,
                "user_type": UserActionType.TRANSPORTER_USER_TYPE
            },1,1,false))
        },
        onSearchReceiptBill(keyword){
            return dispatch(InvoiceAction.searchRequest({
                "code": keyword,
                "has_shipping_info": 1
            },1,1,false))
        }
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(AssignTransporter)