import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import * as CategoryAction from '../../actions/CategoryAction';
import * as CategoryActionType from '../../constants/CategoryActionType';
import { Icon, Whisper, Popover, Alert, TagPicker } from 'rsuite';
const triggerRef = React.createRef();
class CreateAccountingTerm extends Component {
    constructor(props){
        super(props)
        this.state = {  
            name_active: false,
            name_error: false,
            code_active: false,
            code_error: false
        }
        this.onHandleBanksChange = this.onHandleBanksChange.bind(this)
    }
    componentDidMount(){
        this.props.onGetFinanceSource()
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })  
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onHandleBanksChange(value){ 
        this.props.onFieldChange("bank_selected", value)
    }
    onAdd(){
        var code = this.props.category.code
        var name = this.props.category.name
        var bank_array = this.props.category.bank_selected
        if (code === ""){
            this.code_input.focus()
            this.setState({
                code_error: true
            })
            Alert.error("Vui lòng nhập mã chi phí")
            return false
        }
        if (name === ""){
            this.name_input.focus()
            this.setState({
                name_error: true
            })
            Alert.error("Vui lòng nhập tên chi phí")
            return false
        }
        if (bank_array.length === 0){
            Alert.error("Vui lòng chọn tài khoản chi")
            return false
        }
        let banks = bank_array.join()
        this.props.onAdd({
            code,
            name,
            banks,
            category_type: CategoryActionType.ACCOUNTING_TERMS_CATEGORY_TYPE  
        }).then(result => {
            if (result.status !== undefined){
                if (result.status === true){
                    this.props.onDone(result.result)
                    triggerRef.current.close();
                } else if (result.message !== undefined){
                    Alert.error(result.message)
                }
            }
        })
    }
    render(){
        let { name, code, banks, bank_selected } = this.props.category;
        let { name_active, name_error, code_active, code_error } = this.state;

        let code_field_class = "nk-int-st", name_field_class = "nk-int-st"
        if (name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }

        if (code !== "") {
            code_active = true;
        }
        if(code_active === true){
            code_field_class += ' nk-toggled';
        }
        if(code_error === true){
            code_field_class += ' error';
        }
        let bank_data = []
        bank_data.push({
            label: "Tiền mặt",
            value: "cash",
            role: ""
        })
        if (banks.length > 0){
            bank_data.push(...banks)
        }
        const speaker = (
            <Popover title="Thêm danh mục chi phí" style={{ width: 500 }}>
                <div className="row">
                    <div className="col-xs-6">
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Mã chi phí:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <div className="float-lb floating-lb">
                                        <div className={ code_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="code" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.code_input = input; }} value={code} placeholder="Nhập mã chi phí" ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group">    
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Tên chi phí:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <div className="float-lb floating-lb">
                                        <div className={ name_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name} placeholder="Nhập tên chi phí" ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="col-xs-12">
                        <div className="form-group">   
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Tài khoản chi:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <TagPicker
                                        block
                                        data={bank_data}
                                        value={bank_selected}
                                        menuStyle={{width: 300}}
                                        groupBy="role"
                                        placeholder="Chọn tài khoản chi"
                                        onChange={this.onHandleBanksChange}
                                        onSelect={() => {
                                            triggerRef.current.open()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 text-center">
                        <button className="btn btn-danger mr-right-5" onClick={ () => { triggerRef.current.close() } } >
                            <i className="fa fa-ban"></i>&nbsp;Đóng lại
                        </button>
                        <LaddaButton
                            loading={ this.props.category.save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-info" onClick={ () => { this.onAdd() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div>
                </div>
            </Popover>
        );
        return (
            <Whisper
                ref={triggerRef}
                preventOverflow
                trigger="click"
                speaker={speaker}
                placement={this.props.placement}
                enterable
            >
                <button type="button" className='btn btn-link btn-sm btn-plus'><Icon icon="plus-circle" size="2x" /></button>
            </Whisper>
        );
    }
}
CreateAccountingTerm.defaultProps = {
    placement: "rightStart",
}


const mapStateToProps = state => {
    return {
        category: state.category
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(CategoryAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(CategoryAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(CategoryAction.fieldChange(field,value))
        },
        onAdd: (data_post) => {
            return dispatch(CategoryAction.addRequest(data_post))
        },
        onGetFinanceSource(){
            dispatch(CategoryAction.getFinanceSourceRequest())
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(CreateAccountingTerm)