import * as PurchaseOrderActionType from '../constants/PurchaseOrderActionType';
import * as DiscountModeType from '../constants/DiscountModeType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    import_receipt_code: "",
    import_receipt_date: "",
    import_receipt_notes: "",
    import_receipt_notes_active: false,
    import_receipt_notes_error: false,
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    import_receipt_status_select: [],
    import_receipt_status: 0,
    import_receipt_status_text: "",
    import_receipt_status_color: "",
    import_receipt_payment_status: 0,
    import_receipt_payment_status_text: "",
    import_receipt_payment_status_color: "",
    is_inventory_check: 0,
    inventory_check_id: 0,
    inventory_check_code: "",
    is_transfer_store: 0,
    transfer_store_id: 0,
    transfer_store_code: "",
    supplier_vendor_bill_id: 0,
    supplier_vendor_bill_code: "",
    is_return_bill: 0,
    supplier_id: 0,
    supplier_name: "",
    supplier_code: "",
    total_quantity: 0,
    total_product: 0,
    total_amount: 0,
    total_payed_amount: 0,
    discount: 0,
    discount_mode: DiscountModeType.DISCOUNT_MODE_BY_MONEY, // giảm theo số tiền
    payment_method: 1, // 1: tiền mặt, 2: thẻ, 3: chuyển khoản
    payment_method_text: "",
    payment_method_info: [],
    payment_amount: 0,
    liabilities_amount: 0,
    liabilities_payed: 0,
    liabilities_remain: 0,
    total_amount_origin: 0,
    total_amount_return: 0,
    total_quantity_origin: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    has_gift: 0,
    branch_id: 0,
    branch_name: "",
    products: [],
    product_returns: [],
    payment_bills: [],
    staff_notes: [],
    bills_reorder: [],
    invoices: [],
    transfer_store_bills: [],
    transfer_stores: [],
    transfer_store_selected: {},
    return_bills: [],
    return_bill_selected: {},
    receipt_of_goods: [],
    receipt_of_good_selected: {},
    receipt_of_good_products: [],
    supplier_vendor_bills: [],
    supplier_vendor_bill_selected: 0,
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    suppliers: [],
    search_code: "",
    search_product: "",
    search_date: [],
    search_status: [],
    supplier_selected: [],
    search_inventory_check: 0,
    search_transfer_store: 0,
    search_return_bill: 0,
    search_from_supplier: 0,
    search_miss_retail: 0,
    search_button_loading: false,
    save_button_loading: false,
    save_date_button_loading: false,
    save_note_button_loading: false,
    is_search: false,
    direct_to_list: false,
};
const purchase_order = (state = initialState, action) => {
    let index = -1;
    let products = [];
    let total_amount = 0, total_payed_amount = 0, total_quantity = 0;
    let total_product = 0;
    switch(action.type){
        case PurchaseOrderActionType.ITEM_BUTTON_SPINNER_PURCHASE_ORDER:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case PurchaseOrderActionType.BUTTON_SPINNER_PURCHASE_ORDER:
            return {
                ...state,
                save_button_loading: action.status
            };
        case PurchaseOrderActionType.BUTTON_UPDATE_SPINNER_PURCHASE_ORDER:
          return {
              ...state,
              [action.button_name]: action.status
          };
        case PurchaseOrderActionType.FOCUS_FIELD_PURCHASE_ORDER:
              return {
                ...state,
                [action.field]: action.value
              };
        case PurchaseOrderActionType.ERROR_FIELD_PURCHASE_ORDER:
              return {
                ...state,
                [action.field]: action.value
              };
        case PurchaseOrderActionType.FIELD_CHANGE_PURCHASE_ORDER:
              return {
                ...state,
                [action.field]: action.value
              };
        case PurchaseOrderActionType.FIELDS_CHANGE_PURCHASE_ORDER:
              return {
                ...state,
                ...action.fields
              };
        case PurchaseOrderActionType.EMPTY_PURCHASE_ORDER:
          return {
            ...state,
            id: 0,
            import_receipt_code: "",
            import_receipt_date: "",
            import_receipt_notes: "",
            import_receipt_notes_active: false,
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            import_receipt_status_select: [],
            import_receipt_status: 0,
            import_receipt_status_text: "",
            import_receipt_status_color: "",
            import_receipt_payment_status: 0,
            import_receipt_payment_status_text: "",
            import_receipt_payment_status_color: "",
            is_inventory_check: 0,
            inventory_check_id: 0,
            inventory_check_code: "",
            is_transfer_store: 0,
            transfer_store_id: 0,
            transfer_store_code: "",
            is_return_bill: 0,
            supplier_id: 0,
            supplier_name: "",
            total_quantity: 0,
            total_product: 0,
            total_amount: 0,
            total_payed_amount: 0,
            discount: 0,
            discount_mode: DiscountModeType.DISCOUNT_MODE_BY_MONEY, // giảm theo số tiền
            payment_method: 1, // 1: tiền mặt, 2: thẻ, 3: chuyển khoản
            payment_method_text: "",
            payment_method_info: [],
            payment_amount: 0,
            liabilities_amount: 0,
            liabilities_payed: 0,
            liabilities_remain: 0,
            total_amount_return: 0,
            total_amount_origin: 0,
            total_quantity_origin: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            has_gift: 0,
            branch_id: 0,
            branch_name: "",
            products: [],
            product_returns: [],
            payment_bills: [],
            staff_notes: [],
            bills_reorder: [],
            invoices: [],
            transfer_store_bills: [],
            transfer_stores: [],
            transfer_store_selected: {},
            return_bills: [],
            return_bill_selected: {},
            receipt_of_goods: [],
            receipt_of_good_selected: {},
            receipt_of_good_products: [],
            supplier_vendor_bills: [],
            supplier_vendor_bill_selected: 0,
            total: 0,
            limit: 10,
            total_page: 0,
            page: 1,
            list: [],
            suppliers: [],
            search_code: "",
            search_product: "",
            search_date: [],
            search_status: [],
            supplier_selected: [],
            search_inventory_check: 0,
            search_transfer_store: 0,
            search_return_bill: 0,
            search_from_supplier: 0,
            search_miss_retail: 0,
            search_button_loading: false,
            save_button_loading: false,
            save_date_button_loading: false,
            save_note_button_loading: false,
            is_search: false,
            direct_to_list: false,
          };
        case PurchaseOrderActionType.RESET_FIELD_SEARCH_PURCHASE_ORDER:      
            return {
              ...state,
              search_code: "",
              search_product: "",
              search_date: [],
              customer_selected: [],
              search_status: [],
              supplier_selected: [],
              search_inventory_check: 0,
              search_transfer_store: 0,
              search_return_bill: 0,
              search_from_supplier: 0,
              search_miss_retail: 0,
              is_search: false,
            };
        case PurchaseOrderActionType.GET_PURCHASE_ORDER:
            let discount = action.purchase_order.discount_money
            let discount_mode = DiscountModeType.DISCOUNT_MODE_BY_MONEY // giảm theo số tiền
            if (action.purchase_order.discount > 0 && discount === 0){
              discount_mode = DiscountModeType.DISCOUNT_MODE_BY_PERCENT
              discount = action.purchase_order.discount
            } 
            total_product = 0
            total_quantity = 0
            for(let product_key = 0; product_key < action.purchase_order.products.length; product_key++){
              total_quantity += action.purchase_order.products[product_key].total
              total_product++
              action.purchase_order.products[product_key].real_quantity = action.purchase_order.products[product_key].total
              action.purchase_order.products[product_key].key = action.purchase_order.products[product_key].id + '_' + action.purchase_order.products[product_key].product_option_id
              action.purchase_order.products[product_key].price = action.purchase_order.products[product_key].retail
              let price = action.purchase_order.products[product_key].retail
              let discount_amount = 0
              action.purchase_order.products[product_key].discount_mode = DiscountModeType.DISCOUNT_MODE_BY_MONEY
              if (action.purchase_order.products[product_key].discount > 0){
                discount_amount = Helper.parseMoney(price * action.purchase_order.products[product_key].discount / 100)
                action.purchase_order.products[product_key].discount_value = discount_amount
                action.purchase_order.products[product_key].discount_mode = DiscountModeType.DISCOUNT_MODE_BY_PERCENT
              } else if (action.purchase_order.products[product_key].discount_money > 0){
                discount_amount = action.purchase_order.products[product_key].discount_money
                action.purchase_order.products[product_key].discount_value = discount_amount
                action.purchase_order.products[product_key].discount = discount_amount
              }
              price = price - discount_amount
              let amount = price  * action.purchase_order.products[product_key].total
              action.purchase_order.products[product_key].total_amount = amount


              products.push({
                ...action.purchase_order.products[product_key],
                price_save: price
              })
            }
            let supplier_code = ""
            if(action.purchase_order.supplier_code !== undefined && action.purchase_order.supplier_code !== null){
              supplier_code = action.purchase_order.supplier_code
            }
            return {  
                ...state,
                id: action.purchase_order.id,
                import_receipt_code: action.purchase_order.import_receipt_code,
                import_receipt_date: action.purchase_order.import_receipt_date,
                import_receipt_notes: "",
                import_receipt_notes_active: false,
                import_receipt_notes_error: false,
                import_receipt_status: action.purchase_order.import_receipt_status,
                import_receipt_status_text: action.purchase_order.import_receipt_status_text,
                import_receipt_status_color: action.purchase_order.import_receipt_status_color,
                import_receipt_payment_status: action.purchase_order.import_receipt_payment_status,
                import_receipt_payment_status_text: action.purchase_order.import_receipt_payment_status_text,
                supplier_id: action.purchase_order.supplier_id,
                supplier_name: action.purchase_order.supplier_name,
                supplier_code: supplier_code,
                supplier_selected: {
                  label: action.purchase_order.supplier_name,
                  value: action.purchase_order.supplier_id,
                  code: supplier_code,
                },
                is_inventory_check: action.purchase_order.is_check_inventory,
                inventory_check_id: action.purchase_order.inventory_check_id,
                inventory_check_code: action.purchase_order.inventory_check_code,
                is_transfer_store: action.purchase_order.is_transfer_store,
                transfer_store_id: action.purchase_order.transfer_store_id,
                transfer_store_code: action.purchase_order.transfer_store_code,
                is_return_bill: action.purchase_order.is_return_bill,
                supplier_vendor_bill_id: action.purchase_order.supplier_vendor_bill_id,
                supplier_vendor_bill_code: action.purchase_order.supplier_vendor_bill_code,
                total_amount: parseFloat(action.purchase_order.total_amount),
                total_amount_return: parseFloat(action.purchase_order.total_amount_return_supplier),
                discount,
                discount_mode, 
                staff_id: action.purchase_order.staff_id,
                staff_name: action.purchase_order.staff_name,
                staff_cancel_id: action.purchase_order.staff_cancel_id,
                staff_cancel_name: action.purchase_order.staff_cancel_name,
                reason_cancel: action.purchase_order.reason_cancel,
                cancel_time: action.purchase_order.cancel_time,
                branch_id: action.purchase_order.branch_id,
                branch_name: action.purchase_order.branch_name,
                products,
                liabilities_amount: action.purchase_order.total_liabilities,
                liabilities_payed: action.purchase_order.paid_liabilities,
                liabilities_remain: action.purchase_order.remain_liabilities,
                total_quantity,
                total_product
            };
        case PurchaseOrderActionType.GET_LIABILITIES_PURCHASE_ORDER:
          return {  
              ...state,
              liabilities_amount: action.liabilities.amount,
              liabilities_payed: action.liabilities.payed,
              liabilities_remain: action.liabilities.remain,
          };   
        case PurchaseOrderActionType.GET_PAYMENT_BILLS_PURCHASE_ORDER:
          return {  
              ...state,
              payment_bills: action.payment_bills,
              total_payed_amount: action.total_payed
          };    
        case PurchaseOrderActionType.GET_STAFF_NOTES_PURCHASE_ORDER:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };       
        case PurchaseOrderActionType.GET_LAST_STAFF_NOTES_PURCHASE_ORDER:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case PurchaseOrderActionType.GET_BILL_REORDER_PURCHASE_ORDER:
          return {  
              ...state,
              bills_reorder: action.lists,
          };  
        case PurchaseOrderActionType.GET_INVOICES_PURCHASE_ORDER:
          return {  
              ...state,
              invoices: action.lists,
          };  
        case PurchaseOrderActionType.GET_TRANSFER_STORE_USE_PURCHASE_ORDER:
          return {  
              ...state,
              transfer_store_bills: action.lists,
          }; 
        case PurchaseOrderActionType.LIST_TRANSFER_STORE_PURCHASE_ORDER:
        return {  
            ...state,
            transfer_stores: action.lists,
        };  
        case PurchaseOrderActionType.GET_TRANSFER_STORE_PURCHASE_ORDER:
          let transfer_store_selected = {
            id: action.info.id,
            code: action.info.transfer_store_code,
            date: action.info.transfer_date,
            to_store: {
              store_id: action.info.to_store_id,
              store_code: action.info.to_store_code,
              store_name: action.info.to_store_name,
            },
            receipt_of_good_id: action.info.receipt_of_good_id,
            receipt_of_good_code: action.info.receipt_of_good_code,
            products: action.info.products
          }
          total_quantity = 0
          total_product = 0
          total_amount = 0
          products = []
          if(action.info.products.length > 0){
            action.info.products.forEach(itemSelected => {
              let key = itemSelected.product_id + '_' + itemSelected.product_option_id
              total_quantity += itemSelected.real_quantity
              total_amount += itemSelected.real_quantity * itemSelected.retail
              total_product++ 
              products.push({
                  "key": key,
                  "id": itemSelected.product_id,
                  "product_option_id": itemSelected.product_option_id,
                  "product_option_name": itemSelected.product_option_name,
                  "url": itemSelected.product_url,
                  "name": itemSelected.product_name,
                  "model": itemSelected.product_model,
                  "sku": itemSelected.product_sku,
                  "price": itemSelected.retail,
                  "quantity": itemSelected.quantity,
                  "real_quantity": itemSelected.real_quantity,
                  "total_amount": 0,
                  "unit": itemSelected.unit,
                  "categories": itemSelected.categories,
                  "category_id": itemSelected.category_id,
                  "category_name": itemSelected.category_name,
                  "brand_id": itemSelected.brand_id,
                  "brand_name": itemSelected.brand_name,
                  "is_gift": itemSelected.is_gift,
              })
            })
          }

          return {  
              ...state,
              transfer_store_selected,
              products,
              total_quantity,
              total_product,
              total_amount
          };  
        case PurchaseOrderActionType.LIST_RETURN_BILL_PURCHASE_ORDER:
          return {  
              ...state,
              return_bills: action.lists,
          };  
        case PurchaseOrderActionType.GET_RETURN_BILL_PURCHASE_ORDER:
            let return_bill_selected = {
              id: action.info.id,
              code: action.info.return_bill_code,
              date: action.info.return_bill_date,
              products: action.info.products
            }
            total_quantity = 0
            total_product = 0
            total_amount = 0
            products = []
            if(action.info.products.length > 0){
              action.info.products.forEach(itemSelected => {
                let key = itemSelected.product_id + '_' + itemSelected.product_option_id
                total_quantity += itemSelected.real_quantity
                total_amount += itemSelected.real_quantity * itemSelected.retail
                total_product++ 
                products.push({
                    "key": key,
                    "id": itemSelected.product_id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_option_name": itemSelected.product_option_name,
                    "url": itemSelected.product_url,
                    "name": itemSelected.product_name,
                    "model": itemSelected.product_model,
                    "sku": itemSelected.product_sku,
                    "price": itemSelected.retail,
                    "quantity": itemSelected.quantity,
                    "real_quantity": itemSelected.real_quantity,
                    "total_amount": 0,
                    "unit": itemSelected.unit,
                    "categories": itemSelected.categories,
                    "category_id": itemSelected.category_id,
                    "category_name": itemSelected.category_name,
                    "brand_id": itemSelected.brand_id,
                    "brand_name": itemSelected.brand_name,
                    "is_gift": itemSelected.is_gift,
                })
              })
            }
  
            return {  
                ...state,
                return_bill_selected,
                products,
                total_quantity,
                total_product,
                total_amount
            };  
        case PurchaseOrderActionType.LIST_RECEIPT_OF_GOODS_PURCHASE_ORDER:
            return {  
                ...state,
                receipt_of_goods: action.lists,
            };  

        case PurchaseOrderActionType.GET_RECEIPT_OF_GOOD_PURCHASE_ORDER:
          let receipt_of_good_selected = {
            id: action.info.id,
            code: action.info.receipt_code,
            date: action.info.receipt_date,
            products: action.info.products
          }
          products = []
          if(action.info.products.length > 0){
            action.info.products.forEach(itemSelected => {
              let key = itemSelected.product_id + '_' + itemSelected.product_option_id
              products.push({
                  "key": key,
                  "id": itemSelected.product_id,
                  "product_option_id": itemSelected.product_option_id,
                  "product_option_name": itemSelected.product_option_name,
                  "url": itemSelected.product_url,
                  "name": itemSelected.product_name,
                  "model": itemSelected.product_model,
                  "sku": itemSelected.product_sku,
                  "price": itemSelected.retail,
                  "quantity": itemSelected.total,
                  "is_gift": itemSelected.is_gift,
              })
            })
          }

          return {  
              ...state,
              receipt_of_good_selected,
              receipt_of_good_products: products,
          };  
        case PurchaseOrderActionType.LIST_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER: 
          return {
            ...state,
            supplier_vendor_bills: action.lists
          }  
        case PurchaseOrderActionType.GET_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER: 
            total_product = 0
          products = []
          if(action.products.length > 0){
            action.products.forEach(itemSelected => {
              let key = itemSelected.product_id + '_' + itemSelected.product_option_id
                total_product++
                products.push({
                    "key": key,
                    "id": itemSelected.product_id,
                    "product_option_id": itemSelected.product_option_id,
                    "product_option_name": itemSelected.product_option_name,
                    "url": itemSelected.product_url,
                    "name": itemSelected.product_name,
                    "model": itemSelected.product_model,
                    "sku": itemSelected.product_sku,
                    "price": itemSelected.retail,
                    "quantity": itemSelected.quantity,
                    "quantity_real": itemSelected.quantity_real,
                    "real_quantity": 1,
                    "category_id": itemSelected.category_id,
                    "category_name": itemSelected.category_name,
                    "brand_id": itemSelected.brand_id,
                    "brand_name": itemSelected.brand_name,
                    "is_gift": itemSelected.is_gift,
                    "from_supplier_vendor_bill": 1,
                })
            })
          }

          return {
            ...state,
            supplier_vendor_bill_selected: action.id,
            total_product,
            products,
          }  
        case PurchaseOrderActionType.LIST_PURCHASE_ORDER:
            total_amount = action.total_amount
            total_payed_amount = action.total_paid_amount
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount,
                total_payed_amount,
                list: [...action.purchase_orders],
                direct_to_list: false
              };
        case PurchaseOrderActionType.SEARCH_PURCHASE_ORDER:
            return {
                ...state,
                is_search: true,
                search_button_loading: action.search_button_loading
            };
        case PurchaseOrderActionType.LIST_PURCHASE_ORDER_STATUS:
            return {
                ...state,
                import_receipt_status_select: action.status
              };
        case PurchaseOrderActionType.CLEAR_PRODUCTS_PURCHASE_ORDER:
          return {
            ...state,
            products: []
          };
        case PurchaseOrderActionType.SELECT_PRODUCT_PURCHASE_ORDER:
            products = state.products;
            let itemSelected = action.item
            if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
              let key = itemSelected.id + '_' + itemSelected.product_option_id
              //console.log("products: ", products)
             // console.log("key: ", key)
              let index = Helper.findIndexByKey(products, key);
              //console.log("index: ", index)
              if(index > -1){
                products[index].real_quantity = parseInt(products[index].real_quantity) + 1
                let amount = products[index].price * products[index].real_quantity
                if(products[index].discount > 0){
                  let discount_value = products[index].discount
                  if(products[index].discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                    discount_value = Helper.parseMoney(amount * products[index].discount / 100)
                  }
                  amount = amount - discount_value
                }
                products[index].total_amount = amount
                //console.log("products[index]: ", products[index])
              } else {
                  products.unshift({
                      "key": key,
                      "id": itemSelected.id,
                      "product_option_id": itemSelected.product_option_id,
                      "product_option_name": itemSelected.product_option_name,
                      "url": itemSelected.url,
                      "name": itemSelected.name,
                      "model": itemSelected.model,
                      "sku": itemSelected.sku,
                      "price": itemSelected.retail,
                      "real_quantity": 1,
                      "discount_value": 0,
                      "discount": 0,
                      "discount_mode": 1,
                      "total_amount": itemSelected.retail,
                      "remain": itemSelected.remain,
                      "unit": itemSelected.unit,
                      "categories": itemSelected.categories,
                      "category_id": itemSelected.category_id,
                      "category_name": itemSelected.category_name,
                      "brand_id": itemSelected.brand_id,
                      "brand_name": itemSelected.brand_name,
                      "is_gift": itemSelected.is_gift,
                      "has_expiry": itemSelected.has_expiry,
                  })
              }
          }
          return {
            ...state,
            products
          }; 
        case PurchaseOrderActionType.UPDATE_PRODUCTS_PURCHASE_ORDER: 
            return {
              ...state,
              products: [...action.products]
            };   
        case PurchaseOrderActionType.UPDATE_PRODUCT_PURCHASE_ORDER: 
        //console.log("action.key: ",action.key)
          //  console.log("state.products: ",state.products)
            index = Helper.findIndexByKey(state.products, action.key);
            //console.log("index: ",index)
            if (index > -1){
              let product = state.products[index]
              if (action.input_name === "quantity"){
                product.quantity = parseInt(action.value)
              } else if (action.input_name === "real_quantity"){
                product.real_quantity = parseInt(action.value)
              } else if (action.input_name === "price"){
                product.price = action.value
              } else if (action.input_name === "discount"){
                product.discount = action.value
              } else if (action.input_name === "discount_mode"){
                product.discount = 0;
                product.discount_value = 0
                product.discount_mode = action.value
              } else if (action.input_name === "expiry_date"){
                product.expiry_date = action.value
              } else if (action.input_name === "edit_expiry_info"){
                product.edit_expiry_info = action.value
              } 
              if(product.expiry_info !== undefined && product.expiry_info !== null && product.expiry_info.length > 0){
                if (action.input_name === "expiry_info"){
                  let expiry_info_array = action.value
                  if(expiry_info_array.length > 0){
                    expiry_info_array.forEach((expiry_info_item,expiry_info_index) => {
                      let product_expiry_info = product.expiry_info[expiry_info_index]  
                      if(product_expiry_info !== null && product_expiry_info.id !== undefined && product_expiry_info.id > 0){
                        if(isNaN(expiry_info_item.expiry_quantity)){
                          expiry_info_item.expiry_quantity = 0
                        }
                        product_expiry_info.expiry_date = expiry_info_item.expiry_date
                        product_expiry_info.remain = expiry_info_item.expiry_quantity
                        product.expiry_info[expiry_info_index] = product_expiry_info
                      }
                    })
                  }
                } else if (action.input_name === "expiry_info_date" || action.input_name === "expiry_info_quantity"){
                  let product_expiry_info = product.expiry_info[action.expiry_info_index]  
                  if(product_expiry_info !== null && product_expiry_info.id !== undefined && product_expiry_info.id > 0){
                    if(action.input_name === "expiry_info_date"){
                      product_expiry_info.expiry_date_update = action.value
                    } else if(action.input_name === "expiry_info_quantity"){
                      product_expiry_info.remain_update = parseInt(action.value)
                    }
                    product.expiry_info[action.expiry_info_index] = product_expiry_info
                  }
                }  
              }
              if (state.transfer_store_selected !== null && state.transfer_store_selected.id > 0){
                let amount = product.price * product.real_quantity
                amount = Helper.parseMoney(amount)
                product.total_amount = amount
              } else if (state.return_bill_selected !== null && state.return_bill_selected.id > 0){
                let amount = product.price * product.real_quantity
                amount = Helper.parseMoney(amount)
                product.total_amount = amount
              } else{ 
                let price = product.price
                if(product.discount > 0){
                  let discount_value = product.discount
                  if(product.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                    discount_value = Helper.parseMoney(price * product.discount / 100)
                  }
                  product.discount_value = discount_value
                  price = price - discount_value
                }
                let amount = price * product.real_quantity
                product.total_amount = amount
              }
              state.products[index] = product
            }
            return {
              ...state
            };   
        case PurchaseOrderActionType.SUM_TOTAL_PURCHASE_ORDER:
            state.total_amount = 0
            state.total_quantity = 0
            state.total_payed_amount = 0
            state.total_product = 0
            let is_import_transfer = 0
            let is_import_return_bill = 0
            if (state.transfer_store_selected !== null && state.transfer_store_selected.id > 0){
              is_import_transfer = 1
            } else if (state.return_bill_selected !== null && state.return_bill_selected.id > 0){
              is_import_return_bill = 1
            }
            if(state.products.length > 0){
              let total_amount = 0, total_quantity = 0
              state.products.forEach(function(item){
                total_amount += item.total_amount
                if(is_import_transfer === 1){
                  total_quantity += parseInt(item.real_quantity)
                } else if(is_import_return_bill === 1){
                  total_quantity += parseInt(item.real_quantity)
                } else{ 
                  total_quantity += parseInt(item.real_quantity)
                }
                state.total_product++
              });

              state.total_amount = total_amount
              state.total_quantity = total_quantity
            }
            return {
              ...state
            };  
        case PurchaseOrderActionType.UPDATE_PRODUCT_RETURN_PURCHASE_ORDER: 
          index = Helper.findIndexByKey(state.product_returns, action.key);
          if (index > -1){
            let product = state.product_returns[index]
            if (action.input_name === "quantity_return_selected"){
              product.quantity_return_selected = parseInt(action.value)
            }
            let amount = product.retail * product.quantity_return_selected
            if(product.discount > 0){
              let discount_value = product.discount
              if(product.discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
                discount_value = Helper.parseMoney(amount * product.discount / 100)
              }
              product.discount_value = discount_value
              amount = amount - discount_value
            }
            product.total_amount_return = amount
            state.product_returns[index] = product
          }
          return {
            ...state
          };
        case PurchaseOrderActionType.SUM_TOTAL_RETURN_PURCHASE_ORDER:
          state.total_amount_return = 0
          state.total_quantity_return = 0
          //console.log("state.product_returns: ",state.product_returns)
          if(state.product_returns.length > 0){
            let total_amount_return = 0, total_quantity_return = 0
            state.product_returns.forEach(function(item){
              total_amount_return += item.total_amount_return
              total_quantity_return += parseInt(item.quantity_return_selected)
            });
            //console.log("total_amount_return: ", total_amount_return)
            //console.log("total_quantity_return: ", total_quantity_return)
            state.total_amount_return = total_amount_return
            state.total_quantity_return = total_quantity_return
            //console.log("state: ", state)
          }
          return {
            ...state
          };      
        case PurchaseOrderActionType.RESET_FLAG_SEARCH_PURCHASE_ORDER:
          return {
            ...state,
            is_search: action.status
          };       
        case PurchaseOrderActionType.DIRECT_TO_LIST_PURCHASE_ORDER:
          return {
            ...state,
            direct_to_list: action.status
          };     
        default: 
            return state;
    }
}
export default purchase_order;