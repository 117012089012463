import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectPicker, Icon } from 'rsuite';
import * as ZoneAction from '../../actions/ZoneAction';
import apiCaller from '../../utils/apiCaller';
class ZoneSelectBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            cities: [],
            districts: [],
            wards: [],
            city_selected: [],
            district_selected: [],
            ward_selected: []
        }
        this.onOpenCity = this.onOpenCity.bind(this);
        this.onCleanCity = this.onCleanCity.bind(this);
        this.onSelectCity = this.onSelectCity.bind(this);
        this.onOpenDistrict = this.onOpenDistrict.bind(this);
        this.onCleanDistrict = this.onCleanDistrict.bind(this);
        this.onSelectDistrict = this.onSelectDistrict.bind(this);
        this.onOpenWard= this.onOpenWard.bind(this);
        this.onCleanWard = this.onCleanWard.bind(this);
        this.onSelectWard = this.onSelectWard.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.city!==prevState.city_selected || nextProps.district!==prevState.district_selected || nextProps.ward!==prevState.ward_selected){
            return { city: nextProps.city, district: nextProps.district, ward: nextProps.ward };
        }
        return null;
    }
    componentWillUnmount() {
        if(typeof this.props.getRef !== "undefined"){
            this.props.getRef(undefined)
        }
    }
    componentDidMount(){
        let _this = this
        if(typeof _this.props.getRef !== "undefined"){
            _this.props.getRef(_this)
        }
        _this.props.onSaveZone({
            cities: _this.props.zone.cities,
            districts: [],
            wards: []
        })
        if (_this.props.zone.cities.length > 0){
            _this.setState({
                cities: _this.props.zone.cities,
                city_selected: _this.props.city
            })
            if(_this.state.district_selected.value === undefined){
                setTimeout(() => {
                    _this.onGetDistricts(_this.props.district, {
                        city: _this.props.city,
                        district: _this.props.district,
                        ward: _this.props.ward
                    })
                }, 250);
            }
        } else if (_this.props.city.value !== undefined && _this.props.city.value !== null){
            _this.onSetZone({
                city: _this.props.city,
                district: _this.props.district,
                ward: _this.props.ward
            })
        }
    }
    onSetZone(zones_selected){
        if (zones_selected.city.value !== undefined && zones_selected.city.value  !== null){
            this.onGetCities(zones_selected.city, zones_selected)
        } else {
            let _this = this
            _this.props.onCityChange([]);
            _this.setState({
                city_selected: [],
                districts: [],
                district_selected: [],
                wards: [],
                ward_selected: []
            })
            setTimeout(function(){
                _this.onCleanDistrict()
            },250)
        }
    }
    onOpenCity(){
        this.onGetCities()
        let input = document.getElementsByClassName("rs-picker-search-bar-input");
        input[0].focus()
    }
    onOpenDistrict(){
        this.onGetDistricts()
        let input = document.getElementsByClassName("rs-picker-search-bar-input");
        input[0].focus()
    }
    onOpenWard(){
        this.onGetWards()
        let input = document.getElementsByClassName("rs-picker-search-bar-input");
        input[0].focus()
    }
    onGetCities(city_selected = null, zones_selected = null){
        let list = []
        if (this.props.zone.cities.length > 0){
            list = this.props.zone.cities
            if (city_selected === null){
                city_selected = this.props.city
            }
            setTimeout(() => {
                this.setState({
                    cities: list,
                    city_selected
                })
                this.props.onSaveZone({
                    cities: list,
                    districts: [],
                    wards: [],
                })
                if (zones_selected !== null){
                    this.onGetDistricts(zones_selected.district, zones_selected)
                }
            }, 100);
            
        } else {
            apiCaller.callApi(`cities`,null,'GET').then(res => {
                if(res.status === 201 || res.status === 200){
                    if (typeof res.data.result != 'undefined' && res.data.result !== null){
                        list = res.data.result.list;

                        if (city_selected === null){
                            city_selected = this.props.city
                        }
                        setTimeout(() => {
                            this.setState({
                                cities: list,
                                city_selected
                            })
                            this.props.onSaveZone({
                                cities: list,
                                districts: [],
                                wards: [],
                            })
                            if (zones_selected !== null){
                                this.onGetDistricts(zones_selected.district, zones_selected)
                            }
                        }, 100);
                    }
                }
            });
        }
    }
    onGetDistricts(district_selected = null, zones_selected = null){
        if (district_selected === null){
            district_selected = this.props.district
        }
        if (this.props.zone.districts.length === 0){
            if(this.state.city_selected.value !== undefined && this.state.city_selected.value !== null && this.state.city_selected.value !== ""){
                apiCaller.callApi(`districts/${parseInt(this.state.city_selected.value)}`,null,'GET').then(res => {
                    if(res.status === 201 || res.status === 200){
                        if (typeof res.data.result != 'undefined' && res.data.result !== null){
                            let { list } = res.data.result;
                            setTimeout(() => {
                                this.setState({
                                    districts: list,
                                    district_selected
                                })
                                this.props.onSaveZone({
                                    cities: this.props.zone.cities,
                                    districts: list,
                                    wards: [],
                                })
                                if (zones_selected !== null){
                                    this.onGetWards(zones_selected.ward)
                                }
                            }, 250);
                        }
                    }
                });
            }
        }
    }
    onGetWards(ward_selected = null){
        if (ward_selected === null){
            ward_selected = this.props.ward
        }
        if (this.props.zone.wards.length === 0){
            if(this.state.district_selected.value !== undefined && this.state.district_selected.value !== null && this.state.district_selected.value !== ""){
                apiCaller.callApi(`wards/${parseInt(this.state.district_selected.value)}`,null,'GET').then(res => {
                    if(res.status === 201 || res.status === 200){
                        if (typeof res.data.result != 'undefined' && res.data.result !== null){
                            let { list } = res.data.result;
                            setTimeout(() => {
                                this.setState({
                                    wards: list,
                                    ward_selected
                                })
                                this.props.onSaveZone({
                                    cities: this.props.zone.cities,
                                    districts: this.props.zone.districts,
                                    wards: list
                                })
                            }, 250);
                        }
                    }
                });
            }
        }
    }
    onSelectCity(value,item){
        if(item !== null){
            this.props.onCityChange(item);
            this.setState({
                city_selected: item,
                district_selected: [],
                districts: [],
                wards: [],
                ward_selected: []
            })
        } else{
            this.props.onCityChange([]);
            this.setState({
                city_selected: [],
                district_selected: [],
                districts: [],
                wards: [],
                ward_selected: []
            })
        }
        this.props.onSaveZone({
            cities: this.props.zone.cities,
            districts: [],
            wards: [],
        })
    }
    onSelectDistrict(value,item){
        if(item !== null){
            this.props.onDistrictChange(item);
            this.setState({
                district_selected: item,
                wards: [],
                ward_selected: []
            })
        } else{
            this.props.onDistrictChange([]);
            this.setState({
                district_selected: [],
                wards: [],
                ward_selected: []
            })
        }
        this.props.onSaveZone({
            cities: this.props.zone.cities,
            districts: this.props.zone.districts,
            wards: [],
        })
    }
    onSelectWard(value,item){
        if(item !== null){
            this.props.onWardChange(item);
            this.setState({
                ward_selected: item
            })
        } else{
            this.props.onWardChange([]);
            this.setState({
                ward_selected: []
            })
        }
    }
    onCleanCity = () => {
        this.props.onCityChange([]);
        this.setState({
            city_selected: [],
            district_selected: [],
            districts: [],
            wards: [],
            ward_selected: []
        })
        this.props.onSaveZone({
            cities: [],
            districts: [],
            wards: []
        })
    }
    onCleanDistrict = () => {
        this.props.onDistrictChange([]);
        this.setState({
            district_selected: [],
            wards: [],
            ward_selected: []
        })
        this.props.onSaveZone({
            cities: this.props.zone.cities,
            wards: []
        })
    }
    onCleanWard = () => {
        this.props.onWardChange([]);
        this.setState({
            ward_selected: []
        })
        this.props.onSaveZone({
            cities: this.props.zone.cities,
            districts: this.props.zone.districts,
            wards: []
        })
    }
    onResetData(){
        this.setState({
            districts: [],
            wards: [],
        })
        this.props.onSaveZone({
            cities: this.props.zone.cities,
            districts: [],
            wards: []
        })
    }
    render(){
        let { cities, districts, wards, city_selected, district_selected, ward_selected } = this.state;
        let cities_array = [], districts_array = [], wards_array = [];
        if (cities.length > 0) {
            cities.forEach(function(item){
                cities_array.push({
                    label: item.name,
                    value: item.id,
                }); 
            });
        }
        if (districts.length > 0) {
            districts.forEach(function(item){
                districts_array.push({
                    label: item.name_with_type,
                    value: item.id,
                }); 
            });
        }
        if (wards.length > 0) {
            wards.forEach(function(item){
                wards_array.push({
                    label: item.name_with_type,
                    value: item.id,
                }); 
            });
        }
        let city_value_selected = "", district_value_selected = "", ward_value_selected = "";
        if(city_selected.value !== undefined && city_selected.value !== null){
            city_value_selected = city_selected.value;
        }
        if(district_selected.value !== undefined && district_selected.value !== null){
            district_value_selected = district_selected.value;
        }
        if(ward_selected.value !== undefined && ward_selected.value !== null){
            ward_value_selected = ward_selected.value;
        }
        let appearance = (this.props.appearance !== undefined && this.props.appearance !== "")? this.props.appearance : "default"
        let class_direction_first = "", class_direction = "mr-top-5"
        if (this.props.direction === "horizontal"){
            class_direction_first = "pull-left"
            class_direction = "pull-left mr-left-5"
        }
        return (
            <div>
                <div className={class_direction_first}>
                    <SelectPicker
                            block
                            placeholder="Tỉnh/Thành phố"
                            disabled={!this.props.allow_update}
                            appearance={appearance}
                            size={this.props.size}
                            data={cities_array}
                            value={city_value_selected}
                            onOpen={this.onOpenCity}
                            onClean={this.onCleanCity}
                            onSelect={this.onSelectCity}
                            renderMenu={menu => {
                                if (cities_array.length === 0) {
                                    return (
                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                        <Icon icon="spinner" spin />    
                                        </p>
                                    );
                                }
                                return menu;
                            }}
                        />
                </div>
                <div className={class_direction}>
                    <SelectPicker
                            block
                            placeholder="Quận/Huyện"
                            disabled={!this.props.allow_update}
                            appearance={appearance}
                            size={this.props.size}
                            data={districts_array}
                            value={district_value_selected}
                            onOpen={this.onOpenDistrict}
                            onClean={this.onCleanDistrict}
                            onSelect={this.onSelectDistrict}
                            renderMenu={menu => {
                                if (city_value_selected > 0 && districts_array.length === 0) {
                                    return (
                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                        <Icon icon="spinner" spin />    
                                        </p>
                                    );
                                }
                                return menu;
                            }}
                        />
                </div>
                <div className={class_direction}>
                    <SelectPicker
                            block
                            placeholder="Phường/Xã"
                            disabled={!this.props.allow_update}
                            appearance={appearance}
                            size={this.props.size}
                            data={wards_array}
                            value={ward_value_selected}
                            onOpen={this.onOpenWard}
                            onClean={this.onCleanWard}
                            onSelect={this.onSelectWard}
                            renderMenu={menu => {
                                if (district_value_selected > 0 && wards_array.length === 0) {
                                    return (
                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                        <Icon icon="spinner" spin />    
                                        </p>
                                    );
                                }
                                return menu;
                            }}
                        />
                </div>
                <div className="cl"></div>
            </div>
        )
    }
}
ZoneSelectBox.defaultProps = {
    size: "md",
    direction: "vertical",
    allow_update: true,
}

const mapStateToProps = state => {
    return {
        zone: state.zone,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onSaveZone(zones){
            dispatch(ZoneAction.saveZone(zones))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ZoneSelectBox);