import * as TransferStoreActionType from '../constants/TransferStoreActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: TransferStoreActionType.FOCUS_FIELD_TRANSFER_STORE,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: TransferStoreActionType.ERROR_FIELD_TRANSFER_STORE,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: TransferStoreActionType.FIELD_CHANGE_TRANSFER_STORE,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: TransferStoreActionType.EMPTY_TRANSFER_STORE
    }
}

export const resetField = () => {
    return {
        type: TransferStoreActionType.RESET_FIELD_TRANSFER_STORE
    }
}

export const resetFieldSearch = () => {
    return {
        type: TransferStoreActionType.RESET_FIELD_SEARCH_TRANSFER_STORE
    }
}

const buttonSpinner = status => {
    return {
        type: TransferStoreActionType.BUTTON_SPINNER_TRANSFER_STORE,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: TransferStoreActionType.ITEM_BUTTON_SPINNER_TRANSFER_STORE,
        id,
        status
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores/${id}`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(get(res.data.result))
            }
            return {
                status: res.data.status,
                message: (res.data.message !== undefined)?res.data.message:"",
                id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
            }
        });
    }
}

const get = transfer_store => {
    return {
        type: TransferStoreActionType.GET_TRANSFER_STORE,
        transfer_store
    }
}

export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, total_page, page } = res.data.result;
                    dispatch(listTransferStores(total,limit,total_page,page,list));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`transfer-stores`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit, total_page, page } = res.data.result;
                    dispatch(listTransferStores(total,limit,total_page,page,list));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const listTransferStores = (total,limit,total_page,page,inventory_checks) => {
    return {
        type: TransferStoreActionType.LIST_TRANSFER_STORE,
        total,
        limit,
        total_page,
        page,
        inventory_checks
    }
}
const searchButtonSpinner = (search_button_loading) => {
    return {
        type: TransferStoreActionType.SEARCH_TRANSFER_STORE,
        search_button_loading
    }
}

export const selectProductToListRequest = item => {
    return (dispatch) => {
        dispatch(selectProductToList(item))
         setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const selectProductToList = item => {
    return {
        type: TransferStoreActionType.SELECT_PRODUCT_TRANSFER_STORE,
        item
    }
}

export const updateProductItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        //console.log("key in action request: ", key)
        dispatch(updateProductItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, key, value) => {
    //console.log("key in action: ", key)
    return {
        type: TransferStoreActionType.UPDATE_PRODUCT_TRANSFER_STORE,
        input_name,
        key,
        value
    }
}

export const updateProductsRequest = (products) => {
    return (dispatch) => {
        dispatch(updateProducts(products));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProducts = (products) => {
    return {
        type: TransferStoreActionType.UPDATE_PRODUCTS_TRANSFER_STORE,
        products
    }
}

export const clearProductRequest = () => {
    return {
        type: TransferStoreActionType.CLEAR_PRODUCTS_TRANSFER_STORE
    }
}

export const sumTotal = () => {
    return {
        type: TransferStoreActionType.SUM_TOTAL_TRANSFER_STORE
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`transfer-stores/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(resetField())
                }
                return {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            }
        });
    }
}

export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `transfer-stores/${id}`;
        let _method = 'POST';
        dispatch(updateButtonSpinner(button_name,true))
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { id } = res.data.result;
                    dispatch(getRequest(id))
                    if (button_name === "save_note_button_loading"){
                        dispatch(getLastStaffNoteRequest(id))
                        dispatch(getStaffNotesRequest(id))
                    }
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                }
            }
        });
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "transfer_store_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: TransferStoreActionType.GET_STAFF_NOTES_TRANSFER_STORE,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "transfer_store_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: TransferStoreActionType.GET_LAST_STAFF_NOTES_TRANSFER_STORE,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: TransferStoreActionType.BUTTON_UPDATE_SPINNER_TRANSFER_STORE,
        button_name,
        status
    }
}
export const cancelRequest = (id, reason_cancel) => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores/cancel/` + id,{reason_cancel},'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}


export const getStoreDestinationRequest = (branch_ids) => {
    return (dispatch) => {
        return apiCaller.callApi(`stores`,{
            branch_ids
        },'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message !== undefined)?res.data.message:"",
                    result: (res.data.result !== undefined)?res.data.result:null
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const getInvoiceTransferStoreRequest = branch_transfer_id => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills`,{
            branch_transfer_id
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    return res.data.result
                }
            }
        });
    }
}

export const listStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: TransferStoreActionType.LIST_TRANSFER_STORE_STATUS,
        status
    }
}

export const selectReceiptOfGoodRequest = (id, branch_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-of-goods/${id}`,{branch_id},'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(selectReceiptOfGood(res.data.result));
                    return res.data.result
                }
            }
        });
    }
}

const selectReceiptOfGood = info => {
    return {
        type: TransferStoreActionType.GET_RECEIPT_OF_GOOD_TRANSFER_STORE,
        info
    }
}