export const UPDATE_ADDRESS_BOOK = 'UPDATE_ADDRESS_BOOK';
export const GET_ADDRESS_BOOK = 'GET_ADDRESS_BOOK';
export const LIST_ADDRESS_BOOK = 'LIST_ADDRESS_BOOK';
export const SEARCH_ADDRESS_BOOK = 'SEARCH_ADDRESS_BOOK';
export const FIELD_CHANGE_ADDRESS_BOOK = 'FIELD_CHANGE_ADDRESS_BOOK';
export const RESET_FIELD_ADDRESS_BOOK = 'RESET_FIELD_ADDRESS_BOOK';
export const RESET_FIELD_SEARCH_ADDRESS_BOOK = 'RESET_FIELD_SEARCH_ADDRESS_BOOK';
export const FOCUS_FIELD_ADDRESS_BOOK = 'FOCUS_FIELD_ADDRESS_BOOK';
export const ERROR_FIELD_ADDRESS_BOOK = 'ERROR_FIELD_ADDRESS_BOOK';
export const BUTTON_SPINNER_ADDRESS_BOOK = 'BUTTON_SPINNER_ADDRESS_BOOK';
export const ITEM_BUTTON_SPINNER_ADDRESS_BOOK = 'ITEM_BUTTON_SPINNER_ADDRESS_BOOK';
export const RESET_LIST_ADDRESS_BOOK = 'RESET_LIST_ADDRESS_BOOK';
