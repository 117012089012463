import * as PurchaseOrderActionType from '../constants/PurchaseOrderActionType';
var initialState = {
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    item_selected: [],
    list_selected: [],
    supplier_selected: {},
    list: [],
    search_model: "",
    search_name: "",
    search_date: [],
    search_supplier_selected: 0,
    search_button_loading: false,
    is_search: false,
    save_list_button_loading: false
};
const product_expiries = (state = initialState, action) => {
    switch(action.type){
        case PurchaseOrderActionType.FOCUS_FIELD_PRODUCT_EXPIRIES:
              return {
                ...state,
                [action.field]: action.value
              };
        case PurchaseOrderActionType.FIELD_CHANGE_PRODUCT_EXPIRIES:
              return {
                ...state,
                [action.field]: action.value
              };
        case PurchaseOrderActionType.FIELDS_CHANGE_PRODUCT_EXPIRIES:
              return {
                ...state,
                ...action.fields
              };
        case PurchaseOrderActionType.RESET_FIELD_SEARCH_PRODUCT_EXPIRIES:      
            return {
              ...state,
              search_model: "",
              search_name: "",
              search_date: [],
              search_supplier_selected: [],
              search_button_loading: false,
              is_search: false,
            };
        case PurchaseOrderActionType.LIST_PRODUCT_EXPIRIES:
          let item_selected = state.item_selected
          if (item_selected.length > 0){
            for(let i = 0; i < action.list.length; i++){
              if(action.list[i].expiry_info.length > 0){
                for(let j = 0; j < action.list[i].expiry_info.length; j++){
                  for(let k = 0; k < item_selected.length; k++){
                    if(action.list[i].expiry_info[j].id === item_selected[k].id && item_selected[k].selected !== undefined && item_selected[k].selected !== null){
                      action.list[i].expiry_info[j].selected = item_selected[k].selected
                    }
                  }
                }
              }
            }
          }
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                list: action.list,
                direct_to_list: false
              };
        case PurchaseOrderActionType.SEARCH_PRODUCT_EXPIRIES:
            return {
                ...state,
                is_search: true,
                search_button_loading: action.search_button_loading
            };
        default: 
            return state;
    }
}
export default product_expiries;