import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as LoginAction from '../actions/LoginAction';
import Helper from '../utils/Helper';

class AuthComponent extends Component {

    componentDidMount() {
        this.getUser();
    }

    getUser() {
    const jwt = Helper.getJwt();
    if (jwt) {
        var user = Helper.getUser();
       // console.log("user store: ",user);
        if (user !== undefined && user !== null) {
            let is_ssl = Helper.isSSl()
            this.props.onCheckAuthenticate(user.id, is_ssl);
        } else{
           // console.log("user null");
            this.props.onSetAuthenticate(false);
        }
    } else {
        //console.log("token null");
        this.props.onSetAuthenticate(false);
    }
    }

    render() {
        const jwt = Helper.getJwt();
        if (!(jwt === "true")) {
            if(this.props.login.logout_from_web === true){
                window.location.href = process.env.REACT_APP_WEB_URL + "/admincp/login.php"
            } else{
                window.location.href = process.env.REACT_APP_NON_SSL + '/login'
            }
            return null
        }
        if(Helper.isSSl()){
            window.location.href = process.env.REACT_APP_SSL + '/ship-invoices'
            return null
        }

        return this.props.children;
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        store: state.store
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onSetAuthenticate: (authenticated) => {
            dispatch(LoginAction.setAuthenticate(authenticated))
        },
        onCheckAuthenticate: (id, is_ssl) => {
            dispatch(LoginAction.checkAuthenticateRequest(id, is_ssl))
        }
    }
}
const AuthComponentConnect = (connect(mapStateToProps, mapDispatchToProps)(AuthComponent));
export default withRouter(AuthComponentConnect);