import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom";
import Moment from 'react-moment';
import InvoiceForCustomer from '../invoice/ListForCustomer';
import LiabilitiesForCustomer from './LiabilitiesForCustomer';
import NumberFormat from 'react-number-format';
import { Alert, SelectPicker, Toggle } from 'rsuite';
import ZoneSelectBox from '../shared/ZoneSelectBox';
import Helper from '../../utils/Helper';
import * as UserAction from '../../actions/UserAction';
import * as UserActionType from '../../constants/UserActionType';
import LaddaButton, {ZOOM_IN} from 'react-ladda';
import * as AddressBookAction from '../../actions/AddressBookAction';
import CreateAddressBook from './CreateAddressBook';
import ListAddressBook from './ListAddressBook';
import Swal from 'sweetalert2';

class ViewCustomer extends Component {
    constructor() {
        super();
        this.state = { pictures: [], tab: "info", edit_customer: false, edit_customer_success: false, create_address_book: false };
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            //console.log("match.params.id: ", match.params.id)
            id = match.params.id;
        }
        if (id > 0) {
            //console.log("componentDidMount id: ", id)
            this.props.onGetCustomer(id)
            this.props.onListCustomerGroup();
            this.props.onListAddressBook({
                user_id: id,
                is_admin: 1
            },1,this.props.address_book.limit)
        }
    }

    componentDidUpdate(){
        if (this.birthdayInput !== undefined && this.birthdayInput !== null){
            this.birthdayInput.addEventListener('focus', this.onFieldFocus)
            this.birthdayInput.addEventListener('blur', this.onFieldBlur)
        }
    }

    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error= target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
            this.props.onFocusField(field_error,false); 
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDateFieldChange = (values) => {
        //console.log(values)
        if(values !== null){
            this.props.onFieldChange("birthday",values.formattedValue);
        }
    }
    onCityChange(city){
        this.props.onFieldChange("city_selected",city);
    }
    onDistrictChange(district){
        this.props.onFieldChange("district_selected",district);
    }
    onWardChange(ward){
        this.props.onFieldChange("ward_selected",ward);
    }
    onSelectCustomerGroup(item){
        this.props.onFieldChange("customer_group_selected",item);
    }
    onSelectAvatar(file){
        this.props.onFieldChange("avatar", file)
    }
    onCustomerIsCompanyModeChange(checked){
        this.props.onFieldChange("is_company", (checked === true) ? 1 : 0)
    }
    onChangeTab = (tab_selected) => {
        this.setState({
            tab: tab_selected
        })
    }
    onSetEditCustomerInfo = (action) => {
        let _this = this
        _this.setState({
            edit_customer: action
        })
    }
    onStopServing = () => {
        let is_stop_serving = 1
        let stop_serving_text = "ngừng phục vụ"
        if(+this.props.user.is_stop_serving === 1){
            stop_serving_text = "mở phục vụ lại"
            is_stop_serving = 0
        }
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn '+stop_serving_text+' khách hàng này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                let data_post = {
                    is_stop_serving: parseInt(is_stop_serving),
                    user_type: UserActionType.CUSTOMER_USER_TYPE
                }
                this.props.onSubmit(this.props.user.id, data_post).then(result => {
                    //console.log(result)
                    if (result === true){
                        this.props.onFieldChange("is_stop_serving", is_stop_serving)
                    }
                })
            },
        })
    }
    onSaveCustomerInfo(){
        let {id, is_company, name, company, phone, birthday, address, note, city_selected, district_selected, ward_selected, customer_group_selected, tax_code, gender, email, facebook_url, avatar } = this.props.user;
        if (birthday !== ""){
            birthday = Helper.convertToMysqlDate(birthday)
        }
        if (name === ""){
            this.props.onErrorField("name_error", true)
            Alert.error("Vui lòng nhập họ tên")
            this.name_input.focus()
            return
        }
        if (phone === ""){
            this.props.onErrorField("phone_error", true)
            Alert.error("Vui lòng nhập điện thoại")
            this.phone_input.focus()
            return
        }
        if (address === ""){
            this.props.onErrorField("address_error", true)
            Alert.error("Vui lòng nhập địa chỉ")
            this.address_input.focus()
            return
        }
        if (city_selected.value  === undefined){
            Alert.error("Vui lòng chọn tỉnh/thành phố")
            return
        }
        if (district_selected.value === undefined){
            Alert.error("Vui lòng chọn quận/huyện")
            return
        }
        if(is_company === 0){
            company = ""
        }
        let data_post = {
            name: name,
            phone: phone,
            address: address,
            city: parseInt(city_selected.value),
            district: parseInt(district_selected.value),
            ward: parseInt(ward_selected.value),
            birthday: birthday,
            customer_group_id: parseInt(customer_group_selected),
            tax_code,
            gender: parseInt(gender),
            company,
            email,
            note,
            facebook_url,
            avatar,
            is_company: parseInt(is_company),
            user_type: UserActionType.CUSTOMER_USER_TYPE
        }
        //console.log(data_post);
        this.props.onSubmit(id, data_post).then(result => {
            if (result === true){
                let _this = this
                _this.props.onGetCustomer(id)
                _this.onSetEditCustomerInfo(false)
                _this.setState({
                    edit_customer_success: true
                })
                setTimeout(() => {
                    _this.setState({
                        edit_customer_success: false
                    })
                },4000)
            }
        })
    }
    onSetCreateAddressBook = (action) => {
        this.setState({
            create_address_book: action
        })
    }
    onSaveAddressBookInfo = () => {
        let {name, phone, address, city_selected, district_selected, ward_selected } = this.props.address_book;
        if (name === ""){
            Alert.error("Vui lòng nhập họ tên")
            this.props.onSetPropsAddressBook("name","error")
            return
        }
        if (phone === ""){
            Alert.error("Vui lòng nhập điện thoại")
            this.props.onSetPropsAddressBook("phone","error")
            return
        }
        if (address === ""){
            Alert.error("Vui lòng nhập địa chỉ")
            this.props.onSetPropsAddressBook("address","error")
            return
        }
        if (city_selected.value === undefined){
            Alert.error("Vui lòng chọn tỉnh/thành phố")
            return
        }
        if (district_selected.value === undefined){
            Alert.error("Vui lòng chọn quận/huyện")
            return
        }
        let data_post = {
            id: this.props.address_book.id,
            name: name,
            phone: phone,
            address: address,
            city: parseInt(city_selected.value),
            district: parseInt(district_selected.value),
            ward: parseInt(ward_selected.value),
            user_id: this.props.user.id,
        }
        //console.log(data_post);
        this.props.onSaveAddressBook(data_post).then(result => {
            if(result.status !== undefined){
                if(result.status === true){
                    this.props.onListAddressBook({
                        user_id: this.props.user.id,
                        is_admin: 1
                    },1,this.props.address_book.limit)
                    this.props.onSetPropsAddressBook("clear_zone",true)
                    this.onSetCreateAddressBook(false)
                }
            }
        })
    }
    render(){
        let {id, city_name, district_name, ward_name, name, name_active, name_error, phone_active, phone_error, birthday_active, address_active, address_error, tax_code_active, email_active, company_active, facebook_url_active, save_button_loading } = this.props.user
        let zone_text = "", zone_array  = []
        if(ward_name !== ""){
            zone_array.push(ward_name)
        }
        if(district_name !== ""){
            zone_array.push(district_name)
        }
        if(city_name !== ""){
            zone_array.push(city_name)
        }
        if (zone_array.length > 0){
            zone_text = zone_array.join(", ")
        }
        let list_invoices = null, list_invoice_liabilities = null
        if (id > 0){
            //console.log("id view render: ", id)
            list_invoices = <InvoiceForCustomer customer_id={id} limit={10} />
            list_invoice_liabilities = <LiabilitiesForCustomer customer_id={id} limit={10} />
        }
        let tab_action_button = ""
        if(this.state.tab === "info"){
            if(this.state.edit_customer === true){
                tab_action_button = (
                    <div>
                        <button type="button" className="btn btn-default" onClick={() => this.onSetEditCustomerInfo(false)}><i className="fa fa-ban"></i>&nbsp;Bỏ qua</button>&nbsp;
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-success pull-right notika-btn-success waves-effect" onClick={ () => { this.onSaveCustomerInfo() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div>
                )
            } else {
                let stop_serving_button = <button type="button" className="btn btn-danger mr-right-10" onClick={() => this.onStopServing()}><i className="fa fa-ban"></i>&nbsp;Ngừng phục vụ</button>
                if(+this.props.user.is_stop_serving === 1){
                    stop_serving_button = <button type="button" className="btn btn-info mr-right-10" onClick={() => this.onStopServing()}><i className="fa fa-check"></i>&nbsp;Mở phục vụ lại</button>
                }
                tab_action_button = (
                    <div>
                        {stop_serving_button}
                        <button type="button" className="btn btn-default" onClick={() => this.onSetEditCustomerInfo(true)}><i className="fa fa-edit"></i>&nbsp;Sửa thông tin</button>
                    </div>
                )
            }
        } else if(this.state.tab === "addressbook"){
            if(this.state.create_address_book === true){
                tab_action_button = (
                    <div>
                        <button type="button" className="btn btn-default" onClick={() => this.onSetCreateAddressBook(false)}><i className="fa fa-ban"></i>&nbsp;Bỏ qua</button>&nbsp;
                        <LaddaButton
                            loading={ this.props.address_book.save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-success pull-right notika-btn-success waves-effect" onClick={ () => { this.onSaveAddressBookInfo() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div>
                )
            }
            else {
                tab_action_button = (<button type="button" className="btn btn-default" onClick={() => { this.props.onResetFieldAddressBook(); this.onSetCreateAddressBook(true) } }><i className="fa fa-plus"></i>&nbsp;Thêm địa chỉ</button>)
            }
        }

        let name_field_class = "nk-int-st", phone_field_class = "nk-int-st", company_field_class = "nk-int-st", birthday_field_class = "nk-int-st", address_field_class = "nk-int-st", tax_code_field_class = "nk-int-st", email_field_class = "nk-int-st", facebook_url_field_class = "nk-int-st"
        if (this.props.user.name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }
        if (this.props.user.phone !== "") {
            phone_active = true;
        }
        if(phone_active === true){
            phone_field_class += ' nk-toggled';
        }
        if(phone_error === true){
            phone_field_class += ' error';
        }
        if (this.props.user.birthday !== "") {
            birthday_active = true;
        }
        if(birthday_active === true){
            birthday_field_class += ' nk-toggled';
        }
        if (this.props.user.address !== "") {
            address_active = true;
        }
        if(address_active === true){
            address_field_class += ' nk-toggled';
        }
        if(address_error === true){
            address_field_class += ' error';
        }
        if (this.props.user.company !== "") {
            company_active = true;
        }
        if(company_active === true){
            company_field_class += ' nk-toggled';
        }
        if (this.props.user.email !== "") {
            email_active = true;
        }
        if(email_active === true){
            email_field_class += ' nk-toggled';
        }
        if (this.props.user.facebook_url !== "") {
            facebook_url_active = true;
        }
        if(facebook_url_active === true){
            facebook_url_field_class += ' nk-toggled';
        }
        /*if (this.props.user.note !== "") {
            note_active = true;
        }
        if(note_active === true){
            notes_field_class += ' nk-toggled';
        }*/
        if (this.props.user.tax_code !== "") {
            tax_code_active = true;
        }
        if(tax_code_active === true){
            tax_code_field_class += ' nk-toggled';
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <NavLink to="/customers"><i className="fa fa-arrow-left fa-2x text-black"></i></NavLink>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false" onClick={() => this.onChangeTab('info')}>Thông tin</a>   
                            </li>
                            <li>
                                <a data-toggle="tab" href="#addressbook" aria-expanded="false" onClick={() => this.onChangeTab('addressbook')}>Sổ địa chỉ</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#invoices" aria-expanded="false" onClick={() => this.onChangeTab('invoices')}>Lịch sử bán hàng / trả hàng</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#liabilities" aria-expanded="false" onClick={() => this.onChangeTab('liabilities')}>Nợ cần thu từ khách</a>
                            </li>
                            <li className="pull-right">
                                { tab_action_button }
                            </li>
                        </ul>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white padd-10">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="photo">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="row">
                                                { this.state.edit_customer_success === true ?
                                                <div className="col-xs-12">
                                                    <div className="alert alert-success">
                                                        Thông tin khách hàng đã lưu thành công
                                                    </div>
                                                </div>
                                                : "" }
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã khách hàng:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{ this.props.user.code }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Tên khách hàng:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ name_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.name }</strong> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Ngày sinh:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ birthday_field_class }>
                                                                    <NumberFormat className="form-control" format="##/##/####" name="birthday" autoComplete="off" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']} getInputRef = {(el) => this.birthdayInput = el} onValueChange={(values) => this.onDateFieldChange(values)} value={Helper.convertMysqlDate(this.props.user.birthday)}
                                                                    />
                                                                </div>
                                                                : this.props.user.birthday ? <Moment format="DD/MM/YYYY">{ this.props.user.birthday }</Moment> : "" }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Nhóm khách hàng:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <SelectPicker
                                                                    searchable={false}
                                                                    cleanable={false}
                                                                    placeholder="Chọn nhóm khách hàng"  
                                                                    data={this.props.user.customer_groups}
                                                                    value={this.props.user.customer_group_selected}
                                                                    onSelect={this.onSelectCustomerGroup.bind(this)}
                                                                />
                                                                :  this.props.user.customer_group_name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã số thuế:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ tax_code_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="tax_code" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.tax_code_input = input; }} value={this.props.user.tax_code}></input>
                                                                </div>
                                                                : this.props.user.tax_code }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">E-mail:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ email_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="email" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.email_input = input; }} value={this.props.user.email}></input>
                                                                </div>
                                                                : this.props.user.email }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Facebook:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            { this.state.edit_customer === true ? 
                                                                <div className={ facebook_url_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="facebook_url" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.facebook_url_input = input; }} value={this.props.user.facebook_url}></input>
                                                                </div>
                                                                : this.props.user.facebook_url }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        { this.state.edit_customer === true ? 
                                                        <div className="nk-int-st">
                                                            <Toggle name="shipping_mode" size="lg" checkedChildren={"Công ty"} unCheckedChildren={"Cá nhân"} onChange={ this.onCustomerIsCompanyModeChange.bind(this) } checked={ this.props.user.is_company === 1 ? true : false} />
                                                        </div>
                                                        : this.props.user.is_company === 1 ? <h5><label className="label label-info">Khách hàng công ty</label></h5> : ""  }
                                                    </div>
                                                    { this.props.user.is_company === 1 ?  
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Tên công ty:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            { this.state.edit_customer === true ? 
                                                                <div className={ company_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="company" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.company_input = input; }} value={this.props.user.company}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.company }</strong> }
                                                            </div>
                                                        </div>
                                                    </div> : null }
                                                    { this.state.edit_customer === false ? 
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Điểm tích lũy:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{<NumberFormat value={this.props.user.total_point} displayType={'text'} thousandSeparator={true} />}</strong>
                                                            </div>
                                                        </div>
                                                    </div> : null }
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Điện thoại:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            { this.state.edit_customer === true ? 
                                                                <div className={ phone_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="phone" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.phone_input = input; }} value={this.props.user.phone}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.phone }</strong> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Địa chỉ:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                <div className={ address_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="address" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.address_input = input; }} value={this.props.user.address}></input>
                                                                </div>
                                                                : this.props.user.address }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Khu vực:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_customer === true ? 
                                                                    <ZoneSelectBox appearance="subtle" city={this.props.user.city_selected} district={this.props.user.district_selected} ward={this.props.user.ward_selected} onCityChange={(city) => { this.onCityChange(city) }} onDistrictChange={(district) => { this.onDistrictChange(district) }} onWardChange={(ward) => { this.onWardChange(ward) }} getRef={ref => (this.zoneSelectBoxRef = ref)} />
                                                                : zone_text }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { this.state.edit_customer === false ? 
                                                    <div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">Người tạo:</label>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                    { this.props.user.staff_name }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">Ngày tạo:</label>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                    <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.user.time }</Moment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : "" }
                                                </div>                                        
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="addressbook" className="tab-pane fade bg-white padd-10">
                                { this.state.create_address_book === true ?
                                    <CreateAddressBook  />
                                : 
                                    <ListAddressBook onShowEdit={() => this.onSetCreateAddressBook(true)} />
                                } 
                            </div>
                            <div id="invoices" className="tab-pane fade bg-white padd-10">
                                { list_invoices }
                            </div>
                            <div id="liabilities" className="tab-pane fade bg-white padd-10">
                                { list_invoice_liabilities }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        address_book: state.address_book,
        invoice: state.invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetCustomer: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
        onErrorField(field,value){
            dispatch(UserAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(UserAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(UserAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(UserAction.resetField())
        },
        onSubmit(id, data_post){
            return dispatch(UserAction.updateRequest(id, data_post))
        },
        onListCustomerGroup(){
            dispatch(UserAction.listCustomerGroupRequest())
        },
        onSaveAddressBook(data_post){
            return dispatch(AddressBookAction.updateRequest(data_post))
        },
        onSetPropsAddressBook(field,value){
            dispatch(AddressBookAction.setProps(field,value))
        },
        onListAddressBook(data_search, page, limit){
            dispatch(AddressBookAction.searchRequest(data_search, page, limit, false))
        },
        onResetFieldAddressBook(){
            dispatch(AddressBookAction.resetField())
        }
    }
}
const ViewCustomerConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewCustomer));
export default withRouter(ViewCustomerConnect);