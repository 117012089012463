import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as TransferStoreAction from '../../actions/TransferStoreAction';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
class ListTransfers extends Component {
    componentDidMount(){
        let _this = this
        let product_id = Helper.getQueryString('product_id');
        if(product_id !== null){
            let product_option_id_param = Helper.getQueryString('product_option_id');
            let product_option_id = 0
            if (product_option_id_param !== null){
                product_option_id = product_option_id_param
            }
            _this.props.onSearch({
                product_id,
                product_option_id
            },1,_this.props.transfer_store.limit, true);
        } else{
            var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
            var to_date = moment().endOf('month').format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            _this.props.onFieldChange("search_date",search_date);
            setTimeout(() => {
                var data_search = this.getDataSearch();
                _this.props.onSearch(data_search,1,_this.props.transfer_store.limit, true);
            }, 250);
        }
        this.props.onListTransferStoreStatus();
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }

    getDataSearch(){
        let _code = this.props.transfer_store.search_code;
        let _product = this.props.transfer_store.search_product;
        let _status = this.props.transfer_store.search_status;
        let _date = this.props.transfer_store.search_date;
        
        var data_search = {
            "code": _code,
            "product_keyword": _product
        }
        if(_code === ""){
            if (_status.length > 0){
                data_search["status"] = _status.join(",");
            }
            if (typeof _date[0] !== 'undefined'){
                data_search["from_date"] = _date[0];
            }
            if (typeof _date[1] !== 'undefined'){
                data_search["to_date"] = _date[1];
            }
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        this.props.onSearch(data_search,1,this.props.transfer_store.limit, true);
    }
    onViewAll(){
        this.supplierSelectBoxRef.onClean()
        this.props.onResetFieldSearch();
        this.props.onList(1,this.props.transfer_store.limit);
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        this.props.onSearch(data_search,pageNumber,this.props.transfer_store.limit, false);
    }
    onItemClick(id){
        this.props.history.push("/transfer-store/" + id);
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let status_text = <label className='label' style={{backgroundColor: item.transfer_status_color }}>{ item.transfer_status_text }</label>
                let from_store_text = []
                if(item.from_store !== null && item.from_store.length > 0){
                    item.from_store.forEach(storeItem => {
                        from_store_text.push(
                            <span key={"from_store_" + item.id + "_" + storeItem.store_id}>{storeItem.store_code}<br /> {storeItem.store_name}</span>
                        )
                    })
                }
                let has_difference_text = ""
                if (item.has_difference === 1){
                    has_difference_text = <div key={"has_difference_" + item.id}><label className='label label-danger'>Có chênh lệch</label></div>
                }
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td>{item.transfer_store_code}</td>
                         <td>
                            <Moment format="DD/MM/YYYY HH:mm">
                            { Helper.replaceMysqlDateTime(item.transfer_date) }
                            </Moment>
                         </td>
                         <td>{ from_store_text }</td>
                         <td>{ item.to_store_code }<br />{ item.to_store_name }</td>
                         <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">{ status_text }{has_difference_text}</td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, search_code, search_product, search_button_loading } = this.props.transfer_store;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        /*let btn_view_all = ""
        if(is_search === true){
            btn_view_all =  (<LaddaButton
                                loading={ search_button_loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onViewAll() } } >
                                <i className="fa fa-list"></i>&nbsp;Xem tất cả
                            </LaddaButton>)
        }*/
        let allow_create_transfer = false
        if (Helper.checkMyRole('transfer_store') === true){
            allow_create_transfer = true
        }
        return (
            <div className="container">
            <Helmet>
                <title>Danh sách phiếu chuyển kho</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/transfer-stores"} />
            </Helmet>  
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter placeholder="Chọn ngày chuyển kho"  value={this.props.transfer_store.search_date}
                                    onFieldChange = { (value) => { this.onDatePickerChange(value) } }
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_product" value={ search_product } className="form-control" autoComplete="off" placeholder="Nhập mã hoặc tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        <div className="pull-left">Phiếu chuyển kho</div>
                                    </h2>
                                    <p></p>
                                </div>
                                { allow_create_transfer === true ?
                                <button type="button" onClick={() => { this.props.onEmptyInfo(); this.props.history.push("/transfer-store/new") }} className="btn btn-info pull-right"><i className="fa fa-plus"></i>&nbsp;Chuyển kho</button> : null }
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="20%"></col>
                                        <col width="20%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã phiếu</th>
                                            <th>Thời gian</th>
                                            <th>Từ kho</th>
                                            <th>Đến kho</th>
                                            <th className="text-right">Tổng tiền</th>
                                            <th>Người tạo</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transfer_store: state.transfer_store
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(TransferStoreAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(TransferStoreAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(TransferStoreAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(TransferStoreAction.fieldChange(field,value))
        },
        onResetFieldSearch(){
            dispatch(TransferStoreAction.resetFieldSearch())
        },
        onListTransferStoreStatus: () => {
            dispatch(TransferStoreAction.listStatusRequest())
        },
        onList: (page,limit) => {
            dispatch(TransferStoreAction.listRequest(page,limit))
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(TransferStoreAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(ListTransfers)