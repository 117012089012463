export const LIST_BILL_LADINGS = 'LIST_BILL_LADINGS';
export const GET_BILL_LADINGS = 'GET_BILL_LADINGS';
export const LIST_BILL_LADINGS_STATUS = 'LIST_BILL_LADINGS_STATUS';
export const SEARCH_BILL_LADINGS = 'SEARCH_BILL_LADINGS';
export const RESET_FIELD_SEARCH_BILL_LADINGS = 'RESET_FIELD_SEARCH_BILL_LADINGS';
export const FIELD_CHANGE_BILL_LADINGS = 'FIELD_CHANGE_BILL_LADINGS';
export const FIELDS_CHANGE_BILL_LADINGS = 'FIELDS_CHANGE_BILL_LADINGS';
export const EMPTY_BILL_LADINGS = 'EMPTY_BILL_LADINGS';
export const BUTTON_UPDATE_SPINNER_BILL_LADINGS = 'BUTTON_UPDATE_SPINNER_BILL_LADINGS';

export const STATUS_NEW_SHIP_BILL_LADINGS = 0
export const STATUS_SHIP_IN_PROGRESS_BILL_LADINGS = 1
export const STATUS_FINISH_SHIP_BILL_LADINGS = 2
export const STATUS_SHIP_PENDING_BILL_LADINGS = 3
export const STATUS_CANCEL_SHIP_BILL_LADINGS = 4

export const REASON_PENDING_DO_NOT_ANSWER = 1
export const REASON_PENDING_CUSTOMER_CANCEL = 2
export const REASON_PENDING_WRONG_PACKAGE = 3
export const REASON_PENDING_WRONG_CUSTOMER_INFO = 4
export const REASON_PENDING_DELIVERY_NOT_ON_TIME = 5
export const REASON_PENDING_OTHER = 6