import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import * as BillLadingAction from '../../actions/BillLadingAction';
import * as LoginAction from '../../actions/LoginAction';
import BillLadingStatusSelectBox from './BillLadingStatusSelectBox';
import UserSelectBox from '../shared/UserSelectBox';
import {DatePicker} from 'rsuite';
import moment from 'moment';
import Helper from '../../utils/Helper';
import logo from '../../logo.png';
import '../../orientation_lock.css';

class ShipInvoiceHeader extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_search_form: false,
            is_search: false
        }
        
    }
    componentDidMount(){
        let _this = this
        _this.bindDataSearch();
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            if (data_search !== null && data_search.search !== undefined && data_search.search === true){
                _this.props.onSearch(data_search,_this.props.bill_lading.page,_this.props.bill_lading.limit, false);
                _this.setState({
                    is_search: data_search.search
                })
            }
        })
        this.props.innerRef(this)
        window.addEventListener("orientationchange", () => {
            this.checkWindowOrientation()
        })
        window.addEventListener("load", () => {
            this.checkWindowOrientation()
        })
    }
    componentWillUnmount(){
        this.props.innerRef(null)
    }
    checkWindowOrientation(){
        switch ( window.orientation ) {
            case 0:
                window.jQuery('.turnDeviceNotification').css('display', 'none');
              // The device is in portrait mode now
            break
            case 180:
                window.jQuery('.turnDeviceNotification').css('display', 'none');
              // The device is in portrait mode now
            break
            case 90:
              // The device is in landscape now
                window.jQuery('.turnDeviceNotification').css('display', 'block');
            break
            case -90:
              // The device is in landscape now
                window.jQuery('.turnDeviceNotification').css('display', 'block');
            break
            default:
                break
        }
    }
    onGoBackList(){
        let back_list = this.props.bill_lading.back_list
        if(back_list === ""){
            back_list = "/ship-invoices"
        }
        this.props.history.push(back_list)
    }
    onShowSearch(){
        this.setState({
            show_search_form: !this.state.show_search_form
        })
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onReceiptBillDatePickerChange(value){
        if (typeof value !== undefined){
            let search_date = value
            if(value !== null){
                search_date = moment(value).format('YYYY-MM-DD'); 
            }
            this.props.onFieldChange("search_on_date",search_date);
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("search_customer_selected", item);
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let status = Helper.getQueryString('ship_status');
        let from_date = Helper.getQueryString('date');
        let customer_id = Helper.getQueryString('customer');
        var search_on_date = null;
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_on_date = from_date
        }
        var search_customer_selected = null
        if(customer_id !== undefined && customer_id !== null && customer_id > 0){
            search_customer_selected = {}
            search_customer_selected.value = parseInt(customer_id)
            this.props.onGetCustomer(customer_id).then(result => {
                if (result.id !== undefined && result.id > 0){
                    search_customer_selected.label = result.name + " - " + result.phone
                }
            })
        }
        var search_status = []
        if (status !== undefined && status !== null && status !== ""){
            let search_status_array = status.split(",")
            search_status_array.forEach(item => {
                search_status.push(parseInt(item))
            })
        }
        if (search_code === null){
            search_code = ""
        }
        this.props.onFieldsChange({
            search_code, search_status, search_on_date, search_customer_selected
        })
    }
    getDataSearch(){
        let _code = this.props.bill_lading.search_code;
        let _status = this.props.bill_lading.search_status;
        let _date = this.props.bill_lading.search_on_date;
        let _customer = this.props.bill_lading.search_customer_selected;
        let transporter_id = 0
        var transporter_info = Helper.getStaffTransporter();
        if(transporter_info !== undefined && transporter_info !== null && transporter_info.id !== undefined && transporter_info.id > 0){
            transporter_id = transporter_info.id
        }
        var data_search = null
        if(transporter_id > 0){
            data_search = {
                "search": false,
                "skip_in_progress": 1,
                "transporter_id": transporter_id,
                "param": []
            }
            let is_search = false
            if (_code !== ""){
                data_search["bill_code"] = _code;
                data_search["param"].push("code=" + _code)
                is_search = true
                this.props.onFieldsChange({
                    search_customer_selected: null
                })
            } else {
                if (_status.length > 0){
                    data_search["ship_status"] = _status.join(",");
                    data_search["param"].push("ship_status=" + _status.join(","))
                    is_search = true
                }
                if (_date !== undefined && _date !== null){
                    data_search["from_date"] = _date;
                    data_search["param"].push("date=" + _date)
                    is_search = true
                }
                if(_customer !== undefined && _customer !== null && _customer.value !== undefined){
                    data_search["customer_id"] = _customer.value;
                    data_search["param"].push("customer=" + _customer.value)
                    is_search = true
                }
            }
            data_search["search"] = is_search;
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search !== null && data_search.search !== undefined && data_search.search === true){
            if(data_search.param.length > 0){
                let param = data_search.param.join("&")
                if(param !== ""){
                    param = "?" + param
                }
                this.props.history.push("/ship-invoices" + param)
                this.props.onFieldChange("back_list", "/ship-invoices" + param)
            }
            this.props.onSearch(data_search,1,this.props.bill_lading.limit, true);
            this.onShowSearch()
            this.setState({
                is_search: data_search.search
            })
        }
    }
    onClearSearch(){
        this.userSelectBoxRef.onClean()
        this.props.onFieldsChange({
            search_customer_selected: null, search_code: "", search_status: [], search_on_date: null
        })
        this.setState({
            is_search: false
        })
        this.props.onGetDefault()
    }
    render(){
        const jwt = Helper.getJwt();
        if (!(jwt === "true")) {
            window.location.href = process.env.REACT_APP_NON_SSL + '/login'
            return null
        }
        let { search_on_date, search_code, search_button_loading } = this.props.bill_lading;
        let search_date = null
        if(search_on_date !== null){
            search_date = new Date(search_on_date)
        }
        let icon_search = "fa-search"
        if(this.state.show_search_form === true){
            icon_search = "fa-remove"
        }
        return (
            <div className="bill-ladings__header">
                { this.props.back === true ? <i className="btn-back fa fa-chevron-left pull-left" onClick={() =>  this.onGoBackList() }></i> : null }
                { this.props.search === true ? <i className={"btn-search-icon fa "+icon_search+" pull-left"} onClick={() =>  this.onShowSearch() }></i> : null }
                <img className="logo" alt="" src={logo} style={ { "height":"40px" } } />
                <i className="btn-sign-out fa fa-power-off pull-right" onClick={() => this.props.onLogout()}></i>
                {this.state.show_search_form === true ?
                <div className="bill-ladings__search-form">
                    <div className="form-element-list" >
                        <div className="cmp-tb-hd">
                            <h2>Bộ lọc</h2>
                            <p></p>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <DatePicker value={search_date} placeholder="Chọn ngày bán hàng" onChange = { (value) => { this.onReceiptBillDatePickerChange(value) } } style={{width: "100%"}} ranges={[]} />
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã hóa đơn bán hàng" onChange={ (e) => { this.onFieldChange(e) } }></input>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <BillLadingStatusSelectBox multi={true} select_style={{width: "100%"}} />
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <UserSelectBox user_selected={this.props.bill_lading.search_customer_selected} getRef={rel => {this.userSelectBoxRef = rel} } onFieldChange={(item) => { this.onUserFieldChange(item) } }/>
                        </div>
                        {this.state.is_search === true ?
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className="pull-left w-50">
                                <button type="button" className="btn btn-link" onClick={() => this.onClearSearch()}>Nhập lại</button>
                            </div>
                            <div className="pull-left w-50">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div> : 
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <LaddaButton
                                loading={ search_button_loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                            </LaddaButton>
                        </div>
                        }
                    </div>
                </div> : null }
                <div className="turnDeviceNotification"></div>
            </div>   
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        bill_lading: state.bill_lading
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onLogout: () => {
            dispatch(LoginAction.logoutRequest())
        },
        onFieldChange(field,value){
            dispatch(BillLadingAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(BillLadingAction.fieldsChange(fields))
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(BillLadingAction.searchRequest(data_search,page,limit,spinner, true))
        },
    }
}
const ShipInvoiceHeaderConnect = (connect(mapStateToProps, mapDispatchToProps)(ShipInvoiceHeader));
export default withRouter(ShipInvoiceHeaderConnect);