import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Products from './product/Lists';
import ViewProduct from './product/View';
import TransferWarehouse from './inventory/Transfer';
import CreateCheckInventories from './inventory/CreateCheckInventories';
import ListCheckInventories from './inventory/ListCheckInventories';
import PurchaseOrders from './purchase-order/Lists';
import CreatePurchaseOrder from './purchase-order/Create';
import CreatePurchaseOrderFromTransfer from './purchase-order/CreateFromTransfer';
import CreatePurchaseOrderFromReturn from './purchase-order/CreateFromReturn';
import ViewPurchaseOrder from './purchase-order/View';
import ReturnPurchaseOrder from './purchase-order/Return';
import ListReturnPurchaseOrder from './purchase-order/ListReturn';
import ViewReturnPurchaseOrder from './purchase-order/ViewReturn';
import Invoices from './invoice/Lists';
import CreateInvoice from './invoice/Create';
import ViewInvoice from './invoice/View';
import ReturnInvoice from './invoice/Return';
import ReturnInvoices from './invoice/ListReturn';
import ViewReturnInvoice from './invoice/ViewReturn';
import ListCustomers from './partner/ListCustomers';
import ViewCustomer from './partner/ViewCustomer';
import ListTransporters from './partner/ListTransporters';
import ListSuppliers from './partner/ListSuppliers';
import ViewSupplier from './partner/ViewSupplier';
import ListStaffs from './partner/ListStaffs';
import ViewStaff from './partner/ViewStaff';
import ReportProducts from './report/ReportProducts';
import ReportSale from './report/ReportSale';
import Dashboard from './Dashboard';
import PaymentInvoices from './accounting/Lists';
import ViewPaymentInvoice from './accounting/View';
import AccountingTerms from './accounting/Terms';
import BankAccounts from './accounting/BankAccounts';
import AccountingCreatePayment from './accounting/CreatePayment';
import AccountingCreateVendorBill from './accounting/CreateVendorBill';
import AccountingVendorBills from './accounting/VendorBills';
import ViewVendorBill from './accounting/ViewVendorBill';
import ListOwnerPayment from './accounting/ListOwnerPayment';
import ViewOwnerPayment from './accounting/ViewOwnerPayment';
import ListTransfer from './inventory/ListTransfer';
import CreateReceiptGood from './receipt-good/Create';
import ViewReceiptGood from './receipt-good/View';
import ListReceiptGoods from './receipt-good/List';
import ReceiptOfGoods from './purchase-order/ReceiptOfGoods';
import ViewReceiptOfGood from './purchase-order/ViewReceiptOfGood';
import SupplierVendorBills from './purchase-order/SupplierVendorBills';
import CreateSupplierVendorBill from './purchase-order/CreateSupplierVendorBill';
import ViewSupplierVendorBill from './purchase-order/ViewSupplierVendorBill';
import ListBankTransactions from './accounting/ListBankTransactions';
import ReportOverviewBusiness from './report/ReportOverviewBusiness';
import ViewTransporter from './partner/ViewTransporter';
import AssignTransporter from './invoice/AssignTransporter';
import BillLadings from './invoice/BillLadings';
import ViewBillLading from './invoice/ViewBillLading';
import ProductExpiries from './product/ListExpiries';
class Home extends Component {
    render(){
        return (
            <div>
                <Header />
                <Switch>
                    <Route path="/" exact>
                        <Dashboard />
                    </Route>
                    <Route path="/products" exact>
                        <Products />
                    </Route>
                    <Route path="/products-in-stock" exact>
                        <Products in_stock={true} />
                    </Route>
                    <Route path="/expiries" exact>
                        <ProductExpiries />
                    </Route>
                    <Route path="/transfer-stores" exact>
                        <ListTransfer />
                    </Route>
                    <Route path="/transfer-store/new" exact>
                        <TransferWarehouse />
                    </Route>
                    <Route path="/transfer-store/:id" exact render={({ match }) => <TransferWarehouse match={match} />} >
                    </Route>
                    <Route path="/inventories-check" exact>
                        <ListCheckInventories />
                    </Route>
                    <Route path="/inventory-check/new" exact>
                        <CreateCheckInventories />
                    </Route>
                    <Route path="/inventory-check/:id" exact render={({ match }) => <CreateCheckInventories match={match} />} >
                    </Route>
                    <Route path="/product/:id" exact render={({ match }) => <ViewProduct match={match} />} >
                    </Route>
                    <Route path="/purchase-order/new-from-transfer" exact>
                        <CreatePurchaseOrderFromTransfer />
                    </Route>
                    <Route path="/purchase-order/new-from-return" exact>
                        <CreatePurchaseOrderFromReturn />
                    </Route>
                    <Route path="/purchase-order/new" exact>
                        <CreatePurchaseOrder />
                    </Route>
                    <Route path="/accounting/purchase-orders" exact>
                        <PurchaseOrders accounting={true} />
                    </Route>
                    <Route path="/purchase-orders" exact>
                        <PurchaseOrders />
                    </Route>
                    <Route path="/accounting/purchase-order/:id" exact render={({ match }) => <ViewPurchaseOrder match={match} accounting={true}  />} ></Route>
                    <Route path="/purchase-order/return" exact render={({ match }) => <ReturnPurchaseOrder match={match} />} >
                    </Route>
                    <Route path="/return-purchase-orders" exact>
                        <ListReturnPurchaseOrder />
                    </Route>
                    <Route path="/return-purchase-order/:id" exact render={({ match }) => <ViewReturnPurchaseOrder match={match} />} >
                    </Route>
                    <Route path="/purchase-order/:id" exact render={({ match }) => <ViewPurchaseOrder match={match} />} >
                    </Route>
                    <Route path="/receipt-of-goods" exact>
                        <ReceiptOfGoods />
                    </Route>
                    <Route path="/receipt-of-good/:id" exact render={({ match }) => <ViewReceiptOfGood match={match} />} >
                    </Route>
                    <Route path="/supplier-vendor-bills" exact>
                        <SupplierVendorBills />
                    </Route>
                    <Route path="/supplier-vendor-bill/new" exact>
                        <CreateSupplierVendorBill />
                    </Route>
                    <Route path="/supplier-vendor-bill/:id" exact render={({ match }) => <ViewSupplierVendorBill match={match} />} >
                    </Route>
                    <Route path="/invoice/new" exact>
                        <CreateInvoice />
                    </Route>
                    <Route path="/invoices" exact>
                        <Invoices />
                    </Route>
                    <Route path="/return-invoices" exact>
                        <ReturnInvoices />
                    </Route>
                    <Route path="/return-invoice/:id" exact render={({ match }) => <ViewReturnInvoice match={match} />} >
                    </Route>
                    <Route path="/invoice/return" exact render={({ match }) => <ReturnInvoice match={match} />} >
                    </Route>
                    <Route path="/invoice/:id" exact render={({ match }) => <ViewInvoice match={match} />} >
                    </Route>
                    <Route path="/receipt-goods/new" exact>
                        <CreateReceiptGood />
                    </Route>
                    <Route path="/receipt-good/:id" exact render={({ match }) => <ViewReceiptGood match={match} />} >
                    </Route>
                    <Route path="/receipt-goods" exact>
                        <ListReceiptGoods />
                    </Route>
                    <Route path="/staffs" exact>
                        <ListStaffs />
                    </Route>
                    <Route path="/staff/:id" exact render={({ match }) => <ViewStaff match={match} />} >
                    </Route>
                    <Route path="/suppliers" exact>
                        <ListSuppliers/>
                    </Route>
                    <Route path="/supplier/:id" exact render={({ match }) => <ViewSupplier match={match} />} >
                    </Route>
                    <Route path="/transporters" exact>
                        <ListTransporters />
                    </Route>
                    <Route path="/transporter/:id" exact render={({ match }) => <ViewTransporter match={match} />} >
                    </Route>
                    <Route path="/assign-transporter" exact render={({ match }) => <AssignTransporter match={match} />} >
                    </Route>
                    <Route path="/bill-ladings" exact>
                        <BillLadings />
                    </Route>
                    <Route path="/bill-lading/:id" exact render={({ match }) => <ViewBillLading match={match} />} >
                    </Route>
                    <Route path="/customers" exact>
                        <ListCustomers />
                    </Route>
                    <Route path="/customer/:id" exact render={({ match }) => <ViewCustomer match={match} />} >
                    </Route>
                    <Route path="/report/products" exact>
                        <ReportProducts />
                    </Route>
                    <Route path="/report/sale" exact>
                        <ReportSale />
                    </Route>
                    <Route path="/report/overview-business" exact>
                        <ReportOverviewBusiness />
                    </Route>
                    <Route path="/accounting/invoices" exact>
                        <PaymentInvoices />
                    </Route>
                    <Route path="/accounting/invoice/:id" exact render={({ match }) => <ViewPaymentInvoice match={match} />} >
                    </Route>
                    <Route path="/accounting/payment-bills" exact >
                        <ListOwnerPayment />
                    </Route>
                    <Route path="/accounting/payment-bill/new" exact >
                        <AccountingCreatePayment  />
                    </Route>
                    <Route path="/accounting/payment-bill/:id" exact render={({ match }) => <ViewOwnerPayment match={match} />} >
                    </Route>
                    <Route path="/accounting/terms" exact >
                        <AccountingTerms />
                    </Route>
                    <Route path="/accounting/banks" exact >
                        <BankAccounts />
                    </Route>
                    <Route path="/accounting/vendor-bills" exact >
                        <AccountingVendorBills />
                    </Route>
                    <Route path="/accounting/vendor-bill/new" exact >
                        <AccountingCreateVendorBill />
                    </Route>
                    <Route path="/accounting/vendor-bill/:id" exact render={({ match }) => <ViewVendorBill match={match} />} >
                    </Route>
                    <Route path="/accounting/inventories-check" exact>
                        <ListCheckInventories accounting={true} />
                    </Route>
                    <Route path="/accounting/inventory-check/:id"  exact render={({ match }) => <CreateCheckInventories match={match} accounting={true}  />}></Route>
                    <Route path="/accounting/bank-transactions" exact>
                        <ListBankTransactions  />
                    </Route>
                </Switch>
                <Footer />
            </div>
        );
    }
}
export default Home