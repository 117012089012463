import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as InventoryCheckAction from '../../actions/InventoryCheckAction';
import * as InventoryCheckActionType from '../../constants/InventoryCheckActionType';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
class ListCheckInventories extends Component {
    componentDidMount(){
        let product_id = Helper.getQueryString('product_id');
        if(product_id !== null){
            let product_option_id_param = Helper.getQueryString('product_option_id');
            let product_option_id = 0
            if (product_option_id_param !== null){
                product_option_id = product_option_id_param
            }
            this.props.onSearchs({
                product_id,
                product_option_id
            },1,this.props.inventory_check.limit, true);
        } else if(this.props.accounting === true){
            this.props.onSearchs({
                receipt_status: InventoryCheckActionType.STATUS_FINISH_INVENTORY_CHECK
            },1,this.props.inventory_check.limit, true);
        } else {
            this.props.onLists(1,this.props.inventory_check.limit); 
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("supplier_selected", item);
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }

    getDataSearch(){
        let _code = this.props.inventory_check.search_code;
        let _product = this.props.inventory_check.search_product;
        let _date = this.props.inventory_check.search_date;
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        var data_search = {
            "code": _code,
            "product_keyword": _product
        }
        if (typeof _date[0] !== 'undefined'){
            data_search["from_date"] = _date[0];
        }
        if (typeof _date[1] !== 'undefined'){
            data_search["to_date"] = _date[1];
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        this.props.onSearchs(data_search,1,this.props.inventory_check.limit, true);
    }
    onViewAll(){
        this.supplierSelectBoxRef.onClean()
        this.props.onResetFieldSearch();
        this.props.onLists(1,this.props.inventory_check.limit);
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        this.props.onSearchs(data_search,pageNumber,this.props.inventory_check.limit, false);
    }
    onItemClick(id){
        if(this.props.accounting === true){
            this.props.history.push("/accounting/inventory-check/" + id);
        } else{
            this.props.history.push("/inventory-check/" + id);
        }
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let is_status_text = ""
                if(item.inventory_check_status !== InventoryCheckActionType.STATUS_FINISH_INVENTORY_CHECK){
                    is_status_text = <label className='label' style={{backgroundColor: item.inventory_check_status_color }}>{ item.inventory_check_status_text }</label>
                }
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td>{item.inventory_check_code}</td>
                         <td>
                            <Moment format="DD/MM/YYYY HH:mm">
                            { Helper.replaceMysqlDateTime(item.inventory_check_date) }
                            </Moment>
                         </td>
                         <td>
                            { item.finish_time > 0 ? <Moment unix format="DD/MM/YYYY HH:mm">
                            { item.finish_time }
                            </Moment> : null }
                         </td>
                         <td className="text-right"><NumberFormat value={item.total_quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-center">{is_status_text}</td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, search_code, search_product, search_button_loading } = this.props.inventory_check;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let allow_create_check = false
        if(Helper.checkMyRole('create_inventory_check') === true ){
            allow_create_check = true
        }
        return (
            <div className="container">
            <Helmet>
                <title>Danh sách phiếu kiểm kho</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/inventories-check"} />
            </Helmet>  
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter placeholder="Chọn ngày kiểm kho"  value={this.props.inventory_check.search_date}
                                    onFieldChange = { (value) => { this.onDatePickerChange(value) } }
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_product" value={ search_product } className="form-control" autoComplete="off" placeholder="Nhập mã hoặc tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                    Phiếu kiểm kho
                                    </h2>
                                    <p></p>
                                </div>
                                { (allow_create_check === true && this.props.accounting === false) ?
                                    <Link to="/inventory-check/new" className="btn btn-green pull-right"><i className="fa fa-plus"></i>&nbsp;Kiểm kho</Link>
                                : "" }
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã phiếu</th>
                                            <th>Thời gian</th>
                                            <th>Ngày cân bằng</th>
                                            <th className="text-right">SL thực tế</th>
                                            <th className="text-right">Tổng thực tế</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

ListCheckInventories.defaultProps = {
    accounting: false
}

const mapStateToProps = state => {
    return {
        inventory_check: state.inventory_check
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(InventoryCheckAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InventoryCheckAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(InventoryCheckAction.fieldChange(field,value))
        },
        onResetFieldSearch(){
            dispatch(InventoryCheckAction.resetFieldSearch())
        },
        onLists: (page,limit) => {
            dispatch(InventoryCheckAction.listRequest(page,limit))
        },
        onSearchs: (data_search,page,limit,spinner) => {
            dispatch(InventoryCheckAction.searchRequest(data_search,page,limit,spinner))
        },
        onGet: (id) => {
            dispatch(InventoryCheckAction.getRequest(id))
        },
        onItemButtonSpinner(id,status){
            dispatch(InventoryCheckAction.itemButtonSpinner(id,status))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(ListCheckInventories)