import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN, XL } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert, SelectPicker } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as DiscountModeType from '../../constants/DiscountModeType';
import * as UserActionType from '../../constants/UserActionType';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import * as SupplierVendorBillActionType from '../../constants/SupplierVendorBillActionType';
import * as UserAction from '../../actions/UserAction';
import UserSelectBox from '../shared/UserSelectBox';
import SearchProduct from '../shared/SearchProduct';
import Helper from '../../utils/Helper';
import import_products_file from '../../import_products.xlsx';
import Uploader from '../shared/Uploader';

const { Column, HeaderCell, Cell } = Table;

class CreatePurchaseOrder extends Component {
    componentDidMount(){
        //this.props.onSumTotal();  
        this.props.onEmptyInfo();
        this.props.onSearchSupplierVendorBill({
            "status": SupplierVendorBillActionType.STATUS_NEW_SUPPLIER_VENDOR_BILL + "," + SupplierVendorBillActionType.STATUS_WARNING_SUPPLIER_VENDOR_BILL
        });
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }   
    onUploadSuccess = list => {
        let list_temp = list
        list = []
        if (list_temp.length > 0){
            list_temp.forEach(item => {
                let key = item.id + '_' + item.product_option_id
                list.push({
                    "key": key,
                    ...item
                })
            })
        }
        this.props.onUpdateProducts(list);
    }
    onSelectProduct = itemSelected => {
        if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            this.props.onSelectProductToList(itemSelected)
        }
    }
    onRemoveProduct = key => {
        let products = this.props.purchase_order.products;
        let index = Helper.findIndexByKey(products, key);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onClearProduct = () => {
        this.props.onClearProduct();
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("real_quantity", key, value);
    }
    onExpiryDateItemChange = (key,value) => {
        let expiry_date = null
        if (value !== undefined && value !== null){
            expiry_date = moment(value).format('YYYY-MM-DD')
        }
        this.props.onUpdateProductItem("expiry_date", key, expiry_date)
    } 
    onImportReceiptDatePickerChange(value){
        if (value !== undefined && value !== null){
            this.props.onFieldChange("import_receipt_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onUserFieldChange = item => {
        this.props.onFieldChange("supplier_selected", item)
    }
    onSelectSupplierVendorBill = item => {  
        if(item > 0){
            this.props.onGetSupplierVendorBill(item)
        }
    }
    onSubmit(){
        let {products, supplier_selected, supplier_vendor_bills, supplier_vendor_bill_selected, import_receipt_date, import_receipt_notes, discount, discount_mode, payment_method_info} = this.props.purchase_order
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        if (supplier_selected.length === 0){
            Alert.error('Vui lòng chọn nhà cung cấp')
            return;
        }
        if (import_receipt_date === ""){
            import_receipt_date = moment().hour(0).minute(0).second(0).format("YYYY-MM-DD HH:mm:ss")
        }
        let my_stores = Helper.getMyStores()
        let products_array = []
        products.forEach(item => {
            let allow = true
            if(item.from_supplier_vendor_bill === 1 && item.quantity_real > 0 && item.quantity <= item.quantity_real){
                allow = false
            }
            if (allow === true){
                let quantity_supplier_vendor_bill = 0, quantity_real_supplier_vendor_bill = 0
                if(item.quantity !== undefined && item.quantity > 0){
                    quantity_supplier_vendor_bill = parseInt(item.quantity)
                }
                if(item.quantity_real !== undefined && item.quantity_real > 0){
                    quantity_real_supplier_vendor_bill = parseInt(item.quantity_real)
                }
                let expiry_date = ""
                if(item.expiry_date !== undefined && item !== null){
                    expiry_date = item.expiry_date
                }
                products_array.push({
                    "id": item.id,
                    "product_option_id": item.product_option_id,
                    "product_name": item.name,
                    "product_sku": item.sku,
                    "product_model": item.model,
                    "category_id": parseInt(item.category_id),
                    "category_name": item.category_name,
                    "brand_id": parseInt(item.brand_id),
                    "brand_name": item.brand_name,
                    "retail": 0,
                    "quantity_supplier_vendor_bill": quantity_supplier_vendor_bill,
                    "quantity_real_supplier_vendor_bill": quantity_real_supplier_vendor_bill,
                    "quantity": parseInt(item.real_quantity),
                    "discount": 0,
                    "discount_money": 0,
                    "is_gift": parseInt(item.is_gift),
                    "expiry_date": expiry_date,
                    "has_expiry": item.has_expiry,
                    "is_check_inventory": 0,
                })
            }
        })
        if (products_array.length === 0){
            Alert.error('Không có hàng hóa nào được lựa chọn nhập hàng')
            return;
        }
        let discount_money = discount
        if (discount_mode === DiscountModeType.DISCOUNT_MODE_BY_PERCENT){
            discount_money = 0
        } else{
            discount = 0
        }
        let supplier_vendor_bill_code = ""
        if(supplier_vendor_bills !== null && supplier_vendor_bills.length > 0){
            for(let i = 0; i < supplier_vendor_bills.length; i++){
                if (supplier_vendor_bills[i].id === supplier_vendor_bill_selected){
                    supplier_vendor_bill_code = supplier_vendor_bills[i].vendor_bill_code
                    break
                }
            }
        }
        let data_post = {
            import_receipt_date: import_receipt_date,
            supplier_id: supplier_selected.value,
            supplier_name: supplier_selected.label,
            supplier_vendor_bill_id: supplier_vendor_bill_selected,
            supplier_vendor_bill_code: supplier_vendor_bill_code,
            import_receipt_notes: import_receipt_notes,
            discount: parseFloat(discount),
            discount_money: parseFloat(discount_money),
            store_id: my_stores.store_id,
            store_code: my_stores.store_code,
            store_name: my_stores.store_name,
            products: products_array,
            payment_bills: payment_method_info
        }
        //console.log(data_post);
        this.props.onSubmit(data_post).then(result => {
            if(result !== null){
                if(result.status !== undefined && result.status !== null && result.status === false){
                    if(result.message !== undefined && result.message !== null && result.message !== ""){
                        Alert.error(result.message)
                        return;
                    }
                }
                else if(result.id !== undefined && result.id !== null){
                    this.props.history.push("/purchase-order/" + result.id);
                }
                else if(result.length > 0){
                    let first_id = 0
                    result.forEach(item => {
                        if(first_id === 0){
                            first_id = item.id
                        } else{
                            window.open("/purchase-order/" + item.id)
                        }
                    })
                    if (first_id > 0){
                        this.props.history.push("/purchase-order/" + first_id);
                    }
                }
            }
        })
    }
    render(){
        let {supplier_vendor_bills, supplier_vendor_bill_selected, products, total_quantity, total_product, import_receipt_notes, import_receipt_notes_active, save_button_loading } = this.props.purchase_order;
        
        let notes_field_class = "nk-int-st"
        if (import_receipt_notes !== "") {
            import_receipt_notes_active = true;
        }
        if(import_receipt_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }
        let supplier_vendor_bills_array = [], supplier_vendor_bill_value_selected = 0
        if(supplier_vendor_bills !== null && supplier_vendor_bills.length > 0){
            if (supplier_vendor_bill_selected > 0){
                supplier_vendor_bill_value_selected = supplier_vendor_bill_selected
            }
            supplier_vendor_bills.forEach(item => {
                supplier_vendor_bills_array.push({
                    label: item.vendor_bill_code + ' / ' + item.supplier_name + ' / ' + Helper.replaceMysqlDateTime(item.vendor_bill_date),
                    value: item.id
                })
            })
        }
        return (
            <div className="container">
            <Helmet>
                <title>Nhập hàng</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/purchase-order/new"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <div className="pull-left w-70">
                            <SearchProduct onSelect={ this.onSelectProduct.bind(this) } onClearProduct={ this.onClearProduct.bind(this) } get_size={true} />
                        </div>
                        <div className="pull-left w-30">
                            <SelectPicker
                                block
                                size="lg"
                                placeholder="Chọn từ phiếu mua hàng"
                                cleanable={true}
                                value={supplier_vendor_bill_value_selected}
                                data={supplier_vendor_bills_array}
                                onSelect={this.onSelectSupplierVendorBill}
                            />
                        </div>
                        <div className="cl"></div>
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={100}
                                    data={products}
                                    wordWrap={true} >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={300}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                let expiry_date = null 
                                                if(rowData.expiry_date !== undefined){
                                                    expiry_date = new Date(rowData.expiry_date)
                                                }
                                                return (
                                                    <div>
                                                        {nameText}
                                                        {rowData.has_expiry === 1 ? 
                                                        <div className="mr-top-5">
                                                            <DatePicker size="xs" placeholder="Chọn hạn sử dụng"  format="YYYY-MM-DD" appearance="subtle"  ranges={[]} value={expiry_date} onChange = { (value) => { this.onExpiryDateItemChange(rowData.key, value) } }  />
                                                        </div> : null }
                                                    </div>
                                                )
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Cần nhập</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                if (rowData.from_supplier_vendor_bill === 1){
                                                    return (
                                                        <div className="nk-int-st nk-toggled">
                                                            <InputNumber className="text-right" value={rowData.quantity} disabled/>
                                                        </div>
                                                    );
                                                } else{
                                                    return null
                                                }
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Đã nhập</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                if (rowData.from_supplier_vendor_bill === 1){
                                                    return (
                                                        <div className="nk-int-st nk-toggled">
                                                            <InputNumber className="text-right" value={rowData.quantity_real} disabled/>
                                                        </div>
                                                    );
                                                } else{
                                                    return null
                                                }
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Số lượng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                if(rowData.quantity_real > 0 && rowData.quantity <= rowData.quantity_real){
                                                    return null
                                                } else {
                                                    return (
                                                        <div className="nk-int-st nk-toggled">
                                                            <InputNumber className="text-right" value={rowData.real_quantity} max={100} min={1} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                        </div>
                                                    );
                                                }
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50} fixed="right" align="center">
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <button type="button" onClick={() => { this.onRemoveProduct(rowData.key) }}><i className="fa fa-remove"></i></button>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                            <Uploader file_url={import_products_file} file_name="import_products.xlsx" use_for="product" is_each_size={true} onUploadSuccess={(list) => { this.onUploadSuccess(list) } }/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker format="YYYY-MM-DD HH:mm" appearance="subtle" placeholder={moment().format("YYYY-MM-DD HH:mm")} 
                                    defaultValue={moment().hour(0).minute(0).second(0).toDate()}
                                    placement="leftStart" ranges={[]} block 
                                    onChange = { (value) => { this.onImportReceiptDatePickerChange(value) } } />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox placeholder="Tìm nhà cung cấp" use_for_create={true} has_add_new={true} user_type={UserActionType.SUPPLIER_USER_TYPE} user_selected={ this.props.purchase_order.supplier_selected } onFieldChange={(value) => this.onUserFieldChange(value) }/>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Tổng mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Tổng số lượng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_quantity} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <textarea className="form-control" autoComplete="off" name="import_receipt_notes" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.notes_input = input; }} placeholder="Ghi chú" defaultValue={import_receipt_notes} />
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_button_loading }
                                    data-size={XL}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success btn-block notika-btn-success waves-effect" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;HOÀN TẤT NHẬP HÀNG
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        purchase_order: state.purchase_order
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(PurchaseOrderAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(PurchaseOrderAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PurchaseOrderAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PurchaseOrderAction.fieldChange(field,value))
        },
        onUpdateProducts(products){
            dispatch(PurchaseOrderAction.updateProductsRequest(products))
        },
        onSelectProductToList(item){
            dispatch(PurchaseOrderAction.selectProductToListRequest(item))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(PurchaseOrderAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(PurchaseOrderAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(PurchaseOrderAction.sumTotal())
        },
        onSubmit(data_post){
            dispatch(UserAction.resetList())
            return dispatch(PurchaseOrderAction.addRequest(data_post))
        },
        onSearchSupplierVendorBill: (data_search) => {
            dispatch(PurchaseOrderAction.searchSupplierVendorBillRequest(data_search))
        },
        onGetSupplierVendorBill: (supplier_vendor_bill_id) => {
            return dispatch(PurchaseOrderAction.getSupplierVendorBillRequest(supplier_vendor_bill_id))
        },
    }
}
const CreatePurchaseOrderConnect = connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrder);
export default withRouter(CreatePurchaseOrderConnect);