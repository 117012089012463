import * as PurchaseOrderActionType from '../constants/PurchaseOrderActionType';
import * as TransferStoreActionType from '../constants/TransferStoreActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: PurchaseOrderActionType.FOCUS_FIELD_PURCHASE_ORDER,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: PurchaseOrderActionType.ERROR_FIELD_PURCHASE_ORDER,
        field,
        value
    }
}

export const fieldsChange = fields => {
    return {
        type: PurchaseOrderActionType.FIELDS_CHANGE_PURCHASE_ORDER,
        fields
    }
}

export const fieldChange = (field,value) => {
    return {
        type: PurchaseOrderActionType.FIELD_CHANGE_PURCHASE_ORDER,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: PurchaseOrderActionType.EMPTY_PURCHASE_ORDER
    }
}

export const resetFieldSearch = () => {
    return {
        type: PurchaseOrderActionType.RESET_FIELD_SEARCH_PURCHASE_ORDER
    }
}

const buttonSpinner = status => {
    return {
        type: PurchaseOrderActionType.BUTTON_SPINNER_PURCHASE_ORDER,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: PurchaseOrderActionType.ITEM_BUTTON_SPINNER_PURCHASE_ORDER,
        id,
        status
    }
}

export const getRequest = (id, is_return) => {
    if(is_return === undefined){
        is_return = false
    }
    return (dispatch) => {
        return apiCaller.callApi(`import-receipts/${id}`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                let { id, customer_id } = res.data.result
                dispatch(get(res.data.result))
                if(is_return === false){
                    setTimeout(function(){
                        dispatch(getLiabilitiesRequest(id, customer_id))
                    },250)
                }
                return res.data.result
            }
        });
    }
}

const get = purchase_order => {
    return {
        type: PurchaseOrderActionType.GET_PURCHASE_ORDER,
        purchase_order
    }
}

export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`import-receipts`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, total_page, page,total_amount,total_paid_amount } = res.data.result;
                    dispatch(listPurchaseOrder(total,limit,total_page,page,list,total_amount,total_paid_amount));
                    dispatch(resetFlagViewAll());
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`import-receipts`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit, total_page, page,total_amount,total_paid_amount } = res.data.result;
                    dispatch(listPurchaseOrder(total,limit,total_page,page,list,total_amount,total_paid_amount));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                    return res.data.result
                }
            }
        });
    }
}

const listPurchaseOrder = (total,limit,total_page,page,purchase_orders,total_amount,total_paid_amount) => {
    return {
        type: PurchaseOrderActionType.LIST_PURCHASE_ORDER,
        total,
        limit,
        total_page,
        page,
        purchase_orders,
        total_amount,
        total_paid_amount
    }
}

const resetFlagViewAll = (status) => {
    return {
        type: PurchaseOrderActionType.RESET_FLAG_SEARCH_PURCHASE_ORDER,
        status
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: PurchaseOrderActionType.SEARCH_PURCHASE_ORDER,
        search_button_loading
    }
}

export const listStatusRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`import-receipts/status`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listStatus(res.data.result));
                }
            }
        });
    }
}
const listStatus = (status) => {
    return {
        type: PurchaseOrderActionType.LIST_PURCHASE_ORDER_STATUS,
        status
    }
}

export const selectProductToListRequest = item => {
    return (dispatch) => {
        dispatch(selectProductToList(item))
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const selectProductToList = item => {
    return {
        type: PurchaseOrderActionType.SELECT_PRODUCT_PURCHASE_ORDER,
        item
    }
}

export const updateProductItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        //console.log("key in action request: ", key)
        dispatch(updateProductItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, key, value) => {
    //console.log("key in action: ", key)
    return {
        type: PurchaseOrderActionType.UPDATE_PRODUCT_PURCHASE_ORDER,
        input_name,
        key,
        value
    }
}

export const updateProductExpiryInfoItemRequest = (input_name, key, expiry_info_index, value) => {
    return (dispatch) => {
        //console.log("key in action request: ", key)
        dispatch(updateProductExpiryInfoItem(input_name, key, expiry_info_index, value));
    }
}

const updateProductExpiryInfoItem = (input_name, key, expiry_info_index, value) => {
    //console.log("key in action: ", key)
    return {
        type: PurchaseOrderActionType.UPDATE_PRODUCT_PURCHASE_ORDER,
        input_name,
        key,
        expiry_info_index,
        value
    }
}

export const updateProductsRequest = (products) => {
    return (dispatch) => {
        dispatch(updateProducts(products));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProducts = (products) => {
    return {
        type: PurchaseOrderActionType.UPDATE_PRODUCTS_PURCHASE_ORDER,
        products
    }
}

export const clearProductRequest = () => {
    return {
        type: PurchaseOrderActionType.CLEAR_PRODUCTS_PURCHASE_ORDER
    }
}

export const sumTotal = () => {
    return {
        type: PurchaseOrderActionType.SUM_TOTAL_PURCHASE_ORDER
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`import-receipts/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    //dispatch(directToList(true));
                    dispatch(emptyInfo())
                    return res.data.result
                } else {
                    return {
                        status: res.data.status,
                        message: (res.data.message)?res.data.message:""
                    }
                }
            }
        });
    }
}

export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `import-receipts/${id}`;
        let _method = 'POST';
        dispatch(updateButtonSpinner(button_name,true))
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { id } = res.data.result;
                    dispatch(getRequest(id))
                    if (button_name === "save_note_button_loading"){
                        dispatch(getLastStaffNoteRequest(id))
                        dispatch(getStaffNotesRequest(id))
                    }
                    dispatch(updateButtonSpinner(button_name,false))
                }
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            }
        });
    }
}

export const getPaymentBillsRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`payment-bills`,{
            "import_receipt_id": id,
            "is_payment_info": 1,
            "has_finance_source": 1
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getPaymentBills(res.data.result.list, res.data.result.total_payed))
            }
        });
    }
}

const getPaymentBills = (payment_bills, total_payed) => {
    return {
        type: PurchaseOrderActionType.GET_PAYMENT_BILLS_PURCHASE_ORDER,
        payment_bills,
        total_payed
    }
}

export const getLiabilitiesRequest = (id, customer_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`liabilities`,{
            "import_receipt_id": id,
            "customer_id": customer_id
        },'POSt').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLiabilities(res.data.result))
            }
        });
    }
}

const getLiabilities = liabilities => {
    return {
        type: PurchaseOrderActionType.GET_LIABILITIES_PURCHASE_ORDER,
        liabilities
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "import_receipt_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: PurchaseOrderActionType.GET_STAFF_NOTES_PURCHASE_ORDER,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "import_receipt_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: PurchaseOrderActionType.GET_LAST_STAFF_NOTES_PURCHASE_ORDER,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}

export const getInvoicesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills`,{
            "import_receipt_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getInvoices(res.data.result.list))
            }
        });
    }
}

const getInvoices = lists => {
    return {
        type: PurchaseOrderActionType.GET_INVOICES_PURCHASE_ORDER,
        lists
    }
}

export const getTransferStoresRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores`,{
            "import_receipt_ids": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getTransferStores(res.data.result.list))
            }
        });
    }
}

const getTransferStores = lists => {
    return {
        type: PurchaseOrderActionType.GET_TRANSFER_STORE_USE_PURCHASE_ORDER,
        lists
    }
}

export const getBillReorderRequest = (store_id, product_ids) => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-bills/get-reorder`,{
            "store_id": store_id,
            "products": product_ids
        },'POST').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getBillReorder(res.data.result))
            }
        });
    }
}

const getBillReorder = lists => {
    return {
        type: PurchaseOrderActionType.GET_BILL_REORDER_PURCHASE_ORDER,
        lists
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: PurchaseOrderActionType.BUTTON_UPDATE_SPINNER_PURCHASE_ORDER,
        button_name,
        status
    }
}
export const cancelRequest = (id, reason_cancel) => {
    return (dispatch) => {
        return apiCaller.callApi(`import-receipts/cancel/` + id,{reason_cancel},'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}

export const getTransferStoreBillRequest = to_branch_id => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores`,{
            to_branch_id,
            status: TransferStoreActionType.STATUS_EXPORT_TRANSFER_STORE,
            is_import: 1,
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    dispatch(listTransferStores(list));
                }
            }
        });
    }
}

const listTransferStores = (lists) => {
    return {
        type: PurchaseOrderActionType.LIST_TRANSFER_STORE_PURCHASE_ORDER,
        lists
    }
}

export const selectTransferStoreBillRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`transfer-stores/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(selectTransferStoreBill(res.data.result));
                    return res.data.result
                }
            }
        });
    }
}

const selectTransferStoreBill = info => {
    return {
        type: PurchaseOrderActionType.GET_TRANSFER_STORE_PURCHASE_ORDER,
        info
    }
}

export const getReturnBillRequest = branch_id => {
    return (dispatch) => {
        return apiCaller.callApi(`return-bills`,{
            branch_id,
            is_import: 1,
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    dispatch(listReturnBills(list));
                }
            }
        });
    }
}

const listReturnBills = (lists) => {
    return {
        type: PurchaseOrderActionType.LIST_RETURN_BILL_PURCHASE_ORDER,
        lists
    }
}

export const selectReturnBillRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`return-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(selectReturnBill(res.data.result));
                }
            }
        });
    }
}

const selectReturnBill = info => {
    return {
        type: PurchaseOrderActionType.GET_RETURN_BILL_PURCHASE_ORDER,
        info
    }
}

export const getReceiptOfGoodByProductsRequest = products => {
    return (dispatch) => {
        let product_ids = "", product_option_ids = ""
        products.forEach(item => {
            if(product_ids === ""){
                product_ids = item.product_id
            } else {
                product_ids += "," + item.product_id
            }
            if(item.product_option_id > 0){
                if(product_option_ids === ""){
                    product_option_ids = item.product_option_id
                } else {
                    product_option_ids += "," + item.product_option_id
                }
            }
        })
        return apiCaller.callApi(`receipt-of-goods`,{
            product_ids,
            product_option_ids
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(getReceiptOfGoodByProducts(res.data.result.list));
                    return res.data.result.list
                }
            }
        });
    }   
}
const getReceiptOfGoodByProducts = lists => {
    return {
        type: PurchaseOrderActionType.LIST_RECEIPT_OF_GOODS_PURCHASE_ORDER,
        lists
    }
}

export const selectReceiptOfGoodRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`receipt-of-goods/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(selectReceiptOfGood(res.data.result));
                    return res.data.result
                }
            }
        });
    }
}

const selectReceiptOfGood = info => {
    return {
        type: PurchaseOrderActionType.GET_RECEIPT_OF_GOOD_PURCHASE_ORDER,
        info
    }
}

export const searchSupplierVendorBillRequest = data_search => {
    return (dispatch) => {
        return apiCaller.callApi(`supplier-vendor-bills`,{
            limit: 10,
            page: 1,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listSupplierVendorBills(res.data.result.list));
                }
            }
        });
    }
}

const listSupplierVendorBills = (lists) => {
    return {
        type: PurchaseOrderActionType.LIST_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER,
        lists
    }
}

export const getSupplierVendorBillRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`supplier-vendor-bills/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(getSupplierVendorBill(res.data.result))
                    setTimeout(function(){
                        dispatch(sumTotal());
                    },200);
                }
            }
        });
    }
}

const getSupplierVendorBill = (result) => {
    return {
        type: PurchaseOrderActionType.GET_SUPPLIER_VENDOR_BILL_PURCHASE_ORDER,
        products: result.products,
        id: result.id
    }
}

export const updateProductReturnItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        dispatch(updateProductReturnItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotalForReturn());
        },200);
    }
}
export const sumTotalForReturn = () => {
    return {
        type: PurchaseOrderActionType.SUM_TOTAL_RETURN_PURCHASE_ORDER
    }
}

const updateProductReturnItem = (input_name, key, value) => {
    return {
        type: PurchaseOrderActionType.UPDATE_PRODUCT_RETURN_PURCHASE_ORDER,
        input_name,
        key,
        value
    }
}

export const submitReturn = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`return-bills/new`,{
            ...data_post
        },'PUT').then(res => {
            dispatch(buttonSpinner(false));
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:"",
                    info: (res.data.result)?res.data.result:""  
                }
            } 
            return {
                status: false
            }
        });
    }
}