export const SET_TOKEN = 'SET_TOKEN';
export const SET_AUTHENTICATE = 'SET_AUTHENTICATE';
export const CHECK_AUTHENTICATE = 'CHECK_AUTHENTICATE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const FIELD_CHANGE_LOGIN_PAGE = 'FIELD_CHANGE_LOGIN_PAGE';
export const RESET_FIELD_LOGIN_PAGE = 'RESET_FIELD_LOGIN_PAGE';
export const FOCUS_FIELD_LOGIN_PAGE = 'FOCUS_FIELD_LOGIN_PAGE';
export const ERROR_FIELD_LOGIN_PAGE = 'ERROR_FIELD_LOGIN_PAGE';
export const BUTTON_SPINNER_LOGIN_PAGE = 'BUTTON_SPINNER_LOGIN_PAGE';

export const GET_STAFF_TRANSPORTER = 'GET_STAFF_TRANSPORTER';