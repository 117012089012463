import * as ProductActionType from '../constants/ProductActionType';
import apiCaller from '../utils/apiCaller';
import * as ModalAction from './ModalAction';

export const focusField = (field,value) => {
    return {
        type: ProductActionType.FOCUS_FIELD_PRODUCTS,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: ProductActionType.ERROR_FIELD_PRODUCTS,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: ProductActionType.FIELD_CHANGE_PRODUCTS,
        field,
        value
    }
}

export const resetField = () => {
    return {
        type: ProductActionType.RESET_FIELD_PRODUCTS
    }
}
export const resetFieldSearch = () => {
    return {
        type: ProductActionType.RESET_FIELD_SEARCH_PRODUCTS
    }
}


export const buttonSpinner = status => {
    return {
        type: ProductActionType.BUTTON_SPINNER_PRODUCTS,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: ProductActionType.ITEM_BUTTON_SPINNER_PRODUCTS,
        id,
        status
    }
}

export const getRequest = (id) => {
    return (dispatch) => {
        return apiCaller.callApi(`products/${id}`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(get(res.data.result));
                }
            }
        });
    }
}
const get = products => {
    return {
        type: ProductActionType.GET_PRODUCTS,
        products
    }
}

export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`products/search`,{page, limit},'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listProducts(res.data.result));
                    dispatch(directToList(false));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`products/search`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listProducts(res.data.result));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const listProducts = result => {
    return {
        type: ProductActionType.LIST_PRODUCTS,
        ...result
    }
}

export const listInStockRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        return apiCaller.callApi(`import-receipts/product-in-stock`,{page, limit, ...data_search},'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(listProducts(res.data.result));
                    dispatch(directToList(false));
                }
            }
        });
    }
}

const searchButtonSpinner = (search_button_loading) => {
    return {
        type: ProductActionType.SEARCH_PRODUCTS,
        search_button_loading
    }
}

export const listCategoriesRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`categories-select`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    dispatch(listCategories(list));
                }
            }
        });
    }
}
const listCategories = (categories) => {
    return {
        type: ProductActionType.LIST_CATEGORIES_PRODUCTS,
        categories
    }
}

export const listBrandsRequest = () => {
    return (dispatch) => {
        return apiCaller.callApi(`brands-select`,null,'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    dispatch(listBrands(list));
                }
            }
        });
    }
}
const listBrands = (brands) => {
    return {
        type: ProductActionType.LIST_BRANDS_PRODUCTS,
        brands
    }
}

export const updateRequest = (product, files, page, limit)  => {
    return (dispatch) => {
        let _url = `products/new`;
        let _method = 'PUT';
        let is_add_new = true;
        if(product.id > 0){
            _url = `products/${product.id}`;
            _method = 'POST';
            is_add_new = false;
        }
        return apiCaller.callApi(_url,product,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { id } = res.data.result;
                    if(files !== null && files.length > 0){
                        dispatch(uploadProductPhoto(id, files, page, limit, is_add_new));
                    } else {
                        if (is_add_new === false){
                            dispatch(getRequest(id));
                        } else {
                            dispatch(listRequest(page,limit));
                        }
                    }
                    dispatch(resetField())
                    dispatch(ModalAction.showModal(false, "", "", "", ""))
                    dispatch(ModalAction.buttonSpinner(false))
                }
            }
        });
    }
}

export const uploadProductPhoto = (id, files, page, limit, is_add_new) => {
    return (dispatch) => {
        const formData = new FormData(); 
        // Update the formData object 
        files.forEach(function(file){
            formData.append( 
                "files_upload", 
                file, 
                file.name 
            ); 
        });
        return apiCaller.callApi(`products/photo/${id}`,formData,'POST').then(res => {
            //console.log(res);
            if(res.status === 201 || res.status === 200){
                if (is_add_new === false){
                    dispatch(getRequest(id));
                } else {
                    dispatch(listRequest(page,limit));
                }
            }
        });
    }
}

export const removeProductPhotoRequest = (id, photo_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`products/remove-photo`,{id, photo_id},'POST').then(res => {
            //console.log(res);
            if(res.status === 201 || res.status === 200){
                dispatch(removeProductPhoto(id, photo_id));
                dispatch(getRequest(id, true));
            }
        });
    }
}

const removeProductPhoto = (id, photo_id) => {
    return {
        type: ProductActionType.REMOVE_PHOTO_PRODUCTS,
        id, photo_id
    }
}

export const clearSearchProductRequest = () => {
    return {
        type: ProductActionType.CLEAR_SEARCH_PRODUCTS,
    }
}

export const viewProductPhotoThumbnail = photo => { 
    return {
        type: ProductActionType.VIEW_PHOTO_THUMBNAIL_PRODUCTS,
        photo
    }
}

const directToList = (status) => {
    return {
        type: ProductActionType.DIRECT_TO_LIST_PRODUCTS,
        status
    }
}

export const getHistoriesRequest = (id, data_post) => {
    return (dispatch) => {
        return apiCaller.callApi(`products/histories/${id}`,data_post,'GET').then(res => {
            //console.log(res);
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(getHistories(res.data.result.list));
                }
            }
        });
    }
}
const getHistories = (histories) => {
    return {
        type: ProductActionType.GET_HISTORIES_PRODUCTS,
        histories
    }
}