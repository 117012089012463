import * as ZoneActionType from '../constants/ZoneActionType';
var initialState = {
    cities: [],
    districts: [],
    wards: []
};
const zone = (state = initialState, action) => {
    switch(action.type){
        case ZoneActionType.SAVE_ZONE:
            let districts = [], wards = []
            if (typeof action.districts !== 'undefined'){
                districts = action.districts
            }
            if (typeof action.wards !== 'undefined'){
                wards = action.wards
            }
            return {
                ...state,
                cities: action.cities,
                districts,
                wards
            };
        default: 
            return state;
    }
}
export default zone;