import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom";
import LaddaButton, {ZOOM_IN} from 'react-ladda';
import { Alert, SelectPicker } from 'rsuite';
import ZoneSelectBox from '../shared/ZoneSelectBox';
import * as UserAction from '../../actions/UserAction';
import * as UserActionType from '../../constants/UserActionType';

class ViewStaff extends Component {
    constructor() {
        super();
        this.state = { tab: "info", edit_info: false, edit_info_success: false };
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        if (id > 0) {
            this.props.onGetStaff(id)
        }
        this.props.onGetStaffPosition()
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error= target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
            this.props.onFocusField(field_error,false); 
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onSelectStaffPosition(item){
        let position_name = ""
        if(this.props.user.staff_position !== undefined && this.props.user.staff_position !== null && this.props.user.staff_position.length > 0){
            for(let i = 0; i < this.props.user.staff_position.length; i++){
                if(this.props.user.staff_position[i].value === item){
                    position_name = this.props.user.staff_position[i].label
                    break
                }
            }
        }
        this.props.onFieldChange("position_id",item);
        this.props.onFieldChange("position_name",position_name);
    }
    onChangeTab = (tab_selected) => {
        this.setState({
            tab: tab_selected
        })
    }
    onSetEditInfo = (action) => {
        let _this = this
        _this.setState({
            edit_info: action
        })
    }
    onSaveStaffInfo(){
        let {id, name, phone, position_id, position_name, address, email, password, confirm_password, city_selected, district_selected, ward_selected, avatar } = this.props.user;
        if (name === ""){
            this.props.onErrorField("name_error", true)
            Alert.error("Vui lòng nhập họ tên")
            this.name_input.focus()
            return
        }
        if (phone === ""){
            this.props.onErrorField("phone_error", true)
            Alert.error("Vui lòng nhập điện thoại")
            this.phone_input.focus()
            return
        }
        if (email === ""){
            this.props.onErrorField("email_error", true)
            Alert.error("Vui lòng nhập E-mail")
            this.email_input.focus()
            return
        }
        if(password !== ""){
            if (confirm_password === ""){
                this.props.onErrorField("confirm_password_error", true)
                Alert.error("Vui lòng nhập lại mật khẩu")
                this.confirm_password_input.focus()
                return
            }
            if (confirm_password !== password){
                this.props.onErrorField("confirm_password_error", true)
                Alert.error("Nhập lại mật khẩu không đúng")
                this.confirm_password_input.focus()
                return
            }
        }
        let data_post = {
            name: name,
            phone: phone,
            address: address,
            city: parseInt(city_selected.value),
            district: parseInt(district_selected.value),
            ward: parseInt(ward_selected.value),
            email,
            password,
            avatar,
            position_id,
            position_name,
            user_type: UserActionType.STAFF_USER_TYPE
        }
        //console.log(data_post);
        this.props.onSubmit(id, data_post).then(result => {
            if (result === true){
                let _this = this
                _this.props.onGetStaff(id)
                _this.onSetEditInfo(false)
                _this.setState({
                    edit_info_success: true
                })
                setTimeout(() => {
                    _this.setState({
                        edit_info_success: false
                    })
                },4000)
            }
        })
    }
    render(){
        let {city_name, district_name, ward_name, name, name_active, name_error, phone_active, phone_error, address_active, address_error, email_active, email_error, staff_position, password, password_active, password_error, confirm_password, confirm_password_active, confirm_password_error, save_button_loading } = this.props.user
        let zone_text = "", zone_array  = []
        if(ward_name !== ""){
            zone_array.push(ward_name)
        }
        if(district_name !== ""){
            zone_array.push(district_name)
        }
        if(city_name !== ""){
            zone_array.push(city_name)
        }
        if (zone_array.length > 0){
            zone_text = zone_array.join(", ")
        }
        let tab_action_button = ""
        if(this.state.tab === "info"){
            if(this.state.edit_info === true){
                tab_action_button = (
                    <div>
                        <button type="button" className="btn btn-default" onClick={() => this.onSetEditInfo(false)}><i className="fa fa-ban"></i>&nbsp;Bỏ qua</button>&nbsp;
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-success pull-right notika-btn-success waves-effect" onClick={ () => { this.onSaveStaffInfo() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div>
                )
            } else {
                tab_action_button = <div>
                    <button type="button" className="btn btn-default" onClick={() => this.onSetEditInfo(true)}><i className="fa fa-edit"></i>&nbsp;Sửa thông tin</button>
                </div>
            }
        } 
        let name_field_class = "nk-int-st", phone_field_class = "nk-int-st", address_field_class = "nk-int-st", email_field_class = "nk-int-st", password_field_class = "nk-int-st", confirm_password_field_class = "nk-int-st"
        if (this.props.user.name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }
        if (this.props.user.phone !== "") {
            phone_active = true;
        }
        if(phone_active === true){
            phone_field_class += ' nk-toggled';
        }
        if(phone_error === true){
            phone_field_class += ' error';
        }
        if (this.props.user.address !== "") {
            address_active = true;
        }
        if(address_active === true){
            address_field_class += ' nk-toggled';
        }
        if(address_error === true){
            address_field_class += ' error';
        }
        if (this.props.user.email !== "") {
            email_active = true;
        }
        if(email_active === true){
            email_field_class += ' nk-toggled';
        }
        if(email_error === true){
            email_field_class += ' error';
        }
        if (password !== "") {
            password_active = true;
        }
        if(password_active === true){
            password_field_class += ' nk-toggled';
        }
        if(password_error === true){
            password_field_class += ' error';
        }
        if (confirm_password !== "") {
            confirm_password_active = true;
        }
        if(confirm_password_active === true){
            confirm_password_field_class += ' nk-toggled';
        }
        if(confirm_password_error === true){
            confirm_password_field_class += ' error';
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <NavLink to="/customers"><i className="fa fa-arrow-left fa-2x text-black"></i></NavLink>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false" onClick={() => this.onChangeTab('info')}>Thông tin</a>   
                            </li>
                            <li>
                                <a data-toggle="tab" href="#permission" aria-expanded="false" onClick={() => this.onChangeTab('permission')}>Phân quyền</a>
                            </li>
                            <li className="pull-right">
                                { tab_action_button }
                            </li>
                        </ul>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white padd-10">
                                <div className="row">
                                    { this.state.edit_info_success === true ?
                                    <div className="col-xs-12">
                                        <div className="alert alert-success">
                                            Thông tin nhân viên đã lưu thành công
                                        </div>
                                    </div>
                                    : "" }
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="photo">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã nhân viên:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                <strong>{ this.props.user.code }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Tên nhân viên:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_info === true ? 
                                                                <div className={ name_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.name }</strong>}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Vị trí:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_info === true ? 
                                                                <SelectPicker
                                                                    searchable={false}
                                                                    cleanable={false}
                                                                    placeholder="Chọn vị trí"  
                                                                    data={staff_position}
                                                                    value={this.props.user.position_id}
                                                                    onSelect={this.onSelectStaffPosition.bind(this)}
                                                                />
                                                                :  this.props.user.position_name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Địa chỉ:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_info === true ? 
                                                                <div className={ address_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="address" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.address_input = input; }} value={this.props.user.address}></input>
                                                                </div>
                                                                : this.props.user.address }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Điện thoại:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_info === true ? 
                                                                <div className={ phone_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="phone" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.phone_input = input; }} value={this.props.user.phone}></input>
                                                                </div>
                                                                : <strong>{ this.props.user.phone }</strong> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">E-mail:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_info === true ? 
                                                                <div className={ email_field_class }>
                                                                    <input type="text" className="form-control" autoComplete="off" name="email" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.email_input = input; }} value={this.props.user.email}></input>
                                                                </div>
                                                                : this.props.user.email }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { this.state.edit_info === true ? 
                                                    <div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">Mật khẩu:</label>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                    <div className={ password_field_class }>
                                                                        <input type="password" className="form-control" autoComplete="off" name="password" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.password_input = input; }} value={password}></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label className="hrzn-fm">Nhập lại mật khẩu:</label>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                    <div className={ confirm_password_field_class }>
                                                                        <input type="password" className="form-control" autoComplete="off" name="confirm_password" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.confirm_password_input = input; }} value={this.props.user.confirm_password}></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null }
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Khu vực:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { this.state.edit_info === true ? 
                                                                    <ZoneSelectBox appearance="subtle" city={this.props.user.city_selected} district={this.props.user.district_selected} ward={this.props.user.ward_selected} onCityChange={(city) => { this.onCityChange(city) }} onDistrictChange={(district) => { this.onDistrictChange(district) }} onWardChange={(ward) => { this.onWardChange(ward) }} getRef={ref => (this.zoneSelectBoxRef = ref)} />
                                                                : zone_text }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="permission" className="tab-pane fade bg-white padd-10">
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        invoice: state.invoice
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetStaff: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
        onGetStaffPosition: () => {
            return dispatch(UserAction.listStaffPositionRequest())
        },
        onErrorField(field,value){
            dispatch(UserAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(UserAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(UserAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(UserAction.resetField())
        },
        onSubmit(id, data_post){
            return dispatch(UserAction.updateRequest(id, data_post))
        },
    }
}
const ViewStaffConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewStaff));
export default withRouter(ViewStaffConnect);