export const LIST_FINANCE_SOURCE = 'LIST_FINANCE_SOURCE';
export const ADD_FINANCE_SOURCE = 'ADD_FINANCE_SOURCE';
export const UPDATE_FINANCE_SOURCE = 'UPDATE_FINANCE_SOURCE';
export const DELETE_FINANCE_SOURCE = 'DELETE_FINANCE_SOURCE';
export const GET_FINANCE_SOURCE = 'GET_FINANCE_SOURCE';
export const FOCUS_FIELD_FINANCE_SOURCE = 'FOCUS_FIELD_FINANCE_SOURCE';
export const ERROR_FIELD_FINANCE_SOURCE = 'ERROR_FIELD_FINANCE_SOURCE';
export const FIELD_CHANGE_FINANCE_SOURCE = 'FIELD_CHANGE_FINANCE_SOURCE';
export const RESET_FIELD_FINANCE_SOURCE = 'RESET_FIELD_FINANCE_SOURCE';
export const BUTTON_SPINNER_FINANCE_SOURCE = 'BUTTON_SPINNER_FINANCE_SOURCE';
export const ITEM_BUTTON_SPINNER_FINANCE_SOURCE = 'ITEM_BUTTON_SPINNER_FINANCE_SOURCE';
export const RESET_FIELD_SEARCH_FINANCE_SOURCE = 'RESET_FIELD_SEARCH_FINANCE_SOURCE';
export const SEARCH_FINANCE_SOURCE = 'SEARCH_FINANCE_SOURCE';