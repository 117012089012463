import * as StoreActionType from '../constants/StoreActionType';
var initialState = {
    stores: [],
    store_id: 0,
    store_code: "",
    store_name: ""
};
const store = (state = initialState, action) => {
    switch(action.type){
        case StoreActionType.GET_MY_STORE:
            return {
                ...state,
                stores: action.stores,
                store_id: action.store_id,
                store_code: action.store_code,
                store_name: action.store_name
            };
        default: 
            return state;
    }
}
export default store;