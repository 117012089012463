import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom";
import NumberFormat from 'react-number-format';
import Modal from "../shared/Modal";
import ImageUploader from 'react-images-upload';
import * as ProductsAction from '../../actions/ProductAction';
import * as ModalAction from '../../actions/ModalAction';
import HistoriesForProduct from '../product/HistoriesForProduct';
import InvoiceForProduct from '../invoice/ListForProduct';
import InventoryForProduct from '../inventory/InventoryForProduct';

class ViewProduct extends Component {
    constructor() {
        super();
        this.state = { pictures: [] };
    }
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        if (id > 0) {
            this.props.onGetProduct(id);
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    handleSubmitFormUpdateProduct(){
        var name = this.props.product.name;
        var model = this.props.product.model;
        var sku = this.props.product.sku;
        var retail_according = this.props.product.retail_according;
        if (this.props.product.is_gift === 1){
            if (model === ""){
                this.model_input.focus();
                this.props.onErrorField("model_error",true); 
                return false;
            }
            if (name === ""){
                this.name_input.focus();
                this.props.onErrorField("name_error",true); 
                return false;
            }
        }
        var product = {
            "id": this.props.product.id,
            "name": name,
            "model": model,
            "sku": sku,
            "retail_according": retail_according
        }
        this.props.onUpdateProduct(product, this.state.pictures, this.props.product.page, this.props.product.limit);
        this.props.onModalButtonSpinner(true);
        this.setState({
            pictures: []
        });
    }
    handleModalClose(){
        this.props.onShowModal(false);
    }
    handleModalShowAddGift(){
        this.props.onResetField();
        this.props.onShowModal(true, "Thêm mới quà tặng", "modal-lg", "add_gift", "submit_add_gift");
    }
    handleModalSubmit(submit_type){
        if (submit_type === "submit_add_gift"){
            this.handleSubmitFormAddGift()
        } else if (submit_type === "submit_update_product") {
            this.handleSubmitFormUpdateProduct()
        }
    }
    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }
    parseListPhotos(photos, is_view){
        var result = null;
        if(photos.length > 0){
            result = photos.map((item, index) => {
                if(is_view === true){
                    return (
                       <div key={index} className="mr-bot-5 photo__thumb-list">
                           <a href=" #" onClick={ (e) => { this.viewPhotoThumb(e, item) } }><img src={item.url} height="80" alt="" /></a>
                       </div>
                    );  
                } else {
                    return (
                       <div key={index} className="pull-left mr-left-10 product-photos">
                           <div className="uploadPictureContainer"><div className="deleteImage" onClick={ () => { this.onRemovePhotoItem(item.id,item.photo_id) } }>X</div><img src={item.url} height="80" className="uploadPicture" alt="preview" /></div>
                       </div>
                    );  
                } 
            });
        }
        return result;
    }
    parsePhoto(photo, photos){
        var result = [];
        if (typeof photo != 'undefined'){
            result.push(
                <div key={"photo__main"} className="photo__main">
                    <img src={photo.url} width="390" alt="" />
                </div>
            )
            if (photos.length > 1){
                result.push(
                    <div key={"photo__thumb"} className="photo__thumb mr-left-5">
                        { this.parseListPhotos(photos, true) }
                    </div>
                )
            }
            result.push(
                <div key={"photo__clear"} className="cl"></div>
            )
        }
        return result;
    }
    viewPhotoThumb(e, photo){
        e.preventDefault();
        this.props.onViewProductPhotoThumbnail(photo);
    }
    render(){
        //console.log(this.props.product)
        let {model_active, model_error, name_active, name_error, sku_active, quantity_active, quantity_error, id, model, sku, name, quantity, is_gift, retail_according, photos, current_photo, categories, category_id, brand_name, price, retail, options } = this.props.product;
        let { show_modal, modal_type, modal_title, modal_input, modal_submit_type, modal_button_spinner} = this.props.modal;
        let model_field_class = "nk-int-st", name_field_class = 'nk-int-st', sku_field_class = 'nk-int-st', quantity_field_class = 'nk-int-st';
        if (name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }
        if (model !== "") {
            model_active = true;
        }
        if(model_active === true){
            model_field_class += ' nk-toggled';
        }
        if(model_error === true){
            model_field_class += ' error';
        }
        if (sku !== "") {
            sku_active = true;
        }
        if(sku_active === true){
            sku_field_class += ' nk-toggled';
        }
        if (quantity !== "") {
            quantity_active = true;
        }
        if(quantity_active === true){
            quantity_field_class += ' nk-toggled';
        }
        if(quantity_error === true){
            quantity_field_class += ' error';
        }
        let modal_children = "";
        if (is_gift === 1){
            let html_quantity = "", html_photos = ""
            if (modal_input === "add_gift"){
                html_quantity = (
                    <div className="form-group ic-cmp-int float-lb floating-lb">
                        <div className={ quantity_field_class }>
                            <input type="number" className="form-control" autoComplete="off" name="quantity" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.quantity_input = input; }} value={quantity}></input>
                            <label className="nk-label">Tồn kho</label>
                        </div>
                    </div>
                )
            } else if (modal_input === "update_product"){
                html_photos = this.parseListPhotos(photos)
            }
            modal_children = (
                <div className="row mr-top-10">
                    <div className="col-xs-6">
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className={ model_field_class }>
                                <input type="text" className="form-control" autoComplete="off" name="model" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.model_input = input; }} value={model}></input>
                                <label className="nk-label">Mã hàng hóa</label>
                            </div>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className={ name_field_class }>
                                <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name}></input>
                                <label className="nk-label">Tên hàng hóa</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className={ sku_field_class }>
                                <input type="text" className="form-control" autoComplete="off" name="sku" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={sku}></input>
                                <label className="nk-label">Mã vạch</label>
                            </div>
                        </div>
                        {html_quantity}
                    </div>
                    <div className="col-xs-12">
                        <div className="form-group">{html_photos}<div className="cl"></div></div>
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            buttonText='Chọn hình ảnh'
                            label='Hình ảnh không quá: 5mb, cho phép định dạng: jpg|png'
                            onChange={this.onDrop.bind(this)}
                            imgExtension={['.jpg', '.png']}
                            maxFileSize={5242880}
                        />
                    </div>
                </div>
            );
        } else {
            let html_photos = this.parseListPhotos(photos)
            modal_children = (
                <div className="row mr-top-10">
                    <div className="col-xs-6">
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className={ model_field_class }>
                                <input type="text" className="form-control" autoComplete="off" name="model" disabled value={model}></input>
                                <label className="nk-label">Mã hàng hóa</label>
                            </div>
                        </div>
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className={ name_field_class }>
                                <input type="text" className="form-control" autoComplete="off" name="name" disabled value={name}></input>
                                <label className="nk-label">Tên hàng hóa</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group ic-cmp-int float-lb floating-lb">
                            <div className={ sku_field_class }>
                                <input type="text" className="form-control" autoComplete="off" name="sku" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={sku}></input>
                                <label className="nk-label">Mã vạch</label>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop: 28}}>
                            <div className="toggle-select-act fm-cmp-mg">
                                <div className="nk-toggle-switch">
                                    <label htmlFor="retail_according" className="ts-label">Giá nhập theo đợt</label>
                                    <input id="retail_according" name="retail_according" type="checkbox" hidden="hidden" value="1" checked={retail_according === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                    <label htmlFor="retail_according" className="ts-helper"></label>
                                </div>
                                <div><small>Mặc định là lấy giá nhập trung bình</small></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        {html_photos}<div className="cl"></div>
                    </div>
                </div>
            );
        }
        let categories_text = []
        if (categories.length > 0) {
            categories.forEach((category_item,index) => {
                let classMainCategory = "label label-default"
                if (category_item.value === category_id){
                    classMainCategory = "label label-info"
                }
                categories_text.push(<span key={index}><label className={classMainCategory}>{ category_item.label }</label>&nbsp;</span>);
            })
        }
        let sizes_text = [], options_array = []
        if (options.length > 0) {
            options.forEach((option_item,index) => {
                sizes_text.push(<span key={index}><label className="label label-info">{ option_item.label }</label>&nbsp;</span>);
                options_array.push({
                    id: option_item.value,
                    name: option_item.label
                })
            })
        }
        let list_histories = null, list_remain_inventory = null, list_invoices = null
        if (id > 0){
            list_histories = <HistoriesForProduct product_id={id} limit={10} />   
            list_remain_inventory = <InventoryForProduct product_id={id} options={options_array} />
            list_invoices = <InvoiceForProduct product_id={id} options={options_array} limit={10} />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <NavLink to="/products"><i className="fa fa-arrow-left fa-2x text-black"></i></NavLink>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#history" aria-expanded="false">Thẻ kho</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#inventory" aria-expanded="false">Tồn kho</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#invoice" aria-expanded="false">Hóa đơn bán hàng</a>
                            </li>
                        </ul>
                        <div className="tab-content tab-custom-st">
                            <div id="info" className="tab-pane fade active in bg-white padd-10">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <h2>{ name }</h2>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="photo">
                                                { this.parsePhoto(current_photo, photos) }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                        <div className="form-element-list" >
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã hàng hóa:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { model }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Danh mục:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { categories_text }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Giá bán:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Size:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            { sizes_text }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Mã vạch:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { sku }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Thương hiệu:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                { brand_name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label className="hrzn-fm">Giá nhập cuối:</label>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <NumberFormat value={retail} displayType={'text'} thousandSeparator={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    {/*<div className="col-xs-12 mr-bot-10"> 
                                        <button type="button" className="btn btn-danger pull-right mr-left-10" onClick={ () => { this.handleModalShowAddGift() } }><i className="fa fa-ban"></i>&nbsp;Ngừng kinh doanh</button>
                                        <div className="cl"></div>
        </div>*/}
                                </div>
                            </div>
                            <div id="history" className="tab-pane fade bg-white padd-10">
                                { list_histories }
                            </div>
                            <div id="inventory" className="tab-pane fade bg-white padd-10">
                                { list_remain_inventory }
                            </div>
                            <div id="invoice" className="tab-pane fade bg-white padd-10">
                                { list_invoices }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={show_modal} type={modal_type} spinner={modal_button_spinner} title={modal_title} handleClose={this.handleModalClose.bind(this)} handleSubmit={this.handleModalSubmit.bind(this,modal_submit_type)} children={modal_children}></Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        product: state.product,
        modal: state.modal
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowModal: (status, title, type, input, submit_type) => {
            dispatch(ModalAction.showModal(status, title, type, input, submit_type))
        },
        onModalButtonSpinner(status){
            dispatch(ModalAction.buttonSpinner(status))
        },
        onErrorField(field,value){
            dispatch(ProductsAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(ProductsAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ProductsAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(ProductsAction.resetField())
        },
        onListProducts: (page,limit) => {
            dispatch(ProductsAction.listRequest(page,limit))
        },
        onSearchProducts: (data_search,page,limit,spinner) => {
            dispatch(ProductsAction.searchRequest(data_search,page,limit,spinner))
        },
        onGetProduct: (id) => {
            dispatch(ProductsAction.getRequest(id))
        },
        onUpdateProduct: (product, files, page, limit) => {
            dispatch(ProductsAction.updateRequest(product, files, page, limit))
        },
        onItemButtonSpinner(id,status){
            dispatch(ProductsAction.itemButtonSpinner(id,status))
        },
        onRemoveProductPhoto: (id,photo_id) => {
            dispatch(ProductsAction.removeProductPhotoRequest(id,photo_id))
        },
        onViewProductPhotoThumbnail: (photo) => {
            dispatch(ProductsAction.viewProductPhotoThumbnail(photo))
        },
    }
}
const ViewProductConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewProduct));
export default withRouter(ViewProductConnect);