import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { DatePicker, Alert } from 'rsuite';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Helper from '../../utils/Helper';
import * as InventoryCheckAction from '../../actions/InventoryCheckAction';
import * as InventoryCheckActionType from '../../constants/InventoryCheckActionType';
import Moment from 'react-moment';
class ViewCheckInventories extends Component {
    componentDidMount(){
        if (Helper.checkMyRole('update_inventory_check_retail') === false){
            this.props.history.push("/");
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let field_error = target.name + '_error';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onErrorField(field_error,false)
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("inventory_check_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onCancel(){
        Swal.fire({
            title: 'Vui lòng nhập lý do hủy',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Tiếp tục',
            showLoaderOnConfirm: true,
            preConfirm: (reason_cancel) => {
                if(reason_cancel !== ""){
                    this.props.onCancel(this.props.inventory_check.id, reason_cancel).then(result => {
                        if(result.status === true){
                            this.props.onEmptyInfo()
                            this.props.onGetData(this.props.inventory_check.id)
                        }
                        else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    })
                } else {
                    Swal.showValidationMessage('Vui lòng nhập lý do')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    onSaveData(){
        if(this.props.accounting === true){
            if(this.props.inventory_check.products.length === 0){
                Alert.error('Không có hàng hóa trong phiếu kiểm kho')
                return;
            }
            /*let valid = false
            for(let i = 0; i < this.props.inventory_check.products.length; i++){
                let item = this.props.inventory_check.products[i]
                if(item.price_suggest > 0){
                    valid = true
                    break
                }  
            }
            if(valid === false){
                Alert.error('Vui lòng nhập giá cho hàng hóa')
                return;
            }*/
            let product_retails = []
            this.props.inventory_check.products.forEach(item => {
                let price_save = parseFloat(item.price)
                let price_suggest = parseFloat(item.price_suggest)
                if(price_save !== price_suggest){
                    product_retails.push({
                        id: item.id,
                        product_option_id: item.product_option_id,
                        retail: parseFloat(item.price_suggest),
                        real_quantity: parseInt(item.quantity)
                    })
                }
            })
            if(product_retails.length > 0){
                this.props.onUpdateData({
                    product_retails
                }, this.props.inventory_check.id, "save_button_loading").then(result => {
                    if(result.status === true){
                        Alert.success("Thông tin phiếu kiểm kho đã lưu thành công")
                    }
                    else if(result.status === false && result.message !== undefined && result.message !== ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Thông báo lỗi',
                            text: result.message
                        })
                    }
                })
            }
        } else {
            if(this.props.inventory_check.inventory_check_date === ""){
                Alert.error('Vui lòng chọn thời gian kiểm kho')
                return;
            }
            let inventory_check_notes = this.props.inventory_check.inventory_check_notes
            this.props.onUpdateData({
                inventory_check_date: this.props.inventory_check.inventory_check_date,
                inventory_check_notes
            }, this.props.inventory_check.id, "save_button_loading").then(result => {
                if(result.status === true){
                    Alert.success("Thông tin phiếu kiểm kho đã lưu thành công")
                    this.notes_input.value = ""
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        }
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price_suggest", key, values.value);
    }
    showListProduct(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 var price_input = item.price
                 let quantity_diff = item.quantity - item.remain
                 let retail_text = <NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} />
                 if (this.props.accounting === true && item.quantity > 0){
                     price_input = item.price_suggest
                    
                    retail_text = <div className="nk-int-st nk-toggled">
                                <NumberFormat className="form-control text-right" thousandSeparator={true} prefix={""} value={price_input} onValueChange={(values) => this.onPriceItemFieldChange(item.key,values)} />
                            </div>
                 }
                 item.price = Helper.parseMoney(item.price)
                 price_input = Helper.parseMoney(price_input)
                 let total_amount_diff = item.price * quantity_diff
                 let total_amount = item.price * item.quantity
                 if (this.props.accounting === true){
                    total_amount_diff = price_input * quantity_diff
                    total_amount = price_input * item.quantity
                 }
                 if(isNaN(total_amount)){
                    total_amount = 0
                 }
                 if(isNaN(total_amount_diff)){
                    total_amount_diff = 0
                 }
                 if (total_amount_diff > 0) {
                    total_amount_diff = Math.round(total_amount_diff, -3)
                 }
                 if (total_amount > 0) {
                    total_amount = Math.round(total_amount, -3)
                 }
                 return (
                     <tr key={ index } >
                         <td className="text-center"><img src={item.url} alt="" /></td>
                         <td>{ item.model }</td>
                        <td>{gift_text}{ item.name } { item.product_option_name !== "" ? <span><br />{item.product_option_name}</span> : '' }</td>
                         <td className="text-right"><NumberFormat value={item.remain} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={quantity_diff} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right">
                            {retail_text}
                         </td>
                         {
                             (this.props.accounting === true) ? 
                             <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                             : null 
                         }
                         <td className="text-right"><NumberFormat value={total_amount_diff} displayType={'text'} thousandSeparator={true} />
</td>
                         <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} />
</td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    onRemoveStaffNoteItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa ghi chú này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onRemoveStaffNote(this.props.inventory_check.id, id)
            },
          })
    }
    showListStaffNotes(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr key={ index }>
                         <td>{ item.content }</td>
                         <td>{moment(item.time * 1000).format("YYYY-MM-DD HH:mm").toString()}</td>
                         <td>{ item.staff_name }</td>
                         <td className="text-center">
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link notika-btn-success waves-effect" onClick={ () => { this.onRemoveStaffNoteItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton>
                         </td>
                     </tr>  
                 );   
            });
        }
        return result;
    }
    render(){
        let {products, total_product, total_quantity, total_quantity_diff, total_amount, total_amount_diff, inventory_check_date, staff_notes, last_notes, last_notes_staff_name, last_notes_time, save_button_loading } = this.props.inventory_check;
        inventory_check_date = Helper.replaceMysqlDateTime(inventory_check_date)

        let allow_update_date = false, allow_update = false
        let allow_update_staff_notes = true
        let allow_update_cancel_status = false
        if (Helper.checkMyRole('update_inventory_check_cancel') === true){
            allow_update_cancel_status = true
        }
        if (Helper.checkMyRole('update_inventory_check_date') === true){
            allow_update_date = true
            allow_update = true
        }
        let html_alert_bill_status = ""
        if(this.props.inventory_check.inventory_check_status === InventoryCheckActionType.STATUS_CANCEL_INVENTORY_CHECK){
            allow_update_cancel_status = false
            allow_update_date = false
            allow_update = false
            allow_update_staff_notes = false
            html_alert_bill_status = <div className="col-xs-12">
                <div className="alert alert-danger text-center">
                    <h3>
                        { this.props.inventory_check.staff_cancel_name } đã hủy lúc <Moment unix format="HH:mm DD/MM/YYYY">{ this.props.inventory_check.cancel_time }</Moment><br />
                        Lý do: { this.props.inventory_check.reason_cancel }
                    </h3>
                </div>
            </div>  
        }
        let colSpan=8
        if (this.props.accounting === true){
            allow_update_cancel_status = false
            allow_update_date = false
            allow_update = false
            allow_update_staff_notes = false
            colSpan=9
        }
        if (total_amount_diff > 0) {
           total_amount_diff = Math.round(total_amount_diff, -3)
        }
        if (total_amount > 0) {
           total_amount = Math.round(total_amount, -3)
        }
        return (
            <div className="container">
            <Helmet>
                <title>Phiếu kiểm kho { this.props.inventory_check.inventory_check_code }</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/inventory-check/" + this.props.inventory_check.id} />
            </Helmet>  
                <div className="row">
                    { html_alert_bill_status }
                    <div className="col-xs-12">
                    <div className="pull-left">
                        <ul className="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                            <li>
                                <button className="btn btn-link mr-top-7" type="button" onClick={() => { this.props.history.goBack() }}><i className="fa fa-arrow-left fa-2x text-black"></i></button>
                            </li>
                            <li className="active">
                                <a data-toggle="tab" href="#info" aria-expanded="false">Thông tin</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#note" aria-expanded="false">Nhân viên ghi chú</a>
                            </li>
                        </ul>
                    </div>
                    { this.props.accounting === true ?
                    <div className="pull-right">
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-success mr-right-5" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div> : 
                    <div className="pull-right">
                        { allow_update === true ?
                        <LaddaButton
                            loading={ save_button_loading }
                            data-style={ZOOM_IN}
                            className="btn btn-success mr-right-5" onClick={ () => { this.onSaveData() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton> : "" }
                        { allow_update_cancel_status === true ?
                        <button type="button"
                            className="btn btn-danger" onClick={ () => { this.onCancel() } } >
                            <i className="fa fa-remove"></i>&nbsp;Hủy phiếu
                        </button> : "" }
                    </div> }
                    <div className="cl"></div>
                    <div className="tab-content tab-custom-st">
                        <div id="info" className="tab-pane fade active in bg-white">
                            <div className="form-element-list" >
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Mã phiếu:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <strong>{ this.props.inventory_check.inventory_check_code }</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className={allow_update_date === true ? "col-lg-3 col-md-3 col-sm-3 col-xs-12 mr-top-7" : "col-lg-3 col-md-3 col-sm-3 col-xs-12"}>
                                                    <label className="hrzn-fm">Thời gian:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <div className="pull-left">
                                                    { allow_update_date === true ? 
                                                    <DatePicker format="YYYY-MM-DD HH:mm" value={new Date(moment(inventory_check_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())} placement="bottomStart" size="md" ranges={[]} onChange = { (value) => { this.onDatePickerChange(value) } } /> : <span>{ moment(inventory_check_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm").toString() }</span>  }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Người kiểm:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.inventory_check.staff_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        { this.props.inventory_check.import_receipt_id > 0 ?
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Phiếu nhập</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <Link to={"/purchase-order/" + this.props.inventory_check.import_receipt_id.toString()} target="_blank">{this.props.inventory_check.import_receipt_code}</Link>
                                                </div>
                                            </div>
                                        </div> : "" }
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Trạng thái:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    <span style={{ fontSize: 15, color: this.props.inventory_check.inventory_check_status_color }}>{this.props.inventory_check.inventory_check_status_text}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label className="hrzn-fm">Chi nhánh:</label>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                                    {this.props.inventory_check.branch_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group ic-cmp-int float-lb floating-lb">
                                            { last_notes ? 
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label className="hrzn-fm">Ghi chú mới nhất</label>
                                                </div>
                                                <div className="col-xs-12">
                                                {last_notes_staff_name} ({moment(last_notes_time * 1000).format("YYYY-MM-DD HH:mm").toString()}): {last_notes}
                                                </div>
                                                <div className="col-xs-12">
                                                    <hr />
                                                </div>
                                            </div>  : ""   }
                                            { allow_update_staff_notes === true ? 
                                            <div className="row mr-top-10">    
                                                <TextareaAutoHeight placeholder="Thêm ghi chú" name={"inventory_check_notes"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                            </div> : null }
                                        </div>
                                    </div>
                                </div>    
                            </div>    
                            <div className="normal-table-list mr-top-20">
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-condensed">
                                        <colgroup>
                                            <col width="5%"></col>
                                            <col width="15%"></col>
                                            <col></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            <col width="7%"></col>
                                            { (this.props.accounting === true) ? <col width="10%"></col> : null }
                                            <col width="10%"></col>
                                            <col width="10%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Mã hàng</th>
                                                <th>Tên hàng</th>
                                                <th className="text-right">Tồn kho</th>
                                                <th className="text-right">Thực tế</th>
                                                <th className="text-right">SL lệch</th>
                                                <th className="text-right">Giá nhập</th>
                                                { (this.props.accounting === true) ? <th className="text-right">Giá chính thức</th> : null }
                                                <th className="text-right">GT lệch</th>
                                                <th className="text-right">GT thực</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.showListProduct(products) }
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td className="text-right" colSpan={colSpan}>Tổng số mặt hàng</td>
                                                <td className="text-right">{ total_product }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan={colSpan}>Tổng thực tế ({ total_quantity })</td>
                                                <td className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan={colSpan}>Tổng lệch ({ total_quantity_diff })</td>
                                                <td className="text-right"><NumberFormat value={total_amount_diff} displayType={'text'} thousandSeparator={true} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>    
                        </div>
                        <div id="note" className="tab-pane fade bg-white padd-10">
                            { staff_notes.length > 0 ?
                            <table className="table table-sc-ex table-hover table-condensed w-auto">
                                <thead>
                                    <tr>
                                        <th>Nội dung</th>
                                        <th width="10%">Thời gian</th>
                                        <th width="10%">Người tạo</th>
                                        <th width="5%">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.showListStaffNotes(staff_notes) }
                                </tbody>
                            </table> : <div className="alert alert-warning">Không có thông tin nào</div> }
                        </div>  
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

ViewCheckInventories.defaultProps = {
    accounting: false
}

const mapStateToProps = state => {
    return {
        inventory_check: state.inventory_check
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(InventoryCheckAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InventoryCheckAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(InventoryCheckAction.fieldChange(field,value))
        },
        onEmptyInfo(){
            dispatch(InventoryCheckAction.emptyInfo())
        },
        onGetData: id => {
            return dispatch(InventoryCheckAction.getRequest(id))
        },
        onUpdateData: (data_post, id, button_name) => {
            return dispatch(InventoryCheckAction.updateRequest(data_post, id, button_name))
        },
        onRemoveStaffNote: (id, staff_note_id) => {
            dispatch(InventoryCheckAction.removeStaffNoteRequest(id, staff_note_id))
        },
        onCancel: (id, reason_cancel) => {
            return dispatch(InventoryCheckAction.cancelRequest(id, reason_cancel))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(InventoryCheckAction.updateProductItemRequest(input_name, key, value))
        },
    }
}
const ViewCheckInventoriesConnect = (connect(mapStateToProps, mapDispatchToProps)(ViewCheckInventories));
export default withRouter(ViewCheckInventoriesConnect);