import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import * as PurchaseOrderActionType from '../../constants/PurchaseOrderActionType';

class ListForSupplier extends Component {
    constructor() {
        super();
        this.state = {
            list: [],
            total: 0,
            limit: 0,
            supplier_id: 0
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.supplier_id!==prevState.supplier_id){
            return { supplier_id: nextProps.supplier_id};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.supplier_id!==this.props.supplier_id){
            this.setState({supplier_id: this.props.supplier_id});
            this.props.onSearchPurchaseOrders({
                supplier_id: this.props.supplier_id,
            },1,this.props.limit, true).then(result => {
                this.setState({
                    list: result.list,
                    total: result.total,
                    limit: result.limit,
                })
            })
        } 
    }
    componentDidMount(){
        this.props.onSearchPurchaseOrders({
            supplier_id: this.props.supplier_id,
        },1,this.props.limit, true).then(result => {
            this.setState({
                list: result.list,
                total: result.total,
                limit: result.limit,
            })
        })
    }
    onItemClick(id){
        window.open("/purchase-order/" + id, "_blank");
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let status_text = null
                if(item.import_receipt_status === PurchaseOrderActionType.STATUS_CANCEL_PURCHASE_ORDER){
                    status_text = <label className='label' style={{backgroundColor: item.import_receipt_status_color }}>{ item.import_receipt_status_text }</label>
                }
                let paid_amount = item.paid_liabilities
                let remain_amount = item.total_after_discount - paid_amount
                 return (
                    <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                        <td>{item.import_receipt_code}</td>
                        <td>
                           <Moment format="DD/MM/YYYY">
                           { item.import_receipt_date }
                           </Moment>
                        </td>
                        <td className="text-right"><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={item.total_after_discount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={paid_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-right"><NumberFormat value={remain_amount} displayType={'text'} thousandSeparator={true} /></td>
                        <td className="text-center">{status_text}</td>
                    </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, list } = this.state;
        let btn_view_more = ""
        if(total > limit){
            btn_view_more = (
                <div className="mr-top-10 text-center">
                    <Link to={"/purchase-orders?supplier_id=" + this.props.supplier_id} className="btn btn-default btn-sm">Xem thêm</Link>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        { list.length > 0 ? 
                            <div>
                                <div className="normal-table-list">
                                    <div className="bsc-tbl">
                                        <table className="table table-sc-ex">
                                        <colgroup>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Mã phiếu nhập</th>
                                                    <th>Thời gian</th>
                                                    <th className="text-right">Tổng tiền hàng</th>
                                                    <th className="text-right">Giảm giá</th>
                                                    <th className="text-right">Tổng sau giảm</th>
                                                    <th className="text-right">Đã thanh toán</th>
                                                    <th className="text-right">Còn nợ</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.showList(list) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                { btn_view_more }
                            </div>
                         :  <div className="alert alert-warning">Không có thông tin nào</div>
                         }
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSearchPurchaseOrders: (data_search,page,limit,spinner) => {
            return dispatch(PurchaseOrderAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(null, mapDispatchToProps)
 )
 export default enhance(ListForSupplier)