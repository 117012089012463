import * as ActivitiesActionType from '../constants/ActivitiesActionType';
import apiCaller from '../utils/apiCaller';

export const searchRequest = (data_search,page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`activities`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(lists(res.data.result));
                }
            }
        });
    }
}

const lists = (result) => {
    return {
        type: ActivitiesActionType.LIST_ACTIVITIES,
        ...result
    }
}