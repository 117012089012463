export const UPDATE_TRANSFER_STORE = 'UPDATE_TRANSFER_STORE';
export const GET_TRANSFER_STORE = 'GET_TRANSFER_STORE';
export const LIST_TRANSFER_STORE = 'LIST_TRANSFER_STORE';
export const LIST_TRANSFER_STORE_STATUS = 'LIST_TRANSFER_STORE_STATUS';
export const SEARCH_TRANSFER_STORE = 'SEARCH_TRANSFER_STORE';
export const SHOW_HIDE_FIELD_ADD_NEW_TRANSFER_STORE = 'SHOW_HIDE_FIELD_ADD_NEW_TRANSFER_STORE';
export const FIELD_CHANGE_TRANSFER_STORE = 'FIELD_CHANGE_TRANSFER_STORE';
export const RESET_FIELD_TRANSFER_STORE = 'RESET_FIELD_TRANSFER_STORE';
export const RESET_FIELD_SEARCH_TRANSFER_STORE = 'RESET_FIELD_SEARCH_TRANSFER_STORE';
export const FOCUS_FIELD_TRANSFER_STORE = 'FOCUS_FIELD_TRANSFER_STORE';
export const ERROR_FIELD_TRANSFER_STORE = 'ERROR_FIELD_TRANSFER_STORE';
export const BUTTON_SPINNER_TRANSFER_STORE = 'BUTTON_SPINNER_TRANSFER_STORE';
export const BUTTON_UPDATE_SPINNER_TRANSFER_STORE = 'BUTTON_UPDATE_SPINNER_TRANSFER_STORE';
export const ITEM_BUTTON_SPINNER_TRANSFER_STORE = 'ITEM_BUTTON_SPINNER_TRANSFER_STORE';
export const UPDATE_PRODUCTS_TRANSFER_STORE = 'UPDATE_PRODUCTS_TRANSFER_STORE';
export const UPDATE_PRODUCT_TRANSFER_STORE = 'UPDATE_PRODUCT_TRANSFER_STORE';
export const SUM_TOTAL_TRANSFER_STORE = 'SUM_TOTAL_TRANSFER_STORE';
export const CLEAR_PRODUCTS_TRANSFER_STORE = 'CLEAR_PRODUCTS_TRANSFER_STORE';
export const SELECT_PRODUCT_TRANSFER_STORE = 'SELECT_PRODUCT_TRANSFER_STORE';
export const DIRECT_TO_LIST_TRANSFER_STORE = 'DIRECT_TO_LIST_TRANSFER_STORE';
export const RESET_FLAG_SEARCH_TRANSFER_STORE = 'RESET_FLAG_SEARCH_TRANSFER_STORE';
export const EMPTY_TRANSFER_STORE = 'EMPTY_TRANSFER_STORE';

export const GET_STAFF_NOTES_TRANSFER_STORE = 'GET_STAFF_NOTES_TRANSFER_STORE';
export const GET_LAST_STAFF_NOTES_TRANSFER_STORE = 'GET_LAST_STAFF_NOTES_TRANSFER_STORE';
export const GET_RECEIPT_OF_GOOD_TRANSFER_STORE = 'GET_RECEIPT_OF_GOOD_TRANSFER_STORE';

export const STATUS_IS_TEMP_TRANSFER_STORE = 1;
export const STATUS_EXPORT_TRANSFER_STORE = 2;
export const STATUS_FINISH_TRANSFER_STORE = 3;
export const STATUS_CANCEL_TRANSFER_STORE = 4;