export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const LIST_CATEGORIES_PRODUCTS = 'LIST_CATEGORIES_PRODUCTS';
export const LIST_BRANDS_PRODUCTS = 'LIST_BRANDS_PRODUCTS';
export const LIST_SENDER_PRODUCTS = 'LIST_SENDER_PRODUCTS';
export const SET_SENDER_INFO_PRODUCTS = 'SET_SENDER_INFO_PRODUCTS';
export const SET_SENDER_TOTAL_PRODUCTS = 'SET_SENDER_TOTAL_PRODUCTS';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const FIELD_CHANGE_PRODUCTS = 'FIELD_CHANGE_PRODUCTS';
export const RESET_FIELD_PRODUCTS = 'RESET_FIELD_PRODUCTS';
export const RESET_FIELD_SEARCH_PRODUCTS = 'RESET_FIELD_SEARCH_PRODUCTS';
export const FOCUS_FIELD_PRODUCTS = 'FOCUS_FIELD_PRODUCTS';
export const ERROR_FIELD_PRODUCTS = 'ERROR_FIELD_PRODUCTS';
export const BUTTON_SPINNER_PRODUCTS = 'BUTTON_SPINNER_PRODUCTS';
export const ITEM_BUTTON_SPINNER_PRODUCTS = 'ITEM_BUTTON_SPINNER_PRODUCTS';
export const DIRECT_TO_LIST_PRODUCTS = 'DIRECT_TO_LIST_PRODUCTS';
export const REMOVE_PHOTO_PRODUCTS = 'REMOVE_PHOTO_PRODUCTS';
export const CLEAR_SEARCH_PRODUCTS = 'CLEAR_SEARCH_PRODUCTS';
export const VIEW_PHOTO_THUMBNAIL_PRODUCTS = 'VIEW_PHOTO_THUMBNAIL_PRODUCTS';
export const GET_HISTORIES_PRODUCTS = 'GET_HISTORIES_PRODUCTS';




