import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import * as FinanceSourceAction from '../../actions/FinanceSourceAction';
import { Icon, Whisper, Popover, Alert } from 'rsuite';
const triggerRef = React.createRef();
class CreateBankAccount extends Component {
    constructor(props){
        super(props)
        this.state = {  
            name_active: false,
            name_error: false,
            account_number_active: false,
            account_number_error: false,
            account_name_active: false
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })  
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onHandleBanksChange(value){ 
        this.props.onFieldChange("bank_selected", value)
    }
    onAdd(){
        var account_number = this.props.finance_source.account_number
        var name = this.props.finance_source.name
        var account_name = this.props.finance_source.account_name
        var is_card = parseInt(this.props.finance_source.is_card)
        var is_bank = parseInt(this.props.finance_source.is_bank)
        if (name === ""){
            this.name_input.focus()
            this.setState({
                name_error: true
            })
            Alert.error("Vui lòng nhập tên ngân hàng")
            return false
        }
        if (account_number === ""){
            this.account_number_input.focus()
            this.setState({
                account_number_error: true
            })
            Alert.error("Vui lòng nhập số tài khoản")
            return false
        }
        this.props.onAdd({
            account_number,
            name,
            account_name,
            is_card,
            is_bank
        }).then(result => {
            if (result.status !== undefined){
                if (result.status === true){
                    this.props.onDone()
                    triggerRef.current.close();
                } else if (result.message !== undefined){
                    Alert.error(result.message)
                }
            }
        })
    }
    render(){
        let { name, account_number, account_name, is_card, is_bank } = this.props.finance_source;
        let { name_active, name_error, account_number_active, account_number_error, account_name_active } = this.state;

        let account_number_field_class = "nk-int-st", name_field_class = "nk-int-st", account_name_field_class = "nk-int-st"
        if (name !== "") {
            name_active = true;
        }
        if(name_active === true){
            name_field_class += ' nk-toggled';
        }
        if(name_error === true){
            name_field_class += ' error';
        }

        if (account_number !== "") {
            account_number_active = true;
        }
        if(account_number_active === true){
            account_number_field_class += ' nk-toggled';
        }
        if(account_number_error === true){
            account_number_field_class += ' error';
        }
        
        if (account_name !== "") {
            account_name_active = true;
        }
        if(account_name_active === true){
            account_name_field_class += ' nk-toggled';
        }
        const speaker = (
            <Popover title="Thêm tài khoản ngân hàng" style={{ width: 300 }}>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="form-group">    
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Tên ngân hàng:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <div className="float-lb floating-lb">
                                        <div className={ name_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name} placeholder="Nhập tên ngân hàng" ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Số tài khoản:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <div className="float-lb floating-lb">
                                        <div className={ account_number_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="account_number" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.account_number_input = input; }} value={account_number} placeholder="Nhập số tài khoản" ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Chủ tài khoản:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <div className="float-lb floating-lb">
                                        <div className={ account_name_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="account_name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.account_name_input = input; }} value={account_name} placeholder="Nhập tên chủ tài khoản" ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="pull-left mr-right-10">
                                <label><input type="checkbox" name="is_bank" onChange={ (e) => { this.onFieldChange(e) } } value="1" checked={+is_bank === 1 ? true : false} />&nbsp;Chuyển khoản</label>
                            </div>
                            <div className="pull-left">
                                <label><input type="checkbox" name="is_card" onChange={ (e) => { this.onFieldChange(e) } } value="1" checked={+is_card === 1 ? true : false} />&nbsp;Quẹt thẻ</label>
                            </div>
                            <div className="cl"></div>
                        </div>
                    </div>
                    <div className="col-xs-12 text-center">
                        <button className="btn btn-danger mr-right-5" onClick={ () => { triggerRef.current.close() } } >
                            <i className="fa fa-ban"></i>&nbsp;Đóng lại
                        </button>
                        <LaddaButton
                            loading={ this.props.finance_source.save_button_loading }
                            data-style={ZOOM_IN}
                            data-spinner-color="#000000"
                            className="btn btn-info" onClick={ () => { this.onAdd() } } >
                            <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                        </LaddaButton>
                    </div>
                </div>
            </Popover>
          );
        return (
            <Whisper
                ref={triggerRef}
                preventOverflow
                trigger="click"
                speaker={speaker}
                placement="rightStart"
                enterable
            >
                <Icon icon="plus-circle" size="2x" className="txt-blue" />
            </Whisper>
        );
    }
}

const mapStateToProps = state => {
    return {
        finance_source: state.finance_source
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(FinanceSourceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(FinanceSourceAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(FinanceSourceAction.fieldChange(field,value))
        },
        onAdd: (data_post) => {
            return dispatch(FinanceSourceAction.addRequest(data_post))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(CreateBankAccount)