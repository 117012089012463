import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import * as CategoryAction from '../../actions/CategoryAction';
import * as CategoryActionType from '../../constants/CategoryActionType';
import MyPagination from '../shared/Pagination';
import CreateAccountingTerm from './CreateTerm';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Alert, TagPicker } from 'rsuite';
class AccountingTerms extends Component {
    constructor(props){
        super(props)
        this.state = {  
            name_active: false,
            name_error: false,
            code_active: false,
            code_error: false
        }
        this.onHandleBanksChange = this.onHandleBanksChange.bind(this)
    }
    componentDidMount(){
        this.onInitData()
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })  
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onHandleBanksChange(value){ 
        this.props.onFieldChange("bank_selected", value)
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _keyword = this.props.category.search_keyword;
        var data_search = {
            "search": false,
            "is_admin": 1
        }
        let is_search = false
        if (_keyword !== ""){
            data_search["keyword"] = _keyword;
            is_search = true
        }
        data_search["search"] = is_search;
        data_search["category_type"] = CategoryActionType.ACCOUNTING_TERMS_CATEGORY_TYPE;
        return data_search
    }
    onInitData(){
        var data_search = this.getDataSearch();
        this.props.onSearch(data_search,1,this.props.category.limit, false)
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search.search !== undefined && data_search.search === true){
            this.props.onSearch(data_search,1,this.props.category.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        this.props.onFieldChange("page", pageNumber)
        this.props.onSearch(data_search,pageNumber,this.props.category.limit, false);
    }
    onShowEditItem(id){
        this.props.onFieldChange("is_edit", true)
        this.props.onItemButtonSpinner(id,true)
        this.props.onGetData(id).then(result => {
            this.props.onGetFinanceSource()
            this.props.onItemButtonSpinner(id,false)
        })
    }
    onSkipEditItem(){
        this.props.onFieldChange("is_edit", false)
        this.props.onResetField()
    }
    onSaveItem(id){
        var code = this.props.category.code
        var name = this.props.category.name
        var bank_array = this.props.category.bank_selected
        if (code === ""){
            this.code_input.focus()
            this.setState({
                code_error: true
            })
            Alert.error("Vui lòng nhập mã chi phí")
            return false
        }
        if (name === ""){
            this.name_input.focus()
            this.setState({
                name_error: true
            })
            Alert.error("Vui lòng nhập tên chi phí")
            return false
        }
        if (bank_array.length === 0){
            Alert.error("Vui lòng chọn tài khoản chi")
            return false
        }
        let banks = bank_array.join()
        this.props.onUpdate({
            code,
            name,
            banks
        }, id).then(result => {
            if (result !== undefined && result.status !== undefined){
                if (result.status === true){
                    this.onSkipEditItem()
                    var data_search = this.getDataSearch();
                    this.props.onSearch(data_search, this.props.category.page, this.props.category.limit, false)
                } else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            }
        })
    }
    onRemoveItem(id){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn xóa danh mục chi phí này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onDelete(id).then(result => {
                    if (result !== undefined && result.status !== undefined){
                        if (result.status === true){
                            var data_search = this.getDataSearch();
                            this.props.onSearch(data_search, this.props.category.page, this.props.category.limit, false)
                        } else if(result.status === false && result.message !== undefined && result.message !== ""){
                            Swal.fire({
                                icon: 'error',
                                title: 'Thông báo lỗi',
                                text: result.message
                            })
                        }
                    }
                });
            },
        })
    }
    onActiveItem(id,active){
        this.props.onItemButtonSpinner(id,true);
        if(+active === 1){
            active = 0
        } else {
            active = 1
        }
        this.props.onUpdate({
            active
        }, id).then(result => {
            if (result !== undefined && result.status !== undefined){
                if (result.status === true){
                    var data_search = this.getDataSearch();
                    this.props.onSearch(data_search, this.props.category.page, this.props.category.limit, false)
                } else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            }
        });
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            let { name, code, banks, bank_selected } = this.props.category;
            let { name_active, name_error, code_active, code_error } = this.state;
            let code_field_class = "nk-int-st", name_field_class = "nk-int-st"
            if (name !== "") {
                name_active = true;
            }
            if(name_active === true){
                name_field_class += ' nk-toggled';
            }
            if(name_error === true){
                name_field_class += ' error';
            }

            if (code !== "") {
                code_active = true;
            }
            if(code_active === true){
                code_field_class += ' nk-toggled';
            }
            if(code_error === true){
                code_field_class += ' error';
            }
            let bank_data = []
            bank_data.push({
                label: "Tiền mặt",
                value: "cash",
                role: ""
            })
            if (banks.length > 0){
                bank_data.push(...banks)
            }
            result = lists.map((item, index) => {
                let active_class = 'fa fa-eye';
                let active_text = (<span><br /><label className="label label-warning"><i className="fa fa-eye-slash"></i>&nbsp;Disabled</label></span>);
                if(+item.active === 1){
                    active_class = 'fa fa-eye-slash';
                    active_text = '';
                }
                let finance_sources = []
                if (item.finance_sources !== null && item.finance_sources.length > 0){
                    item.finance_sources.forEach(finance_source_item => {
                        let finance_source_name = ""
                        if (finance_source_item.is_cash === 1){
                            finance_source_name = "Tiền mặt"
                        } else if (finance_source_item.finance_source_id > 0){
                            finance_source_name = finance_source_item.finance_source_name
                        }
                        finance_sources.push(
                            <h5 key={"finance_source_" + finance_source_item.finance_source_id + "_" + index} ><label className="label label-primary mr-right-5">{finance_source_name}</label></h5>
                        )
                    })   
                }
                return (
                    <tr key={ index }>
                        <td>{ (this.props.category.is_edit === true && this.props.category.id === item.id) ? 
                                <div className={code_field_class}>
                                    <div className="nk-int-st nk-toggled">
                                        <input type="text" className="form-control" autoComplete="off" name="code" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.code_input = input; }} value={code} placeholder="Nhập mã chi phí" ></input>
                                    </div>
                                </div>
                            : <span>{ item.code }</span> }
                        </td>
                        <td>
                            { (this.props.category.is_edit === true && this.props.category.id === item.id) ? 
                                <div className={name_field_class}>
                                    <div className="nk-int-st nk-toggled">
                                        <input type="text" className="form-control" autoComplete="off" name="name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.name_input = input; }} value={name} placeholder="Nhập tên chi phí" ></input>
                                    </div>
                                </div>
                            : <span>{ item.name } { active_text }</span> }
                        </td>
                        <td>
                            { (this.props.category.is_edit === true && this.props.category.id === item.id) ?
                            <TagPicker
                                block
                                data={bank_data}
                                value={bank_selected}
                                menuStyle={{width: 300}}
                                groupBy="role"
                                placeholder="Chọn tài khoản chi"
                                onChange={this.onHandleBanksChange}
                            />
                            : <div>{finance_sources}</div> }
                        </td>
                        { this.props.category.is_edit === true ? 
                        <td className="text-center">
                            { this.props.category.id === item.id ?
                                <div>
                                    <LaddaButton
                                        loading={ item.loading }
                                        data-style={ZOOM_IN}
                                        data-spinner-color="#000000"
                                        className="btn btn-link" onClick={ () => { this.onSaveItem(item.id) } } >
                                        <i className="fa fa-save"></i>
                                    </LaddaButton>&nbsp;
                                    <button className="btn btn-link mr-left-5" onClick={ () => { this.onSkipEditItem() } } >
                                        <i className="fa fa-ban"></i>&nbsp;Bỏ qua
                                    </button>
                                </div> : "" }
                        </td>
                        : <td className="text-center">
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link" onClick={ () => { this.onShowEditItem(item.id) } } >
                                <i className="fa fa-edit"></i>
                            </LaddaButton>&nbsp;
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link" onClick={ () => { this.onActiveItem(item.id, item.active) } } >
                                <i className={active_class}></i>
                            </LaddaButton>&nbsp;
                            <LaddaButton
                                loading={ item.loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-link" onClick={ () => { this.onRemoveItem(item.id) } } >
                                <i className="fa fa-remove"></i>
                            </LaddaButton>
                        </td> }
                    </tr>
                );   
            })
        }
        return result;
    }
    render(){
        let { list, page, total_page, total, limit  } = this.props.category;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        <div className="pull-left">Danh mục chi phí</div>
                                        <div className="pull-left mr-left-10">
                                            <CreateAccountingTerm onDone={() => { this.onInitData() }} />
                                        </div>
                                    </h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="20%"></col>
                                        <col width="30%"></col>
                                        <col></col>
                                        <col width="14%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã chi phí</th>
                                            <th>Tên chi phí</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        category: state.category
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(CategoryAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(CategoryAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(CategoryAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(CategoryAction.resetField())
        },
        onResetFieldSearch(){
            dispatch(CategoryAction.resetFieldSearch())
        },
        onSearch: (data_search,page,limit,spinner) => {
            dispatch(CategoryAction.searchRequest(data_search,page,limit,spinner))
        },
        onDelete: id => {
            return  dispatch(CategoryAction.deleteRequest(id))
        },
        onUpdate: (data_post, id) => {
            return dispatch(CategoryAction.updateRequest(data_post, id))
        },
        onItemButtonSpinner(id,status){
            dispatch(CategoryAction.itemButtonSpinner(id,status))
        },
        onGetData: id => {
            return dispatch(CategoryAction.getRequest(id))
        },
        onGetFinanceSource(){
            dispatch(CategoryAction.getFinanceSourceRequest())
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(AccountingTerms)