import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputGroup, Icon, AutoComplete, Input, Toggle, Whisper, Tooltip } from 'rsuite';
import NumberFormat from 'react-number-format';
import * as UploaderAction from '../../actions/UploaderAction';
import apiCaller from '../../utils/apiCaller';

let timerSearchProduct = null;
class SearchProduct extends Component {
    constructor() {
        super();
        this.state = {
            list: [],
            search_mode: 0, 
            upload_file_mode: 1, 
            search_keyword: "", 
            tooltip_search_mode: "Nhập từ khóa" 
        };
        this.refSearchProduct = null;
        this.refSearchProductBarcode = null;
        this.limit = 100;
    }
    onSearchModeChange(checked){
        let value = 0
        let tooltip_search_mode_text = "Nhập từ khóa"
        if (checked === true){
            value = 1
            tooltip_search_mode_text = "Quét mã vạch"
        }
        this.setState({
            search_mode: value,
            tooltip_search_mode: tooltip_search_mode_text,
            upload_file_mode: 0,
            list: []
        })
        this.props.onShowUploader(false)
        if(this.props.allow_clear_product === true){
            this.props.onClearProduct()
        }
        setTimeout(() => {
            if(this.refSearchProduct !== null && value === 0){
                this.refSearchProduct.focus()
            } else if(this.refSearchProductBarcode !== null && value === 1){
                this.refSearchProductBarcode.focus()
            }
        },500)
    }
    onUploadFileModeChange(checked){
        let value = 0;
        let show = false;
        if (checked === true){
            value = 1
            show = true;
        }
        this.setState({
            upload_file_mode: value,
            search_mode: 0,
            list: []
        })
        this.props.onShowUploader(show)
        this.props.onClearProduct()
    }   
    onSearchProductByKeyword(value,event){
        if(timerSearchProduct !== null){
            clearTimeout(timerSearchProduct);
        }
        let is_each_size = 0
        if (this.props.get_size === true){
            is_each_size = 1
        }
        let is_check_inventory = 0
        if (this.props.is_check_inventory === true){
            is_check_inventory = 1
        }
        let evt = event.nativeEvent
        if(evt.inputType !== undefined){
            if (value.length >= 2){
                timerSearchProduct = setTimeout(() => {
                    this.onSearchProduct({
                        "keyword": value.trim(),
                        "is_each_size": is_each_size,
                        "is_check_inventory": is_check_inventory
                    },this.limit);
                }, 700)
            }
            this.setState({
                search_keyword: value
            });
        }
    }
    onSearchProductByBarcode(e) {
        if(timerSearchProduct !== null){
            clearTimeout(timerSearchProduct);
        }
        let value = this.refSearchProductBarcode.value
        if (e.key === 'Enter' && value !== null && value !== "") {
            let is_each_size = 0
            if (this.props.get_size === true){
                is_each_size = 1
            }
            let is_check_inventory = 0
            if (this.props.is_check_inventory === true){
                is_check_inventory = 1
            }
            this.onSearchProduct({
                "sku": value,
                "is_each_size": is_each_size,
                "is_check_inventory": is_check_inventory
            },1);
            this.refSearchProductBarcode.value = ""
        }
    }
    onSelectProduct(item){
        //console.log("item: ",item)
        this.props.onSelect(item)
        this.setState({
            search_keyword: "",
            upload_file_mode: 0
        });
        if (this.props.import_file === true){
        this.props.onShowUploader(false)
        }
        this.refSearchProduct.focus()
    }
    onSearchProduct(data_search,limit){
        apiCaller.callApi(`products/search`,{
            limit,
            page: 1,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list } = res.data.result;
                    //console.log("list: ", list)
                    if(list.length > 0){
                        if(limit === 1){
                            this.onSelectProduct(list[0])     
                        } else {
                            this.setState({
                                list: [...list]
                            })
                        }
                    }
                }
            }
        });
    }
    render(){
        let { list, search_mode } = this.state;
        let data = [];
        if(list.length > 0 && search_mode === 0){
            list.forEach((item) => {
                let key = item.id + "_" + item.product_option_id
                data.push({
                    id: item.id,
                    product_option_id: item.product_option_id,
                    product_option_name: item.product_option_name,
                    label: item.name + " " + item.model,
                    value: key,
                    name: item.name,
                    model: item.model,
                    sku: item.sku,
                    price: item.price,
                    retail_average: item.retail,
                    retail: item.main_retail,
                    remain: item.remain,
                    other_remain: item.other_remain,
                    url: item.url,
                    unit: item.unit,
                    categories: item.categories,
                    category_id: item.category_id,
                    category_name: item.category_name,
                    brand_id: item.brand_id,
                    brand_name: item.brand_name,
                    is_gift: item.is_gift,
                    has_expiry: item.has_expiry
                });
            });
        }
        //console.log(data)
        let upload_file_mode = this.state.upload_file_mode
        if (this.props.uploader.show_uploader === false){
            upload_file_mode = 0
        }
        let import_file_input = ""
        if (this.props.import_file === true) {
            import_file_input = <Whisper placement="top" trigger="hover" speaker={<Tooltip>Tải danh sách lên</Tooltip>}><Toggle className="mr-left-10" name="search_mode" size="lg" checkedChildren={<Icon icon="file-upload" />} unCheckedChildren={<Icon icon="ban" />} checked={ upload_file_mode === 1 ? true : false } onChange={ this.onUploadFileModeChange.bind(this) } /></Whisper>
        }
        return(
            <div className="form-group cl_search_product">
                <div className="pull-left w-80">
                    <div className={this.state.search_mode === 1 ? "display-none": "display-block"}>
                        <InputGroup style={{height: 50}}>
                            <InputGroup.Button>
                                <Icon icon="search" />
                            </InputGroup.Button>
                            <AutoComplete data={data} placeholder={this.props.placeholder}
                                renderItem={ item => {
                                    let imgTag = ""
                                    if(item.url !== ""){
                                        imgTag = <img src={item.url} alt="" />
                                    }
                                    return (
                                        <div className="form-group cl_product_item">
                                            <div className="pull-left">
                                                { imgTag }
                                            </div>
                                            <div className="pull-left mr-left-10">
                                                <strong className="title">{item.label}</strong><br />
                                                {item.model}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ item.product_option_name }<br />
                                                Giá: <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={item.price} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tồn kho: {item.remain}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CN khác: {item.other_remain}
                                            </div> 
                                            <div className="cl"></div>
                                        </div>
                                    );
                                }}
                                onChange={this.onSearchProductByKeyword.bind(this)}
                                onSelect={this.onSelectProduct.bind(this)}
                                value={this.state.search_keyword}
                                inputRef={(el) => this.refSearchProduct = el}
                            />
                        </InputGroup>
                    </div>
                    <div className={this.state.search_mode === 1 ? "display-block": "display-none"}>
                        <InputGroup style={{height: 50}}>
                            <InputGroup.Addon>
                                <Icon icon="search" />
                            </InputGroup.Addon>
                            <Input placeholder="Nhập mã vạch" inputRef={(el) => this.refSearchProductBarcode = el} onKeyDown={this.onSearchProductByBarcode.bind(this)} />
                        </InputGroup>
                    </div>
                </div>
                <div className="pull-left mr-left-10 mr-top-10">
                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>{this.state.tooltip_search_mode}</Tooltip>}>
                            <Toggle name="search_mode" size="lg" checkedChildren={<Icon icon="barcode" />} unCheckedChildren={<Icon icon="keyboard-o" />} checked={ this.state.search_mode === 1 ? true : false } onChange={ this.onSearchModeChange.bind(this) } />
                        </Whisper>
                        { import_file_input }
                </div>
                <div className="cl"></div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        uploader: state.uploader
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowUploader(status){
            dispatch(UploaderAction.showUploaderRequest(status))
        },
    }
}
SearchProduct.defaultProps = {
    import_file: true,
    get_size: false,
    allow_clear_product: false,
    is_check_inventory: false,
    placeholder: "Nhập mã hoặc tên hàng hóa"
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchProduct);