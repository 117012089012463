import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import MyPagination from '../shared/Pagination';
import Modal from "../shared/Modal";
import CreatePartner from './Create';
import * as UserAction from '../../actions/UserAction';
import * as ModalAction from '../../actions/ModalAction';
import * as UserActionType from '../../constants/UserActionType';
import NumberFormat from 'react-number-format';
import Helper from '../../utils/Helper';
class ListSuppliers extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_form_add: false
        }
    }
    componentDidMount(){
        if(this.props.user.is_search === true){
            this.handlePageChange(1)
        } else {
            this.props.onListCustomers(1,this.props.user.limit,UserActionType.SUPPLIER_USER_TYPE);
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    getDataSearch(is_search = true){
        let _name = this.props.user.search_name;
        let _phone = this.props.user.search_phone;
        let _email = this.props.user.search_email;
        let _keyword = this.props.user.search_keyword;
        var data_search = {
            "phone": _phone,
            "name": _name,
            "email": _email,
            "keyword": _keyword,
            "user_type": UserActionType.SUPPLIER_USER_TYPE
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search !== null){
            this.props.onSearchCustomers(data_search,1,this.props.user.limit, true);
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch(false);
        this.props.onSearchCustomers(data_search,pageNumber,this.props.user.limit, false);
    }
    onViewAll(){
        this.props.onResetFieldSearch();
        this.props.onListCustomers(1,this.props.user.limit,this.props.user.limit,UserActionType.SUPPLIER_USER_TYPE);
    }
    onItemClick(id){
        this.props.history.push("/supplier/" + id);
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td>{ item.code }</td>
                         <td>{ item.name }</td>
                         <td>{ item.phone }</td>
                         <td className="text-right"><NumberFormat value={ item.remain_liabilities } displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={ item.total_liabilities } displayType={'text'} thousandSeparator={true} /></td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    handleModalClose(){
        this.props.onShowModal(false);
    }
    handleModalShowAddCustomer(){
        //this.props.onShowModal(true, "Tạo thông tin nhà vận chuyển", "modal-lg", "add_supplier", "submit_add_supplier");
        this.props.onResetField()
        this.setState({
            show_form_add: true
        })
    }
    handleModalSubmit(submit_type){
        if (submit_type === "submit_add_supplier"){
            this.handleSubmitFormAddCustomer()
        }
    }
    handleSubmitFormAddCustomer(){
        this.createPartnerRef.onSubmit()
    }
    onCreatePartnerReturn(user_info){
        //console.log(user_info)
        //this.handleModalClose();

        this.setState({
            show_form_add: false
        })
        this.props.onListCustomers(1,this.props.user.limit,UserActionType.SUPPLIER_USER_TYPE);
    }
    onSkipCreatePartner(){
        this.setState({
            show_form_add: false
        })
    }
    render(){
        let { total, remain_liabilities, total_liabilities, limit, total_page, page, list, search_keyword, search_button_loading, is_search } = this.props.user;
        let { show_modal, modal_type, modal_title, modal_submit_type, modal_button_spinner} = this.props.modal;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let btn_view_all = ""
        if(is_search === true){
            btn_view_all =  (<LaddaButton
                                loading={ search_button_loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onViewAll() } } >
                                <i className="fa fa-list"></i>&nbsp;Xem tất cả
                            </LaddaButton>)
        }
        return (
            <div>
                { this.state.show_form_add === true ?
                <CreatePartner partner_type={UserActionType.SUPPLIER_USER_TYPE} getRef={ref => this.createPartnerRef = ref} return_info={true} return={() => { this.onCreatePartnerReturn()  } } onSkip={() => this.onSkipCreatePartner() }/>
                : <div className="container">
                    <div className="row">
                        { Helper.isViewer() === false ?
                        <div className="col-xs-12 mr-bot-10"> 
                            <button type="button" className="btn btn-info pull-right mr-left-10" onClick={ () => { this.handleModalShowAddCustomer() } }><i className="fa fa-plus"></i>&nbsp;Thêm nhà cung cấp</button>
                            <div className="cl"></div>
                        </div> : null }
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div className="form-element-list" >
                                <div className="cmp-tb-hd">
                                    <h2>Bộ lọc</h2>
                                    <p></p>
                                </div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <input name="search_keyword" value={ search_keyword } className="form-control" autoComplete="off" placeholder="Nhập tên hoặc điện thoại" onChange={ (e) => { this.onFieldChange(e) } }></input>
                                </div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <LaddaButton
                                        loading={ search_button_loading }
                                        data-style={ZOOM_IN}
                                        data-spinner-color="#000000"
                                        className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                        <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                    </LaddaButton>
                                    { btn_view_all }
                                </div>
                            </div>
                        </div>    
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                    { _pagination }
                            <div className="normal-table-list">
                                <div className="basic-tb-hd">
                                    <div className="pull-left">
                                        <h2>
                                            <div className="pull-left">Nhà cung cấp</div>
                                        </h2>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="bsc-tbl">
                                    <table className="table table-sc-ex table-hover table-condensed">
                                        <colgroup>
                                            <col width="15%"></col>
                                            <col></col>
                                            <col width="7%"></col>
                                            <col width="12%"></col>
                                            <col width="12%"></col>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Mã</th>
                                                <th>Tên nhà cung cấp</th>
                                                <th>Điện thoại</th>
                                                <th className="text-right">Nợ cần trả hiện tại</th>
                                                <th className="text-right">Tổng mua</th>
                                            </tr>
                                            <tr className="bg-warning">
                                                <th colSpan="3"></th>
                                                <th className="text-right"><NumberFormat value={remain_liabilities} displayType={'text'} thousandSeparator={true} /></th>
                                                <th className="text-right"><NumberFormat value={total_liabilities} displayType={'text'} thousandSeparator={true} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { this.showList(list) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                    { _pagination }
                        </div>
                    </div>
                    <Modal show={show_modal} type={modal_type} spinner={modal_button_spinner} title={modal_title} handleClose={this.handleModalClose.bind(this)} handleSubmit={this.handleModalSubmit.bind(this,modal_submit_type)}  children={<CreatePartner getRef={ref => (this.createPartnerRef = ref)} avatarUploaderWidth={100} avatarUploaderHeight={100} has_save_button={false} return_info={true} return={user_info => { this.onCreatePartnerReturn(user_info)  } } partner_type={UserActionType.SUPPLIER_USER_TYPE} />}></Modal>
                </div> }
            </div>
            );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        modal: state.modal
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onShowModal: (status, title, type, input, submit_type) => {
            dispatch(ModalAction.showModal(status, title, type, input, submit_type))
        },
        onModalButtonSpinner(status){
            dispatch(ModalAction.buttonSpinner(status))
        },
        onErrorField(field,value){
            dispatch(UserAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(UserAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(UserAction.fieldChange(field,value))
        },
        onResetFieldSearch(){
            dispatch(UserAction.resetFieldSearch())
        },
        onResetField(){
            dispatch(UserAction.resetField())
        },
        onListCustomers: (page,limit,user_type) => {
            dispatch(UserAction.listRequest(page,limit,user_type))
        },
        onSearchCustomers: (data_search,page,limit,spinner) => {
            dispatch(UserAction.searchRequest(data_search,page,limit,spinner))
        },
        onUpdateProduct: (product, files, page, limit) => {
            dispatch(UserAction.updateRequest(product, files, page, limit))
        },
        onItemButtonSpinner(id,status){
            dispatch(UserAction.itemButtonSpinner(id,status))
        },
    }
}
const ListSuppliersConnect = (connect(mapStateToProps, mapDispatchToProps)(ListSuppliers));
export default withRouter(ListSuppliersConnect);