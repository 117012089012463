import * as InventoryCheckActionType from '../constants/InventoryCheckActionType';
import apiCaller from '../utils/apiCaller';

export const focusField = (field,value) => {
    return {
        type: InventoryCheckActionType.FOCUS_FIELD_INVENTORY_CHECK,
        field,
        value
    }
}

export const errorField = (field,value) => {
    return {
        type: InventoryCheckActionType.ERROR_FIELD_INVENTORY_CHECK,
        field,
        value
    }
}

export const fieldChange = (field,value) => {
    return {
        type: InventoryCheckActionType.FIELD_CHANGE_INVENTORY_CHECK,
        field,
        value
    }
}

export const emptyInfo = () => {
    return {
        type: InventoryCheckActionType.EMPTY_INVENTORY_CHECK
    }
}

export const resetField = () => {
    return {
        type: InventoryCheckActionType.RESET_FIELD_INVENTORY_CHECK
    }
}

export const resetFieldSearch = () => {
    return {
        type: InventoryCheckActionType.RESET_FIELD_SEARCH_INVENTORY_CHECK
    }
}

const buttonSpinner = status => {
    return {
        type: InventoryCheckActionType.BUTTON_SPINNER_INVENTORY_CHECK,
        status
    }
}
export const itemButtonSpinner = (id,status) => {
    return {
        type: InventoryCheckActionType.ITEM_BUTTON_SPINNER_INVENTORY_CHECK,
        id,
        status
    }
}

export const getRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`inventory-checks/${id}`,null,'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(get(res.data.result))
            }
            return {
                status: res.data.status,
                message: (res.data.message !== undefined)?res.data.message:"",
                id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
            }
        });
    }
}

const get = inventory_checks => {
    return {
        type: InventoryCheckActionType.GET_INVENTORY_CHECK,
        inventory_checks
    }
}

export const listRequest = (page,limit) => {
    return (dispatch) => {
        return apiCaller.callApi(`inventory-checks`,{
            limit,
            page
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, total_page, page } = res.data.result;
                    dispatch(listInventoryChecks(total,limit,total_page,page,list));
                }
            }
        });
    }
}

export const searchRequest = (data_search,page,limit,spinner) => {
    return (dispatch) => {
        if (spinner === true){
            dispatch(searchButtonSpinner(true));
        }
        return apiCaller.callApi(`inventory-checks`,{
            limit,
            page,
            ...data_search
        },'GET').then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { list, total, limit, total_page, page } = res.data.result;
                    dispatch(listInventoryChecks(total,limit,total_page,page,list));
                    if (spinner === true){
                        dispatch(searchButtonSpinner(false));
                    }
                }
            }
        });
    }
}

const listInventoryChecks = (total,limit,total_page,page,inventory_checks) => {
    return {
        type: InventoryCheckActionType.LIST_INVENTORY_CHECK,
        total,
        limit,
        total_page,
        page,
        inventory_checks
    }
}
const searchButtonSpinner = (search_button_loading) => {
    return {
        type: InventoryCheckActionType.SEARCH_INVENTORY_CHECK,
        search_button_loading
    }
}

export const selectProductToListRequest = item => {
    return (dispatch) => {
        dispatch(selectProductToList(item))
         setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const selectProductToList = item => {
    return {
        type: InventoryCheckActionType.SELECT_PRODUCT_INVENTORY_CHECK,
        item
    }
}

export const updateProductItemRequest = (input_name, key, value) => {
    return (dispatch) => {
        //console.log("key in action request: ", key)
        dispatch(updateProductItem(input_name, key, value));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProductItem = (input_name, key, value) => {
    console.log("input_name in action: ", input_name)
    console.log("key in action: ", key)
    console.log("value in action: ", value)
    return {
        type: InventoryCheckActionType.UPDATE_PRODUCT_INVENTORY_CHECK,
        input_name,
        key,
        value
    }
}

export const updateProductsRequest = (products) => {
    return (dispatch) => {
        dispatch(updateProducts(products));
        setTimeout(function(){
            dispatch(sumTotal());
        },200);
    }
}

const updateProducts = (products) => {
    return {
        type: InventoryCheckActionType.UPDATE_PRODUCTS_INVENTORY_CHECK,
        products
    }
}

export const clearProductRequest = () => {
    return {
        type: InventoryCheckActionType.CLEAR_PRODUCTS_INVENTORY_CHECK
    }
}

export const sumTotal = () => {
    return {
        type: InventoryCheckActionType.SUM_TOTAL_INVENTORY_CHECK
    }
}

export const addRequest = (data_post) => {
    return (dispatch) => {
        dispatch(buttonSpinner(true));
        return apiCaller.callApi(`inventory-checks/new`,data_post,'PUT').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(buttonSpinner(false));
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    dispatch(resetField())
                    return {
                        status: res.data.status,
                        message: (res.data.message !== undefined)?res.data.message:"",
                        id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                    }
                }
            }
        });
    }
}

export const updateRequest = (data_post, id, button_name)  => {
    return (dispatch) => {
        let _url = `inventory-checks/${id}`;
        let _method = 'POST';
        dispatch(updateButtonSpinner(button_name,true))
        return apiCaller.callApi(_url,data_post,_method).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                    let { id } = res.data.result;
                    dispatch(getRequest(id))
                    if (button_name === "save_note_button_loading"){
                        dispatch(getLastStaffNoteRequest(id))
                        dispatch(getStaffNotesRequest(id))
                    }
                    dispatch(updateButtonSpinner(button_name,false))
                    return {
                        status: res.data.status,
                        message: (res.data.message)?res.data.message:"",
                        id: (res.data.result !== undefined && res.data.result.id !== undefined)?res.data.result.id:0
                    }
                }
            }
        });
    }
}

export const getStaffNotesRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes`,{
            "inventory_check_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getStaffNotes(res.data.result.list))
            }
        });
    }
}

const getStaffNotes = staff_notes => {
    return {
        type: InventoryCheckActionType.GET_STAFF_NOTES_INVENTORY_CHECK,
        staff_notes
    }
}

export const getLastStaffNoteRequest = id => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/last`,{
            "inventory_check_id": id
        },'GET').then(res => {
            if (typeof res.data.result != 'undefined' && res.data.result !== null){
                dispatch(getLastStaffNote(res.data.result))
            }
        });
    }
}

const getLastStaffNote = last_staff_notes => {
    return {
        type: InventoryCheckActionType.GET_LAST_STAFF_NOTES_INVENTORY_CHECK,
        last_staff_notes
    }
}

export const removeStaffNoteRequest = (id, staff_note_id) => {
    return (dispatch) => {
        return apiCaller.callApi(`notes/${staff_note_id}`,null,'DELETE').then(res => {
            if(res.status === 201 || res.status === 200){
                dispatch(getLastStaffNoteRequest(id))
                dispatch(getStaffNotesRequest(id))
            }
        });
    }
}

const updateButtonSpinner = (button_name, status) => {
    return {
        type: InventoryCheckActionType.BUTTON_UPDATE_SPINNER_INVENTORY_CHECK,
        button_name,
        status
    }
}
export const cancelRequest = (id, reason_cancel) => {
    return (dispatch) => {
        return apiCaller.callApi(`inventory-checks/cancel/` + id,{reason_cancel},'POST').then(res => {
            if(res.status === 201 || res.status === 200){
                return {
                    status: res.data.status,
                    message: (res.data.message)?res.data.message:""
                }
            } 
            return {
                status: false
            }
        });
    }
}