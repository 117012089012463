import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { RadioGroup, Radio, SelectPicker  } from 'rsuite';
import moment from 'moment';
import * as ReportAction from '../../actions/ReportAction';
import * as ReportActionType from '../../constants/ReportActionType';
import DatePickerFilter from '../shared/DatePickerFilter';
import LoadingIndicator from '../shared/Loader';
import ReportProfit from './ReportProfit';
import Helper from '../../utils/Helper';
import Swal from 'sweetalert2';

class ReportSale extends Component {
    constructor(props){
        super(props)
        this.state = {
            branchs: [],
            from_date: "",
            to_date: "",
            back_filter_date: [],
            back_index: 0,
        }
        this.onSelectBranch = this.onSelectBranch.bind(this);
    }
    componentDidMount(){
        let branchs_state = []
        let branchs = Helper.getMyBranchs()
        if (branchs !== null && branchs.length > 0){
            branchs.forEach(item => {
                branchs_state.push(item)
            });
        }
        this.setState({
            branchs: branchs_state
        })

        let from_date = moment().startOf('month').format('YYYY-MM-DD'); 
        let to_date = moment().endOf('month').format('YYYY-MM-DD');

        let branch_search = Helper.getQueryString('branch');
        let from_date_search = Helper.getQueryString('from_date');
        let to_date_search = Helper.getQueryString('to_date');
        if(branch_search !== undefined && branch_search !== null && branch_search !== ""){
            branch_search = parseInt(branch_search)
            if(branch_search > 0){
                let branch_selected = {}
                branchs_state.forEach(item => {
                    if (branch_search === item.id){
                        branch_selected = {
                            name: item.branch_name,
                            id: item.id,
                        }
                    }   
                });
                this.props.onFieldChange("branch_selected",branch_selected);
            }
        }
        if(from_date_search !== undefined && from_date_search !== null && from_date_search !== ""){
            var from_date_check = moment(from_date_search);
            if (from_date_check.isValid() === true){
                from_date = from_date_search
            }
        }
        if(to_date_search !== undefined && to_date_search !== null && to_date_search !== ""){
            var to_date_check = moment(to_date_search);
            if (to_date_check.isValid() === true){
                to_date = to_date_search
            }
        } else if(from_date_search !== undefined && from_date_search !== null && from_date_search !== "" && from_date !== ""){
            to_date = ""
        }
        this.props.onFieldChange("from_date",from_date);
        this.props.onFieldChange("to_date",to_date);
        this.props.onFieldChange("interest_type",ReportActionType.INTEREST_TYPE_PROFIT);
        this.setState({
            from_date,
            to_date,
            back_filter_date: [],
            back_index: 0
        })
        this.onViewReport(from_date, to_date, false)
    }
    componentWillUnmount(){
        this.props.onFieldChange("back",false);
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
    }
    onReportDatePickerChange(value){
        if (value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            this.props.onFieldChange("from_date",from_date);
            this.props.onFieldChange("to_date",to_date);
            this.setState({
                from_date,
                to_date,
                back_filter_date: [],
                back_index: 0
            })
            this.onViewReport(from_date, to_date, false)
        }
    }
    onViewInReport = (from_date, to_date, back) => {
        this.props.onFieldChange("back",back);
        let back_filter_date = this.state.back_filter_date
        if(back === true){
            let add_to_list = true
            for(let i = 0; i < back_filter_date.length; i++){
                if(back_filter_date[i].from_date === this.state.from_date && back_filter_date[i].to_date === this.state.to_date){
                    add_to_list = false
                    break
                }
            }
            if (add_to_list === true){
                back_filter_date.push({
                    from_date: this.state.from_date,
                    to_date: this.state.to_date,
                })
            }
            this.setState({
                from_date,
                to_date,
                back_filter_date,
                back_index: this.state.back_index + 1
            })
        } else {
            let back_index = this.state.back_index - 1
            if(back_index > 0){
                this.props.onFieldChange("back",true);
            } else {
                back_index = 0
            }
            back_filter_date = this.state.back_filter_date[back_index]
            from_date = back_filter_date.from_date
            to_date = back_filter_date.to_date
            this.setState({
                from_date,
                to_date,
                back_index,
            })
        }
        this.onViewReport(from_date, to_date)
    }
    onViewReport(from_date, to_date){
        var _this = this
        setTimeout(function(){   
            if(from_date !== ""){
                //let my_stores = Helper.getMyStores()
                let branch_id = 0
                let branch_selected = _this.props.report.branch_selected
                if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
                    branch_id = branch_selected.id
                }
                let param = []
                if (branch_id > 0){
                    param.push("branch=" + branch_id)
                }
                if (from_date !== ""){
                    param.push("from_date=" + from_date)
                }
                if (to_date !== ""){
                    param.push("to_date=" + to_date)
                }
                if(param.length > 0){
                    _this.props.history.push("/report/sale?" + param.join("&"))
                }
                _this.props.onSetLoading(true)
                _this.props.onReport(_this.props.report.interest_type, {
                    branch_id: branch_id,
                    from_date: from_date,
                    to_date: to_date,
                }).then(result => {
                    if(result.status === false && result.message !== undefined && result.message !== ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Thông báo lỗi',
                            text: result.message
                        })
                    }
                })
            }
        },250)
    }
    onSelectBranch(value){
        if(value !== null){
            let branch_selected_state = {}
            if (this.state.branchs.length > 0){
                this.state.branchs.forEach(item => {
                    if(item.id === value){
                        branch_selected_state = {
                            name: item.branch_name,
                            id: item.id,
                        }
                    }
                });
            }
            this.props.onFieldChange("branch_selected",branch_selected_state)
            this.onViewReport(this.props.report.from_date, this.props.report.to_date)
        }
    }
    render(){
        let { report_view, interest_type, list, loading, back, from_date, to_date, branch_selected } = this.props.report;
        /*console.log("this.state.back_filter_date: ",this.state.back_filter_date)
        console.log("this.state.back_index: ",this.state.back_index)
        if (this.state.back_filter_date.length > 0){
            console.log("this.state.back_filter width index: ",this.state.back_filter_date[this.state.back_index])
        }*/
        let date_filter = []
        if (from_date !== ""){
            date_filter.push(from_date)
        }
        if (to_date !== ""){
            date_filter.push(to_date)
        }
        let branchs_array = []
        if (this.state.branchs.length > 0){
            branchs_array.push({
                label: "Tất cả",
                value: 0
            })
            this.state.branchs.forEach(item => {
                branchs_array.push({
                    label: item.branch_name,
                    value: item.id
                })
            });
        }
        let branch_selected_value = 0
        if(branch_selected.id !== undefined && branch_selected.id !== null && branch_selected.id > 0){
            branch_selected_value = branch_selected.id
        }
        return (
            <div className="container">
            <Helmet>
                <title>Báo cáo lợi nhuận theo hóa đơn</title>
            </Helmet>   
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Kiểu hiển thị</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <RadioGroup
                                                name="report_view"
                                                value={report_view}
                                                onChange={value => { this.props.onFieldChange('report_view', value) } }
                                            >
                                            <Radio checked={report_view === ReportActionType.REPORT_VIEW_CHART ? true : false}  value={ReportActionType.REPORT_VIEW_CHART}> Biểu đồ</Radio>
                                            <Radio checked={report_view === ReportActionType.REPORT_VIEW_INFO ? true : false}  value={ReportActionType.REPORT_VIEW_INFO}> Báo cáo</Radio>
                                        </RadioGroup>    
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Mối quan tâm</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <RadioGroup
                                                name="report_view"
                                                value={interest_type}
                                                onChange={value => { this.props.onFieldChange('interest_type', value) }}
                                            >
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_TIME ? true : false} value={ReportActionType.INTEREST_TYPE_TIME}> Thời gian</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_PROFIT ? true : false} value={ReportActionType.INTEREST_TYPE_PROFIT}> Lợi nhuận</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_RECEIPT_DISCOUNT ? true : false} value={ReportActionType.INTEREST_TYPE_RECEIPT_DISCOUNT}> Giảm giá hóa đơn</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_RECEIPT_RETURN ? true : false} value={ReportActionType.INTEREST_TYPE_RECEIPT_RETURN}> Trả hàng</Radio>
                                            <Radio checked={interest_type === ReportActionType.INTEREST_TYPE_SELLER ? true : false} value={ReportActionType.INTEREST_TYPE_SELLER}> Nhân viên</Radio>
                                        </RadioGroup>    
                                    </div>
                                </div>
                            </div>    
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Thời gian</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                            <DatePickerFilter placeholder="Chọn thời gian báo cáo" has_default={true} value={date_filter} onFieldChange = { (value) => { this.onReportDatePickerChange(value) } }
                                            />
                                    </div>
                                </div>
                            </div>  
                            <div className="col-xs-12 mr-top-10">
                                <div className="form-element-list" >
                                    <div className="cmp-tb-hd">
                                        <h2>Chi nhánh</h2>
                                        <p></p>
                                    </div>
                                    <div className="form-group ic-cmp-int float-lb floating-lb">
                                        <SelectPicker
                                            style={{color: "#FFFFFF"}}
                                            block
                                            searchable={false}
                                            cleanable={false}
                                            placeholder="Chọn chi nhánh"
                                            data={branchs_array}
                                            value={branch_selected_value}
                                            onSelect={this.onSelectBranch}
                                            renderMenu={menu => {
                                                return menu;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        { loading === true ? <LoadingIndicator /> : <ReportProfit list={list} report_view={report_view} from_date={this.state.from_date} to_date={this.state.to_date} back={back} branch_selected={branch_selected} onView={(from_date, to_date, back) => {  this.onViewInReport(from_date, to_date, back) } }  /> }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {    
        report: state.report
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldChange(field,value){
            dispatch(ReportAction.fieldChange(field,value))
        },
        onSetLoading(status){
            dispatch(ReportAction.setLoading(status))
        },
        onReport: (interest_type, data_post) => {
            return dispatch(ReportAction.reportRequest(interest_type, data_post))
        },
        onReportByChart: (data_post) => {
            dispatch(ReportAction.reportByChartRequest(data_post))
        },
    }
}

const ReportSaleConnect = (connect(mapStateToProps, mapDispatchToProps)(ReportSale));
export default withRouter(ReportSaleConnect);