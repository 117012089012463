import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import CategoriesSelectBox from './CategoriesSelectBox';
import BrandsSelectBox from './BrandsSelectBox';
import MyPagination from '../shared/Pagination';
import * as ProductsAction from '../../actions/ProductAction';

class Products extends Component {
    constructor() {
        super();
        this.state = {
            in_stock: false
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.in_stock !== prevState.in_stock){
            return { in_stock: nextProps.in_stock};
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.in_stock !== this.props.in_stock){
            this.setState({in_stock: this.props.in_stock});
            if (this.props.in_stock === true) {
                this.props.onSearchProductInStock({},1,this.props.product.limit, false);
            } else {
                this.props.onListProducts(1,this.props.product.limit);
            }
        } 
    } 
    componentDidMount(){
        if(this.props.product.is_search === true){
            this.handlePageChange(1)
        } else {
            if (this.props.in_stock === true) {
                this.props.onSearchProductInStock({},1,this.props.product.limit, false);
            } else {
                this.props.onListProducts(1,this.props.product.limit);
            }
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value)
    }
    onSearchProductEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }
    getDataSearch(){
        let _model = this.props.product.search_model;
        let _name = this.props.product.search_name;
        let _category_selected = this.props.product.search_category_selected;
        let _brand_selected = this.props.product.search_brand_selected;
        if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return null;
        }
        var data_search = {
            "model": _model,
            "name": _name,
            "category_id": _category_selected.value,
            "brand_id": _brand_selected.value,
            "is_gift": this.props.product.search_gift
        }
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if (data_search !== null){
            if (this.props.in_stock === true) {
                this.props.onSearchProductInStock(data_search,1,this.props.product.limit, true);
            } else {
                this.props.onSearchProducts(data_search,1,this.props.product.limit, true);
            }
        }
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        if (this.props.in_stock === true) {
            this.props.onSearchProductInStock(data_search,pageNumber,this.props.product.limit, false);
        } else {
            this.props.onSearchProducts(data_search,pageNumber,this.props.product.limit, false);
        }
    }
    onViewAll(){
        this.props.onResetFieldSearch();
        if (this.props.in_stock === true) {
            this.props.onSearchProductInStock({},1,this.props.product.limit);
        } else {
            this.props.onListProducts(1,this.props.product.limit);
        }
    }
    onSelectSearchByCategoryChange(opt, meta){
        if(opt != null){
            this.props.onFieldChange("search_category_selected",opt);
        }
    }
    onItemClick(id){
        this.props.history.push("/product/" + id);
    }
    showList(list_products){
        var result = null;
        if(list_products.length > 0){
            result = list_products.map((item, index) => {
                 let gift_text = ""
                 if (item.is_gift === 1){
                    gift_text = <span><i className="fa fa-gift text-danger"></i>&nbsp;</span>
                 }
                 //console.log('id: ',item.id, item.retail_according);
                 let retail_according = ""
                 if (item.retail_according === 1){
                    retail_according = <span><br /><label className="label label-info">Giá nhập theo đợt</label></span>
                 }
                 return (
                     <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                         <td className="text-center"><img src={item.url} alt="" /></td>
                         <td>{ item.model }</td>
                         <td>{gift_text}{ item.name }{retail_according}</td>
                         <td className="text-right"><NumberFormat value={item.remain} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.retail} displayType={'text'} thousandSeparator={true} /></td>
                         <td className="text-right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} />
</td>
                     </tr>
                 );   
            });
        }
        return result;
    }
    render(){
        let { total, total_remain, total_retail, limit, total_page, page, list, search_model, search_name, search_gift, search_button_loading, is_search } = this.props.product;
        
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }

        let btn_view_all = ""
        if(is_search === true){
            btn_view_all =  (<LaddaButton
                                loading={ search_button_loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onViewAll() } } >
                                <i className="fa fa-list"></i>&nbsp;Xem tất cả
                            </LaddaButton>)
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_model" value={ search_model } className="form-control" autoComplete="off" placeholder="Nhập mã hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchProductEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_name" value={ search_name } className="form-control" autoComplete="off" placeholder="Nhập tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchProductEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <CategoriesSelectBox />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <BrandsSelectBox />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="toggle-select-act fm-cmp-mg">
                                    <div className="nk-toggle-switch">
                                        <label htmlFor="search_gift" className="ts-label">Là quà tặng</label>
                                        <input id="search_gift" name="search_gift" type="checkbox" hidden="hidden" value="1" checked={search_gift === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                        <label htmlFor="search_gift" className="ts-helper"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                                { btn_view_all }
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                { _pagination }
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        {this.props.in_stock === true ? "Hàng hóa tồn kho" : "Hàng hóa" }
                                    </h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-condensed">
                                    <colgroup>
                                        <col width="8%"></col>
                                        <col width="15%"></col>
                                        <col></col>
                                        <col width="7%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Mã hàng</th>
                                            <th>Tên hàng</th>
                                            <th className="text-right">Tồn kho</th>
                                            <th className="text-right">Giá nhập cuối</th>
                                            <th className="text-right">Giá bán</th>
                                        </tr>
                                        {this.props.in_stock === true ?
                                        <tr className="bg-warning">
                                            <th colSpan="3"></th>
                                            <th className="text-right"><NumberFormat value={total_remain} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_retail} displayType={'text'} thousandSeparator={true} /></th>
                                            <th></th>
                                        </tr>
                                        : null }
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

Products.defaultProps = {
    in_stock: false
}

const mapStateToProps = state => {
    return {
        product: state.product
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(ProductsAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(ProductsAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(ProductsAction.fieldChange(field,value))
        },
        onResetFieldSearch(){
            dispatch(ProductsAction.resetFieldSearch())
        },
        onResetField(){
            dispatch(ProductsAction.resetField())
        },
        onListProducts: (page,limit) => {
            dispatch(ProductsAction.listRequest(page,limit))
        },
        onSearchProductInStock: (data_search, page,limit,spinner) => {
            dispatch(ProductsAction.listInStockRequest(data_search,page,limit,spinner))
        },
        onSearchProducts: (data_search,page,limit,spinner) => {
            dispatch(ProductsAction.searchRequest(data_search,page,limit,spinner))
        },
        onUpdateProduct: (product, files, page, limit) => {
            dispatch(ProductsAction.updateRequest(product, files, page, limit))
        },
        onItemButtonSpinner(id,status){
            dispatch(ProductsAction.itemButtonSpinner(id,status))
        },
    }
}
const ProductsConnect = (connect(mapStateToProps, mapDispatchToProps)(Products));
export default withRouter(ProductsConnect);