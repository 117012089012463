import * as InventoryCheckActionType from '../constants/InventoryCheckActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    inventory_check_code: "",
    inventory_check_date: "",
    inventory_check_notes: "",
    inventory_check_notes_active: false,
    import_receipt_id: 0,
    import_receipt_code: "",
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    inventory_check_status_select: [],
    inventory_check_status: 0,
    inventory_check_status_text: "",
    inventory_check_status_color: "",
    total_quantity: 0,
    total_product: 0,
    total_amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    branch_id: 0,
    branch_name: "",
    products: [],
    staff_notes: [],
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    search_code: "",
    search_product: "",
    search_date: [],
    search_status: [],
    search_button_loading: false,
    save_button_loading: false,
    save_temp_button_loading: false,
    save_date_button_loading: false,
    save_note_button_loading: false,
    is_search: false,
    direct_to_list: false,
};
const inventory_check = (state = initialState, action) => {
    let index = -1
    let products = []
    switch(action.type){
        case InventoryCheckActionType.ITEM_BUTTON_SPINNER_INVENTORY_CHECK:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case InventoryCheckActionType.BUTTON_SPINNER_INVENTORY_CHECK:
            return {
                ...state,
                save_button_loading: action.status
            };
        case InventoryCheckActionType.BUTTON_UPDATE_SPINNER_INVENTORY_CHECK:
          return {
              ...state,
              [action.button_name]: action.status
          };
        case InventoryCheckActionType.FOCUS_FIELD_INVENTORY_CHECK:
              return {
                ...state,
                [action.field]: action.value
              };
        case InventoryCheckActionType.ERROR_FIELD_INVENTORY_CHECK:
              return {
                ...state,
                [action.field]: action.value
              };
        case InventoryCheckActionType.FIELD_CHANGE_INVENTORY_CHECK:
              return {
                ...state,
                [action.field]: action.value
              };
        case InventoryCheckActionType.EMPTY_INVENTORY_CHECK:
          return {
            ...state,
            id: 0,
            inventory_check_code: "",
            inventory_check_date: "",
            inventory_check_notes: "",
            inventory_check_notes_active: false,
            inventory_check_notes_error: false,
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            inventory_check_status_select: [],
            inventory_check_status: 0,
            inventory_check_status_text: "",
            import_receipt_status_color: "",
            total_quantity: 0,
            total_product: 0,
            total_amount: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            branch_id: 0,
            branch_name: "",
            products: [],
            staff_notes: [],
            total: 0,
            limit: 10,
            total_page: 0,
            page: 1,
            list: [],
            search_code: "",
            search_product: "",
            search_date: [],
            search_status: [],
            search_button_loading: false,
            save_button_loading: false,
            save_temp_button_loading: false,
            save_date_button_loading: false,
            save_note_button_loading: false,
            is_search: false,
            direct_to_list: false,
          };
        case InventoryCheckActionType.RESET_FIELD_SEARCH_INVENTORY_CHECK:      
            return {
              ...state,
              search_code: "",
              search_product: "",
              search_date: [],
              search_status: [],
              is_search: false,
            };
        case InventoryCheckActionType.RESET_FIELD_INVENTORY_CHECK:
            return {
              ...state,
              id: 0,
              inventory_check_code: "",
              inventory_check_date: "",
              inventory_check_notes: "",
              inventory_check_notes_active: false,
              inventory_check_notes_error: false,
              last_notes: "",
              last_notes_staff_name: "",
              last_notes_time: "",
              inventory_check_status_select: [],
              inventory_check_status: 0,
              inventory_check_status_text: "",
              import_receipt_status_color: "",
              total_quantity: 0,
              total_product: 0,
              total_amount: 0,
              staff_id: 0,
              staff_name: "",
              staff_cancel_id: 0,
              staff_cancel_name: "",
              reason_cancel: "",
              cancel_time: 0,
              branch_id: 0,
              branch_name: "",
              products: [],
              staff_notes: [],
              total: 0,
              limit: 10,
              total_page: 0,
              page: 1,
              list: [],
              search_code: "",
              search_product: "",
              search_date: [],
              search_status: [],
              search_button_loading: false,
              save_button_loading: false,
              save_temp_button_loading: false,
              save_date_button_loading: false,
              save_note_button_loading: false,
              is_search: false,
              direct_to_list: false,
            };
        case InventoryCheckActionType.GET_INVENTORY_CHECK:
            products = []
            let total_quantity = 0, total_quantity_diff = 0, total_amount = 0, total_amount_diff = 0, total_product = 0
            action.inventory_checks.products.forEach(function(item){
              let key = item.id + '_' + item.product_option_id
              let quantity_diff = item.real_quantity - item.remain_quantity
              let price_suggest = parseFloat(item.retail)
              if(!(price_suggest > 0) && item.price_suggest > 0){
                price_suggest = item.price_suggest
              }
              total_quantity += item.real_quantity
              total_quantity_diff += quantity_diff
              total_amount += price_suggest * item.real_quantity
              total_amount_diff += price_suggest * quantity_diff
              total_product++
              products.push({
                  "key": key,
                  "id": item.product_id,
                  "product_option_id": item.product_option_id,
                  "product_option_name": item.product_option_name,
                  "url": item.product_url,
                  "name": item.product_name,
                  "model": item.product_model,
                  "sku": item.product_sku,
                  "price": item.retail,
                  "price_suggest": price_suggest,
                  "quantity": item.real_quantity,
                  "total_amount": item.real_quantity * price_suggest,
                  "total_amount_diff": quantity_diff * price_suggest,
                  "remain": item.remain_quantity,
                  "quantity_diff": quantity_diff,
                  "unit": item.unit,
                  "categories": item.categories,
                  "category_id": item.category_id,
                  "category_name": item.category_name,
                  "brand_id": item.brand_id,
                  "brand_name": item.brand_name,
                  "is_gift": item.is_gift,
              })
            })
            return {  
                ...state,
                ...action.inventory_checks,
                products,
                total_quantity,
                total_quantity_diff,
                total_amount,
                total_amount_diff,
                total_product
            };
        case InventoryCheckActionType.GET_STAFF_NOTES_INVENTORY_CHECK:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };       
        case InventoryCheckActionType.GET_LAST_STAFF_NOTES_INVENTORY_CHECK:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };    
        case InventoryCheckActionType.LIST_INVENTORY_CHECK:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                list: [...action.inventory_checks],
                direct_to_list: false
              };
        case InventoryCheckActionType.SEARCH_INVENTORY_CHECK:
            return {
                ...state,
                is_search: true,
                search_button_loading: action.search_button_loading
            };
        case InventoryCheckActionType.LIST_INVENTORY_CHECK_STATUS:
            return {
                ...state,
                import_receipt_status_select: action.status
              };
        case InventoryCheckActionType.CLEAR_PRODUCTS_INVENTORY_CHECK:
          return {
            ...state,
            products: []
          };
        case InventoryCheckActionType.SELECT_PRODUCT_INVENTORY_CHECK:
            products = state.products;
            let itemSelected = action.item
            if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
              let key = itemSelected.id + '_' + itemSelected.product_option_id
              let index = Helper.findIndexByKey(products, key);
              if(index > -1){
                products[index].quantity = parseInt(products[index].quantity) + 1
                products[index].quantity_diff = products[index].quantity - products[index].remain
                products[index].total_amount = products[index].price * products[index].quantity
                products[index].total_amount_diff = products[index].price * products[index].quantity_diff
              } else {
                  let quantity = 1
                  let quantity_diff = quantity - itemSelected.remain
                  products.unshift({
                      "key": key,
                      "id": itemSelected.id,
                      "product_option_id": itemSelected.product_option_id,
                      "product_option_name": itemSelected.product_option_name,
                      "url": itemSelected.url,
                      "name": itemSelected.name,
                      "model": itemSelected.model,
                      "sku": itemSelected.sku,
                      "price": itemSelected.retail,
                      "quantity": quantity,
                      "discount_value": 0,
                      "discount": 0,
                      "discount_mode": 1,
                      "total_amount": itemSelected.retail * quantity,
                      "total_amount_diff": itemSelected.retail * quantity_diff,
                      "remain": itemSelected.remain,
                      "quantity_diff": quantity_diff,
                      "unit": itemSelected.unit,
                      "categories": itemSelected.categories,
                      "category_id": itemSelected.category_id,
                      "category_name": itemSelected.category_name,
                      "brand_id": itemSelected.brand_id,
                      "brand_name": itemSelected.brand_name,
                      "is_gift": itemSelected.is_gift,
                  })
              }
          }
          return {
            ...state,
            products: [...products]
          }; 
        case InventoryCheckActionType.UPDATE_PRODUCTS_INVENTORY_CHECK: 
            return {
              ...state,
              products: [...action.products]
            };   
        case InventoryCheckActionType.UPDATE_PRODUCT_INVENTORY_CHECK: 
        //console.log("action.key: ",action.key)
          //  console.log("state.products: ",state.products)
            index = Helper.findIndexByKey(state.products, action.key);
            if (index > -1){
              let product = state.products[index]
              if (action.input_name === "quantity"){
                product.quantity = action.value
              } else if (action.input_name === "price_suggest"){
                console.log("action.value: ", action.value)
                product.price_suggest = action.value
              }
              product.quantity_diff = product.quantity - product.remain
              product.total_amount = product.price_suggest * product.quantity
              product.total_amount_diff = product.price_suggest * product.quantity_diff
              state.products[index] = product
            }
            return {
              ...state
            };   
        case InventoryCheckActionType.SUM_TOTAL_INVENTORY_CHECK:
            state.total_amount = 0
            state.total_amount_diff = 0
            state.total_quantity = 0
            state.total_quantity_diff = 0
            state.total_product = 0
            if(state.products.length > 0){
              let total_amount = 0, total_amount_diff = 0, total_quantity = 0, total_quantity_diff  = 0, total_product = 0
              state.products.forEach(function(item){
                total_amount += item.total_amount
                total_amount_diff += item.total_amount_diff
                total_quantity += parseInt(item.quantity)
                total_quantity_diff += parseInt(item.quantity_diff)
                total_product++
              });
              state.total_amount = total_amount
              state.total_amount_diff = total_amount_diff
              state.total_quantity = total_quantity
              state.total_quantity_diff  = total_quantity_diff
              state.total_product = total_product
            }
            return {
              ...state
            };  
        case InventoryCheckActionType.RESET_FLAG_SEARCH_INVENTORY_CHECK:
          return {
            ...state,
            is_search: action.status
          };       
        case InventoryCheckActionType.DIRECT_TO_LIST_INVENTORY_CHECK:
          return {
            ...state,
            direct_to_list: action.status
          };     
        default: 
            return state;
    }
}
export default inventory_check;