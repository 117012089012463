export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const LIST_USER = 'LIST_USER';
export const RESET_LIST_USER = 'RESET_LIST_USER';
export const LIST_USER_STATUS = 'LIST_USER_STATUS';
export const SEARCH_USER = 'SEARCH_USER';
export const SHOW_HIDE_FIELD_ADD_NEW_USER = 'SHOW_HIDE_FIELD_ADD_NEW_USER';
export const FIELD_CHANGE_USER = 'FIELD_CHANGE_USER';
export const RESET_FIELD_USER = 'RESET_FIELD_USER';
export const RESET_FIELD_SEARCH_USER = 'RESET_FIELD_SEARCH_USER';
export const FOCUS_FIELD_USER = 'FOCUS_FIELD_USER';
export const ERROR_FIELD_USER = 'ERROR_FIELD_USER';
export const BUTTON_SPINNER_USER = 'BUTTON_SPINNER_USER';
export const ITEM_BUTTON_SPINNER_USER = 'ITEM_BUTTON_SPINNER_USER';
export const DIRECT_TO_LIST_USER = 'DIRECT_TO_LIST_USER';
export const RESET_DIRECT_USER = 'RESET_DIRECT_USER';
export const LIST_CUSTOMER_GROUP = 'LIST_CUSTOMER_GROUP';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const GET_STAFF_POSITION_USER = 'GET_STAFF_POSITION_USER';

export const STAFF_USER_TYPE = 1
export const CUSTOMER_USER_TYPE = 2
export const SUPPLIER_USER_TYPE = 3
export const TRANSPORTER_USER_TYPE = 4
export const PAYMENT_OBJECT_USER_TYPE = 5