import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toggle, Whisper, Popover, Icon, SelectPicker, Alert } from 'rsuite';
import Helper from '../../utils/Helper';
import * as InvoiceAction from '../../actions/InvoiceAction';
import * as InvoiceActionType from '../../constants/InvoiceActionType';
import * as UserActionType from '../../constants/UserActionType';
import * as AddressBookAction from '../../actions/AddressBookAction';
import ZoneSelectBox from '../shared/ZoneSelectBox';
import UserSelectBox from '../shared/UserSelectBox';
import LaddaButton, { ZOOM_IN, L } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2/dist/sweetalert2';
const triggerRef = React.createRef();
var timeoutSearchAddressBook = null
class InvoiceShipping extends Component {
    constructor() {
        super();
        this.state = { 
            cod_amount: 0,
            show_shipping_form: false,
            receiver_name_active: false,
            receiver_name_error: false,
            receiver_phone_active: false,
            receiver_phone_error: false,
            receiver_address_active: false,
            receiver_address_error: false
         };
         this.onOpenAddressBook = this.onOpenAddressBook.bind(this);
         this.onSearchAddressBook = this.onSearchAddressBook.bind(this);
         this.onSelectAddressBook = this.onSelectAddressBook.bind(this);
    }
    componentWillUnmount() {
        if(this.props.getRef !== undefined){
            this.props.getRef(undefined)
        }
    }
    componentDidMount(){
        if(this.props.getRef !== undefined){
            this.props.getRef(this)
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.receiver_name_error!==prevState.receiver_name_error){
          return { receiver_name_error: nextProps.receiver_name_error};
        } else if(nextProps.receiver_phone_error!==prevState.receiver_phone_error){
          return { receiver_phone_error: nextProps.receiver_phone_error};
        } else if(nextProps.receiver_address_error!==prevState.receiver_address_error){
          return { receiver_address_error: nextProps.receiver_address_error};
        }
        return null;
    }
      
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.receiver_name_error!==this.props.receiver_name_error){
            if(this.props.receiver_name_error === true){
                this.receiver_name_input.focus()
            }
            this.setState({receiver_name_error: this.props.receiver_name_error, receiver_name_active: this.props.receiver_name_error});
        
        } else if(prevProps.receiver_phone_error!==this.props.receiver_phone_error){
            if(this.props.receiver_phone_error === true){
                this.receiver_phone_input.focus()
            }
            this.setState({receiver_phone_error: this.props.receiver_phone_error, receiver_phone_active: this.props.receiver_phone_error});
        
        } else if(prevProps.receiver_address_error!==this.props.receiver_address_error){
            if(this.props.receiver_address_error === true){
                this.receiver_address_input.focus()
            }
            this.setState({receiver_address_error: this.props.receiver_address_error, receiver_address_active: this.props.receiver_address_error});
        }
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.setState({
            [field_active]: true
        })
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.setState({
            [field_active]: is_focus
        })
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        } 
        if(name === "weight"){
            value = Helper.removeMoneyText(value)
            let weight = 0, weight_kg = 0
            if (this.props.invoice.weight_unit.value === InvoiceActionType.WEIGHT_UNIT_KG) {
               // console.log("value kg: ",value)
                weight_kg = value
                weight = value * 1000
            } else {
                weight = value
                weight_kg = Math.fround(value / 1000, 2)
            }
            this.props.onFieldChange("weight",weight);
            this.props.onFieldChange("weight_kg",weight_kg);
        } else {
            this.props.onFieldChange(name,value);
        }
    }
    onCODModeChange(checked){
        this.props.onFocusField("has_cod", checked)
    }
    onOpenAddressBook(){
        if (this.props.invoice.customer_selected.id > 0){
            this.props.onSearchAddressBook({
                "user_id": this.props.invoice.customer_selected.id,
            }, 10)
        }
    }
    onSearchAddressBook(keyword){
        let _this = this
        if(timeoutSearchAddressBook !== null){
            clearTimeout(timeoutSearchAddressBook)
        }
        if (!keyword || _this.props.invoice.customer_selected.id === 0) {
          return;
        }
        timeoutSearchAddressBook = setTimeout(() => {
            var data_search = {
                "keyword": keyword,
                "user_id": _this.props.invoice.customer_selected.id,
            }
            _this.props.onSearchAddressBook(data_search, _this.props.address_book.limit)
        }, 700);
    }
    onSelectAddressBook(_,item){
        let _this = this
        let value = {}
        if(item !== null){
            value = item
        }
        let receiver_name = "", receiver_phone = "" ,receiver_address = ""
        let receiver_city_selected = {}, receiver_district_selected = {}, receiver_ward_selected = {}
        console.log("value: ",value)
        if(value.city !== undefined){
            receiver_name = value.name
            receiver_phone = value.phone
            receiver_address = value.address
            receiver_city_selected = {
                label : value.city_name,
                value: value.city
            }
            receiver_district_selected = {
                label: value.district_name,
                value: value.district
            }
            receiver_ward_selected = {
                label: value.ward_name,
                value: value.ward
            }
        }
        _this.props.onFieldsChange({
            address_book_selected: value,
            receiver_name,
            receiver_phone,
            receiver_address,
            receiver_city_selected,
            receiver_district_selected,
            receiver_ward_selected
        });
        setTimeout(() => {
            _this.zoneSelectBoxRef.onSetZone({
                city: receiver_city_selected,
                district: receiver_district_selected,
                ward: receiver_ward_selected
            })
        },100)
    }
    onCloneReceiverInfoModeChange(){
        let _this = this
        if(_this.props.invoice.receiver_name === ""){
            let receiver_name = _this.props.invoice.customer_selected.name
            let receiver_phone = _this.props.invoice.customer_selected.phone
            let receiver_address = _this.props.invoice.customer_selected.address
            let receiver_city_selected = {
                label : _this.props.invoice.customer_selected.city_name,
                value: _this.props.invoice.customer_selected.city
            }
            let receiver_district_selected = {
                label: _this.props.invoice.customer_selected.district_name,
                value: _this.props.invoice.customer_selected.district
            }
            let receiver_ward_selected = {
                label: _this.props.invoice.customer_selected.ward_name,
                value: _this.props.invoice.customer_selected.ward
            }
            _this.props.onFieldsChange({
                receiver_name,
                receiver_phone,
                receiver_address,
                receiver_city_selected,
                receiver_district_selected,
                receiver_ward_selected
            });
            setTimeout(() => {
                _this.zoneSelectBoxRef.onSetZone({
                    city: receiver_city_selected,
                    district: receiver_district_selected,
                    ward: receiver_ward_selected
                })
            },100)
        }
    }
    onSetShippingCOD(cod_amount){
        triggerRef.current.open();
        this.setState({
            show_shipping_form: true,
            cod_amount
        })
        this.props.onFieldChange("has_cod",true);
    }
    onShippingModeChange(checked){
        if (checked === true){
            triggerRef.current.open();
        } else{
            triggerRef.current.close();
        }
        this.setState({
            show_shipping_form: checked
        })
        this.props.onFieldChange("has_shipping",checked);
        this.onCloneReceiverInfoModeChange()
    }
    onShippingModeClick(show){
        if (show === true){
            triggerRef.current.open();
        } else{
            triggerRef.current.close();
        }
    }
    onCityChange(city){
        this.props.onFieldChange("receiver_city_selected",city);
    }
    onDistrictChange(district){
        this.props.onFieldChange("receiver_district_selected",district);
    }
    onWardChange(ward){
        this.props.onFieldChange("receiver_ward_selected",ward);
    }
    onSelectWeightUnit(item){
        this.props.onFieldChange("weight_unit",item);
    }
    onUserFieldChange(item){
        this.props.onFieldChange("transporter_selected",item);
    }
    onSelectShipStatus(item){
        let color = ""
        if(this.props.invoice.receipt_bill_ship_status_color_select.length > 0){
            let color_array = this.props.invoice.receipt_bill_ship_status_color_select
            for (let i = 0; i < color_array.length; i++) {
                if (color_array[i].value === item.value){
                    color = color_array[i].label
                    break;
                }
            }
        }
        this.props.onFieldChange("receipt_bill_ship_status", item.value)
        this.props.onFieldChange("receipt_bill_ship_status_text", item.label)
        this.props.onFieldChange("receipt_bill_ship_status_color", color)
        if (this.props.invoice.id > 0){
            this.props.onSaveShipStatus(parseInt(item.value), this.props.invoice.id)
        }
    }
    onRemoveShippingInfo(){
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn thật sự muốn bỏ giao hàng này?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            confirmButtonText: 'ĐỒNG Ý',
            cancelButtonText: 'KHÔNG',
            preConfirm: () => {
                this.props.onFieldsChange({
                    has_shipping: false,
                    has_cod: false,
                    transporter_selected: [],
                    transporter_name: "",
                    transporter_bill_number: "",
                    receiver_name: "", 
                    receiver_phone: "", 
                    receiver_address: "", 
                    receiver_city_selected: [], 
                    receiver_district_selected: [], 
                    receiver_ward_selected: [], 
                    weight: 0,
                    weight_kg: 0,
                    weight_unit: {
                        label: "gram",
                        value: InvoiceActionType.WEIGHT_UNIT_GRAM
                    },
                    length: 0,
                    width: 0,
                    height: 0,
                    pick_up_address: [],
                    delivery_type: 0,
                    delivery_type_text: "",
                    delivery_check_type: 0,
                    delivery_check_type_text: "",
                    receiver_pay_fee: 0,
                    receipt_bill_ship_status: InvoiceActionType.SHIP_STATUS_IN_PROGRESS_INVOICE
                });

                this.zoneSelectBoxRef.onSetZone({
                    city: [],
                    district: [],
                    ward: []
                })

                this.props.onSubmitShipInfo({
                    money_collect_cod: 0,
                    transporter_id: 0,
                    transporter_name: '',
                    transporter_bill_number: '',
                    receiver_name: '',
                    receiver_phone: '',
                    receiver_address: '',
                    receiver_city: 0,
                    receiver_district: 0,
                    receiver_ward: 0,
                    weight: 0,
                    weight_kg: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    delivery_type: 0,
                    delivery_check_type: 0,
                    receiver_pay_fee: 0
                }, this.props.invoice.id)


                let _this = this
                setTimeout(() => {
                    _this.onShippingModeClick(false)
                },400)
            },
        })
    }
    onSaveShippingInfo(){
        let {transporter_selected, transporter_bill_number, receiver_name, receiver_phone, receiver_address, receiver_city_selected, receiver_district_selected, receiver_ward_selected, receiver_pay_fee} = this.props.invoice
        receiver_pay_fee = parseInt(receiver_pay_fee)
        let data_post = {}
        let money_collect_cod = 0
        if (this.props.invoice.has_cod === true){
            if(this.state.cod_amount > 0){
                money_collect_cod = this.state.cod_amount
            } else if(this.props.cod_amount > 0){
                money_collect_cod = this.props.cod_amount
            }
        }
        let receiver_city = 0, receiver_district = 0, receiver_ward = 0, transporter_id = 0, is_staff_transporter = 0
        let receiver_city_name = "", receiver_district_name = "", receiver_ward_name = "", transporter_name = ""
        let has_shipping_error = false
        let error_msg = "", field_error = ""
        if(this.props.allow_update === true){
            if (receiver_name === ""){
                error_msg = 'Vui lòng nhập tên người nhận'
                field_error = "receiver_name_error"
                has_shipping_error = true
            } else if (receiver_phone === ""){
                error_msg = 'Vui lòng nhập số điện thoại người nhận'
                field_error = "receiver_phone_error"
                has_shipping_error = true
            } else if (receiver_address === ""){
                error_msg = 'Vui lòng nhập địa chỉ người nhận'
                field_error = "receiver_address_error"
                has_shipping_error = true
            } else if (receiver_city_selected.value === undefined){
                error_msg = 'Vui lòng chọn tỉnh/thành phố'
                has_shipping_error = true
            } else if (receiver_district_selected.value === undefined){
                error_msg = 'Vui lòng chọn quận/huyện'
                has_shipping_error = true
            } else if (receiver_ward_selected.value === undefined){
                error_msg = 'Vui lòng chọn phường/xã'
                has_shipping_error = true
            } else if (transporter_selected.value === undefined){
                error_msg = 'Vui lòng chọn đối tác giao hàng'
                has_shipping_error = true
            }
            if(has_shipping_error === true){
                Alert.error(error_msg)
                let _this = this
                setTimeout(function(){
                    if(field_error !== ""){
                        _this.props.onErrorField(field_error,true)
                    }
                },250)
                return;
            }
            receiver_city = parseInt(receiver_city_selected.value)
            receiver_city_name = receiver_city_selected.label
            receiver_district = parseInt(receiver_district_selected.value)
            receiver_district_name = receiver_district_selected.label
            receiver_ward = parseInt(receiver_ward_selected.value)
            receiver_ward_name = receiver_ward_selected.label
            transporter_id = parseInt(transporter_selected.value)
            transporter_name = transporter_selected.label
            is_staff_transporter = parseInt(transporter_selected.is_staff_transporter)
            data_post = {
                money_collect_cod: parseFloat(money_collect_cod),
                transporter_id,
                transporter_name,
                is_staff_transporter,
                transporter_bill_number,
                receiver_name,
                receiver_phone,
                receiver_address,
                receiver_city,
                receiver_city_name,
                receiver_district,
                receiver_district_name,
                receiver_ward,
                receiver_ward_name,
                weight: parseFloat(this.props.invoice.weight),
                weight_kg: parseFloat(this.props.invoice.weight_kg),
                length: parseFloat(this.props.invoice.length),
                width: parseFloat(this.props.invoice.width),
                height: parseFloat(this.props.invoice.height),
                delivery_type: 0,
                delivery_check_type: 0,
                receiver_pay_fee: receiver_pay_fee
            }
        } else if (this.props.allow_update_cod === true){
            data_post = {
                money_collect_cod: parseFloat(money_collect_cod),
            }
        }
        //console.log("this.props.invoice.has_cod: ",this.props.invoice.has_cod)
        if (this.props.invoice.id > 0){
            //console.log("money_collect_cod: ",money_collect_cod)
            this.props.onSubmitShipInfo(data_post, this.props.invoice.id).then(result => {
                if(result.status === true){
                    this.props.onFieldChange("has_shipping", true)
                    Alert.success("Thông tin giao hàng đã lưu thành công")
                    this.props.refresh_info()
                } else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        }
    }
    render(){
        let { receiver_name, receiver_phone, receiver_address, receiver_city_selected, receiver_district_selected, receiver_ward_selected, has_shipping, has_real_ship_info, has_cod, weight, weight_kg, weight_unit, weight_active, length, length_active, width, width_active, height, height_active , transporter_selected, transporter_bill_number, transporter_bill_number_active, transporter_bill_number_error, receiver_pay_fee } = this.props.invoice
        let { receiver_name_active, receiver_name_error, receiver_phone_active, receiver_phone_error, receiver_address_active, receiver_address_error } = this.state
        receiver_pay_fee = parseInt(receiver_pay_fee)
        let receiver_name_field_class = "nk-int-st", receiver_phone_field_class = "nk-int-st", receiver_address_field_class = "nk-int-st", weight_field_class = "nk-int-st", length_field_class = "nk-int-st", width_field_class = "nk-int-st", height_field_class = "nk-int-st", transporter_bill_number_field_class = "nk-int-st"
        if (receiver_name !== "") {
            receiver_name_active = true;
        }
        if(receiver_name_active === true){
            receiver_name_field_class += ' nk-toggled';
        }
        if(receiver_name_error === true){
            receiver_name_field_class += ' error';
        }
        if (receiver_phone !== "") {
            receiver_phone_active = true;
        }
        if(receiver_phone_active === true){
            receiver_phone_field_class += ' nk-toggled';
        }
        if(receiver_phone_error === true){
            receiver_phone_field_class += ' error';
        }
        if (receiver_address !== "") {
            receiver_address_active = true;
        }
        if(receiver_address_active === true){
            receiver_address_field_class += ' nk-toggled';
        }
        if(receiver_address_error === true){
            receiver_address_field_class += ' error';
        }
        if (weight > 0) {
            weight_active = true;
        }
        if(weight_active === true){
            weight_field_class += ' nk-toggled';
        }
        let weight_unit_array = [
            {
                label: "gram",
                value: InvoiceActionType.WEIGHT_UNIT_GRAM
            },
            {
                label: "kg",
                value: InvoiceActionType.WEIGHT_UNIT_KG
            }
        ]
        let weight_for_unit = weight
        if(weight_unit.value === InvoiceActionType.WEIGHT_UNIT_KG){
            weight_for_unit = weight_kg
        }
        let weight_for_unit_text = ""
        if (weight_for_unit > 0){
            weight_for_unit_text = Helper.parseMoneyText(weight_for_unit)
        }
        let length_text = "", width_text = "", height_text = ""
        if (length > 0) {
            length_active = true;
            length_text = Helper.parseMoneyText(length)
        }
        if(length_active === true){
            length_field_class += ' nk-toggled';
        }
        if (width > 0) {
            width_active = true;
            width_text = Helper.parseMoneyText(width)
        }
        if(width_active === true){
            width_field_class += ' nk-toggled';
        }
        if (height > 0) {
            height_active = true;
            height_text = Helper.parseMoneyText(height)
        }
        if(height_active === true){
            height_field_class += ' nk-toggled';
        }
        if (transporter_bill_number !== "") {
            transporter_bill_number_active = true;
        }
        if(transporter_bill_number_active === true){
            transporter_bill_number_field_class += ' nk-toggled';
        }
        if(transporter_bill_number_error === true){
            transporter_bill_number_field_class += ' error';
        }
        let disabled_cod_mode = false
        if (this.props.cod_amount === 0 && this.state.cod_amount === 0){
            disabled_cod_mode = true
        }
        if (this.props.allow_update_cod === false){
            disabled_cod_mode = true
        }
        let cod_amount = ""
        if(this.state.cod_amount > 0){
            cod_amount = Helper.parseMoneyText(this.state.cod_amount)
        } else if(this.props.cod_amount > 0){
            cod_amount = Helper.parseMoneyText(this.props.cod_amount)
        }
        let zone_text = ""
        if (receiver_ward_selected.label !== undefined && receiver_ward_selected.label !== null && receiver_ward_selected.label !== ""){
            zone_text = receiver_ward_selected.label +  ", "
        }
        if (receiver_district_selected.label !== undefined && receiver_district_selected.label !== null && receiver_district_selected.label !== ""){
            zone_text += receiver_district_selected.label +  ", "
        }
        if (receiver_city_selected.label !== undefined && receiver_city_selected.label !== null && receiver_city_selected.label !== ""){
            zone_text += receiver_city_selected.label
        }
        let ship_status_array = []
        if(this.props.invoice.receipt_bill_ship_status_select.length > 0){
            ship_status_array = this.props.invoice.receipt_bill_ship_status_select
        }
        let receiver_pay_fee_text = ""
        if (receiver_pay_fee > 0) {
            if(receiver_pay_fee === InvoiceActionType.SENDER_PAY_SHIP_FEE){
                receiver_pay_fee_text = "Moby trả"
            } else if(receiver_pay_fee === InvoiceActionType.RECEIVER_PAY_SHIP_FEE){
                receiver_pay_fee_text = "Khách trả"
            }
        }
        let address_book_value_selected = 0
        let address_books_array = []
        if(this.props.address_book.list !== undefined && this.props.address_book.list !== null && this.props.address_book.list.length > 0){
            if(this.props.invoice.address_book_selected.value !== undefined && this.props.invoice.address_book_selected.value > 0){
                address_book_value_selected = this.props.invoice.address_book_selected.value
            }
            this.props.address_book.list.forEach(item => {
                let address_full = item.address
                if(item.ward_name !== ""){
                    address_full += ", " + item.ward_name
                }
                if(item.district_name !== ""){
                    address_full += ", " + item.district_name
                }
                if(item.city_name !== ""){
                    address_full += ", " + item.city_name
                }
                address_books_array.push({  
                    ...item,
                    label: item.name + " / " + item.phone + " / " + address_full,
                    value: item.id,
                })
            })
            
        }
        const speaker = (
            <Popover className="shippingInfo" title="CHI TIẾT ĐƠN GIAO HÀNG" style={{minWidth: 800}}>
                <div className="row">
                    <div className="shippingInfo__close">
                        <Icon className="cl_handover" icon='close' size="lg" onClick={ (e) => { this.onShippingModeClick(false) }} />
                    </div>
                    <div className="col-xs-6">
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Thu hộ tiền (COD):</label>
                                </div>
                                <div className="shippingInfo__right shippingInfo__right--text">
                                    { this.props.allow_update_cod === true ?
                                    <Toggle name="cod_mode" size="lg" checkedChildren={ cod_amount } unCheckedChildren={ cod_amount } onChange={ this.onCODModeChange.bind(this) } checked={has_cod} disabled={disabled_cod_mode} />
                                    : <div className="mr-top-5"><NumberFormat value={this.props.cod_amount} displayType={'text'} thousandSeparator={true} /></div> }
                                </div>
                            </div>
                        </div>
                        { this.props.allow_update === true ?
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Sổ địa chỉ:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    <SelectPicker
                                        style={{width: 263}}
                                        block={true}
                                        placeholder="Chọn địa chỉ"
                                        cleanable={false}
                                        data={address_books_array}
                                        value={address_book_value_selected}
                                        onOpen={this.onOpenAddressBook}
                                        onSearch={this.onSearchAddressBook}
                                        onSelect={this.onSelectAddressBook}
                                        renderMenu={menu => {
                                            if (address_books_array.length === 0) {
                                                return (
                                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                    <Icon icon="spinner" spin />
                                                </p>
                                                );
                                            }
                                            return menu;
                                        }}
                                    />
                                </div>
                            </div>
                        </div> : "" }
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Tên người nhận:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ?
                                    <div className="float-lb floating-lb">
                                        <div className={ receiver_name_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="receiver_name" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.receiver_name_input = input; }} value={receiver_name} placeholder="Nhập tên người nhận" disabled={!this.props.allow_update}></input>
                                        </div>
                                    </div>
                                    : <div className="mr-top-5">{receiver_name}</div> }
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Số điện thoại:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ?
                                    <div className="float-lb floating-lb">
                                        <div className={ receiver_phone_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="receiver_phone" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.receiver_phone_input = input; }} value={receiver_phone} placeholder="Nhập số điện thoại người nhận" disabled={!this.props.allow_update}></input>
                                        </div>
                                    </div>
                                    : <div className="mr-top-5">{receiver_phone}</div> }
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Địa chỉ nhận:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ?
                                    <div className="float-lb floating-lb">
                                        <div className={ receiver_address_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="receiver_address" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.receiver_address_input = input; }} value={receiver_address} placeholder="Nhập địa chỉ người nhận" disabled={!this.props.allow_update}></input>
                                        </div>
                                    </div>
                                    : <div className="mr-top-5">{receiver_address}</div> }
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Khu vực:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ?
                                    <ZoneSelectBox appearance="subtle" allow_update={this.props.allow_update} city={receiver_city_selected} district={receiver_district_selected} ward={receiver_ward_selected} onCityChange={(city) => { this.onCityChange(city) }} onDistrictChange={(district) => { this.onDistrictChange(district) }} onWardChange={(ward) => { this.onWardChange(ward) }} getRef={ref => { this.zoneSelectBoxRef = ref }  } />
                                    : <div className="mr-top-5">{ zone_text }</div> }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        { this.props.allow_update === true ? 
                        <div className="form-group">
                            <UserSelectBox placeholder="Tìm đối tác giao hàng" allow_update={this.props.allow_update} user_type={UserActionType.TRANSPORTER_USER_TYPE} use_for_create={true} user_selected={transporter_selected} has_add_new={true} modal_inside={false} onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                        </div> 
                            : 
                            <div className="form-group">
                                <div className="shippingInfo__item">
                                    <div className="shippingInfo__left shippingInfo__left--top">
                                        <label className="hrzn-fm">Đối tác VC:</label>
                                    </div>
                                    <div className="shippingInfo__right">
                                        <div className="mr-top-5">{transporter_selected.label}</div>
                                    </div>
                                </div>    
                            </div>    
                            }
                        { (this.props.allow_update === true || transporter_bill_number !== "") ? 
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Mã bill:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ? 
                                    <div className="float-lb floating-lb">
                                        <div className={ transporter_bill_number_field_class }>
                                            <input type="text" className="form-control" autoComplete="off" name="transporter_bill_number" onFocus={ (e) => { this.onFieldFocus(e) } } onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.transporter_bill_number_input = input; }} value={transporter_bill_number} placeholder="Nhập mã bill đối tác giao hàng" disabled={!this.props.allow_update}></input>
                                        </div>
                                    </div> : <div className="mr-top-5">{transporter_bill_number}</div> }
                                </div>
                            </div>
                        </div> : "" }
                        { (this.props.allow_update === true || weight_for_unit_text !== "") ? 
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Trọng lượng:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ? 
                                    <div>
                                        <div className="pull-left">
                                            <div className="float-lb floating-lb">
                                                <div className={ weight_field_class }>
                                                    <input type="text" className="form-control text-right" autoComplete="off" name="weight" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.weight_input = input; }} value={weight_for_unit_text} placeholder="Cân nặng hàng gửi" disabled={!this.props.allow_update}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pull-left mr-left-5">
                                            <SelectPicker
                                                block
                                                searchable={false}
                                                cleanable={false}
                                                placeholder="gram"
                                                appearance="subtle"
                                                data={weight_unit_array}
                                                value={weight_unit}
                                                onSelect={this.onSelectWeightUnit.bind(this)}
                                                disabled={!this.props.allow_update}
                                            />
                                        </div>
                                        <div className="cl"></div>
                                    </div>
                                    : <div className="mr-top-5">{ weight_for_unit_text !== "" ? <span>{weight_for_unit_text} { weight_unit.label}</span> : "" }</div> }
                                </div>
                            </div>
                        </div> : "" }
                        { (this.props.allow_update === true || length_text !== "") ? 
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top">
                                    <label className="hrzn-fm">Kích thước:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ? 
                                        <div>
                                            <div className="pull-left w-30">
                                                <div className="float-lb floating-lb">
                                                    <div className={ length_field_class }>
                                                        <input type="text" className="form-control text-right" autoComplete="off" name="length" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.length_input = input; }} value={length_text} placeholder="Chiều dài" disabled={!this.props.allow_update}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pull-left mr-left-5 w-30">
                                                <div className="float-lb floating-lb">
                                                    <div className={ width_field_class }>
                                                        <input type="text" className="form-control text-right" autoComplete="off" name="width" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.width_input = input; }} value={width_text} placeholder="Chiều rộng" disabled={!this.props.allow_update}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pull-left mr-left-5 w-30">
                                                <div className="float-lb floating-lb">
                                                    <div className={ height_field_class }>
                                                        <input type="text" className="form-control text-right" autoComplete="off" name="height" onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } ref={(input) => { this.height_input = input; }} value={height_text} placeholder="Chiều cao" disabled={!this.props.allow_update}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pull-left mr-left-5 mr-top-7 w-2">
                                                <strong>cm</strong>
                                            </div>
                                            <div className="cl"></div>
                                        </div>
                                    : <div className="mr-top-5">{ length_text !== "" ? <span>{length_text}x{width_text}x{height_text} cm</span> : "" }</div> }   
                                </div>
                            </div>
                        </div> : "" }
                        { (this.props.allow_update === true || receiver_pay_fee_text !== "") ? 
                        <div className="form-group">
                            <div className="shippingInfo__item">
                                <div className="shippingInfo__left shippingInfo__left--top mr-top-10">
                                    <label className="hrzn-fm">Trả phí vận chuyển:</label>
                                </div>
                                <div className="shippingInfo__right">
                                    { this.props.allow_update === true ? 
                                    <div>
                                        <div className="pull-left">
                                            <div className="radio"><label><input type="radio" name="receiver_pay_fee" id="receiver_pay_fee_1" value={2} onChange={(e) => { this.onFieldChange(e) }} checked={(receiver_pay_fee === 2 ? true : false)} />&nbsp;Khách trả</label></div>
                                        </div>
                                        <div className="pull-left mr-left-10">
                                            <div className="radio"><label><input type="radio" name="receiver_pay_fee" id="receiver_pay_fee_2" value={1} onChange={(e) => { this.onFieldChange(e) }} checked={(receiver_pay_fee === 1 ? true : false)}  />&nbsp;Moby trả</label></div>
                                        </div>
                                        <div className="cl"></div>
                                    </div> : 
                                    <div className="mr-top-5">{ receiver_pay_fee_text }</div>
                                    }
                                </div>
                            </div>
                        </div> : "" }
                    </div>
                    { (this.props.in_view === true && (this.props.allow_update === true || this.props.allow_update_cod === true)) ? 
                    <div className="col-xs-12 text-center">
                            { has_real_ship_info === true && this.props.allow_update === true ? 
                            <button className="btn btn-danger mr-right-5" onClick={ () => { this.onRemoveShippingInfo() } } >
                                <span style={{fontSize: "1.2em"}}><i className="fa fa-remove"></i>&nbsp;Bỏ giao hàng</span>
                            </button>: "" } 
                            <LaddaButton
                                loading={ this.props.invoice.save_ship_button_loading }
                                data-size={L}
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-info" onClick={ () => { this.onSaveShippingInfo() } } >
                                <i className="fa fa-save"></i>&nbsp;Lưu thông tin
                            </LaddaButton>
                    </div> : "" 
                    }
                </div>
            </Popover>
        );
        let html_toggle = [], html_select = [], html_button_update = []
        let has_button_update = true
        let has_customer_info = false
        if(this.props.invoice.customer_selected.id !== undefined && this.props.invoice.customer_selected.id > 0){
            has_customer_info = true
        }
        if(this.props.in_view === true && has_real_ship_info === true){
            if (this.props.bill_reorder === false || this.props.invoice.receipt_bill_ship_status === InvoiceActionType.SHIP_STATUS_DONE_INVOICE){
                html_select.push((this.props.allow_update_ship_status === true ?
                            <div key="select_bill_ship_status" className="btn-group mr-right-5">
                                <button type="button" className="btn" style={{ backgroundColor: this.props.invoice.receipt_bill_ship_status_color, fontSize: 15, color: "#FFFFFF" }}>{ this.props.invoice.receipt_bill_ship_status_text }</button>
                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: this.props.invoice.receipt_bill_ship_status_color, fontSize: 15, color: "#FFFFFF" }}>
                                    <span className="caret"></span>
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                <ul className="dropdown-menu">
                                    { ship_status_array.map((item, index) => {
                                        return <li key={index}><a href=" #" onClick={() => { this.onSelectShipStatus(item) }}>{item.label}</a></li>
                                    }) }
                                </ul>
                            </div>
                        : <span key="bill_ship_status_text" className="mr-right-5" style={{ color: this.props.invoice.receipt_bill_ship_status_color, fontSize: 15 }}>{ this.props.invoice.receipt_bill_ship_status_text }</span>))     
            }
        } else if(has_customer_info === true) {
            html_toggle.push(<Toggle key="shipping_mode_toggle" className="mr-right-5" name="shipping_mode" size="lg" checkedChildren={"Giao hàng"} unCheckedChildren={"Giao hàng"} checked={has_shipping} onChange={ this.onShippingModeChange.bind(this) } />)
            has_button_update = this.state.show_shipping_form
        }
        if (has_button_update === true && has_customer_info === true){
            html_button_update.push(<div key="shipping_button_update" className={ this.props.allow_update === true ? "pull-left mr-top-7" : "pull-left mr-top-3" }><Icon className="display-block cl_handover" icon={this.props.allow_update === false ? "exclamation-circle2" : "edit"} size="lg" onClick={ (e) => { this.onShippingModeClick(true) } } /></div>)
        }
        return (
            <Whisper
                placement={ this.props.in_view === true ? "bottom" : "leftStart" }
                ref={triggerRef}
                trigger='none'
                speaker={speaker} >
                    <div>
                        <div className="pull-left">
                            { html_select }
                            { html_toggle }
                        </div>
                        { html_button_update }
                        <div className="cl"></div>
                    </div>
            </Whisper>
        )
    }
}
InvoiceShipping.defaultProps = {
    allow_update: true,
    allow_update_cod: true,
    allow_update_ship_status: true,
    bill_reorder: false,
}
const mapStateToProps = state => {
    return {
        invoice: state.invoice,
        user: state.user,
        address_book: state.address_book
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(InvoiceAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InvoiceAction.focusField(field,value))
        },
        onFieldsChange(fields){
            dispatch(InvoiceAction.fieldsChange(fields))
        },
        onFieldChange(field,value){
            dispatch(InvoiceAction.fieldChange(field,value))
        },
        onSaveShipStatus: (value, id) => {
            dispatch(InvoiceAction.updateShipStatusRequest(value, id))
        },
        onSubmitShipInfo(data_post, id){
            return dispatch(InvoiceAction.updateRequest(data_post, id, ""))
        },
        onSearchAddressBook: (data_search,limit) => {
            dispatch(AddressBookAction.searchRequest(data_search,1,limit,false))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceShipping);