export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const GET_INVOICE = 'GET_INVOICE';
export const LIST_INVOICE = 'LIST_INVOICE';
export const LIST_INVOICE_STATUS = 'LIST_INVOICE_STATUS';
export const SEARCH_INVOICE = 'SEARCH_INVOICE';
export const SHOW_HIDE_FIELD_ADD_NEW_INVOICE = 'SHOW_HIDE_FIELD_ADD_NEW_INVOICE';
export const FIELD_CHANGE_INVOICE = 'FIELD_CHANGE_INVOICE';
export const FIELDS_CHANGE_INVOICE = 'FIELDS_CHANGE_INVOICE';
export const RESET_FIELD_SEARCH_INVOICE = 'RESET_FIELD_SEARCH_INVOICE';
export const FOCUS_FIELD_INVOICE = 'FOCUS_FIELD_INVOICE';
export const ERROR_FIELD_INVOICE = 'ERROR_FIELD_INVOICE';
export const BUTTON_SPINNER_INVOICE = 'BUTTON_SPINNER_INVOICE';
export const BUTTON_UPDATE_SPINNER_INVOICE= 'BUTTON_UPDATE_SPINNER_INVOICE';
export const ITEM_BUTTON_SPINNER_INVOICE = 'ITEM_BUTTON_SPINNER_INVOICE';
export const UPDATE_PRODUCTS_INVOICE = 'UPDATE_PRODUCTS_INVOICE';
export const UPDATE_PRODUCT_INVOICE = 'UPDATE_PRODUCT_INVOICE';
export const UPDATE_PRODUCT_RETURN_INVOICE = 'UPDATE_PRODUCT_RETURN_INVOICE';
export const SUM_TOTAL_INVOICE = 'SUM_TOTAL_INVOICE';
export const SUM_TOTAL_RETURN_INVOICE = 'SUM_TOTAL_RETURN_INVOICE';
export const CLEAR_PRODUCTS_INVOICE = 'CLEAR_PRODUCTS_INVOICE';
export const SELECT_PRODUCT_INVOICE = 'SELECT_PRODUCT_INVOICE';
export const DIRECT_TO_LIST_INVOICE = 'DIRECT_TO_LIST_INVOICE';
export const RESET_FLAG_SEARCH_INVOICE = 'RESET_FLAG_SEARCH_INVOICE';
export const EMPTY_INVOICE = 'EMPTY_INVOICE';
export const CLEAR_SHIPPING_INFO = 'CLEAR_SHIPPING_INFO';

export const GET_PAYMENT_BILLS_INVOICE = 'GET_PAYMENT_BILLS_INVOICE';
export const GET_LIABILITIES_INVOICE = 'GET_LIABILITIES_INVOICE';
export const GET_STAFF_NOTES_INVOICE = 'GET_STAFF_NOTES_INVOICE';
export const GET_LAST_STAFF_NOTES_INVOICE = 'GET_LAST_STAFF_NOTES_INVOICE';
export const GET_SHIP_LOG_INVOICE = 'GET_SHIP_LOG_INVOICE';
export const GET_SHIP_STATUS_INVOICE = 'GET_SHIP_STATUS_INVOICE';
export const GET_BILL_STATUS_INVOICE = 'GET_BILL_STATUS_INVOICE';

export const WEIGHT_UNIT_GRAM = 1;
export const WEIGHT_UNIT_KG = 2;

export const STATUS_IN_PROGRESS_INVOICE = 1;
export const STATUS_FINISH_INVOICE = 2;
export const STATUS_CANCEL_INVOICE = 4;
export const STATUS_REORDER_INVOICE = 3;
export const STATUS_RETURN_INVOICE = 5;

export const SHIP_STATUS_NEW_INVOICE = 0
export const SHIP_STATUS_IN_PROGRESS_INVOICE = 1;
export const SHIP_STATUS_DONE_INVOICE = 2;
export const SHIP_STATUS_RETURNING_INVOICE = 3;
export const SHIP_STATUS_RETURN_FINISH_INVOICE = 4;
export const SHIP_STATUS_CANCEL_INVOICE = 5;

export const SENDER_PAY_SHIP_FEE = 1
export const RECEIVER_PAY_SHIP_FEE = 2