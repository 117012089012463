import * as InventoryActionType from '../constants/InventoryActionType';
var initialState = {
    store_id: 0,
    store_code: "",
    store_name: "",
    parent_store_id: 0,
    parent_store_code: "",
    parent_store_name: "",
    total: 0,
    remain: 0,
    used: 0,
    list: []
};
const inventory = (state = initialState, action) => {
    switch(action.type){
        case InventoryActionType.PRODUCT_REMAIN_INVENTORY:
            return {
                ...state,
                list: action.list
            };
        default: 
            return state;
    }
}
export default inventory;