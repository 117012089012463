import * as ReturnInvoiceActionType from '../constants/ReturnInvoiceActionType';
import moment from 'moment';
var initialState = {
    id: 0,
    return_bill_code: "",
    return_bill_date: "",
    return_bill_notes: "",
    receipt_bill_id: 0,
    receipt_bill_code: "",
    import_receipt_id: 0,
    import_receipt_code: "",
    staff_notes_input: "",
    staff_notes_active: false,
    staff_notes_error: false,
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    customer_id: 0,
    customer_code: "",
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    customer_city: 0,
    customer_district: 0,
    customer_ward: 0,
    total_quantity: 0,
    total_amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    branch_id: 0,
    branch_name: "",
    products: [],
    staff_notes: [],
    is_cancel: 0,
    total: 0,
    limit: 15,
    total_page: 0,
    page: 1,
    list: [],
    customers: [],
    search_code: "",
    search_bill_code: "",
    search_product: "",
    search_date: [],
    branch_selected: {},
    search_button_loading: false,
    save_button_loading: false,
    save_note_button_loading: false,
    is_search: false,
    direct_to_list: false,
};
const return_invoice = (state = initialState, action) => {
    switch(action.type){
        case ReturnInvoiceActionType.BUTTON_SPINNER_RETURN_INVOICE:
            return {
                ...state,
                save_button_loading: action.status
            };
          case ReturnInvoiceActionType.BUTTON_UPDATE_SPINNER_RETURN_INVOICE:
            return {
                ...state,
                [action.button_name]: action.status
            };
        case ReturnInvoiceActionType.FOCUS_FIELD_RETURN_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case ReturnInvoiceActionType.ERROR_FIELD_RETURN_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case ReturnInvoiceActionType.FIELD_CHANGE_RETURN_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case ReturnInvoiceActionType.FIELDS_CHANGE_RETURN_INVOICE:
              return {
                ...state,
                ...action.fields
              };
        case ReturnInvoiceActionType.EMPTY_RETURN_INVOICE:      
          return {
            ...state,
            id: 0,
            return_bill_code: "",
            return_bill_date: "",
            return_bill_notes: "",
            receipt_bill_id: 0,
            receipt_bill_code: "",
            import_receipt_id: 0,
            import_receipt_code: "",
            staff_notes_input: "",
            staff_notes_active: false,
            staff_notes_error: false,
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            customer_id: 0,
            customer_code: "",
            customer_name: "",
            customer_phone: "",
            customer_address: "",
            customer_city: 0,
            customer_district: 0,
            customer_ward: 0,
            total_quantity: 0,
            total_amount: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            branch_id: 0,
            branch_name: "",
            products: [],
            staff_notes: [],
            is_cancel: 0,
            search_button_loading: false,
            save_button_loading: false,
            save_note_button_loading: false,
            is_search: false,
            direct_to_list: false,
          };
        case ReturnInvoiceActionType.RESET_FIELD_SEARCH_RETURN_INVOICE:     
          var from_date = moment().startOf('month').format('YYYY-MM-DD'); 
          var to_date = moment().endOf('month').format('YYYY-MM-DD');
          var search_date = [];
          if(from_date !== ""){
              search_date.push(from_date)
          }
          if(to_date !== ""){
              search_date.push(to_date)
          } 
            return {
              ...state,
              search_code: "",
              search_bill_code: "",
              search_product: "",
              search_date: [],
              branch_selected: {},
              search_button_loading: false,
              save_button_loading: false,
              save_note_button_loading: false,
              is_search: false,
            };
        case ReturnInvoiceActionType.GET_RETURN_INVOICE:
            return {
              ...state,
              id: action.return_invoice.id,
              return_bill_code: action.return_invoice.return_bill_code,
              return_bill_date: action.return_invoice.return_bill_date,
              return_bill_notes: action.return_invoice.return_bill_notes,
              receipt_bill_id: action.return_invoice.receipt_bill_id,
              receipt_bill_code: action.return_invoice.receipt_bill_code,
              import_receipt_id: action.return_invoice.import_receipt_id,
              import_receipt_code: action.return_invoice.import_receipt_code,
              customer_id: action.return_invoice.customer_id,
              customer_code: action.return_invoice.customer_code,
              customer_name: action.return_invoice.customer_name,
              customer_phone: action.return_invoice.customer_phone,
              total_quantity: action.return_invoice.total_quantity,
              total_amount: action.return_invoice.total_amount,
              staff_id: action.return_invoice.staff_id,
              staff_name: action.return_invoice.staff_name,
              staff_cancel_id: action.return_invoice.staff_cancel_id,
              staff_cancel_name: action.return_invoice.staff_cancel_name,
              reason_cancel: action.return_invoice.reason_cancel,
              cancel_time: action.return_invoice.cancel_time,
              branch_id: action.return_invoice.branch_id,
              branch_name: action.return_invoice.branch_name,
              products: action.return_invoice.products,
              is_cancel: action.return_invoice.is_cancel,
            }
        case ReturnInvoiceActionType.GET_STAFF_NOTES_RETURN_INVOICE:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };      
        case ReturnInvoiceActionType.GET_LAST_STAFF_NOTES_RETURN_INVOICE:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case ReturnInvoiceActionType.LIST_RETURN_INVOICE:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount: action.total_amount,
                list: [...action.list],
                direct_to_list: false
              };
        case ReturnInvoiceActionType.SEARCH_RETURN_INVOICE:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        default: 
            return state;
    }
}
export default return_invoice;