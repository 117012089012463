import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageUploader from 'react-images-upload';
import * as UploaderAction from '../../actions/UploaderAction';
import DownloadLink from '../shared/DownloadLink';
import apiCaller from '../../utils/apiCaller';

class Uploader extends Component {
    constructor() {
        super();
        this.state = {
            percent_completed: 0
        };
    }
    onUploadProgress = progressEvent => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setState({
            percent_completed: percentCompleted
        })
    }
    onResetProgress = () => {
        this.setState({
            percent_completed: 0
        }) 
    }
    onUploadFile = files => {
        apiCaller.uploadFile("files/excel-parser",files, this.onUploadProgress).then(res => {
            if(res.status === 201 || res.status === 200){
                if (typeof res.data.result != 'undefined' && res.data.result !== null && res.data.result.length > 0){
                   let list_origin = res.data.result
                   if (this.props.use_for === "product"){
                        let model_array = []
                        let size_array = []
                        list_origin.forEach((item) => {
                            model_array.push(item[0])
                            size_array.push(item[3])
                        })
                        apiCaller.callApi(`products/search`,{
                            limit: 0,
                            page: 1,
                            model: model_array.join(","),
                            size: size_array.join(","),
                            is_each_size: (this.props.is_each_size === true)?1:0
                        },'GET').then(res => {
                            if(res.status === 201 || res.status === 200){
                                if (typeof res.data.result != 'undefined' && res.data.result !== null){
                                    let { list } = res.data.result;
                                    let products = []
                                    if(list.length > 0){
                                        list.forEach((item) => {
                                            let quantity = 1
                                            let price = item.retail
                                            for (let i = 0; i < list_origin.length; i++){
                                                if(list_origin[i][0] === item.model){
                                                    quantity = list_origin[i][4]
                                                    price = list_origin[i][2]
                                                    break;
                                                }
                                            }
                                            products.push({
                                                "id": item.id,
                                                "product_option_id": item.product_option_id,
                                                "product_option_name": item.product_option_name,
                                                "url": item.url,
                                                "name": item.name,
                                                "model": item.model,
                                                "sku": item.sku,
                                                "price": price,
                                                "quantity": quantity,
                                                "discount_value": 0,
                                                "discount": 0,
                                                "discount_mode": 1,
                                                "total_amount": (quantity * price),
                                                "remain": item.remain,
                                                "unit": item.unit,
                                                "category_id": item.category_id,
                                                "category_name": item.category_name,
                                                "brand_id": item.brand_id,
                                                "brand_name": item.brand_name,
                                                "is_gift": item.is_gift,
                                            })
                                        })
                                        this.props.onUploadSuccess(products)
                                    }
                                }
                            }
                        });
                   }
                   this.props.oShowUploaderRequest(false)
                   this.onResetProgress();
                }
            }
        });
    }
    render(){
        let uploader_percent = this.state.percent_completed;
        let uploader_percent_text = "";
        if(uploader_percent > 0){
            uploader_percent_text = uploader_percent + "%"
        }
        return(
            <div className={this.props.uploader.show_uploader === true ? "display-block": "display-none"}>
                <div className={uploader_percent > 0 ? "display-block": "display-none"}>
                    <div className="filesContainer">
                        <div className="fileUploader ">
                            <div className="fileContainer">
                                <img src="/static/media/UploadIcon.1cedb6e9.svg" className="uploadIcon" alt="Upload Icon" />
                                <p className="">Tập tin không quá: 5mb, cho phép định dạng: xls|xlsx</p>
                                <div className="progress">
                                    <div className="progress-done" style={{
                                        opacity: 1,
                                        width: `${uploader_percent}%`
                                    }}>{uploader_percent_text}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={uploader_percent === 0 ? "display-block": "display-none"}>
                    <div className="filesContainer">
                        <ImageUploader
                            withIcon={true}
                            withPreview={false}
                            singleImage={true}
                            buttonText='Chọn tập tin'
                            label='Tập tin không quá: 5mb, cho phép định dạng: xls|xlsx'
                            onChange={this.onUploadFile.bind(this)}
                            imgExtension={['.xls', '.xlsx']}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            maxFileSize={5242880}
                        />
                        <DownloadLink button_name="Tải tập tin mẫu" file_url={this.props.file_url} file_name={this.props.file_name} />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        uploader: state.uploader
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        oShowUploaderRequest(status){
            dispatch(UploaderAction.showUploaderRequest(status))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Uploader);