import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import * as UserAction from '../../actions/UserAction';
import Barcode from 'react-barcode';
import logo from '../../logo.png';
import '../../print_a5.css';

class PrintTransporter extends Component {
    componentDidMount(){
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        this.props.onEmptyInfo()
        if (id > 0) {
            this.props.onGetCustomer(id)
        }
    }
    render(){
        let {address, city_name, district_name, ward_name, code, name, phone  } = this.props.user
        
        let receiver_address = address
        if(ward_name!== ""){
            receiver_address += ", " + ward_name
        }
        if(district_name!== ""){
            receiver_address += ", " + district_name
        }
        if(city_name !== ""){
            receiver_address += ", " + city_name
        }
        return (
                <div className="face">
                    <div className="text-center hide-print">
                        <button className="btn btn-default btn-sm" onClick={() => { window.print() }}><i className="fa fa-print"></i>&nbsp;In</button>
                    </div>
                <table style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>
                                <div className="pull-left">
                                <img alt="" src={logo} style={ { "height":"80px" } } />
                                </div>
                                <div className="pull-right text-center">
                                    <span>MOBY KID</span><br />
                                    <span>ĐIỀU TỐT NHẤT CHO CON</span><br />
                                    <span>15i Nguyễn Thị Minh Khai, Phường Bến Nghé, Quận 1, HCM</span><br />
                                    <span>344A Lê Hồng Phong, Phường 1, Quận 10, HCM</span><br />
                                    <span>Hotline: 0986.680.500 - Website: moby.com.vn</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-center">
                    <h4>THÔNG TIN NHÀ VẬN CHUYỂN</h4>
                    <div><Barcode className="text-center" width={3} height={50} font="Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif" value={code} /></div>
                </div>
                <div className="mr-top-10">
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td><strong>Họ tên:&nbsp;</strong>{name}</td>
                                <td><strong>Số điện thoại:&nbsp;</strong>{phone}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">{ receiver_address ? <span><strong>Địa chỉ:&nbsp;</strong>{ receiver_address }</span> : "" }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo: () => {
            dispatch(UserAction.resetField())
        },
        onGetCustomer: (id) => {
            return dispatch(UserAction.getRequest(id))
        },
    }
}
const PrintTransporterConnect = (connect(mapStateToProps, mapDispatchToProps)(PrintTransporter));
export default withRouter(PrintTransporterConnect);