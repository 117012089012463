import * as ReceiptOfGoodActionType from '../constants/ReceiptOfGoodActionType';
var initialState = {
    id: 0,
    receipt_code: "",
    receipt_date: "",
    receipt_status_select: [],
    receipt_status_color_select: [],
    receipt_status: 0,
    receipt_status_text: "",
    receipt_status_color: "",
    import_receipt_id: 0,
    import_receipt_code: "",
    transfer_store_id: 0,
    transfer_store_code: "",
    total_quantity: 0,
    total_product: 0,
    total_amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    staff_finish_id: 0,
    staff_finish_name: "",
    finish_time: 0,
    has_gift: 0,
    branch_id: 0,
    branch_name: "",
    products: [],
    total: 0,
    limit: 10,
    total_page: 0,
    page: 1,
    list: [],
    search_code: "",
    search_product: "",
    search_date: [],
    search_status: [],
    search_import_receipt_code: "",
    search_transfer_store_code: "",
    search_button_loading: false,
    is_search: false,
};
const receipt_of_goods = (state = initialState, action) => {
    switch(action.type){
      case ReceiptOfGoodActionType.FOCUS_FIELD_RECEIPT_OF_GOOD:
        return {
          ...state,
          [action.field]: action.value
        };
      case ReceiptOfGoodActionType.FIELD_CHANGE_RECEIPT_OF_GOOD:
            return {
              ...state,
              [action.field]: action.value
            };
      case ReceiptOfGoodActionType.FIELDS_CHANGE_RECEIPT_OF_GOOD:
            return {
              ...state,
              ...action.fields
            };
        case ReceiptOfGoodActionType.EMPTY_RECEIPT_OF_GOOD:
          return {
            ...state,
            id: 0,
            receipt_code: "",
            receipt_date: "",
            receipt_status_select: [],
            receipt_status: 0,
            receipt_status_text: "",
            receipt_status_color: "",
            import_receipt_id: 0,
            import_receipt_code: "",
            transfer_store_id: 0,
            transfer_store_code: "",
            total_quantity: 0,
            total_product: 0,
            total_amount: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            staff_finish_id: 0,
            staff_finish_name: "",
            finish_time: 0,
            has_gift: 0,
            branch_id: 0,
            branch_name: "",
            products: [],
            total: 0,
            limit: 10,
            total_page: 0,
            page: 1,
            list: [],
            search_code: "",
            search_product: "",
            search_date: [],
            search_status: [],
            search_import_receipt_code: "",
            search_transfer_store_code: "",
            search_button_loading: false,
            is_search: false,
          };
        case ReceiptOfGoodActionType.RESET_FIELD_SEARCH_RECEIPT_OF_GOOD:      
            return {
              ...state,
              search_code: "",
              search_product: "",
              search_date: [],
              search_status: [],
              search_import_receipt_code: "",
              search_transfer_store_code: "",
              search_button_loading: false,
              is_search: false,
            };
        case ReceiptOfGoodActionType.GET_RECEIPT_OF_GOOD:
            return {  
                ...state,
                id: action.receipt_of_good.id,
                receipt_code: action.receipt_of_good.receipt_code,
                receipt_date: action.receipt_of_good.receipt_date,
                receipt_status: action.receipt_of_good.receipt_status,
                receipt_status_text: action.receipt_of_good.receipt_status_text,
                receipt_status_color: action.receipt_of_good.receipt_status_color,
                import_receipt_id: action.receipt_of_good.import_receipt_id,
                import_receipt_code: action.receipt_of_good.import_receipt_code,
                transfer_store_id: action.receipt_of_good.transfer_store_id,
                transfer_store_code: action.receipt_of_good.transfer_store_code,
                total_amount: action.receipt_of_good.total_amount,
                total_product: action.receipt_of_good.total_product,
                total_quantity: action.receipt_of_good.total_quantity,
                staff_id: action.receipt_of_good.staff_id,
                staff_name: action.receipt_of_good.staff_name,
                staff_cancel_id: action.receipt_of_good.staff_cancel_id,
                staff_cancel_name: action.receipt_of_good.staff_cancel_name,
                reason_cancel: action.receipt_of_good.reason_cancel,
                cancel_time: action.receipt_of_good.cancel_time,
                staff_finish_id: action.receipt_of_good.staff_finish_id,
                staff_finish_name: action.receipt_of_good.staff_finish_name,
                finish_time: action.receipt_of_good.finish_time,
                branch_id: action.receipt_of_good.branch_id,
                branch_name: action.receipt_of_good.branch_name,
                products: action.receipt_of_good.products,
            };
        case ReceiptOfGoodActionType.LIST_RECEIPT_OF_GOOD:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                list: [...action.receipt_of_goods],
              };
        case ReceiptOfGoodActionType.SEARCH_RECEIPT_OF_GOOD:
            return {
                ...state,
                is_search: true,
                search_button_loading: action.search_button_loading
            }; 
        case ReceiptOfGoodActionType.GET_RECEIPT_OF_GOOD_STATUS:
          let status_array = [], status_color_array = []
          Object.keys(action.list).map((k) => {
            status_array.push({
                label: action.list[k],
                value: k
            })
            return true
          })
          Object.keys(action.color).map((k) => {
            status_color_array.push({
                label: action.color[k],
                value: k
            })
            return true
          })
        return {  
            ...state,
            receipt_status_select: status_array,
            receipt_status_color_select: status_color_array
        };  
      case ReceiptOfGoodActionType.LIST_RECEIPT_OF_GOOD_STATUS:
        return {
            ...state,
            receipt_status_select: action.status
          };
        default: 
            return state;
    }
}
export default receipt_of_goods;