import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import moment from 'moment';
import * as PurchaseOrderAction from '../../actions/PurchaseOrderAction';
import * as PurchaseOrderActionType from '../../constants/PurchaseOrderActionType';
import * as UserActionType from '../../constants/UserActionType';
import UserSelectBox from '../shared/UserSelectBox';
import DatePickerFilter from '../shared/DatePickerFilter';
import MyPagination from '../shared/Pagination';
import Helper from '../../utils/Helper';
class PurchaseOrders extends Component {
    componentDidMount(){
        let _this = this
        let page = Helper.getQueryString('page');
        if(page === null){
            page = 1
        }
        _this.bindDataSearch()
        _this.props.onListPurchaseOrderStatus();
        setTimeout(() => {
            var data_search = _this.getDataSearch();
            if (data_search.search !== undefined && data_search.search === true){
                _this.props.onSearchPurchaseOrders(data_search,page,_this.props.purchase_order.limit, true);
            } else if (this.props.accounting === true){
                this.props.onFieldChange("search_from_supplier",1);
                this.props.onFieldChange("search_transfer_store",0);
                this.props.onFieldChange("search_inventory_check",0);
                this.props.onFieldChange("search_return_bill",0);
                _this.props.onSearchPurchaseOrders({
                    "is_accounting": 1,
                    "from_supplier": 1
                },page,_this.props.purchase_order.limit, true);
            } else{
                this.props.onFieldChange("search_from_supplier",1);
                this.props.onFieldChange("search_transfer_store",0);
                this.props.onFieldChange("search_inventory_check",0);
                this.props.onFieldChange("search_return_bill",0);
                _this.props.onSearchPurchaseOrders({
                    "from_supplier": 1
                },page,_this.props.purchase_order.limit, true);
            }
        },250)
    }
    bindDataSearch = () => {
        let search_code = Helper.getQueryString('code');
        let search_product = Helper.getQueryString('keyword');
        let status = Helper.getQueryString('status');
        let from_date = Helper.getQueryString('from_date');
        let to_date = Helper.getQueryString('to_date');
        let supplier_id = Helper.getQueryString('supplier_id');
        let inventory_check = Helper.getQueryString('inventory_check');
        let transfer_store = Helper.getQueryString('transfer_store');
        let return_bill = Helper.getQueryString('return_bill');
        let from_supplier = Helper.getQueryString('from_supplier');
        let miss_retail = Helper.getQueryString('miss_retail');
        var search_date = [];
        if(from_date !== undefined && from_date !== null && from_date !== ""){
            search_date.push(from_date)
        }
        if(to_date !== undefined && to_date !== null && to_date !== ""){
            search_date.push(to_date)
        }
        var supplier_selected = {}
        if(supplier_id !== undefined && supplier_id !== null && supplier_id > 0){
            supplier_selected.value = parseInt(supplier_id)
        }
        var search_status = []
        if (status !== undefined && status !== null && status !== ""){
            let search_status_array = status.split(",")
            search_status_array.forEach(item => {
                search_status.push(parseInt(item))
            })
        }
        if (search_product === null){
            search_product = ""
        }
        if (search_code === null){
            search_code = ""
        }
        if (!(inventory_check > 0)){
            inventory_check = 0   
        }
        if (!(transfer_store > 0)){
            transfer_store = 0   
        }
        if (!(return_bill > 0)){
            return_bill = 0   
        }
        if (!(from_supplier > 0)){
            from_supplier = 0   
        }
        if (!(miss_retail > 0)){
            miss_retail = 0
        }
        this.props.onFieldsChange({
            search_code, search_status, search_date, supplier_selected, search_product, search_inventory_check: parseInt(inventory_check), search_transfer_store: parseInt(transfer_store), search_return_bill: parseInt(return_bill), search_from_supplier: parseInt(from_supplier), search_miss_retail: parseInt(miss_retail)
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if(target.type === "checkbox"){
            value = 0;
            if(target.checked === true){
                value = 1;
            }
        }
        this.props.onFieldChange(name,value);
        if(value === 1){
            if(name === "search_inventory_check"){
                this.props.onFieldChange("search_transfer_store",0);
                this.props.onFieldChange("search_from_supplier",0);
                this.props.onFieldChange("search_return_bill",0);
            } else if(name === "search_transfer_store"){
                this.props.onFieldChange("search_inventory_check",0);
                this.props.onFieldChange("search_from_supplier",0);
                this.props.onFieldChange("search_return_bill",0);
            } else if(name === "search_from_supplier"){
                this.props.onFieldChange("search_inventory_check",0);
                this.props.onFieldChange("search_transfer_store",0);
                this.props.onFieldChange("search_return_bill",0);
            } else if(name === "search_return_bill"){
                this.props.onFieldChange("search_inventory_check",0);
                this.props.onFieldChange("search_transfer_store",0);
                this.props.onFieldChange("search_from_supplier",0);
            }
        }
    }
    onImportReceiptDatePickerChange(value){
        if (typeof value !== undefined && value !== null && value.length > 0){
            var from_date = moment(value[0]).format('YYYY-MM-DD'); 
            var to_date = moment(value[1]).format('YYYY-MM-DD');
            var search_date = [];
            if(from_date !== ""){
                search_date.push(from_date)
            }
            if(to_date !== ""){
                search_date.push(to_date)
            }
            this.props.onFieldChange("search_date",search_date);
        }
    }
    onUserFieldChange(item){
        this.props.onFieldChange("supplier_selected", item);
    }
    onSearchByEnter(e) {
        let target = e.target;
        let value = target.value;
        if (e.key === 'Enter' && value !== null && value !== "") {
            this.onSearch()
        }
    }

    getDataSearch(){
        let _code = this.props.purchase_order.search_code;
        let _product = this.props.purchase_order.search_product;
        let _status = this.props.purchase_order.search_status;
        let _date = this.props.purchase_order.search_date;
        let _supplier = this.props.purchase_order.supplier_selected;
        let _inventory_check = parseInt(this.props.purchase_order.search_inventory_check);
        let _transfer_store = parseInt(this.props.purchase_order.search_transfer_store);
        let _is_return_bill = parseInt(this.props.purchase_order.search_return_bill);
        let _from_supplier = parseInt(this.props.purchase_order.search_from_supplier);
        let _miss_retail = parseInt(this.props.purchase_order.search_miss_retail);
        let product_id = Helper.getQueryString('product_id');
        let product_option_id_param = Helper.getQueryString('product_option_id');
        let product_option_id = 0
        if (product_option_id_param !== null){
            product_option_id = product_option_id_param
        }
        /*if(_model === '' && _name === '' && _category_selected.length === 0 && _brand_selected.length === 0){
            return false;
        }*/
        if(isNaN(_inventory_check)){
            _inventory_check = 0
        }
        if(isNaN(_transfer_store)){
            _transfer_store = 0
        }
        if(isNaN(_is_return_bill)){
            _is_return_bill = 0
        }
        if(isNaN(_from_supplier)){
            _from_supplier = 0
        }
        if(isNaN(_miss_retail)){
            _miss_retail = 0
        }
        var is_search = false
        var data_search = {
            "search": false,
            "param": []
        }
        if (this.props.accounting === true){ 
            data_search["is_accounting"] = 1;
            data_search["from_supplier"] = 1;
        }
        if(_code !== ""){
            data_search["code"] = _code;
            data_search["param"].push("code=" + _code)
            is_search = true
            this.props.onFieldsChange({
                supplier_selected: {}, search_product: "", search_inventory_check: 0, search_transfer_store: 0, search_from_supplier: 0, search_return_bill: 0
            })
        } else {
            if(product_id > 0){
                data_search["param"].push("product_id=" + product_id)
                is_search = true
            }
            if(product_option_id > 0){
                data_search["param"].push("product_option_id=" + product_option_id)
                is_search = true
            }
            if (_product !== ""){
                data_search["product_keyword"] = _product;
                data_search["param"].push("keyword=" + _product)
                is_search = true
            }
            if(_inventory_check > 0){
                data_search["inventory_check"] = _inventory_check;
                data_search["param"].push("inventory_check=" + _inventory_check)
                is_search = true
            }
            if(_transfer_store > 0){
                data_search["transfer_store"] = _transfer_store;
                data_search["param"].push("transfer_store=" + _transfer_store)
                is_search = true
            }
            if(_is_return_bill > 0){
                data_search["is_return_bill"] = _is_return_bill;
                data_search["param"].push("return_bill=" + _is_return_bill)
                is_search = true
            }
            if(_from_supplier > 0){
                data_search["from_supplier"] = _from_supplier;
                data_search["param"].push("from_supplier=" + _from_supplier)
                is_search = true
            }
            if(_miss_retail > 0){
                data_search["miss_retail"] = _miss_retail;
                data_search["param"].push("miss_retail=" + _miss_retail)
                is_search = true
            }
            if (_status.length > 0){
                data_search["receipt_status"] = _status.join(",")
                data_search["param"].push("status=" + _status.join(","))
                is_search = true
            }
            if (typeof _date[0] !== 'undefined'){
                data_search["from_date"] = _date[0];
                data_search["param"].push("from_date=" + _date[0])
                is_search = true
            }
            if (typeof _date[1] !== 'undefined'){
                data_search["to_date"] = _date[1];
                data_search["param"].push("to_date=" + _date[1])
                is_search = true
            }
            if(typeof _supplier.value !== 'undefined'){
                data_search["supplier_id"] = _supplier.value;
                data_search["param"].push("supplier_id=" + _supplier.value)
                is_search = true
            }
        }
        data_search["search"] = is_search;
        return data_search
    }
    onSearch(){
        var data_search = this.getDataSearch();
        if(data_search.param.length > 0){
            let param = data_search.param.join("&")
            if(param !== ""){
                param = "?" + param
            }
            if (this.props.accounting === true){ 
                this.props.history.push("/accounting/purchase-orders" + param)
            } else{
                this.props.history.push("/purchase-orders" + param)
            }
        }
        this.props.onSearchPurchaseOrders(data_search,1,this.props.purchase_order.limit, true);
    }
    onViewAll(){
        this.supplierSelectBoxRef.onClean()
        this.props.onResetFieldSearch();
        this.props.onListPurchaseOrders(1,this.props.purchase_order.limit);
    }
    handlePageChange(pageNumber){
        var data_search = this.getDataSearch();
        let param = "?page=" + pageNumber
        if(data_search.param.length > 0){
            let param_search = data_search.param.join("&")
            if(param_search !== ""){
                param += "&" + param_search
            }
        }
        if (this.props.accounting === true){ 
            this.props.history.push("/accounting/purchase-orders" + param)
        } else{
            this.props.history.push("/purchase-orders" + param)
        }
        this.props.onSearchPurchaseOrders(data_search,pageNumber,this.props.purchase_order.limit, false);
    }
    onItemClick(id){
        if (this.props.accounting === true){
            this.props.history.push("/accounting/purchase-order/" + id);
        } else{
            this.props.history.push("/purchase-order/" + id);
        }
    }
    showList(lists){
        var result = null;
        if(lists.length > 0){
            result = lists.map((item, index) => {
                let pay_supplier = item.total_liabilities - item.paid_liabilities
                let total_liabilities = Helper.parseMoney(item.total_liabilities)
                let paid_liabilities = Helper.parseMoney(item.paid_liabilities)
                let status_text = ""
                if(+item.is_check_inventory === 1){
                    total_liabilities = item.total_amount
                    status_text = <label className="label label-warning">Kiểm kho</label>
                } else if(+item.is_transfer_store === 1){
                    total_liabilities = item.total_amount
                    status_text = <label className="label label-warning">Chuyển kho</label>
                }  else if(+item.is_return_bill === 1){
                    total_liabilities = item.total_amount
                    status_text = <label className="label label-warning">Hàng trả</label>
                } else if (item.import_receipt_status !== PurchaseOrderActionType.STATUS_FINISH_PURCHASE_ORDER){
                    status_text = <label className='label' style={{backgroundColor: item.import_receipt_status_color }}>{ item.import_receipt_status_text }</label>
                }
                pay_supplier = Helper.parseMoney(pay_supplier)
                return (
                <tr className="cl_handover" key={ index } onClick={ () => { this.onItemClick(item.id) }}>
                    <td className="text-center">{item.import_receipt_code}</td>
                    <td>
                    <Moment format="DD/MM/YYYY HH:mm">
                    { Helper.replaceMysqlDateTime(item.import_receipt_date) }
                    </Moment>
                    </td>
                    <td>{ item.supplier_name }</td>
                    <td className="text-right"><NumberFormat value={total_liabilities} displayType={'text'} thousandSeparator={true} /></td>
                    <td className="text-right"><NumberFormat value={paid_liabilities} displayType={'text'} thousandSeparator={true} /></td>
                    <td className="text-right"><NumberFormat value={pay_supplier} displayType={'text'} thousandSeparator={true} /></td>
                    <td className="text-center">{ status_text }</td>
                </tr>
                );   
            });
        }
        return result;
    }
    render(){
        let { total, limit, total_page, page, list, total_amount, total_payed_amount, search_code, search_product, search_inventory_check, search_transfer_store, search_return_bill, search_from_supplier, search_miss_retail, search_button_loading } = this.props.purchase_order;
        let _pagination;
        if(total > limit){
            _pagination = <MyPagination page={page} total_page={total_page} pageChange={this.handlePageChange.bind(this)} />
        }
        let btn_view_all = ""
        /*if(is_search === true){
            btn_view_all =  (<LaddaButton
                                loading={ search_button_loading }
                                data-style={ZOOM_IN}
                                data-spinner-color="#000000"
                                className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onViewAll() } } >
                                <i className="fa fa-list"></i>&nbsp;Xem tất cả
                            </LaddaButton>)
        }*/
        let total_pay_supplier = total_amount - total_payed_amount
        total_amount = Helper.parseMoney(total_amount)
        total_pay_supplier = Helper.parseMoney(total_pay_supplier)
        total_payed_amount = Helper.parseMoney(total_payed_amount)
        return (
            <div className="container">
            <Helmet>
                <title>Danh sách phiếu nhập hàng</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/purchase-orders"} />
            </Helmet>  
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                        <div className="form-element-list" >
                            <div className="cmp-tb-hd">
                                <h2>Bộ lọc</h2>
                                <p></p>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <DatePickerFilter placeholder="Chọn ngày nhập hàng"  value={this.props.purchase_order.search_date}
                                    onFieldChange = { (value) => { this.onImportReceiptDatePickerChange(value) } }
                                />
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_code" value={ search_code } className="form-control" autoComplete="off" placeholder="Nhập mã phiếu" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <input name="search_product" value={ search_product } className="form-control" autoComplete="off" placeholder="Nhập mã hoặc tên hàng hóa" onChange={ (e) => { this.onFieldChange(e) } } onKeyDown={(e) => { this.onSearchByEnter(e) } }></input>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <UserSelectBox getRef={ref => { this.supplierSelectBoxRef = ref } } placeholder="Tìm nhà cung cấp" user_type={UserActionType.SUPPLIER_USER_TYPE} onFieldChange={(item) => { this.onUserFieldChange(item) } } />
                            </div>
                            { this.props.accounting === false ? 
                            <div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <div className="toggle-select-act fm-cmp-mg">
                                        <div className="nk-toggle-switch">
                                            <label htmlFor="search_from_supplier" className="ts-label">Nhập hàng</label>
                                            <input id="search_from_supplier" name="search_from_supplier" type="checkbox" hidden="hidden" value="1" checked={search_from_supplier === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                            <label htmlFor="search_from_supplier" className="ts-helper"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <div className="toggle-select-act fm-cmp-mg">
                                        <div className="nk-toggle-switch">
                                            <label htmlFor="search_inventory_check" className="ts-label">Kiểm kho</label>
                                            <input id="search_inventory_check" name="search_inventory_check" type="checkbox" hidden="hidden" value="1" checked={search_inventory_check === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                            <label htmlFor="search_inventory_check" className="ts-helper"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <div className="toggle-select-act fm-cmp-mg">
                                        <div className="nk-toggle-switch">
                                            <label htmlFor="search_transfer_store" className="ts-label">Chuyển kho</label>
                                            <input id="search_transfer_store" name="search_transfer_store" type="checkbox" hidden="hidden" value="1" checked={search_transfer_store === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                            <label htmlFor="search_transfer_store" className="ts-helper"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <div className="toggle-select-act fm-cmp-mg">
                                        <div className="nk-toggle-switch">
                                            <label htmlFor="search_return_bill" className="ts-label">Hàng trả</label>
                                            <input id="search_return_bill" name="search_return_bill" type="checkbox" hidden="hidden" value="1" checked={search_return_bill === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                            <label htmlFor="search_return_bill" className="ts-helper"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> : "" }
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                    <div className="toggle-select-act fm-cmp-mg">
                                        <div className="nk-toggle-switch">
                                            <label htmlFor="search_miss_retail" className="ts-label">Thiếu giá nhập</label>
                                            <input id="search_miss_retail" name="search_miss_retail" type="checkbox" hidden="hidden" value="1" checked={search_miss_retail === 1?true:false} onChange={ (e) => { this.onFieldChange(e) } }></input>
                                            <label htmlFor="search_miss_retail" className="ts-helper"></label>
                                        </div>
                                    </div>
                                </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ search_button_loading }
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-default btn-block notika-btn-success waves-effect" onClick={ () => { this.onSearch() } } >
                                    <i className="fa fa-search"></i>&nbsp;Tìm kiếm
                                </LaddaButton>
                                { btn_view_all }
                            </div>
                        </div>
                    </div>    
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="normal-table-list">
                            <div className="basic-tb-hd">
                                <div className="pull-left">
                                    <h2>
                                        <div className="pull-left">Phiếu nhập hàng</div>
                                    </h2>
                                    <p></p>
                                </div>
                            </div>
                            <div className="bsc-tbl">
                                <table className="table table-sc-ex table-hover table-striped table-condensed">
                                    <colgroup>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                        <col width="10%"></col>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Mã nhập hàng</th>
                                            <th>Thời gian</th>
                                            <th>Nhà cung cấp</th>
                                            <th className="text-right">Tổng tiền</th>
                                            <th className="text-right">Đã trả</th>
                                            <th className="text-right">Cần trả NCC</th>
                                            <th className="text-center">Trạng thái</th>
                                        </tr>
                                        { search_from_supplier === 1 ?
                                        <tr className="bg-warning">
                                            <th colSpan="3"></th>
                                            <th className="text-right"><NumberFormat value={total_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_payed_amount} displayType={'text'} thousandSeparator={true} /></th>
                                            <th className="text-right"><NumberFormat value={total_pay_supplier} displayType={'text'} thousandSeparator={true} /></th>
                                            <th></th>
                                        </tr> : <tr>
                                            <th colSpan="7"></th>
                                        </tr> }
                                    </thead>
                                    <tbody>
                                    { this.showList(list) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                { _pagination }
                    </div>
                </div>
            </div>
        );
    }
}

PurchaseOrders.defaultProps = {
    accounting: false
}
const mapStateToProps = state => {
    return {
        purchase_order: state.purchase_order
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onErrorField(field,value){
            dispatch(PurchaseOrderAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(PurchaseOrderAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(PurchaseOrderAction.fieldChange(field,value))
        },
        onFieldsChange(fields){
            dispatch(PurchaseOrderAction.fieldsChange(fields))
        },
        onResetFieldSearch(){
            dispatch(PurchaseOrderAction.resetFieldSearch())
        },
        onListPurchaseOrderStatus: () => {
            dispatch(PurchaseOrderAction.listStatusRequest())
        },
        onListPurchaseOrders: (page,limit) => {
            dispatch(PurchaseOrderAction.listRequest(page,limit))
        },
        onSearchPurchaseOrders: (data_search,page,limit,spinner) => {
            dispatch(PurchaseOrderAction.searchRequest(data_search,page,limit,spinner))
        },
    }
}
const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )
 export default enhance(PurchaseOrders)