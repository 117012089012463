import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { DatePicker, Table, InputNumber, Alert } from 'rsuite';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import * as InventoryCheckAction from '../../actions/InventoryCheckAction';
import * as InventoryCheckActionType from '../../constants/InventoryCheckActionType';
import SearchProduct from '../shared/SearchProduct';
import Helper from '../../utils/Helper';
import import_products_file from '../../import_products.xlsx';
import Uploader from '../shared/Uploader';
import TextareaAutoHeight from '../shared/TextareaAutoHeight';
import Swal from 'sweetalert2';
import ViewCheckInventories from './ViewCheckInventories';

const { Column, HeaderCell, Cell } = Table;

class CreateCheckInventories extends Component {
    componentDidMount(){
        this.props.onEmptyInfo();
        let { match } = this.props;
        let id = 0;
        if(match !== undefined && match.params !== undefined){
            id = match.params.id;
        }
        if (id > 0) {
            this.onInitData(id)
        }
    }
    onInitData(id){
        this.props.onGetData(id).then(result => {
            if (result !== undefined && result.id !== undefined){
                this.props.onGetStaffNotes(id)
                this.props.onGetLastStaffNote(id)
            } else if(result.status === false && result.message !== undefined && result.message !== ""){
                this.props.history.push("/inventories-check");
            }
        })
    }
    onFieldFocus = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        this.props.onFocusField(field_active,true);  
    }
    onFieldBlur = (event) => {
        let target = event.target;
        let field_active = target.name + '_active';
        let is_focus = false;
        if(target.value !== ''){
            is_focus = true;
        }
        this.props.onFocusField(field_active,is_focus);   
    }
    onFieldChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        this.props.onFieldChange(name,value);
    }
    onUploadSuccess = list => {
        let list_temp = list
        list = []
        if (list_temp.length > 0){
            list_temp.forEach(item => {
                let key = item.id + '_' + item.product_option_id
                list.push({
                    "key": key,
                    ...item
                })
            })
        }
        this.props.onUpdateProducts(list);
    }
    onSelectProduct = itemSelected => {
        if(itemSelected !== null && typeof itemSelected.id !== "undefined"){
            this.props.onSelectProductToList(itemSelected)
        }
    }
    onRemoveProduct = key => {
        let products = this.props.inventory_check.products;
        let index = Helper.findIndexByKey(products, key);
        if(index > -1){
            let lists_after = products.slice(0, index).concat(products.slice(index + 1, products.length))
            this.props.onUpdateProducts(lists_after);
        }
    }
    onClearProduct = () => {
        this.props.onClearProduct();
    }
    onQuantityItemFieldChange = (key,value) => {
        this.props.onUpdateProductItem("quantity", key, value);
    }
    onPriceItemFieldChange(key,values){
        this.props.onUpdateProductItem("price", key, values.value);
    }
    onDatePickerChange(value){
        if (typeof value !== undefined && value !== null){
            this.props.onFieldChange("inventory_check_date",moment(value).format('YYYY-MM-DD HH:mm:ss'));
        }
    }
    onSaveTemp(){
        this.onSaveData(false)
    }
    onSubmit(){
        this.onSaveData(true)
    }
    onSaveData(is_finish){
        let {products, inventory_check_date, inventory_check_notes } = this.props.inventory_check
        if (products.length === 0){
            Alert.error('Vui lòng nhập chọn hàng hóa')
            return;
        }
        if (inventory_check_date === ""){
            inventory_check_date = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        let my_stores = Helper.getMyStores()
        let products_array = []
        products.forEach(item => {
            products_array.push({
                "id": item.id,
                "product_option_id": item.product_option_id,
                "product_name": item.name,
                "product_sku": item.sku,
                "product_model": item.model,
                "category_id": parseInt(item.category_id),
                "category_name": item.category_name,
                "brand_id": parseInt(item.brand_id),
                "brand_name": item.brand_name,
                "retail": 0,
                "real_quantity": parseInt(item.quantity),
                "is_gift": parseInt(item.is_gift),
                "is_check_inventory": 1,
            })
        })
        let data_post = {
            inventory_check_date: inventory_check_date,
            inventory_check_notes: inventory_check_notes,
            inventory_check_status: (is_finish === true) ? InventoryCheckActionType.STATUS_FINISH_INVENTORY_CHECK : InventoryCheckActionType.STATUS_NEW_INVENTORY_CHECK,
            store_id: my_stores.store_id,
            store_code: my_stores.store_code,
            store_name: my_stores.store_name,
            products: products_array,
        }
        //console.log(data_post);
        let button_name = "save_temp_button_loading"
        if (is_finish === true){
            button_name = "save_button_loading"
        }
        if (this.props.inventory_check.id !== null && this.props.inventory_check.id > 0){
            this.props.onUpdate(data_post, this.props.inventory_check.id, button_name).then(result => {
                if(result.status === true){
                    this.props.history.push("/inventory-check/" + result.id);
                    //this.onInitData(result.id)
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        } else {
            this.props.onSubmit(data_post).then(result => {
                if(result.status === true){
                    this.props.history.push("/inventories-check");
                }
                else if(result.status === false && result.message !== undefined && result.message !== ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Thông báo lỗi',
                        text: result.message
                    })
                }
            })
        }
    }
    render(){
        if(this.props.inventory_check.inventory_check_status === InventoryCheckActionType.STATUS_FINISH_INVENTORY_CHECK || this.props.inventory_check.inventory_check_status === InventoryCheckActionType.STATUS_CANCEL_INVENTORY_CHECK){
            return <ViewCheckInventories accounting={this.props.accounting} />
        }
        let {products, total_product, total_quantity, inventory_check_date, inventory_check_notes, inventory_check_notes_active, save_button_loading, save_temp_button_loading } = this.props.inventory_check;
        inventory_check_date = Helper.replaceMysqlDateTime(inventory_check_date)
        let inventory_check_date_text = null
        if (inventory_check_date !== ""){
            inventory_check_date_text = new Date(moment(inventory_check_date, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss").toString())
        }
        let notes_field_class = "nk-int-st"
        if (inventory_check_notes !== "") {
            inventory_check_notes_active = true;
        }
        if(inventory_check_notes_active === true){
            notes_field_class += ' nk-toggled';
        }
        let tableHeight = 0
        if(products.length > 0){
            tableHeight = 400
        }
        return (
            <div className="container">
            <Helmet>
                <title>Phiếu kiểm kho</title>
                <link rel="canonical" href={Helper.getMainUrl() + "/inventory-check/new"} />
            </Helmet>  
                <div className="row">   
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                        <SearchProduct is_check_inventory={true} onSelect={ this.onSelectProduct.bind(this) } onClearProduct={ this.onClearProduct.bind(this) } get_size={true} />
                        <div className="normal-table-list cl_list_product">
                            <div>
                                <Table
                                    virtualized
                                    height={tableHeight}
                                    rowHeight={55}
                                    wordWrap={true}
                                    data={products} >
                                    <Column width={50} align="center" fixed>
                                        <HeaderCell>STT</HeaderCell>
                                        <Cell>
                                            {(rowData,rowIndex) => {
                                                return (
                                                <span>{rowIndex + 1}</span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50}>
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let imgTag = ""
                                                if(rowData.url !== ""){
                                                    imgTag = <img src={rowData.url} alt="" />
                                                }
                                                return imgTag;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell>Mã hàng</HeaderCell>
                                        <Cell dataKey="model" />
                                    </Column>
                                    <Column width={400}>
                                        <HeaderCell>Tên hàng</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                let nameText = rowData.name 
                                                if(typeof rowData.product_option_name !== 'undefined' && rowData.product_option_name !== null && rowData.product_option_name !== ""){
                                                    nameText = (
                                                        <span>
                                                        { rowData.name  }<br />
                                                        { rowData.product_option_name }
                                                        </span>
                                                    )
                                                }
                                                return nameText
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>ĐVT</HeaderCell>
                                        <Cell dataKey="unit" />
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Tồn kho</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={rowData.remain} />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Thực tế</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <InputNumber className="text-right" value={rowData.quantity}  min={0} onChange={(value) => { this.onQuantityItemFieldChange(rowData.key,value) }}/>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="right">
                                        <HeaderCell>Chênh lệch</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <div className="nk-int-st nk-toggled">
                                                        <NumberFormat className="form-control text-right" readOnly={true} thousandSeparator={true} prefix={""} value={rowData.quantity_diff} />
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={50} fixed="right" align="center">
                                        <HeaderCell>&nbsp;</HeaderCell>
                                        <Cell>
                                            {rowData => {
                                                return (
                                                    <button type="button" onClick={() => { this.onRemoveProduct(rowData.key) }}><i className="fa fa-remove"></i></button>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                            <Uploader file_url={import_products_file} file_name="import_products.xlsx" use_for="product" is_each_size={true} onUploadSuccess={(list) => { this.onUploadSuccess(list) } }/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                        <div className="form-element-list" >
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-right">
                                    <DatePicker format="YYYY-MM-DD HH:mm" appearance="subtle" placeholder={moment().format("YYYY-MM-DD HH:mm")} 
                                    value={inventory_check_date_text}
                                    defaultValue={moment().hour(0).minute(0).second(0).toDate()}
                                    placement="leftStart" ranges={[]} block 
                                    onChange = { (value) => { this.onDatePickerChange(value) } } />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Tổng số mặt hàng
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_product} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className="pull-left mr-top-5">
                                    Tổng số lượng thực tế
                                </div>
                                <div className="pull-right">
                                    <NumberFormat className="text-right" thousandSeparator={true} displayType={"text"} value={total_quantity} />
                                </div>
                                <div className="cl"></div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <div className={notes_field_class}>
                                    <TextareaAutoHeight placeholder="Thêm ghi chú" name={"inventory_check_notes"} onFocus={ (e) => { this.onFieldFocus(e) } }  onBlur={ (e) => { this.onFieldBlur(e) } } onChange={ (e) => { this.onFieldChange(e) } } refInput={(input) => { this.notes_input = input; }}/>
                                </div>
                            </div>
                            <div className="form-group ic-cmp-int float-lb floating-lb">
                                <LaddaButton
                                    loading={ save_temp_button_loading }
                                    disabled={save_button_loading}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-info pull-left mr-right-5" onClick={ () => { this.onSaveTemp() } } >
                                    <i className="fa fa-save"></i>&nbsp;LƯU TẠM
                                </LaddaButton>
                                <LaddaButton
                                    loading={ save_button_loading }
                                    disabled={save_temp_button_loading}
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#000000"
                                    className="btn btn-success pull-left" onClick={ () => { this.onSubmit() } } >
                                    <i className="fa fa-check"></i>&nbsp;HOÀN TẤT KIỂM KHO
                                </LaddaButton>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );
    }
}

CreateCheckInventories.defaultProps = {
    accounting: false
}

const mapStateToProps = state => {
    return {
        inventory_check: state.inventory_check
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onEmptyInfo() {
            dispatch(InventoryCheckAction.emptyInfo())
        },
        onErrorField(field,value){
            dispatch(InventoryCheckAction.errorField(field,value))
        },
        onFocusField(field,value){
            dispatch(InventoryCheckAction.focusField(field,value))
        },
        onFieldChange(field,value){
            dispatch(InventoryCheckAction.fieldChange(field,value))
        },
        onResetField(){
            dispatch(InventoryCheckAction.resetField())
        },
        onUpdateProducts(products){
            dispatch(InventoryCheckAction.updateProductsRequest(products))
        },
        onSelectProductToList(item){
            dispatch(InventoryCheckAction.selectProductToListRequest(item))
        },
        onUpdateProductItem(input_name, key, value){
            dispatch(InventoryCheckAction.updateProductItemRequest(input_name, key, value))
        },
        onClearProduct(){
            dispatch(InventoryCheckAction.clearProductRequest())
        },
        onSumTotal(){
            dispatch(InventoryCheckAction.sumTotal())
        },
        onSubmit(data_post){
            return dispatch(InventoryCheckAction.addRequest(data_post))
        },
        onUpdate(data_post, id, button_name){
            return dispatch(InventoryCheckAction.updateRequest(data_post, id, button_name))
        },
        onGetData: id => {
            return dispatch(InventoryCheckAction.getRequest(id))
        },
        onGetStaffNotes: id => {
            dispatch(InventoryCheckAction.getStaffNotesRequest(id))
        },
        onGetLastStaffNote: id => {
            dispatch(InventoryCheckAction.getLastStaffNoteRequest(id))
        },
    }
}
const CreateCheckInventoriesConnect = connect(mapStateToProps, mapDispatchToProps)(CreateCheckInventories);
export default withRouter(CreateCheckInventoriesConnect);