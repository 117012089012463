import * as ActivitiesActionType from '../constants/ActivitiesActionType';
var initialState = {
    list: [],
    total: 0,
    total_page: 0,
    page: 1,
    limit: 20
};
const activities = (state = initialState, action) => {
    switch(action.type){
        case ActivitiesActionType.LIST_ACTIVITIES:
            return {
                ...state,
                list: action.list,
                total: action.total,
                total_page: action.total_page,
                page: action.page,
                limit: action.limit,
            };
        default: 
            return state;
    }
}
export default activities;