import * as PaymentInvoiceActionType from '../constants/PaymentInvoiceActionType';
import Helper from '../utils/Helper';
var initialState = {
    id: 0,
    receipt_bill_id: 0,
    receipt_bill_code: "",
    payment_bill_date: "",
    payment_bill_code: "",
    payment_bill_notes: "", 
    payment_bill_notes_active: false,
    payment_category_name: "",
    last_notes: "",
    last_notes_staff_name: "",
    last_notes_time: "",
    payment_bill_status_select: [],
    payment_bill_status_color_select: [],
    payment_bill_status: 0,
    payment_bill_status_text: "",
    payment_bill_status_color: "",
    payment_bill_type: 0,
    partner_id: 0,
    partner_code: "",
    partner_name: "",
    amount: 0,
    staff_id: 0,
    staff_name: "",
    staff_cancel_id: 0,
    staff_cancel_name: "",
    reason_cancel: "",
    cancel_time: 0,
    staff_finish_id: 0,
    staff_finish_name: "",
    finish_time: 0,
    branch_id: 0,
    branch_name: "",
    is_cod: false,
    staff_notes: [],
    products: [],
    receipts: [],
    receipts_selected: [],
    finance_sources_selected: [],
    object_selected: {}, 
    category_selected: {},
    total: 0,
    limit: 15,
    total_liabilities_amount: 0,
    total_paid_amount: 0,
    total_amount: 0,
    total_page: 0,
    page: 1,
    list: [],
    search_code: "",
    selected_import_receipt_code: "",
    selected_vendor_bill_code: "",
    selected_invoice_code: "",
    search_invoice_cancel: 0,
    search_date: [],
    search_status: [],
    customer_selected: [],
    payment_filter_selected: [],
    search_button_loading: false,
    save_button_loading: false,
    save_date_button_loading: false,
    save_note_button_loading: false,
    is_search: false,
    direct_to_list: false,
};
const payment_invoice = (state = initialState, action) => {
    let index = -1;
    switch(action.type){
        case PaymentInvoiceActionType.ITEM_BUTTON_SPINNER_PAYMENT_INVOICE:
                index = Helper.findIndex(state.list, action.id);
                state.list[index].loading = action.status;
              return {
                ...state
              };
        case PaymentInvoiceActionType.BUTTON_SPINNER_PAYMENT_INVOICE:
            return {
                ...state,
                save_button_loading: action.status
            };
        case PaymentInvoiceActionType.BUTTON_UPDATE_SPINNER_PAYMENT_INVOICE:
          return {
              ...state,
              [action.button_name]: action.status
          };
        case PaymentInvoiceActionType.FOCUS_FIELD_PAYMENT_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case PaymentInvoiceActionType.ERROR_FIELD_PAYMENT_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case PaymentInvoiceActionType.FIELD_CHANGE_PAYMENT_INVOICE:
              return {
                ...state,
                [action.field]: action.value
              };
        case PaymentInvoiceActionType.FIELDS_CHANGE_PAYMENT_INVOICE:
              return {
                ...state,
                ...action.fields
              };
        case PaymentInvoiceActionType.EMPTY_PAYMENT_INVOICE:      
          return {
            ...state,
            id: 0,
            receipt_bill_id: 0,
            receipt_bill_code: "",
            payment_bill_date: "",
            payment_bill_code: "",
            payment_bill_notes: "", 
            payment_bill_notes_active: false,
            payment_category_name: "",
            last_notes: "",
            last_notes_staff_name: "",
            last_notes_time: "",
            payment_bill_status_select: [],
            payment_bill_status_color_select: [],
            payment_bill_status: 0,
            payment_bill_status_text: "",
            payment_bill_status_color: "",
            payment_bill_type: 0,
            partner_id: 0,
            partner_code: "",
            partner_name: "",
            amount: 0,
            staff_id: 0,
            staff_name: "",
            staff_cancel_id: 0,
            staff_cancel_name: "",
            reason_cancel: "",
            cancel_time: 0,
            staff_finish_id: 0,
            staff_finish_name: "",
            finish_time: 0,
            branch_id: 0,
            branch_name: "",
            is_cod: false,
            staff_notes: [],
            products: [],
            receipts: [],
            receipts_selected: [],
            finance_sources_selected: [],
            object_selected: {}, 
            category_selected: {},
            total: 0,
            limit: 15,
            total_liabilities_amount: 0,
            total_paid_amount: 0,
            total_amount: 0,
            total_page: 0,
            page: 1,
            list: [],
            search_code: "",
            selected_import_receipt_code: "",
            selected_vendor_bill_code: "",
            selected_invoice_code: "",
            search_invoice_cancel: 0,
            search_date: [],
            search_status: [],
            customer_selected: [],
            payment_filter_selected: [],
            search_button_loading: false,
            save_button_loading: false,
            save_date_button_loading: false,
            save_note_button_loading: false,
            is_search: false,
            direct_to_list: false,
          };
        case PaymentInvoiceActionType.RESET_FIELD_SEARCH_PAYMENT_INVOICE:      
            return {
              ...state,
              search_code: "",
              selected_import_receipt_code: "",
              selected_vendor_bill_code: "",
              selected_invoice_code: "",
              search_invoice_cancel: 0,
              search_date: [],
              search_status: [],
              customer_selected: [],
              payment_filter_selected: [],
              search_button_loading: false,
              is_search: false,
            };
        case PaymentInvoiceActionType.GET_PAYMENT_INVOICE:
            let is_cod = false
            if (action.invoice.payment_bill_type === PaymentInvoiceActionType.CUSTOMER_PAYMENT_INVOICE){
              if (action.invoice.payment_bill_finance.length > 0){
                action.invoice.payment_bill_finance.forEach((methodItem, _) => {
                    if (methodItem.is_cod){
                      is_cod = true
                    } 
                })
              }
            }
            
            return {
                ...state,
                id: action.invoice.id,
                receipt_bill_id: action.invoice.receipt_bill_id,
                receipt_bill_code: action.invoice.receipt_bill_code,
                payment_bill_type: action.invoice.payment_bill_type,
                payment_bill_date: action.invoice.payment_bill_date,
                payment_bill_code: action.invoice.payment_bill_code,
                payment_bill_notes: action.invoice.payment_bill_notes, 
                payment_bill_status: action.invoice.payment_bill_status, 
                payment_category_name: action.invoice.payment_category_name, 
                payment_bill_status_text: action.invoice.payment_bill_status_text, 
                payment_bill_status_color: action.invoice.payment_bill_status_color, 
                partner_id: action.invoice.partner_id, 
                partner_code: action.invoice.partner_code, 
                partner_name: action.invoice.partner_name, 
                amount: action.invoice.amount, 
                staff_id: action.invoice.staff_id, 
                staff_name: action.invoice.staff_name, 
                staff_cancel_id: action.invoice.staff_cancel_id, 
                staff_cancel_name: action.invoice.staff_cancel_name, 
                reason_cancel: action.invoice.reason_cancel, 
                cancel_time: action.invoice.cancel_time, 
                staff_finish_id: action.invoice.staff_finish_id, 
                staff_finish_name: action.invoice.staff_finish_name, 
                finish_time: action.invoice.finish_time, 
                branch_id: action.invoice.branch_id, 
                branch_name: action.invoice.branch_name, 
                payment_bill_finance: action.invoice.payment_bill_finance,
                receipts_selected: action.invoice.payment_bill_receipts,
                is_cod
            };
        case PaymentInvoiceActionType.GET_BILL_STATUS_PAYMENT_INVOICE:
            let bill_status_array = [], bill_status_color_array = []
            Object.keys(action.list).map((k) => {
              bill_status_array.push({
                  label: action.list[k],
                  value: k
              })
              return true
            })
            Object.keys(action.color).map((k) => {
              bill_status_color_array.push({
                  label: action.color[k],
                  value: k
              })
              return true
            })
          return {  
              ...state,
              payment_bill_status_select: bill_status_array,
              payment_bill_status_color_select: bill_status_color_array
          };  
        case PaymentInvoiceActionType.LIST_PAYMENT_INVOICE_STATUS:
          return {
              ...state,
              payment_bill_status_select: action.status
            };
        case PaymentInvoiceActionType.GET_STAFF_NOTES_PAYMENT_INVOICE:
          return {  
              ...state,
              staff_notes: action.staff_notes
          };  
        case PaymentInvoiceActionType.GET_LAST_STAFF_NOTES_PAYMENT_INVOICE:
          return {  
              ...state,
              last_notes: action.last_staff_notes.content,
              last_notes_staff_name: action.last_staff_notes.staff_name,
              last_notes_time: action.last_staff_notes.time,
          };  
        case PaymentInvoiceActionType.LIST_PAYMENT_INVOICE:
            return {
                ...state,
                total: action.total,
                limit: action.limit,
                page: action.page,
                total_page: action.total_page,
                total_amount: action.total_payed,
                list: [...action.list],
                direct_to_list: false
              };
        case PaymentInvoiceActionType.SET_PRODUCTS_PAYMENT_INVOICE:
          return {  
              ...state,
              products: action.products
          };  
        case PaymentInvoiceActionType.SET_RECEIPTS_PAYMENT_INVOICE:
          let receipts = []
          if(action.receipts.length > 0){
            action.receipts.forEach(item => {
              let code = "", amount = 0, date = "", payed_amount = 0
              if(item.import_receipt_code !== undefined){
                code = item.import_receipt_code
              } else if(item.bill_code !== undefined){
                code = item.bill_code
              }
              if(item.total_liabilities !== undefined){
                amount = item.total_liabilities
                payed_amount = item.paid_liabilities
              } else if(item.amount !== undefined){
                amount = item.amount
                payed_amount = item.paid_amount
              }
              if(item.import_receipt_date !== undefined){
                date = item.import_receipt_date
              } else if(item.bill_date !== undefined){
                date = item.bill_date
              }
              receipts.push({
                id: item.id,
                code,
                amount,
                payed_amount,
                date: Helper.replaceMysqlDateTime(date)
              })
            })
          }
          return {  
              ...state,
              receipts
          };  
        case PaymentInvoiceActionType.UPDATE_AMOUNT_PAYMENT_INVOICE: 
          let total_amount = 0
          index = Helper.findIndex(state.receipts_selected, action.id);
          console.log("index: ",index)
          if (index > -1){
            let receipt_selected = state.receipts_selected[index]
            receipt_selected.finance_sources = [...action.finance_sources]
            receipt_selected.pay_amount = 0
            for(var i = 0; i < receipt_selected.finance_sources.length; i++){
              if(receipt_selected.finance_sources[i].amount !== undefined && receipt_selected.finance_sources[i].amount > 0){
                receipt_selected.pay_amount += parseFloat(receipt_selected.finance_sources[i].amount)
              }
            } 
            state.receipts_selected[index] = receipt_selected
            state.receipts_selected.forEach(item => {
              if(item.pay_amount!== undefined && item.pay_amount > 0){
                total_amount += parseFloat(item.pay_amount)
              }
            })
          }
          return {
            ...state,
            total_amount
          };   
        case PaymentInvoiceActionType.SEARCH_PAYMENT_INVOICE:
            return {
                ...state,
                search_button_loading: action.search_button_loading
            };
        case PaymentInvoiceActionType.DIRECT_TO_LIST_PAYMENT_INVOICE:
          return {
            ...state,
            direct_to_list: action.status
          };     
        default: 
            return state;
    }
}
export default payment_invoice;